
import { Card } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { CheckCircle2, Clock, AlertTriangle } from 'lucide-react';
import type { Database } from '@/types/supabase';

type WorkspaceAnalytics = Database['public']['Tables']['workspace_analytics']['Row'];

interface TaskMetricsProps {
  analytics: WorkspaceAnalytics | null;
  isLoading: boolean;
}

export function TaskMetrics({ analytics, isLoading }: TaskMetricsProps) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <Card className="p-6">
        <div className="flex items-center gap-4">
          <CheckCircle2 className="h-8 w-8 text-green-500" />
          <div>
            <h3 className="text-sm font-medium text-gray-500">Completed Tasks</h3>
            {isLoading ? (
              <Skeleton className="h-8 w-20" />
            ) : (
              <p className="text-2xl font-bold">{analytics?.completed_tasks || 0}</p>
            )}
          </div>
        </div>
      </Card>

      <Card className="p-6">
        <div className="flex items-center gap-4">
          <Clock className="h-8 w-8 text-blue-500" />
          <div>
            <h3 className="text-sm font-medium text-gray-500">In Progress</h3>
            {isLoading ? (
              <Skeleton className="h-8 w-20" />
            ) : (
              <p className="text-2xl font-bold">{analytics?.in_progress_tasks || 0}</p>
            )}
          </div>
        </div>
      </Card>

      <Card className="p-6">
        <div className="flex items-center gap-4">
          <AlertTriangle className="h-8 w-8 text-red-500" />
          <div>
            <h3 className="text-sm font-medium text-gray-500">Blocked Tasks</h3>
            {isLoading ? (
              <Skeleton className="h-8 w-20" />
            ) : (
              <p className="text-2xl font-bold">{analytics?.blocked_tasks || 0}</p>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
}
