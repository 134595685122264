import { Skeleton } from "@/components/ui/skeleton";

export const CompanyDetailsSidebarLoading = () => {
  return (
    <div className="space-y-6 p-4">
      <Skeleton className="h-12 w-12 rounded-lg" />
      <Skeleton className="h-8 w-3/4" />
      <Skeleton className="h-4 w-1/2" />
      <div className="grid grid-cols-2 gap-4 mt-6">
        <Skeleton className="h-24" />
        <Skeleton className="h-24" />
      </div>
    </div>
  );
};