import React from 'react';
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { 
  Loader2, 
  TrendingUp,
  Calendar,
  Target,
  Building2,
  DollarSign,
  AlertTriangle,
  CheckCircle2,
  ChartBar
} from "lucide-react";

interface SuggestedAction {
  priority: string;
  action: string;
}

interface CompetitorAnalysis {
  competitive_advantages: string[];
}

interface PriceRecommendations {
  minimum_price: number;
  recommended_price: number;
  optimal_price: number;
  justification: string;
}

export const DealIntelligence = ({ dealId }: { dealId: string }) => {
  const { data: dealAnalytics, isLoading } = useQuery({
    queryKey: ['deal-analytics', dealId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('deal_analytics')
        .select('*')
        .eq('deal_id', dealId)
        .maybeSingle();
      
      if (error) throw error;
      return data;
    }
  });

  if (isLoading) {
    return (
      <Card>
        <CardContent className="flex justify-center items-center h-[300px]">
          <Loader2 className="w-8 h-8 animate-spin text-primary" />
        </CardContent>
      </Card>
    );
  }

  if (!dealAnalytics) {
    return (
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <ChartBar className="w-5 h-5 text-primary" />
            Deal Intelligence
          </CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-muted-foreground text-center py-4">
            No analytics data available for this deal yet.
          </p>
        </CardContent>
      </Card>
    );
  }

  const suggestedActions = dealAnalytics?.suggested_actions 
    ? dealAnalytics.suggested_actions as unknown as SuggestedAction[] 
    : [];

  const competitorAnalysis = dealAnalytics?.competitor_analysis 
    ? dealAnalytics.competitor_analysis as unknown as CompetitorAnalysis 
    : { competitive_advantages: [] };

  const priceRecommendations = dealAnalytics?.price_recommendations 
    ? dealAnalytics.price_recommendations as unknown as PriceRecommendations 
    : {
      minimum_price: 0,
      recommended_price: 0,
      optimal_price: 0,
      justification: 'No pricing data available'
    };

  const getPriorityColor = (priority: string) => {
    switch (priority.toLowerCase()) {
      case 'high':
        return 'bg-red-500/10 text-red-500';
      case 'medium':
        return 'bg-yellow-500/10 text-yellow-500';
      case 'low':
        return 'bg-green-500/10 text-green-500';
      default:
        return 'bg-blue-500/10 text-blue-500';
    }
  };

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <Target className="w-5 h-5 text-primary" />
            Deal Intelligence
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-8">
          {/* Suggested Actions Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4 flex items-center gap-2">
              <AlertTriangle className="w-4 h-4 text-primary" />
              Suggested Actions
            </h3>
            {suggestedActions.length > 0 ? (
              <div className="space-y-3">
                {suggestedActions.map((action, index) => (
                  <div key={index} className="flex items-start gap-3 p-3 rounded-lg bg-accent/50">
                    <Badge className={getPriorityColor(action.priority)}>
                      {action.priority}
                    </Badge>
                    <p className="text-sm">{action.action}</p>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-muted-foreground">No suggested actions available</p>
            )}
          </div>

          {/* Competitor Analysis Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4 flex items-center gap-2">
              <Building2 className="w-4 h-4 text-primary" />
              Competitive Advantages
            </h3>
            {competitorAnalysis.competitive_advantages.length > 0 ? (
              <div className="grid gap-3">
                {competitorAnalysis.competitive_advantages.map((advantage, index) => (
                  <div key={index} className="flex items-center gap-3">
                    <CheckCircle2 className="w-4 h-4 text-green-500 shrink-0" />
                    <p className="text-sm">{advantage}</p>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-muted-foreground">No competitive advantages identified</p>
            )}
          </div>

          {/* Price Recommendations Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4 flex items-center gap-2">
              <DollarSign className="w-4 h-4 text-primary" />
              Price Recommendations
            </h3>
            <div className="grid gap-6">
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                <Card className="p-4">
                  <p className="text-sm text-muted-foreground mb-1">Minimum Price</p>
                  <p className="text-lg font-semibold">${priceRecommendations.minimum_price.toLocaleString()}</p>
                </Card>
                <Card className="p-4">
                  <p className="text-sm text-muted-foreground mb-1">Recommended Price</p>
                  <p className="text-lg font-semibold">${priceRecommendations.recommended_price.toLocaleString()}</p>
                </Card>
                <Card className="p-4">
                  <p className="text-sm text-muted-foreground mb-1">Optimal Price</p>
                  <p className="text-lg font-semibold">${priceRecommendations.optimal_price.toLocaleString()}</p>
                </Card>
              </div>
              <Card className="p-4">
                <p className="text-sm text-muted-foreground mb-1">Pricing Justification</p>
                <p className="text-sm">{priceRecommendations.justification}</p>
              </Card>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};