
import { ReactNode } from 'react';

interface ProtectedRouteProps {
  children: ReactNode;
  requiredRole: 'admin' | 'employee' | 'workspace_user';
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  return <div className="min-h-screen bg-background">{children}</div>;
};
