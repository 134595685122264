
import { useState } from 'react';
import { motion } from 'framer-motion';
import { Button } from '@/components/ui/button';
import { Download, Check, ArrowRight } from 'lucide-react';
import { toast } from 'sonner';

interface ResourceOfferProps {
  segment: 'startup' | 'enterprise' | 'government';
  serviceType: string;
  goal: string;
}

export const ResourceOffer = ({ segment, serviceType, goal }: ResourceOfferProps) => {
  const [claimed, setClaimed] = useState(false);
  
  const handleDownload = () => {
    // In a real implementation, this would trigger an actual download
    // or redirect to a download page
    setClaimed(true);
    toast.success("Your resource is being prepared!", {
      description: "Check your email for the download link soon.",
      duration: 5000,
    });
  };

  // Segment-specific resource data
  const resourceData = {
    startup: {
      title: "Startup Toolkit",
      description: "Get our <span class=\"font-semibold text-brand-orange\">Unstuck Anywhere Startup Toolkit</span> – a comprehensive guide to launching your idea with confidence and clarity.",
      features: [
        "25-point launch checklist for digital products",
        "Resource allocation template for early-stage startups",
        "Decision-making framework for product development",
        "Access to our exclusive startup community"
      ],
      buttonText: "Download Free Toolkit",
      imageAlt: "Startup Toolkit Preview"
    },
    enterprise: {
      title: "Enterprise Transformation Guide",
      description: "Access our <span class=\"font-semibold text-brand-orange\">Enterprise Digital Transformation Playbook</span> – a strategic roadmap for modernizing legacy systems and driving innovation.",
      features: [
        "Digital transformation assessment framework",
        "Legacy system modernization checklist",
        "ROI calculator for technology investments",
        "Change management blueprint for enterprise adoption"
      ],
      buttonText: "Get Enterprise Playbook",
      imageAlt: "Enterprise Guide Preview"
    },
    government: {
      title: "Government Compliance Framework",
      description: "Download our <span class=\"font-semibold text-brand-orange\">Government Technology Compliance Framework</span> – your comprehensive guide to navigating complex regulations while implementing modern solutions.",
      features: [
        "FedRAMP compliance readiness assessment",
        "Security control implementation guide",
        "Procurement process optimization toolkit",
        "Citizen-centric service design templates"
      ],
      buttonText: "Access Compliance Framework",
      imageAlt: "Government Framework Preview"
    }
  };

  // Further personalize based on service type and goal
  let customResourceTitle = resourceData[segment].title;
  let customDescription = resourceData[segment].description;
  
  // Customize resource title and description based on service type and goal
  if (segment === 'startup') {
    if (serviceType.includes('MVP Development')) {
      customResourceTitle = "MVP Launch Toolkit";
      customDescription = `Get our <span class="font-semibold text-brand-orange">MVP Launch Toolkit</span> – designed specifically for founders looking to ${goal}.`;
    } else if (serviceType.includes('Technical Co-Founding')) {
      customResourceTitle = "Technical Co-Founder's Playbook";
      customDescription = `Access our <span class="font-semibold text-brand-orange">Technical Co-Founder's Playbook</span> – essential strategies for founders who want to ${goal}.`;
    }
  } else if (segment === 'enterprise') {
    if (serviceType.includes('Legacy Modernization')) {
      customResourceTitle = "Legacy System Modernization Guide";
      customDescription = `Download our <span class="font-semibold text-brand-orange">Legacy Modernization Roadmap</span> – a step-by-step guide to help you ${goal}.`;
    } else if (serviceType.includes('Digital Transformation')) {
      customResourceTitle = "Digital Transformation Blueprint";
      customDescription = `Get our <span class="font-semibold text-brand-orange">Digital Transformation Blueprint</span> – strategic frameworks to help your organization ${goal}.`;
    }
  } else if (segment === 'government') {
    if (serviceType.includes('FedRAMP')) {
      customResourceTitle = "FedRAMP Compliance Kit";
      customDescription = `Access our <span class="font-semibold text-brand-orange">FedRAMP Compliance Kit</span> – a complete resource to help your agency ${goal}.`;
    } else if (serviceType.includes('Legacy Modernization')) {
      customResourceTitle = "Government Systems Modernization Guide";
      customDescription = `Download our <span class="font-semibold text-brand-orange">Government Systems Modernization Guide</span> – designed to help agencies ${goal}.`;
    }
  }

  const resource = resourceData[segment];

  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.7, delay: 0.9 }}
      className="bg-gradient-to-br from-black to-accent/70 border border-brand-orange/20 rounded-xl p-6 md:p-8 w-full max-w-4xl mx-auto backdrop-blur-sm shadow-[0_0_15px_rgba(255,69,0,0.15)]"
    >
      <div className="flex flex-col md:flex-row gap-6 md:gap-8 items-center">
        <div className="w-full md:w-1/2">
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.1, duration: 0.5 }}
          >
            <h3 className="text-2xl md:text-3xl font-display font-bold text-brand-orange mb-3">
              {customResourceTitle}
            </h3>
            <p className="text-white/90 mb-4" dangerouslySetInnerHTML={{ __html: customDescription }} />
            <ul className="space-y-2 mb-6">
              {resource.features.map((item, i) => (
                <motion.li 
                  key={i}
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 1.2 + (i * 0.1), duration: 0.3 }}
                  className="flex items-start gap-2 text-white/80"
                >
                  <Check className="text-brand-orange mt-1 size-4 shrink-0" />
                  <span>{item}</span>
                </motion.li>
              ))}
            </ul>
            <Button 
              onClick={handleDownload}
              className="bg-brand-orange hover:bg-brand-orange/90 text-white font-bold py-2 px-6 rounded-md flex items-center gap-2 transition-all transform hover:scale-105"
              disabled={claimed}
            >
              {claimed ? (
                <>
                  <Check className="size-4" />
                  Resource Claimed!
                </>
              ) : (
                <>
                  <Download className="size-4" />
                  {resource.buttonText}
                </>
              )}
            </Button>
          </motion.div>
        </div>
        
        <div className="w-full md:w-1/2 flex justify-center">
          <motion.div
            initial={{ opacity: 0, scale: 0.8, rotate: -5 }}
            animate={{ opacity: 1, scale: 1, rotate: 0 }}
            transition={{ delay: 1.3, duration: 0.7, type: "spring" }}
            className="relative"
          >
            <div className="absolute inset-0 bg-gradient-to-tr from-brand-orange/40 to-brand-orange/10 rounded-lg blur-xl"></div>
            <div className="relative bg-gradient-to-br from-black/70 to-accent p-5 rounded-lg border border-white/10 transform">
              <div className="text-sm text-white/70 font-mono mb-3">UNSTUCK ANYWHERE PRESENTS</div>
              <div className="text-2xl font-display font-bold text-white mb-1">{customResourceTitle}</div>
              <div className="text-brand-orange font-semibold mb-4">
                {segment === 'startup' ? 'Complete Resource Pack' : 
                 segment === 'enterprise' ? 'Digital Transformation Guide' : 
                 'Compliance Framework'}
              </div>
              <div className="space-y-2 mb-4">
                {[1, 2, 3].map(i => (
                  <div key={i} className="h-2 bg-white/10 rounded-full w-full"></div>
                ))}
              </div>
              <div className="flex justify-between items-center">
                <div className="text-xs text-white/60">{resource.features.length}+ Resources</div>
                <div className="flex gap-1 items-center text-xs text-brand-orange">
                  Explore <ArrowRight className="size-3" />
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};
