import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Globe } from "lucide-react";
import { ContactSocialInfoProps } from "../../types/contactTypes";

export const ContactSocialInfo = ({ 
  formData, 
  onSocialProfileChange, 
  isEditing = true 
}: ContactSocialInfoProps) => {
  if (!isEditing) {
    return (
      <div className="space-y-4">
        <div className="flex items-center gap-2 text-muted-foreground mb-2">
          <Globe className="w-4 h-4" />
          <Label>Social Profiles</Label>
        </div>
        <div className="space-y-2">
          {Object.entries(formData.social_profiles || {}).map(([platform, url]) => (
            <div key={platform} className="flex items-center gap-2">
              <span className="capitalize">{platform}:</span>
              <a 
                href={url} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-blue-500 hover:underline"
              >
                {url}
              </a>
            </div>
          ))}
          {(!formData.social_profiles || Object.keys(formData.social_profiles).length === 0) && (
            <span className="text-muted-foreground">No social profiles</span>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <Label className="flex items-center gap-2 mb-2">
        <Globe className="w-4 h-4" />
        Social Profiles
      </Label>
      <div className="space-y-4">
        {['linkedin', 'twitter', 'github'].map((platform) => (
          <div key={platform} className="flex gap-2">
            <Label className="w-24 capitalize">{platform}</Label>
            <Input
              value={formData.social_profiles?.[platform] || ""}
              onChange={(e) => onSocialProfileChange(platform, e.target.value)}
              placeholder={`${platform} URL`}
              className="bg-background flex-1"
            />
          </div>
        ))}
      </div>
    </div>
  );
};