export const automationGuide = {
  title: "Automation & AI",
  description: "Leverage automation tools for enhanced efficiency and intelligence.",
  sections: [
    {
      title: "Email Automation",
      content: `Configure automated email responses and campaigns:

Key Features:
- Response template management
- Trigger condition setup
- Performance monitoring
- A/B testing capabilities

Interactive Demo:
1. Create email template
2. Set up trigger conditions
3. Configure response rules
4. Test automation flow

Best Practices:
- Regular template updates
- Monitor response rates
- Test all automation flows
- Keep templates personalized`
    },
    {
      title: "Chat Bot Configuration",
      content: `Set up and manage AI-powered chat assistance:

Key Features:
- Bot personality customization
- Response flow creation
- Handoff rules setup
- Analytics tracking

Interactive Demo:
1. Configure bot settings
2. Create response flows
3. Set up handoff rules
4. Test conversations

Best Practices:
- Regular bot training
- Monitor chat quality
- Update responses regularly
- Track user satisfaction`
    },
    {
      title: "Workflow Automation",
      content: `Create and manage automated business processes:

Key Features:
- Workflow builder
- Trigger management
- Action configuration
- Performance tracking

Interactive Demo:
1. Create new workflow
2. Set up triggers
3. Configure actions
4. Test automation

Best Practices:
- Document all workflows
- Regular testing
- Monitor performance
- Update as needed`
    }
  ]
};