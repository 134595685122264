import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Briefcase, Users } from "lucide-react";
import { Contact } from "../../types/contactTypes";

interface ContactJobInfoProps {
  formData: Contact;
  onChange: (field: string, value: string) => void;
  isEditing: boolean;
}

export const ContactJobInfo = ({ formData, onChange, isEditing }: ContactJobInfoProps) => {
  if (!isEditing) {
    return (
      <div className="grid grid-cols-2 gap-6">
        <div className="space-y-2">
          <div className="flex items-center gap-2 text-muted-foreground">
            <Briefcase className="w-4 h-4" />
            <Label>Job Title</Label>
          </div>
          <p className="text-lg">{formData.job_title || "Not specified"}</p>
        </div>

        <div className="space-y-2">
          <div className="flex items-center gap-2 text-muted-foreground">
            <Users className="w-4 h-4" />
            <Label>Department</Label>
          </div>
          <p className="text-lg">{formData.department || "Not specified"}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-2 gap-6">
      <div className="space-y-2">
        <Label htmlFor="job_title" className="flex items-center gap-2">
          <Briefcase className="w-4 h-4" />
          Job Title
        </Label>
        <Input
          id="job_title"
          value={formData.job_title || ""}
          onChange={(e) => onChange("job_title", e.target.value)}
          className="bg-background"
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="department" className="flex items-center gap-2">
          <Users className="w-4 h-4" />
          Department
        </Label>
        <Input
          id="department"
          value={formData.department || ""}
          onChange={(e) => onChange("department", e.target.value)}
          className="bg-background"
        />
      </div>
    </div>
  );
};