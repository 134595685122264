import { motion } from "framer-motion";
import { Card } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Info, PlayCircle } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { toast } from "sonner";

interface GuideSection {
  title: string;
  content: string;
}

interface Guide {
  title: string;
  description: string;
  sections: GuideSection[];
}

interface AdminWikiSectionProps {
  guide: Guide;
}

export const AdminWikiSection = ({ guide }: AdminWikiSectionProps) => {
  const [activeDemo, setActiveDemo] = useState<string | null>(null);

  const startInteractiveDemo = (sectionTitle: string) => {
    setActiveDemo(sectionTitle);
    toast.success(`Starting interactive demo for ${sectionTitle}`, {
      description: "Follow the highlighted steps to complete the demo",
    });
  };

  return (
    <div className="space-y-6">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="space-y-2"
      >
        <h3 className="text-2xl font-bold">{guide.title}</h3>
        <p className="text-muted-foreground">{guide.description}</p>
      </motion.div>

      <ScrollArea className="h-[600px] pr-4">
        <div className="grid gap-6">
          {guide.sections.map((section, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <Card className="p-6 space-y-4 relative overflow-hidden">
                {activeDemo === section.title && (
                  <motion.div
                    className="absolute inset-0 bg-primary/5 dark:bg-primary/10"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  />
                )}
                
                <div className="flex items-center gap-2">
                  <Info className="w-5 h-5 text-primary" />
                  <h4 className="text-lg font-semibold">{section.title}</h4>
                </div>
                
                <div className="prose prose-sm max-w-none dark:prose-invert">
                  {section.content.split('\n\n').map((paragraph, pIndex) => {
                    if (paragraph.startsWith('Best Practices:')) {
                      return (
                        <motion.div 
                          key={pIndex} 
                          className="mt-4 bg-secondary/10 p-4 rounded-lg"
                          whileHover={{ scale: 1.01 }}
                          transition={{ duration: 0.2 }}
                        >
                          <h5 className="font-semibold mb-2">Best Practices</h5>
                          <ul className="list-disc pl-4 space-y-1">
                            {paragraph.replace('Best Practices:', '').trim().split('\n').map((practice, practiceIndex) => (
                              <li key={practiceIndex}>{practice.replace('- ', '')}</li>
                            ))}
                          </ul>
                        </motion.div>
                      );
                    }
                    
                    if (paragraph.startsWith('Interactive Demo:')) {
                      return (
                        <motion.div 
                          key={pIndex} 
                          className="mt-4 border border-primary/20 p-4 rounded-lg"
                          whileHover={{ scale: 1.01 }}
                          transition={{ duration: 0.2 }}
                        >
                          <div className="flex items-center justify-between mb-2">
                            <div className="flex items-center gap-2">
                              <PlayCircle className="w-4 h-4 text-primary" />
                              <h5 className="font-semibold">Interactive Demo</h5>
                            </div>
                            <Button 
                              variant="outline" 
                              size="sm"
                              onClick={() => startInteractiveDemo(section.title)}
                              className="text-xs"
                            >
                              Start Demo
                            </Button>
                          </div>
                          <ol className="list-decimal pl-4 space-y-1">
                            {paragraph.split(':')[1].trim().split('\n').map((step, stepIndex) => (
                              <motion.li 
                                key={stepIndex}
                                className={activeDemo === section.title ? 'text-primary font-medium' : ''}
                                animate={activeDemo === section.title ? {
                                  scale: [1, 1.02, 1],
                                  transition: { 
                                    duration: 0.5,
                                    delay: stepIndex * 0.5,
                                    repeat: Infinity,
                                    repeatDelay: 2
                                  }
                                } : {}}
                              >
                                {step.replace(/^\d+\.\s/, '')}
                              </motion.li>
                            ))}
                          </ol>
                        </motion.div>
                      );
                    }
                    
                    if (paragraph.includes(':')) {
                      const [title, ...content] = paragraph.split(':');
                      return (
                        <motion.div 
                          key={pIndex} 
                          className="mb-4"
                          initial={{ opacity: 0, x: -20 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ delay: pIndex * 0.1 }}
                        >
                          <h5 className="font-semibold mb-2">{title}:</h5>
                          <ul className="list-disc pl-4 space-y-1">
                            {content.join(':').trim().split('\n').map((item, itemIndex) => (
                              <li key={itemIndex}>{item.replace('- ', '')}</li>
                            ))}
                          </ul>
                        </motion.div>
                      );
                    }
                    
                    return (
                      <motion.p 
                        key={pIndex}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: pIndex * 0.1 }}
                      >
                        {paragraph}
                      </motion.p>
                    );
                  })}
                </div>

                <motion.div 
                  className="mt-4 flex items-center gap-2 text-sm text-muted-foreground"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.3 }}
                >
                  <PlayCircle className="w-4 h-4" />
                  <span>Interactive demo available</span>
                </motion.div>
              </Card>
            </motion.div>
          ))}
        </div>
      </ScrollArea>
    </div>
  );
};