
import { useState } from "react";
import { Tabs, TabsContent } from "@/components/ui/tabs";
import { DashboardLayout } from "./layout/DashboardLayout";
import { AdminTabs } from "./navigation/AdminTabs";
import { AdminAnalytics } from "./AdminAnalytics";
import { AdminSettings } from "./AdminSettings";
import { AutomatedAgents } from "./automation/AutomatedAgents";
import { AdminCMS } from "./cms/AdminCMS";
import { AdminWiki } from "./documentation/AdminWiki";
import { CRMDashboard } from "./crm/CRMDashboard";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { ContactDetailsPage } from "./crm/contacts/ContactDetailsPage";
import { DealView } from "./crm/deals/DealView";

export const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState("analytics");
  const location = useLocation();
  const navigate = useNavigate();

  const isDetailsPage = location.pathname.includes('/contacts/') || location.pathname.includes('/deals/');
  const isCRMRoute = location.pathname.includes('/sales') || isDetailsPage;

  if (isCRMRoute) {
    return (
      <Routes>
        <Route path="/contacts/:id" element={<ContactDetailsPage />} />
        <Route path="/deals/:id" element={<DealView />} />
        <Route path="*" element={<CRMDashboard />} />
      </Routes>
    );
  }

  if (isDetailsPage) {
    return (
      <DashboardLayout showBackButton onBack={() => navigate('/admin')}>
        <Routes>
          <Route path="/contacts/:id" element={<ContactDetailsPage />} />
          <Route path="/deals/:id" element={<DealView />} />
        </Routes>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <Tabs value={activeTab} onValueChange={setActiveTab} className="space-y-6 sm:space-y-8">
        <AdminTabs />

        <TabsContent value="analytics" className="space-y-4 sm:space-y-6">
          <AdminAnalytics />
        </TabsContent>

        <TabsContent value="sales" className="space-y-4 sm:space-y-6">
          <CRMDashboard />
        </TabsContent>

        <TabsContent value="marketing" className="space-y-4 sm:space-y-6">
          <AdminWiki />
        </TabsContent>

        <TabsContent value="automation" className="space-y-4 sm:space-y-6">
          <AutomatedAgents />
        </TabsContent>

        <TabsContent value="settings" className="space-y-4 sm:space-y-6">
          <AdminSettings />
        </TabsContent>
      </Tabs>
    </DashboardLayout>
  );
};
