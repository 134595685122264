
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LayoutDashboard, Briefcase, Building2, UserRound, LogOut } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { useAuth } from "@/contexts/AuthContext";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";

export const UserMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { session, isLoading } = useAuth();
  const isLandingPage = location.pathname === '/';

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      // Clear React Query cache
      queryClient.clear();
      
      // Clear any local storage
      localStorage.clear();
      
      // Navigate to auth page
      navigate('/auth');
      
      toast.success('Successfully logged out');
    } catch (error: any) {
      console.error('Logout error:', error);
      toast.error(error.message || 'Error logging out');
    }
  };

  if (isLoading) {
    return null;
  }

  if (!session?.user) {
    return (
      <Button 
        variant="ghost" 
        size="sm"
        onClick={() => navigate('/auth')}
        className={cn(
          "text-brand-orange hover:text-white hover:bg-brand-charcoal",
          isLandingPage && "text-brand-orange hover:text-white hover:bg-brand-charcoal"
        )}
      >
        <UserRound className="h-4 w-4 mr-2" />
        Sign In
      </Button>
    );
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button 
          variant="ghost" 
          size="sm"
          className="relative text-brand-orange hover:text-white hover:bg-brand-charcoal flex items-center gap-2 z-[101]"
        >
          <Avatar className="h-8 w-8">
            <AvatarFallback>
              <UserRound className="h-4 w-4" />
            </AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent 
        align="end" 
        className={cn(
          "z-[101]",
          isLandingPage && "bg-[#0D1117] border-zinc-800"
        )}
      >
        <DropdownMenuItem asChild>
          <Link 
            to="/admin" 
            className={cn(
              "flex items-center gap-2",
              isLandingPage && "text-brand-orange hover:text-white hover:bg-brand-charcoal"
            )}
          >
            <LayoutDashboard className="h-4 w-4 mr-2" />
            Admin Dashboard
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem asChild>
          <Link 
            to="/employee/dashboard"
            className={cn(
              "flex items-center gap-2",
              isLandingPage && "text-brand-orange hover:text-white hover:bg-brand-charcoal"
            )}
          >
            <Building2 className="h-4 w-4 mr-2" />
            Employee Portal
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem asChild>
          <Link 
            to="/workspace"
            className={cn(
              "flex items-center gap-2",
              isLandingPage && "text-brand-orange hover:text-white hover:bg-brand-charcoal"
            )}
          >
            <Briefcase className="h-4 w-4 mr-2" />
            Workspace
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={handleLogout}
          className={cn(
            "flex items-center gap-2 cursor-pointer",
            isLandingPage && "text-brand-orange hover:text-white hover:bg-brand-charcoal"
          )}
        >
          <LogOut className="h-4 w-4 mr-2" />
          Sign Out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
