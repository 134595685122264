import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '@/integrations/supabase/client';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import { Switch } from '@/components/ui/switch';
import { toast } from 'sonner';
import { EmployeeFormData, DatabaseEmployee, EmergencyContact } from '@/types/employee';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Upload } from 'lucide-react';
import { Json } from '@/integrations/supabase/types';

const DEPARTMENTS = [
  'Executive Leadership',
  'Operations',
  'Marketing and Communications',
  'Product Development',
  'Software Engineering',
  'Design and User Experience (UX)',
  'Sales and Business Development',
  'Human Resources (HR)',
  'Customer Success',
  'Research and Innovation',
  'Finance and Administration',
  'Data and Analytics',
  'Events and Community Engagement',
  'Fellowships and Internships',
  'Other'
];

const JOB_TITLES = {
  'Executive Leadership': [
    'Chief Executive Officer (CEO)',
    'Chief Operating Officer (COO)',
    'Chief Technology Officer (CTO)',
    'Chief Marketing Officer (CMO)',
    'Chief AI Officer'
  ],
  'Operations': [
    'Operations Manager',
    'Project Manager',
    'Administrative Coordinator',
    'Program Coordinator'
  ],
  'Marketing and Communications': [
    'Marketing Director',
    'Content Strategist',
    'Social Media Manager',
    'Digital Marketing Specialist',
    'Communications Coordinator'
  ],
  'Product Development': [
    'Product Manager',
    'Product Owner',
    'Technical Program Manager',
    'Agile Coach'
  ],
  'Software Engineering': [
    'Senior Software Engineer',
    'Full-Stack Developer',
    'Backend Developer',
    'Frontend Developer',
    'QA Engineer',
    'DevOps Engineer',
    'Mobile App Developer',
    'Data Engineer'
  ],
  'Design and User Experience (UX)': [
    'UX/UI Designer',
    'Graphic Designer',
    'Interaction Designer',
    'Creative Director',
    'User Researcher'
  ],
  'Sales and Business Development': [
    'Business Development Manager',
    'Sales Executive',
    'Account Manager',
    'Partnerships Manager'
  ],
  'Human Resources (HR)': [
    'HR Manager',
    'Talent Acquisition Specialist',
    'Learning and Development Coordinator',
    'HR Generalist'
  ],
  'Customer Success': [
    'Customer Success Manager',
    'Customer Support Specialist',
    'Technical Support Engineer'
  ],
  'Research and Innovation': [
    'Research Fellow',
    'Innovation Strategist',
    'AI Researcher',
    'Startup Analyst'
  ],
  'Finance and Administration': [
    'Finance Manager',
    'Accountant',
    'Financial Analyst',
    'Office Administrator'
  ],
  'Data and Analytics': [
    'Data Scientist',
    'Data Analyst',
    'Business Intelligence Specialist'
  ],
  'Events and Community Engagement': [
    'Events Coordinator',
    'Community Manager',
    'Outreach Specialist'
  ],
  'Fellowships and Internships': [
    'Marketing Fellow',
    'Software Engineering Fellow',
    'Social Justice Research Fellow',
    'Product Development Fellow',
    'Marketing Intern',
    'Software Engineering Intern',
    'Research Intern',
    'Design Intern',
    'Operations Intern'
  ]
};

const LOCATIONS = [
  'Arlington, Virginia',
  'San Francisco Bay Area',
  'Islamabad, Pakistan',
  'Riyadh, Saudi Arabia',
  'Panama City, Panama'
];

interface EmployeeFormProps {
  isFirstUser?: boolean;
  onComplete?: () => void;
}

interface ManagerData {
  id: string;
  first_name: string;
  last_name: string;
  title: string;
}

export const EmployeeForm = ({ isFirstUser, onComplete }: EmployeeFormProps) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [uploadingAvatar, setUploadingAvatar] = useState(false);
  const [managers, setManagers] = useState<ManagerData[]>([]);
  const [showCustomLocation, setShowCustomLocation] = useState(false);
  const [showCustomDepartment, setShowCustomDepartment] = useState(false);
  const [showCustomTitle, setShowCustomTitle] = useState(false);
  const [showCustomAllergy, setShowCustomAllergy] = useState(false);
  const [showCustomDietary, setShowCustomDietary] = useState(false);
  const [showCustomAccommodation, setShowCustomAccommodation] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState<string>('');
  const [availableTitles, setAvailableTitles] = useState<string[]>([]);
  const [formData, setFormData] = useState<EmployeeFormData>({
    first_name: '',
    last_name: '',
    title: '',
    department: '',
    email: '',
    work_phone: null,
    personal_phone: null,
    reports_to: null,
    start_date: new Date().toISOString().split('T')[0],
    pin: '0000',
    location: null,
    emergency_contacts: [{
      name: '',
      relationship: '',
      phone: ''
    }],
    medical_info: {
      allergies: '',
      conditions: '',
      medications: ''
    },
    preferences: {
      dietary: '',
      accommodations: ''
    },
    is_admin: isFirstUser ? true : false,
    birthday: null,
    personal_email: null,
    avatar_url: null,
  });

  useEffect(() => {
    if (!isFirstUser) {
      fetchManagers();
    }
    if (id) {
      fetchEmployee();
    }
  }, [id, isFirstUser]);

  const fetchManagers = async () => {
    try {
      const { data, error } = await supabase
        .from('employee_profiles')
        .select('id, first_name, last_name, title')
        .order('last_name');
      
      if (error) throw error;
      setManagers(data || []);
    } catch (error) {
      console.error('Error fetching managers:', error);
      toast.error('Failed to load managers');
    }
  };

  const fetchEmployee = async () => {
    try {
      const { data, error } = await supabase
        .from('employee_profiles')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;
      if (data) {
        const employee = data as unknown as DatabaseEmployee;
        const parsedData: EmployeeFormData = {
          first_name: employee.first_name,
          last_name: employee.last_name,
          title: employee.title,
          department: employee.department,
          email: employee.email,
          work_phone: employee.work_phone,
          personal_phone: employee.personal_phone,
          reports_to: employee.reports_to,
          start_date: employee.start_date,
          pin: employee.pin,
          location: employee.location,
          emergency_contacts: employee.emergency_contacts,
          medical_info: employee.medical_info,
          preferences: employee.preferences,
          is_admin: employee.is_admin,
          birthday: employee.birthday,
          personal_email: employee.personal_email,
          avatar_url: employee.avatar_url,
        };
        setFormData(parsedData);
      }
    } catch (error) {
      console.error('Error fetching employee:', error);
      toast.error('Failed to load employee data');
    }
  };

  const handleAvatarUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (!event.target.files || event.target.files.length === 0) {
        return;
      }
      
      const file = event.target.files[0];
      const fileExt = file.name.split('.').pop();
      const fileName = `${id || crypto.randomUUID()}.${fileExt}`;
      
      setUploadingAvatar(true);

      const { error: uploadError } = await supabase.storage
        .from('avatars')
        .upload(fileName, file, { 
          upsert: true,
          contentType: file.type
        });

      if (uploadError) {
        console.error('Upload error:', uploadError);
        throw uploadError;
      }

      const { data: { publicUrl } } = supabase.storage
        .from('avatars')
        .getPublicUrl(fileName);

      console.log('Avatar uploaded successfully, public URL:', publicUrl);

      setFormData(prev => ({
        ...prev,
        avatar_url: publicUrl
      }));

      if (id) {
        const { error: updateError } = await supabase
          .from('employee_profiles')
          .update({ 
            avatar_url: publicUrl,
            updated_at: new Date().toISOString()
          })
          .eq('id', id);

        if (updateError) {
          console.error('Profile update error:', updateError);
          throw updateError;
        }
      }

      toast.success('Profile picture updated successfully');
    } catch (error) {
      console.error('Error uploading avatar:', error);
      toast.error('Failed to upload profile picture');
    } finally {
      setUploadingAvatar(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const dbData = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        title: formData.title,
        department: formData.department,
        email: formData.email,
        work_phone: formData.work_phone,
        personal_phone: formData.personal_phone,
        reports_to: formData.reports_to,
        start_date: formData.start_date,
        pin: formData.pin,
        location: formData.location,
        emergency_contacts: formData.emergency_contacts as unknown as Json[],
        medical_info: formData.medical_info as unknown as Json,
        preferences: formData.preferences as unknown as Json,
        is_admin: formData.is_admin,
        birthday: formData.birthday,
        personal_email: formData.personal_email,
        avatar_url: formData.avatar_url
      };

      if (isFirstUser) {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          throw new Error('No authenticated user found');
        }

        const { error } = await supabase
          .from('employee_profiles')
          .insert({
            ...dbData,
            user_id: user.id,
            profile_claimed: true
          });

        if (error) throw error;
        toast.success('Profile created successfully');
        onComplete?.();
      } else if (id) {
        const { error } = await supabase
          .from('employee_profiles')
          .update({
            ...dbData,
            updated_at: new Date().toISOString()
          })
          .eq('id', id);

        if (error) throw error;
        toast.success('Employee updated successfully');
        navigate('/employee/dashboard');
      } else {
        const { error } = await supabase
          .from('employee_profiles')
          .insert({
            ...dbData,
            user_id: null,
            profile_claimed: false
          });

        if (error) throw error;
        toast.success('Employee created successfully');
        navigate('/employee/dashboard');
      }
    } catch (error) {
      console.error('Error saving employee:', error);
      toast.error('Failed to save employee');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (field: keyof EmployeeFormData, value: any) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleNestedChange = (category: 'emergency_contacts' | 'medical_info' | 'preferences', field: string, value: string) => {
    if (category === 'emergency_contacts') {
      setFormData(prev => ({
        ...prev,
        emergency_contacts: [{
          ...prev.emergency_contacts[0],
          [field]: value
        }]
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [category]: {
          ...prev[category],
          [field]: value
        }
      }));
    }
  };

  const handleLocationChange = (value: string) => {
    if (value === 'Other') {
      setShowCustomLocation(true);
      setFormData(prev => ({ ...prev, location: null }));
    } else {
      setShowCustomLocation(false);
      setFormData(prev => ({ ...prev, location: value }));
    }
  };

  useEffect(() => {
    if (selectedDepartment && selectedDepartment !== 'Other') {
      setAvailableTitles(JOB_TITLES[selectedDepartment as keyof typeof JOB_TITLES] || []);
    }
  }, [selectedDepartment]);

  const handleDepartmentChange = (value: string) => {
    if (value === 'Other') {
      setShowCustomDepartment(true);
      setFormData(prev => ({ ...prev, department: '' }));
    } else {
      setShowCustomDepartment(false);
      setFormData(prev => ({ ...prev, department: value }));
    }
    setSelectedDepartment(value);
    
    if (value in JOB_TITLES) {
      setAvailableTitles(JOB_TITLES[value as keyof typeof JOB_TITLES]);
    } else {
      setAvailableTitles([]);
    }
  };

  const handleTitleChange = (value: string) => {
    if (value === 'Other') {
      setShowCustomTitle(true);
      setFormData(prev => ({ ...prev, title: '' }));
    } else {
      setShowCustomTitle(false);
      setFormData(prev => ({ ...prev, title: value }));
    }
  };

  const handleAllergyChange = (value: string) => {
    if (value === 'Other') {
      setShowCustomAllergy(true);
      setFormData(prev => ({
        ...prev,
        medical_info: { ...prev.medical_info, allergies: '' }
      }));
    } else {
      setShowCustomAllergy(false);
      setFormData(prev => ({
        ...prev,
        medical_info: { ...prev.medical_info, allergies: value }
      }));
    }
  };

  const handleDietaryChange = (value: string) => {
    if (value === 'Other') {
      setShowCustomDietary(true);
      setFormData(prev => ({
        ...prev,
        preferences: { ...prev.preferences, dietary: '' }
      }));
    } else {
      setShowCustomDietary(false);
      setFormData(prev => ({
        ...prev,
        preferences: { ...prev.preferences, dietary: value }
      }));
    }
  };

  const handleAccommodationChange = (value: string) => {
    if (value === 'Other') {
      setShowCustomAccommodation(true);
      setFormData(prev => ({
        ...prev,
        preferences: { ...prev.preferences, accommodations: '' }
      }));
    } else {
      setShowCustomAccommodation(false);
      setFormData(prev => ({
        ...prev,
        preferences: { ...prev.preferences, accommodations: value }
      }));
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <Card>
        <CardHeader>
          <CardTitle>
            {isFirstUser ? 'Create Your Profile' : id ? 'Edit Employee' : 'New Employee'}
          </CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-4">
              <h3 className="text-lg font-medium">Basic Information</h3>

              <div className="flex items-center gap-4">
                <Avatar className="w-20 h-20">
                  <AvatarImage 
                    src={formData.avatar_url || undefined} 
                    alt={`${formData.first_name} ${formData.last_name}`}
                  />
                  <AvatarFallback>
                    {formData.first_name?.[0]}{formData.last_name?.[0]}
                  </AvatarFallback>
                </Avatar>
                <div className="flex flex-col gap-2">
                  <Label htmlFor="avatar" className="cursor-pointer">
                    <div className="flex items-center gap-2 px-4 py-2 text-sm border rounded-md hover:bg-accent">
                      <Upload className="w-4 h-4" />
                      {uploadingAvatar ? 'Uploading...' : 'Upload Picture'}
                    </div>
                    <Input
                      id="avatar"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={handleAvatarUpload}
                      disabled={uploadingAvatar}
                    />
                  </Label>
                  <p className="text-xs text-muted-foreground">
                    Recommended: Square image, at least 200x200px
                  </p>
                </div>
              </div>

              <div className="space-y-2">
                <Label htmlFor="location">Office Location</Label>
                <Select
                  value={formData.location || ''}
                  onValueChange={(value) => handleChange('location', value)}
                >
                  <SelectTrigger className="w-full bg-background border">
                    <SelectValue placeholder="Select office location" />
                  </SelectTrigger>
                  <SelectContent className="bg-background border shadow-lg">
                    {LOCATIONS.map((location) => (
                      <SelectItem key={location} value={location} className="hover:bg-accent">
                        {location}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="space-y-2">
                <Label htmlFor="first_name">First Name</Label>
                <Input
                  id="first_name"
                  value={formData.first_name}
                  onChange={(e) => handleChange('first_name', e.target.value)}
                  required
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="last_name">Last Name</Label>
                <Input
                  id="last_name"
                  value={formData.last_name}
                  onChange={(e) => handleChange('last_name', e.target.value)}
                  required
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="department">Department</Label>
                <Select
                  value={showCustomDepartment ? 'Other' : formData.department}
                  onValueChange={handleDepartmentChange}
                >
                  <SelectTrigger className="w-full bg-background border">
                    <SelectValue placeholder="Select a department" />
                  </SelectTrigger>
                  <SelectContent className="bg-background border shadow-lg">
                    {DEPARTMENTS.map((dept) => (
                      <SelectItem key={dept} value={dept} className="hover:bg-accent">
                        {dept}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {showCustomDepartment && (
                  <Input
                    className="mt-2"
                    placeholder="Enter custom department"
                    value={formData.department}
                    onChange={(e) => handleChange('department', e.target.value)}
                  />
                )}
              </div>

              <div className="space-y-2">
                <Label htmlFor="title">Title</Label>
                <Select
                  value={showCustomTitle ? 'Other' : formData.title}
                  onValueChange={handleTitleChange}
                >
                  <SelectTrigger className="w-full bg-background border">
                    <SelectValue placeholder="Select a title" />
                  </SelectTrigger>
                  <SelectContent className="bg-background border shadow-lg">
                    {availableTitles.map((title) => (
                      <SelectItem key={title} value={title} className="hover:bg-accent">
                        {title}
                      </SelectItem>
                    ))}
                    <SelectItem value="Other" className="hover:bg-accent">Other</SelectItem>
                  </SelectContent>
                </Select>
                {showCustomTitle && (
                  <Input
                    className="mt-2"
                    placeholder="Enter custom title"
                    value={formData.title}
                    onChange={(e) => handleChange('title', e.target.value)}
                  />
                )}
              </div>

              <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  value={formData.email}
                  onChange={(e) => handleChange('email', e.target.value)}
                  required
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="work_phone">Work Phone</Label>
                <Input
                  id="work_phone"
                  value={formData.work_phone || ''}
                  onChange={(e) => handleChange('work_phone', e.target.value)}
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="personal_phone">Personal Phone</Label>
                <Input
                  id="personal_phone"
                  value={formData.personal_phone || ''}
                  onChange={(e) => handleChange('personal_phone', e.target.value)}
                />
              </div>
            </div>

            <div className="space-y-4">
              <h3 className="text-lg font-medium">Emergency Contact</h3>
              <div className="space-y-2">
                <Label htmlFor="emergency_name">Name</Label>
                <Input
                  id="emergency_name"
                  value={formData.emergency_contacts[0]?.name || ''}
                  onChange={(e) => handleNestedChange('emergency_contacts', 'name', e.target.value)}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="emergency_relationship">Relationship</Label>
                <Input
                  id="emergency_relationship"
                  value={formData.emergency_contacts[0]?.relationship || ''}
                  onChange={(e) => handleNestedChange('emergency_contacts', 'relationship', e.target.value)}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="emergency_phone">Phone</Label>
                <Input
                  id="emergency_phone"
                  value={formData.emergency_contacts[0]?.phone || ''}
                  onChange={(e) => handleNestedChange('emergency_contacts', 'phone', e.target.value)}
                />
              </div>
            </div>

            <div className="space-y-4">
              <h3 className="text-lg font-medium">Medical Information</h3>
              <div className="space-y-2">
                <Label htmlFor="allergies">Allergies</Label>
                <Select
                  value={showCustomAllergy ? 'Other' : formData.medical_info?.allergies || ''}
                  onValueChange={handleAllergyChange}
                >
                  <SelectTrigger className="w-full bg-background border">
                    <SelectValue placeholder="Select allergies" />
                  </SelectTrigger>
                  <SelectContent className="bg-background border shadow-lg">
                    {['None', 'Peanuts', 'Tree Nuts', 'Dairy', 'Eggs', 'Soy', 'Wheat', 'Fish', 'Shellfish', 'Other'].map((allergy) => (
                      <SelectItem key={allergy} value={allergy} className="hover:bg-accent">
                        {allergy}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {showCustomAllergy && (
                  <Input
                    className="mt-2"
                    placeholder="Enter custom allergies"
                    value={formData.medical_info?.allergies || ''}
                    onChange={(e) => handleNestedChange('medical_info', 'allergies', e.target.value)}
                  />
                )}
              </div>

              <div className="space-y-2">
                <Label htmlFor="conditions">Medical Conditions</Label>
                <Textarea
                  id="conditions"
                  value={formData.medical_info?.conditions || ''}
                  onChange={(e) => handleNestedChange('medical_info', 'conditions', e.target.value)}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="medications">Medications</Label>
                <Textarea
                  id="medications"
                  value={formData.medical_info?.medications || ''}
                  onChange={(e) => handleNestedChange('medical_info', 'medications', e.target.value)}
                />
              </div>
            </div>

            <div className="space-y-4">
              <h3 className="text-lg font-medium">Preferences</h3>
              <div className="space-y-2">
                <Label htmlFor="dietary">Dietary Preferences</Label>
                <Select
                  value={showCustomDietary ? 'Other' : formData.preferences?.dietary || ''}
                  onValueChange={handleDietaryChange}
                >
                  <SelectTrigger className="w-full bg-background border">
                    <SelectValue placeholder="Select dietary preference" />
                  </SelectTrigger>
                  <SelectContent className="bg-background border shadow-lg">
                    {['None', 'Vegetarian', 'Vegan', 'Gluten-Free', 'Kosher', 'Halal', 'Other'].map((pref) => (
                      <SelectItem key={pref} value={pref} className="hover:bg-accent">
                        {pref}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {showCustomDietary && (
                  <Input
                    className="mt-2"
                    placeholder="Enter custom dietary preference"
                    value={formData.preferences?.dietary || ''}
                    onChange={(e) => handleNestedChange('preferences', 'dietary', e.target.value)}
                  />
                )}
              </div>

              <div className="space-y-2">
                <Label htmlFor="accommodations">Special Accommodations</Label>
                <Select
                  value={showCustomAccommodation ? 'Other' : formData.preferences?.accommodations || ''}
                  onValueChange={handleAccommodationChange}
                >
                  <SelectTrigger className="w-full bg-background border">
                    <SelectValue placeholder="Select accommodation" />
                  </SelectTrigger>
                  <SelectContent className="bg-background border shadow-lg">
                    {['None Required', 'Flexible Work Hours', 'Remote Work', 'Ergonomic Equipment', 'Accessible Workspace', 'Visual Aids', 'Hearing Aids', 'Other'].map((acc) => (
                      <SelectItem key={acc} value={acc} className="hover:bg-accent">
                        {acc}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {showCustomAccommodation && (
                  <Input
                    className="mt-2"
                    placeholder="Enter custom accommodation"
                    value={formData.preferences?.accommodations || ''}
                    onChange={(e) => handleNestedChange('preferences', 'accommodations', e.target.value)}
                  />
                )}
              </div>
            </div>

            <div className="space-y-4">
              <h3 className="text-lg font-medium">Work Details</h3>
              <div className="space-y-2">
                <Label htmlFor="start_date">Start Date</Label>
                <Input
                  id="start_date"
                  type="date"
                  value={formData.start_date}
                  onChange={(e) => handleChange('start_date', e.target.value)}
                  required
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="reports_to">Reports To</Label>
                <Select
                  value={formData.reports_to || undefined}
                  onValueChange={(value) => handleChange('reports_to', value)}
                >
                  <SelectTrigger className="w-full bg-background border">
                    <SelectValue placeholder="Select a manager" />
                  </SelectTrigger>
                  <SelectContent className="bg-background border shadow-lg">
                    {managers.map((manager) => (
                      <SelectItem key={manager.id} value={manager.id} className="hover:bg-accent">
                        {manager.first_name} {manager.last_name} - {manager.title}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="flex items-center space-x-2">
                <Switch
                  id="is_admin"
                  checked={formData.is_admin}
                  onCheckedChange={(checked) => handleChange('is_admin', checked)}
                />
                <Label htmlFor="is_admin">Administrator Access</Label>
              </div>
            </div>

            <div className="flex justify-end space-x-4">
              {!isFirstUser && (
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => navigate('/employee/dashboard')}
                >
                  Cancel
                </Button>
              )}
              <Button type="submit" disabled={loading}>
                {loading ? 'Saving...' : (id ? 'Update' : 'Create')}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};
