import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Check, Loader2, BarChart, Database, Server, CircleSlash, ChevronsUp, Filter, Settings } from "lucide-react";
import { Checkbox } from "@/components/ui/checkbox";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { 
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger 
} from "@/components/ui/collapsible";
import { Textarea } from "@/components/ui/textarea";
import { AnalysisResults } from "./components/AnalysisResults";
import { BusinessChallengesInput } from "./components/BusinessChallengesInput";
import { IndustryTrendsCard } from "./components/IndustryTrendsCard";
import { ModernizationResources } from "./components/ModernizationResources";
import { isWorkEmail } from "@/utils/validation";

const ENTERPRISE_SYSTEMS = {
  ERP: [
    { id: "sap", name: "SAP", description: "Enterprise resource planning", vendor: "SAP", complexity: "High", cloudReady: true },
    { id: "oracle", name: "Oracle ERP", description: "Enterprise resource planning", vendor: "Oracle", complexity: "High", cloudReady: true },
    { id: "microsoft_dynamics", name: "Microsoft Dynamics", description: "Enterprise resource planning", vendor: "Microsoft", complexity: "Medium", cloudReady: true },
    { id: "netsuite", name: "NetSuite", description: "Cloud ERP solution", vendor: "Oracle", complexity: "Medium", cloudReady: true },
    { id: "sage", name: "Sage", description: "Business management", vendor: "Sage", complexity: "Medium", cloudReady: true },
    { id: "infor", name: "Infor CloudSuite", description: "Industry-specific ERP", vendor: "Infor", complexity: "High", cloudReady: true },
  ],
  CRM: [
    { id: "salesforce", name: "Salesforce", description: "Customer relationship management", vendor: "Salesforce", complexity: "Medium", cloudReady: true },
    { id: "hubspot", name: "HubSpot", description: "CRM platform", vendor: "HubSpot", complexity: "Low", cloudReady: true },
    { id: "zoho_crm", name: "Zoho CRM", description: "Customer relationship management", vendor: "Zoho", complexity: "Low", cloudReady: true },
    { id: "microsoft_dynamics_crm", name: "Microsoft Dynamics CRM", description: "Customer engagement", vendor: "Microsoft", complexity: "Medium", cloudReady: true },
    { id: "sugarcrm", name: "SugarCRM", description: "Customer experience platform", vendor: "SugarCRM", complexity: "Medium", cloudReady: true },
    { id: "pipedrive", name: "Pipedrive", description: "Sales CRM", vendor: "Pipedrive", complexity: "Low", cloudReady: true },
  ],
  HRMS: [
    { id: "workday", name: "Workday", description: "Human capital management", vendor: "Workday", complexity: "High", cloudReady: true },
    { id: "sap_successfactors", name: "SAP SuccessFactors", description: "Human resource management", vendor: "SAP", complexity: "High", cloudReady: true },
    { id: "adp", name: "ADP", description: "Payroll and HR solutions", vendor: "ADP", complexity: "Medium", cloudReady: true },
    { id: "bamboo_hr", name: "BambooHR", description: "HR software for small and medium businesses", vendor: "BambooHR", complexity: "Low", cloudReady: true },
    { id: "oracle_hcm", name: "Oracle HCM Cloud", description: "HR and talent management", vendor: "Oracle", complexity: "High", cloudReady: true },
    { id: "dayforce", name: "Ceridian Dayforce", description: "Human capital management", vendor: "Ceridian", complexity: "Medium", cloudReady: true },
    { id: "gusto", name: "Gusto", description: "Payroll, benefits, and HR", vendor: "Gusto", complexity: "Low", cloudReady: true },
    { id: "zenefits", name: "Zenefits", description: "HR, payroll, and benefits", vendor: "Zenefits", complexity: "Medium", cloudReady: true },
  ],
  ProjectManagement: [
    { id: "clickup", name: "ClickUp", description: "Project management and productivity", vendor: "ClickUp", complexity: "Low", cloudReady: true },
    { id: "asana", name: "Asana", description: "Work management platform", vendor: "Asana", complexity: "Low", cloudReady: true },
    { id: "jira", name: "Jira", description: "Project and issue tracking", vendor: "Atlassian", complexity: "Medium", cloudReady: true },
    { id: "monday", name: "Monday.com", description: "Work OS platform", vendor: "Monday.com", complexity: "Low", cloudReady: true },
    { id: "trello", name: "Trello", description: "Visual collaboration tool", vendor: "Atlassian", complexity: "Low", cloudReady: true },
    { id: "smartsheet", name: "Smartsheet", description: "Work management platform", vendor: "Smartsheet", complexity: "Medium", cloudReady: true },
  ],
  DataManagement: [
    { id: "oracle_database", name: "Oracle Database", description: "Enterprise database", vendor: "Oracle", complexity: "High", cloudReady: true },
    { id: "microsoft_sql", name: "Microsoft SQL Server", description: "Relational database", vendor: "Microsoft", complexity: "Medium", cloudReady: true },
    { id: "ibm_db2", name: "IBM Db2", description: "Data management", vendor: "IBM", complexity: "High", cloudReady: false },
    { id: "snowflake", name: "Snowflake", description: "Cloud data platform", vendor: "Snowflake", complexity: "Medium", cloudReady: true },
    { id: "mongodb", name: "MongoDB", description: "NoSQL database", vendor: "MongoDB", complexity: "Medium", cloudReady: true },
    { id: "postgresql", name: "PostgreSQL", description: "Open-source database", vendor: "PostgreSQL Global Development Group", complexity: "Medium", cloudReady: true },
    { id: "aws_rds", name: "AWS RDS", description: "Managed relational database service", vendor: "Amazon", complexity: "Medium", cloudReady: true },
  ],
  Communications: [
    { id: "microsoft_teams", name: "Microsoft Teams", description: "Collaboration platform", vendor: "Microsoft", complexity: "Low", cloudReady: true },
    { id: "slack", name: "Slack", description: "Business communication platform", vendor: "Salesforce", complexity: "Low", cloudReady: true },
    { id: "zoom", name: "Zoom", description: "Video conferencing", vendor: "Zoom", complexity: "Low", cloudReady: true },
    { id: "cisco_webex", name: "Cisco Webex", description: "Conferencing and collaboration", vendor: "Cisco", complexity: "Medium", cloudReady: true },
    { id: "google_workspace", name: "Google Workspace", description: "Productivity and collaboration", vendor: "Google", complexity: "Low", cloudReady: true },
    { id: "ringcentral", name: "RingCentral", description: "Cloud communications", vendor: "RingCentral", complexity: "Medium", cloudReady: true },
  ],
  Security: [
    { id: "okta", name: "Okta", description: "Identity management", vendor: "Okta", complexity: "Medium", cloudReady: true },
    { id: "crowdstrike", name: "CrowdStrike", description: "Endpoint protection", vendor: "CrowdStrike", complexity: "High", cloudReady: true },
    { id: "palo_alto", name: "Palo Alto Networks", description: "Network security", vendor: "Palo Alto Networks", complexity: "High", cloudReady: true },
    { id: "azure_active_directory", name: "Azure Active Directory", description: "Identity management", vendor: "Microsoft", complexity: "Medium", cloudReady: true },
    { id: "splunk", name: "Splunk", description: "Security analytics", vendor: "Splunk", complexity: "High", cloudReady: true },
  ],
  Other: [
    { id: "sap_concur", name: "SAP Concur", description: "Travel and expense management", vendor: "SAP", complexity: "Medium", cloudReady: true },
    { id: "servicenow", name: "ServiceNow", description: "IT service management", vendor: "ServiceNow", complexity: "High", cloudReady: true },
    { id: "tableau", name: "Tableau", description: "Business intelligence", vendor: "Salesforce", complexity: "Medium", cloudReady: true },
    { id: "power_bi", name: "Power BI", description: "Business analytics", vendor: "Microsoft", complexity: "Medium", cloudReady: true },
    { id: "aws_lambda", name: "AWS Lambda", description: "Serverless computing", vendor: "Amazon", complexity: "Medium", cloudReady: true },
    { id: "azure_functions", name: "Azure Functions", description: "Serverless computing", vendor: "Microsoft", complexity: "Medium", cloudReady: true },
  ],
};

const INDUSTRY_SYSTEMS = {
  finance: ["sap", "oracle", "workday", "salesforce", "tableau", "oracle_database"],
  healthcare: ["epic", "cerner", "allscripts", "oracle_database", "salesforce", "workday"],
  manufacturing: ["sap", "oracle", "infor", "siemens_plm", "autodesk", "microsoft_dynamics"],
  retail: ["oracle_retail", "sap", "microsoft_dynamics", "salesforce", "shopify", "netsuite"],
  government: ["sap", "oracle", "workday", "servicenow", "splunk", "tableau"],
};

export const EnterpriseSystemAnalyzer = () => {
  const [selectedSystems, setSelectedSystems] = useState<Record<string, boolean>>({});
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState<string | null>(null);
  const [businessChallenges, setBusinessChallenges] = useState("");
  const [companySize, setCompanySize] = useState<string>("");
  const [industry, setIndustry] = useState<string>("");
  const [deploymentPreference, setDeploymentPreference] = useState<string>("");
  const [integrationComplexity, setIntegrationComplexity] = useState<string>("");
  const [budgetRange, setBudgetRange] = useState<string>("");
  const [timeframe, setTimeframe] = useState<string>("");
  const [analysisResult, setAnalysisResult] = useState<any>(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [currentStep, setCurrentStep] = useState<string | null>(null);
  
  const handleSystemToggle = (systemId: string) => {
    setSelectedSystems(prev => ({
      ...prev,
      [systemId]: !prev[systemId]
    }));
  };

  const countSelectedSystems = () => {
    return Object.values(selectedSystems).filter(Boolean).length;
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    
    if (value) {
      if (!isWorkEmail(value)) {
        setEmailError("Please enter a work email. Personal email domains are not accepted.");
      } else {
        setEmailError(null);
      }
    } else {
      setEmailError(null);
    }
  };

  const generateAnalysis = async () => {
    const selectedSystemIds = Object.entries(selectedSystems)
      .filter(([_, isSelected]) => isSelected)
      .map(([id, _]) => id);

    if (selectedSystemIds.length === 0) {
      toast.error("Please select at least one enterprise system to analyze");
      return;
    }

    if (!email) {
      toast.error("Please enter your work email to receive the detailed analysis");
      return;
    }

    if (!isWorkEmail(email)) {
      toast.error("Please enter a valid work email. Personal email domains are not accepted.");
      return;
    }

    if (!companySize) {
      toast.error("Please select your organization size");
      return;
    }

    if (!industry) {
      toast.error("Please select your industry");
      return;
    }

    setIsAnalyzing(true);
    setCurrentStep("Gathering system information...");

    try {
      await new Promise(resolve => setTimeout(resolve, 1500));
      setCurrentStep("Analyzing integration opportunities...");

      await new Promise(resolve => setTimeout(resolve, 1800));
      setCurrentStep("Evaluating AI automation possibilities...");

      await new Promise(resolve => setTimeout(resolve, 2000));
      setCurrentStep("Generating custom solution blueprint...");

      const analysisParams = {
        selectedSystems: selectedSystemIds,
        email,
        companySize,
        industry,
        businessChallenges,
        ...(deploymentPreference && { deploymentPreference }),
        ...(integrationComplexity && { integrationComplexity }),
        ...(budgetRange && { budgetRange }),
        ...(timeframe && { timeframe }),
      };

      const { data, error } = await supabase.functions.invoke('analyze-enterprise-systems', {
        body: analysisParams
      });

      if (error) throw error;

      setAnalysisResult(data);
      setIsCompleted(true);
      
      const { error: insertError } = await supabase
        .from("enterprise_analyses")
        .insert({
          systems: selectedSystemIds,
          company_size: companySize,
          industry,
          email: email,
          business_challenges: businessChallenges,
          analysis_result: data,
          deployment_preference: deploymentPreference || null,
          integration_complexity: integrationComplexity || null,
          budget_range: budgetRange || null,
          timeframe: timeframe || null
        });

      if (insertError) {
        console.error("Error saving analysis:", insertError);
      }

      toast.success("Your enterprise systems analysis is complete!");
    } catch (error) {
      console.error('Error generating analysis:', error);
      toast.error("Failed to generate analysis. Please try again.");
    } finally {
      setIsAnalyzing(false);
      setCurrentStep(null);
    }
  };

  const resetAnalysis = () => {
    setSelectedSystems({});
    setEmail("");
    setEmailError(null);
    setBusinessChallenges("");
    setCompanySize("");
    setIndustry("");
    setDeploymentPreference("");
    setIntegrationComplexity("");
    setBudgetRange("");
    setTimeframe("");
    setAnalysisResult(null);
    setIsCompleted(false);
  };

  const renderSystemSelectionCards = () => {
    return Object.entries(ENTERPRISE_SYSTEMS).map(([category, systems]) => {
      return (
        <div key={category} className="mb-8">
          <h3 className="text-xl font-semibold text-blue-300 mb-4">{category} Systems</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
            {systems.map(system => (
              <Card
                key={system.id}
                className={`p-4 cursor-pointer border ${
                  selectedSystems[system.id] 
                    ? "border-blue-500 bg-blue-900/20" 
                    : "border-gray-700 bg-transparent hover:border-gray-500"
                } transition-all duration-200`}
                onClick={() => handleSystemToggle(system.id)}
              >
                <div className="flex items-center space-x-3">
                  <Checkbox 
                    checked={!!selectedSystems[system.id]} 
                    onCheckedChange={() => handleSystemToggle(system.id)}
                    className="border-blue-500 data-[state=checked]:bg-blue-500"
                  />
                  <div className="flex-1">
                    <h4 className="text-white font-medium">{system.name}</h4>
                    <p className="text-gray-400 text-sm">{system.description}</p>
                    <div className="flex flex-wrap gap-2 mt-1">
                      <span className="text-xs bg-blue-900/30 text-blue-300 px-2 py-0.5 rounded">
                        {system.vendor}
                      </span>
                      <span className={`text-xs px-2 py-0.5 rounded ${
                        system.complexity === "Low" 
                          ? "bg-green-900/30 text-green-300" 
                          : system.complexity === "Medium"
                            ? "bg-yellow-900/30 text-yellow-300"
                            : "bg-red-900/30 text-red-300"
                      }`}>
                        {system.complexity} Complexity
                      </span>
                      {system.cloudReady && (
                        <span className="text-xs bg-indigo-900/30 text-indigo-300 px-2 py-0.5 rounded">
                          Cloud Ready
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>
      );
    });
  };

  const renderAnalysisForm = () => {
    return (
      <div className="space-y-6 mt-8">
        <div className="space-y-4">
          <label className="text-sm text-gray-300">What business challenges are you trying to solve?</label>
          <Textarea
            placeholder="Describe the specific problems your organization faces (e.g., legacy system integration issues, data silos, compliance requirements, scalability challenges)"
            value={businessChallenges}
            onChange={(e) => setBusinessChallenges(e.target.value)}
            className="min-h-[100px] bg-gray-800/50 border-gray-700 text-white"
          />
          <p className="text-xs text-gray-400">This helps us tailor our analysis to your specific needs</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-2">
            <label htmlFor="companySize" className="text-sm text-gray-300">Organization Size</label>
            <Select value={companySize} onValueChange={setCompanySize}>
              <SelectTrigger className="w-full bg-gray-800/50 border-gray-700">
                <SelectValue placeholder="Select organization size" />
              </SelectTrigger>
              <SelectContent className="bg-gray-800 border-gray-700">
                <SelectItem value="small">Small (1-50 employees)</SelectItem>
                <SelectItem value="medium">Medium (51-500 employees)</SelectItem>
                <SelectItem value="large">Large (501-5000 employees)</SelectItem>
                <SelectItem value="enterprise">Enterprise (5000+ employees)</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-2">
            <label htmlFor="industry" className="text-sm text-gray-300">Industry</label>
            <Select value={industry} onValueChange={setIndustry}>
              <SelectTrigger className="w-full bg-gray-800/50 border-gray-700">
                <SelectValue placeholder="Select your industry" />
              </SelectTrigger>
              <SelectContent className="bg-gray-800 border-gray-700">
                <SelectItem value="finance">Finance & Banking</SelectItem>
                <SelectItem value="healthcare">Healthcare</SelectItem>
                <SelectItem value="manufacturing">Manufacturing</SelectItem>
                <SelectItem value="retail">Retail</SelectItem>
                <SelectItem value="technology">Technology</SelectItem>
                <SelectItem value="government">Government</SelectItem>
                <SelectItem value="education">Education</SelectItem>
                <SelectItem value="other">Other</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        
        <Collapsible open={false} onOpenChange={() => {}}>
          <CollapsibleTrigger asChild>
            <Button variant="ghost" className="w-full justify-between border border-blue-800/20 bg-blue-950/10 text-blue-400 hover:text-blue-300">
              Advanced Options
              <Settings className="h-4 w-4 ml-2" />
            </Button>
          </CollapsibleTrigger>
          <CollapsibleContent className="pt-4 space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-2">
                <label className="text-sm text-gray-300">Deployment Preference</label>
                <Select value={deploymentPreference} onValueChange={setDeploymentPreference}>
                  <SelectTrigger className="w-full bg-gray-800/50 border-gray-700">
                    <SelectValue placeholder="Select deployment preference" />
                  </SelectTrigger>
                  <SelectContent className="bg-gray-800 border-gray-700">
                    <SelectItem value="cloud">Cloud-First</SelectItem>
                    <SelectItem value="hybrid">Hybrid Cloud/On-Premise</SelectItem>
                    <SelectItem value="onpremise">On-Premise Only</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <label className="text-sm text-gray-300">Integration Complexity Tolerance</label>
                <Select value={integrationComplexity} onValueChange={setIntegrationComplexity}>
                  <SelectTrigger className="w-full bg-gray-800/50 border-gray-700">
                    <SelectValue placeholder="Select complexity tolerance" />
                  </SelectTrigger>
                  <SelectContent className="bg-gray-800 border-gray-700">
                    <SelectItem value="simple">Simple (Limited Customization)</SelectItem>
                    <SelectItem value="moderate">Moderate (Some Custom Development)</SelectItem>
                    <SelectItem value="complex">Complex (Custom Development)</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-2">
                <label className="text-sm text-gray-300">Budget Range</label>
                <Select value={budgetRange} onValueChange={setBudgetRange}>
                  <SelectTrigger className="w-full bg-gray-800/50 border-gray-700">
                    <SelectValue placeholder="Select budget range" />
                  </SelectTrigger>
                  <SelectContent className="bg-gray-800 border-gray-700">
                    <SelectItem value="low">Less than $100,000</SelectItem>
                    <SelectItem value="medium">$100,000 - $500,000</SelectItem>
                    <SelectItem value="high">$500,000 - $1,000,000</SelectItem>
                    <SelectItem value="enterprise">$1,000,000+</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <label className="text-sm text-gray-300">Implementation Timeframe</label>
                <Select value={timeframe} onValueChange={setTimeframe}>
                  <SelectTrigger className="w-full bg-gray-800/50 border-gray-700">
                    <SelectValue placeholder="Select timeframe" />
                  </SelectTrigger>
                  <SelectContent className="bg-gray-800 border-gray-700">
                    <SelectItem value="urgent">Urgent (1-3 months)</SelectItem>
                    <SelectItem value="standard">Standard (3-6 months)</SelectItem>
                    <SelectItem value="extended">Extended (6-12 months)</SelectItem>
                    <SelectItem value="phased">Multi-Phase (12+ months)</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </CollapsibleContent>
        </Collapsible>
        
        <div className="space-y-2">
          <label htmlFor="email" className="text-sm text-gray-300">Work Email for Report Delivery</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="your@company.com"
            className={`w-full p-3 rounded-md bg-gray-800/50 border ${
              emailError ? 'border-red-400' : 'border-gray-700'
            } text-white`}
          />
          {emailError && (
            <p className="text-xs text-red-400">{emailError}</p>
          )}
          <p className="text-xs text-gray-400">We'll send a detailed PDF report to your work email</p>
        </div>
        
        <Button 
          className="w-full bg-blue-600 hover:bg-blue-700 text-white py-5 text-lg"
          onClick={generateAnalysis}
          disabled={isAnalyzing || countSelectedSystems() === 0 || !!emailError}
        >
          {isAnalyzing ? (
            <>
              <Loader2 className="w-4 h-4 mr-2 animate-spin" />
              Analyzing...
            </>
          ) : (
            <>
              {countSelectedSystems() === 0 ? "Select Systems to Analyze" : `Analyze ${countSelectedSystems()} Selected Systems`}
            </>
          )}
        </Button>
      </div>
    );
  };

  return (
    <div className="max-w-5xl mx-auto">
      {!isCompleted ? (
        <>
          <div className="mb-8">
            <div className="inline-flex items-center px-4 py-2 bg-blue-900/30 text-blue-300 rounded-full text-sm font-medium mb-4">
              <Server className="w-4 h-4 mr-2" />
              Enterprise Systems Modernization Analyzer
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
              Select Your Current Enterprise Systems
            </h2>
            <p className="text-gray-300 text-lg">
              Choose all the enterprise systems your organization currently uses. Our AI will analyze your ecosystem and recommend a tailored modernization strategy with custom integrations and AI automation opportunities.
            </p>
          </div>

          {isAnalyzing && currentStep && (
            <div className="mb-8">
              <div className="h-1 w-full bg-gray-700 rounded-full overflow-hidden">
                <div className="h-full bg-blue-500 animate-pulse rounded-full" />
              </div>
              <p className="text-sm text-gray-400 mt-2">{currentStep}</p>
            </div>
          )}

          {renderSystemSelectionCards()}
          {countSelectedSystems() > 0 && renderAnalysisForm()}
        </>
      ) : (
        <>
          <AnalysisResults 
            analysisResult={analysisResult}
            email={email}
            resetAnalysis={resetAnalysis}
          />
        </>
      )}
    </div>
  );
};
