
import { motion } from "framer-motion";
import { ArrowRight, Network, Cpu, Database } from "lucide-react";
import { Button } from "@/components/ui/button";

export const EnterpriseJourney = () => {
  return (
    <div className="space-y-24">
      {/* Enterprise Value Proposition */}
      <section className="py-24">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="max-w-4xl mx-auto text-center"
          >
            <h2 className="text-4xl md:text-5xl font-display font-bold mb-6 text-white">
              Purpose-Built Enterprise Solutions
            </h2>
            <p className="text-xl text-gray-300 mb-12">
              Transition from disparate systems to a unified platform that provides centralized control and enhanced efficiency.
            </p>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="p-6 border border-blue-900/30 rounded-xl">
                <Network className="w-10 h-10 text-blue-500 mb-4" />
                <h3 className="text-lg font-bold mb-2 text-white">System Consolidation</h3>
                <p className="text-gray-300">Replace multiple tools with one purpose-built solution</p>
              </div>
              <div className="p-6 border border-blue-900/30 rounded-xl">
                <Cpu className="w-10 h-10 text-blue-500 mb-4" />
                <h3 className="text-lg font-bold mb-2 text-white">AI-Powered Automation</h3>
                <p className="text-gray-300">Streamline operations with intelligent workflows</p>
              </div>
              <div className="p-6 border border-blue-900/30 rounded-xl">
                <Database className="w-10 h-10 text-blue-500 mb-4" />
                <h3 className="text-lg font-bold mb-2 text-white">Centralized Control</h3>
                <p className="text-gray-300">Gain complete visibility and governance</p>
              </div>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Success Metrics Section */}
      <section className="py-24 border-t border-blue-900/30">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-3xl font-display font-bold mb-8 text-center text-white">
              Transformation Success Metrics
            </h2>
            <div className="grid md:grid-cols-3 gap-6">
              <div className="text-center p-6">
                <div className="text-4xl font-bold text-blue-500 mb-2">67%</div>
                <p className="text-gray-300">Reduction in operational complexity</p>
              </div>
              <div className="text-center p-6">
                <div className="text-4xl font-bold text-blue-500 mb-2">82%</div>
                <p className="text-gray-300">Improvement in data accessibility</p>
              </div>
              <div className="text-center p-6">
                <div className="text-4xl font-bold text-blue-500 mb-2">43%</div>
                <p className="text-gray-300">Increase in operational efficiency</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
