
import { Routes, Route, Navigate, useParams, useLocation } from 'react-router-dom';
import { FlareStartPage } from '@/components/programs/flare/FlareStartPage';
import { FlareDesignCanvas } from '@/components/programs/flare/FlareDesignCanvas';
import { FlareContent } from '@/components/programs/FlareContent';
import { Header } from '@/components/Header';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { PasswordProtection } from '@/components/programs/flare/PasswordProtection';

function DesignRoute() {
  const { designId } = useParams();
  return (
    <ErrorBoundary>
      <PasswordProtection>
        <FlareDesignCanvas designId={designId} />
      </PasswordProtection>
    </ErrorBoundary>
  );
}

export default function FlarePage() {
  const location = useLocation();
  
  // Check if the current path includes '/flare/canvas' to hide the header
  const isCanvasPage = location.pathname.includes('/flare/canvas');
  
  return (
    <div className={isCanvasPage ? '' : 'pt-14 sm:pt-16 md:pt-20'}>
      {!isCanvasPage && <Header />}
      <ErrorBoundary>
        <Routes>
          <Route path="/" element={<FlareContent />} />
          <Route path="canvas" element={
            <PasswordProtection>
              <FlareStartPage />
            </PasswordProtection>
          } />
          <Route path="canvas/design/:designId" element={<DesignRoute />} />
          <Route path="canvas/new" element={
            <PasswordProtection>
              <FlareDesignCanvas />
            </PasswordProtection>
          } />
          <Route path="*" element={<Navigate to="/flare" replace />} />
        </Routes>
      </ErrorBoundary>
    </div>
  );
}
