
import { Task } from '@/types/task';

interface TaskBoardProps {
  tasks: Task[];
  onTaskClick: (task: Task) => void;
}

export function TaskBoard({ tasks, onTaskClick }: TaskBoardProps) {
  const columns = {
    todo: tasks.filter(task => task.status === 'todo'),
    in_progress: tasks.filter(task => task.status === 'in_progress'),
    done: tasks.filter(task => task.status === 'done'),
    blocked: tasks.filter(task => task.status === 'blocked'),
  };

  return (
    <div className="grid grid-cols-4 gap-4">
      {Object.entries(columns).map(([status, statusTasks]) => (
        <div key={status} className="space-y-4">
          <div className="flex items-center justify-between">
            <h3 className="font-medium capitalize">{status.replace('_', ' ')}</h3>
            <span className="text-sm text-muted-foreground">{statusTasks.length}</span>
          </div>
          <div className="space-y-2">
            {statusTasks.map(task => (
              <button
                key={task.id}
                onClick={() => onTaskClick(task)}
                className="w-full text-left p-4 bg-background border rounded-lg hover:bg-accent/50 transition-colors"
              >
                <h4 className="font-medium">{task.title}</h4>
                {task.description && (
                  <p className="text-sm text-muted-foreground truncate mt-1">
                    {task.description}
                  </p>
                )}
              </button>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
