
import { BlogPost as BlogPostType } from "@/types/blog";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ArrowRight, Clock, User } from "lucide-react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useState } from "react";

interface BlogPostProps {
  post: BlogPostType;
  index: number;
}

export const BlogPost = ({ post, index }: BlogPostProps) => {
  const [imageError, setImageError] = useState(false);
  
  // Fallback image in case the one from post doesn't load
  const fallbackImage = "/lovable-uploads/e371f263-27ef-4fc0-a6ec-86c9e23d904f.png";
  
  const imageUrl = imageError 
    ? fallbackImage 
    : (post.image_url.startsWith('http') ? post.image_url : `${window.location.origin}${post.image_url}`);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1 }}
      className="h-full"
    >
      <Card className="h-full overflow-hidden hover:shadow-lg transition-shadow duration-300 bg-card dark:bg-card/80 dark:border-accent">
        <div className="aspect-video relative overflow-hidden">
          <img
            src={imageUrl}
            alt={post.title}
            className="object-cover w-full h-full transform hover:scale-105 transition-transform duration-500"
            onError={(e) => {
              console.error('Image failed to load:', post.image_url);
              setImageError(true);
            }}
          />
        </div>
        <div className="p-6">
          <div className="flex flex-wrap gap-2 mb-4">
            {post.tags?.map((tag, tagIndex) => (
              <span
                key={tagIndex}
                className="text-xs font-medium px-2 py-1 rounded-full bg-secondary/10 text-secondary dark:bg-secondary/20"
              >
                {tag}
              </span>
            ))}
          </div>
          <h3 className="text-xl font-display font-bold mb-3 text-foreground hover:text-secondary transition-colors">
            {post.title}
          </h3>
          <p className="text-muted-foreground mb-4">
            {post.excerpt}
          </p>
          <div className="flex items-center justify-between text-sm text-muted-foreground mb-4">
            <div className="flex items-center">
              <User className="w-4 h-4 mr-1" />
              {post.author}
            </div>
            <div className="flex items-center">
              <Clock className="w-4 h-4 mr-1" />
              {post.read_time}
            </div>
          </div>
          <Link to={`/blog/${post.slug}`} className="block">
            <Button
              variant="ghost"
              className="w-full justify-between text-secondary hover:text-secondary/80 hover:bg-secondary/5 dark:hover:bg-secondary/10"
            >
              Read More <ArrowRight className="w-4 h-4 ml-2" />
            </Button>
          </Link>
        </div>
      </Card>
    </motion.div>
  );
};
