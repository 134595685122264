import { memo } from 'react';
import { Handle, Position } from '@xyflow/react';
import { CircleDollarSign } from 'lucide-react';

interface StageNodeData {
  label: string;
  color: string;
  features: {
    id: string;
    data: {
      points?: number;
    };
  }[];
}

export const StageNode = memo(({ data }: { data: StageNodeData }) => {
  const totalPoints = data.features.reduce((sum, feature) => sum + (feature.data.points || 0), 0);

  return (
    <div className="relative">
      <Handle
        type="target"
        position={Position.Left}
        className="w-4 h-4 !bg-background border-2 border-secondary rounded-full"
      />
      <div 
        className="w-[200px] min-h-[100px] rounded-xl p-4 transition-shadow duration-200"
        style={{
          backgroundColor: `${data.color}10`,
          borderColor: `${data.color}30`,
          border: '1px solid',
        }}
      >
        <h3 
          className="text-xl font-display font-bold mb-4"
          style={{ color: data.color }}
        >
          {data.label}
        </h3>
        <div className="space-y-1">
          <div className="text-sm text-muted-foreground">
            {data.features.length} feature{data.features.length !== 1 ? 's' : ''}
          </div>
          <div className="text-sm text-muted-foreground flex items-center gap-1">
            <CircleDollarSign className="h-3 w-3" />
            {totalPoints} total points
          </div>
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Right}
        className="w-4 h-4 !bg-background border-2 border-secondary rounded-full"
      />
    </div>
  );
});

StageNode.displayName = 'StageNode';