import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Mail, Phone, Building2, Activity } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

interface ContactCardProps {
  contact: any;
}

export const ContactCard = ({ contact }: ContactCardProps) => {
  const navigate = useNavigate();

  const getEngagementColor = (score: number) => {
    if (score >= 75) return "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200";
    if (score >= 50) return "bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200";
    return "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-200";
  };

  const handleViewDetails = () => {
    console.log("Navigating to contact:", contact.id);
    try {
      navigate(`/admin/contacts/${contact.id}`);
    } catch (error) {
      console.error("Navigation error:", error);
      toast.error("Failed to navigate to contact details");
    }
  };

  const handleEmailClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = `mailto:${contact.email}`;
  };

  const handlePhoneClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = `tel:${contact.phone}`;
  };

  return (
    <Card className="p-6 hover:shadow-md transition-shadow cursor-pointer bg-card">
      <div className="space-y-4">
        <div className="flex items-start justify-between">
          <div>
            <h3 className="text-lg font-semibold">
              {contact.first_name} {contact.last_name}
            </h3>
            {contact.job_title && (
              <p className="text-sm text-muted-foreground">{contact.job_title}</p>
            )}
          </div>
          <Badge className={getEngagementColor(contact.engagement_score || 0)}>
            <Activity className="w-3 h-3 mr-1" />
            {contact.engagement_score || 0}
          </Badge>
        </div>

        <div className="space-y-2">
          {contact.email && (
            <div className="flex items-center gap-2 text-sm">
              <Mail className="w-4 h-4 text-muted-foreground" />
              <a
                href={`mailto:${contact.email}`}
                className="hover:text-primary transition-colors"
                onClick={handleEmailClick}
              >
                {contact.email}
              </a>
            </div>
          )}
          {contact.phone && (
            <div className="flex items-center gap-2 text-sm">
              <Phone className="w-4 h-4 text-muted-foreground" />
              <a
                href={`tel:${contact.phone}`}
                className="hover:text-primary transition-colors"
                onClick={handlePhoneClick}
              >
                {contact.phone}
              </a>
            </div>
          )}
          {contact.company && (
            <div className="flex items-center gap-2 text-sm">
              <Building2 className="w-4 h-4 text-muted-foreground" />
              <span>{contact.company.name}</span>
            </div>
          )}
        </div>

        <div className="flex flex-wrap gap-2">
          {contact.tags?.map((tag: string) => (
            <Badge key={tag} variant="secondary" className="text-xs">
              {tag}
            </Badge>
          ))}
        </div>

        <Button
          variant="outline"
          className="w-full"
          onClick={handleViewDetails}
        >
          View Details
        </Button>
      </div>
    </Card>
  );
};