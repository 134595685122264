import { useQuery } from "@tanstack/react-query";
import { Card } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { supabase } from "@/integrations/supabase/client";

export const LeadScoring = () => {
  const { data: scoringRules } = useQuery({
    queryKey: ["lead-scoring-rules"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("lead_scoring_rules")
        .select("*");
      
      if (error) throw error;
      return data;
    },
  });

  const { data: leadScores } = useQuery({
    queryKey: ["lead-scores"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("leads")
        .select("lead_score")
        .not("lead_score", "is", null);
      
      if (error) throw error;
      return data;
    },
  });

  const getScoreDistribution = () => {
    const distribution = {
      low: 0,
      medium: 0,
      high: 0,
    };

    leadScores?.forEach(({ lead_score }) => {
      if (lead_score < 30) distribution.low++;
      else if (lead_score < 70) distribution.medium++;
      else distribution.high++;
    });

    return distribution;
  };

  const distribution = getScoreDistribution();
  const total = leadScores?.length || 0;

  return (
    <div className="grid gap-6 md:grid-cols-2">
      <Card className="p-6">
        <h3 className="text-lg font-semibold mb-4">Score Distribution</h3>
        
        <div className="space-y-4">
          <div>
            <div className="flex justify-between mb-2">
              <span>High Quality (70-100)</span>
              <span>{Math.round((distribution.high / total) * 100)}%</span>
            </div>
            <Progress value={(distribution.high / total) * 100} className="h-2" />
          </div>
          
          <div>
            <div className="flex justify-between mb-2">
              <span>Medium Quality (30-69)</span>
              <span>{Math.round((distribution.medium / total) * 100)}%</span>
            </div>
            <Progress value={(distribution.medium / total) * 100} className="h-2" />
          </div>
          
          <div>
            <div className="flex justify-between mb-2">
              <span>Low Quality (0-29)</span>
              <span>{Math.round((distribution.low / total) * 100)}%</span>
            </div>
            <Progress value={(distribution.low / total) * 100} className="h-2" />
          </div>
        </div>
      </Card>

      <Card className="p-6">
        <h3 className="text-lg font-semibold mb-4">Scoring Rules</h3>
        
        <div className="space-y-4">
          {scoringRules?.map((rule) => (
            <div key={rule.id} className="border-b border-gray-100 pb-4">
              <h4 className="font-medium">{rule.rule_name}</h4>
              <p className="text-sm text-muted-foreground">
                Weight: {rule.score_weight} points
              </p>
              <div className="mt-2 text-sm">
                {Object.entries(rule.criteria).map(([key, value]) => (
                  <div key={key} className="text-muted-foreground">
                    • {key}: {value as string}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
};