
import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Button } from "@/components/ui/button";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { TaskForm } from "./components/TaskForm";
import { TaskList } from "./components/TaskList";
import { type Database } from "@/types/supabase";

type TaskBase = Database['public']['Tables']['tasks']['Row'];
type AdminProfile = Database['public']['Tables']['users_metadata']['Row'];

type TaskResponse = Omit<TaskBase, 'user_id'> & {
  admin_profiles: Pick<AdminProfile, 'name'> | null;
};

interface DealTasksProps {
  dealId: string;
}

export const DealTasks = ({ dealId }: DealTasksProps) => {
  const [showNewTask, setShowNewTask] = useState(false);
  const queryClient = useQueryClient();
  
  const { data: tasks = [], isLoading } = useQuery({
    queryKey: ["deal-tasks", dealId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('tasks')
        .select(`
          *,
          admin_profiles:users_metadata(name)
        `)
        .eq("deal_id", dealId)
        .order("due_date", { ascending: true });

      if (error) throw error;
      return data as unknown as TaskResponse[];
    }
  });

  const handleToggleStatus = async (taskId: string, currentStatus: string) => {
    try {
      const { error } = await supabase
        .from("tasks")
        .update({ status: currentStatus === "completed" ? "pending" : "completed" })
        .eq("id", taskId);

      if (error) throw error;

      queryClient.invalidateQueries({ queryKey: ["deal-tasks"] });
    } catch (error) {
      console.error("Error updating task:", error);
      toast.error("Failed to update task");
    }
  };

  if (isLoading) {
    return <div className="text-center py-4">Loading tasks...</div>;
  }

  return (
    <div className="space-y-4">
      <Button
        variant="outline"
        onClick={() => setShowNewTask(!showNewTask)}
        className="w-full"
      >
        {showNewTask ? "Cancel" : "Add New Task"}
      </Button>

      {showNewTask && (
        <TaskForm
          dealId={dealId}
          onSuccess={() => {
            setShowNewTask(false);
            queryClient.invalidateQueries({ queryKey: ["deal-tasks"] });
          }}
          onCancel={() => setShowNewTask(false)}
        />
      )}

      <TaskList
        tasks={tasks}
        onToggleStatus={handleToggleStatus}
      />
    </div>
  );
};
