
import { useEffect, useState } from "react";
import { ClientSegment } from "@/types/segments";
import { StartupHero } from "./segments/StartupHero";
import { EnterpriseHero } from "./segments/EnterpriseHero";
import { GovernmentHero } from "./segments/GovernmentHero";

interface DynamicHeroContentProps {
  clientSegment?: ClientSegment;
}

export const DynamicHeroContent = ({ clientSegment }: DynamicHeroContentProps) => {
  const [loading, setLoading] = useState(true);
  const [returningVisitor, setReturningVisitor] = useState<boolean>(false);

  useEffect(() => {
    const lastVisit = localStorage.getItem('lastVisit');
    if (lastVisit) {
      setReturningVisitor(true);
    }
    localStorage.setItem('lastVisit', new Date().toISOString());
    setLoading(false);
  }, []);

  const renderHeroContent = () => {
    switch (clientSegment) {
      case "startup":
        return <StartupHero returningVisitor={returningVisitor} />;
      case "enterprise":
        return <EnterpriseHero returningVisitor={returningVisitor} />;
      case "government":
        return <GovernmentHero returningVisitor={returningVisitor} />;
      default:
        return <StartupHero returningVisitor={returningVisitor} />;
    }
  };

  return (
    <div className="space-y-12">
      {renderHeroContent()}
    </div>
  );
};
