
import { useState } from 'react';
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/hooks/use-toast";
import { Copy, ArrowRight } from "lucide-react";
import { supabase } from '@/integrations/supabase/client';

interface WelcomeScreenProps {
  accessCode: string;
  onContinue: (clientName: string, companyName: string) => void;
}

export const WelcomeScreen = ({
  accessCode,
  onContinue
}: WelcomeScreenProps) => {
  const { toast } = useToast();
  const [clientName, setClientName] = useState('');
  const [companyName, setCompanyName] = useState('');

  const copyAccessCode = () => {
    navigator.clipboard.writeText(accessCode);
    toast({
      title: "Access code copied!",
      description: "Save this code to access your project later."
    });
  };

  const handleContinue = async () => {
    if (!clientName || !companyName) {
      toast({
        title: "Please fill in all fields",
        description: "Client name and company name are required.",
        variant: "destructive"
      });
      return;
    }

    try {
      const { error } = await supabase.from('flare_designs').update({
        client_name: clientName,
        client_company: companyName
      }).eq('access_code', accessCode);

      if (error) throw error;
      onContinue(clientName, companyName);
    } catch (error) {
      console.error('Error saving client info:', error);
      toast({
        title: "Error saving information",
        description: "Please try again.",
        variant: "destructive"
      });
    }
  };

  return <div className="min-h-screen relative bg-zinc-950 flex items-center justify-center p-4 overflow-hidden">
      {/* Background Image with Animation */}
      <div className="absolute inset-y-0 right-0 flex items-center opacity-20">
        <img src="/lovable-uploads/9cabc77c-2108-4a79-b8a0-0c09e7ecc384.png" alt="Retro Gaming Character" className="h-screen w-auto object-contain animate-pulse" style={{
        transform: 'translateX(20%)'
      }} />
      </div>
      
      {/* Content */}
      <div className="relative z-10 flex flex-col items-center space-y-6">
        <h1 className="text-4xl font-bold tracking-tight text-[#ff4d00] mb-4">
          FLARE CANVAS
        </h1>
        
        <Card className="w-full max-w-lg p-6 space-y-6 bg-zinc-900/80 backdrop-blur-xl border-[#ff4d00]/20 shadow-lg shadow-[#ff4d00]/10">
          <div className="space-y-2 text-center">
            <h2 className="text-2xl font-bold text-[#ff4d00]">Welcome to Your New Project</h2>
            <p className="text-muted-foreground">
              Save your access code and fill in some basic information to get started
            </p>
          </div>

          <div className="space-y-4">
            <div className="space-y-2">
              <Label>Your Access Code</Label>
              <div className="flex gap-2">
                <Input value={accessCode} readOnly className="font-mono bg-zinc-950/50" />
                <Button variant="outline" size="icon" onClick={copyAccessCode} className="border-[#ff4d00]/20 hover:bg-[#ff4d00]/10">
                  <Copy className="h-4 w-4" />
                </Button>
              </div>
              <p className="text-sm text-muted-foreground">
                Save this code to access your project later
              </p>
            </div>

            <div className="space-y-2">
              <Label htmlFor="clientName">Client Name</Label>
              <Input id="clientName" value={clientName} onChange={e => setClientName(e.target.value)} placeholder="Enter client name" className="bg-zinc-950/50 border-[#ff4d00]/20 focus:border-[#ff4d00]" />
            </div>

            <div className="space-y-2">
              <Label htmlFor="companyName">Company Name</Label>
              <Input id="companyName" value={companyName} onChange={e => setCompanyName(e.target.value)} placeholder="Enter company name" className="bg-zinc-950/50 border-[#ff4d00]/20 focus:border-[#ff4d00]" />
            </div>
          </div>

          <Button onClick={handleContinue} size="lg" className="w-full text-white bg-[#ff4d00]">
            Continue to Design Canvas
            <ArrowRight className="ml-2 h-4 w-4" />
          </Button>
        </Card>
      </div>
    </div>;
};
