
import React from "react";
import { motion } from "framer-motion";

interface GridMotionProps {
  items: string[];
  gradientColor?: string;
  className?: string;
}

export const GridMotion: React.FC<GridMotionProps> = ({ 
  items, 
  gradientColor = "rgba(0, 0, 0, 0.1)",
  className = "" 
}) => {
  // Double the items to create a seamless loop
  const gridItems = [...items, ...items];
  
  return (
    <div className="overflow-hidden w-full">
      <motion.div 
        className={`grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-3 p-3 
                    transform -rotate-12 scale-110 ${className}`}
        animate={{
          y: ["0%", "-50%"]
        }}
        transition={{
          y: {
            duration: 50,
            repeat: Infinity,
            ease: "linear"
          }
        }}
        style={{
          willChange: "transform"
        }}
      >
        {gridItems.map((item, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.5,
              delay: index * 0.02,
              ease: "easeOut"
            }}
            className="relative group transform transition-all duration-500 hover:z-50"
            style={{ aspectRatio: '3/4' }}
          >
            {/* Genie effect container */}
            <div 
              className="absolute inset-0 rounded-lg transition-all duration-500 ease-[cubic-bezier(0.34,1.56,0.64,1)] 
                         group-hover:scale-[1.35] group-hover:rotate-3 group-hover:-translate-y-2
                         group-hover:shadow-2xl group-hover:shadow-brand-orange/20"
            >
              {/* Gradient overlay */}
              <div 
                className="absolute inset-0 rounded-lg bg-gradient-to-b from-black/20 to-black/40 
                         group-hover:from-brand-orange/20 group-hover:to-black/60 
                         transition-all duration-500"
              />
              
              {/* Image */}
              <img
                src={item}
                alt={`Grid item ${index + 1}`}
                className="w-full h-full object-cover rounded-lg 
                         transition-all duration-700 
                         group-hover:brightness-110 group-hover:contrast-110 group-hover:saturate-150"
              />
              
              {/* Border effect */}
              <div 
                className="absolute inset-0 rounded-lg ring-1 ring-white/10 
                         group-hover:ring-brand-orange/20 group-hover:ring-2
                         transition-all duration-500"
              />
              
              {/* Glow effect */}
              <div 
                className="absolute inset-0 rounded-lg opacity-0 group-hover:opacity-100
                         bg-brand-orange/10 blur-xl transition-all duration-500"
              />
            </div>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};
