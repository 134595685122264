import { useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { ProjectScope } from "../types";

export const useProjectSubmission = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState("");

  const submitProject = async (pitch: string) => {
    setIsLoading(true);
    try {
      setCurrentStep("Processing project requirements");
      
      // First, create the initial project analysis
      const { data: analysisData, error: analysisError } = await supabase
        .from('project_analyses')
        .insert([{
          project_type: 'startup',
          project_stage: 'initial',
          description: pitch,
        }])
        .select()
        .single();

      if (analysisError) throw analysisError;

      setCurrentStep("Analyzing market trends");
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Get AI analysis
      setCurrentStep("Generating technical recommendations");
      const { data: aiResponse, error: aiError } = await supabase.functions.invoke('generate-enhanced-analysis', {
        body: { 
          projectType: 'startup',
          projectStage: 'initial',
          description: pitch
        }
      });

      if (aiError) throw aiError;
      
      console.log("AI Response:", aiResponse); // Debug log

      if (!aiResponse || !aiResponse.projectUnderstanding) {
        throw new Error("Invalid AI response format");
      }

      setCurrentStep("Calculating timeline and budget");
      
      // Create detailed project scope
      const { error: scopeError } = await supabase
        .from('project_scopes')
        .insert([{
          project_analysis_id: analysisData.id,
          overview: {
            description: pitch,
            objectives: aiResponse.projectUnderstanding.objectives || [],
            success_criteria: aiResponse.projectUnderstanding.success_criteria || []
          },
          deliverables: {
            features: aiResponse.features || [],
            milestones: aiResponse.timeline?.phases || [],
            technical_requirements: aiResponse.technicalRecommendations || []
          },
          timeline: {
            duration: aiResponse.timeline?.total || "8-12 weeks",
            phases: aiResponse.timeline?.breakdown || [],
            dependencies: aiResponse.timeline?.dependencies || []
          },
          communication_plan: {
            stakeholders: ["Product Owner", "Development Team", "Project Manager"],
            meeting_cadence: "Weekly",
            reporting_frequency: "Bi-weekly"
          },
          budget_breakdown: aiResponse.budget || {
            total: "Contact for pricing",
            development: "60%",
            design: "20%",
            project_management: "10%",
            contingency: "10%"
          },
          approvals: {
            required_approvals: ["Technical Design", "Budget", "Timeline"],
            approval_process: "Sequential",
            stakeholders: ["Technical Lead", "Product Owner", "Finance"]
          }
        }]);

      if (scopeError) throw scopeError;

      // Update the analysis with AI insights
      const { error: updateError } = await supabase
        .from('project_analyses')
        .update({
          market_analysis: aiResponse.marketAnalysis,
          similar_projects: aiResponse.projectUnderstanding.competitors,
          technical_recommendations: aiResponse.technicalRecommendations,
          estimated_timeline: aiResponse.timeline?.total,
          budget_range: aiResponse.budget?.range
        })
        .eq('id', analysisData.id);

      if (updateError) throw updateError;

      toast.success("Project analysis completed successfully!");
      return {
        id: analysisData.id,
        ...aiResponse
      };
    } catch (error) {
      console.error("Error submitting project:", error);
      toast.error("Failed to analyze project. Please try again.");
      return null;
    } finally {
      setIsLoading(false);
      setCurrentStep("");
    }
  };

  return {
    submitProject,
    isLoading,
    currentStep
  };
};