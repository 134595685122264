import { useEffect, useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { supabase } from '@/integrations/supabase/client';
import { Calendar, Loader2 } from 'lucide-react';
import { toast } from 'sonner';
import { format } from 'date-fns';

interface Schedule {
  id: string;
  date: string;
  start_time: string;
  end_time: string;
}

export const EmployeeSchedule = () => {
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getSchedules = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
          const { data: employeeProfile } = await supabase
            .from('employee_profiles')
            .select('id')
            .eq('user_id', user.id)
            .single();

          if (employeeProfile) {
            const { data, error } = await supabase
              .from('schedules')
              .select('*')
              .eq('employee_id', employeeProfile.id)
              .order('date', { ascending: true });

            if (error) throw error;
            if (data) {
              setSchedules(data);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching schedules:', error);
        toast.error('Failed to load schedule');
      } finally {
        setLoading(false);
      }
    };

    getSchedules();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-2">
        <Calendar className="h-6 w-6 text-primary" />
        <h1 className="text-3xl font-bold">My Schedule</h1>
      </div>
      
      <div className="grid gap-4">
        {schedules.map((schedule) => (
          <Card key={schedule.id} className="hover:shadow-md transition-shadow">
            <CardHeader>
              <CardTitle className="flex justify-between items-center">
                <span>{format(new Date(schedule.date), 'EEEE, MMMM d, yyyy')}</span>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-muted-foreground">
                {schedule.start_time} - {schedule.end_time}
              </p>
            </CardContent>
          </Card>
        ))}
        
        {schedules.length === 0 && (
          <Card>
            <CardContent className="py-8">
              <div className="text-center text-muted-foreground">
                <Calendar className="h-12 w-12 mx-auto mb-4 opacity-50" />
                <p>No scheduled shifts found</p>
              </div>
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
};