export const crmGuide = {
  title: "Sales & CRM",
  description: "Comprehensive guide for managing contacts, companies, and deals effectively.",
  sections: [
    {
      title: "Contact Management",
      content: `Managing contacts is a core feature of the CRM system. Here's how to make the most of it:

1. Adding Contacts:
   - Click the "Add Contact" button in the top right
   - Fill in required information (name, email)
   - Add optional details (phone, job title, company)
   - Use tags for categorization (e.g., "prospect", "customer")
   - Set communication preferences and lead source

2. Contact Tags:
   - Click on a contact to open their profile
   - Scroll to the Tags section
   - Type a new tag and press Enter
   - Click existing tags to filter contacts
   - Use consistent naming conventions

3. Engagement Tracking:
   - System automatically tracks email opens
   - Monitors website visits and form submissions
   - Calculates engagement score (0-100)
   - Filters highly engaged contacts

4. Communication History:
   - View all email threads in one place
   - Track meeting notes and call logs
   - Set follow-up reminders
   - Monitor response rates

Best Practices:
- Update contact information regularly
- Use standardized phone number format
- Add detailed notes after each interaction
- Review and update tags monthly

Interactive Demo:
1. Navigate to Contacts tab
2. Click "+ New Contact"
3. Fill in the form
4. Add tags and notes
5. Save and view contact card`
    },
    {
      title: "Company Tracking",
      content: `Effectively manage company relationships and opportunities:

1. Company Profiles:
   - Create detailed company profiles
   - Track key company metrics
   - Monitor engagement levels
   - Store company documents

2. Company Analysis:
   - View company insights dashboard
   - Track deal history and pipeline
   - Monitor communication frequency
   - Analyze engagement patterns

3. Associated Contacts:
   - Link contacts to companies
   - Identify key stakeholders
   - Track relationship hierarchy
   - Manage multiple locations

Best Practices:
- Keep company information current
- Update status regularly
- Document all interactions
- Track competitive intelligence

Interactive Features:
- Drag and drop file upload
- One-click contact association
- Automated company enrichment
- Visual relationship mapping`
    },
    {
      title: "Deal Pipeline",
      content: `Manage your sales pipeline effectively:

1. Deal Creation:
   - Click "New Deal" in Deals tab
   - Associate with company/contact
   - Set deal value and probability
   - Choose appropriate stage

2. Pipeline Management:
   - Drag deals between stages
   - Update deal values and notes
   - Set follow-up tasks
   - Track deal progress

3. Deal Stages:
   - Qualification: Initial assessment
   - Proposal: Offer preparation
   - Negotiation: Terms discussion
   - Closed Won/Lost: Final status

Best Practices:
- Review pipeline weekly
- Update deal stages promptly
- Document all activities
- Set realistic close dates

Video Tutorial:
1. Pipeline Overview
2. Creating New Deals
3. Managing Deal Stages
4. Setting Up Automations`
    }
  ]
};