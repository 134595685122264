
import { useQuery } from '@tanstack/react-query';
import { Loader2, Book, FileCode } from 'lucide-react';
import { format } from 'date-fns';
import { supabase } from '@/integrations/supabase/client';

interface TaskDocumentationProps {
  taskId: string;
}

export function TaskDocumentation({ taskId }: TaskDocumentationProps) {
  const { data: technicalDocs = [], isLoading: isLoadingDocs } = useQuery({
    queryKey: ['task-documentation', taskId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('task_documentation')
        .select('*')
        .eq('task_id', taskId)
        .order('created_at', { ascending: false });
      if (error) throw error;
      return data;
    },
    enabled: !!taskId
  });

  const { data: apiDocs = [], isLoading: isLoadingApiDocs } = useQuery({
    queryKey: ['task-api-docs', taskId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('task_api_docs')
        .select('*')
        .eq('task_id', taskId)
        .order('created_at', { ascending: false });
      if (error) throw error;
      return data;
    },
    enabled: !!taskId
  });

  if (isLoadingDocs || isLoadingApiDocs) {
    return (
      <div className="flex justify-center py-4">
        <Loader2 className="h-6 w-6 animate-spin text-muted-foreground" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {technicalDocs.length > 0 && (
        <div className="space-y-4">
          <h3 className="font-medium flex items-center gap-2">
            <Book className="h-4 w-4" />
            Technical Documentation
          </h3>
          <div className="space-y-4">
            {technicalDocs.map(doc => (
              <div key={doc.id} className="bg-[#ff4d00]/5 rounded-lg p-4 space-y-2">
                <div className="flex items-center justify-between">
                  <h4 className="font-medium">{doc.title}</h4>
                  <span className="text-xs text-[#ff4d00]/70">
                    {format(new Date(doc.created_at), 'PPp')}
                  </span>
                </div>
                <p className="text-sm whitespace-pre-wrap">{doc.content}</p>
              </div>
            ))}
          </div>
        </div>
      )}

      {apiDocs.length > 0 && (
        <div className="space-y-4">
          <h3 className="font-medium flex items-center gap-2">
            <FileCode className="h-4 w-4" />
            API Documentation
          </h3>
          <div className="space-y-4">
            {apiDocs.map(doc => (
              <div key={doc.id} className="bg-[#ff4d00]/5 rounded-lg p-4 space-y-3">
                <div className="flex items-center justify-between">
                  <div className="space-y-1">
                    <div className="flex items-center gap-2">
                      <span className="px-2 py-0.5 bg-[#ff4d00] text-white rounded text-xs">
                        {doc.method}
                      </span>
                      <code className="text-sm font-mono">{doc.endpoint}</code>
                    </div>
                    <p className="text-sm text-[#ff4d00]/70">{doc.description}</p>
                  </div>
                  <span className="text-xs text-[#ff4d00]/70">
                    {format(new Date(doc.created_at), 'PPp')}
                  </span>
                </div>
                {(doc.request_schema || doc.response_schema) && (
                  <div className="grid grid-cols-2 gap-4">
                    {doc.request_schema && (
                      <div className="space-y-1">
                        <h5 className="text-xs font-medium">Request Schema</h5>
                        <pre className="text-xs bg-black/5 p-2 rounded overflow-auto">
                          {JSON.stringify(doc.request_schema, null, 2)}
                        </pre>
                      </div>
                    )}
                    {doc.response_schema && (
                      <div className="space-y-1">
                        <h5 className="text-xs font-medium">Response Schema</h5>
                        <pre className="text-xs bg-black/5 p-2 rounded overflow-auto">
                          {JSON.stringify(doc.response_schema, null, 2)}
                        </pre>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {technicalDocs.length === 0 && apiDocs.length === 0 && (
        <p className="text-center text-[#ff4d00]/70 py-4">
          No documentation available
        </p>
      )}
    </div>
  );
}
