import { useState, useEffect } from "react";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

export const useDealData = (id: string | undefined) => {
  const queryClient = useQueryClient();
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState<any>(null);

  const { data: deal, isLoading, error } = useQuery({
    queryKey: ["deal", id],
    queryFn: async () => {
      if (!id) {
        throw new Error("Invalid deal ID");
      }

      const { data, error } = await supabase
        .from("deals")
        .select(`
          *,
          companies (
            name,
            logo_url
          ),
          contacts (
            first_name,
            last_name,
            email
          ),
          admin_profiles (
            name
          ),
          deal_analytics (
            probability_score,
            predicted_close_date,
            suggested_actions
          )
        `)
        .eq('id', id)
        .maybeSingle();

      if (error) throw error;
      if (!data) throw new Error("Deal not found");
      return data;
    },
    enabled: !!id,
  });

  // Initialize formData when deal data is loaded
  useEffect(() => {
    if (deal) {
      setFormData({
        name: deal.name,
        value: deal.value,
        stage: deal.stage,
        priority: deal.priority,
        contact_id: deal.contact_id,
        company_id: deal.company_id,
        expected_close_date: deal.expected_close_date,
        notes: deal.notes,
      });
    }
  }, [deal]);

  const handleSave = async () => {
    if (!formData || !id) return;
    
    setIsSubmitting(true);
    try {
      const { error } = await supabase
        .from("deals")
        .update({
          name: formData.name,
          value: formData.value,
          stage: formData.stage,
          priority: formData.priority,
          contact_id: formData.contact_id,
          company_id: formData.company_id,
          expected_close_date: formData.expected_close_date,
          notes: formData.notes,
        })
        .eq('id', id);

      if (error) throw error;

      toast.success("Deal updated successfully");
      queryClient.invalidateQueries({ queryKey: ["deal", id] });
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating deal:", error);
      toast.error("Failed to update deal");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFieldChange = (field: string, value: any) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  return {
    deal,
    isLoading,
    error,
    isEditing,
    setIsEditing,
    isSubmitting,
    formData,
    handleSave,
    handleFieldChange,
  };
};