import React, { useState } from 'react';
import { ClientSegment } from '@/types/segments';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { SparklesCore } from "@/components/ui/sparkles";
import { validateEmail } from '@/utils/validation';

export const MadLibForm = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    full_name: '',
    segment: '' as ClientSegment,
    job_title: '',
    company_name: '',
    service_type: '',
    goal: '',
    timeframe: '',
    contact_method: '',
    contact_info: ''
  });

  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});

  const getRolesBySegment = (segment: string) => {
    switch (segment) {
      case 'startup':
        return [
          'Founder',
          'Co-Founder',
          'CTO',
          'CEO',
          'Product Manager',
          'Technical Lead'
        ];
      case 'enterprise':
        return [
          'CTO',
          'CIO',
          'IT Director',
          'Digital Transformation Lead',
          'Innovation Manager',
          'Technology Director',
          'Enterprise Architect'
        ];
      case 'government':
        return [
          'Agency Director',
          'IT Manager',
          'Program Manager',
          'Technology Officer',
          'Department Head',
          'Project Lead',
          'Contracting Officer'
        ];
      default:
        return [];
    }
  };

  const validateForm = () => {
    const errors: { [key: string]: string } = {};
    
    if (!formData.full_name.trim()) {
      errors.full_name = 'Name is required';
    }
    
    if (!formData.company_name.trim()) {
      errors.company_name = 'Organization name is required';
    }

    if (formData.contact_method === 'Email' && !validateEmail(formData.contact_info)) {
      errors.contact_info = 'Please enter a valid email address';
    }

    if (formData.contact_method === 'Phone') {
      const phoneRegex = /^\+?[\d\s-()]{10,}$/;
      if (!phoneRegex.test(formData.contact_info)) {
        errors.contact_info = 'Please enter a valid phone number';
      }
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const formatPhoneNumber = (value: string) => {
    const numbers = value.replace(/\D/g, '');
    if (numbers.length <= 3) return numbers;
    if (numbers.length <= 6) return `(${numbers.slice(0, 3)}) ${numbers.slice(3)}`;
    return `(${numbers.slice(0, 3)}) ${numbers.slice(3, 6)}-${numbers.slice(6, 10)}`;
  };

  const handleContactInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (formData.contact_method === 'Phone') {
      value = formatPhoneNumber(value);
    }
    setFormData(prev => ({ ...prev, contact_info: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      toast.error("Please fill in all required fields correctly");
      return;
    }

    setIsSubmitting(true);
    
    try {
      const { error } = await supabase
        .from('madlib_contact_submissions')
        .insert([formData]);

      if (error) throw error;

      toast.success("Thanks for reaching out! Let's schedule a time to chat.");
      
      navigate(`/thank-you?name=${encodeURIComponent(formData.full_name)}` + 
        `&company=${encodeURIComponent(formData.company_name)}` +
        `&segment=${encodeURIComponent(formData.segment)}` +
        `&job_title=${encodeURIComponent(formData.job_title)}` +
        `&service_type=${encodeURIComponent(formData.service_type)}` +
        `&goal=${encodeURIComponent(formData.goal)}` +
        `&contact_method=${encodeURIComponent(formData.contact_method)}` +
        `&contact_info=${encodeURIComponent(formData.contact_info)}`
      );
    } catch (error) {
      console.error('Error:', error);
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const inputClasses = `
    bg-black/20 backdrop-blur-sm border-b-4 border-brand-orange 
    px-4 py-2 focus:outline-none w-full sm:w-auto min-w-[200px] 
    placeholder:text-brand-orange/40 font-normal text-center rounded-lg 
    hover:bg-black/30 transition-colors text-brand-orange
    focus:bg-[#1A1F2C]/40 focus:border-brand-orange/80
    ${validationErrors ? 'focus:ring-2 focus:ring-brand-orange/20' : ''}
  `;

  const selectClasses = `
    bg-black/20 backdrop-blur-sm border-b-4 border-brand-orange 
    px-4 py-2 focus:outline-none w-full sm:w-auto min-w-[200px] 
    appearance-none cursor-pointer font-normal text-center rounded-lg 
    hover:bg-black/30 transition-colors text-brand-orange
    focus:bg-[#1A1F2C]/40 focus:border-brand-orange/80
    disabled:opacity-50 disabled:cursor-not-allowed
  `;

  return (
    <section className="relative h-[100dvh] bg-gradient-to-br from-brand-charcoal via-black to-brand-darkCharcoal overflow-hidden">
      <div className="absolute inset-0 -bottom-[100vh]">
        <SparklesCore
          id="form-sparkles"
          background="transparent"
          minSize={0.4}
          maxSize={1}
          particleDensity={70}
          className="w-full h-[200vh]"
          particleColor="rgba(255, 126, 0, 0.3)"
          speed={0.5}
        />
      </div>

      <div className="relative z-10 h-full flex items-center justify-center p-8 md:p-12 lg:p-16">
        <div className="w-full max-w-[1600px] mx-auto">
          <motion.h2
            className="text-5xl md:text-6xl lg:text-7xl font-display font-bold mb-12"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Hello, How can we help?
          </motion.h2>

          <motion.form 
            onSubmit={handleSubmit}
            className="space-y-4 md:space-y-6 text-2xl sm:text-3xl md:text-4xl lg:text-[2.8rem] font-display font-bold leading-tight"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <p className="flex flex-wrap gap-2 items-center">
              Hi, I'm
              <div className="relative">
                <input
                  type="text"
                  value={formData.full_name}
                  onChange={(e) => setFormData(prev => ({ ...prev, full_name: e.target.value }))}
                  placeholder="your name"
                  className={inputClasses}
                  required
                />
                {validationErrors.full_name && (
                  <span className="absolute -bottom-6 left-0 text-red-500 text-sm font-normal">
                    {validationErrors.full_name}
                  </span>
                )}
              </div>
              representing a
              <select
                value={formData.segment}
                onChange={(e) => {
                  const newSegment = e.target.value as ClientSegment;
                  setFormData(prev => ({ 
                    ...prev, 
                    segment: newSegment,
                    job_title: '',
                    service_type: '',
                    goal: ''
                  }));
                }}
                className={selectClasses}
                required
              >
                <option value="" className="bg-black font-normal">Select segment</option>
                <option value="startup" className="bg-black font-normal">Startup</option>
                <option value="enterprise" className="bg-black font-normal">Enterprise</option>
                <option value="government" className="bg-black font-normal">Government</option>
              </select>
              ,
            </p>

            <AnimatePresence mode="wait">
              <motion.div
                key={formData.segment}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.3 }}
              >
                <p className="flex flex-wrap gap-2 items-center">
                  as a
                  <select
                    value={formData.job_title}
                    onChange={(e) => setFormData(prev => ({ ...prev, job_title: e.target.value }))}
                    className={selectClasses}
                    required
                    disabled={!formData.segment}
                  >
                    <option value="" className="bg-black font-normal">Select role</option>
                    {getRolesBySegment(formData.segment).map((role) => (
                      <option key={role} value={role} className="bg-black font-normal">
                        {role}
                      </option>
                    ))}
                  </select>
                  at
                  <div className="relative">
                    <input
                      type="text"
                      value={formData.company_name}
                      onChange={(e) => setFormData(prev => ({ ...prev, company_name: e.target.value }))}
                      placeholder="your organization"
                      className={inputClasses}
                      required
                    />
                    {validationErrors.company_name && (
                      <span className="absolute -bottom-6 left-0 text-red-500 text-sm font-normal">
                        {validationErrors.company_name}
                      </span>
                    )}
                  </div>
                </p>
              </motion.div>
            </AnimatePresence>

            <p className="flex flex-wrap gap-2 items-center">
              I need help with
              <select
                value={formData.service_type}
                onChange={(e) => setFormData(prev => ({ ...prev, service_type: e.target.value }))}
                className={selectClasses}
                required
                disabled={!formData.segment}
              >
                <option value="" className="bg-black font-normal">Select service</option>
                {formData.segment === 'startup' && (
                  <>
                    <option value="MVP Development" className="bg-black font-normal">MVP Development</option>
                    <option value="Technical Co-Founding" className="bg-black font-normal">Technical Co-Founding</option>
                    <option value="Growth Engineering" className="bg-black font-normal">Growth Engineering</option>
                    <option value="Startup Acceleration" className="bg-black font-normal">Startup Acceleration</option>
                  </>
                )}
                {formData.segment === 'enterprise' && (
                  <>
                    <option value="Legacy Modernization" className="bg-black font-normal">Legacy Modernization</option>
                    <option value="Digital Transformation" className="bg-black font-normal">Digital Transformation</option>
                    <option value="Cloud Migration" className="bg-black font-normal">Cloud Migration</option>
                    <option value="Enterprise Integration" className="bg-black font-normal">Enterprise Integration</option>
                  </>
                )}
                {formData.segment === 'government' && (
                  <>
                    <option value="FedRAMP Solutions" className="bg-black font-normal">FedRAMP Solutions</option>
                    <option value="Legacy Modernization" className="bg-black font-normal">Legacy Modernization</option>
                    <option value="Compliance Implementation" className="bg-black font-normal">Compliance Implementation</option>
                    <option value="Security Assessment" className="bg-black font-normal">Security Assessment</option>
                  </>
                )}
              </select>
              {" "}to{" "}
              <select
                value={formData.goal}
                onChange={(e) => setFormData(prev => ({ ...prev, goal: e.target.value }))}
                className={selectClasses}
                required
                disabled={!formData.segment}
              >
                <option value="" className="bg-black font-normal">Select goal</option>
                {formData.segment === 'startup' && (
                  <>
                    <option value="launch our MVP" className="bg-black font-normal">launch our MVP</option>
                    <option value="scale our platform" className="bg-black font-normal">scale our platform</option>
                    <option value="optimize our infrastructure" className="bg-black font-normal">optimize our infrastructure</option>
                    <option value="accelerate growth" className="bg-black font-normal">accelerate growth</option>
                  </>
                )}
                {formData.segment === 'enterprise' && (
                  <>
                    <option value="modernize legacy systems" className="bg-black font-normal">modernize legacy systems</option>
                    <option value="improve operational efficiency" className="bg-black font-normal">improve operational efficiency</option>
                    <option value="enhance security posture" className="bg-black font-normal">enhance security posture</option>
                    <option value="enable digital transformation" className="bg-black font-normal">enable digital transformation</option>
                  </>
                )}
                {formData.segment === 'government' && (
                  <>
                    <option value="achieve FedRAMP compliance" className="bg-black font-normal">achieve FedRAMP compliance</option>
                    <option value="modernize agency systems" className="bg-black font-normal">modernize agency systems</option>
                    <option value="implement security controls" className="bg-black font-normal">implement security controls</option>
                    <option value="improve citizen services" className="bg-black font-normal">improve citizen services</option>
                  </>
                )}
              </select>
            </p>

            <p className="flex flex-wrap gap-2 items-center">
              within the next
              <select
                value={formData.timeframe}
                onChange={(e) => setFormData(prev => ({ ...prev, timeframe: e.target.value }))}
                className={selectClasses}
                required
              >
                <option value="" className="bg-black font-normal">Select timeframe</option>
                <option value="1-3 months" className="bg-black font-normal">1-3 months</option>
                <option value="3-6 months" className="bg-black font-normal">3-6 months</option>
                <option value="6-12 months" className="bg-black font-normal">6-12 months</option>
                <option value="12+ months" className="bg-black font-normal">12+ months</option>
              </select>
            </p>

            <p className="flex flex-wrap gap-2 items-center">
              Please contact me via
              <select
                value={formData.contact_method}
                onChange={(e) => {
                  setFormData(prev => ({ 
                    ...prev, 
                    contact_method: e.target.value,
                    contact_info: '' // Reset contact info when method changes
                  }));
                  setValidationErrors(prev => ({ ...prev, contact_info: '' }));
                }}
                className={selectClasses}
                required
              >
                <option value="" className="bg-black font-normal">Select method</option>
                <option value="Email" className="bg-black font-normal">Email</option>
                <option value="Phone" className="bg-black font-normal">Phone</option>
                <option value="Video Call" className="bg-black font-normal">Video Call</option>
              </select>
              at
              <div className="relative">
                <input
                  type="text"
                  value={formData.contact_info}
                  onChange={handleContactInfoChange}
                  placeholder={
                    formData.contact_method === 'Email' ? 'your@email.com' :
                    formData.contact_method === 'Phone' ? '(555) 555-5555' :
                    'your contact information'
                  }
                  className={inputClasses}
                  required
                />
                {validationErrors.contact_info && (
                  <span className="absolute -bottom-6 left-0 text-red-500 text-sm font-normal">
                    {validationErrors.contact_info}
                  </span>
                )}
              </div>
            </p>

            <div className="pt-6">
              <Button 
                type="submit"
                className="bg-brand-orange hover:bg-brand-orange/90 text-white px-12 py-6 text-xl font-mono rounded-full relative overflow-hidden disabled:opacity-50 disabled:cursor-not-allowed group"
                disabled={isSubmitting}
              >
                <span className={`relative z-10 ${isSubmitting ? 'opacity-0' : 'opacity-100'}`}>
                  Send Message
                </span>
                {isSubmitting && (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="w-6 h-6 border-t-2 border-white rounded-full animate-spin" />
                  </div>
                )}
                <div className="absolute inset-0 bg-gradient-to-r from-brand-orange via-brand-orange/80 to-brand-orange opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              </Button>
            </div>
          </motion.form>
        </div>
      </div>
    </section>
  );
};
