
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { ChevronRight } from "lucide-react";
import { Link } from "react-router-dom";
import { Header } from "@/components/Header";
import { useState } from "react";

interface HeroSectionProps {
  subheading: string;
  scrollToMadLib: () => void;
}

export const HeroSection = ({ subheading, scrollToMadLib }: HeroSectionProps) => {
  const [isHovered, setIsHovered] = useState(false);
  
  return (
    <div 
      className="relative min-h-screen flex flex-col justify-center px-8 sm:px-12 md:px-20"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Header />
      <motion.div 
        className={`absolute right-0 top-0 w-full md:w-1/2 h-full opacity-20 mix-blend-luminosity transition-all duration-700 ${
          isHovered ? "opacity-40 mix-blend-normal" : "opacity-20 mix-blend-luminosity"
        }`}
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: isHovered ? 0.4 : 0.2 }}
        transition={{ duration: 1 }}
      >
        <div 
          className={`w-full h-full bg-contain bg-no-repeat bg-center md:bg-right transition-all duration-700`}
          style={{ 
            backgroundImage: 'url(/lovable-uploads/a07695c7-d8be-4d00-8567-8d2dc1add609.png)',
            filter: isHovered ? 'brightness(1.4) contrast(1.2) saturate(1.3)' : 'brightness(1.2) contrast(1.1)'
          }} 
        />
      </motion.div>

      <motion.span
        className="text-lg sm:text-xl mb-8 sm:mb-16 font-light tracking-wide text-gray-400"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {subheading}
      </motion.span>
      
      <motion.h1 
        className="text-4xl sm:text-[8vw] leading-tight sm:leading-none font-display font-bold text-white relative z-10 mb-8 sm:mb-12 max-w-[90vw]"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        Join Hundreds from<br />
        <Link to="/startup" className="hover:text-brand-orange transition-colors">Startups</Link>, {" "}
        <Link to="/enterprise" className="hover:text-brand-orange transition-colors">Enterprises</Link>,<br />and {" "}
        <Link to="/government" className="hover:text-brand-orange transition-colors">Government</Link> {" "}
        Relying on Unstuck
      </motion.h1>
      
      <motion.div 
        className="relative z-20"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
      >
        <Button 
          onClick={scrollToMadLib}
          className="rounded-full bg-brand-orange hover:bg-brand-orange/90 text-white px-6 sm:px-8 py-4 sm:py-6 text-base sm:text-lg group backdrop-blur-sm"
        >
          Schedule Free Consultation
          <ChevronRight className="ml-2 h-4 w-4 sm:h-5 sm:w-5 group-hover:translate-x-1 transition-transform" />
        </Button>
      </motion.div>
    </div>
  );
};
