import { motion } from "framer-motion";
import { ProjectUnderstanding } from "../components/results/ProjectUnderstanding";
import { BudgetTierSlider } from "../components/budget/BudgetTierSlider";
import { FeatureRecommendations } from "../components/results/FeatureRecommendations";
import { TimelineMilestones } from "../components/results/TimelineMilestones";
import { NextSteps } from "../components/results/NextSteps";
import { useProjectStore } from "../stores/projectStore";

export const ResultsView = () => {
  const analysis = useProjectStore((state) => state.analysis);

  if (!analysis) {
    return (
      <div className="flex items-center justify-center min-h-[50vh]">
        <p className="text-muted-foreground">No analysis data available</p>
      </div>
    );
  }

  // Get complexity from analysis for budget tiers
  const complexity = analysis.projectUnderstanding?.complexity || 'low';

  return (
    <div className="max-w-6xl mx-auto space-y-12 px-4 pb-24 bg-background">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="space-y-12"
      >
        <ProjectUnderstanding
          understanding={{
            summary: analysis.projectUnderstanding.summary,
            targetMarket: analysis.projectUnderstanding.targetMarket,
            competitors: analysis.projectUnderstanding.competitors || [],
            uniqueValue: analysis.projectUnderstanding.uniqueValue
          }}
          marketAnalysis={analysis.marketAnalysis}
        />

        <div className="space-y-4">
          <h2 className="text-2xl font-bold text-center">Investment Options</h2>
          <p className="text-muted-foreground text-center">
            Slide the budget range selector below to explore different investment tiers and see what features and capabilities are included at each level.
          </p>
          <BudgetTierSlider complexity={complexity} />
        </div>

        <FeatureRecommendations features={{
          mustHave: analysis.features?.mustHave?.map(f => ({
            name: f.name,
            description: f.description,
            priority: "must-have"
          })) || [],
          niceToHave: analysis.features?.niceToHave?.map(f => ({
            name: f.name,
            description: f.description,
            priority: "nice-to-have"
          })) || [],
          future: []
        }} />
        
        <TimelineMilestones phases={analysis.timeline?.phases?.map(phase => ({
          name: phase.name,
          duration: phase.duration,
          tasks: phase.tasks,
          deliverables: phase.deliverables || []
        })) || []} />
        
        <NextSteps />
      </motion.div>
    </div>
  );
};