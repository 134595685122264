import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Mail, Phone } from "lucide-react";
import { Contact } from "../../types/contactTypes";

interface ContactBasicInfoProps {
  formData: Contact;
  onChange: (field: string, value: string) => void;
  isEditing: boolean;
}

export const ContactBasicInfo = ({ formData, onChange, isEditing }: ContactBasicInfoProps) => {
  if (!isEditing) {
    return (
      <div className="grid grid-cols-2 gap-6">
        <div className="space-y-2">
          <Label>Name</Label>
          <p className="text-lg">
            {formData.first_name} {formData.last_name}
          </p>
        </div>
        <div className="space-y-2">
          <div className="flex items-center gap-2 text-muted-foreground">
            <Mail className="w-4 h-4" />
            <Label>Email</Label>
          </div>
          <p className="text-lg">
            {formData.email ? (
              <a href={`mailto:${formData.email}`} className="text-blue-500 hover:underline">
                {formData.email}
              </a>
            ) : (
              "Not specified"
            )}
          </p>
        </div>
        <div className="space-y-2">
          <div className="flex items-center gap-2 text-muted-foreground">
            <Phone className="w-4 h-4" />
            <Label>Phone</Label>
          </div>
          <p className="text-lg">
            {formData.phone ? (
              <a href={`tel:${formData.phone}`} className="text-blue-500 hover:underline">
                {formData.phone}
              </a>
            ) : (
              "Not specified"
            )}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-2 gap-6">
      <div className="space-y-2">
        <Label htmlFor="first_name">First Name</Label>
        <Input
          id="first_name"
          value={formData.first_name}
          onChange={(e) => onChange("first_name", e.target.value)}
          className="bg-background"
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="last_name">Last Name</Label>
        <Input
          id="last_name"
          value={formData.last_name}
          onChange={(e) => onChange("last_name", e.target.value)}
          className="bg-background"
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="email" className="flex items-center gap-2">
          <Mail className="w-4 h-4" />
          Email
        </Label>
        <Input
          id="email"
          type="email"
          value={formData.email || ""}
          onChange={(e) => onChange("email", e.target.value)}
          className="bg-background"
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="phone" className="flex items-center gap-2">
          <Phone className="w-4 h-4" />
          Phone
        </Label>
        <Input
          id="phone"
          type="tel"
          value={formData.phone || ""}
          onChange={(e) => onChange("phone", e.target.value)}
          className="bg-background"
        />
      </div>
    </div>
  );
};