
import { useSearchParams } from 'react-router-dom';
import { ThankYouHeader } from '@/components/ThankYou/ThankYouHeader';
import { DiscoveryMeetingForm } from '@/components/ThankYou/DiscoveryMeetingForm';
import { ResourceOffer } from '@/components/ThankYou/ResourceOffer';
import { SparklesCore } from '@/components/ui/sparkles';
import { Meteors } from '@/components/ui/meteors';
import { motion } from 'framer-motion';

export default function ThankYou() {
  const [searchParams] = useSearchParams();

  // Get form data from URL params
  const formData = {
    name: searchParams.get('name') || '',
    company: searchParams.get('company') || '',
    email: searchParams.get('email') || '',
    segment: searchParams.get('segment') as 'startup' | 'enterprise' | 'government' || 'startup',
    // Additional form fields from MadLib
    job_title: searchParams.get('job_title') || '',
    service_type: searchParams.get('service_type') || '',
    goal: searchParams.get('goal') || '',
    contact_method: searchParams.get('contact_method') || '',
    contact_info: searchParams.get('contact_info') || '',
  };

  // Get first name for personalized greeting
  const firstName = formData.name.split(' ')[0];

  return (
    <div className="min-h-screen bg-black relative overflow-hidden flex items-center justify-center">
      {/* Enhanced background effects */}
      <div className="absolute inset-0 z-0">
        <div className="absolute inset-0 bg-gradient-to-b from-brand-orange/5 via-black to-black"></div>
      </div>
      
      {/* Sparkles background effect */}
      <div className="absolute inset-0 z-10">
        <SparklesCore
          id="tsparticlesfullpage"
          background="transparent"
          minSize={0.6}
          maxSize={1.4}
          particleDensity={70}
          className="w-full h-full"
          particleColor="#FF4500"
          speed={0.5}
        />
      </div>
      
      {/* Meteors effect */}
      <div className="absolute inset-0 overflow-hidden z-10">
        <Meteors number={15} className="opacity-70" />
      </div>
      
      {/* Robot character background */}
      <div 
        className="absolute inset-0 z-10"
        style={{
          backgroundImage: 'url(/lovable-uploads/c1b6478d-f073-4180-ae4c-9669aebaa801.png)',
          backgroundSize: '800px',
          backgroundPosition: 'center',
          backgroundRepeat: 'repeat',
          transform: 'scale(1.1)',
          filter: 'saturate(0.8) brightness(0.8)',
          mixBlendMode: 'overlay',
          opacity: '0.2',
          pointerEvents: 'none'
        }}
      />
      
      {/* Animated glowing orb */}
      <div className="absolute inset-0 z-10 flex items-center justify-center pointer-events-none">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
          className="absolute w-[600px] h-[600px] rounded-full bg-gradient-to-r from-brand-orange/20 to-transparent blur-[80px]"
          style={{
            animation: "pulse 8s ease-in-out infinite alternate"
          }}
        />
      </div>
      
      <motion.div 
        className="relative z-20 w-full min-h-screen px-4 py-12 flex flex-col items-center justify-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <div className="max-w-[1000px] mx-auto w-full flex flex-col items-center space-y-12">
          <ThankYouHeader 
            firstName={firstName} 
          />
          
          <ResourceOffer 
            segment={formData.segment} 
            serviceType={formData.service_type}
            goal={formData.goal}
          />
          
          <DiscoveryMeetingForm />
        </div>
      </motion.div>
      
      {/* Add pulse animation keyframes */}
      <style dangerouslySetInnerHTML={{
        __html: `
          @keyframes pulse {
            0% {
              transform: scale(1);
              opacity: 0.2;
            }
            50% {
              transform: scale(1.1);
              opacity: 0.3;
            }
            100% {
              transform: scale(1);
              opacity: 0.2;
            }
          }
        `
      }} />
    </div>
  );
}
