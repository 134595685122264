
import { motion } from "framer-motion";
import { SparklesCore } from "@/components/ui/sparkles";

export const SuccessSection = () => {
  return (
    <div className="relative min-h-screen flex flex-col items-center justify-center px-4 md:px-20 overflow-visible">
      <div className="absolute inset-0 -bottom-[100vh]">
        <SparklesCore
          id="awards-sparkles"
          background="transparent"
          minSize={0.4}
          maxSize={1}
          particleDensity={70}
          className="w-full h-[200vh]"
          particleColor="rgba(255, 126, 0, 0.3)"
          speed={0.5}
        />
      </div>

      <motion.div 
        className="absolute inset-0 opacity-10 mix-blend-luminosity z-10"
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 0.1 }}
        transition={{ duration: 1 }}
      >
        <div 
          className="w-full h-full bg-contain bg-no-repeat bg-center"
          style={{ 
            backgroundImage: 'url(/lovable-uploads/82729488-908f-4d85-a820-59087e990b49.png)',
            filter: 'brightness(1.2) contrast(1.1)'
          }} 
        />
      </motion.div>

      <div className="relative z-20 text-center space-y-16 max-w-6xl">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="space-y-2"
        >
          <h2 className="text-5xl sm:text-7xl font-display font-bold text-white">
            Innovators on Fire Award
          </h2>
          <p className="text-xl text-gray-400">Washington Business Journal</p>
        </motion.div>

        <div className="grid md:grid-cols-2 gap-8 md:gap-12">
          {[
            { title: "DC Coolest Company Award", source: "DC Inno" },
            { title: "World Changing Idea Award", source: "Fast Company Magazine" }
          ].map((award, index) => (
            <motion.div
              key={award.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 + index * 0.1 }}
              className="space-y-2"
            >
              <h3 className="text-3xl sm:text-4xl font-display font-bold text-white">
                {award.title}
              </h3>
              <p className="text-lg text-gray-400">{award.source}</p>
            </motion.div>
          ))}
        </div>

        <div className="grid md:grid-cols-3 gap-6 md:gap-8">
          {[
            { title: "Design for Good Award", source: "Index Design" },
            { title: "Best Software Developer", source: "Tech Behemoths" },
            { title: "Awarded Key to the City", source: "Arlington, Virginia" }
          ].map((award, index) => (
            <motion.div
              key={award.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.4 + index * 0.1 }}
              className="space-y-2 hover:scale-105 transition-transform duration-300"
            >
              <h3 className="text-2xl sm:text-3xl font-display font-bold text-white">
                {award.title}
              </h3>
              <p className="text-base text-gray-400">{award.source}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};
