import { create } from 'zustand';
import { ProjectAnalysis } from '../types';

interface ProjectStore {
  analysis: ProjectAnalysis | null;
  setAnalysis: (analysis: ProjectAnalysis) => void;
  clearAnalysis: () => void;
}

export const useProjectStore = create<ProjectStore>((set) => ({
  analysis: null,
  setAnalysis: (analysis) => set({ analysis }),
  clearAnalysis: () => set({ analysis: null }),
}));