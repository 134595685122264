
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar";
import { 
  LayoutDashboard, 
  Users, 
  Building2, 
  Mail, 
  Phone,
  LineChart,
  Settings,
  Filter,
  Target
} from "lucide-react";
import { useNavigate } from "react-router-dom";

export function CRMSidebar() {
  const navigate = useNavigate();

  const menuItems = [
    {
      title: "Overview",
      icon: LayoutDashboard,
      path: "/admin/sales",
    },
    {
      title: "Pipeline",
      icon: Filter,
      path: "/admin/sales/pipeline",
    },
    {
      title: "Leads",
      icon: Target,
      path: "/admin/sales/leads",
    },
    {
      title: "Contacts",
      icon: Users,
      path: "/admin/sales/contacts",
    },
    {
      title: "Companies",
      icon: Building2,
      path: "/admin/sales/companies",
    },
    {
      title: "Email",
      icon: Mail,
      path: "/admin/sales/email",
    },
    {
      title: "Calls",
      icon: Phone,
      path: "/admin/sales/calls",
    },
    {
      title: "Analytics",
      icon: LineChart,
      path: "/admin/sales/analytics",
    },
    {
      title: "Settings",
      icon: Settings,
      path: "/admin/sales/settings",
    },
  ];

  return (
    <Sidebar>
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupLabel>CRM</SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>
              {menuItems.map((item) => (
                <SidebarMenuItem key={item.title}>
                  <SidebarMenuButton onClick={() => navigate(item.path)}>
                    <item.icon className="w-4 h-4 mr-2" />
                    <span>{item.title}</span>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
    </Sidebar>
  );
}
