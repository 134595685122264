
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const ScaleSection = () => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  
  const handleNavigateToEnterprise = () => {
    navigate('/enterprise', { replace: true });
    window.scrollTo(0, 0);
  };
  
  return (
    <div 
      className="relative min-h-screen flex flex-col justify-center px-4 sm:px-8 md:px-20"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <motion.div 
        className={`absolute right-0 top-0 w-full md:w-1/2 h-full transition-all duration-700 ${
          isHovered ? "opacity-40 mix-blend-normal" : "opacity-20 mix-blend-luminosity"
        }`}
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: isHovered ? 0.4 : 0.2 }}
        transition={{ duration: 1 }}
      >
        <div 
          className="w-full h-full bg-contain bg-no-repeat bg-center md:bg-right transition-all duration-700"
          style={{ 
            backgroundImage: 'url(/lovable-uploads/4519641b-2798-4d51-8cb6-3d612cc6fed5.png)',
            filter: isHovered ? 'brightness(1.4) contrast(1.2) saturate(1.3)' : 'brightness(1.2) contrast(1.1)'
          }} 
        />
      </motion.div>
      
      <motion.h2 
        className="text-5xl sm:text-[12vw] leading-tight sm:leading-none font-display font-bold text-white relative z-10"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        Scale to<br />Enterprise<br />in Months
      </motion.h2>
      
      <motion.div 
        className="absolute top-1/2 right-4 sm:right-20 transform -translate-y-1/2 z-20"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
      >
        <Button 
          onClick={handleNavigateToEnterprise}
          className="rounded-full bg-white text-brand-darkCharcoal hover:bg-white/90 px-6 sm:px-8 py-4 sm:py-6 text-base sm:text-lg group backdrop-blur-sm"
        >
          Enterprise Solutions
          <ChevronRight className="ml-2 h-4 w-4 sm:h-5 sm:w-5 group-hover:translate-x-1 transition-transform" />
        </Button>
      </motion.div>
    </div>
  );
};
