
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { motion } from "framer-motion";
import { Loader2, Shield } from "lucide-react";
import { RequirementsList } from "./components/RequirementsList";
import { ComplianceResults } from "./components/ComplianceResults";
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue 
} from "@/components/ui/select";

export const GovernmentComplianceAnalyzer = () => {
  const [requirements, setRequirements] = useState({
    dataPrivacy: false,
    security: false,
    fedramp: false,
    fisma: false,
    section508: false,
  });
  
  const [agencyType, setAgencyType] = useState<string>("");
  const [dataClassification, setDataClassification] = useState<string>("");
  const [email, setEmail] = useState("");
  const [analysisResult, setAnalysisResult] = useState<any>(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [currentStep, setCurrentStep] = useState<string | null>(null);

  const handleRequirementChange = (key: string, value: boolean) => {
    setRequirements(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const countSelectedRequirements = () => {
    return Object.values(requirements).filter(Boolean).length;
  };

  const generateAnalysis = async () => {
    const selectedRequirements = Object.entries(requirements)
      .filter(([_, isSelected]) => isSelected)
      .map(([id, _]) => id);

    if (selectedRequirements.length === 0) {
      toast.error("Please select at least one compliance requirement to analyze");
      return;
    }

    if (!email) {
      toast.error("Please enter your email to receive the detailed assessment");
      return;
    }

    if (!agencyType) {
      toast.error("Please select your agency type");
      return;
    }

    if (!dataClassification) {
      toast.error("Please select your data classification level");
      return;
    }

    setIsAnalyzing(true);
    setCurrentStep("Analyzing compliance requirements...");

    try {
      // Step 1: Analyzing requirements
      await new Promise(resolve => setTimeout(resolve, 1000));
      setCurrentStep("Evaluating security controls...");

      // Step 2: Evaluating security controls
      await new Promise(resolve => setTimeout(resolve, 1000));
      setCurrentStep("Generating compliance roadmap...");

      // Step 3: Generating final analysis
      try {
        const { data, error } = await supabase.functions.invoke('analyze-government-compliance', {
          body: {
            selectedRequirements,
            email,
            agencyType,
            dataClassification,
          }
        });

        if (error) {
          console.error("Function invoke error:", error);
          throw error;
        }

        setAnalysisResult(data);
        setIsCompleted(true);
        
        try {
          const { error: insertError } = await supabase
            .from("government_analyses")
            .insert({
              requirements: selectedRequirements,
              agency_type: agencyType,
              data_classification: dataClassification,
              email: email,
              analysis_result: data
            });

          if (insertError) {
            console.error("Error saving analysis:", insertError);
            toast.error("Your assessment was generated but we couldn't save it for future reference.");
          } else {
            console.log("Analysis saved successfully");
          }
        } catch (dbError) {
          console.error("Database error:", dbError);
        }

        toast.success("Your compliance assessment is complete!");
      } catch (functionError) {
        console.error("Function error:", functionError);
        toast.error("There was an error generating your compliance assessment. Please try again.");
      }
    } catch (error) {
      console.error('Error generating analysis:', error);
      toast.error("Failed to generate assessment. Please try again.");
    } finally {
      setIsAnalyzing(false);
      setCurrentStep(null);
    }
  };

  const resetAnalysis = () => {
    setRequirements({
      dataPrivacy: false,
      security: false,
      fedramp: false,
      fisma: false,
      section508: false,
    });
    setAgencyType("");
    setDataClassification("");
    setEmail("");
    setAnalysisResult(null);
    setIsCompleted(false);
  };

  return (
    <div className="max-w-5xl mx-auto">
      {!isCompleted ? (
        <>
          <div className="mb-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="inline-flex items-center px-4 py-2 bg-emerald-900/20 text-emerald-400 rounded-full text-sm font-medium mb-4"
            >
              <Shield className="w-4 h-4 mr-2" />
              Government Compliance Assessment Tool
            </motion.div>
            <motion.h2 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              className="text-3xl md:text-4xl font-bold dark:text-white mb-4"
            >
              Evaluate Your Agency's Compliance Requirements
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="text-gray-600 dark:text-gray-300 text-lg"
            >
              Select the compliance standards and requirements relevant to your agency. Our tool will analyze gaps, provide recommendations, and generate a compliance roadmap tailored to your needs.
            </motion.p>
          </div>

          {isAnalyzing && currentStep && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="mb-8"
            >
              <div className="h-1 w-full bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
                <div className="h-full bg-emerald-500 animate-pulse rounded-full"></div>
              </div>
              <p className="text-sm text-emerald-600 dark:text-emerald-400 mt-2">{currentStep}</p>
            </motion.div>
          )}

          <div className="space-y-8">
            {/* Requirements Section */}
            <Card className="p-6 backdrop-blur-sm border border-emerald-900/30 rounded-lg bg-white/50 dark:bg-gray-900/50">
              <RequirementsList 
                requirements={requirements} 
                onRequirementChange={handleRequirementChange} 
              />
            </Card>

            {/* Agency Information Form */}
            <Card className="p-6 backdrop-blur-sm border border-emerald-900/30 rounded-lg bg-white/50 dark:bg-gray-900/50">
              <h3 className="text-xl font-semibold text-emerald-700 dark:text-emerald-400 mb-4">Agency Information</h3>
              
              <div className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="space-y-2">
                    <label htmlFor="agencyType" className="text-sm text-gray-600 dark:text-gray-300">Agency Type</label>
                    <Select value={agencyType} onValueChange={setAgencyType}>
                      <SelectTrigger className="w-full border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800">
                        <SelectValue placeholder="Select agency type" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="federal">Federal Agency</SelectItem>
                        <SelectItem value="state">State Agency</SelectItem>
                        <SelectItem value="local">Local Government</SelectItem>
                        <SelectItem value="tribal">Tribal Government</SelectItem>
                        <SelectItem value="military">Military / Defense</SelectItem>
                        <SelectItem value="other">Other Government Entity</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="space-y-2">
                    <label htmlFor="dataClassification" className="text-sm text-gray-600 dark:text-gray-300">Data Classification Level</label>
                    <Select value={dataClassification} onValueChange={setDataClassification}>
                      <SelectTrigger className="w-full border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800">
                        <SelectValue placeholder="Select data classification" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="public">Public</SelectItem>
                        <SelectItem value="sensitive">Sensitive But Unclassified (SBU)</SelectItem>
                        <SelectItem value="confidential">Confidential</SelectItem>
                        <SelectItem value="secret">Secret</SelectItem>
                        <SelectItem value="topsecret">Top Secret</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                
                <div className="space-y-2">
                  <label htmlFor="email" className="text-sm text-gray-600 dark:text-gray-300">Email for Assessment Delivery</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="your@agency.gov"
                    className="w-full p-3 rounded-md border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
                  />
                  <p className="text-xs text-gray-500 dark:text-gray-400">We'll send a detailed PDF assessment to this email</p>
                </div>
                
                <Button 
                  className="w-full bg-emerald-600 hover:bg-emerald-700 text-white py-5 text-lg dark:bg-emerald-600/90 dark:hover:bg-emerald-600"
                  onClick={generateAnalysis}
                  disabled={isAnalyzing || countSelectedRequirements() === 0}
                >
                  {isAnalyzing ? (
                    <>
                      <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                      Analyzing...
                    </>
                  ) : (
                    <>
                      {countSelectedRequirements() === 0 ? "Select Requirements to Analyze" : `Analyze ${countSelectedRequirements()} Selected Requirements`}
                    </>
                  )}
                </Button>
              </div>
            </Card>
          </div>
        </>
      ) : (
        <ComplianceResults analysisResult={analysisResult} email={email} onReset={resetAnalysis} />
      )}
    </div>
  );
};
