
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Shield, FileCheck, Clock, DollarSign, AlertTriangle, CheckCircle, XCircle, HelpCircle } from "lucide-react";

interface ComplianceResultsProps {
  analysisResult: any;
  email: string;
  onReset: () => void;
}

export const ComplianceResults = ({ analysisResult, email, onReset }: ComplianceResultsProps) => {
  if (!analysisResult) return null;

  const getStatusIcon = (status: string) => {
    switch (status.toLowerCase()) {
      case 'compliant':
      case 'implemented':
        return <CheckCircle className="w-5 h-5 text-green-500" />;
      case 'partial':
        return <HelpCircle className="w-5 h-5 text-amber-500" />;
      case 'non-compliant':
      case 'not implemented':
        return <XCircle className="w-5 h-5 text-red-500" />;
      default:
        return <HelpCircle className="w-5 h-5 text-gray-500" />;
    }
  };

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'compliant':
      case 'implemented':
        return 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300';
      case 'partial':
        return 'bg-amber-100 text-amber-800 dark:bg-amber-900/30 dark:text-amber-300';
      case 'non-compliant':
      case 'not implemented':
        return 'bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-300';
      default:
        return 'bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-300';
    }
  };

  const getImpactBadge = (impact: string) => {
    switch (impact.toLowerCase()) {
      case 'critical':
        return <span className="px-2 py-1 text-xs rounded bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-300">Critical</span>;
      case 'high':
        return <span className="px-2 py-1 text-xs rounded bg-amber-100 text-amber-800 dark:bg-amber-900/30 dark:text-amber-300">High</span>;
      case 'medium':
        return <span className="px-2 py-1 text-xs rounded bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-300">Medium</span>;
      case 'low':
        return <span className="px-2 py-1 text-xs rounded bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300">Low</span>;
      default:
        return <span className="px-2 py-1 text-xs rounded bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-300">Unknown</span>;
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="space-y-8 my-8"
    >
      <Card className="p-6 border-emerald-700 bg-white/80 dark:bg-gray-900/80 backdrop-blur-sm dark:border-emerald-900/50">
        <h3 className="text-2xl font-bold text-emerald-700 dark:text-emerald-400 mb-4">Compliance Assessment Results</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
          <div className="bg-emerald-50 dark:bg-emerald-900/10 border border-emerald-100 dark:border-emerald-900/30 rounded-lg p-4">
            <h4 className="text-emerald-700 dark:text-emerald-400 font-medium mb-2 flex items-center">
              <Shield className="w-4 h-4 mr-2" /> Priority Level
            </h4>
            <p className="text-gray-900 dark:text-gray-100">{analysisResult.priority}</p>
          </div>
          <div className="bg-emerald-50 dark:bg-emerald-900/10 border border-emerald-100 dark:border-emerald-900/30 rounded-lg p-4">
            <h4 className="text-emerald-700 dark:text-emerald-400 font-medium mb-2 flex items-center">
              <Clock className="w-4 h-4 mr-2" /> Timeline Estimate
            </h4>
            <p className="text-gray-900 dark:text-gray-100">{analysisResult.timelineEstimate}</p>
          </div>
          <div className="bg-emerald-50 dark:bg-emerald-900/10 border border-emerald-100 dark:border-emerald-900/30 rounded-lg p-4">
            <h4 className="text-emerald-700 dark:text-emerald-400 font-medium mb-2 flex items-center">
              <DollarSign className="w-4 h-4 mr-2" /> Cost Estimate
            </h4>
            <p className="text-gray-900 dark:text-gray-100">{analysisResult.costEstimate}</p>
          </div>
        </div>
        
        <h3 className="text-xl font-semibold text-emerald-700 dark:text-emerald-400 mt-8 mb-4">Compliance Gap Analysis</h3>
        <Table className="border dark:border-gray-800 mb-6">
          <TableHeader className="bg-emerald-50 dark:bg-emerald-900/10">
            <TableRow>
              <TableHead className="text-emerald-700 dark:text-emerald-400">Requirement</TableHead>
              <TableHead className="text-emerald-700 dark:text-emerald-400">Status</TableHead>
              <TableHead className="text-emerald-700 dark:text-emerald-400">Description</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {analysisResult.complianceGaps.map((gap: any, index: number) => (
              <TableRow key={index} className="border-t dark:border-gray-800">
                <TableCell className="font-medium text-gray-900 dark:text-white">{gap.requirement}</TableCell>
                <TableCell>
                  <div className="flex items-center gap-2">
                    {getStatusIcon(gap.status)}
                    <span className={`px-2 py-1 text-xs rounded ${getStatusColor(gap.status)}`}>
                      {gap.status}
                    </span>
                  </div>
                </TableCell>
                <TableCell className="text-gray-600 dark:text-gray-300">{gap.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        
        <h3 className="text-xl font-semibold text-emerald-700 dark:text-emerald-400 mt-8 mb-4">Security Controls Assessment</h3>
        <Table className="border dark:border-gray-800 mb-6">
          <TableHeader className="bg-emerald-50 dark:bg-emerald-900/10">
            <TableRow>
              <TableHead className="text-emerald-700 dark:text-emerald-400">Control Family</TableHead>
              <TableHead className="text-emerald-700 dark:text-emerald-400">Status</TableHead>
              <TableHead className="text-emerald-700 dark:text-emerald-400">Impact</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {analysisResult.securityControls.map((control: any, index: number) => (
              <TableRow key={index} className="border-t dark:border-gray-800">
                <TableCell className="font-medium text-gray-900 dark:text-white">{control.name}</TableCell>
                <TableCell>
                  <div className="flex items-center gap-2">
                    {getStatusIcon(control.status)}
                    <span className={`px-2 py-1 text-xs rounded ${getStatusColor(control.status)}`}>
                      {control.status}
                    </span>
                  </div>
                </TableCell>
                <TableCell>{getImpactBadge(control.impact)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        
        <h3 className="text-xl font-semibold text-emerald-700 dark:text-emerald-400 mt-8 mb-4">Recommended Actions</h3>
        <div className="bg-emerald-50 dark:bg-emerald-900/10 border border-emerald-100 dark:border-emerald-900/30 rounded-lg p-4 mb-6">
          <ul className="space-y-2">
            {analysisResult.recommendedActions.map((action: string, index: number) => (
              <li key={index} className="flex items-start">
                <AlertTriangle className="w-5 h-5 text-amber-500 mt-0.5 mr-2 flex-shrink-0" />
                <span className="text-gray-700 dark:text-gray-300">{action}</span>
              </li>
            ))}
          </ul>
        </div>
        
        <h3 className="text-xl font-semibold text-emerald-700 dark:text-emerald-400 mt-8 mb-4">Implementation Roadmap</h3>
        <Table className="border dark:border-gray-800 mb-6">
          <TableHeader className="bg-emerald-50 dark:bg-emerald-900/10">
            <TableRow>
              <TableHead className="text-emerald-700 dark:text-emerald-400">Phase</TableHead>
              <TableHead className="text-emerald-700 dark:text-emerald-400">Duration</TableHead>
              <TableHead className="text-emerald-700 dark:text-emerald-400">Focus</TableHead>
              <TableHead className="text-emerald-700 dark:text-emerald-400">Key Deliverables</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {analysisResult.implementationRoadmap.map((phase: any, index: number) => (
              <TableRow key={index} className="border-t dark:border-gray-800">
                <TableCell className="font-medium text-gray-900 dark:text-white">{phase.phase}</TableCell>
                <TableCell className="text-gray-600 dark:text-gray-300">{phase.duration}</TableCell>
                <TableCell className="text-gray-600 dark:text-gray-300">{phase.focus}</TableCell>
                <TableCell className="text-gray-600 dark:text-gray-300">{phase.key_deliverables}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        
        <h3 className="text-xl font-semibold text-emerald-700 dark:text-emerald-400 mt-8 mb-4">Benefits of Compliance</h3>
        <div className="bg-emerald-50 dark:bg-emerald-900/10 border border-emerald-100 dark:border-emerald-900/30 rounded-lg p-4 mb-6">
          <ul className="space-y-2">
            {analysisResult.benefits.map((benefit: string, index: number) => (
              <li key={index} className="flex items-start">
                <CheckCircle className="w-5 h-5 text-green-500 mt-0.5 mr-2 flex-shrink-0" />
                <span className="text-gray-700 dark:text-gray-300">{benefit}</span>
              </li>
            ))}
          </ul>
        </div>
        
        <div className="mt-8 flex flex-col items-center justify-center">
          <p className="text-gray-600 dark:text-gray-400 text-center mb-4">
            A comprehensive PDF assessment has been sent to <span className="text-emerald-600 dark:text-emerald-400 font-medium">{email}</span> with detailed implementation guidelines and agency-specific recommendations.
          </p>
          <Button
            onClick={onReset}
            className="bg-gray-600 hover:bg-gray-500 text-white dark:bg-gray-700 dark:hover:bg-gray-600"
          >
            Start New Assessment
          </Button>
        </div>
      </Card>
    </motion.div>
  );
};
