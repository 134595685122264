
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Search, Plus, Bell } from "lucide-react";

export const CRMHeader = () => {
  return (
    <header className="border-b border-border bg-card/80 backdrop-blur-sm">
      <div className="flex h-16 items-center px-6 gap-4">
        <div className="flex flex-1 items-center gap-4">
          <div className="relative w-full max-w-md">
            <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              type="search"
              placeholder="Search contacts, companies, or deals..."
              className="w-full bg-background pl-8"
            />
          </div>
        </div>
        <div className="flex items-center gap-4">
          <Button variant="outline" size="sm">
            <Plus className="h-4 w-4 mr-2" />
            Add New
          </Button>
          <Button variant="ghost" size="icon">
            <Bell className="h-5 w-5" />
          </Button>
        </div>
      </div>
    </header>
  );
};
