import { Card } from "@/components/ui/card";
import { Lightbulb, ArrowRight } from "lucide-react";
import { Button } from "@/components/ui/button";

interface BestPracticesProps {
  onLearnMore: () => void;
}

export const BestPracticesSection = ({ onLearnMore }: BestPracticesProps) => {
  return (
    <Card className="p-6">
      <div className="flex items-center gap-3 mb-4">
        <Lightbulb className="w-6 h-6 text-[#FF4D00]" />
        <h3 className="text-xl font-semibold">Best Practices & Success Tips</h3>
      </div>

      <p className="text-muted-foreground mb-6">
        Building a successful application involves more than just coding. Here are proven strategies
        that will help ensure your project's success, along with clear explanations of why they matter
        and how they benefit you.
      </p>

      <div className="space-y-6">
        <div className="p-6 bg-secondary/5 rounded-lg">
          <h4 className="font-medium mb-2">User Research & Validation</h4>
          <div className="space-y-3">
            <p className="text-sm text-muted-foreground">
              <strong>What it is:</strong> Before diving deep into development, we help you validate
              your ideas with potential users. This is like test-driving a car before buying it - you
              want to make sure it meets your needs.
            </p>
            <p className="text-sm text-muted-foreground">
              <strong>Why it matters:</strong> This step helps ensure you're building something people
              actually want and will pay for, saving you time and money in the long run.
            </p>
            <div className="mt-4">
              <p className="text-sm font-medium mb-2">We recommend:</p>
              <ul className="list-none space-y-2">
                <li className="flex items-start gap-2 text-sm text-muted-foreground">
                  <span className="w-2 h-2 rounded-full bg-[#FF4D00] mt-2 flex-shrink-0" />
                  Conducting user interviews to understand needs
                </li>
                <li className="flex items-start gap-2 text-sm text-muted-foreground">
                  <span className="w-2 h-2 rounded-full bg-[#FF4D00] mt-2 flex-shrink-0" />
                  Creating user personas to guide decisions
                </li>
                <li className="flex items-start gap-2 text-sm text-muted-foreground">
                  <span className="w-2 h-2 rounded-full bg-[#FF4D00] mt-2 flex-shrink-0" />
                  Testing simple prototypes before full development
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="p-6 bg-secondary/5 rounded-lg">
          <h4 className="font-medium mb-2">Journey Mapping</h4>
          <div className="space-y-3">
            <p className="text-sm text-muted-foreground">
              <strong>What it is:</strong> A visual story of how someone will use your application
              from start to finish - like creating a roadmap for your users' experience.
            </p>
            <p className="text-sm text-muted-foreground">
              <strong>Why it matters:</strong> This helps identify potential problems and
              opportunities to make the experience better before we start building, saving time
              and ensuring user satisfaction.
            </p>
          </div>
        </div>

        <div className="p-6 bg-secondary/5 rounded-lg">
          <h4 className="font-medium mb-2">Feature Prioritization</h4>
          <div className="space-y-3">
            <p className="text-sm text-muted-foreground">
              Not all features are equally important. We'll help you identify which features to
              build first based on:
            </p>
            <ul className="list-none space-y-2">
              <li className="flex items-start gap-2 text-sm text-muted-foreground">
                <span className="w-2 h-2 rounded-full bg-[#FF4D00] mt-2 flex-shrink-0" />
                <div>
                  <strong>User Value:</strong> What will help users the most?
                </div>
              </li>
              <li className="flex items-start gap-2 text-sm text-muted-foreground">
                <span className="w-2 h-2 rounded-full bg-[#FF4D00] mt-2 flex-shrink-0" />
                <div>
                  <strong>Business Impact:</strong> What will drive growth?
                </div>
              </li>
              <li className="flex items-start gap-2 text-sm text-muted-foreground">
                <span className="w-2 h-2 rounded-full bg-[#FF4D00] mt-2 flex-shrink-0" />
                <div>
                  <strong>Development Effort:</strong> What can we build quickly?
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-8 p-6 bg-[#FF4D00]/5 rounded-lg">
          <h4 className="font-medium mb-2">Need Help With The Non-Technical Side?</h4>
          <p className="text-sm text-muted-foreground mb-4">
            Our Unstuck Anywhere program provides expert guidance, resources, and mentorship to help
            you with user research, business validation, and growth strategies. Think of it as having
            a startup advisor in your pocket.
          </p>
          <Button 
            onClick={onLearnMore}
            className="w-full bg-[#FF4D00] hover:bg-[#FF4D00]/90 text-white group"
          >
            Learn More About Unstuck Anywhere
            <ArrowRight className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" />
          </Button>
        </div>
      </div>
    </Card>
  );
};