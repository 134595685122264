
import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@/components/ThemeProvider";
import { Toaster } from "sonner";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { AuthProvider } from "@/contexts/AuthContext";

// Page imports
import Index from "@/pages/Index";
import About from "@/pages/About";
import Contact from "@/pages/Contact";
import Services from "@/pages/Services";
import Work from "@/pages/Work";
import Blog from "@/pages/Blog";
import BlogPost from "@/pages/BlogPost";
import EmployeePortalPage from "@/pages/EmployeePortalPage";
import AdminPage from "@/pages/AdminPage";
import EmployeeLoginPage from "@/pages/EmployeeLoginPage";
import AdminLoginPage from "@/pages/AdminLoginPage";
import StartupPage from "@/pages/StartupPage";
import EnterprisePage from "@/pages/EnterprisePage";
import GovernmentPage from "@/pages/GovernmentPage";
import FlarePage from "@/pages/FlarePage";
import FlareApplyPage from "@/pages/FlareApplyPage";
import VenturesPage from "@/pages/VenturesPage";
import VenturesApplyPage from "@/pages/VenturesApplyPage";
import UnstuckAnywherePage from "@/pages/UnstuckAnywherePage";
import Guide from "@/pages/Guide";
import DesignPlayground from "@/pages/DesignPlayground";
import ClientShowcase from "@/pages/ClientShowcase";
import WorkspacePage from "@/pages/WorkspacePage";
import AuthPage from "@/pages/AuthPage";
import ThankYou from "@/pages/ThankYou";
import IntakeFormPage from "@/pages/IntakeFormPage";
import { FlareDesignCanvas } from "@/components/programs/flare/FlareDesignCanvas";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PasswordProtection } from "@/components/programs/flare/PasswordProtection";

const queryClient = new QueryClient();

const App: React.FC = () => {
  useEffect(() => {
    console.log("[App] Component mounted");
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthProvider>
          <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
            <ErrorBoundary>
              <Routes>
                <Route path="/" element={<Index />} />
                <Route path="/thank-you" element={<ThankYou />} />
                <Route path="/auth" element={<AuthPage />} />
                <Route path="/about" element={<About />} />
                <Route path="/services" element={<Services />} />
                <Route path="/work" element={<Work />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:slug" element={<BlogPost />} />
                <Route path="/employee/*" element={<EmployeePortalPage />} />
                <Route path="/admin" element={<AdminPage />} />
                <Route path="/employee/login" element={<EmployeeLoginPage />} />
                <Route path="/admin/login" element={<AdminLoginPage />} />
                <Route path="/startup" element={<StartupPage />} />
                <Route path="/enterprise" element={<EnterprisePage />} />
                <Route path="/government" element={<GovernmentPage />} />
                <Route path="/flare/*" element={<FlarePage />} />
                <Route path="/flare/apply" element={<FlareApplyPage />} />
                <Route path="/ventures" element={<VenturesPage />} />
                <Route path="/ventures/apply" element={<VenturesApplyPage />} />
                <Route path="/anywhere" element={<UnstuckAnywherePage />} />
                <Route path="/guide/:slug" element={<Guide />} />
                <Route path="/playground" element={<DesignPlayground />} />
                <Route path="/clients" element={<ClientShowcase />} />
                <Route path="/workspace/*" element={<WorkspacePage />} />
                <Route path="/intake" element={<IntakeFormPage />} />
                <Route path="/employee/flare-canvas" element={
                  <PasswordProtection>
                    <FlareDesignCanvas />
                  </PasswordProtection>
                } />
                <Route path="/employee/flare-canvas/:designId" element={
                  <PasswordProtection>
                    <FlareDesignCanvas />
                  </PasswordProtection>
                } />
                <Route path="/flare/canvas" element={
                  <PasswordProtection>
                    <FlareDesignCanvas />
                  </PasswordProtection>
                } />
                <Route path="/flare/canvas/:designId" element={
                  <PasswordProtection>
                    <FlareDesignCanvas />
                  </PasswordProtection>
                } />
              </Routes>
              <Toaster />
            </ErrorBoundary>
          </ThemeProvider>
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
