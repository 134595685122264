import { motion } from "framer-motion";
import { useState } from "react";

const technologies = [
  { name: "React", color: "#61DAFB" },
  { name: "TypeScript", color: "#3178C6" },
  { name: "Node.js", color: "#339933" },
  { name: "Tailwind", color: "#06B6D4" },
  { name: "Supabase", color: "#3ECF8E" },
  { name: "PostgreSQL", color: "#4169E1" }
];

export const TechStack = () => {
  const [hoveredTech, setHoveredTech] = useState<string | null>(null);

  return (
    <motion.div 
      className="relative h-[400px] rounded-xl bg-black/5 backdrop-blur-xl p-8 overflow-hidden"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div className="absolute inset-0 flex items-center justify-center">
        {technologies.map((tech, index) => {
          const angle = (index * 2 * Math.PI) / technologies.length;
          const radius = 150;
          const x = Math.cos(angle) * radius;
          const y = Math.sin(angle) * radius;

          return (
            <motion.div
              key={tech.name}
              className="absolute flex items-center justify-center"
              style={{
                x: x + radius,
                y: y + radius,
              }}
              whileHover={{ scale: 1.2 }}
              animate={{
                scale: hoveredTech === tech.name ? 1.2 : 1,
                opacity: hoveredTech ? (hoveredTech === tech.name ? 1 : 0.5) : 1
              }}
              onHoverStart={() => setHoveredTech(tech.name)}
              onHoverEnd={() => setHoveredTech(null)}
            >
              <div 
                className="w-20 h-20 rounded-full flex items-center justify-center text-white font-bold"
                style={{ backgroundColor: tech.color }}
              >
                {tech.name}
              </div>
            </motion.div>
          );
        })}
      </div>
    </motion.div>
  );
};