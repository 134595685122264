import { Card } from "@/components/ui/card";
import { Phone, Mail, Globe, MapPin } from "lucide-react";
import { CompanyWithRelations } from "@/types/company";

interface CompanyInfoProps {
  company: CompanyWithRelations;
}

export const CompanyInfo = ({ company }: CompanyInfoProps) => {
  return (
    <>
      {company.description && (
        <Card className="p-4">
          <h4 className="font-medium mb-2">About</h4>
          <p className="text-sm text-muted-foreground">{company.description}</p>
        </Card>
      )}

      <Card className="p-4 space-y-3">
        <h4 className="font-medium mb-2">Contact Information</h4>
        {company.contact_info?.phones?.[0] && (
          <div className="flex items-center gap-3 text-sm">
            <Phone className="w-4 h-4 text-muted-foreground" />
            <a href={`tel:${company.contact_info.phones[0]}`} className="hover:underline">
              {company.contact_info.phones[0]}
            </a>
          </div>
        )}
        {company.contact_info?.emails?.[0] && (
          <div className="flex items-center gap-3 text-sm">
            <Mail className="w-4 h-4 text-muted-foreground" />
            <a href={`mailto:${company.contact_info.emails[0]}`} className="hover:underline">
              {company.contact_info.emails[0]}
            </a>
          </div>
        )}
        {company.website && (
          <div className="flex items-center gap-3 text-sm">
            <Globe className="w-4 h-4 text-muted-foreground" />
            <a href={company.website} target="_blank" rel="noopener noreferrer" className="hover:underline">
              {company.website}
            </a>
          </div>
        )}
        {company.address && (
          <div className="flex items-center gap-3 text-sm">
            <MapPin className="w-4 h-4 text-muted-foreground" />
            <span>{company.address}</span>
          </div>
        )}
      </Card>
    </>
  );
};