
import { motion } from "framer-motion";

const clientNames = [
  "Georgetown University",
  "Chico State",
  "American University",
  "The George Washington University",
  "Professional Fellows",
  "Sharjah Startup Studio",
  "Korea Innovation Center (KIC)",
  "Latino Economic Development Center",
  "UNTUCKit",
  "Lovelytics",
  "Pfizer",
  "AWS",
  "Six Flags",
  "Mount Sinai Hospital",
  "The Washington Center",
  "Traxyl",
  "Halcyon",
  "Under Armour Softball Factory",
  "American Real Estate Partners",
  "Arlington Economic Development",
  "Rosslyn BID",
  "Reyets",
  "Rooam",
  "Pryze",
  "Grocery Magic",
  "Tuesdai",
  "EEZZ Answers",
  "Battery XChange",
  "Action Grad",
  "Billie Bot",
  "Pet Connect",
  "WIWO",
  "Space Interactions",
  "Freely Payments",
  "G Square Investments",
  "Seed Spot",
  "WeWork Labs",
  "Rare Breed Ventures",
  "Vitalize Ventures",
  "Rogue Women's Fund",
  "Camelback Ventures",
  "Village Capital",
  "Dream.org",
  "Gener8tor",
  "Liberation Fund",
  "SHRM Labs",
  "Founders Institute",
  "AI Leadership Institute",
  "Value Technology Foundation",
  "Misk Foundation",
  "Legacy International",
  "U.S. Department of State",
  "USAID",
  "AMIDEAST",
  "The Brand Amplifiers",
  "SPACES",
  "Netflix",
  "Memory Anchor",
  "John Hopkins Hospital"
];

export const ClientLogosBackground = () => {
  // Create balanced columns with equal number of items
  const itemsPerColumn = Math.ceil(clientNames.length / 4);
  
  const columns = [
    [...clientNames].slice(0, itemsPerColumn),
    [...clientNames].slice(itemsPerColumn, itemsPerColumn * 2),
    [...clientNames].slice(itemsPerColumn * 2, itemsPerColumn * 3),
    [...clientNames].slice(itemsPerColumn * 3)
  ];

  // Duplicate each column to create a seamless infinite scroll
  const columnsWithDuplicates = columns.map(column => [...column, ...column]);

  return (
    <div className="relative w-full min-h-screen bg-black overflow-hidden">
      {/* Dark overlay with increased opacity */}
      <div className="absolute inset-0 bg-black/60 z-10" />
      
      {/* Background pattern of client names with continuous scroll */}
      <div className="absolute inset-0 flex justify-between px-4 md:px-12">
        {columnsWithDuplicates.map((column, columnIndex) => (
          <div 
            key={columnIndex}
            className="flex-1 relative px-1 md:px-2"
          >
            <motion.div 
              className="absolute top-0 flex flex-col gap-8 md:gap-16 py-16 min-w-full"
              initial={{ y: "0%" }}
              animate={{ 
                y: "-100%",
              }}
              transition={{
                duration: 60, // Consistent duration for all columns
                ease: "linear",
                repeat: Infinity,
              }}
            >
              {column.map((name, index) => (
                <motion.div
                  key={`${name}-${index}`}
                  className="text-white text-xs md:text-base lg:text-lg tracking-wider cursor-default 
                            text-center transform transition-all duration-500"
                  whileHover={{
                    color: "#FF4500",
                    textShadow: "0 0 20px rgba(255,69,0,0.5)",
                    scale: 1.05,
                    transition: { duration: 0.2 }
                  }}
                >
                  {name}
                </motion.div>
              ))}
            </motion.div>
            {/* Duplicate for seamless loop */}
            <motion.div 
              className="absolute top-0 flex flex-col gap-8 md:gap-16 py-16 min-w-full"
              initial={{ y: "100%" }}
              animate={{ 
                y: "0%",
              }}
              transition={{
                duration: 60, // Consistent duration for all columns
                ease: "linear",
                repeat: Infinity,
              }}
            >
              {column.map((name, index) => (
                <motion.div
                  key={`${name}-${index}-duplicate`}
                  className="text-white text-xs md:text-base lg:text-lg tracking-wider cursor-default 
                            text-center transform transition-all duration-500"
                  whileHover={{
                    color: "#FF4500",
                    textShadow: "0 0 20px rgba(255,69,0,0.5)",
                    scale: 1.05,
                    transition: { duration: 0.2 }
                  }}
                >
                  {name}
                </motion.div>
              ))}
            </motion.div>
          </div>
        ))}
      </div>

      {/* Bottom fade gradient */}
      <div 
        className="absolute bottom-0 left-0 right-0 h-64 bg-gradient-to-t from-black via-black/80 to-transparent z-30 pointer-events-none"
        style={{
          maskImage: 'linear-gradient(to bottom, transparent, black)',
          WebkitMaskImage: 'linear-gradient(to bottom, transparent, black)'
        }}
      />

      {/* Centered content overlay */}
      <div className="absolute inset-0 z-20 flex items-center justify-center">
        <div className="text-center max-w-6xl mx-auto px-4">
          <motion.h2 
            className="text-5xl md:text-[12rem] font-display font-bold leading-[0.9] text-white"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Trusted by<br />Industry Leaders
          </motion.h2>
          
          <motion.p
            className="text-xl text-gray-400 max-w-3xl mx-auto font-light mt-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            From startups to Fortune 500 companies, we help organizations build better software
          </motion.p>
        </div>
      </div>
    </div>
  );
};
