
import { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "sonner";
import { Loader2, Wand2 } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { AIAnalysisDialog } from './AIAnalysisDialog';
import { AnalysisProgress } from '@/components/ProjectScoping/components/feedback/AnalysisProgress';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { ScreenType } from '../types';

interface AIImportDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  designId?: string;
  onAnalysisComplete: () => void;
  clientName?: string;
  clientCompany?: string;
  accessCode: string;
  onAddScreen?: (screen: any) => void;
}

export const AIImportDialog = ({ 
  open, 
  onOpenChange, 
  designId, 
  onAnalysisComplete,
  clientName = '',
  clientCompany = '',
  accessCode,
  onAddScreen
}: AIImportDialogProps) => {
  const [input, setInput] = useState("");
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [analysis, setAnalysis] = useState<any>(null);
  const [showAnalysis, setShowAnalysis] = useState(false);
  const [currentStep, setCurrentStep] = useState("Processing project requirements");
  const navigate = useNavigate();

  const handleAnalyze = async () => {
    console.log('Handle analyze clicked');
    
    if (!input.trim()) {
      toast.error("Please describe your project");
      return;
    }

    setIsAnalyzing(true);
    let projectId = designId;

    try {
      // If no designId exists, create a new project first
      if (!designId) {
        console.log('Creating new project with access code:', accessCode);
        projectId = uuidv4();
        
        const { error: createError } = await supabase
          .from('flare_designs')
          .insert({
            id: projectId,
            title: 'AI Generated Project',
            access_code: accessCode,
            initial_requirements: input.trim(),
            features: {},
            stages: { alpha: [], beta: [], launch: [] },
            client_name: clientName,
            client_company: clientCompany,
            created_by: 'wail@unstucklabs.com'
          });

        if (createError) {
          console.error('Error creating new project:', createError);
          throw new Error(`Failed to create project: ${createError.message}`);
        }
        
        // Navigate to the new project - using the correct path
        const correctPath = `/flare/canvas/design/${projectId}`;
        navigate(correctPath);
        
        toast.success('New project created!');
      }

      // Show progress indicators to the user
      setCurrentStep("Processing project requirements");
      await new Promise(resolve => setTimeout(resolve, 1000));
      setCurrentStep("Analyzing market trends");
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      console.log('Calling analyze-project-input function with:', { input, designId: projectId });
      
      const { data, error } = await supabase.functions.invoke('analyze-project-input', {
        body: { 
          input, 
          designId: projectId 
        }
      });

      if (error) {
        console.error('Supabase function error:', error);
        throw new Error(`Analysis failed: ${error.message}`);
      }
      
      if (!data) {
        throw new Error('No data received from analysis');
      }

      console.log('Analysis data received:', data);

      setCurrentStep("Generating technical recommendations");
      await new Promise(resolve => setTimeout(resolve, 1000));
      setCurrentStep("Calculating timeline and budget");
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      setAnalysis(data);
      setShowAnalysis(true);
      toast.success("Project analyzed successfully!");
    } catch (error) {
      console.error('Error analyzing project:', error);
      toast.error(`Failed to analyze project: ${error instanceof Error ? error.message : String(error)}`);
    } finally {
      setIsAnalyzing(false);
    }
  };

  const handleAddFeature = async (feature: any) => {
    if (!designId) {
      toast.error("Design ID is required");
      return;
    }

    try {
      const { error: updateError } = await supabase
        .from('flare_designs')
        .update({
          features: {
            ...analysis.features,
            [crypto.randomUUID()]: {
              id: crypto.randomUUID(),
              type: 'feature',
              position: { x: 200, y: 200 },
              data: {
                label: feature.name,
                description: feature.description,
                priority: feature.priority,
                points: feature.points,
                notes: `Description:\n${feature.description}\n\nPriority: ${feature.priority}\nPoints: ${feature.points}`
              }
            }
          },
          updated_at: new Date().toISOString()
        })
        .eq('id', designId);

      if (updateError) throw updateError;
      onAnalysisComplete();
    } catch (error) {
      console.error('Error adding feature:', error);
      toast.error("Failed to add feature. Please try again.");
    }
  };

  const handleAddScreen = (screen: { name: string; description: string; type: ScreenType }) => {
    if (onAddScreen) {
      onAddScreen(screen);
    }
  };

  return (
    <ErrorBoundary>
      <Dialog open={open && !showAnalysis} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>AI Project Analysis</DialogTitle>
            <DialogDescription>
              Describe your project or paste requirements, and I'll help identify and organize the key features.
            </DialogDescription>
          </DialogHeader>
          
          <div className="space-y-4 py-4">
            <Textarea
              placeholder="Describe your project idea or paste requirements..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className="min-h-[200px]"
              disabled={isAnalyzing}
            />
            
            <div className="flex justify-end space-x-2">
              <Button
                variant="outline"
                onClick={() => onOpenChange(false)}
                disabled={isAnalyzing}
                className="bg-[#F1F0FB] hover:bg-[#C8C8C9] text-[#333] border-[#8E9196]"
              >
                Cancel
              </Button>
              <Button
                onClick={handleAnalyze}
                disabled={!input.trim() || isAnalyzing}
                className="bg-[#F97316] hover:bg-[#F97316]/90 text-white space-x-2 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isAnalyzing ? (
                  <>
                    <Loader2 className="h-4 w-4 animate-spin" />
                    <span>Analyzing...</span>
                  </>
                ) : (
                  <>
                    <Wand2 className="h-4 w-4" />
                    <span>Analyze</span>
                  </>
                )}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <AnalysisProgress 
        isGenerating={isAnalyzing} 
        currentStep={currentStep}
      />

      {analysis && (
        <AIAnalysisDialog 
          open={showAnalysis} 
          onOpenChange={(open) => {
            setShowAnalysis(open);
            if (!open) {
              onOpenChange(false);
              setAnalysis(null);
            }
          }}
          analysis={analysis}
          onAddFeature={handleAddFeature}
          onAddScreen={handleAddScreen}
        />
      )}
    </ErrorBoundary>
  );
};
