
import { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { useQueryClient } from "@tanstack/react-query";
import { URLAnalyzer } from "./URLAnalyzer";

interface CreateCompanyDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const CreateCompanyDialog = ({
  open,
  onOpenChange,
}: CreateCompanyDialogProps) => {
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    website_url: "",
    industry: "",
    description: "",
    logo_url: "",
    social_links: {},
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!formData.name && !formData.website_url) {
      toast.error("Please provide either a company name or website URL");
      return;
    }

    setIsSubmitting(true);

    try {
      // First get the user's current workspace
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        toast.error("Authentication required");
        return;
      }

      // Get the user's current workspace
      const { data: workspaceMember, error: workspaceError } = await supabase
        .from('workspace_members')
        .select('workspace_id')
        .eq('user_id', user.id)
        .single();

      if (workspaceError || !workspaceMember?.workspace_id) {
        toast.error("Failed to determine workspace");
        return;
      }

      const { error } = await supabase.from("companies").insert([{
        ...formData,
        workspace_id: workspaceMember.workspace_id
      }]);

      if (error) throw error;

      toast.success("Company created successfully");
      queryClient.invalidateQueries({ queryKey: ["companies"] });
      onOpenChange(false);
      setFormData({
        name: "",
        website_url: "",
        industry: "",
        description: "",
        logo_url: "",
        social_links: {},
      });
    } catch (error) {
      console.error("Error creating company:", error);
      toast.error("Failed to create company");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAnalysisComplete = (data: any) => {
    setFormData((prev) => ({
      ...prev,
      name: data.name || prev.name,
      logo_url: data.logo_url || prev.logo_url,
      industry: data.industry || prev.industry,
      description: data.description || prev.description,
      social_links: data.social_links || prev.social_links,
    }));
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create New Company</DialogTitle>
        </DialogHeader>

        <div className="mb-4">
          <Label>Quick Fill from Website</Label>
          <URLAnalyzer onAnalysisComplete={handleAnalysisComplete} />
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="name">Company Name</Label>
            <Input
              id="name"
              value={formData.name}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, name: e.target.value }))
              }
              placeholder="Will be extracted from website if URL is provided"
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="website">Website URL</Label>
            <Input
              id="website"
              type="url"
              value={formData.website_url}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, website_url: e.target.value }))
              }
              placeholder="https://"
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="industry">Industry</Label>
            <Input
              id="industry"
              value={formData.industry}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, industry: e.target.value }))
              }
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="description">Description</Label>
            <Input
              id="description"
              value={formData.description}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, description: e.target.value }))
              }
            />
          </div>

          <div className="flex justify-end gap-2">
            <Button
              type="button"
              variant="outline"
              onClick={() => onOpenChange(false)}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Creating..." : "Create Company"}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
