import { motion } from "framer-motion";

interface IsometricRocketProps {
  className?: string;
  color?: string;
}

export const IsometricRocket = ({ className = "", color = "currentColor" }: IsometricRocketProps) => {
  return (
    <svg className={`${className} w-32 h-32`} viewBox="0 0 100 100">
      <motion.path
        initial={{ opacity: 0, pathLength: 0 }}
        animate={{ opacity: 1, pathLength: 1 }}
        transition={{ duration: 2, ease: "easeInOut" }}
        d="M 50 10 L 70 30 L 70 70 L 50 90 L 30 70 L 30 30 Z"
        fill="none"
        stroke={color}
        strokeWidth="2"
      />
      <motion.path
        initial={{ opacity: 0, pathLength: 0 }}
        animate={{ opacity: 1, pathLength: 1 }}
        transition={{ duration: 2, delay: 0.5, ease: "easeInOut" }}
        d="M 50 10 L 50 90"
        fill="none"
        stroke={color}
        strokeWidth="2"
      />
      <motion.path
        initial={{ opacity: 0, pathLength: 0 }}
        animate={{ opacity: 1, pathLength: 1 }}
        transition={{ duration: 2, delay: 1, ease: "easeInOut" }}
        d="M 30 50 L 70 50"
        fill="none"
        stroke={color}
        strokeWidth="2"
      />
      <motion.circle
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 1, delay: 1.5 }}
        cx="50"
        cy="50"
        r="5"
        fill={color}
      />
    </svg>
  );
};