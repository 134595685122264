
import { motion } from "framer-motion";
import { ArrowRight, Landmark, Shield, FileCheck } from "lucide-react";
import { Button } from "@/components/ui/button";

export const GovernmentJourney = () => {
  return (
    <div className="space-y-24">
      {/* Government Value Proposition */}
      <section className="py-24">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="max-w-4xl mx-auto text-center"
          >
            <h2 className="text-4xl md:text-5xl font-display font-bold mb-6 dark:text-white">
              Secure Government Solutions
            </h2>
            <p className="text-xl text-gray-600 dark:text-gray-300 mb-12">
              FedRAMP-compliant digital solutions designed for government agencies
              and public sector organizations.
            </p>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="p-6 backdrop-blur-sm border border-primary/10 rounded-xl">
                <Landmark className="w-10 h-10 text-emerald-500 mb-4" />
                <h3 className="text-lg font-bold mb-2 dark:text-white">FedRAMP Certified</h3>
                <p className="text-gray-600 dark:text-gray-300">Fully compliant with federal standards</p>
              </div>
              <div className="p-6 backdrop-blur-sm border border-primary/10 rounded-xl">
                <Shield className="w-10 h-10 text-emerald-500 mb-4" />
                <h3 className="text-lg font-bold mb-2 dark:text-white">Security Cleared</h3>
                <p className="text-gray-600 dark:text-gray-300">Top Secret cleared personnel</p>
              </div>
              <div className="p-6 backdrop-blur-sm border border-primary/10 rounded-xl">
                <FileCheck className="w-10 h-10 text-emerald-500 mb-4" />
                <h3 className="text-lg font-bold mb-2 dark:text-white">Contract Vehicles</h3>
                <p className="text-gray-600 dark:text-gray-300">Multiple procurement options</p>
              </div>
            </div>
            
            <div className="mt-12">
              <Button 
                size="lg"
                className="bg-emerald-600 hover:bg-emerald-700 text-white dark:bg-emerald-600/90 dark:hover:bg-emerald-600"
                onClick={() => {
                  const analyzerSection = document.getElementById('compliance-analyzer');
                  if (analyzerSection) {
                    analyzerSection.scrollIntoView({ behavior: 'smooth' });
                  }
                }}
              >
                Try Our Compliance Analyzer <ArrowRight className="ml-2 h-4 w-4" />
              </Button>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
};
