
import { motion } from "framer-motion";
import { TiltedScroll } from "@/components/ui/tilted-scroll";

export const CostsSection = () => {
  const costsItems = [
    { id: "1", text: "Save 40% on dev time" },
    { id: "2", text: "Save 30% on costs" },
    { id: "3", text: "2x faster to market" },
    { id: "4", text: "Streamlined workflows" },
    { id: "5", text: "AI-accelerated development" },
    { id: "6", text: "Efficient tech stack" },
    { id: "7", text: "Rapid iterations" },
    { id: "8", text: "Zero waste processes" },
  ];

  return (
    <div className="relative min-h-screen flex flex-col items-center justify-center">
      <motion.h2 
        className="text-5xl sm:text-[10vw] leading-tight sm:leading-none font-display font-bold text-white relative z-10 text-center mb-16"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        Cut Dev Costs<br />by 60%
      </motion.h2>

      <motion.div 
        className="w-full flex justify-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
      >
        <TiltedScroll items={costsItems} className="transform scale-125" />
      </motion.div>
    </div>
  );
};
