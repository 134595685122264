
import { FeatureNode } from './FeatureNode';
import { StageNode } from './StageNode';
import { ScreenNode } from './ScreenNode';
import { CodeNode } from './CodeNode';

export const nodeTypes = {
  feature: FeatureNode,
  stage: StageNode,
  screen: ScreenNode,
  code: CodeNode,
};
