import { motion } from "framer-motion";
import { useState } from "react";
import { Code2, Brain, Zap, GitBranch, Database, Shield, Cpu } from "lucide-react";
import { Button } from "@/components/ui/button";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";

export const CodeAnalyzer = () => {
  const [analyzing, setAnalyzing] = useState(false);
  const [codeInput, setCodeInput] = useState("");
  const [analysis, setAnalysis] = useState<any>(null);
  const { toast } = useToast();

  const startAnalysis = async () => {
    if (!codeInput.trim()) {
      toast({
        title: "Error",
        description: "Please enter some code to analyze",
        variant: "destructive",
      });
      return;
    }

    setAnalyzing(true);
    try {
      const { data, error } = await supabase.functions.invoke('analyze-code', {
        body: { code: codeInput }
      });

      if (error) throw error;
      setAnalysis(data);
      
      toast({
        title: "Analysis Complete",
        description: "Your code has been analyzed successfully!",
      });
    } catch (error) {
      console.error('Analysis error:', error);
      toast({
        title: "Analysis Failed",
        description: "There was an error analyzing your code. Please try again.",
        variant: "destructive",
      });
    } finally {
      setAnalyzing(false);
    }
  };

  return (
    <div className="relative h-[600px] rounded-xl bg-black/80 backdrop-blur-xl p-8 overflow-hidden">
      {/* Animated Background */}
      <motion.div
        className="absolute inset-0 bg-gradient-to-br from-purple-500/10 via-blue-500/10 to-pink-500/10"
        animate={{
          background: [
            "radial-gradient(circle at 0% 0%, rgba(147, 51, 234, 0.1) 0%, rgba(59, 130, 246, 0.1) 50%, rgba(236, 72, 153, 0.1) 100%)",
            "radial-gradient(circle at 100% 100%, rgba(147, 51, 234, 0.1) 0%, rgba(59, 130, 246, 0.1) 50%, rgba(236, 72, 153, 0.1) 100%)",
          ],
        }}
        transition={{ duration: 10, repeat: Infinity, repeatType: "reverse" }}
      />

      {/* Floating Particles */}
      {[...Array(20)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute w-1 h-1 bg-white/20 rounded-full"
          initial={{
            x: Math.random() * window.innerWidth,
            y: Math.random() * window.innerHeight,
          }}
          animate={{
            x: Math.random() * window.innerWidth,
            y: Math.random() * window.innerHeight,
          }}
          transition={{
            duration: Math.random() * 10 + 10,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        />
      ))}

      {/* Content */}
      <div className="relative z-10 space-y-6">
        <div className="flex items-center gap-3 text-white">
          <Brain className="w-6 h-6" />
          <h3 className="text-xl font-bold">AI Code Analyzer</h3>
        </div>

        {/* Code Input */}
        <div className="space-y-4">
          <textarea
            value={codeInput}
            onChange={(e) => setCodeInput(e.target.value)}
            placeholder="Paste your code or repository URL here..."
            className="w-full h-32 bg-black/50 text-green-400 font-mono text-sm p-4 rounded-lg border border-white/10 focus:border-purple-500 focus:ring-1 focus:ring-purple-500 transition-all"
          />
          <Button
            onClick={startAnalysis}
            className="w-full bg-purple-600 hover:bg-purple-700 text-white"
            disabled={analyzing}
          >
            {analyzing ? (
              <span className="flex items-center gap-2">
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                >
                  <Zap className="w-4 h-4" />
                </motion.div>
                Analyzing...
              </span>
            ) : (
              "Analyze Code"
            )}
          </Button>
        </div>

        {/* Analysis Results */}
        {analysis && (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <motion.div
              className="bg-white/5 p-4 rounded-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              <div className="flex items-center gap-2 mb-2">
                <GitBranch className="w-4 h-4 text-purple-400" />
                <h4 className="text-white font-semibold">Architecture Score</h4>
              </div>
              <div className="text-2xl font-bold text-purple-400">{analysis.architecture_score}/100</div>
              <p className="text-sm text-gray-400">Code structure and patterns</p>
            </motion.div>

            <motion.div
              className="bg-white/5 p-4 rounded-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
            >
              <div className="flex items-center gap-2 mb-2">
                <Cpu className="w-4 h-4 text-blue-400" />
                <h4 className="text-white font-semibold">Performance Score</h4>
              </div>
              <div className="text-2xl font-bold text-blue-400">{analysis.performance_score}/100</div>
              <p className="text-sm text-gray-400">Runtime efficiency</p>
            </motion.div>

            <motion.div
              className="bg-white/5 p-4 rounded-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              <div className="flex items-center gap-2 mb-2">
                <Shield className="w-4 h-4 text-green-400" />
                <h4 className="text-white font-semibold">Security Score</h4>
              </div>
              <div className="text-2xl font-bold text-green-400">{analysis.security_score}/100</div>
              <p className="text-sm text-gray-400">Security best practices</p>
            </motion.div>
          </div>
        )}

        {/* Recommendations */}
        {analysis && (
          <motion.div
            className="bg-white/5 p-4 rounded-lg mt-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
          >
            <h4 className="text-white font-semibold mb-2">AI Recommendations</h4>
            <p className="text-sm text-gray-400 whitespace-pre-wrap">{analysis.recommendations}</p>
          </motion.div>
        )}
      </div>
    </div>
  );
};