import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { ContactBasicInfo } from "./forms/ContactBasicInfo";
import { ContactJobInfo } from "./forms/ContactJobInfo";
import { ContactCompanySelect } from "./forms/ContactCompanySelect";

interface CreateContactDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  companyId?: string;
  onSuccess?: () => void;
}

export const CreateContactDialog = ({
  open,
  onOpenChange,
  companyId,
  onSuccess,
}: CreateContactDialogProps) => {
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    job_title: "",
    department: "",
    company_id: companyId || "",
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Create the contact
      const { error: insertError } = await supabase
        .from("contacts")
        .insert([{
          ...formData,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          interaction_count: 0,
          engagement_score: 25,
          tags: [],
          social_profiles: {},
          custom_fields: {},
          communication_preferences: {},
          lifecycle_stage: 'new'
        }]);

      if (insertError) {
        console.error("Insert error:", insertError);
        throw new Error("Failed to create contact. Please try again.");
      }

      toast.success("Contact created successfully");
      queryClient.invalidateQueries({ queryKey: ["contacts"] });
      if (companyId) {
        queryClient.invalidateQueries({ queryKey: ["company", companyId] });
      }
      onSuccess?.();
      onOpenChange(false);
      setFormData({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        job_title: "",
        department: "",
        company_id: companyId || "",
      });
    } catch (error) {
      console.error("Error creating contact:", error);
      toast.error(error instanceof Error ? error.message : "Failed to create contact");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create New Contact</DialogTitle>
        </DialogHeader>

        <form onSubmit={handleSubmit} className="space-y-6">
          <ContactBasicInfo formData={formData} onChange={(e) => {
            const { name, value } = e.target;
            setFormData((prev) => ({ ...prev, [name]: value }));
          }} />
          <ContactJobInfo formData={formData} onChange={(e) => {
            const { name, value } = e.target;
            setFormData((prev) => ({ ...prev, [name]: value }));
          }} />
          <ContactCompanySelect
            value={formData.company_id}
            onValueChange={(value) =>
              setFormData((prev) => ({ ...prev, company_id: value }))
            }
            companyId={companyId}
          />

          <div className="flex justify-end gap-2">
            <Button
              type="button"
              variant="outline"
              onClick={() => onOpenChange(false)}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Creating..." : "Create Contact"}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};