import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

export const DealNotes = ({ dealId }: { dealId: string }) => {
  const [newNote, setNewNote] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryClient = useQueryClient();

  // Query to get the current user's admin profile
  const { data: adminProfile, isLoading: isLoadingProfile } = useQuery({
    queryKey: ["admin-profile"],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user) {
        toast.error("You must be logged in to add notes");
        return null;
      }

      const { data, error } = await supabase
        .from("admin_profiles")
        .select("id, name")
        .eq("user_id", session.user.id)
        .maybeSingle();

      if (error) {
        console.error("Error fetching admin profile:", error);
        toast.error("Failed to load your profile");
        return null;
      }

      if (!data) {
        toast.error("No admin profile found. Please contact support.");
        return null;
      }

      return data;
    },
  });

  const { data: notes = [], isLoading } = useQuery({
    queryKey: ["deal-notes", dealId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("notes")
        .select(`
          *,
          admin_profiles (
            name
          )
        `)
        .eq("deal_id", dealId)
        .order("created_at", { ascending: false });

      if (error) throw error;
      return data;
    },
  });

  const handleAddNote = async () => {
    if (!newNote.trim() || !adminProfile?.id) return;

    setIsSubmitting(true);
    try {
      const { error } = await supabase.from("notes").insert({
        deal_id: dealId,
        content: newNote,
        created_by: adminProfile.id,
      });

      if (error) throw error;

      toast.success("Note added successfully");
      setNewNote("");
      queryClient.invalidateQueries({ queryKey: ["deal-notes"] });
    } catch (error) {
      console.error("Error adding note:", error);
      toast.error("Failed to add note");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoadingProfile) {
    return (
      <Card className="p-6">
        <div className="text-center py-4">Loading profile...</div>
      </Card>
    );
  }

  return (
    <Card className="p-6">
      <div className="space-y-6">
        <div className="space-y-4">
          <h3 className="text-lg font-semibold">Notes</h3>
          <div className="space-y-2">
            <Textarea
              placeholder="Add a new note..."
              value={newNote}
              onChange={(e) => setNewNote(e.target.value)}
              className="min-h-[100px]"
            />
            <Button 
              onClick={handleAddNote} 
              disabled={!newNote.trim() || isSubmitting || !adminProfile?.id}
            >
              {isSubmitting ? "Adding..." : "Add Note"}
            </Button>
          </div>
        </div>

        <div className="space-y-4">
          {isLoading ? (
            <div className="text-center py-4">Loading notes...</div>
          ) : notes.length === 0 ? (
            <div className="text-center py-4 text-muted-foreground">
              No notes yet
            </div>
          ) : (
            notes.map((note) => (
              <div
                key={note.id}
                className="p-4 bg-muted/50 rounded-lg space-y-2"
              >
                <div className="flex justify-between items-start">
                  <p className="text-sm text-muted-foreground">
                    {note.admin_profiles?.name} •{" "}
                    {new Date(note.created_at).toLocaleString()}
                  </p>
                </div>
                <p className="whitespace-pre-wrap">{note.content}</p>
              </div>
            ))
          )}
        </div>
      </div>
    </Card>
  );
};