
import { AdminDashboard } from "@/components/admin/AdminDashboard";
import { ProtectedRoute } from "@/components/admin/ProtectedRoute";

const AdminPage = () => {
  return (
    <ProtectedRoute requiredRole="admin">
      <AdminDashboard />
    </ProtectedRoute>
  );
};

export default AdminPage;
