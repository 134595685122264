import { Shield, ShoppingCart, FileText, Users, BarChart3, Lock, Link2, Layout, MessageSquare, Server, Brain, Eye, Mic, Sparkles, Database } from 'lucide-react';
import { Feature } from '../types';

export interface FeatureGroup {
  id: string;
  name: string;
  description: string;
  icon: any;
  category: string;
  features: {
    name: string;
    description: string;
    priority: Feature['data']['priority'];
  }[];
  estimatedTime: string;
  complexity: 'Low' | 'Medium' | 'High';
}

export const featureGroups: FeatureGroup[] = [
  {
    id: 'auth-bundle',
    name: 'Authentication & Security',
    description: 'Essential security features to protect your application and users. Includes user authentication, role-based access control, and security best practices to prevent common vulnerabilities.',
    icon: Shield,
    category: 'auth',
    estimatedTime: '2-3 days',
    complexity: 'Medium',
    features: [
      {
        name: 'User Authentication',
        description: 'Complete authentication system with email/password, social logins, and MFA support for enhanced security',
        priority: 'high'
      },
      {
        name: 'Two-Factor Authentication',
        description: 'Additional security layer with SMS, authenticator apps, and backup codes for account recovery',
        priority: 'medium'
      },
      {
        name: 'Password Management',
        description: 'Secure password reset flow, strength requirements, and encrypted storage with industry standards',
        priority: 'high'
      }
    ]
  },
  {
    id: 'ecommerce-bundle',
    name: 'E-commerce Essentials',
    description: 'Comprehensive e-commerce functionality for online stores. From product management to checkout, includes everything needed to start selling online with a focus on conversion optimization.',
    icon: ShoppingCart,
    category: 'core',
    estimatedTime: '4-5 days',
    complexity: 'High',
    features: [
      {
        name: 'Product Catalog',
        description: 'Dynamic product listings with categories, filters, and search functionality for optimal product discovery',
        priority: 'high'
      },
      {
        name: 'Shopping Cart',
        description: 'Persistent cart with real-time updates, saved items, and multi-currency support',
        priority: 'high'
      },
      {
        name: 'Payment Processing',
        description: 'Integration with major payment gateways, supporting multiple payment methods and secure transactions',
        priority: 'high'
      }
    ]
  },
  {
    id: 'cms-bundle',
    name: 'Content Management',
    description: 'Powerful content management tools for creating, organizing, and publishing content. Perfect for blogs, documentation, or any content-heavy application with version control.',
    icon: FileText,
    category: 'core',
    estimatedTime: '3-4 days',
    complexity: 'Medium',
    features: [
      {
        name: 'Rich Text Editor',
        description: 'Full-featured WYSIWYG editor with image handling, formatting, and markdown support',
        priority: 'high'
      },
      {
        name: 'Media Library',
        description: 'Centralized media management with image optimization, categorization, and CDN integration',
        priority: 'medium'
      },
      {
        name: 'Content Workflow',
        description: 'Draft/publish system with review processes, scheduling, and content versioning',
        priority: 'medium'
      }
    ]
  },
  {
    id: 'collaboration-bundle',
    name: 'Team Collaboration',
    description: 'Tools for effective team coordination and productivity. Enables seamless communication, task management, and progress tracking for teams of any size.',
    icon: Users,
    category: 'core',
    estimatedTime: '3-4 days',
    complexity: 'Medium',
    features: [
      {
        name: 'Team Management',
        description: 'Comprehensive user roles and permissions system with custom access levels and team hierarchies',
        priority: 'high'
      },
      {
        name: 'Task Management',
        description: 'Kanban boards, task assignments, and progress tracking with deadline notifications',
        priority: 'medium'
      },
      {
        name: 'Team Chat',
        description: 'Built-in messaging system with channels, direct messages, and file sharing capabilities',
        priority: 'medium'
      }
    ]
  },
  {
    id: 'analytics-bundle',
    name: 'Data & Analytics',
    description: 'Advanced analytics tools for data-driven decisions. Track user behavior, monitor performance metrics, and generate insights with interactive visualizations.',
    icon: BarChart3,
    category: 'analytics',
    estimatedTime: '3-4 days',
    complexity: 'Medium',
    features: [
      {
        name: 'Data Visualization',
        description: 'Custom dashboards with real-time charts, graphs, and exportable reports',
        priority: 'high'
      },
      {
        name: 'User Analytics',
        description: 'Detailed user behavior tracking with funnel analysis and conversion optimization tools',
        priority: 'medium'
      },
      {
        name: 'Performance Metrics',
        description: 'System health monitoring with automated alerts and historical performance data',
        priority: 'medium'
      }
    ]
  },
  {
    id: 'compliance-bundle',
    name: 'Compliance & Privacy',
    description: 'Comprehensive compliance features for data protection regulations. Ensures your application meets GDPR, CCPA, and other privacy requirements with built-in tools.',
    icon: Lock,
    category: 'compliance',
    estimatedTime: '4-5 days',
    complexity: 'High',
    features: [
      {
        name: 'GDPR Compliance',
        description: 'Complete GDPR toolkit with consent management, data export, and right to be forgotten features',
        priority: 'high'
      },
      {
        name: 'Data Protection',
        description: 'End-to-end encryption, secure data storage, and automated backup systems',
        priority: 'high'
      },
      {
        name: 'Privacy Controls',
        description: 'User privacy settings, data usage transparency, and compliance documentation',
        priority: 'high'
      }
    ]
  },
  {
    id: 'integration-bundle',
    name: 'Integration & API',
    description: 'Tools for seamless third-party integrations and API management. Build and maintain robust connections with external services and provide API access to your platform.',
    icon: Link2,
    category: 'integration',
    estimatedTime: '3-4 days',
    complexity: 'Medium',
    features: [
      {
        name: 'API Management',
        description: 'API key management, rate limiting, and comprehensive API documentation generation',
        priority: 'high'
      },
      {
        name: 'Third-party Integrations',
        description: 'Pre-built connectors for popular services with customizable integration options',
        priority: 'medium'
      },
      {
        name: 'Webhook Support',
        description: 'Configurable webhook system for real-time event notifications and data synchronization',
        priority: 'medium'
      }
    ]
  },
  {
    id: 'ux-bundle',
    name: 'User Experience',
    description: 'Enhanced UI/UX components for a polished user experience. Includes accessible, responsive design elements and customization options for your application interface.',
    icon: Layout,
    category: 'ui',
    estimatedTime: '2-3 days',
    complexity: 'Medium',
    features: [
      {
        name: 'UI Components',
        description: 'Library of customizable, responsive UI components following modern design principles',
        priority: 'high'
      },
      {
        name: 'Theme Management',
        description: 'Dynamic theming system with dark mode support and brand customization options',
        priority: 'medium'
      },
      {
        name: 'Accessibility Features',
        description: 'WCAG 2.1 compliant components with screen reader support and keyboard navigation',
        priority: 'high'
      }
    ]
  },
  {
    id: 'communication-bundle',
    name: 'Communication',
    description: 'Comprehensive communication features for user engagement. Handle all types of notifications and messaging to keep users informed and engaged with your platform.',
    icon: MessageSquare,
    category: 'communication',
    estimatedTime: '3-4 days',
    complexity: 'Medium',
    features: [
      {
        name: 'Email System',
        description: 'Templated email system with campaign management and delivery analytics',
        priority: 'high'
      },
      {
        name: 'Notification Center',
        description: 'Centralized notification hub with customizable alerts and delivery preferences',
        priority: 'medium'
      },
      {
        name: 'Chat Features',
        description: 'Real-time chat functionality with message history and file sharing capabilities',
        priority: 'medium'
      }
    ]
  },
  {
    id: 'infrastructure-bundle',
    name: 'Infrastructure',
    description: 'Core infrastructure components for a scalable application. Ensures your application can handle growth with reliable data management and performance optimization.',
    icon: Server,
    category: 'infrastructure',
    estimatedTime: '4-5 days',
    complexity: 'High',
    features: [
      {
        name: 'Cloud Storage',
        description: 'Scalable file storage with CDN integration and automatic backup systems',
        priority: 'high'
      },
      {
        name: 'Database Management',
        description: 'Optimized database operations with indexing and query performance monitoring',
        priority: 'high'
      },
      {
        name: 'Caching System',
        description: 'Multi-layer caching strategy for improved application performance and reduced server load',
        priority: 'medium'
      }
    ]
  },
  {
    id: 'ai-ml-bundle',
    name: 'AI & Machine Learning',
    description: 'Advanced AI capabilities including natural language processing, machine learning models, and intelligent automation features.',
    icon: Brain,
    category: 'ai',
    estimatedTime: '4-5 days',
    complexity: 'High',
    features: [
      {
        name: 'Natural Language Processing',
        description: 'Advanced text analysis, sentiment detection, and language understanding capabilities',
        priority: 'high'
      },
      {
        name: 'Predictive Analytics',
        description: 'Machine learning models for forecasting, trend analysis, and pattern recognition',
        priority: 'high'
      },
      {
        name: 'Chatbot Integration',
        description: 'AI-powered conversational interface with context awareness and learning capabilities',
        priority: 'medium'
      }
    ]
  },
  {
    id: 'computer-vision-bundle',
    name: 'Computer Vision',
    description: 'Visual processing capabilities powered by AI, including image recognition, object detection, and visual search features.',
    icon: Eye,
    category: 'ai',
    estimatedTime: '3-4 days',
    complexity: 'High',
    features: [
      {
        name: 'Image Recognition',
        description: 'Identify objects, faces, text, and patterns in images with high accuracy',
        priority: 'high'
      },
      {
        name: 'Visual Search',
        description: 'Search functionality based on image content and visual similarity',
        priority: 'medium'
      },
      {
        name: 'Object Detection',
        description: 'Real-time detection and tracking of objects in images or video streams',
        priority: 'medium'
      }
    ]
  },
  {
    id: 'voice-speech-bundle',
    name: 'Voice & Speech',
    description: 'Voice processing and speech recognition capabilities for enhanced user interaction and accessibility.',
    icon: Mic,
    category: 'ai',
    estimatedTime: '3-4 days',
    complexity: 'High',
    features: [
      {
        name: 'Speech Recognition',
        description: 'Convert spoken language into text with support for multiple languages',
        priority: 'high'
      },
      {
        name: 'Voice Commands',
        description: 'Enable voice-controlled navigation and actions within the application',
        priority: 'medium'
      },
      {
        name: 'Text-to-Speech',
        description: 'Convert text content into natural-sounding speech for accessibility',
        priority: 'medium'
      }
    ]
  },
  {
    id: 'recommendation-bundle',
    name: 'Recommendation Systems',
    description: 'Intelligent recommendation engines for personalized content and product suggestions.',
    icon: Sparkles,
    category: 'ai',
    estimatedTime: '4-5 days',
    complexity: 'High',
    features: [
      {
        name: 'Content Recommendations',
        description: 'Personalized content suggestions based on user behavior and preferences',
        priority: 'high'
      },
      {
        name: 'Collaborative Filtering',
        description: 'User similarity-based recommendations using historical interaction data',
        priority: 'medium'
      },
      {
        name: 'Real-time Personalization',
        description: 'Dynamic content adaptation based on user context and behavior',
        priority: 'medium'
      }
    ]
  },
  {
    id: 'data-storage-bundle',
    name: 'Data & Storage',
    description: 'Essential data management and storage capabilities for your application. Includes secure file storage, database operations, and data backup solutions.',
    icon: Database,
    category: 'data',
    estimatedTime: '3-4 days',
    complexity: 'Medium',
    features: [
      {
        name: 'File Storage System',
        description: 'Secure cloud storage system with file upload/download, versioning, and access control',
        priority: 'high'
      },
      {
        name: 'Data Backup',
        description: 'Automated backup system with scheduled backups, retention policies, and restore capabilities',
        priority: 'high'
      },
      {
        name: 'Database Management',
        description: 'Robust database operations with data validation, migrations, and optimization',
        priority: 'high'
      },
      {
        name: 'CDN Integration',
        description: 'Content delivery network integration for fast global asset distribution',
        priority: 'medium'
      },
      {
        name: 'Data Export',
        description: 'Export functionality supporting multiple formats (CSV, JSON, Excel) with customizable fields',
        priority: 'medium'
      },
      {
        name: 'Data Encryption',
        description: 'End-to-end encryption for sensitive data with key management system',
        priority: 'high'
      }
    ]
  }
];
