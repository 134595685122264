
import { TaskView } from './TaskView';
import { useParams } from 'react-router-dom';

export function ListView() {
  const { listId } = useParams<{ listId: string }>();

  if (!listId) {
    return <div>No list selected</div>;
  }

  return (
    <div className="h-full">
      <TaskView />
    </div>
  );
}
