import { Card } from "@/components/ui/card";
import { Users, DollarSign } from "lucide-react";
import { CompanyWithRelations } from "@/types/company";

interface CompanyStatsProps {
  company: CompanyWithRelations;
}

export const CompanyStats = ({ company }: CompanyStatsProps) => {
  const activeDeals = company.deals?.filter(d => !["won", "lost"].includes(d.stage));

  return (
    <div className="grid grid-cols-2 gap-4">
      <Card className="p-4">
        <div className="flex items-center gap-2 text-muted-foreground mb-2">
          <Users className="w-4 h-4" />
          <span className="text-sm">Contacts</span>
        </div>
        <p className="text-2xl font-semibold">
          {company.contacts?.length || 0}
        </p>
      </Card>
      <Card className="p-4">
        <div className="flex items-center gap-2 text-muted-foreground mb-2">
          <DollarSign className="w-4 h-4" />
          <span className="text-sm">Active Deals</span>
        </div>
        <p className="text-2xl font-semibold">
          {activeDeals?.length || 0}
        </p>
      </Card>
    </div>
  );
};