import { Card } from "@/components/ui/card";
import { Building2, Users, DollarSign } from "lucide-react";
import { Button } from "@/components/ui/button";
import { CompanyWithRelations } from "@/types/company";
import { useState } from "react";
import { CompanyDetailsSidebar } from "./CompanyDetailsSidebar";

interface CompanyCardProps {
  company: CompanyWithRelations;
}

export const CompanyCard = ({ company }: CompanyCardProps) => {
  const [showDetails, setShowDetails] = useState(false);

  const activeDeals = company.deals?.filter(
    (deal) => !["won", "lost"].includes(deal.stage)
  );
  const totalValue = activeDeals?.reduce(
    (sum, deal) => sum + (deal.value || 0),
    0
  );

  return (
    <>
      <Card className="p-4 hover:shadow-lg transition-shadow">
        <div className="flex items-center gap-3 mb-4">
          {company.logo_url ? (
            <img
              src={company.logo_url}
              alt={company.name}
              className="w-12 h-12 rounded object-cover"
            />
          ) : (
            <div className="w-12 h-12 rounded bg-muted flex items-center justify-center">
              <Building2 className="w-6 h-6 text-muted-foreground" />
            </div>
          )}
          <div>
            <h3 className="font-medium">{company.name}</h3>
            {company.industry && (
              <p className="text-sm text-muted-foreground">{company.industry}</p>
            )}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 mb-4">
          <div className="flex items-center gap-2">
            <Users className="w-4 h-4 text-muted-foreground" />
            <span className="text-sm">{company.contacts?.length || 0}</span>
          </div>
          <div className="flex items-center gap-2">
            <DollarSign className="w-4 h-4 text-muted-foreground" />
            <span className="text-sm">{totalValue?.toLocaleString() || "—"}</span>
          </div>
        </div>

        <Button
          variant="secondary"
          className="w-full"
          onClick={() => setShowDetails(true)}
        >
          View Details
        </Button>
      </Card>

      <CompanyDetailsSidebar
        companyId={company.id}
        open={showDetails}
        onOpenChange={setShowDetails}
      />
    </>
  );
};