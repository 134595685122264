import { motion } from "framer-motion";
import { IsometricCube } from "../3d/IsometricCube";

export const VenturesHeroHeader = () => {
  return (
    <div className="relative">
      <motion.h1 
        className="text-7xl md:text-[12rem] font-mono font-light tracking-tighter leading-[0.8] text-white/90"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <motion.span 
          className="block transform -skew-x-12 relative z-10"
          animate={{
            textShadow: [
              "0 0 10px rgba(255,77,0,0.5)",
              "0 0 20px rgba(255,77,0,0.3)",
              "0 0 10px rgba(255,77,0,0.5)"
            ]
          }}
          transition={{ duration: 2, repeat: Infinity }}
        >
          Unstuck
        </motion.span>
        <motion.span 
          className="block text-[#FF4D00] relative z-20"
          animate={{
            x: [-2, 2, -2],
            y: [1, -1, 1]
          }}
          transition={{ duration: 0.5, repeat: Infinity }}
        >
          Ventures
        </motion.span>
      </motion.h1>

      <motion.div
        className="absolute -top-20 -right-20 opacity-30"
        animate={{
          rotate: [0, 360],
          scale: [1, 1.2, 1]
        }}
        transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
      >
        <IsometricCube className="text-[#FF4D00]" />
      </motion.div>
    </div>
  );
};