
import { ScrollArea } from "@/components/ui/scroll-area";
import { CRMSidebar } from "../navigation/CRMSidebar";
import { CRMHeader } from "./CRMHeader";

interface CRMLayoutProps {
  children: React.ReactNode;
}

export const CRMLayout = ({ children }: CRMLayoutProps) => {
  return (
    <div className="flex h-full min-h-screen bg-background">
      <CRMSidebar />
      <div className="flex-1 flex flex-col">
        <CRMHeader />
        <ScrollArea className="flex-1">
          <main className="flex-1 p-6">{children}</main>
        </ScrollArea>
      </div>
    </div>
  );
};
