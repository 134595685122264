
import { ArrowRight, Award, Rocket, ExternalLink } from "lucide-react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { motion } from "framer-motion";
import { CaseStudy } from "@/types/caseStudy";
import { Link } from "react-router-dom";
import { Badge } from "@/components/ui/badge";

interface CaseStudyCardProps {
  study: CaseStudy;
}

export const CaseStudyCard = ({ study }: CaseStudyCardProps) => {
  // Convert title to URL-friendly slug
  const slug = study.title
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)/g, '');

  return (
    <motion.div
      whileHover={{ y: -5 }}
      transition={{ duration: 0.2 }}
    >
      <Card className="overflow-hidden h-full hover:shadow-xl transition-all duration-300 bg-card/80 border-accent backdrop-blur-sm group border-2 border-secondary/5 hover:border-secondary/20">
        <div className="aspect-video relative overflow-hidden">
          <img 
            src={study.image} 
            alt={study.title} 
            className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-500"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-background/80 via-background/20 to-transparent" />
          
          {/* Floating badges */}
          <div className="absolute top-3 left-3 flex flex-wrap gap-2">
            {study.tags.slice(0, 2).map((tag, tagIndex) => (
              <Badge 
                key={tagIndex}
                variant="secondary"
                className="text-xs font-medium px-2 py-1 rounded-full bg-secondary/80 text-white backdrop-blur-sm"
              >
                {tag}
              </Badge>
            ))}
            {study.tags.length > 2 && (
              <Badge 
                variant="outline"
                className="text-xs font-medium px-2 py-1 rounded-full bg-background/70 backdrop-blur-sm"
              >
                +{study.tags.length - 2}
              </Badge>
            )}
          </div>
          
          {/* Title overlay */}
          <div className="absolute bottom-0 left-0 w-full p-6">
            <h3 className="text-2xl font-semibold text-white drop-shadow-md group-hover:text-secondary transition-colors">
              {study.title}
            </h3>
          </div>
        </div>
        
        <div className="p-6">
          <p className="text-muted-foreground mb-6 text-lg">{study.description}</p>

          {study.metrics && (
            <div className="grid grid-cols-3 gap-4 mb-6 p-4 bg-accent/30 rounded-lg">
              {study.metrics.map((metric, metricIndex) => (
                <div key={metricIndex} className="text-center">
                  <div className="text-secondary text-xl font-bold mb-1">{metric.value}</div>
                  <div className="text-xs text-muted-foreground">{metric.label}</div>
                </div>
              ))}
            </div>
          )}

          <div className="space-y-4 mb-6">
            {study.impact && (
              <div className="flex gap-2">
                <Rocket className="h-5 w-5 text-secondary flex-shrink-0 mt-0.5" />
                <div>
                  <p className="text-sm font-medium text-foreground">Impact</p>
                  <p className="text-sm text-muted-foreground">{study.impact}</p>
                </div>
              </div>
            )}
            
            {study.awards && (
              <div className="flex gap-2">
                <Award className="h-5 w-5 text-secondary flex-shrink-0 mt-0.5" />
                <div>
                  <p className="text-sm font-medium text-foreground">Recognition</p>
                  <p className="text-sm text-muted-foreground">{study.awards}</p>
                </div>
              </div>
            )}
            
            {study.backing && (
              <div className="flex gap-2">
                <ExternalLink className="h-5 w-5 text-secondary flex-shrink-0 mt-0.5" />
                <div>
                  <p className="text-sm font-medium text-foreground">Status</p>
                  <p className="text-sm text-muted-foreground">{study.backing}</p>
                </div>
              </div>
            )}
          </div>

          <Link to={`/case-study/${slug}`}>
            <Button 
              variant="secondary" 
              className="w-full group"
            >
              View Case Study <ArrowRight className="ml-2 h-4 w-4 group-hover:translate-x-1 transition-transform" />
            </Button>
          </Link>
        </div>
      </Card>
    </motion.div>
  );
};
