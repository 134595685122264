
import { motion } from "framer-motion";
import { Rocket, Star, Zap, ArrowRight, Shield } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";

export const FlareTeaser = () => {
  const navigate = useNavigate();

  return (
    <motion.div 
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="relative overflow-hidden p-8 md:p-12 rounded-2xl"
    >
      <div className="relative z-10">
        <div className="flex items-center gap-2 text-brand-orange font-mono text-sm mb-6">
          <Shield className="w-4 h-4" />
          <span>Invite-Only Program</span>
        </div>

        <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-10 mb-10">
          <div className="space-y-4">
            <h3 className="text-2xl md:text-3xl font-display font-bold text-white flex items-center gap-3">
              <Rocket className="w-7 h-7 text-brand-orange" />
              FLARE Program
            </h3>
            <div className="text-brand-orange/80 font-mono text-sm tracking-wider">
              Fast Launch And Rapid Evolution
            </div>
            <p className="text-gray-300 max-w-xl leading-relaxed">
              Build your MVP in just 7 days with our exclusive rapid development program - 10x faster and cheaper than traditional development.
            </p>
          </div>

          <div className="flex flex-col sm:flex-row gap-4">
            <Button
              onClick={() => navigate('/flare')}
              className="group bg-brand-orange hover:bg-brand-orange/90 text-white font-mono rounded-full px-6 py-2.5"
            >
              Learn More
              <ArrowRight className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" />
            </Button>
            <Button
              onClick={() => navigate('/flare/apply')}
              variant="outline"
              className="border-brand-orange text-brand-orange hover:bg-brand-orange/10 font-mono rounded-full px-6 py-2.5"
            >
              Check Eligibility
              <Zap className="ml-2 w-4 h-4" />
            </Button>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
          {[
            { icon: Zap, text: "7-Day MVP Development" },
            { icon: Star, text: "Elite Development Team" },
            { icon: Rocket, text: "Rapid Iteration Cycles" }
          ].map((benefit, index) => (
            <div 
              key={index}
              className="flex items-center gap-3 text-sm text-gray-300 bg-white/5 backdrop-blur-sm p-4 rounded-xl"
            >
              <benefit.icon className="w-5 h-5 text-brand-orange flex-shrink-0" />
              <span>{benefit.text}</span>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};
