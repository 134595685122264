
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ChevronDown } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

export const ProgramsDropdown = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const getActiveText = () => {
    if (location.pathname.includes('/ventures')) return 'Unstuck Ventures';
    if (location.pathname.includes('/anywhere')) return 'Unstuck Anywhere';
    return 'Startups';
  };

  const isActive = (path: string) => {
    if (path !== '/' && location.pathname.includes(path)) return true;
    return false;
  };

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button 
          variant="ghost" 
          className="text-sm font-mono text-brand-orange hover:text-white hover:bg-brand-charcoal flex items-center gap-2"
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setTimeout(() => setOpen(false), 100)}
        >
          {getActiveText()}
          <ChevronDown className="h-4 w-4 transition-transform duration-200 group-data-[state=open]:rotate-180" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent 
        align="end" 
        className="w-[280px] bg-black border border-zinc-800 shadow-xl rounded-lg p-1 z-50"
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <DropdownMenuItem 
          onClick={() => navigate('/ventures')} 
          className={`flex flex-col items-start py-3 px-4 rounded-md transition-colors ${
            isActive('/ventures') 
              ? 'bg-brand-orange text-white' 
              : 'text-brand-orange hover:bg-brand-charcoal hover:text-white'
          }`}
        >
          <span className="font-semibold">Unstuck Ventures</span>
          <span className="text-xs opacity-80">Early-stage startup investment and incubation program</span>
        </DropdownMenuItem>
        
        <DropdownMenuItem 
          onClick={() => window.location.href = 'https://unstuck.lovable.app'} 
          className="flex flex-col items-start py-3 px-4 rounded-md transition-colors text-brand-orange hover:bg-brand-charcoal hover:text-white"
        >
          <span className="font-semibold">Unstuck Anywhere</span>
          <span className="text-xs opacity-80">No-code AI platform for rapid innovation and development</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
