
import { motion } from "framer-motion";
import { ArrowRight, Sparkles } from "lucide-react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export const FlareHero = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isHomePage = location.pathname === '/';
  
  const handleContactClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (isHomePage) {
      const element = document.querySelector('#madlib-section');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // Use navigate instead of direct window.location to prevent full page refresh
      navigate('/');
      // Need a small timeout to ensure navigation completes before scrolling
      setTimeout(() => {
        const element = document.querySelector('#madlib-section');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      className="max-w-5xl mx-auto text-center space-y-8 px-4 md:px-8 pb-16"
    >
      <motion.h1 
        className="text-8xl md:text-9xl font-bold leading-tight"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <span className="bg-gradient-to-r from-[#FF4D00] via-[#FFCDB8] to-[#FF4D00] bg-clip-text text-transparent">FLARE</span>
      </motion.h1>
      
      <motion.p 
        className="text-xl md:text-2xl text-gray-300 max-w-3xl mx-auto leading-relaxed"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
      >
        Fast Launch and Rapid Evolution — AI-powered development that builds your MVP in days, not months
      </motion.p>

      <motion.div 
        className="flex flex-col sm:flex-row justify-center gap-4 pt-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.6 }}
      >
        <button 
          onClick={handleContactClick}
          className="inline-flex items-center justify-center px-8 py-3 rounded-full bg-[#FF4D00] hover:bg-[#FF4D00]/90 text-white font-medium text-lg transition-colors group"
        >
          Start Your Project
          <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
        </button>
        
        <Link 
          to="/flare/canvas" 
          className="inline-flex items-center justify-center px-8 py-3 rounded-full border border-[#FF4D00]/30 hover:bg-[#FF4D00]/10 text-white font-medium text-lg transition-colors"
        >
          Try Canvas
          <Sparkles className="ml-2 w-5 h-5 text-[#FF4D00]" />
        </Link>
      </motion.div>
    </motion.div>
  );
};
