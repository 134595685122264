import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Mail, Phone, User } from "lucide-react";

interface ContactBasicInfoProps {
  formData: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isEditing?: boolean;
}

export const ContactBasicInfo = ({ formData, onChange, isEditing = true }: ContactBasicInfoProps) => {
  if (!isEditing) {
    return (
      <div className="grid grid-cols-2 gap-6">
        <div className="space-y-2">
          <div className="flex items-center gap-2 text-muted-foreground">
            <User className="w-4 h-4" />
            <Label>Name</Label>
          </div>
          <p className="text-lg">
            {formData?.first_name} {formData?.last_name}
          </p>
        </div>

        <div className="space-y-2">
          <div className="flex items-center gap-2 text-muted-foreground">
            <Mail className="w-4 h-4" />
            <Label>Email</Label>
          </div>
          <p className="text-lg">
            {formData?.email ? (
              <a href={`mailto:${formData.email}`} className="text-blue-500 hover:underline dark:text-blue-400">
                {formData.email}
              </a>
            ) : (
              "Not specified"
            )}
          </p>
        </div>

        <div className="space-y-2">
          <div className="flex items-center gap-2 text-muted-foreground">
            <Phone className="w-4 h-4" />
            <Label>Phone</Label>
          </div>
          <p className="text-lg">
            {formData?.phone ? (
              <a href={`tel:${formData.phone}`} className="text-blue-500 hover:underline dark:text-blue-400">
                {formData.phone}
              </a>
            ) : (
              "Not specified"
            )}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-2 gap-6">
      <div className="space-y-2">
        <Label htmlFor="first_name" className="flex items-center gap-2">
          First Name
        </Label>
        <Input
          id="first_name"
          name="first_name"
          value={formData.first_name}
          onChange={onChange}
          className="bg-background"
          required
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="last_name" className="flex items-center gap-2">
          Last Name
        </Label>
        <Input
          id="last_name"
          name="last_name"
          value={formData.last_name}
          onChange={onChange}
          className="bg-background"
          required
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="email" className="flex items-center gap-2">
          <Mail className="w-4 h-4" />
          Email
        </Label>
        <Input
          id="email"
          name="email"
          type="email"
          value={formData.email || ""}
          onChange={onChange}
          className="bg-background"
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="phone" className="flex items-center gap-2">
          <Phone className="w-4 h-4" />
          Phone
        </Label>
        <Input
          id="phone"
          name="phone"
          type="tel"
          value={formData.phone || ""}
          onChange={onChange}
          className="bg-background"
        />
      </div>
    </div>
  );
};