import { format } from "date-fns";
import { Activity, Clock } from "lucide-react";
import { useActivityLogger } from "@/hooks/use-activity-logger";
import { ScrollArea } from "@/components/ui/scroll-area";

interface CompanyActivitiesProps {
  companyId: string;
}

export const CompanyActivities = ({ companyId }: CompanyActivitiesProps) => {
  const { useEntityActivities } = useActivityLogger();
  const { data: activities, isLoading } = useEntityActivities('company', companyId);

  if (isLoading) {
    return <div className="p-4 text-muted-foreground">Loading activities...</div>;
  }

  return (
    <ScrollArea className="h-[400px] pr-4">
      <div className="space-y-4">
        {activities?.map((activity) => (
          <div 
            key={activity.id} 
            className="flex items-start gap-3 p-3 rounded-lg border bg-card/50"
          >
            <Activity className="w-4 h-4 mt-1 text-muted-foreground" />
            <div className="flex-1 space-y-1">
              <p className="text-sm">{activity.details?.description || activity.action}</p>
              <div className="flex items-center gap-2 text-xs text-muted-foreground">
                <Clock className="w-3 h-3" />
                <span>{format(new Date(activity.created_at), 'MMM d, yyyy h:mm a')}</span>
                {activity.admin_profiles?.name && (
                  <>
                    <span>•</span>
                    <span>by {activity.admin_profiles.name}</span>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
        {!activities?.length && (
          <div className="text-center text-muted-foreground py-8">
            No activities recorded yet
          </div>
        )}
      </div>
    </ScrollArea>
  );
};