
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { User, Building2, MapPin, Phone } from 'lucide-react';
import { toast } from 'sonner';
import { DatabaseEmployee, EmergencyContact, MedicalInfo, Preferences } from '@/types/employee';

export const DashboardProfile = () => {
  const { data: profile, isLoading } = useQuery({
    queryKey: ['employee-profile'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const { data, error } = await supabase
        .from('employee_profiles')
        .select('*')
        .eq('user_id', user.id)
        .maybeSingle();

      if (error) {
        toast.error('Failed to load profile');
        throw error;
      }

      const defaultEmergencyContact: EmergencyContact = {
        name: '',
        relationship: '',
        phone: ''
      };

      const defaultMedicalInfo: MedicalInfo = {
        allergies: '',
        conditions: '',
        medications: ''
      };

      const defaultPreferences: Preferences = {
        dietary: '',
        accommodations: ''
      };

      const transformedData: DatabaseEmployee = {
        id: data.id,
        user_id: data.user_id,
        first_name: data.first_name,
        last_name: data.last_name,
        title: data.title,
        department: data.department,
        reports_to: data.reports_to,
        start_date: data.start_date,
        email: data.email,
        work_phone: data.work_phone,
        personal_phone: data.personal_phone,
        avatar_url: data.avatar_url,
        created_at: data.created_at,
        updated_at: data.updated_at,
        pin: data.pin,
        location: data.location,
        birthday: data.birthday,
        personal_email: data.personal_email,
        profile_claimed: data.profile_claimed,
        is_admin: data.is_admin,
        invite_token: data.invite_token,
        invite_sent_at: data.invite_sent_at,
        status: data.status,
        archived_at: data.archived_at,
        archived_reason: data.archived_reason,
        emergency_contacts: Array.isArray(data?.emergency_contacts) 
          ? (data.emergency_contacts as any[]).map(contact => ({
              name: String(contact?.name || ''),
              relationship: String(contact?.relationship || ''),
              phone: String(contact?.phone || '')
            }))
          : [defaultEmergencyContact],
        medical_info: data?.medical_info && typeof data.medical_info === 'object'
          ? {
              allergies: String((data.medical_info as any)?.allergies || ''),
              conditions: String((data.medical_info as any)?.conditions || ''),
              medications: String((data.medical_info as any)?.medications || '')
            }
          : defaultMedicalInfo,
        preferences: data?.preferences && typeof data.preferences === 'object'
          ? {
              dietary: String((data.preferences as any)?.dietary || ''),
              accommodations: String((data.preferences as any)?.accommodations || '')
            }
          : defaultPreferences,
        allergies: Array.isArray(data?.allergies) ? data.allergies : [],
        dietary_preferences: Array.isArray(data?.dietary_preferences) ? data.dietary_preferences : [],
        accommodations: Array.isArray(data?.accommodations) ? data.accommodations : []
      };

      return transformedData;
    }
  });

  if (isLoading) {
    return <div>Loading profile...</div>;
  }

  if (!profile) {
    return <div>No profile found</div>;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Profile Overview</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex items-start space-x-4">
          <Avatar className="h-20 w-20">
            <AvatarImage src={profile.avatar_url || undefined} alt={profile.first_name} />
            <AvatarFallback>
              {profile.first_name?.[0]}{profile.last_name?.[0]}
            </AvatarFallback>
          </Avatar>
          
          <div className="space-y-3">
            <div>
              <h3 className="text-2xl font-semibold">
                {profile.first_name} {profile.last_name}
              </h3>
              <p className="text-muted-foreground">{profile.title}</p>
            </div>

            <div className="flex flex-wrap gap-3">
              <div className="flex items-center gap-2">
                <Building2 className="h-4 w-4 text-muted-foreground" />
                <span>{profile.department}</span>
              </div>
              {profile.location && (
                <div className="flex items-center gap-2">
                  <MapPin className="h-4 w-4 text-muted-foreground" />
                  <span>{profile.location}</span>
                </div>
              )}
              {profile.work_phone && (
                <div className="flex items-center gap-2">
                  <Phone className="h-4 w-4 text-muted-foreground" />
                  <span>{profile.work_phone}</span>
                </div>
              )}
              {profile.is_admin && (
                <Badge variant="secondary">Admin</Badge>
              )}
            </div>

            <div className="flex items-center gap-2">
              <User className="h-4 w-4 text-muted-foreground" />
              <span className="text-sm text-muted-foreground">
                Reports to: {profile.reports_to ? "Manager Name" : "No manager assigned"}
              </span>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
