
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";

export const ServicesHero = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  
  const handleContactClick = () => {
    if (isHomePage) {
      document.querySelector('#madlib-section')?.scrollIntoView({ behavior: 'smooth' });
    } else {
      navigate('/#madlib-section');
    }
  };
  
  return (
    <section className="pt-36 pb-16 text-white relative overflow-hidden">
      <div className="container mx-auto px-4 relative z-10">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="max-w-4xl mx-auto text-center"
        >
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, delay: 0.2 }}
          >
            <h1 className="text-5xl md:text-6xl lg:text-7xl font-display font-bold mb-6 bg-gradient-to-r from-brand-orange via-white to-brand-orange bg-clip-text text-transparent leading-tight">
              Expert Solutions for Your Digital Journey
            </h1>
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, delay: 0.4 }}
          >
            <p className="text-xl md:text-2xl text-gray-300 mb-10 max-w-3xl mx-auto">
              From custom software development to cutting-edge cloud solutions, we deliver innovative 
              digital experiences that drive real business outcomes.
            </p>
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, delay: 0.6 }}
            className="flex flex-col sm:flex-row items-center justify-center gap-4"
          >
            <Button 
              className="bg-gradient-to-r from-brand-orange/90 to-brand-orange hover:from-brand-orange hover:to-brand-orange/90 text-white px-8 py-6 text-lg font-mono rounded-full group w-full sm:w-auto"
              onClick={handleContactClick}
            >
              Schedule a Free Consultation 
              <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
            </Button>
            
            <Button 
              variant="outline"
              className="border-white/10 hover:border-white/20 bg-white/5 hover:bg-white/10 backdrop-blur-sm text-white px-8 py-6 text-lg font-mono rounded-full w-full sm:w-auto"
              onClick={() => document.getElementById('services-gallery')?.scrollIntoView({ behavior: 'smooth' })}
            >
              Explore Our Services
            </Button>
          </motion.div>
        </motion.div>
      </div>
      
      {/* Background elements - subtle and not distracting */}
      <div className="absolute bottom-0 left-0 right-0 h-1/3 bg-gradient-to-t from-black/70 to-transparent pointer-events-none"></div>
      
      {/* Animated accent blobs */}
      <motion.div 
        className="absolute bottom-0 left-0 right-0 h-40 bg-gradient-to-t from-brand-orange/10 to-transparent blur-3xl"
        animate={{ 
          opacity: [0.2, 0.3, 0.2]
        }}
        transition={{ 
          duration: 5, 
          repeat: Infinity,
          repeatType: "reverse" 
        }}
      />
    </section>
  );
};
