
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Check, Plus, LayoutTemplate } from "lucide-react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Badge } from "@/components/ui/badge";
import { useState } from "react";
import { ScreenType } from "../types";

interface AIAnalysisDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  analysis: {
    projectSummary: {
      title: string;
      description: string;
      objectives: string[];
    };
    features: {
      alpha: Array<{
        name: string;
        description: string;
        priority: string;
        points: number;
      }>;
      beta: Array<{
        name: string;
        description: string;
        priority: string;
        points: number;
      }>;
      launch: Array<{
        name: string;
        description: string;
        priority: string;
        points: number;
      }>;
    };
  };
  onAddFeature: (feature: any) => void;
  onAddScreen?: (screen: any) => void;
}

export const AIAnalysisDialog = ({ 
  open, 
  onOpenChange, 
  analysis, 
  onAddFeature,
  onAddScreen 
}: AIAnalysisDialogProps) => {
  const [addedFeatures, setAddedFeatures] = useState<Set<string>>(new Set());
  const [addedScreens, setAddedScreens] = useState<Set<string>>(new Set());

  const handleAddFeature = (feature: any, phase: string) => {
    const featureKey = `${phase}-${feature.name}`;
    if (!addedFeatures.has(featureKey)) {
      onAddFeature({ ...feature, phase });
      setAddedFeatures(new Set([...addedFeatures, featureKey]));
    }
  };

  const handleAddScreen = (feature: any) => {
    if (!onAddScreen) return;
    
    const screenKey = feature.name;
    if (!addedScreens.has(screenKey)) {
      // Map feature to a screen type
      const screenType = mapFeatureToScreenType(feature);
      onAddScreen({ 
        name: feature.name, 
        description: feature.description,
        type: screenType
      });
      setAddedScreens(new Set([...addedScreens, screenKey]));
    }
  };

  // Helper to map feature to screen type based on keywords
  const mapFeatureToScreenType = (feature: any): ScreenType => {
    const nameLower = feature.name.toLowerCase();
    const descLower = feature.description.toLowerCase();
    
    // Map features to screen types based on keywords
    if (nameLower.includes('login') || descLower.includes('login') || descLower.includes('sign in')) {
      return 'auth_login';
    } else if (nameLower.includes('signup') || descLower.includes('signup') || descLower.includes('register')) {
      return 'auth_signup';
    } else if (nameLower.includes('dashboard') || descLower.includes('dashboard')) {
      return 'dash_main';
    } else if (nameLower.includes('profile') || descLower.includes('profile')) {
      return 'profile_view';
    } else if (nameLower.includes('search') || descLower.includes('search')) {
      return 'search_global';
    } else if (nameLower.includes('chat') || descLower.includes('chat') || descLower.includes('message')) {
      return 'collab_chat';
    } else if (nameLower.includes('settings') || descLower.includes('settings')) {
      return 'profile_settings';
    } else if (nameLower.includes('landing') || descLower.includes('landing page')) {
      return 'landing_hero';
    } else if (nameLower.includes('payment') || descLower.includes('payment') || descLower.includes('checkout')) {
      return 'ecom_checkout';
    } else if (nameLower.includes('product') || descLower.includes('product')) {
      return 'ecom_products';
    } else if (nameLower.includes('onboarding') || descLower.includes('onboard')) {
      return 'auth_onboarding';
    } else if (nameLower.includes('notification') || descLower.includes('notifications')) {
      return 'notif_center';
    } else if (nameLower.includes('task') || descLower.includes('tasks')) {
      return 'task_list';
    } else if (nameLower.includes('analytics') || descLower.includes('analytics')) {
      return 'analytics_dashboard';
    } else if (nameLower.includes('list') || descLower.includes('list')) {
      return 'content_list';
    } else if (nameLower.includes('detail') || descLower.includes('detail')) {
      return 'content_detail';
    } else if (nameLower.includes('form') || descLower.includes('form')) {
      return 'form_basic';
    }
    
    // Default fallback
    return 'dash_main';
  };

  const renderFeatureSection = (phase: string, features: any[]) => (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold capitalize">{phase} Phase</h3>
      {features.map((feature, index) => (
        <div key={`${phase}-${index}`} className="bg-secondary/50 p-4 rounded-lg space-y-2">
          <div className="flex items-center justify-between">
            <h4 className="font-medium">{feature.name}</h4>
            <div className="flex gap-1">
              {onAddScreen && (
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => handleAddScreen(feature)}
                  disabled={addedScreens.has(feature.name)}
                  title="Add as screen"
                >
                  {addedScreens.has(feature.name) ? (
                    <Check className="h-4 w-4 text-green-500" />
                  ) : (
                    <LayoutTemplate className="h-4 w-4" />
                  )}
                </Button>
              )}
              <Button
                variant="ghost"
                size="sm"
                onClick={() => handleAddFeature(feature, phase)}
                disabled={addedFeatures.has(`${phase}-${feature.name}`)}
                title="Add as feature"
              >
                {addedFeatures.has(`${phase}-${feature.name}`) ? (
                  <Check className="h-4 w-4 text-green-500" />
                ) : (
                  <Plus className="h-4 w-4" />
                )}
              </Button>
            </div>
          </div>
          <p className="text-sm text-muted-foreground">{feature.description}</p>
          <div className="flex gap-2">
            <Badge variant="secondary">{feature.priority}</Badge>
            <Badge variant="secondary">{feature.points} points</Badge>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-3xl max-h-[80vh] flex flex-col">
        <DialogHeader>
          <DialogTitle>{analysis.projectSummary.title}</DialogTitle>
          <DialogDescription>
            {analysis.projectSummary.description}
          </DialogDescription>
        </DialogHeader>

        <div className="bg-muted/50 p-4 rounded-lg my-4">
          <h3 className="font-medium mb-2">Key Objectives:</h3>
          <ul className="list-disc list-inside space-y-1">
            {analysis.projectSummary.objectives.map((objective, index) => (
              <li key={index} className="text-sm text-muted-foreground">{objective}</li>
            ))}
          </ul>
        </div>

        <ScrollArea className="flex-1 pr-4">
          <div className="space-y-6">
            {renderFeatureSection('alpha', analysis.features.alpha)}
            {renderFeatureSection('beta', analysis.features.beta)}
            {renderFeatureSection('launch', analysis.features.launch)}
          </div>
        </ScrollArea>

        <div className="mt-4 pt-4 border-t border-border flex justify-between items-center">
          <div className="text-sm text-muted-foreground">
            <span className="inline-flex items-center mr-4">
              <Plus className="h-3 w-3 mr-1" /> Feature
            </span>
            <span className="inline-flex items-center">
              <LayoutTemplate className="h-3 w-3 mr-1" /> Screen
            </span>
          </div>
          <Button 
            variant="outline" 
            onClick={() => onOpenChange(false)}
          >
            Done
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
