
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { Plus, Building2, MoreVertical, ChevronDown, ChevronRight, Trash2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Space, Folder, List, Workspace } from './hooks/useWorkspaceData';
import { SpaceItem } from './components/SpaceItem';
import { 
  DropdownMenu, 
  DropdownMenuContent, 
  DropdownMenuItem, 
  DropdownMenuTrigger 
} from '@/components/ui/dropdown-menu';

interface WorkspaceSidebarProps {
  workspaces: Workspace[];
  spaces: Space[];
  folders: Folder[];
  lists: List[];
  selectedWorkspace: string | null;
  selectedSpace: string | null;
  selectedFolder: string | null;
  onWorkspaceClick: (workspaceId: string) => void;
  onSpaceClick: (spaceId: string) => void;
  onFolderClick: (folderId: string) => void;
  onListClick: (listId: string) => void;
  onNewWorkspace: () => void;
  onNewSpace: () => void;
  onNewFolder: (spaceId: string) => void;  // Updated to accept spaceId parameter
  onNewList: (folderId: string) => void;   // Updated to accept folderId parameter
  deleteWorkspace: (id: string) => void;
  deleteSpace: (id: string) => void;
  deleteFolder: (id: string) => void;
  deleteList: (id: string) => void;
  className?: string;
}

export function WorkspaceSidebar({
  workspaces,
  spaces,
  folders,
  lists,
  selectedWorkspace,
  selectedSpace,
  selectedFolder,
  onWorkspaceClick,
  onSpaceClick,
  onFolderClick,
  onListClick,
  onNewWorkspace,
  onNewSpace,
  onNewFolder,
  onNewList,
  deleteWorkspace,
  deleteSpace,
  deleteFolder,
  deleteList,
  className
}: WorkspaceSidebarProps) {
  const [expandedWorkspaces, setExpandedWorkspaces] = useState<Record<string, boolean>>({});
  const navigate = useNavigate();

  const toggleWorkspace = (workspaceId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    setExpandedWorkspaces(prev => ({
      ...prev,
      [workspaceId]: !prev[workspaceId]
    }));
  };

  const navigateToList = (listId: string) => {
    navigate(`/workspace/list/${listId}`);
  };

  return (
    <div className={cn("flex flex-col bg-background", className)}>
      <div className="flex items-center justify-between p-4 border-b border-accent">
        <div className="flex items-center gap-2">
          <h2 className="text-lg font-semibold">Workspaces</h2>
          <Button variant="ghost" size="icon" className="h-6 w-6" onClick={onNewWorkspace}>
            <Plus className="h-4 w-4" />
          </Button>
        </div>
      </div>

      <div className="flex-1 overflow-auto py-2">
        <div className="px-3 space-y-1">
          {workspaces.map((workspace) => (
            <div key={workspace.id} className="space-y-1">
              <div className="group flex items-center">
                <button
                  onClick={() => onWorkspaceClick(workspace.id)}
                  className={cn(
                    "flex-1 flex items-center gap-2 px-2 py-1.5 text-sm rounded-md transition-colors",
                    "hover:bg-accent/50",
                    selectedWorkspace === workspace.id && "bg-accent text-accent-foreground"
                  )}
                >
                  <button
                    onClick={(e) => toggleWorkspace(workspace.id, e)}
                    className="p-0.5 hover:bg-accent/50 rounded"
                  >
                    {expandedWorkspaces[workspace.id] ? (
                      <ChevronDown className="h-4 w-4" />
                    ) : (
                      <ChevronRight className="h-4 w-4" />
                    )}
                  </button>
                  <Building2 className="h-4 w-4" />
                  <span className="flex-1 text-left">{workspace.name}</span>
                </button>
                <div className="flex items-center gap-0.5 opacity-0 group-hover:opacity-100 transition-opacity">
                  <Button variant="ghost" size="icon" className="h-7 w-7" onClick={() => onNewSpace()}>
                    <Plus className="h-4 w-4" />
                  </Button>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" size="icon" className="h-7 w-7">
                        <MoreVertical className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end" className="w-[180px]">
                      <DropdownMenuItem
                        onClick={() => deleteWorkspace(workspace.id)}
                        className="text-destructive focus:text-destructive"
                      >
                        <Trash2 className="mr-2 h-4 w-4" />
                        Delete Workspace
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>

              {expandedWorkspaces[workspace.id] && (
                <div className="ml-4">
                  {spaces
                    .filter((space) => space.workspace_id === workspace.id)
                    .map((space) => (
                      <SpaceItem
                        key={space.id}
                        space={space}
                        folders={folders}
                        lists={lists}
                        isExpanded={selectedSpace === space.id}
                        isSelected={selectedSpace === space.id}
                        selectedFolder={selectedFolder}
                        onSpaceClick={onSpaceClick}
                        onFolderClick={onFolderClick}
                        onToggle={() => {}}
                        onNewFolder={onNewFolder}
                        onNewList={onNewList}
                        navigateToList={navigateToList}
                        deleteSpace={deleteSpace}
                        deleteFolder={deleteFolder}
                        deleteList={deleteList}
                      />
                    ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
