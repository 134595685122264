import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Activity, Clock, ArrowLeft, Pencil, Save, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { format, isValid, parseISO } from "date-fns";

interface ContactHeaderProps {
  contact: any;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  handleSubmit: (e: React.FormEvent) => Promise<void>;
}

export const ContactHeader = ({ 
  contact, 
  isEditing, 
  setIsEditing, 
  handleSubmit 
}: ContactHeaderProps) => {
  const navigate = useNavigate();

  const getEngagementColor = (score: number) => {
    if (score >= 75) return "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200";
    if (score >= 50) return "bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200";
    return "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-200";
  };

  const formatDate = (dateString: string | null) => {
    if (!dateString) return "Not available";
    const date = parseISO(dateString);
    return isValid(date) ? format(date, "MMM d, yyyy") : "Invalid date";
  };

  return (
    <>
      <Button 
        variant="ghost" 
        onClick={() => navigate("/admin")}
        className="mb-6"
      >
        <ArrowLeft className="w-4 h-4 mr-2" />
        Back to Contacts
      </Button>

      <div className="flex items-center justify-between mb-6">
        <div className="space-y-1">
          <h1 className="text-2xl font-bold">
            {contact?.first_name} {contact?.last_name}
          </h1>
          <div className="flex items-center gap-2 text-sm text-muted-foreground">
            <Activity className="w-4 h-4" />
            <Badge className={getEngagementColor(contact?.engagement_score || 0)}>
              Engagement Score: {contact?.engagement_score || 0}
            </Badge>
            <span>•</span>
            <Clock className="w-4 h-4" />
            <span>
              Last Updated: {formatDate(contact?.updated_at)}
            </span>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Button
            variant={isEditing ? "outline" : "default"}
            onClick={() => setIsEditing(!isEditing)}
            size="sm"
            className="gap-2"
          >
            {isEditing ? (
              <>
                <X className="w-4 h-4" />
                Cancel
              </>
            ) : (
              <>
                <Pencil className="w-4 h-4" />
                Edit
              </>
            )}
          </Button>
          {isEditing && (
            <Button onClick={handleSubmit} size="sm" className="gap-2">
              <Save className="w-4 h-4" />
              Save Changes
            </Button>
          )}
        </div>
      </div>
    </>
  );
};