
import { motion } from "framer-motion";
import { Guide, ChecklistContent, PlaybookContent } from "@/types/guides";
import { BookOpen, Check, ChevronRight, List, Lightbulb, Clock } from "lucide-react";

interface GuideContentProps {
  guide: Guide;
}

export const GuideContent = ({ guide }: GuideContentProps) => {
  const isChecklistContent = (content: any): content is ChecklistContent => {
    return 'sections' in content;
  };

  const isPlaybookContent = (content: any): content is PlaybookContent => {
    return 'chapters' in content;
  };

  // Helper function to render different guide types
  const renderGuideContent = () => {
    if (isChecklistContent(guide.content)) {
      return renderChecklistContent(guide.content);
    } else if (isPlaybookContent(guide.content)) {
      return renderPlaybookContent(guide.content);
    }
    return null;
  };

  const renderChecklistContent = (content: ChecklistContent) => {
    return (
      <div className="space-y-8">
        {content.sections.map((section, idx) => (
          <motion.div 
            key={idx}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: idx * 0.1 }}
            className="bg-white dark:bg-gray-800/50 rounded-lg shadow-sm border border-gray-100 dark:border-gray-700/50 p-6"
          >
            <h3 className="text-xl font-semibold mb-4 text-secondary dark:text-secondary/90 flex items-center">
              <List className="mr-2 h-5 w-5 text-brand-orange" />
              {section.title}
            </h3>
            <ul className="space-y-4">
              {section.items.map((item, itemIdx) => (
                <li key={itemIdx} className="flex items-start gap-4">
                  <div className="flex-shrink-0 mt-1">
                    <div className="w-5 h-5 rounded-full bg-brand-orange/10 flex items-center justify-center">
                      <Check className="h-3 w-3 text-brand-orange" />
                    </div>
                  </div>
                  <span className="text-gray-700 dark:text-gray-300">{item}</span>
                </li>
              ))}
            </ul>
          </motion.div>
        ))}
      </div>
    );
  };

  const renderPlaybookContent = (content: PlaybookContent) => {
    return (
      <div className="space-y-8">
        {content.chapters.map((chapter, idx) => (
          <motion.div
            key={idx}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: idx * 0.1 }}
            className="bg-white dark:bg-gray-800/50 rounded-lg shadow-sm border border-gray-100 dark:border-gray-700/50 p-6"
          >
            <h3 className="text-xl font-semibold mb-4 text-secondary dark:text-secondary/90 flex items-center">
              <BookOpen className="mr-2 h-5 w-5 text-brand-orange" />
              {chapter.title}
            </h3>
            <div className="space-y-4">
              {chapter.sections.map((section, sectionIdx) => (
                <div key={sectionIdx} className="text-gray-700 dark:text-gray-300">
                  <p className="flex items-start">
                    <ChevronRight className="h-4 w-4 text-brand-orange mt-1 mr-2 flex-shrink-0" />
                    <span>{section}</span>
                  </p>
                </div>
              ))}
            </div>
          </motion.div>
        ))}
      </div>
    );
  };

  // Calculate estimated reading time based on guide type
  const calculateReadingTime = () => {
    if (isChecklistContent(guide.content)) {
      return Math.ceil(
        guide.content.sections.reduce(
          (acc, section) => 
            acc + section.items.reduce(
              (sum, item) => sum + item.split(' ').length, 0
            ), 0
        ) / 200
      ) || 5;
    } else if (isPlaybookContent(guide.content)) {
      return Math.ceil(
        guide.content.chapters.reduce(
          (acc, chapter) => 
            acc + chapter.sections.reduce(
              (sum, section) => sum + section.split(' ').length, 0
            ), 0
        ) / 200
      ) || 5;
    }
    return 5; // Default if calculation fails
  };

  // Calculate item count based on guide type
  const calculateItemCount = () => {
    if (isChecklistContent(guide.content)) {
      return guide.content.sections.reduce(
        (acc, section) => acc + section.items.length, 0
      ) || 0;
    } else if (isPlaybookContent(guide.content)) {
      return guide.content.chapters.reduce(
        (acc, chapter) => acc + chapter.sections.length, 0
      ) || 0;
    }
    return 0; // Default if calculation fails
  };

  return (
    <div className="prose prose-lg max-w-none dark:prose-invert pt-8">
      <div className="bg-secondary/5 dark:bg-secondary/10 rounded-lg p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4 dark:text-white flex items-center">
          <Lightbulb className="mr-2 h-5 w-5 text-brand-orange" />
          About this Guide
        </h2>
        
        <div className="flex items-center mb-4 text-sm text-gray-600 dark:text-gray-400">
          <Clock className="mr-2 h-4 w-4" />
          <span>
            {guide.type === 'checklist' ? 'action items' : 'key insights'}: {calculateItemCount()}
            {' • '}
            {calculateReadingTime()} min read
          </span>
        </div>
        
        <p className="dark:text-gray-300">
          This {guide.type} is designed to help you {
            guide.type === 'checklist' 
              ? 'ensure all critical aspects of your project are covered' 
              : 'understand and implement best practices'
          }. Follow the sections below in order for the best results.
        </p>
      </div>

      {renderGuideContent()}
    </div>
  );
};
