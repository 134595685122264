import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Shield, ToggleLeft, ToggleRight } from 'lucide-react';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from '@/components/ui/alert-dialog';
import { DatabaseEmployee } from '@/types/employee';

interface ProfileAdminAccessProps {
  profile: DatabaseEmployee;
  onAdminToggle: (checked: boolean) => Promise<void>;
}

export const ProfileAdminAccess = ({ profile, onAdminToggle }: ProfileAdminAccessProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center">
          <Shield className="h-5 w-5 mr-2" />
          Admin Access
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex items-center justify-between">
          <div className="space-y-1">
            <h4 className="text-sm font-medium">Administrator Privileges</h4>
            <p className="text-sm text-muted-foreground">
              {profile.is_admin 
                ? 'You have admin access to the system' 
                : 'You have standard user access'}
            </p>
          </div>
          {profile.is_admin ? (
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button 
                  variant="ghost" 
                  size="icon"
                  className="relative"
                >
                  <ToggleRight className="h-6 w-6 text-orange-500" />
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Disable Admin Access?</AlertDialogTitle>
                  <AlertDialogDescription>
                    This will remove your administrator privileges. Are you sure you want to continue?
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction
                    onClick={() => onAdminToggle(false)}
                    className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
                  >
                    Disable Admin Access
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          ) : (
            <Button
              variant="ghost"
              size="icon"
              onClick={() => onAdminToggle(true)}
            >
              <ToggleLeft className="h-6 w-6" />
            </Button>
          )}
        </div>
      </CardContent>
    </Card>
  );
};