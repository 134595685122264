
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "sonner";
import { checkAdminExists, createFirstAdmin, signInAdmin } from '@/utils/adminAuth';

const AdminLoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isFirstAdmin, setIsFirstAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkFirstAdmin = async () => {
      try {
        const hasAdmin = await checkAdminExists();
        setIsFirstAdmin(!hasAdmin);
      } catch (error) {
        console.error('Error checking admin status:', error);
        toast.error('Error checking admin status');
      } finally {
        setLoading(false);
      }
    };

    checkFirstAdmin();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (isFirstAdmin) {
        await createFirstAdmin(email, password);
        toast.success('Admin account created successfully! Please verify your email.');
        navigate('/auth');
      } else {
        await signInAdmin(email, password);
        toast.success('Logged in successfully!');
        navigate('/admin');
      }
    } catch (error: any) {
      console.error('Auth error:', error);
      // More specific error messages
      if (error.message.includes('already registered') || error.message.includes('already exists')) {
        toast.error('This email is already registered. Please try logging in instead.');
      } else if (error.message.includes('Invalid login credentials')) {
        toast.error('Invalid email or password. Please try again.');
      } else if (error.message.includes("don't have admin privileges")) {
        toast.error("This account doesn't have admin privileges.");
      } else {
        toast.error(error.message || 'Error during authentication');
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-background p-4">
      <Card className="w-full max-w-md">
        <form onSubmit={handleSubmit}>
          <CardHeader>
            <CardTitle>{isFirstAdmin ? 'Create Admin Account' : 'Admin Login'}</CardTitle>
            <CardDescription>
              {isFirstAdmin 
                ? 'Set up your first admin account to get started'
                : 'Sign in to access the admin dashboard'}
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="space-y-2">
              <Input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="space-y-2">
              <Input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          </CardContent>
          <CardFooter>
            <Button 
              type="submit" 
              className="w-full"
              disabled={loading}
            >
              {loading ? 'Processing...' : (isFirstAdmin ? 'Create Account' : 'Login')}
            </Button>
          </CardFooter>
        </form>
      </Card>
    </div>
  );
};

export default AdminLoginPage;
