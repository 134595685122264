
import { ReactFlow, Background, useReactFlow, Panel } from '@xyflow/react';
import { Button } from '@/components/ui/button';
import { useState, useEffect } from 'react';
import { nodeTypes } from '../nodes';
import { ViewProps } from '../types/views';
import { Screen } from '../types';
import { LayoutTemplate, RefreshCw, Wand2, Save, Download } from 'lucide-react';
import { WireframeControls } from '../components/WireframeControls';
import { toast } from 'sonner';
import { supabase } from '@/integrations/supabase/client';

export const WireframeView = ({ 
  nodes, 
  edges, 
  onNodesChange, 
  onEdgesChange, 
  onConnect,
  onNodeDrag,
  onNodeDragStop,
  fitView,
  minZoom,
  maxZoom,
  defaultViewport
}: ViewProps) => {
  const [selectedScreen, setSelectedScreen] = useState<string | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const { setViewport } = useReactFlow();

  // Filter to show only screen nodes
  const screenNodes = nodes.filter(node => node.type === 'screen') as any[];
  
  const handleScreenSelect = (nodeId: string) => {
    setSelectedScreen(nodeId);
    const node = screenNodes.find(n => n.id === nodeId);
    if (node) {
      setViewport({
        x: -node.position.x + 200,
        y: -node.position.y + 100,
        zoom: 1.5
      });
    }
  };

  const handleGenerateWireframe = async () => {
    if (!selectedScreen) {
      toast.error("Please select a screen first");
      return;
    }
    
    setIsGenerating(true);
    
    try {
      const selectedNode = screenNodes.find(n => n.id === selectedScreen) as Screen;
      if (!selectedNode) return;
      
      // In a real implementation, this would call an AI service to generate a wireframe
      // For now, we'll simulate it with a timeout and pre-defined elements
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      // Generate mock wireframe elements based on screen type
      const elements = generateMockWireframeElements(selectedNode.data.type);
      
      // Update the node with the generated wireframe
      const updatedNodes = nodes.map(node => {
        if (node.id === selectedScreen) {
          return {
            ...node,
            data: {
              ...node.data,
              wireframe: {
                elements,
                lastUpdated: new Date().toISOString(),
                generatedImage: generateMockImageUrl(selectedNode.data.type)
              }
            }
          };
        }
        return node;
      });
      
      // Replace the "change" type with "replace" which is a valid type
      onNodesChange([{ 
        type: 'replace', 
        item: updatedNodes.find(n => n.id === selectedScreen),
        id: selectedScreen 
      }]);
      
      toast.success("Wireframe generated successfully");
    } catch (error) {
      console.error("Error generating wireframe:", error);
      toast.error("Failed to generate wireframe");
    } finally {
      setIsGenerating(false);
    }
  };

  // Mock function to generate wireframe elements based on screen type
  const generateMockWireframeElements = (screenType: string): any[] => {
    // This would be replaced with actual AI-generated elements
    const baseElements = [
      {
        id: 'container-1',
        type: 'container',
        x: 0,
        y: 0,
        width: 375,
        height: 667,
        props: { backgroundColor: '#ffffff' },
        children: []
      }
    ];
    
    if (screenType.includes('login')) {
      return [...baseElements, 
        {
          id: 'logo',
          type: 'image',
          x: 137.5,
          y: 100,
          width: 100,
          height: 100,
          props: { placeholder: 'Logo' }
        },
        {
          id: 'username',
          type: 'input',
          x: 50,
          y: 250,
          width: 275,
          height: 40,
          props: { placeholder: 'Username' }
        },
        {
          id: 'password',
          type: 'input',
          x: 50,
          y: 310,
          width: 275,
          height: 40,
          props: { placeholder: 'Password', type: 'password' }
        },
        {
          id: 'login-btn',
          type: 'button',
          x: 50,
          y: 380,
          width: 275,
          height: 50,
          content: 'Login',
          props: { primary: true }
        }
      ];
    }
    
    if (screenType.includes('hero')) {
      return [...baseElements,
        {
          id: 'navbar',
          type: 'navbar',
          x: 0,
          y: 0,
          width: 375,
          height: 60,
          props: { fixed: true }
        },
        {
          id: 'hero-title',
          type: 'text',
          x: 50,
          y: 100,
          width: 275,
          height: 80,
          content: 'Your Product Name',
          props: { fontSize: 32, fontWeight: 'bold', textAlign: 'center' }
        },
        {
          id: 'hero-desc',
          type: 'text',
          x: 50,
          y: 190,
          width: 275,
          height: 60,
          content: 'A short description of your amazing product and why users should care about it.',
          props: { fontSize: 16, textAlign: 'center' }
        },
        {
          id: 'cta-button',
          type: 'button',
          x: 87.5,
          y: 280,
          width: 200,
          height: 50,
          content: 'Get Started',
          props: { primary: true }
        },
        {
          id: 'hero-image',
          type: 'image',
          x: 87.5,
          y: 360,
          width: 200,
          height: 200,
          props: { placeholder: 'Product Image' }
        }
      ];
    }
    
    // Default wireframe for other screen types
    return [...baseElements,
      {
        id: 'navbar',
        type: 'navbar',
        x: 0,
        y: 0,
        width: 375,
        height: 60,
        props: { fixed: true }
      },
      {
        id: 'title',
        type: 'text',
        x: 20,
        y: 80,
        width: 335,
        height: 40,
        content: screenType.split('_').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' '),
        props: { fontSize: 24, fontWeight: 'bold' }
      },
      {
        id: 'content-area',
        type: 'container',
        x: 20,
        y: 140,
        width: 335,
        height: 400,
        props: { backgroundColor: '#f5f5f5' },
        content: 'Content for ' + screenType
      }
    ];
  };

  // Mock function to generate image URL
  const generateMockImageUrl = (screenType: string): string => {
    // In a real implementation, this would be an actual generated image
    return `data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='375' height='667' viewBox='0 0 375 667' preserveAspectRatio='none'%3E%3Cg fill='none' stroke='%23aaa' stroke-width='1'%3E%3Crect width='375' height='667' fill='%23f9f9f9'/%3E%3Ctext x='187.5' y='333.5' font-family='Arial' font-size='14' fill='%23666' text-anchor='middle'%3E${screenType} Wireframe%3C/text%3E%3C/g%3E%3C/svg%3E`;
  };

  const handleSaveWireframes = async () => {
    // This would save the wireframes to the database
    toast.success("Wireframes saved successfully");
  };

  const handleExportWireframes = () => {
    // This would export the wireframes as images or PDF
    toast.success("Wireframes exported successfully");
  };

  // Focus on the first screen node when view loads
  useEffect(() => {
    if (screenNodes.length > 0 && !selectedScreen) {
      setSelectedScreen(screenNodes[0].id);
      setViewport({
        x: -screenNodes[0].position.x + 200,
        y: -screenNodes[0].position.y + 100,
        zoom: 1.5
      });
    }
  }, [screenNodes, selectedScreen, setViewport]);

  return (
    <div className="relative h-full">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onNodeDrag={onNodeDrag}
        onNodeDragStop={onNodeDragStop}
        nodeTypes={nodeTypes}
        fitView={fitView}
        minZoom={minZoom}
        maxZoom={maxZoom}
        defaultViewport={defaultViewport}
        snapToGrid={true}
        snapGrid={[8, 8]}
        className="touch-none bg-background"
      >
        <Background />
        
        {/* Wireframe Controls Panel */}
        <Panel position="top-right" className="bg-background/80 backdrop-blur-sm p-4 rounded-lg border border-border shadow-md">
          <div className="flex flex-col space-y-4">
            <div className="flex items-center space-x-2">
              <LayoutTemplate className="h-5 w-5 text-muted-foreground" />
              <h3 className="text-sm font-medium">Wireframe Generator</h3>
            </div>
            
            <div className="flex flex-col space-y-2">
              <label className="text-xs text-muted-foreground">Select Screen</label>
              <select 
                className="w-full rounded-md border border-input bg-background p-2 text-sm" 
                value={selectedScreen || ''}
                onChange={(e) => handleScreenSelect(e.target.value)}
              >
                <option value="">Select a screen</option>
                {screenNodes.map(node => (
                  <option key={node.id} value={node.id}>
                    {node.data.label}
                  </option>
                ))}
              </select>
            </div>
            
            <div className="flex space-x-2">
              <Button
                size="sm"
                onClick={handleGenerateWireframe}
                disabled={!selectedScreen || isGenerating}
                className="flex-1"
              >
                {isGenerating ? <RefreshCw className="h-4 w-4 mr-2 animate-spin" /> : <Wand2 className="h-4 w-4 mr-2" />}
                Generate
              </Button>
              
              <Button
                size="sm"
                variant="outline"
                onClick={handleSaveWireframes}
                className="flex-1"
              >
                <Save className="h-4 w-4 mr-2" />
                Save
              </Button>
            </div>
            
            <Button
              size="sm"
              variant="secondary"
              onClick={handleExportWireframes}
            >
              <Download className="h-4 w-4 mr-2" />
              Export Wireframes
            </Button>
          </div>
        </Panel>
        
        {/* Wireframe Controls for the selected screen */}
        {selectedScreen && (
          <WireframeControls 
            screenId={selectedScreen}
            nodes={nodes}
            onNodesChange={onNodesChange}
          />
        )}
      </ReactFlow>
    </div>
  );
};
