import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { 
  Calendar,
  DollarSign,
  Building,
  User
} from "lucide-react";

export const DealHeader = ({ deal }: { deal: any }) => {
  const getStageColor = (stage: string) => {
    switch (stage) {
      case 'won':
        return 'bg-green-500/10 text-green-500 hover:bg-green-500/20';
      case 'lost':
        return 'bg-red-500/10 text-red-500 hover:bg-red-500/20';
      default:
        return 'bg-blue-500/10 text-blue-500 hover:bg-blue-500/20';
    }
  };

  return (
    <div>
      <div className="flex items-center gap-3 mb-3">
        <Badge
          className={`px-3 py-1 text-sm font-medium capitalize ${getStageColor(deal.stage)}`}
        >
          {deal.stage}
        </Badge>
      </div>
      <h1 className="text-2xl font-bold mb-4">{deal.name}</h1>
      <div className="flex flex-wrap items-center gap-6 text-muted-foreground">
        {deal.companies && (
          <div className="flex items-center gap-2">
            <Building className="w-4 h-4" />
            <span className="text-sm font-medium">{deal.companies?.name}</span>
          </div>
        )}
        {deal.contacts && (
          <div className="flex items-center gap-2">
            <User className="w-4 h-4" />
            <span className="text-sm font-medium">
              {deal.contacts.first_name} {deal.contacts.last_name}
            </span>
          </div>
        )}
        <div className="flex items-center gap-2">
          <DollarSign className="w-4 h-4" />
          <span className="text-sm font-medium">${deal.value?.toLocaleString()}</span>
        </div>
        {deal.expected_close_date && (
          <div className="flex items-center gap-2">
            <Calendar className="w-4 h-4" />
            <span className="text-sm font-medium">
              {new Date(deal.expected_close_date).toLocaleDateString()}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};