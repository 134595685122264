
import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { List, KanbanSquare, Calendar, Plus } from 'lucide-react';
import { TaskBoard } from './TaskBoard';
import { CreateTaskDialog } from './CreateTaskDialog';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { useParams, useNavigate } from 'react-router-dom';
import { Task } from '@/types/task';
import { Card } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { AlertTriangle, CheckCircle, Clock } from 'lucide-react';

export function TaskView() {
  const { listId, workspaceId } = useParams<{ listId: string; workspaceId: string }>();
  const navigate = useNavigate();
  const [view, setView] = useState<'list' | 'board' | 'calendar'>('list');
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const queryClient = useQueryClient();

  const { data: tasks = [], isLoading } = useQuery({
    queryKey: ['tasks', listId],
    queryFn: async () => {
      console.log('Fetching tasks for list:', listId);
      const { data, error } = await supabase
        .from('tasks')
        .select('*')
        .eq('list_id', listId)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching tasks:', error);
        throw error;
      }

      console.log('Fetched tasks:', data);
      return data as Task[];
    },
    enabled: !!listId,
  });

  const handleTaskClick = (task: Task) => {
    navigate(`/workspace/${workspaceId}/list/${listId}/task/${task.id}`);
  };

  const getStatusBadge = (status: string) => {
    switch (status) {
      case 'completed':
        return (
          <Badge variant="success" className="flex items-center gap-1">
            <CheckCircle className="h-3 w-3" />
            Completed
          </Badge>
        );
      case 'in_progress':
        return (
          <Badge variant="default" className="flex items-center gap-1">
            <Clock className="h-3 w-3" />
            In Progress
          </Badge>
        );
      case 'blocked':
        return (
          <Badge variant="destructive" className="flex items-center gap-1">
            <AlertTriangle className="h-3 w-3" />
            Blocked
          </Badge>
        );
      default:
        return (
          <Badge variant="secondary" className="flex items-center gap-1">
            <Clock className="h-3 w-3" />
            Todo
          </Badge>
        );
    }
  };

  if (isLoading) {
    return <div className="p-4">Loading tasks...</div>;
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <Tabs value={view} onValueChange={(v) => setView(v as typeof view)}>
          <TabsList>
            <TabsTrigger value="list">
              <List className="h-4 w-4 mr-2" />
              List
            </TabsTrigger>
            <TabsTrigger value="board">
              <KanbanSquare className="h-4 w-4 mr-2" />
              Board
            </TabsTrigger>
            <TabsTrigger value="calendar">
              <Calendar className="h-4 w-4 mr-2" />
              Calendar
            </TabsTrigger>
          </TabsList>
        </Tabs>
        <Button onClick={() => setIsCreateDialogOpen(true)}>
          <Plus className="h-4 w-4 mr-2" />
          New Task
        </Button>
      </div>

      {view === 'board' ? (
        <TaskBoard tasks={tasks} onTaskClick={handleTaskClick} />
      ) : view === 'calendar' ? (
        <div className="h-[500px] flex items-center justify-center text-muted-foreground">
          Calendar view coming soon
        </div>
      ) : (
        <div className="grid gap-4">
          {tasks.length === 0 ? (
            <div className="text-center text-muted-foreground py-8">
              No tasks found. Create your first task to get started.
            </div>
          ) : (
            tasks.map((task) => (
              <Card
                key={task.id}
                className="p-4 hover:bg-accent/50 transition-colors cursor-pointer"
                onClick={() => handleTaskClick(task)}
              >
                <div className="flex items-start justify-between gap-4">
                  <div className="flex-1">
                    <h3 className="font-medium text-black dark:text-white mb-1">{task.title}</h3>
                    {task.description && (
                      <p className="text-sm text-muted-foreground mb-2">{task.description}</p>
                    )}
                  </div>
                  <div>
                    {getStatusBadge(task.status || 'todo')}
                  </div>
                </div>
              </Card>
            ))
          )}
        </div>
      )}

      <CreateTaskDialog
        isOpen={isCreateDialogOpen}
        onOpenChange={setIsCreateDialogOpen}
        onSubmit={() => {
          queryClient.invalidateQueries({ queryKey: ['tasks', listId] });
          setIsCreateDialogOpen(false);
        }}
        listId={listId}
      />
    </div>
  );
}
