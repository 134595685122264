import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Coffee } from 'lucide-react';
import { DatabaseEmployee } from '@/types/employee';

interface ProfilePreferencesProps {
  profile: DatabaseEmployee;
  isEditMode: boolean;
  onNestedChange: (category: 'preferences', field: string, value: string) => void;
  dietaryPreferences: string[];
  accommodations: string[];
}

export const ProfilePreferences = ({
  profile,
  isEditMode,
  onNestedChange,
  dietaryPreferences,
  accommodations
}: ProfilePreferencesProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center">
          <Coffee className="h-5 w-5 mr-2" />
          Preferences
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {isEditMode ? (
            <>
              <div>
                <Label htmlFor="dietary">Dietary Preferences</Label>
                <Select
                  value={profile.preferences?.dietary || 'None'}
                  onValueChange={(value) => onNestedChange('preferences', 'dietary', value)}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select dietary preference" />
                  </SelectTrigger>
                  <SelectContent>
                    {dietaryPreferences.map((pref) => (
                      <SelectItem key={pref} value={pref}>
                        {pref}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div>
                <Label htmlFor="accommodations">Special Accommodations</Label>
                <Select
                  value={profile.preferences?.accommodations || 'None Required'}
                  onValueChange={(value) => onNestedChange('preferences', 'accommodations', value)}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select accommodation" />
                  </SelectTrigger>
                  <SelectContent>
                    {accommodations.map((acc) => (
                      <SelectItem key={acc} value={acc}>
                        {acc}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </>
          ) : (
            <>
              <div>
                <h4 className="font-semibold mb-2">Dietary Preferences</h4>
                <p>{profile.preferences?.dietary || 'None specified'}</p>
              </div>
              <div>
                <h4 className="font-semibold mb-2">Accommodations</h4>
                <p>{profile.preferences?.accommodations || 'None specified'}</p>
              </div>
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
};