import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { LeadList } from "./LeadList";
import { LeadActivities } from "./LeadActivities";
import { LeadScoring } from "./LeadScoring";
import { LeadAnalytics } from "./LeadAnalytics";
import { useIsMobile } from "@/hooks/use-mobile";

export const LeadManagement = () => {
  const [activeTab, setActiveTab] = useState("leads");
  const isMobile = useIsMobile();

  return (
    <Card className="p-4 sm:p-6">
      <div className="mb-4 sm:mb-6">
        <h2 className="text-xl sm:text-2xl font-bold mb-2">Lead Management</h2>
        <p className="text-sm sm:text-base text-muted-foreground">
          Manage and track leads through the sales pipeline
        </p>
      </div>

      <Tabs value={activeTab} onValueChange={setActiveTab}>
        <TabsList className="w-full justify-start overflow-x-auto">
          <TabsTrigger value="leads" className="flex items-center gap-2">
            {isMobile ? "Leads" : "All Leads"}
          </TabsTrigger>
          <TabsTrigger value="activities" className="flex items-center gap-2">
            {isMobile ? "Acts" : "Activities"}
          </TabsTrigger>
          <TabsTrigger value="scoring" className="flex items-center gap-2">
            {isMobile ? "Score" : "Lead Scoring"}
          </TabsTrigger>
          <TabsTrigger value="analytics" className="flex items-center gap-2">
            {isMobile ? "Stats" : "Analytics"}
          </TabsTrigger>
        </TabsList>

        <div className="mt-4 sm:mt-6">
          <TabsContent value="leads">
            <LeadList />
          </TabsContent>

          <TabsContent value="activities">
            <LeadActivities />
          </TabsContent>

          <TabsContent value="scoring">
            <LeadScoring />
          </TabsContent>

          <TabsContent value="analytics">
            <LeadAnalytics />
          </TabsContent>
        </div>
      </Tabs>
    </Card>
  );
};