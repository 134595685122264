import { motion } from "framer-motion";
import { useState, useEffect } from "react";

export const CodeTerminal = () => {
  const [currentLine, setCurrentLine] = useState(0);
  
  const codeLines = [
    "npm create vite@latest my-awesome-project",
    "cd my-awesome-project",
    "npm install",
    "git init",
    "git add .",
    "git commit -m 'Initial commit'",
    "npm run dev"
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentLine((prev) => (prev + 1) % codeLines.length);
    }, 2000);

    return () => clearInterval(timer);
  }, []);

  return (
    <motion.div 
      className="bg-black/80 backdrop-blur-xl rounded-xl p-6 font-mono text-sm text-green-400 overflow-hidden"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div className="flex gap-2 mb-4">
        <div className="w-3 h-3 rounded-full bg-red-500" />
        <div className="w-3 h-3 rounded-full bg-yellow-500" />
        <div className="w-3 h-3 rounded-full bg-green-500" />
      </div>
      <div className="space-y-2">
        {codeLines.map((line, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, x: -20 }}
            animate={{ 
              opacity: index <= currentLine ? 1 : 0,
              x: index <= currentLine ? 0 : -20
            }}
            className="flex items-center gap-2"
          >
            <span className="text-gray-500">$</span>
            <span>{line}</span>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};