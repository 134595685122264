import { Building2, Globe, MapPin } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { CompanyWithRelations } from "@/types/company";

interface CompanyHeaderProps {
  company: CompanyWithRelations;
}

export const CompanyHeader = ({ company }: CompanyHeaderProps) => {
  return (
    <div className="space-y-4">
      <div className="flex items-start gap-4">
        {company.logo_url ? (
          <img
            src={company.logo_url}
            alt={company.name}
            className="w-16 h-16 rounded-lg object-cover border"
          />
        ) : (
          <div className="w-16 h-16 rounded-lg bg-primary/10 flex items-center justify-center">
            <Building2 className="w-8 h-8 text-primary" />
          </div>
        )}
        <div className="space-y-1 flex-1">
          <h3 className="text-xl font-semibold">{company.name}</h3>
          <div className="flex items-center gap-2 flex-wrap">
            {company.industry && (
              <Badge variant="secondary" className="font-normal">
                {company.industry}
              </Badge>
            )}
            {company.employee_count && (
              <Badge variant="secondary" className="font-normal">
                {company.employee_count} employees
              </Badge>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-wrap gap-4">
        {company.website_url && (
          <Button
            variant="outline"
            size="sm"
            className="gap-2"
            asChild
          >
            <a 
              href={company.website_url} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <Globe className="w-4 h-4" />
              Website
            </a>
          </Button>
        )}
        {company.location && (
          <Button
            variant="outline"
            size="sm"
            className="gap-2"
          >
            <MapPin className="w-4 h-4" />
            {company.location}
          </Button>
        )}
      </div>
    </div>
  );
};