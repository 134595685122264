
import { useState } from 'react';
import { Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import { Send } from 'lucide-react';
import type { Database } from '@/types/supabase';

type WorkspaceMessage = Database['public']['Tables']['workspace_chat_messages']['Row'];

interface WorkspaceChatProps {
  messages: WorkspaceMessage[];
  isLoading: boolean;
  onSendMessage: (message: string) => void;
  isSending: boolean;
}

export function WorkspaceChat({ messages, isLoading, onSendMessage, isSending }: WorkspaceChatProps) {
  const [message, setMessage] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!message.trim()) return;
    onSendMessage(message);
    setMessage('');
  };

  return (
    <Card className="p-6">
      <h3 className="text-lg font-medium mb-4">Workspace Chat</h3>
      <div className="flex flex-col h-[500px]">
        <div className="flex-1 overflow-y-auto mb-4 space-y-4">
          {isLoading ? (
            <div className="space-y-4">
              {[...Array(3)].map((_, i) => (
                <Skeleton key={i} className="h-16 w-full" />
              ))}
            </div>
          ) : messages.length === 0 ? (
            <p className="text-center text-muted-foreground py-8">
              No messages yet. Start a conversation!
            </p>
          ) : (
            messages.map((msg) => (
              <div
                key={msg.id}
                className={`p-3 rounded-lg ${
                  msg.type === 'system'
                    ? 'bg-muted text-muted-foreground'
                    : 'bg-primary/10'
                }`}
              >
                <p className="text-sm">{msg.content}</p>
                <p className="text-xs text-muted-foreground mt-1">
                  {new Date(msg.created_at).toLocaleTimeString()}
                </p>
              </div>
            ))
          )}
        </div>
        <form onSubmit={handleSubmit} className="flex gap-2">
          <Input
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type a message or command..."
            className="flex-1"
          />
          <Button type="submit" disabled={!message.trim() || isSending}>
            <Send className="h-4 w-4" />
          </Button>
        </form>
      </div>
    </Card>
  );
}
