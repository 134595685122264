
import { motion } from "framer-motion";
import { ArrowRight, Phone } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate, useLocation } from "react-router-dom";
import { ClientSegment } from "@/types/segments";
import { ContactPopover } from "@/components/ContactPopover";

interface FinalCTAProps {
  segment: ClientSegment;
}

export const FinalCTA = ({ segment }: FinalCTAProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const content = {
    startup: {
      heading: "Ready to Build Your MVP?",
      subheading: "Start your journey with expert guidance and support.",
      primaryCta: "Start Your Project",
      secondaryCta: "View Case Studies",
      contactText: "Talk to a Technical Founder"
    },
    enterprise: {
      heading: "Ready to Modernize Your Systems?",
      subheading: "Schedule a consultation to explore how we can help centralize and transform your digital infrastructure.",
      primaryCta: "Schedule Modernization Assessment",
      secondaryCta: "View Case Studies",
      contactText: "Connect with an Expert"
    },
    government: {
      heading: "Ready to Modernize Your Agency?",
      subheading: "Get started with FedRAMP-compliant solutions.",
      primaryCta: "Request Information",
      secondaryCta: "View Case Studies",
      contactText: "Speak with a Specialist"
    }
  };

  const selectedContent = content[segment];

  const handleContactClick = () => {
    if (isHomePage) {
      document.querySelector('#madlib-section')?.scrollIntoView({ behavior: 'smooth' });
    } else {
      navigate('/#madlib-section');
    }
  };

  return (
    <section className="py-24 backdrop-blur-sm">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="max-w-4xl mx-auto text-center space-y-8"
        >
          <h2 className="text-4xl md:text-5xl font-display font-bold text-foreground">
            {selectedContent.heading}
          </h2>
          <p className="text-xl text-muted-foreground">
            {selectedContent.subheading}
          </p>
          
          <div className="flex flex-col sm:flex-row gap-4 justify-center mt-8">
            <Button 
              size="lg"
              className="bg-[#FF4D00] hover:bg-[#FF4D00]/90 text-white px-8 py-6 rounded-lg font-mono group relative overflow-hidden"
              onClick={handleContactClick}
            >
              <span className="relative z-10 flex items-center">
                {selectedContent.primaryCta}
                <ArrowRight className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" />
              </span>
            </Button>
            
            <Button 
              variant="outline"
              size="lg"
              className="border-[#FF4D00] text-[#FF4D00] hover:bg-[#FF4D00]/10 px-8 py-6 rounded-lg font-mono backdrop-blur-sm"
              onClick={() => navigate('/work')}
            >
              {selectedContent.secondaryCta}
            </Button>
            
            <ContactPopover>
              <Button 
                variant="ghost"
                size="lg"
                className="bg-white/5 hover:bg-white/10 text-white border border-white/20 px-8 py-6 rounded-lg font-mono backdrop-blur-sm flex items-center gap-2"
              >
                <Phone className="w-4 h-4 text-[#FF4D00]" />
                {selectedContent.contactText}
              </Button>
            </ContactPopover>
          </div>
          
          <p className="text-sm text-muted-foreground mt-4">
            Have questions? We're just a call or click away.
          </p>
        </motion.div>
      </div>
    </section>
  );
};
