
import { useLocation } from "react-router-dom";
import { Logo } from "./header/Logo";
import { Navigation } from "./header/Navigation";
import { MobileMenu } from "./header/MobileMenu";
import { UserMenu } from "./header/UserMenu";

export const Header = () => {
  const location = useLocation();
  
  const isVenturesPage = location.pathname.includes('/ventures');
  const isFlarePage = location.pathname.includes('/flare');
  const isIndexPage = location.pathname === '/';

  const getHeaderClass = () => {
    if (isFlarePage) {
      return 'text-[#FF4D00]';
    }
    if (isVenturesPage) {
      return 'text-white';
    }
    return '';
  };

  return (
    <header 
      className={`fixed top-0 left-0 right-0 z-[100] bg-transparent ${getHeaderClass()}`}
    >
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-14 sm:h-16 md:h-20">
          <Logo />
          <Navigation />
          <div className="hidden md:flex items-center gap-4">
            <UserMenu />
          </div>
          <MobileMenu />
        </div>
      </div>
    </header>
  );
};
