
import { supabase } from "@/integrations/supabase/client";

export const checkAdminExists = async () => {
  const { count } = await supabase
    .from('user_roles')
    .select('*', { count: 'exact', head: true })
    .eq('role', 'admin');
  return count !== 0;
};

export const createFirstAdmin = async (email: string, password: string) => {
  // First check if any admin exists
  const hasAdmin = await checkAdminExists();
  if (hasAdmin) {
    throw new Error("An admin account already exists. Please use the login form instead.");
  }

  // Check if user exists
  const { data: existingUser } = await supabase.auth.signUp({
    email,
    password,
    options: {
      emailRedirectTo: typeof window !== 'undefined' 
        ? `${window.location.origin}/admin`
        : '/admin'
    }
  });

  if (!existingUser.user) {
    throw new Error("Failed to create user");
  }

  // Add admin role
  const { error: roleError } = await supabase
    .from('user_roles')
    .insert({
      user_id: existingUser.user.id,
      role: 'admin'
    });

  if (roleError) throw roleError;

  return existingUser;
};

export const signInAdmin = async (email: string, password: string) => {
  const { data: signInData, error: signInError } = await supabase.auth.signInWithPassword({
    email,
    password,
  });

  if (signInError) {
    if (signInError.message.includes('Invalid login credentials')) {
      throw new Error("Invalid email or password. Please try again.");
    }
    throw signInError;
  }

  if (!signInData.session) throw new Error("No session created");

  // Check if user has admin role
  const { data: hasAdminRole, error: roleError } = await supabase
    .rpc('has_role', { role: 'admin' });

  if (roleError || !hasAdminRole) {
    await supabase.auth.signOut();
    throw new Error("You don't have admin privileges.");
  }

  return signInData;
};

// Helper function to check if current user is admin
export const isAdmin = async () => {
  const { data: hasAdminRole } = await supabase.rpc('has_role', { role: 'admin' });
  return hasAdminRole;
};
