import { Card } from "@/components/ui/card";
import { Calendar } from "lucide-react";

interface Phase {
  name: string;
  duration: string;
  tasks: string[];
  deliverables: string[];
}

interface TimelineMilestonesProps {
  phases: Phase[];
}

export const TimelineMilestones = ({ phases }: TimelineMilestonesProps) => {
  return (
    <div className="space-y-4">
      <h3 className="text-2xl font-bold">Development Timeline</h3>
      <Card className="overflow-hidden">
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b">
                <th className="text-left py-3 px-4">Phase</th>
                <th className="text-left py-3 px-4">Duration</th>
                <th className="text-left py-3 px-4">Tasks</th>
                <th className="text-left py-3 px-4">Deliverables</th>
              </tr>
            </thead>
            <tbody>
              {phases.map((phase, index) => (
                <tr 
                  key={index}
                  className="border-b last:border-0 hover:bg-muted/50 transition-colors"
                >
                  <td className="py-3 px-4">
                    <span className="font-semibold">
                      {phase.name}
                    </span>
                  </td>
                  <td className="py-3 px-4">
                    <div className="flex items-center gap-2">
                      <Calendar className="w-4 h-4 text-muted-foreground" />
                      <span>{phase.duration}</span>
                    </div>
                  </td>
                  <td className="py-3 px-4">
                    <ul className="list-disc list-inside space-y-1">
                      {phase.tasks.map((task, taskIndex) => (
                        <li key={taskIndex} className="text-sm text-muted-foreground">
                          {task}
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td className="py-3 px-4">
                    <ul className="list-disc list-inside space-y-1">
                      {phase.deliverables.map((deliverable, index) => (
                        <li key={index} className="text-sm text-muted-foreground">
                          {deliverable}
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};