
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { Loader2 } from "lucide-react";

interface URLAnalyzerProps {
  onAnalysisComplete: (data: any) => void;
}

export const URLAnalyzer = ({ onAnalysisComplete }: URLAnalyzerProps) => {
  const [url, setUrl] = useState("");
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  const validateAndNormalizeUrl = (inputUrl: string): string | null => {
    // Trim whitespace and convert to lowercase
    const trimmedUrl = inputUrl.trim().toLowerCase();
    
    // Check if URL is empty
    if (!trimmedUrl) return null;

    // If no protocol is provided, prepend https://
    const normalizedUrl = trimmedUrl.startsWith('http://') || trimmedUrl.startsWith('https://') 
      ? trimmedUrl 
      : `https://${trimmedUrl}`;

    try {
      // Validate the URL
      new URL(normalizedUrl);
      return normalizedUrl;
    } catch {
      return null;
    }
  };

  const handleAnalyze = async () => {
    const validatedUrl = validateAndNormalizeUrl(url);

    if (!validatedUrl) {
      toast.error("Please enter a valid URL");
      return;
    }

    setIsAnalyzing(true);
    try {
      const { data, error } = await supabase.functions.invoke('analyze-company', {
        body: { url: validatedUrl }
      });

      if (error) throw error;
      
      toast.success("Website technology analysis complete!");
      onAnalysisComplete(data);
    } catch (error) {
      console.error("Error analyzing website:", error);
      toast.error("Failed to analyze website technologies");
    } finally {
      setIsAnalyzing(false);
    }
  };

  return (
    <div className="flex gap-2">
      <Input
        type="url"
        placeholder="Enter website URL"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        className="flex-1"
      />
      <Button 
        onClick={handleAnalyze}
        disabled={isAnalyzing}
      >
        {isAnalyzing ? (
          <>
            <Loader2 className="w-4 h-4 mr-2 animate-spin" />
            Scanning...
          </>
        ) : (
          "Scan Tech"
        )}
      </Button>
    </div>
  );
};
