import { ReactFlow, Background, Node, Edge, OnNodesChange, OnEdgesChange, OnConnect, useReactFlow, Panel } from '@xyflow/react';
import { Button } from '@/components/ui/button';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useState, useEffect } from 'react';
import { nodeTypes } from '../nodes';
import { ViewProps } from '../types/views';

const stages = ['alpha', 'beta', 'launch'];

export const FocusView = ({ 
  nodes, 
  edges, 
  onNodesChange, 
  onEdgesChange, 
  onConnect,
  onNodeDrag,
  onNodeDragStop,
  onDragOver,
  onDrop,
  fitView,
  minZoom,
  maxZoom,
  defaultViewport
}: ViewProps) => {
  const [currentStage, setCurrentStage] = useState('alpha');
  const { setViewport, fitView: fitViewFn } = useReactFlow();

  // Get all nodes connected to the current stage
  const getConnectedNodes = (stageId: string) => {
    // Get all edges connected to/from the stage
    const relevantEdges = edges.filter(edge => 
      edge.source === stageId || edge.target === stageId
    );

    // Get all node IDs connected to these edges
    const connectedNodeIds = new Set(
      relevantEdges.flatMap(edge => [edge.source, edge.target])
    );

    // Return all nodes that are either the stage itself or connected to it
    return nodes.filter(node => 
      node.id === stageId || connectedNodeIds.has(node.id)
    );
  };

  // Filter nodes to show only the current stage and its connected nodes
  const filteredNodes = getConnectedNodes(currentStage);

  // Filter edges to only show connections between visible nodes
  const filteredEdges = edges.filter(edge => {
    const nodeIds = filteredNodes.map(n => n.id);
    return nodeIds.includes(edge.source) && nodeIds.includes(edge.target);
  });

  const navigateStage = (direction: 'prev' | 'next') => {
    const currentIndex = stages.indexOf(currentStage);
    if (direction === 'prev' && currentIndex > 0) {
      setCurrentStage(stages[currentIndex - 1]);
    } else if (direction === 'next' && currentIndex < stages.length - 1) {
      setCurrentStage(stages[currentIndex + 1]);
    }
  };

  // Center view on stage change
  useEffect(() => {
    const stageNode = nodes.find(node => node.id === currentStage);
    if (stageNode) {
      setViewport({
        x: -stageNode.position.x + 100,
        y: -stageNode.position.y + 100,
        zoom: 1.2
      });
    }
    fitViewFn({ padding: 0.2, duration: 800 });
  }, [currentStage, setViewport, fitViewFn, nodes]);

  return (
    <ReactFlow
      nodes={filteredNodes}
      edges={filteredEdges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      onNodeDrag={onNodeDrag}
      onNodeDragStop={onNodeDragStop}
      onDragOver={onDragOver}
      onDrop={onDrop}
      nodeTypes={nodeTypes}
      fitView={fitView}
      className="touch-none"
      minZoom={minZoom}
      maxZoom={maxZoom}
      defaultViewport={defaultViewport}
    >
      <Background />
      <Panel position="bottom-center" className="flex gap-4 items-center">
        <Button
          variant="ghost"
          size="icon"
          onClick={() => navigateStage('prev')}
          disabled={currentStage === stages[0]}
          className="h-8 w-8 bg-accent hover:bg-accent-hover"
        >
          <ChevronLeft className="h-4 w-4" />
        </Button>
        <div className="px-3 py-1.5 bg-accent/10 rounded-md">
          <span className="text-sm text-muted-foreground capitalize">
            {currentStage} Stage
          </span>
        </div>
        <Button
          variant="ghost"
          size="icon"
          onClick={() => navigateStage('next')}
          disabled={currentStage === stages[stages.length - 1]}
          className="h-8 w-8 bg-accent hover:bg-accent-hover"
        >
          <ChevronRight className="h-4 w-4" />
        </Button>
      </Panel>
    </ReactFlow>
  );
};