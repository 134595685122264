import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Search, X } from "lucide-react";

interface BlogSearchProps {
  searchTerm: string;
  onSearchChange: (value: string) => void;
  selectedTags: string[];
  availableTags: string[];
  onTagSelect: (tag: string) => void;
  onTagDeselect: (tag: string) => void;
}

export const BlogSearch = ({
  searchTerm,
  onSearchChange,
  selectedTags,
  availableTags,
  onTagSelect,
  onTagDeselect,
}: BlogSearchProps) => {
  return (
    <div className="mb-8 space-y-4">
      <div className="relative">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground h-4 w-4" />
        <Input
          type="text"
          placeholder="Search articles..."
          value={searchTerm}
          onChange={(e) => onSearchChange(e.target.value)}
          className="pl-10"
        />
      </div>
      
      <div className="space-y-2">
        <div className="flex flex-wrap gap-2">
          {selectedTags.map((tag) => (
            <Button
              key={tag}
              variant="secondary"
              size="sm"
              onClick={() => onTagDeselect(tag)}
              className="flex items-center gap-1"
            >
              {tag}
              <X className="h-3 w-3" />
            </Button>
          ))}
        </div>
        
        <div className="flex flex-wrap gap-2">
          {availableTags
            .filter((tag) => !selectedTags.includes(tag))
            .map((tag) => (
              <Button
                key={tag}
                variant="outline"
                size="sm"
                onClick={() => onTagSelect(tag)}
              >
                {tag}
              </Button>
            ))}
        </div>
      </div>
    </div>
  );
};