
import { Header } from "@/components/Header";
import { Footer } from "@/components/Footer";
import { Hero } from "@/components/Hero";
import { StartupJourney } from "@/components/segments/StartupJourney";
import { useEffect } from "react";
import { motion } from "framer-motion";
import { HeroBackground } from "@/components/hero/HeroBackground";
import { Helmet } from "react-helmet";

const StartupPage = () => {
  useEffect(() => {
    try {
      localStorage.setItem('preferred-segment', 'startup');
    } catch (error) {
      console.warn('Error setting preferred-segment in localStorage:', error);
    }
    
    // Scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Startup Solutions | Unstuck Labs</title>
        <meta name="description" content="Launch your startup MVP in 4 weeks with fixed-price development and technical co-founding support." />
      </Helmet>
      
      <main className="relative min-h-screen bg-black overflow-x-hidden w-full">
        <div className="absolute inset-0">
          <HeroBackground />
        </div>
        
        <Header />
        
        <motion.section 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="relative pt-16"
        >
          <Hero clientSegment="startup" />
        </motion.section>

        <StartupJourney />

        <Footer />
      </main>
    </>
  );
};

export default StartupPage;
