
import { Header } from "@/components/Header";
import { Footer } from "@/components/Footer";
import { IntakeForm } from "@/components/IntakeForm/IntakeForm";
import { Helmet } from "react-helmet";

const IntakeFormPage = () => {
  return (
    <>
      <Helmet>
        <title>Project Intake Form | Unstuck Labs</title>
        <meta
          name="description"
          content="Submit your project details and get a personalized consultation with our development team."
        />
      </Helmet>

      <div className="min-h-screen flex flex-col bg-gradient-to-b from-black to-gray-900">
        <Header />
        
        <main className="flex-1 pt-16 pb-24">
          <div className="container mx-auto px-4">
            <div className="text-center mb-12">
              <h1 className="text-4xl md:text-5xl font-display font-bold text-white">
                Start Your <span className="text-brand-orange">Project Journey</span>
              </h1>
              <p className="mt-4 text-xl text-gray-400 max-w-3xl mx-auto">
                Tell us about your project, and we'll analyze it to provide personalized insights and recommendations.
              </p>
            </div>
            
            <IntakeForm />
          </div>
        </main>
        
        <Footer />
      </div>
    </>
  );
};

export default IntakeFormPage;
