import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Grid2X2, List, SortAsc, SortDesc } from "lucide-react";
import { cn } from "@/lib/utils";

interface ViewControlsProps {
  view: "grid" | "list";
  setView: (view: "grid" | "list") => void;
  sortField: string;
  setSortField: (field: string) => void;
  sortDirection: "asc" | "desc";
  setSortDirection: (direction: "asc" | "desc") => void;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  className?: string;
}

export const ViewControls = ({
  view,
  setView,
  sortField,
  setSortField,
  sortDirection,
  setSortDirection,
  searchTerm,
  setSearchTerm,
  className
}: ViewControlsProps) => {
  return (
    <div className={cn("flex items-center gap-4 flex-wrap", className)}>
      <Input
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="flex-1 min-w-[200px]"
      />
      
      <div className="flex items-center gap-2">
        <Select value={sortField} onValueChange={setSortField}>
          <SelectTrigger className="w-[140px]">
            <SelectValue placeholder="Sort by" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="name">Name</SelectItem>
            <SelectItem value="created_at">Created Date</SelectItem>
            <SelectItem value="industry">Industry</SelectItem>
          </SelectContent>
        </Select>

        <Button
          variant="outline"
          size="icon"
          onClick={() => setSortDirection(sortDirection === "asc" ? "desc" : "asc")}
          className="shrink-0"
        >
          {sortDirection === "asc" ? (
            <SortAsc className="h-4 w-4" />
          ) : (
            <SortDesc className="h-4 w-4" />
          )}
        </Button>
      </div>

      <div className="flex items-center gap-1">
        <Button
          variant={view === "grid" ? "default" : "outline"}
          size="icon"
          onClick={() => setView("grid")}
          className="shrink-0"
        >
          <Grid2X2 className="h-4 w-4" />
        </Button>
        <Button
          variant={view === "list" ? "default" : "outline"}
          size="icon"
          onClick={() => setView("list")}
          className="shrink-0"
        >
          <List className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};