
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { v4 as uuidv4 } from 'uuid';
import { generateAccessCode } from './utils/accessCode';

export const FlareStartPage = () => {
  const [accessCode, setAccessCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [projectTitle, setProjectTitle] = useState('');
  const navigate = useNavigate();

  // Use the access code that was generated when component mounts
  useEffect(() => {
    // Only generate if we don't already have one
    if (!accessCode) {
      const newAccessCode = generateAccessCode();
      setAccessCode(newAccessCode);
    }
  }, [accessCode]);

  const handleCreateProject = async () => {
    if (!projectTitle.trim()) {
      toast.error('Please enter a project title');
      return;
    }

    setIsLoading(true);
    try {
      // Use the pre-generated access code
      const newProjectId = uuidv4();
      
      console.log('Creating new project with access code:', accessCode);

      const { error } = await supabase
        .from('flare_designs')
        .insert({
          id: newProjectId,
          title: projectTitle.trim(),
          access_code: accessCode,
          initial_requirements: '',
          features: [],
          stages: { alpha: [], beta: [], launch: [] },
          created_by: 'wail@unstucklabs.com'
        });

      if (error) throw error;

      toast.success('Project created successfully!');
      navigate(`/flare/design/${newProjectId}`);
    } catch (error) {
      console.error('Error creating project:', error);
      toast.error('Failed to create project. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAccessProject = async () => {
    const trimmedCode = accessCode.trim().toUpperCase();
    if (!trimmedCode) {
      toast.error('Please enter an access code');
      return;
    }

    setIsLoading(true);
    try {
      console.log('Searching for project with access code:', trimmedCode);
      
      const { data, error } = await supabase
        .from('flare_designs')
        .select('id')
        .eq('access_code', trimmedCode)
        .maybeSingle();

      if (error) {
        console.error('Error searching for project:', error);
        toast.error('Failed to search for project. Please try again.');
        return;
      }

      if (!data) {
        console.log('No project found with access code:', trimmedCode);
        toast.error('Project not found. Please check your access code and try again.');
        return;
      }

      console.log('Found project:', data);
      navigate(`/flare/design/${data.id}`);
    } catch (error) {
      console.error('Error accessing project:', error);
      toast.error('Failed to access project. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-20 p-6 bg-card rounded-lg shadow-lg">
      <div className="space-y-6">
        <div>
          <h2 className="text-2xl font-bold mb-4">Create New Project</h2>
          <div className="space-y-4">
            <div className="p-3 bg-accent/20 rounded-md border border-accent/30">
              <label className="text-sm font-medium mb-1 block">Your Access Code</label>
              <div className="font-mono text-lg font-bold text-center py-1">{accessCode}</div>
              <p className="text-xs text-muted-foreground mt-1">Save this code to access your project later</p>
            </div>
            <Input
              placeholder="Enter project title"
              value={projectTitle}
              onChange={(e) => setProjectTitle(e.target.value)}
            />
            <Button 
              onClick={handleCreateProject} 
              className="w-full"
              disabled={isLoading}
            >
              {isLoading ? 'Creating...' : 'Create Project'}
            </Button>
          </div>
        </div>

        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <span className="w-full border-t" />
          </div>
          <div className="relative flex justify-center text-xs uppercase">
            <span className="bg-background px-2 text-muted-foreground">
              Or
            </span>
          </div>
        </div>

        <div>
          <h2 className="text-2xl font-bold mb-4">Access Existing Project</h2>
          <div className="space-y-4">
            <Input
              placeholder="Enter access code"
              value={accessCode}
              onChange={(e) => setAccessCode(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleAccessProject();
                }
              }}
            />
            <Button 
              onClick={handleAccessProject} 
              className="w-full"
              disabled={isLoading}
            >
              {isLoading ? 'Accessing...' : 'Access Project'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
