
import { NavLink, useNavigate } from "react-router-dom";
import { 
  LayoutDashboard, 
  UserRound, 
  Calendar, 
  Clock, 
  Target,
  BookOpen,
  Users,
  LogOut
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";

export const EmployeeSidebar = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const links = [
    { to: "dashboard", icon: LayoutDashboard, label: "Dashboard" },
    { to: "profile", icon: UserRound, label: "Profile" },
    { to: "schedule", icon: Calendar, label: "Schedule" },
    { to: "time-off", icon: Clock, label: "Time Off" },
    { to: "goals", icon: Target, label: "Goals" },
    { to: "handbook", icon: BookOpen, label: "Handbook" },
    { to: "organization", icon: Users, label: "Organization" }
  ];

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      // Clear React Query cache
      queryClient.clear();
      
      // Clear any local storage
      localStorage.clear();
      
      // Navigate to auth page
      navigate('/auth');
      
      toast.success('Successfully logged out');
    } catch (error: any) {
      console.error('Logout error:', error);
      toast.error(error.message || 'Failed to log out');
    }
  };

  return (
    <div className="w-64 border-r border-border h-screen bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 flex flex-col">
      <div className="p-4 border-b border-border/40 bg-background">
        <img 
          src="/lovable-uploads/a7877f84-d391-4a98-83c3-cc62b8bd8c4b.png" 
          alt="Company Logo" 
          className="h-8 mb-2"
        />
      </div>
      
      <div className="flex-1 space-y-4 py-4 bg-transparent">
        <div className="px-3 py-2">
          <h2 className="mb-4 px-4 text-xl font-semibold tracking-tight">Employee Portal</h2>
          <div className="space-y-1">
            {links.map((link) => (
              <NavLink
                key={link.to}
                to={link.to}
                className={({ isActive }) =>
                  `flex items-center gap-3 rounded-lg px-3 py-2 text-sm transition-all hover:bg-accent ${
                    isActive 
                      ? "bg-accent text-accent-foreground font-medium" 
                      : "text-muted-foreground hover:text-foreground"
                  }`
                }
              >
                <link.icon className="h-4 w-4" />
                <span>{link.label}</span>
              </NavLink>
            ))}
          </div>
        </div>
      </div>

      <div className="p-4 border-t border-border/40 mt-auto bg-background">
        <Button 
          variant="outline" 
          className="w-full justify-start" 
          onClick={handleLogout}
        >
          <LogOut className="h-4 w-4 mr-2" />
          Sign Out
        </Button>
      </div>
    </div>
  );
};
