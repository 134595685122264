
import { motion } from "framer-motion";
import { Header } from "@/components/Header";
import { Footer } from "@/components/Footer";
import { ServicesHero } from "@/components/services/ServicesHero";
import { ServiceCard } from "@/components/services/ServiceCard";
import { ServicesCTA } from "@/components/services/ServicesCTA";
import { Code, Rocket, Shield, Zap, Database, Cloud, Server, Cpu, LineChart, Globe, Lock, Shapes } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Card } from "@/components/ui/card";
import { useState } from "react";
import { cn } from "@/lib/utils";
import { ParticleBackground } from "@/components/programs/flare/ParticleBackground";

// Service category filter options
const categories = [
  { id: "all", label: "All Services" },
  { id: "software", label: "Software" },
  { id: "startup", label: "Startup" },
  { id: "government", label: "Government" },
  { id: "innovation", label: "Innovation" },
  { id: "cloud", label: "Cloud" },
  { id: "security", label: "Security" }
];

const services = [
  {
    id: "software",
    title: "Custom Software Development",
    icon: Code,
    description: "We craft tailored software solutions that perfectly align with your business objectives, leveraging cutting-edge technologies and best practices in development.",
    features: [
      "Full-stack web applications",
      "Mobile app development",
      "API development and integration",
      "Cloud-native solutions",
      "AI and machine learning integration"
    ],
    ctaText: "Start Your Project",
    imageSrc: "https://images.unsplash.com/photo-1498050108023-c5249f4df085"
  },
  {
    id: "startup",
    title: "Startup Accelerator",
    icon: Rocket,
    description: "Launch and scale your startup with our proven acceleration program. We provide the guidance, resources, and connections needed to turn your vision into a successful business.",
    features: [
      "Business model validation",
      "Technical co-founding",
      "MVP development",
      "Funding preparation",
      "Market entry strategy"
    ],
    ctaText: "Join Our Program",
    imageSrc: "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d"
  },
  {
    id: "government",
    title: "Government Solutions",
    icon: Shield,
    description: "We deliver secure, compliant, and innovative digital solutions for federal agencies, helping modernize government services and improve citizen experiences.",
    features: [
      "FedRAMP compliance",
      "Cybersecurity solutions",
      "Digital transformation",
      "Legacy system modernization",
      "Citizen engagement platforms"
    ],
    ctaText: "Learn More",
    imageSrc: "https://images.unsplash.com/photo-1483058712412-4245e9b90334"
  },
  {
    id: "innovation",
    title: "Digital Innovation",
    icon: Zap,
    description: "Stay ahead of the curve with our end-to-end digital innovation solutions. We help enterprises embrace emerging technologies and create competitive advantages.",
    features: [
      "Digital strategy consulting",
      "Innovation workshops",
      "Emerging tech integration",
      "Process automation",
      "Digital transformation roadmap"
    ],
    ctaText: "Innovate Now",
    imageSrc: "https://images.unsplash.com/photo-1487058792275-0ad4aaf24ca7"
  },
  {
    id: "cloud",
    title: "Cloud Infrastructure",
    icon: Cloud,
    description: "Modernize your infrastructure with our cloud-first approach. We design, migrate, and manage cloud environments that are secure, scalable, and cost-efficient.",
    features: [
      "Cloud architecture design",
      "AWS/Azure/GCP migrations",
      "Serverless applications",
      "Infrastructure as Code (IaC)",
      "DevOps implementation"
    ],
    ctaText: "Explore Solutions",
    imageSrc: "https://images.unsplash.com/photo-1607799279861-4dd421887fb3"
  },
  {
    id: "software",
    title: "Enterprise Applications",
    icon: Shapes,
    description: "Transform your enterprise with custom applications that integrate seamlessly with your existing systems and drive operational efficiency.",
    features: [
      "ERP system integration",
      "CRM development",
      "Business intelligence dashboards",
      "Workflow automation",
      "Legacy system modernization"
    ],
    ctaText: "Schedule Demo",
    imageSrc: "https://images.unsplash.com/photo-1581092335397-9583eb92d232"
  },
  {
    id: "security",
    title: "Cybersecurity Services",
    icon: Lock,
    description: "Protect your digital assets with our comprehensive cybersecurity solutions. We identify vulnerabilities, implement robust security measures, and ensure compliance.",
    features: [
      "Security assessments",
      "Penetration testing",
      "Security architecture design",
      "Compliance management",
      "Incident response planning"
    ],
    ctaText: "Secure Your Business",
    imageSrc: "https://images.unsplash.com/photo-1563013544-824ae1b704d3"
  },
  {
    id: "innovation",
    title: "Data Analytics & AI",
    icon: LineChart,
    description: "Unlock the power of your data with our advanced analytics and AI solutions. We help you derive actionable insights and create intelligent systems.",
    features: [
      "Data strategy development",
      "Predictive analytics",
      "Machine learning models",
      "Natural language processing",
      "Computer vision solutions"
    ],
    ctaText: "Analyze Your Data",
    imageSrc: "https://images.unsplash.com/photo-1551288049-bebda4e38f71"
  },
  {
    id: "cloud",
    title: "Microservices Architecture",
    icon: Server,
    description: "Improve scalability and resilience with our microservices-based application architecture. We design systems that are modular, maintainable, and scalable.",
    features: [
      "Architecture assessment",
      "Microservices design",
      "Container orchestration",
      "API gateway implementation",
      "Event-driven architectures"
    ],
    ctaText: "Modernize Architecture",
    imageSrc: "https://images.unsplash.com/photo-1558494949-ef010cbdcc31"
  },
  {
    id: "innovation",
    title: "IoT Solutions",
    icon: Cpu,
    description: "Connect the physical and digital worlds with our Internet of Things (IoT) solutions. We develop systems that collect, analyze, and act on real-time data.",
    features: [
      "IoT device integration",
      "Real-time analytics",
      "Edge computing",
      "Remote monitoring systems",
      "Smart infrastructure solutions"
    ],
    ctaText: "Connect Devices",
    imageSrc: "https://images.unsplash.com/photo-1518770660439-4636190af475"
  },
  {
    id: "software",
    title: "Web3 & Blockchain",
    icon: Database,
    description: "Leverage the power of decentralized technologies with our Web3 and blockchain solutions. We develop secure, transparent systems with distributed ledger technology.",
    features: [
      "Smart contract development",
      "Decentralized applications (dApps)",
      "Tokenization",
      "Blockchain integration",
      "Web3 strategy consulting"
    ],
    ctaText: "Explore Web3",
    imageSrc: "https://images.unsplash.com/photo-1639762681057-408e52192e55"
  },
  {
    id: "government",
    title: "Digital Accessibility",
    icon: Globe,
    description: "Ensure your digital products are accessible to all users. We design and develop systems that comply with accessibility standards and provide inclusive experiences.",
    features: [
      "WCAG compliance audits",
      "Accessible design implementation",
      "Section 508 compliance",
      "Remediation services",
      "Staff training & education"
    ],
    ctaText: "Make It Accessible",
    imageSrc: "https://images.unsplash.com/photo-1555421689-d68471e189f2"
  }
];

const ServicesPage = () => {
  const navigate = useNavigate();
  const [activeCategory, setActiveCategory] = useState("all");

  const filteredServices = activeCategory === "all" 
    ? services 
    : services.filter(service => service.id === activeCategory);

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-black to-black text-white overflow-hidden">
      <Helmet>
        <title>Our Services - Unstuck Labs</title>
        <meta 
          name="description" 
          content="Explore our comprehensive range of digital services including custom software development, startup acceleration, government solutions, and digital innovation." 
        />
      </Helmet>
      
      {/* Particle background */}
      <div className="fixed inset-0 z-0 opacity-40">
        <ParticleBackground />
      </div>
      
      {/* Subtle grid pattern */}
      <div className="fixed inset-0 bg-[linear-gradient(to_right,#FF4D0005_1px,transparent_1px),linear-gradient(to_bottom,#FF4D0005_1px,transparent_1px)] bg-[size:4rem_4rem] pointer-events-none"></div>
      
      <Header />
      
      <ServicesHero />
      
      {/* Services Gallery Section */}
      <section className="py-20 relative z-10">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-display font-bold mb-4 bg-gradient-to-r from-brand-orange via-white to-brand-orange bg-clip-text text-transparent">
              Our Services
            </h2>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              Discover how we transform digital visions into reality through our comprehensive range of services.
            </p>
          </motion.div>

          {/* Category Filter Buttons */}
          <div className="flex flex-wrap justify-center gap-3 mb-16">
            {categories.map((category) => (
              <motion.button
                key={category.id}
                className={cn(
                  "px-5 py-2 rounded-full text-sm font-mono transition-all backdrop-blur-sm",
                  activeCategory === category.id 
                    ? "bg-gradient-to-r from-brand-orange/80 to-brand-orange text-white shadow-lg shadow-brand-orange/20" 
                    : "bg-white/5 text-white/80 hover:bg-white/10 border border-white/10 hover:border-white/20"
                )}
                onClick={() => setActiveCategory(category.id)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {category.label}
              </motion.button>
            ))}
          </div>

          {/* Services Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-8 max-w-7xl mx-auto">
            {filteredServices.map((service, index) => (
              <ServiceCard
                key={`${service.id}-${index}`}
                service={service}
                index={index}
                onClick={() => navigate('/contact')}
              />
            ))}
          </div>
        </div>
      </section>

      <ServicesCTA />
      <Footer />
    </div>
  );
};

export default ServicesPage;
