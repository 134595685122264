import { SidebarProvider } from "@/components/ui/sidebar";
import { Button } from "@/components/ui/button";
import { Search, Plus } from "lucide-react";
import { Input } from "@/components/ui/input";

interface DashboardLayoutProps {
  children: React.ReactNode;
  showBackButton?: boolean;
  onBack?: () => void;
}

export const DashboardLayout = ({ children, showBackButton, onBack }: DashboardLayoutProps) => {
  return (
    <SidebarProvider>
      <div className="min-h-screen flex w-full bg-background dark:bg-background transition-colors duration-300">
        <div className="max-w-[1600px] mx-auto px-4 sm:px-6 py-8 sm:py-12 w-full">
          {/* Header with Search and Add New Button */}
          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-8">
            <div>
              <h1 className="text-2xl font-semibold text-foreground mb-2">Admin Dashboard</h1>
              <p className="text-sm text-muted-foreground">
                Manage your contacts, deals, and business operations
              </p>
            </div>
            <div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-4">
              <div className="relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground h-4 w-4" />
                <Input 
                  type="search" 
                  placeholder="Search across all records..." 
                  className="pl-10 w-full sm:w-[280px] bg-background dark:bg-background border-border"
                />
              </div>
              <Button className="bg-primary hover:bg-primary/90 text-primary-foreground">
                <Plus className="h-4 w-4 mr-2" />
                Add New
              </Button>
            </div>
          </div>

          {children}
        </div>
      </div>
    </SidebarProvider>
  );
};