import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { Input } from '@/components/ui/input';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { ChevronDown, ChevronUp, Paperclip, X } from 'lucide-react';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';

interface FeatureNotesProps {
  featureId: string;
  initialNotes?: string;
  attachments?: { name: string; url: string }[];
  onNotesChange: (notes: string) => void;
  onAttachmentAdd: (attachment: { name: string; url: string }) => void;
  onAttachmentRemove: (url: string) => void;
}

export const FeatureNotes = ({
  featureId,
  initialNotes = '',
  attachments = [],
  onNotesChange,
  onAttachmentAdd,
  onAttachmentRemove
}: FeatureNotesProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [notes, setNotes] = useState(initialNotes);
  const [isUploading, setIsUploading] = useState(false);

  const handleNotesChange = (value: string) => {
    setNotes(value);
    onNotesChange(value);
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setIsUploading(true);
    try {
      const fileExt = file.name.split('.').pop();
      const filePath = `${featureId}/${crypto.randomUUID()}.${fileExt}`;

      const { error: uploadError } = await supabase.storage
        .from('flare-attachments')
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from('flare-attachments')
        .getPublicUrl(filePath);

      onAttachmentAdd({ name: file.name, url: publicUrl });
      toast.success('File uploaded successfully');
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('Failed to upload file');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Collapsible open={isOpen} onOpenChange={setIsOpen} className="w-full">
      <CollapsibleTrigger asChild>
        <Button variant="ghost" size="sm" className="w-full flex justify-between">
          <span>Notes & Attachments</span>
          {isOpen ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
        </Button>
      </CollapsibleTrigger>
      <CollapsibleContent className="p-2 space-y-2">
        <Textarea
          value={notes}
          onChange={(e) => handleNotesChange(e.target.value)}
          placeholder="Add notes..."
          className="min-h-[100px]"
        />
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <Input
              type="file"
              onChange={handleFileUpload}
              disabled={isUploading}
              className="hidden"
              id={`file-upload-${featureId}`}
            />
            <label
              htmlFor={`file-upload-${featureId}`}
              className="flex items-center gap-2 px-3 py-1 bg-secondary text-secondary-foreground rounded-md cursor-pointer hover:bg-secondary/80 transition-colors"
            >
              <Paperclip className="h-4 w-4" />
              {isUploading ? 'Uploading...' : 'Attach File'}
            </label>
          </div>
          {attachments.length > 0 && (
            <div className="space-y-1">
              {attachments.map((attachment) => (
                <div
                  key={attachment.url}
                  className="flex items-center justify-between p-2 bg-secondary/50 rounded-md"
                >
                  <a
                    href={attachment.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm hover:underline"
                  >
                    {attachment.name}
                  </a>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => onAttachmentRemove(attachment.url)}
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </div>
              ))}
            </div>
          )}
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};