
import { Card } from "@/components/ui/card";
import { CheckCircle2, Circle, Calendar } from "lucide-react";
import { format } from "date-fns";
import { type Database } from "@/types/supabase";

type TaskBase = Database['public']['Tables']['tasks']['Row'];
type AdminProfile = Database['public']['Tables']['users_metadata']['Row'];

export type TaskResponse = Omit<TaskBase, 'user_id'> & {
  admin_profiles: Pick<AdminProfile, 'name'> | null;
};

interface TaskListProps {
  tasks: TaskResponse[];
  onToggleStatus: (taskId: string, currentStatus: string) => void;
}

export const TaskList = ({ tasks, onToggleStatus }: TaskListProps) => {
  if (!tasks.length) {
    return (
      <Card className="p-4">
        <p className="text-center text-muted-foreground">No tasks found</p>
      </Card>
    );
  }

  return (
    <div className="space-y-3">
      {tasks.map((task) => (
        <Card key={task.id} className="p-4">
          <div className="flex items-start gap-3">
            <button
              onClick={() => onToggleStatus(task.id, task.status)}
              className="mt-1"
            >
              {task.status === "completed" ? (
                <CheckCircle2 className="w-5 h-5 text-green-500" />
              ) : (
                <Circle className="w-5 h-5 text-muted-foreground" />
              )}
            </button>
            <div className="flex-1">
              <h4
                className={`font-medium ${
                  task.status === "completed" ? "line-through text-muted-foreground" : ""
                }`}
              >
                {task.title}
              </h4>
              {task.description && (
                <p className="text-sm text-muted-foreground mt-1">{task.description}</p>
              )}
              <div className="flex gap-2 text-sm text-muted-foreground mt-2">
                {task.admin_profiles?.name && (
                  <span>Assigned to: {task.admin_profiles.name}</span>
                )}
                {task.due_date && (
                  <>
                    <span>•</span>
                    <div className="flex items-center gap-1">
                      <Calendar className="w-4 h-4" />
                      <span>Due: {format(new Date(task.due_date), "MMM d, yyyy")}</span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};
