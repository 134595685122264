
import { Card } from "@/components/ui/card";
import { DealCard } from "./DealCard";
import { Database } from "@/integrations/supabase/types";

type DealStage = Database["public"]["Enums"]["deal_stage"];

interface StageColumnProps {
  stage: {
    id: DealStage;
    label: string;
  };
  deals: any[];
  onMove: (dealId: string, currentStage: DealStage, direction: 'left' | 'right') => void;
  isMoving: boolean;
  isFirstStage: boolean;
  isLastStage: boolean;
  isMobile: boolean;
}

export const StageColumn = ({
  stage,
  deals,
  onMove,
  isMoving,
  isFirstStage,
  isLastStage,
  isMobile,
}: StageColumnProps) => {
  const stageDeals = deals?.filter(d => d.stage === stage.id) || [];
  const totalValue = stageDeals.reduce((sum, deal) => sum + (deal.value || 0), 0);

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.currentTarget.classList.add('bg-accent/50');
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.currentTarget.classList.remove('bg-accent/50');
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.currentTarget.classList.remove('bg-accent/50');
    
    const dealId = e.dataTransfer.getData('dealId');
    const currentStage = e.dataTransfer.getData('currentStage') as DealStage;
    
    if (currentStage === stage.id) return;
    
    const direction = stages.findIndex(s => s.id === stage.id) > 
                     stages.findIndex(s => s.id === currentStage) ? 'right' : 'left';
    
    onMove(dealId, currentStage, direction);
  };

  return (
    <div className={isMobile ? "w-full" : "w-full min-w-[300px]"}>
      <div className="bg-accent/10 p-3 rounded-lg mb-3 backdrop-blur-sm">
        <div className="flex items-center justify-between mb-2">
          <h3 className="font-semibold truncate mr-2 text-foreground">{stage.label}</h3>
          <span className="text-xs text-muted-foreground px-2 py-1 bg-background/50 rounded-full">
            {stageDeals.length}
          </span>
        </div>
        {totalValue > 0 && (
          <div className="text-xs text-muted-foreground">
            Total: ${totalValue.toLocaleString()}
          </div>
        )}
      </div>

      <div 
        className="space-y-3 min-h-[100px] rounded-lg transition-colors duration-200 p-2"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {stageDeals.map((deal) => (
          <DealCard
            key={deal.id}
            deal={deal}
            onMove={onMove}
            isMoving={isMoving}
            isFirstStage={isFirstStage}
            isLastStage={isLastStage}
            isMobile={isMobile}
          />
        ))}
      </div>
    </div>
  );
};

// This needs to match the stages array in DealKanban.tsx
const stages: { id: DealStage; label: string }[] = [
  { id: 'qualification', label: 'Qualification' },
  { id: 'discovery', label: 'Discovery' },
  { id: 'proposal', label: 'Proposal' },
  { id: 'negotiation', label: 'Negotiation' },
  { id: 'closing', label: 'Closing' },
  { id: 'won', label: 'Won' },
  { id: 'lost', label: 'Lost' },
];
