import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "@/components/ui/sheet";
import { useMobileMenuAuth } from "@/hooks/useMobileMenuAuth";
import { UserProfileSection } from "./mobile/UserProfileSection";
import { NavigationLinks } from "./mobile/NavigationLinks";
import { cn } from "@/lib/utils";

export const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { session, profile, handleLogout } = useMobileMenuAuth();
  
  const isFlarePage = location.pathname.includes('/flare');
  const isHomePage = location.pathname === '/';
  
  const getSegmentClass = (path: string) => {
    const isActive = location.pathname === path;
    const baseClass = "py-3 px-4 rounded-lg flex items-center gap-2 transition-all duration-300";
    
    if (isFlarePage) {
      return cn(
        baseClass,
        isActive 
          ? 'text-[#FF4D00] bg-[#FF4D00]/10 scale-105' 
          : 'text-[#FF4D00]/70 hover:text-[#FF4D00] hover:bg-[#FF4D00]/5'
      );
    }

    return cn(
      baseClass,
      isActive 
        ? 'text-brand-orange bg-brand-charcoal scale-105 font-medium' 
        : 'text-brand-orange hover:text-white hover:bg-brand-charcoal'
    );
  };

  const handleContactClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsOpen(false);
    
    if (isHomePage) {
      setTimeout(() => {
        document.querySelector('#madlib-section')?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    } else {
      navigate('/');
      setTimeout(() => {
        document.querySelector('#madlib-section')?.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    }
  };

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild className="md:hidden">
        <Button 
          variant="ghost" 
          size="icon" 
          className={cn(
            "h-9 w-9",
            isFlarePage && "text-[#FF4D00]"
          )}
        >
          <Menu className="h-5 w-5" />
        </Button>
      </SheetTrigger>
      <SheetContent 
        side="right" 
        className={cn(
          "w-[280px] sm:w-[350px]",
          isFlarePage ? 'bg-[#0D1117] text-[#FF4D00]' : 'bg-background'
        )}
      >
        {session && (
          <UserProfileSection 
            profile={profile} 
            onLogout={handleLogout}
          />
        )}

        <NavigationLinks 
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          getSegmentClass={getSegmentClass}
          session={session}
        />
        
        <button onClick={handleContactClick} className={cn(
          "w-full px-4 py-2 rounded-md font-medium",
          isFlarePage ? "bg-[#FF4D00] hover:bg-[#FF4D00]/90 text-white font-mono flex items-center gap-2 justify-center" : 
          "bg-brand-orange hover:bg-brand-orange/90 text-white"
        )}>
          Contact Us
        </button>
      </SheetContent>
    </Sheet>
  );
};
