import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Guide } from "@/types/guides";
import { Header } from "@/components/Header";
import { Footer } from "@/components/Footer";
import { useToast } from "@/components/ui/use-toast";
import { GuideHeader } from "@/components/guides/GuideHeader";
import { GuideMetrics } from "@/components/guides/GuideMetrics";
import { GuideContent } from "@/components/guides/GuideContent";
import { Button } from "@/components/ui/button";

const GuidePage = () => {
  const { slug } = useParams();
  const { toast } = useToast();
  const navigate = useNavigate();

  const { data: guide, isLoading } = useQuery({
    queryKey: ['guide', slug],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('guides')
        .select('*')
        .eq('slug', slug)
        .single();
      
      if (error) throw error;
      return data as Guide;
    }
  });

  const handleDownload = async () => {
    if (!guide) return;

    const content = JSON.stringify(guide.content, null, 2);
    const blob = new Blob([content], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${guide.title.toLowerCase().replace(/\s+/g, '-')}.json`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);

    toast({
      title: "Guide downloaded!",
      description: "You can now access this guide offline.",
    });
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center dark:bg-gray-900">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-secondary"></div>
      </div>
    );
  }

  if (!guide) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center dark:bg-gray-900 dark:text-white">
        <h1 className="text-2xl font-bold mb-4">Guide not found</h1>
        <Button onClick={() => navigate(-1)}>Go Back</Button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 transition-colors duration-200">
      <Header />
      
      <main className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto">
          <GuideHeader 
            title={guide.title}
            type={guide.type}
            handleDownload={handleDownload}
          />
          
          <GuideMetrics type={guide.type} />
          
          <GuideContent guide={guide} />
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default GuidePage;