import { motion } from "framer-motion";
import { BlogPost as BlogPostType } from "@/types/blog";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { BlogGrid } from "./blog/BlogGrid";
import { BlogSearch } from "./blog/BlogSearch";
import { Button } from "./ui/button";
import { useState, useMemo } from "react";
import { useToast } from "@/hooks/use-toast";

const POSTS_PER_PAGE = 6;

export const Blog = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const { toast } = useToast();

  const { data: posts = [], isLoading, isError } = useQuery({
    queryKey: ['blog-posts'],
    queryFn: async () => {
      console.log('Fetching blog posts');
      
      const { data, error } = await supabase
        .from('blog_posts')
        .select('*')
        .order('published_date', { ascending: false });
      
      if (error) {
        console.error('Error fetching blog posts:', error);
        throw new Error('Failed to load blog posts');
      }

      if (!data) {
        console.log('No blog posts found');
        return [];
      }

      return data as BlogPostType[];
    },
    meta: {
      onError: (error: Error) => {
        toast({
          variant: "destructive",
          title: "Error",
          description: error.message || "Failed to load blog posts"
        });
      }
    }
  });

  const availableTags = useMemo(() => {
    const tags = new Set<string>();
    posts.forEach(post => {
      post.tags?.forEach(tag => tags.add(tag));
    });
    return Array.from(tags);
  }, [posts]);

  const filteredPosts = useMemo(() => {
    return posts.filter(post => {
      const matchesSearch = searchTerm === "" || 
        post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        post.excerpt.toLowerCase().includes(searchTerm.toLowerCase()) ||
        post.author.toLowerCase().includes(searchTerm.toLowerCase());

      const matchesTags = selectedTags.length === 0 || 
        selectedTags.every(tag => post.tags?.includes(tag));

      return matchesSearch && matchesTags;
    });
  }, [posts, searchTerm, selectedTags]);

  const totalPages = Math.ceil(filteredPosts.length / POSTS_PER_PAGE);
  const paginatedPosts = filteredPosts.slice(
    (currentPage - 1) * POSTS_PER_PAGE,
    currentPage * POSTS_PER_PAGE
  );

  const handleTagSelect = (tag: string) => {
    setSelectedTags(prev => [...prev, tag]);
    setCurrentPage(1);
  };

  const handleTagDeselect = (tag: string) => {
    setSelectedTags(prev => prev.filter(t => t !== tag));
    setCurrentPage(1);
  };

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
    setCurrentPage(1);
  };

  if (isLoading) {
    return (
      <section className="py-32 bg-background">
        <div className="container mx-auto px-4">
          <div className="text-center">
            <p className="text-foreground">Loading blog posts...</p>
          </div>
        </div>
      </section>
    );
  }

  if (isError) {
    return (
      <section className="py-32 bg-background">
        <div className="container mx-auto px-4">
          <div className="text-center">
            <p className="text-red-500 dark:text-red-400">Failed to load blog posts. Please try again later.</p>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="py-32 bg-background">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h2 className="text-5xl font-display font-bold mb-6 text-foreground">
            Latest Insights
          </h2>
          <p className="text-xl text-muted-foreground max-w-2xl mx-auto">
            Stay updated with our latest thoughts on technology, innovation, and digital transformation.
          </p>
        </motion.div>

        <BlogSearch
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          selectedTags={selectedTags}
          availableTags={availableTags}
          onTagSelect={handleTagSelect}
          onTagDeselect={handleTagDeselect}
        />

        {filteredPosts.length === 0 ? (
          <div className="text-center py-12">
            <p className="text-lg text-muted-foreground">
              No posts found matching your criteria. Try adjusting your search or filters.
            </p>
          </div>
        ) : (
          <>
            <BlogGrid posts={paginatedPosts} />

            {totalPages > 1 && (
              <div className="flex justify-center gap-2 mt-12">
                {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                  <Button
                    key={page}
                    variant={currentPage === page ? "default" : "outline"}
                    size="sm"
                    onClick={() => setCurrentPage(page)}
                    className="min-w-[40px] dark:hover:bg-accent"
                  >
                    {page}
                  </Button>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </section>
  );
};