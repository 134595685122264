
import { Header } from "@/components/Header";
import { Footer } from "@/components/Footer";
import { Hero } from "@/components/Hero";
import { EnterpriseJourney } from "@/components/segments/EnterpriseJourney";
import { FinalCTA } from "@/components/segments/FinalCTA";
import { EnterpriseSystemAnalyzer } from "@/components/enterprise/EnterpriseSystemAnalyzer";
import { useEffect, useRef } from "react";
import { Button } from "@/components/ui/button";
import { ArrowDownCircle, BarChart, LineChart } from "lucide-react";
import { Badge } from "@/components/ui/badge";

const EnterprisePage = () => {
  const analyzerRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    try {
      localStorage.setItem('preferred-segment', 'enterprise');
    } catch (error) {
      console.warn('Error setting preferred-segment in localStorage:', error);
    }
  }, []);

  const scrollToAnalyzer = () => {
    analyzerRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <main className="relative min-h-screen bg-[#0c1127] text-white">
      <Header />
      <div className="relative z-10">
        <Hero clientSegment="enterprise" />
        
        {/* New CTA Section */}
        <section className="py-16 bg-blue-900/20 backdrop-blur-sm border-y border-blue-900/30">
          <div className="container mx-auto px-4">
            <div className="max-w-5xl mx-auto text-center space-y-6">
              <Badge variant="success" className="mb-4">
                AI-Powered Analysis Tool
              </Badge>
              
              <h2 className="text-3xl md:text-4xl font-bold font-display bg-gradient-to-r from-blue-400 to-cyan-300 bg-clip-text text-transparent mb-4">
                Analyze Your Enterprise Systems & Calculate Time/Cost Savings
              </h2>
              
              <p className="text-xl text-gray-300 mb-8 max-w-3xl mx-auto">
                Our AI-powered Enterprise System Analyzer evaluates your current technology stack, identifies integration opportunities, and calculates potential efficiency gains and cost savings.
              </p>
              
              <div className="flex flex-wrap gap-4 justify-center items-center">
                <Button 
                  onClick={scrollToAnalyzer}
                  size="lg" 
                  className="bg-blue-600 hover:bg-blue-700 text-white gap-2 py-6 px-8 rounded-lg text-lg group"
                >
                  <LineChart className="w-5 h-5 group-hover:animate-pulse" />
                  Start Your Free AI Savings Analysis
                  <ArrowDownCircle className="w-5 h-5 ml-1 animate-bounce" />
                </Button>
                
                <Button
                  variant="outline"
                  size="lg"
                  className="border-blue-500 text-blue-400 hover:bg-blue-950/50 gap-2 py-6 px-8 rounded-lg text-lg"
                  onClick={scrollToAnalyzer}
                >
                  <BarChart className="w-5 h-5" />
                  See Sample Analysis
                </Button>
              </div>
              
              <div className="pt-8 flex justify-center space-x-8 text-sm text-gray-400">
                <div className="flex items-center">
                  <span className="inline-block w-3 h-3 rounded-full bg-green-500 mr-2"></span>
                  No Credit Card Required
                </div>
                <div className="flex items-center">
                  <span className="inline-block w-3 h-3 rounded-full bg-green-500 mr-2"></span>
                  AI-Powered Insights
                </div>
                <div className="flex items-center">
                  <span className="inline-block w-3 h-3 rounded-full bg-green-500 mr-2"></span>
                  Detailed Savings Report
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <EnterpriseJourney />
        
        {/* Enterprise System Analyzer Section */}
        <section className="py-24" ref={analyzerRef}>
          <div className="container mx-auto px-4">
            <div className="max-w-6xl mx-auto">
              <EnterpriseSystemAnalyzer />
            </div>
          </div>
        </section>
        
        {/* Enterprise Testimonial Section */}
        <section className="py-24">
          <div className="container mx-auto px-4">
            <div className="max-w-4xl mx-auto p-8 border-t border-blue-900/30">
              <div className="text-center mb-8">
                <h2 className="text-3xl font-display font-bold mb-4 text-white">What Our Enterprise Clients Say</h2>
              </div>
              <blockquote className="italic text-xl text-gray-300 text-center mb-6">
                "By centralizing our disparate systems into one unified platform, Unstuck Labs reduced our operational overhead by 40% and gave us unprecedented visibility across departments. The AI-powered automation has transformed how we process information."
              </blockquote>
              <div className="text-center">
                <p className="font-semibold text-white">Sarah Johnson</p>
                <p className="text-gray-400">CTO, Fortune 500 Financial Services Company</p>
              </div>
            </div>
          </div>
        </section>
        
        <FinalCTA segment="enterprise" />
      </div>
      <Footer />
    </main>
  );
};

export default EnterprisePage;
