import { DashboardMetrics } from './dashboard/DashboardMetrics';
import { DashboardSchedule } from './dashboard/DashboardSchedule';
import { DashboardProfile } from './dashboard/DashboardProfile';

export const EmployeeDashboard = () => {
  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold">Dashboard</h2>
      <div className="space-y-6">
        <DashboardMetrics />
        <DashboardProfile />
        <DashboardSchedule />
      </div>
    </div>
  );
};