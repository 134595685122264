import { Card } from "@/components/ui/card";
import { Workflow, HelpCircle } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider
} from "@/components/ui/tooltip";

interface ApproachProps {
  approach: string;
  methodology: string;
  timeline: string;
}

export const DevelopmentApproachSection = ({ approach, methodology, timeline }: ApproachProps) => {
  return (
    <Card className="p-6">
      <div className="flex items-center gap-3 mb-4">
        <Workflow className="w-6 h-6 text-[#FF4D00]" />
        <h3 className="text-xl font-semibold">Development Approach</h3>
      </div>

      <p className="text-muted-foreground mb-6">
        We've designed a development approach that puts you in control while ensuring the highest
        quality results. Our method focuses on regular communication, quick adjustments, and
        delivering value at every step.
      </p>

      <div className="space-y-6">
        <div className="p-6 bg-secondary/5 rounded-lg">
          <div className="flex items-center gap-2 mb-2">
            <h4 className="font-medium">Agile Development Process</h4>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <HelpCircle className="w-4 h-4 text-muted-foreground" />
                </TooltipTrigger>
                <TooltipContent>
                  <p className="max-w-xs">
                    Agile means we build your app piece by piece, getting your feedback along the way
                    to ensure everything meets your expectations.
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          <div className="space-y-3">
            <p className="text-sm text-muted-foreground">
              <strong>What it means for you:</strong> Instead of waiting months to see results,
              you'll see progress every few weeks and can make adjustments based on real feedback.
              Think of it like building with Lego blocks - we add and refine pieces iteratively
              until we have exactly what you want.
            </p>
            <p className="text-sm text-[#FF4D00]">
              <strong>Key benefit:</strong> You have control and visibility throughout the entire
              process, reducing risks and ensuring the final product matches your vision.
            </p>
          </div>
        </div>

        <div className="p-6 bg-secondary/5 rounded-lg">
          <h4 className="font-medium mb-2">Your Involvement & Communication</h4>
          <p className="text-sm text-muted-foreground mb-4">
            You'll have regular meetings with the development team (usually weekly) where you can:
          </p>
          <ul className="list-none space-y-2">
            <li className="flex items-start gap-2 text-sm text-muted-foreground">
              <span className="w-2 h-2 rounded-full bg-[#FF4D00] mt-2 flex-shrink-0" />
              See demonstrations of new features as they're built
            </li>
            <li className="flex items-start gap-2 text-sm text-muted-foreground">
              <span className="w-2 h-2 rounded-full bg-[#FF4D00] mt-2 flex-shrink-0" />
              Provide feedback and suggest adjustments early
            </li>
            <li className="flex items-start gap-2 text-sm text-muted-foreground">
              <span className="w-2 h-2 rounded-full bg-[#FF4D00] mt-2 flex-shrink-0" />
              Discuss upcoming features and prioritize what matters most
            </li>
            <li className="flex items-start gap-2 text-sm text-muted-foreground">
              <span className="w-2 h-2 rounded-full bg-[#FF4D00] mt-2 flex-shrink-0" />
              Address any questions or concerns immediately
            </li>
          </ul>
        </div>

        <div className="p-6 bg-secondary/5 rounded-lg">
          <h4 className="font-medium mb-2">Quality Assurance & Testing</h4>
          <p className="text-sm text-muted-foreground mb-4">
            Every feature we build goes through comprehensive testing to ensure reliability:
          </p>
          <div className="grid md:grid-cols-2 gap-4">
            <div className="space-y-2">
              <p className="text-sm font-medium">What We Test:</p>
              <ul className="list-none space-y-2">
                <li className="flex items-start gap-2 text-sm text-muted-foreground">
                  <span className="w-2 h-2 rounded-full bg-[#FF4D00] mt-2 flex-shrink-0" />
                  Functionality (does it work as expected?)
                </li>
                <li className="flex items-start gap-2 text-sm text-muted-foreground">
                  <span className="w-2 h-2 rounded-full bg-[#FF4D00] mt-2 flex-shrink-0" />
                  User experience (is it easy to use?)
                </li>
              </ul>
            </div>
            <div className="space-y-2">
              <p className="text-sm font-medium">Why It Matters:</p>
              <ul className="list-none space-y-2">
                <li className="flex items-start gap-2 text-sm text-muted-foreground">
                  <span className="w-2 h-2 rounded-full bg-[#FF4D00] mt-2 flex-shrink-0" />
                  Prevents costly fixes later
                </li>
                <li className="flex items-start gap-2 text-sm text-muted-foreground">
                  <span className="w-2 h-2 rounded-full bg-[#FF4D00] mt-2 flex-shrink-0" />
                  Ensures user satisfaction
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="mt-8 p-6 bg-[#FF4D00]/5 rounded-lg">
          <h4 className="font-medium mb-2">Need More Details?</h4>
          <p className="text-sm text-muted-foreground">
            We're happy to dive deeper into any aspect of our development approach. Understanding
            how we work helps ensure a smooth, successful project journey together.
          </p>
        </div>
      </div>
    </Card>
  );
};
