import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Book, Settings, Users, Bot, BarChart3, Megaphone } from "lucide-react";
import { AdminWikiSection } from "./AdminWikiSection";
import { analyticsGuide } from "./guides/analyticsGuide";
import { crmGuide } from "./guides/crmGuide";
import { marketingGuide } from "./guides/marketingGuide";
import { automationGuide } from "./guides/automationGuide";
import { settingsGuide } from "./guides/settingsGuide";

export const AdminWiki = () => {
  const [activeTab, setActiveTab] = useState("analytics");

  return (
    <Card className="p-6">
      <div className="flex items-center gap-2 mb-6">
        <Book className="w-6 h-6 text-primary" />
        <h2 className="text-2xl font-bold">Admin Documentation</h2>
      </div>

      <Tabs value={activeTab} onValueChange={setActiveTab} className="space-y-6">
        <TabsList className="grid grid-cols-5 gap-4">
          <TabsTrigger value="analytics" className="flex items-center gap-2">
            <BarChart3 className="w-4 h-4" />
            Analytics
          </TabsTrigger>
          <TabsTrigger value="sales" className="flex items-center gap-2">
            <Users className="w-4 h-4" />
            Sales & CRM
          </TabsTrigger>
          <TabsTrigger value="marketing" className="flex items-center gap-2">
            <Megaphone className="w-4 h-4" />
            Marketing
          </TabsTrigger>
          <TabsTrigger value="automation" className="flex items-center gap-2">
            <Bot className="w-4 h-4" />
            Automation
          </TabsTrigger>
          <TabsTrigger value="settings" className="flex items-center gap-2">
            <Settings className="w-4 h-4" />
            Settings
          </TabsTrigger>
        </TabsList>

        <ScrollArea className="h-[600px] pr-4">
          <TabsContent value="analytics">
            <AdminWikiSection guide={analyticsGuide} />
          </TabsContent>

          <TabsContent value="sales">
            <AdminWikiSection guide={crmGuide} />
          </TabsContent>

          <TabsContent value="marketing">
            <AdminWikiSection guide={marketingGuide} />
          </TabsContent>

          <TabsContent value="automation">
            <AdminWikiSection guide={automationGuide} />
          </TabsContent>

          <TabsContent value="settings">
            <AdminWikiSection guide={settingsGuide} />
          </TabsContent>
        </ScrollArea>
      </Tabs>
    </Card>
  );
};