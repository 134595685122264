
import { useState } from 'react';
import { Space, Folder, List } from '../hooks/useWorkspaceData';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { 
  DropdownMenu, 
  DropdownMenuContent, 
  DropdownMenuItem, 
  DropdownMenuSeparator,
  DropdownMenuTrigger 
} from '@/components/ui/dropdown-menu';
import { 
  ChevronDown, 
  ChevronRight, 
  MoreVertical, 
  Plus, 
  Folder as FolderIcon, 
  List as ListIcon, 
  Trash2,
  Pencil,
  Copy,
  FolderInput
} from 'lucide-react';

interface SpaceItemProps {
  space: Space;
  folders: Folder[];
  lists: List[];
  isExpanded: boolean;
  isSelected: boolean;
  selectedFolder: string | null;
  onSpaceClick: (spaceId: string) => void;
  onFolderClick: (folderId: string) => void;
  onToggle: () => void;
  onNewFolder: (spaceId: string) => void;  // Modified to include spaceId
  onNewList: (folderId: string) => void;   // Modified to include folderId
  navigateToList: (listId: string) => void;
  deleteSpace: (id: string) => void;
  deleteFolder: (id: string) => void;
  deleteList: (id: string) => void;
}

export function SpaceItem({
  space,
  folders,
  lists,
  isExpanded,
  isSelected,
  selectedFolder,
  onSpaceClick,
  onFolderClick,
  onToggle,
  onNewFolder,
  onNewList,
  navigateToList,
  deleteSpace,
  deleteFolder,
  deleteList,
}: SpaceItemProps) {
  const [expandedFolders, setExpandedFolders] = useState<Record<string, boolean>>({});

  const toggleFolder = (folderId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    setExpandedFolders(prev => ({
      ...prev,
      [folderId]: !prev[folderId]
    }));
  };

  const spaceFolders = folders.filter(folder => folder.space_id === space.id);

  // Placeholder functions for now - these will need to be implemented and passed down as props
  const handleRename = (type: 'space' | 'folder' | 'list', id: string) => {
    console.log(`Rename ${type} ${id}`);
  };

  const handleDuplicate = (type: 'space' | 'folder' | 'list', id: string) => {
    console.log(`Duplicate ${type} ${id}`);
  };

  const handleMove = (type: 'space' | 'folder' | 'list', id: string) => {
    console.log(`Move ${type} ${id}`);
  };

  return (
    <div className="space-y-1">
      <div className="group flex items-center">
        <button
          onClick={() => onSpaceClick(space.id)}
          className={cn(
            "flex-1 flex items-center gap-2 px-2 py-1.5 text-sm rounded-md transition-colors",
            "hover:bg-accent/50",
            isSelected && "bg-accent text-accent-foreground"
          )}
        >
          <button
            onClick={(e) => {
              e.stopPropagation();
              onToggle();
            }}
            className="p-0.5 hover:bg-accent/50 rounded"
          >
            {isExpanded ? (
              <ChevronDown className="h-4 w-4" />
            ) : (
              <ChevronRight className="h-4 w-4" />
            )}
          </button>
          <img
            src={space.avatar_url || '/placeholder.svg'}
            alt={space.name}
            className="h-4 w-4 rounded"
          />
          <span className="flex-1 text-left">{space.name}</span>
        </button>
        <div className="flex items-center gap-0.5 opacity-0 group-hover:opacity-100 transition-opacity">
          <Button 
            variant="ghost" 
            size="icon" 
            className="h-7 w-7" 
            onClick={(e) => {
              e.stopPropagation();
              onNewFolder(space.id);
            }}
          >
            <Plus className="h-4 w-4" />
          </Button>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon" className="h-7 w-7">
                <MoreVertical className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-[180px] bg-background border border-border">
              <DropdownMenuItem onClick={() => handleRename('space', space.id)}>
                <Pencil className="mr-2 h-4 w-4" />
                Rename
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => handleDuplicate('space', space.id)}>
                <Copy className="mr-2 h-4 w-4" />
                Duplicate
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => handleMove('space', space.id)}>
                <FolderInput className="mr-2 h-4 w-4" />
                Move
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onClick={() => deleteSpace(space.id)}
                className="text-destructive focus:text-destructive"
              >
                <Trash2 className="mr-2 h-4 w-4" />
                Delete Space
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      {isExpanded && (
        <div className="ml-9 space-y-1">
          {spaceFolders.map(folder => (
            <div key={folder.id} className="space-y-1">
              <div className="group flex items-center">
                <button
                  onClick={() => onFolderClick(folder.id)}
                  className={cn(
                    "flex-1 flex items-center gap-2 px-2 py-1.5 text-sm rounded-md transition-colors",
                    "hover:bg-accent/50",
                    selectedFolder === folder.id && "bg-accent text-accent-foreground"
                  )}
                >
                  <button
                    onClick={(e) => toggleFolder(folder.id, e)}
                    className="p-0.5 hover:bg-accent/50 rounded"
                  >
                    {expandedFolders[folder.id] ? (
                      <ChevronDown className="h-4 w-4" />
                    ) : (
                      <ChevronRight className="h-4 w-4" />
                    )}
                  </button>
                  <FolderIcon className="h-4 w-4" />
                  <span className="flex-1 text-left">{folder.name}</span>
                </button>
                <div className="flex items-center gap-0.5 opacity-0 group-hover:opacity-100 transition-opacity">
                  <Button 
                    variant="ghost" 
                    size="icon" 
                    className="h-7 w-7" 
                    onClick={(e) => {
                      e.stopPropagation();
                      onNewList(folder.id);
                    }}
                  >
                    <Plus className="h-4 w-4" />
                  </Button>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" size="icon" className="h-7 w-7">
                        <MoreVertical className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end" className="w-[180px] bg-background border border-border">
                      <DropdownMenuItem onClick={() => handleRename('folder', folder.id)}>
                        <Pencil className="mr-2 h-4 w-4" />
                        Rename
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={() => handleDuplicate('folder', folder.id)}>
                        <Copy className="mr-2 h-4 w-4" />
                        Duplicate
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={() => handleMove('folder', folder.id)}>
                        <FolderInput className="mr-2 h-4 w-4" />
                        Move to Space
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem
                        onClick={() => deleteFolder(folder.id)}
                        className="text-destructive focus:text-destructive"
                      >
                        <Trash2 className="mr-2 h-4 w-4" />
                        Delete Folder
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>

              {expandedFolders[folder.id] && (
                <div className="ml-9 space-y-1">
                  {lists
                    .filter(list => list.folder_id === folder.id)
                    .map(list => (
                      <div key={list.id} className="group flex items-center">
                        <button
                          onClick={() => navigateToList(list.id)}
                          className="flex-1 flex items-center gap-2 px-2 py-1.5 text-sm rounded-md transition-colors hover:bg-accent/50"
                        >
                          <ListIcon className="h-4 w-4" />
                          <span className="flex-1 text-left">{list.name}</span>
                        </button>
                        <div className="flex items-center gap-0.5 opacity-0 group-hover:opacity-100 transition-opacity">
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button variant="ghost" size="icon" className="h-7 w-7">
                                <MoreVertical className="h-4 w-4" />
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end" className="w-[180px] bg-background border border-border">
                              <DropdownMenuItem onClick={() => handleRename('list', list.id)}>
                                <Pencil className="mr-2 h-4 w-4" />
                                Rename
                              </DropdownMenuItem>
                              <DropdownMenuItem onClick={() => handleDuplicate('list', list.id)}>
                                <Copy className="mr-2 h-4 w-4" />
                                Duplicate
                              </DropdownMenuItem>
                              <DropdownMenuItem onClick={() => handleMove('list', list.id)}>
                                <FolderInput className="mr-2 h-4 w-4" />
                                Move to Folder
                              </DropdownMenuItem>
                              <DropdownMenuSeparator />
                              <DropdownMenuItem
                                onClick={() => deleteList(list.id)}
                                className="text-destructive focus:text-destructive"
                              >
                                <Trash2 className="mr-2 h-4 w-4" />
                                Delete List
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
