
import { CRMLayout } from "./layout/CRMLayout";
import { Card } from "@/components/ui/card";
import { Tabs, TabsContent } from "@/components/ui/tabs";
import { SubTabs } from "../navigation/SubTabs";
import { LeadManagement } from "./leads/LeadManagement";
import { ContactList } from "./contacts/ContactList";
import { CompanyList } from "./companies/CompanyList";
import { DealKanban } from "./deals/DealKanban";
import { CRMOverview } from "./overview/CRMOverview";

export const CRMDashboard = () => {
  return (
    <CRMLayout>
      <Card className="p-6 sm:p-8 bg-card dark:bg-card text-card-foreground rounded-xl border border-border shadow-sm backdrop-blur-sm transition-all duration-200">
        <Tabs defaultValue="overview" className="space-y-4 sm:space-y-6">
          <div className="overflow-x-auto -mx-2 px-2 sm:mx-0 sm:px-0">
            <SubTabs 
              tabs={[
                { value: "overview", label: "Overview" },
                { value: "leads", label: "Leads" },
                { value: "contacts", label: "Contacts" },
                { value: "companies", label: "Companies" },
                { value: "deals", label: "Deals" }
              ]}
              activeColor="sales"
            />
          </div>

          <TabsContent value="overview" className="mt-4 sm:mt-8">
            <CRMOverview />
          </TabsContent>

          <TabsContent value="leads" className="mt-4 sm:mt-8">
            <LeadManagement />
          </TabsContent>

          <TabsContent value="contacts" className="mt-4 sm:mt-8">
            <ContactList />
          </TabsContent>

          <TabsContent value="companies" className="mt-4 sm:mt-8">
            <CompanyList />
          </TabsContent>

          <TabsContent value="deals" className="mt-4 sm:mt-8">
            <DealKanban />
          </TabsContent>
        </Tabs>
      </Card>
    </CRMLayout>
  );
};
