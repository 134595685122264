export const marketingGuide = {
  title: "Marketing Tools",
  description: "Master content management, SEO monitoring, and lead generation tools.",
  sections: [
    {
      title: "Content Management",
      content: `Managing your content effectively:

Key Features:
- Blog post creation and scheduling
- Content block management
- Media library organization
- SEO optimization tools

Interactive Demo:
1. Navigate to Content Management
2. Click "New Blog Post"
3. Fill in title, content, and meta data
4. Preview and schedule publication

Best Practices:
- Plan content calendar in advance
- Use SEO best practices for all content
- Maintain consistent brand voice
- Regular content audits and updates`
    },
    {
      title: "SEO Monitoring",
      content: `Track and optimize your search engine performance:

Key Features:
- Keyword tracking and analysis
- Competitor rank monitoring
- On-page SEO recommendations
- Technical SEO audits

Interactive Demo:
1. Access SEO Dashboard
2. Add keywords to track
3. Review current rankings
4. Generate optimization report

Best Practices:
- Monitor rankings weekly
- Update meta descriptions regularly
- Fix technical SEO issues promptly
- Track competitor movements`
    },
    {
      title: "Lead Generation",
      content: `Maximize lead capture and conversion:

Key Features:
- Form builder and management
- Landing page optimization
- A/B testing capabilities
- Lead scoring setup

Interactive Demo:
1. Create new lead capture form
2. Set up form validation rules
3. Design thank you page
4. Configure lead notifications

Best Practices:
- Test form layouts regularly
- Optimize form fields
- Set up proper tracking
- Follow up promptly`
    }
  ]
};