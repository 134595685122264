
import { motion, useScroll, useTransform } from "framer-motion";
import { Loading } from "./ui/loading";
import { useQuery } from "@tanstack/react-query";
import { useState, useRef } from "react";
import { Filters } from "./CaseStudies/Filters";
import { CaseStudiesGrid } from "./CaseStudies/CaseStudiesGrid";
import type { CaseStudy } from "@/types/caseStudy";
import { Award, Rocket, Target, TrendingUp } from "lucide-react";
import DisplayCards from "./ui/display-cards";

export const CaseStudies = () => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedIndustry, setSelectedIndustry] = useState<string>('all');
  const sectionRef = useRef<HTMLElement>(null);
  
  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ["start end", "end start"]
  });
  
  const opacity = useTransform(scrollYProgress, [0, 0.2, 0.8, 1], [0.6, 1, 1, 0.6]);
  const scale = useTransform(scrollYProgress, [0, 0.2, 0.8, 1], [0.98, 1, 1, 0.98]);

  const { data: caseStudies, isLoading, error } = useQuery({
    queryKey: ['case-studies'],
    queryFn: async () => {
      return [
        {
          title: "Reyets – Smart Advocacy Tool",
          description: "An award-winning platform that empowers changemakers by providing real-time resources to fight injustices.",
          impact: "Increased social awareness, secure reporting, and geolocation-based features",
          awards: "World Changing Idea Award and Global Design for Good Award",
          backing: "Venture backed by Camel Back Ventures",
          image: "https://images.unsplash.com/photo-1649972904349-6e44c42644a7?auto=format&fit=crop&q=80&w=1200",
          tags: ["Social Impact", "Mobile App", "AI Integration"],
          metrics: [
            { label: "User Growth", value: "300%" },
            { label: "Reports Filed", value: "10k+" },
            { label: "Success Rate", value: "92%" }
          ]
        },
        {
          title: "Skip Q – Food Delivery Platform",
          description: "Custom-built for Middle Eastern markets, Skip Q streamlines food ordering with real-time updates and POS integrations.",
          impact: "Increased restaurant efficiency and high user adoption rates",
          image: "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?auto=format&fit=crop&q=80&w=1200",
          tags: ["Food Tech", "Mobile App", "POS Integration"],
          metrics: [
            { label: "Order Volume", value: "50k+" },
            { label: "Restaurant Partners", value: "500+" },
            { label: "User Rating", value: "4.8/5" }
          ]
        },
        {
          title: "Rooam – Real-Time Payments",
          description: "A mobile payment solution transforming the bar and restaurant experience.",
          impact: "Streamlined user payments, enhanced operational efficiency",
          backing: "Acquired by American Express",
          image: "https://images.unsplash.com/photo-1498050108023-c5249f4df085?auto=format&fit=crop&q=80&w=1200",
          tags: ["FinTech", "Mobile Payments", "Hospitality"]
        },
        {
          title: "PRYZE – Digital Engagement Platform",
          description: "An innovative platform revolutionizing digital engagement and user interaction.",
          impact: "Enhanced user engagement and platform growth",
          backing: "Venture backed by Hearst Media / Level Up Ventures",
          image: "https://images.unsplash.com/photo-1519389950473-47ba0277781c?auto=format&fit=crop&q=80&w=1200",
          tags: ["Digital Platform", "User Engagement", "Analytics"]
        },
        {
          title: "Memory Anchor – AR-Powered Tributes",
          description: "An augmented reality app honoring fallen soldiers at national cemeteries, enhancing visitor engagement through immersive storytelling.",
          impact: "Increased interaction and deeper emotional connections",
          image: "https://images.unsplash.com/photo-1470071459604-3b5ec3a7fe05?auto=format&fit=crop&q=80&w=1200",
          tags: ["AR/VR", "Memorial", "Mobile App"]
        },
        {
          title: "QR Code Solution – Federal Government",
          description: "A custom-built QR code system automating global asset tracking and documentation for federal logistics.",
          impact: "Improved operational efficiency, security, and real-time visibility",
          image: "https://images.unsplash.com/photo-1605810230434-7631ac76ec81?auto=format&fit=crop&q=80&w=1200",
          tags: ["Government", "Asset Tracking", "Security"]
        },
        {
          title: "FiftyOne AI – AI-Powered Advocacy",
          description: "A scalable AI-driven solution for advocacy organizations to reduce administrative burdens and expand their influence.",
          impact: "Enhanced automation, intake efficiency, and actionable insights",
          backing: "Venture backed by Dream.org",
          image: "https://images.unsplash.com/photo-1581091226825-a6a2a5aee158?auto=format&fit=crop&q=80&w=1200",
          tags: ["AI/ML", "Advocacy", "Automation"]
        },
      ] as CaseStudy[];
    }
  });

  const allTags = Array.from(
    new Set(caseStudies?.flatMap(study => study.tags) || [])
  ).sort();

  const industries = ['all', 'Social Impact', 'Food Tech', 'FinTech', 'Digital Platform', 'AR/VR', 'Government', 'AI/ML'];

  const filteredCaseStudies = caseStudies?.filter(study => {
    const matchesTags = selectedTags.length === 0 || 
      selectedTags.every(tag => study.tags.includes(tag));
    const matchesIndustry = selectedIndustry === 'all' || 
      study.tags.includes(selectedIndustry);
    return matchesTags && matchesIndustry;
  });

  const handleTagClick = (tag: string) => {
    setSelectedTags(prev => 
      prev.includes(tag) 
        ? prev.filter(t => t !== tag)
        : [...prev, tag]
    );
  };

  const clearFilters = () => {
    setSelectedTags([]);
    setSelectedIndustry('all');
  };

  if (isLoading) {
    return (
      <section className="py-24 bg-[#1f1f1f] text-white">
        <div className="container">
          <Loading size="lg" text="Loading case studies..." />
        </div>
      </section>
    );
  }

  if (error) {
    return (
      <section className="py-24 bg-[#1f1f1f] text-white">
        <div className="container text-center">
          <h2 className="text-2xl font-bold mb-4">Unable to load case studies</h2>
          <p className="text-muted-foreground">Please try again later</p>
        </div>
      </section>
    );
  }

  return (
    <section ref={sectionRef} className="py-24 relative overflow-hidden bg-[#1f1f1f]">
      {/* Interactive cards showcase */}
      <motion.div 
        style={{ opacity, scale }}
        className="container relative z-10 mb-16"
      >
        <div className="hidden md:block overflow-hidden py-12">
          <DisplayCards 
            cards={[
              {
                icon: <Award className="size-5 text-secondary" />,
                title: "Award-Winning",
                description: "Our work has been recognized globally for innovation and design excellence",
                date: "2023",
                className: "[grid-area:stack] hover:-translate-y-10 before:absolute before:w-[100%] before:outline-1 before:rounded-xl before:outline-border before:h-[100%] before:content-[''] before:bg-blend-overlay before:bg-background/50 hover:before:opacity-0 before:transition-opacity before:duration:700 hover:grayscale-0 before:left-0 before:top-0",
              },
              {
                icon: <Rocket className="size-5 text-secondary" />,
                title: "Rapid Launch",
                description: "We deliver projects in 1/10 of the time with our Flare development process",
                date: "Since 2018",
                className: "[grid-area:stack] translate-x-16 translate-y-10 hover:-translate-y-1 before:absolute before:w-[100%] before:outline-1 before:rounded-xl before:outline-border before:h-[100%] before:content-[''] before:bg-blend-overlay before:bg-background/50 hover:before:opacity-0 before:transition-opacity before:duration:700 hover:grayscale-0 before:left-0 before:top-0",
              },
              {
                icon: <TrendingUp className="size-5 text-secondary" />,
                title: "Success Stories",
                description: "Our clients have raised over $30M and achieved multiple acquisitions",
                date: "Venture Backed",
                className: "[grid-area:stack] translate-x-32 translate-y-20 hover:translate-y-10",
              },
            ]}
          />
        </div>
      </motion.div>

      {/* Filter and case studies grid */}
      <motion.div 
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        className="container relative z-10"
      >
        <div className="text-center mb-16">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="text-4xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-secondary to-brand-pink"
          >
            Our Success Stories
          </motion.h2>
          <motion.p 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.5 }}
            className="text-white/70 max-w-2xl mx-auto text-lg mb-8"
          >
            From startups to enterprises, we've helped organizations across industries build exceptional software solutions.
          </motion.p>

          <Filters
            selectedTags={selectedTags}
            selectedIndustry={selectedIndustry}
            industries={industries}
            allTags={allTags}
            onTagClick={handleTagClick}
            onIndustrySelect={setSelectedIndustry}
          />
        </div>

        <CaseStudiesGrid 
          studies={filteredCaseStudies || []}
          onClearFilters={clearFilters}
        />
      </motion.div>
    </section>
  );
};
