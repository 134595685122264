
import { motion } from "framer-motion";
import { ArrowDown } from "lucide-react";

export const FlareHowItWorks = () => {
  return (
    <section className="py-16 px-4">
      <div className="max-w-4xl mx-auto text-center">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-4xl font-bold mb-8"
        >
          How It Works
        </motion.h2>
        
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.2 }}
          className="text-xl text-zinc-300 mb-12"
        >
          FLARE combines AI engineers, proprietary tooling, and human developers to build web and mobile 
          platforms at incredible speed with significant cost savings.
        </motion.p>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.4 }}
          className="inline-flex items-center text-brand-orange hover:text-brand-orange/80 transition-colors cursor-pointer"
        >
          Learn More
          <ArrowDown className="ml-2 w-5 h-5" />
        </motion.div>
      </div>
    </section>
  );
};
