
import { useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { EmployeeSidebar } from './EmployeeSidebar';
import { supabase } from '@/integrations/supabase/client';
import { Loader2 } from 'lucide-react';
import { toast } from 'sonner';
import { useQuery } from '@tanstack/react-query';

interface EmployeeLayoutProps {
  children?: React.ReactNode;
}

export const EmployeeLayout = ({ children }: EmployeeLayoutProps) => {
  const navigate = useNavigate();
  
  useEffect(() => {
    console.log('[EmployeeLayout] Component mounted');
  }, []);

  const { data: session, isLoading: isSessionLoading } = useQuery({
    queryKey: ['auth-session'],
    queryFn: async () => {
      console.log('[EmployeeLayout] Fetching auth session');
      const { data: { session }, error } = await supabase.auth.getSession();
      if (error) {
        console.error('[EmployeeLayout] Session error:', error);
        throw error;
      }
      return session;
    }
  });

  const { data: profile, isLoading: isProfileLoading } = useQuery({
    queryKey: ['employee-profile', session?.user?.id],
    enabled: !!session?.user?.id,
    queryFn: async () => {
      console.log('[EmployeeLayout] Fetching employee profile');
      const { data, error } = await supabase
        .from('employee_profiles')
        .select('*')
        .eq('user_id', session.user.id)
        .single();

      if (error) {
        console.error('[EmployeeLayout] Profile error:', error);
        throw error;
      }
      
      if (!data) {
        console.error('[EmployeeLayout] No profile found');
        throw new Error('No employee profile found');
      }
      
      return data;
    },
    retry: false // Don't retry if the profile doesn't exist
  });

  if (isSessionLoading || isProfileLoading) {
    console.log('[EmployeeLayout] Loading...');
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  // If there's no session, redirect to auth
  if (!session) {
    console.log('[EmployeeLayout] No session found, redirecting to auth');
    toast.error('Please log in to access the employee portal');
    return <Navigate to="/auth" replace />;
  }

  // If there's a session but no profile or error loading profile, show error
  if (!profile) {
    console.error('[EmployeeLayout] Access denied: No employee profile');
    toast.error('Access denied: No employee profile found');
    return <Navigate to="/auth" replace />;
  }

  return (
    <div className="flex h-screen overflow-hidden">
      <EmployeeSidebar />
      <main className="flex-1 overflow-y-auto bg-background">
        <div className="container mx-auto py-6">
          {children || <Outlet />}
        </div>
      </main>
    </div>
  );
};
