
import { Moon } from "lucide-react";
import { Button } from "@/components/ui/button";

export function ThemeSwitcher() {
  // This is now just a placeholder that shows the dark mode icon
  return (
    <Button
      variant="ghost"
      size="icon"
      className="h-9 w-9 rounded-md"
      disabled
    >
      <Moon className="h-4 w-4" />
      <span className="sr-only">Dark mode</span>
    </Button>
  );
}
