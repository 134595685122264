import { useState } from "react";
import { Card } from "@/components/ui/card";
import { ViewControls } from "../common/ViewControls";
import { ContactCard } from "./ContactCard";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { CreateContactButton } from "./CreateContactButton";
import { toast } from "sonner";

export const ContactList = () => {
  const [view, setView] = useState<"grid" | "list">("grid");
  const [sortField, setSortField] = useState("created_at");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const [searchTerm, setSearchTerm] = useState("");

  const { data: contacts = [], isLoading } = useQuery({
    queryKey: ["contacts"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("contacts")
        .select(`
          *,
          company:companies(*)
        `)
        .order(sortField, { ascending: sortDirection === "asc" });

      if (error) {
        toast.error("Failed to fetch contacts");
        throw error;
      }
      return data;
    },
  });

  const filteredContacts = contacts.filter((contact) =>
    Object.values(contact).some((value) =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <Card className="p-4 sm:p-6">
      <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-6">
        <div>
          <h2 className="text-xl sm:text-2xl font-bold mb-2">Contacts</h2>
          <p className="text-sm sm:text-base text-muted-foreground">
            Manage your contacts and their information
          </p>
        </div>
        <CreateContactButton />
      </div>

      <ViewControls
        view={view}
        setView={setView}
        sortField={sortField}
        setSortField={setSortField}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        className="mb-6"
      />

      {isLoading ? (
        <div className="text-center py-8">Loading contacts...</div>
      ) : filteredContacts.length === 0 ? (
        <div className="text-center py-8 text-muted-foreground">
          No contacts found
        </div>
      ) : (
        <div className={`grid gap-4 ${
          view === "grid" 
            ? "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3" 
            : "grid-cols-1"
        }`}>
          {filteredContacts.map((contact) => (
            <ContactCard key={contact.id} contact={contact} />
          ))}
        </div>
      )}
    </Card>
  );
};