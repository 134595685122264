import { motion } from "framer-motion";
import { 
  ArrowUpRight, ArrowDownRight, ArrowDownLeft, 
  ArrowUpLeft, ArrowBigRightDash, ArrowBigLeftDash 
} from "lucide-react";

export const InvestmentCriteria = () => {
  const criteria = [
    {
      icon: ArrowUpRight,
      title: "Market Potential",
      description: "$1+ billion valuation potential with significant TAM"
    },
    {
      icon: ArrowDownRight,
      title: "Defensible Product",
      description: "Unique IP, network effects, and strong market positioning"
    },
    {
      icon: ArrowDownLeft,
      title: "Traction",
      description: "Demonstrated market validation and growth metrics"
    },
    {
      icon: ArrowUpLeft,
      title: "Strong Team",
      description: "Resilient founders with excellent execution capabilities"
    },
    {
      icon: ArrowBigRightDash,
      title: "Innovation Focus",
      description: "Technology-driven solutions in emerging sectors"
    },
    {
      icon: ArrowBigLeftDash,
      title: "Growth Path",
      description: "Clear route to profitability and exit opportunities"
    }
  ];

  return (
    <section className="py-24 bg-[#1A1F2C] relative overflow-hidden">
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#FF4D0005_1px,transparent_1px),linear-gradient(to_bottom,#FF4D0005_1px,transparent_1px)] bg-[size:4rem_4rem] pointer-events-none" />
      
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl md:text-5xl font-display font-bold mb-6 text-white/90">
            What We Look For
          </h2>
          <p className="text-xl text-white/60 max-w-2xl mx-auto">
            We invest in companies that demonstrate exceptional potential across key dimensions
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {criteria.map((item, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="p-6 rounded-xl bg-[#222222]/50 backdrop-blur-sm border border-white/5 hover:border-[#FF4D00]/20 transition-all duration-300"
            >
              <item.icon className="w-8 h-8 text-[#FF4D00] mb-4" />
              <h3 className="text-xl font-bold text-white/90 mb-2">{item.title}</h3>
              <p className="text-white/60">{item.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};