import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Slider } from "@/components/ui/slider";
import { motion, AnimatePresence } from "framer-motion";
import { DollarSign, SlidersHorizontal } from "lucide-react";
import { TooltipProvider } from "@/components/ui/tooltip";
import { BudgetTier } from "../../types";
import { useProjectStore } from "../../stores/projectStore";
import { BudgetTierCard } from "./BudgetTierCard";
import { getDefaultTiers } from "./budgetTierUtils";

interface BudgetTierSliderProps {
  complexity: 'low' | 'medium' | 'high';
}

export const BudgetTierSlider = ({ complexity }: BudgetTierSliderProps) => {
  const [sliderValue, setSliderValue] = useState([1]);
  const analysis = useProjectStore((state) => state.analysis);
  
  const tiers = getDefaultTiers(complexity);
  const currentTier = tiers[Math.min(sliderValue[0] - 1, tiers.length - 1)];

  const handleValueChange = (newValue: number[]) => {
    setSliderValue(newValue);
  };

  if (!currentTier) {
    return null;
  }

  return (
    <TooltipProvider>
      <Card className="p-6 bg-background dark:bg-gray-900 shadow-lg rounded-lg border border-gray-200 dark:border-gray-800">
        <div className="space-y-6">
          <div className="flex items-center justify-between">
            <h3 className="text-xl font-semibold flex items-center gap-2 text-foreground dark:text-gray-100">
              <DollarSign className="w-5 h-5 text-[#FF4D00]" />
              Investment Tier
            </h3>
            <div className="flex items-center gap-2 text-sm text-muted-foreground dark:text-gray-400">
              <SlidersHorizontal className="w-4 h-4" />
              <span>Slide to explore options</span>
            </div>
          </div>
          
          <div className="py-4">
            <Slider
              value={sliderValue}
              onValueChange={handleValueChange}
              max={tiers.length}
              min={1}
              step={1}
              className="w-full"
            />

            <div className="flex justify-between text-sm text-muted-foreground dark:text-gray-400 mt-2">
              {tiers.map((tier, index) => (
                <span key={index} className="px-2">{tier.range}</span>
              ))}
            </div>
          </div>

          <AnimatePresence mode="wait">
            <motion.div
              key={sliderValue[0]}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="mt-6"
            >
              <BudgetTierCard tier={currentTier} />
            </motion.div>
          </AnimatePresence>
        </div>
      </Card>
    </TooltipProvider>
  );
};