
import { Card } from "@/components/ui/card";
import { EnrichedData } from "@/types/intake";

interface DataPreviewProps {
  enrichedData: EnrichedData;
}

export const DataPreview = ({ enrichedData }: DataPreviewProps) => {
  return (
    <div className="space-y-6 text-white">
      <h2 className="text-2xl font-semibold text-brand-orange">Project Analysis</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card className="bg-gray-900 border-gray-800 p-4 shadow">
          <h3 className="text-xl font-semibold mb-4 text-brand-orange">Project Fit</h3>
          <div className="space-y-4">
            <div>
              <div className="flex justify-between items-center mb-2">
                <span className="text-gray-300">Fit Score</span>
                <span className="font-semibold text-white">{enrichedData.projectFitScore}/100</span>
              </div>
              <div className="w-full bg-gray-800 rounded-full h-2.5">
                <div 
                  className="bg-brand-orange h-2.5 rounded-full" 
                  style={{ width: `${enrichedData.projectFitScore}%` }}
                ></div>
              </div>
            </div>
            <div>
              <h4 className="text-gray-300 mb-2">Estimated Budget Range</h4>
              <p className="text-white">{enrichedData.estimatedBudgetRange}</p>
            </div>
          </div>
        </Card>

        <Card className="bg-gray-900 border-gray-800 p-4 shadow">
          <h3 className="text-xl font-semibold mb-4 text-brand-orange">Indicators</h3>
          <div className="space-y-4">
            <div>
              <h4 className="text-green-400 mb-2">Green Flags</h4>
              <ul className="list-disc list-inside space-y-1">
                {enrichedData.greenFlags.map((flag, index) => (
                  <li key={index} className="text-gray-300">{flag}</li>
                ))}
              </ul>
            </div>
            <div>
              <h4 className="text-red-400 mb-2">Potential Concerns</h4>
              <ul className="list-disc list-inside space-y-1">
                {enrichedData.redFlags.map((flag, index) => (
                  <li key={index} className="text-gray-300">{flag}</li>
                ))}
              </ul>
            </div>
          </div>
        </Card>
      </div>
      
      <Card className="bg-gray-900 border-gray-800 p-4 shadow">
        <h3 className="text-xl font-semibold mb-4 text-brand-orange">About You</h3>
        <div className="space-y-4">
          <div>
            <h4 className="text-gray-300 mb-2">Professional Profile</h4>
            <p className="text-white">{enrichedData.professionalProfile}</p>
          </div>
          <div>
            <h4 className="text-gray-300 mb-2">Company Information</h4>
            <p className="text-white">{enrichedData.companyInformation}</p>
          </div>
        </div>
      </Card>
    </div>
  );
};
