import { useEffect } from "react";
import { motion } from "framer-motion";
import { supabase } from "@/integrations/supabase/client";
import { useQuery } from "@tanstack/react-query";
import { Header } from "@/components/Header";
import { Footer } from "@/components/Footer";

interface Client {
  id: string;
  name: string;
  logo_url: string;
  description: string;
  industry: string;
  year_started: number;
  highlight_color: string;
}

const ClientShowcase = () => {
  const { data: clients, isLoading } = useQuery({
    queryKey: ["clients"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("client_showcase")
        .select("*")
        .order("name");
      
      if (error) throw error;
      return data as Client[];
    },
  });

  return (
    <div className="min-h-screen bg-background">
      <Header />
      
      <main className="container mx-auto px-4 py-24">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-center mb-16"
        >
          <h1 className="heading-1 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 text-transparent bg-clip-text mb-6">
            Our Trusted Partners
          </h1>
          <p className="body-large text-muted-foreground max-w-2xl mx-auto">
            We've had the privilege of working with industry leaders and innovators across various sectors.
            Each partnership represents a unique journey of transformation and growth.
          </p>
        </motion.div>

        {isLoading ? (
          <div className="flex items-center justify-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
          </div>
        ) : (
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
          >
            {clients?.map((client, index) => (
              <motion.div
                key={client.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4, delay: index * 0.1 }}
                className="group relative overflow-hidden rounded-xl bg-accent p-8 transition-all duration-300 hover:scale-[1.02] hover:shadow-2xl"
                style={{
                  background: `linear-gradient(45deg, ${client.highlight_color}15, transparent)`,
                }}
              >
                <div className="absolute inset-0 bg-gradient-to-br from-background/80 to-transparent opacity-90 transition-opacity group-hover:opacity-75" />
                
                <div className="relative z-10">
                  <div className="h-24 mb-6 flex items-center justify-center">
                    <img
                      src={client.logo_url}
                      alt={client.name}
                      className="max-h-full w-auto object-contain filter grayscale transition-all duration-300 group-hover:grayscale-0"
                    />
                  </div>
                  
                  <h3 className="text-xl font-display font-bold mb-2">{client.name}</h3>
                  
                  <div className="flex items-center justify-center gap-4 mb-4">
                    <span className="text-sm font-mono px-3 py-1 rounded-full bg-accent/50">
                      {client.industry}
                    </span>
                    {client.year_started && (
                      <span className="text-sm font-mono px-3 py-1 rounded-full bg-accent/50">
                        Since {client.year_started}
                      </span>
                    )}
                  </div>
                  
                  {client.description && (
                    <p className="text-sm text-muted-foreground">
                      {client.description}
                    </p>
                  )}
                </div>
              </motion.div>
            ))}
          </motion.div>
        )}
      </main>

      <Footer />
    </div>
  );
};

export default ClientShowcase;