import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Plus, LayoutList, Kanban } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Database } from "@/integrations/supabase/types";
import { StageColumn } from "./components/StageColumn";
import { useIsMobile } from "@/hooks/use-mobile";
import { CreateDealDialog } from "./CreateDealDialog";
import { DealAnalytics } from "./DealAnalytics";
import { DealList } from "./components/DealList";

type DealStage = Database["public"]["Enums"]["deal_stage"];

const stages: { id: DealStage; label: string }[] = [
  { id: 'qualification', label: 'Qualification' },
  { id: 'discovery', label: 'Discovery' },
  { id: 'proposal', label: 'Proposal' },
  { id: 'negotiation', label: 'Negotiation' },
  { id: 'closing', label: 'Closing' },
  { id: 'won', label: 'Won' },
  { id: 'lost', label: 'Lost' },
];

export const DealKanban = () => {
  const { toast } = useToast();
  const [movingDeal, setMovingDeal] = useState<string | null>(null);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [viewMode, setViewMode] = useState<'kanban' | 'list'>('kanban');
  const isMobile = useIsMobile();

  const { data: deals = [], isLoading, refetch } = useQuery({
    queryKey: ["deals"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("deals")
        .select(`
          *,
          leads (
            name,
            company
          ),
          admin_profiles (
            name
          ),
          deal_analytics (
            probability_score,
            predicted_close_date,
            suggested_actions
          )
        `)
        .order("created_at", { ascending: false });

      if (error) throw error;
      return data;
    },
  });

  const moveDeal = async (dealId: string, currentStage: DealStage, direction: 'left' | 'right') => {
    setMovingDeal(dealId);
    const currentIndex = stages.findIndex(s => s.id === currentStage);
    const newStage = stages[direction === 'left' ? currentIndex - 1 : currentIndex + 1]?.id;

    if (newStage) {
      try {
        const { error } = await supabase
          .from('deals')
          .update({ stage: newStage })
          .eq('id', dealId);

        if (error) throw error;

        await supabase
          .from('deal_activities')
          .insert({
            deal_id: dealId,
            type: 'stage_change',
            description: `Deal moved from ${currentStage} to ${newStage}`,
          });

        toast({
          title: "Success",
          description: "Deal stage updated successfully",
        });

        refetch();
      } catch (error) {
        console.error('Error moving deal:', error);
        toast({
          variant: "destructive",
          title: "Error",
          description: "Failed to update deal stage",
        });
      }
    }
    setMovingDeal(null);
  };

  const totalValue = deals.reduce((sum, deal) => sum + (deal.value || 0), 0);
  const wonDeals = deals.filter(deal => deal.stage === 'won');
  const wonValue = wonDeals.reduce((sum, deal) => sum + (deal.value || 0), 0);

  return (
    <Card className="p-6 sm:p-8 bg-background border-border">
      <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-6">
        <div>
          <h2 className="text-xl sm:text-2xl font-bold text-foreground">Deal Pipeline</h2>
          <div className="flex gap-4 mt-2 text-sm text-muted-foreground">
            <span>Total Value: ${totalValue.toLocaleString()}</span>
            <span>Won Value: ${wonValue.toLocaleString()}</span>
          </div>
        </div>
        <div className="flex flex-wrap gap-2">
          <Button 
            variant="outline"
            onClick={() => setShowAnalytics(!showAnalytics)}
            className="bg-background hover:bg-accent"
          >
            {showAnalytics ? 'Hide Analytics' : 'Show Analytics'}
          </Button>
          <Button
            variant="outline"
            onClick={() => setViewMode(viewMode === 'kanban' ? 'list' : 'kanban')}
            className="bg-background hover:bg-accent"
          >
            {viewMode === 'kanban' ? (
              <><LayoutList className="w-4 h-4 mr-2" /> List View</>
            ) : (
              <><Kanban className="w-4 h-4 mr-2" /> Kanban View</>
            )}
          </Button>
          <Button 
            onClick={() => setShowCreateDialog(true)}
            className="bg-brand-orange hover:bg-brand-orange/90 text-white"
          >
            <Plus className="w-4 h-4 mr-2" />
            New Deal
          </Button>
        </div>
      </div>

      {showAnalytics && <DealAnalytics deals={deals} className="mb-6" />}

      {isLoading ? (
        <div className="text-center py-8 text-muted-foreground">Loading deals...</div>
      ) : viewMode === 'list' ? (
        <DealList deals={deals} />
      ) : isMobile ? (
        <div className="space-y-6">
          {stages.map((stage) => (
            <StageColumn
              key={stage.id}
              stage={stage}
              deals={deals}
              onMove={moveDeal}
              isMoving={!!movingDeal}
              isFirstStage={stage.id === stages[0].id}
              isLastStage={stage.id === stages[stages.length - 1].id}
              isMobile={true}
            />
          ))}
        </div>
      ) : (
        <div className="overflow-x-auto">
          <div className="inline-flex gap-6 min-w-max pb-4">
            {stages.map((stage, index) => (
              <div key={stage.id} className="w-[300px]">
                <StageColumn
                  stage={stage}
                  deals={deals}
                  onMove={moveDeal}
                  isMoving={!!movingDeal}
                  isFirstStage={index === 0}
                  isLastStage={index === stages.length - 1}
                  isMobile={false}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      <CreateDealDialog 
        open={showCreateDialog} 
        onOpenChange={setShowCreateDialog}
        onSuccess={() => {
          refetch();
          setShowCreateDialog(false);
        }}
      />
    </Card>
  );
};