import { SheetHeader, SheetTitle } from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { Building2, X, Sparkles } from "lucide-react";

interface CompanyDetailsSidebarHeaderProps {
  onClose: () => void;
  onEnrich: () => void;
  isEnriching: boolean;
}

export const CompanyDetailsSidebarHeader = ({
  onClose,
  onEnrich,
  isEnriching,
}: CompanyDetailsSidebarHeaderProps) => {
  return (
    <SheetHeader className="mb-6">
      <div className="flex items-center justify-between">
        <SheetTitle className="flex items-center gap-2 text-xl">
          <Building2 className="h-5 w-5 text-muted-foreground" />
          Company Details
        </SheetTitle>
        <div className="flex items-center gap-2">
          <Button
            variant="outline"
            size="sm"
            onClick={onEnrich}
            disabled={isEnriching}
            className="gap-2"
          >
            <Sparkles className="h-4 w-4" />
            Enrich Data
          </Button>
          <Button
            variant="ghost"
            size="icon"
            onClick={onClose}
            className="h-8 w-8"
          >
            <X className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </SheetHeader>
  );
};