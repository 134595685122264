import { Button } from "@/components/ui/button";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Trash } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";

interface DealActionsProps {
  isEditing: boolean;
  onEdit: () => void;
  onSave: () => void;
  onCancel: () => void;
  isSubmitting: boolean;
  dealId: string;
}

export const DealActions = ({ 
  isEditing, 
  onEdit, 
  onSave, 
  onCancel, 
  isSubmitting,
  dealId
}: DealActionsProps) => {
  const navigate = useNavigate();

  const handleDelete = async () => {
    try {
      // Delete deal analytics
      const { error: analyticsError } = await supabase
        .from('deal_analytics')
        .delete()
        .eq('deal_id', dealId);

      if (analyticsError) {
        console.error('Error deleting deal analytics:', analyticsError);
      }

      // Delete all related activities
      const { error: activitiesError } = await supabase
        .from('deal_activities')
        .delete()
        .eq('deal_id', dealId);

      if (activitiesError) {
        console.error('Error deleting deal activities:', activitiesError);
      }

      // Delete all related notes
      const { error: notesError } = await supabase
        .from('notes')
        .delete()
        .eq('deal_id', dealId);

      if (notesError) {
        console.error('Error deleting notes:', notesError);
      }

      // Delete all related tasks
      const { error: tasksError } = await supabase
        .from('tasks')
        .delete()
        .eq('deal_id', dealId);

      if (tasksError) {
        console.error('Error deleting tasks:', tasksError);
      }

      // Finally delete the deal
      const { error: dealError } = await supabase
        .from('deals')
        .delete()
        .eq('id', dealId);

      if (dealError) throw dealError;

      toast.success("Deal and all related data have been deleted successfully");
      navigate('/admin');
    } catch (error) {
      console.error('Error deleting deal:', error);
      toast.error("Failed to delete the deal. Please try again.");
    }
  };

  return (
    <div className="flex gap-2">
      {isEditing ? (
        <>
          <Button
            variant="outline"
            onClick={onCancel}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button onClick={onSave} disabled={isSubmitting}>
            Save Changes
          </Button>
        </>
      ) : (
        <>
          <Button onClick={onEdit}>
            Edit Deal
          </Button>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button variant="destructive" className="gap-2">
                <Trash className="h-4 w-4" />
                Delete Deal
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className="bg-background">
              <AlertDialogHeader>
                <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  This action cannot be undone. This will permanently delete the deal
                  and all associated data including activities, notes, and tasks.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction
                  onClick={handleDelete}
                  className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
                >
                  Delete Deal
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </>
      )}
    </div>
  );
};