import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Tag } from "lucide-react";
import { ContactTagsProps } from "../../types/contactTypes";

export const ContactTags = ({ 
  formData, 
  onTagsChange, 
  isEditing = true 
}: ContactTagsProps) => {
  const [newTag, setNewTag] = useState("");

  const handleAddTag = () => {
    if (newTag && !formData.tags?.includes(newTag)) {
      onTagsChange([...(formData.tags || []), newTag]);
      setNewTag("");
    }
  };

  const handleRemoveTag = (tagToRemove: string) => {
    onTagsChange(formData.tags?.filter((tag) => tag !== tagToRemove) || []);
  };

  if (!isEditing) {
    return (
      <div>
        <div className="flex items-center gap-2 text-muted-foreground mb-2">
          <Tag className="w-4 h-4" />
          <Label>Tags</Label>
        </div>
        <div className="flex flex-wrap gap-2">
          {formData.tags?.map((tag) => (
            <Badge key={tag} variant="secondary">
              {tag}
            </Badge>
          ))}
          {(!formData.tags || formData.tags.length === 0) && (
            <span className="text-muted-foreground">No tags</span>
          )}
        </div>
      </div>
    );
  }

  return (
    <div>
      <Label className="flex items-center gap-2 mb-2">
        <Tag className="w-4 h-4" />
        Tags
      </Label>
      <div className="flex flex-wrap gap-2 mb-2">
        {formData.tags?.map((tag) => (
          <Badge key={tag} variant="secondary" className="group">
            {tag}
            <button
              type="button"
              onClick={() => handleRemoveTag(tag)}
              className="ml-2 hover:text-destructive"
            >
              ×
            </button>
          </Badge>
        ))}
      </div>
      <div className="flex gap-2">
        <Input
          value={newTag}
          onChange={(e) => setNewTag(e.target.value)}
          placeholder="Add a tag"
          className="bg-background"
          onKeyPress={(e) => e.key === 'Enter' && handleAddTag()}
        />
        <Button type="button" onClick={handleAddTag} variant="outline">
          Add
        </Button>
      </div>
    </div>
  );
};