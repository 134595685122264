import { motion } from "framer-motion";

export const InvestmentFocus = () => {
  const stages = ["PRE-SEED", "SEED", "SERIES A"];
  const sectors = [
    "AI & ML",
    "SaaS",
    "FinTech",
    "EdTech",
    "Infrastructure",
    "Product Innovation",
  ];

  return (
    <section className="py-24 bg-[#221F26] relative overflow-hidden">
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#FF4D0005_1px,transparent_1px),linear-gradient(to_bottom,#FF4D0005_1px,transparent_1px)] bg-[size:4rem_4rem] pointer-events-none" />
      
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl md:text-5xl font-display font-bold mb-6 text-white/90">
            Investment Focus
          </h2>
          <p className="text-xl text-white/60 max-w-2xl mx-auto">
            We focus on early-stage technology companies across key sectors
          </p>
        </motion.div>

        <div className="space-y-16">
          <div className="relative">
            <div className="flex justify-between items-center">
              {stages.map((stage, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: 20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.2 }}
                  className="relative z-10 text-center"
                >
                  <div className="w-12 h-12 rounded-full bg-[#FF4D00] text-white flex items-center justify-center mx-auto mb-4">
                    {index + 1}
                  </div>
                  <h3 className="font-bold text-white/90">{stage}</h3>
                </motion.div>
              ))}
            </div>
            <div className="absolute top-6 left-0 right-0 h-[2px] bg-[#FF4D00]/20" />
          </div>

          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {sectors.map((sector, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.95 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ delay: index * 0.1 }}
                className="p-4 rounded-lg border border-white/10 bg-[#222222]/30 backdrop-blur-sm text-center text-white/80 hover:border-[#FF4D00]/30 hover:bg-[#222222]/50 transition-all duration-300"
              >
                {sector}
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};