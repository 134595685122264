import { supabase } from "@/integrations/supabase/client";
import { useQuery } from "@tanstack/react-query";
import { toast } from "sonner";

export const useActivityLogger = () => {
  const logActivity = async ({
    entityType,
    entityId,
    action,
    details,
    adminId
  }: {
    entityType: 'company' | 'contact' | 'deal' | 'lead';
    entityId: string;
    action: string;
    details?: any;
    adminId?: string;
  }) => {
    try {
      const { error } = await supabase.functions.invoke('log-activity', {
        body: {
          entityType,
          entityId,
          action,
          details,
          adminId
        }
      });

      if (error) throw error;

      console.log(`Activity logged: ${entityType} ${action}`);
    } catch (error) {
      console.error('Error logging activity:', error);
      toast.error('Failed to log activity');
    }
  };

  // Fetch recent activities for a specific entity
  const useEntityActivities = (entityType: string, entityId: string) => {
    return useQuery({
      queryKey: ['activities', entityType, entityId],
      queryFn: async () => {
        let query;
        
        switch (entityType) {
          case 'deal':
            query = supabase
              .from('deal_activities')
              .select(`
                *,
                admin_profiles (
                  name
                )
              `)
              .eq('deal_id', entityId)
              .order('created_at', { ascending: false });
            break;

          case 'lead':
            query = supabase
              .from('lead_activities')
              .select(`
                *,
                admin_profiles (
                  name
                )
              `)
              .eq('lead_id', entityId)
              .order('created_at', { ascending: false });
            break;

          default:
            query = supabase
              .from('admin_activity_logs')
              .select(`
                *,
                admin_profiles (
                  name
                )
              `)
              .contains('details', { entityId })
              .order('created_at', { ascending: false });
        }

        const { data, error } = await query;
        if (error) throw error;
        return data;
      },
      enabled: !!entityId
    });
  };

  return {
    logActivity,
    useEntityActivities
  };
};