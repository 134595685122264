import { Link } from "react-router-dom";
import { useLocalStorage } from "@/hooks/use-local-storage";
import { ClientSegment } from "@/types/segments";

export const Logo = () => {
  const [, setClientSegment] = useLocalStorage<ClientSegment | undefined>(
    "preferred-segment",
    undefined
  );

  const handleLogoClick = (e: React.MouseEvent) => {
    e.preventDefault();
    // Clear the segment when clicking the logo
    setClientSegment(undefined);
    window.location.href = '/';
  };

  return (
    <Link to="/" onClick={handleLogoClick} className="flex items-center group">
      <img 
        src="/lovable-uploads/a7877f84-d391-4a98-83c3-cc62b8bd8c4b.png" 
        alt="Unstuck Labs Logo" 
        className="h-4 sm:h-5 md:h-6 transition-all duration-300"
      />
    </Link>
  );
};