import { Facebook, Instagram, Linkedin, Twitter, Youtube, ArrowUpCircle, Terminal, Plus, History, LogIn, User, Phone } from "lucide-react";
import { motion } from "framer-motion";
import { Button } from "./ui/button";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
export const Footer = () => {
  const {
    session
  } = useAuth();
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  const handleSignIn = () => {
    navigate('/auth');
  };
  const socialLinks = [{
    icon: <Linkedin className="w-5 h-5" />,
    href: "#",
    label: "LinkedIn"
  }, {
    icon: <Twitter className="w-5 h-5" />,
    href: "#",
    label: "Twitter"
  }, {
    icon: <Instagram className="w-5 h-5" />,
    href: "#",
    label: "Instagram"
  }, {
    icon: <Facebook className="w-5 h-5" />,
    href: "#",
    label: "Facebook"
  }, {
    icon: <Youtube className="w-5 h-5" />,
    href: "#",
    label: "YouTube"
  }];
  const quickLinks = [{
    label: "Services",
    path: "/services"
  }, {
    label: "About",
    path: "/about"
  }, {
    label: "Case Studies",
    path: "/work"
  }, {
    label: "Blog",
    path: "/blog"
  }, {
    label: "Contact",
    path: "/contact"
  }];
  return <footer className="mt-auto pointer-events-auto relative z-10 bg-black">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-7xl mx-auto px-4 py-16 bg-black">
        <div className="relative z-10">
          <img src="/lovable-uploads/a7877f84-d391-4a98-83c3-cc62b8bd8c4b.png" alt="Unstuck Labs Logo" className="h-8 brightness-0 invert mb-4" />
          <p className="text-gray-300 font-mono text-sm">
            Transforming ideas into powerful digital solutions that drive growth and deliver results.
          </p>
          <Button variant="ghost" className="mt-4 flex items-center gap-2 text-brand-orange hover:text-white hover:bg-brand-orange/10" asChild>
            
          </Button>
        </div>

        <div className="relative z-10">
          <h3 className="text-lg font-display font-bold mb-4 text-white">Quick Links</h3>
          <ul className="space-y-2">
            {quickLinks.map(link => <li key={link.label}>
                <Link to={link.path} className="text-gray-300 hover:text-white transition-colors font-mono text-sm">
                  {link.label}
                </Link>
              </li>)}
            <li>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <button className="text-gray-300 hover:text-white transition-colors font-mono text-sm flex items-center gap-1">
                    FLARE Canvas <Terminal className="w-4 h-4 inline-block ml-1" />
                  </button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                  <DropdownMenuItem asChild>
                    <Link to="/flare/canvas" className="flex items-center gap-2">
                      <Plus className="w-4 h-4" /> New Project
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem asChild>
                    <Link to="/flare" className="flex items-center gap-2">
                      <History className="w-4 h-4" /> Continue Project
                    </Link>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </li>
          </ul>
        </div>

        <div className="relative z-10">
          <h3 className="text-lg font-display font-bold mb-4 text-white">Contact</h3>
          <ul className="space-y-2 text-gray-300">
            <li className="font-mono text-sm">1700 N. Moore St. STE 1502<br />Arlington, VA 22209</li>
            <li className="font-mono text-sm">hello@unstucklabs.com</li>
            <li className="font-mono text-sm">
              <a href="tel:+17035528348" className="flex items-center gap-2 hover:text-brand-orange transition-colors">
                <Phone className="w-4 h-4" />
                +1 (703) 552-8348
              </a>
            </li>
          </ul>
        </div>

        <div className="relative z-10">
          <h3 className="text-lg font-display font-bold mb-4 text-white">Account</h3>
          {session?.user ? <div className="space-y-4">
              <div className="flex items-center gap-2 text-gray-300">
                <Avatar className="h-8 w-8">
                  <AvatarImage src={session.user.user_metadata?.avatar_url} />
                  <AvatarFallback>
                    <User className="h-4 w-4" />
                  </AvatarFallback>
                </Avatar>
                <span className="font-mono text-sm">{session.user.email}</span>
              </div>
            </div> : <Button variant="outline" className="w-full mb-4 text-brand-orange hover:text-white hover:bg-brand-charcoal flex items-center gap-2 justify-center" onClick={handleSignIn}>
              <LogIn className="h-4 w-4" />
              Sign In
            </Button>}
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 py-6 bg-black relative z-10">
        <div className="flex flex-col md:flex-row justify-between items-center gap-4">
          <p className="text-gray-300 text-sm font-mono">
            © {new Date().getFullYear()} Unstuck Labs. All rights reserved.
          </p>
          
          <div className="flex gap-6">
            {socialLinks.map(social => <motion.a key={social.label} href={social.href} whileHover={{
            scale: 1.1
          }} whileTap={{
            scale: 0.95
          }} className="text-gray-300 hover:text-secondary transition-colors" aria-label={social.label}>
                {social.icon}
              </motion.a>)}
          </div>

          <motion.button onClick={scrollToTop} whileHover={{
          scale: 1.1
        }} whileTap={{
          scale: 0.95
        }} className="text-gray-300 hover:text-secondary transition-colors" aria-label="Scroll to top">
            <ArrowUpCircle className="w-6 h-6" />
          </motion.button>
        </div>
      </div>
    </footer>;
};