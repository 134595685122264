import { useState, useEffect } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { useAuth } from '@/contexts/AuthContext';

export interface Space {
  id: string;
  name: string;
  description: string | null;
  workspace_id: string;
  avatar_url?: string | null;
}

export interface Folder {
  id: string;
  name: string;
  description: string | null;
  workspace_id: string | null;
  space_id: string | null;
  created_at?: string;
  updated_at?: string;
}

export interface List {
  id: string;
  name: string;
  description: string | null;
  folder_id: string;
}

export interface Workspace {
  id: string;
  name: string;
  description: string | null;
}

export function useWorkspaceData(workspaceId: string | undefined) {
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  const [spaces, setSpaces] = useState<Space[]>([]);
  const [folders, setFolders] = useState<Folder[]>([]);
  const [lists, setLists] = useState<List[]>([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState<string | null>(null);
  const [selectedSpace, setSelectedSpace] = useState<string | null>(null);
  const [selectedFolder, setSelectedFolder] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { session } = useAuth();

  const fetchWorkspaceData = async () => {
    try {
      setIsLoading(true);
      const { data: workspacesData, error: workspacesError } = await supabase
        .from('workspaces')
        .select('*')
        .order('created_at', { ascending: false });

      if (workspacesError) throw workspacesError;
      
      setWorkspaces(workspacesData || []);

      if (workspacesData?.length > 0) {
        const firstWorkspace = workspaceId || workspacesData[0].id;
        setSelectedWorkspace(firstWorkspace);
        await fetchSpaces(firstWorkspace);
      }
    } catch (error) {
      console.error('Error fetching workspace data:', error);
      toast.error('Failed to load workspace data');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSpaces = async (wsId: string) => {
    try {
      console.log('Fetching spaces for workspace:', wsId);
      const { data: spacesData, error: spacesError } = await supabase
        .from('spaces')
        .select('*')
        .eq('workspace_id', wsId)
        .order('created_at', { ascending: false });

      if (spacesError) throw spacesError;
      
      console.log('Spaces fetched:', spacesData);
      setSpaces(spacesData || []);

      if (spacesData?.length > 0) {
        setSelectedSpace(spacesData[0].id);
        await fetchFolders(spacesData[0].id);
      } else {
        setFolders([]);
        setLists([]);
      }
    } catch (error) {
      console.error('Error fetching spaces:', error);
      toast.error('Failed to load spaces');
    }
  };

  const fetchFolders = async (spaceId: string) => {
    try {
      console.log('Fetching folders for space:', spaceId);
      const { data, error } = await supabase
        .from('folders')
        .select('*')
        .eq('space_id', spaceId)
        .order('created_at', { ascending: false });

      if (error) throw error;
      
      const typedFolders: Folder[] = (data || []).map(folder => ({
        id: folder.id,
        name: folder.name,
        description: folder.description,
        workspace_id: null,
        space_id: folder.space_id,
        created_at: folder.created_at,
        updated_at: folder.updated_at
      }));
      
      console.log('Folders fetched:', typedFolders);
      setFolders(typedFolders);

      if (typedFolders.length > 0) {
        setSelectedFolder(typedFolders[0].id);
        await fetchLists(typedFolders[0].id);
      } else {
        setLists([]);
      }
    } catch (error) {
      console.error('Error fetching folders:', error);
      toast.error('Failed to load folders');
    }
  };

  const fetchLists = async (folderId: string) => {
    try {
      console.log('Fetching lists for folder:', folderId);
      const { data, error } = await supabase
        .from('lists')
        .select('*')
        .eq('folder_id', folderId)
        .order('created_at', { ascending: false });

      if (error) throw error;
      console.log('Lists fetched:', data);
      setLists(data || []);
    } catch (error) {
      console.error('Error fetching lists:', error);
      toast.error('Failed to load lists');
    }
  };

  const createWorkspace = async (data: { name: string; description?: string }) => {
    try {
      if (!session?.user?.id) {
        throw new Error('No authenticated user found');
      }

      const { error } = await supabase
        .from('workspaces')
        .insert([data]);
      
      if (error) throw error;
      
      toast.success('Workspace created successfully');
      await fetchWorkspaceData();
    } catch (error) {
      console.error('Error creating workspace:', error);
      toast.error('Failed to create workspace');
    }
  };

  const deleteWorkspace = async (id: string) => {
    try {
      const { error } = await supabase
        .from('workspaces')
        .delete()
        .eq('id', id);
      
      if (error) throw error;
      
      toast.success('Workspace deleted successfully');
      fetchWorkspaceData();
    } catch (error) {
      console.error('Error deleting workspace:', error);
      toast.error('Failed to delete workspace');
    }
  };

  const deleteSpace = async (id: string) => {
    try {
      const { error } = await supabase
        .from('spaces')
        .delete()
        .eq('id', id);
      
      if (error) throw error;
      
      toast.success('Space deleted successfully');
      if (selectedWorkspace) {
        fetchSpaces(selectedWorkspace);
      }
    } catch (error) {
      console.error('Error deleting space:', error);
      toast.error('Failed to delete space');
    }
  };

  const deleteFolder = async (id: string) => {
    try {
      const { error } = await supabase
        .from('folders')
        .delete()
        .eq('id', id);
      
      if (error) throw error;
      
      toast.success('Folder deleted successfully');
      if (selectedSpace) {
        fetchFolders(selectedSpace);
      }
    } catch (error) {
      console.error('Error deleting folder:', error);
      toast.error('Failed to delete folder');
    }
  };

  const deleteList = async (id: string) => {
    try {
      const { error } = await supabase
        .from('lists')
        .delete()
        .eq('id', id);
      
      if (error) throw error;
      
      toast.success('List deleted successfully');
      if (selectedFolder) {
        fetchLists(selectedFolder);
      }
    } catch (error) {
      console.error('Error deleting list:', error);
      toast.error('Failed to delete list');
    }
  };

  const updateWorkspace = async (id: string, data: Partial<Workspace>) => {
    try {
      const { error } = await supabase
        .from('workspaces')
        .update(data)
        .eq('id', id);
      
      if (error) throw error;
      
      toast.success('Workspace updated successfully');
      fetchWorkspaceData();
    } catch (error) {
      console.error('Error updating workspace:', error);
      toast.error('Failed to update workspace');
    }
  };

  const updateSpace = async (id: string, data: Partial<Space>) => {
    try {
      const { error } = await supabase
        .from('spaces')
        .update(data)
        .eq('id', id);
      
      if (error) throw error;
      
      toast.success('Space updated successfully');
      if (selectedWorkspace) {
        fetchSpaces(selectedWorkspace);
      }
    } catch (error) {
      console.error('Error updating space:', error);
      toast.error('Failed to update space');
    }
  };

  const updateFolder = async (id: string, data: Partial<Folder>) => {
    try {
      const { error } = await supabase
        .from('folders')
        .update(data)
        .eq('id', id);
      
      if (error) throw error;
      
      toast.success('Folder updated successfully');
      if (selectedSpace) {
        fetchFolders(selectedSpace);
      }
    } catch (error) {
      console.error('Error updating folder:', error);
      toast.error('Failed to update folder');
    }
  };

  const updateList = async (id: string, data: Partial<List>) => {
    try {
      const { error } = await supabase
        .from('lists')
        .update(data)
        .eq('id', id);
      
      if (error) throw error;
      
      toast.success('List updated successfully');
      if (selectedFolder) {
        fetchLists(selectedFolder);
      }
    } catch (error) {
      console.error('Error updating list:', error);
      toast.error('Failed to update list');
    }
  };

  useEffect(() => {
    if (session?.user) {
      fetchWorkspaceData();
    }
  }, [session]);

  useEffect(() => {
    if (workspaceId && session?.user) {
      setSelectedWorkspace(workspaceId);
      fetchSpaces(workspaceId);
    }
  }, [workspaceId, session]);

  return {
    workspaces,
    spaces,
    folders,
    lists,
    selectedWorkspace,
    selectedSpace,
    selectedFolder,
    isLoading,
    setSelectedWorkspace,
    setSelectedSpace,
    setSelectedFolder,
    fetchSpaces,
    fetchFolders,
    fetchLists,
    fetchWorkspaceData,
    createWorkspace,
    deleteWorkspace,
    deleteSpace,
    deleteFolder,
    deleteList,
    updateWorkspace,
    updateSpace,
    updateFolder,
    updateList,
  };
}
