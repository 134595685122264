
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Mail, Phone, MapPin, ArrowRight } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";

export const UnifiedContactForm = ({ darkMode = true }: { darkMode?: boolean }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // In a real application, this would send the data to a server
    console.log("Form submitted:", formData);
    toast.success("Message sent successfully! We'll get back to you soon.");
    setFormData({ name: "", email: "", message: "" });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <section className={`py-32 ${darkMode ? 'bg-black dark:bg-primary' : 'bg-background dark:bg-primary'}`}>
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="max-w-4xl mx-auto text-center mb-16"
        >
          <h2 className={`text-5xl font-display font-bold mb-8 ${darkMode ? 'text-white' : 'text-primary dark:text-white'}`}>
            Let's Build Something Amazing Together
          </h2>
          <p className={`text-xl mb-16 ${darkMode ? 'text-white/80' : 'text-primary/80 dark:text-white/80'}`}>
            Ready to start your next project? We're here to help turn your vision into reality.
          </p>

          <div className="grid md:grid-cols-3 gap-8 mb-16">
            <div className={`${darkMode ? 'bg-zinc-900/40' : 'bg-accent dark:bg-primary/40'} p-8 text-center rounded-lg shadow-lg dark:shadow-primary/5`}>
              <Mail className={`w-8 h-8 ${darkMode ? 'text-brand-orange' : 'text-secondary'} mx-auto mb-4`} />
              <h3 className={`text-xl font-display font-bold mb-2 ${darkMode ? 'text-white' : 'dark:text-white'}`}>Email Us</h3>
              <p className={`${darkMode ? 'text-white/70' : 'text-primary/70 dark:text-white/70'}`}>hello@unstucklabs.com</p>
            </div>
            <div className={`${darkMode ? 'bg-zinc-900/40' : 'bg-accent dark:bg-primary/40'} p-8 text-center rounded-lg shadow-lg dark:shadow-primary/5 group hover:bg-zinc-800/40 transition-colors duration-300 cursor-pointer`}>
              <a href="tel:+17035528348" className="block">
                <Phone className={`w-8 h-8 ${darkMode ? 'text-brand-orange' : 'text-secondary'} mx-auto mb-4 group-hover:scale-110 transition-transform`} />
                <h3 className={`text-xl font-display font-bold mb-2 ${darkMode ? 'text-white' : 'dark:text-white'}`}>Call Us</h3>
                <p className={`${darkMode ? 'text-white/70' : 'text-primary/70 dark:text-white/70'}`}>+1 (703) 552-8348</p>
                <p className="mt-2 text-sm text-brand-orange opacity-0 group-hover:opacity-100 transition-opacity">Tap to call now</p>
              </a>
            </div>
            <div className={`${darkMode ? 'bg-zinc-900/40' : 'bg-accent dark:bg-primary/40'} p-8 text-center rounded-lg shadow-lg dark:shadow-primary/5`}>
              <MapPin className={`w-8 h-8 ${darkMode ? 'text-brand-orange' : 'text-secondary'} mx-auto mb-4`} />
              <h3 className={`text-xl font-display font-bold mb-2 ${darkMode ? 'text-white' : 'dark:text-white'}`}>Visit Us</h3>
              <p className={`${darkMode ? 'text-white/70' : 'text-primary/70 dark:text-white/70'}`}>1700 N. Moore St. STE 1502<br />Arlington, VA 22209</p>
            </div>
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className={`${darkMode ? 'bg-zinc-900/40' : 'bg-accent dark:bg-primary/40'} p-12 rounded-xl shadow-xl dark:shadow-primary/5`}
          >
            <h3 className={`text-3xl font-display font-bold mb-8 ${darkMode ? 'text-white' : 'text-primary dark:text-white'}`}>Send Us a Message</h3>
            <form onSubmit={handleSubmit} className="max-w-2xl mx-auto">
              <div className="grid md:grid-cols-2 gap-6 mb-6">
                <div>
                  <Input
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    className={`${darkMode ? 'bg-black/60 border-zinc-800' : 'bg-white dark:bg-primary/60'} ${darkMode ? 'text-white' : 'dark:text-white'} ${darkMode ? 'placeholder-white/30' : 'dark:placeholder-white/50'}`}
                    required
                  />
                </div>
                <div>
                  <Input
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleChange}
                    className={`${darkMode ? 'bg-black/60 border-zinc-800' : 'bg-white dark:bg-primary/60'} ${darkMode ? 'text-white' : 'dark:text-white'} ${darkMode ? 'placeholder-white/30' : 'dark:placeholder-white/50'}`}
                    required
                  />
                </div>
              </div>
              <div className="mb-6">
                <Textarea
                  name="message"
                  placeholder="Your Message"
                  value={formData.message}
                  onChange={handleChange}
                  className={`min-h-[200px] ${darkMode ? 'bg-black/60 border-zinc-800' : 'bg-white dark:bg-primary/60'} ${darkMode ? 'text-white' : 'dark:text-white'} ${darkMode ? 'placeholder-white/30' : 'dark:placeholder-white/50'}`}
                  required
                />
              </div>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <Button 
                  type="submit"
                  className={`${darkMode ? 'bg-brand-orange hover:bg-brand-orange/90' : 'bg-secondary hover:bg-secondary/90'} text-white px-8 py-6 text-lg font-mono w-full md:w-auto`}
                >
                  Send Message <ArrowRight className="ml-2" />
                </Button>
                <a 
                  href="tel:+17035528348" 
                  className={`flex items-center justify-center gap-2 px-8 py-6 border ${darkMode ? 'border-white/20 hover:bg-white/5' : 'border-gray-300 hover:bg-gray-100'} rounded-md text-lg font-mono w-full md:w-auto`}
                >
                  <Phone className="w-5 h-5" /> 
                  Call Us Now
                </a>
              </div>
            </form>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};
