import { useEffect, useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { supabase } from '@/integrations/supabase/client';
import { Clock, Loader2, Plus } from 'lucide-react';
import { toast } from 'sonner';
import { format } from 'date-fns';

interface TimeOffRequest {
  id: string;
  request_type: 'vacation' | 'sick' | 'personal' | 'other';
  start_date: string;
  end_date: string;
  status: string;
  notes: string | null;
  manager_notes: string | null;
  reviewed_at: string | null;
}

interface ManagerInfo {
  first_name: string;
  last_name: string;
  email: string;
}

export const EmployeeTimeOff = () => {
  const [requests, setRequests] = useState<TimeOffRequest[]>([]);
  const [loading, setLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [managerInfo, setManagerInfo] = useState<ManagerInfo | null>(null);
  
  // Form state
  const [requestType, setRequestType] = useState<'vacation' | 'sick' | 'personal' | 'other'>('vacation');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [notes, setNotes] = useState('');

  useEffect(() => {
    const getRequests = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          toast.error('No authenticated user found');
          setLoading(false);
          return;
        }

        const { data: employeeProfile, error: profileError } = await supabase
          .from('employee_profiles')
          .select('id, reports_to')
          .eq('user_id', user.id)
          .maybeSingle();

        if (profileError) {
          console.error('Error fetching employee profile:', profileError);
          toast.error('Error checking employee access');
          setLoading(false);
          return;
        }

        if (!employeeProfile) {
          toast.error('No employee profile found. Please contact your administrator.');
          setLoading(false);
          return;
        }

        // Fetch manager info if reports_to is set
        if (employeeProfile.reports_to) {
          const { data: managerData, error: managerError } = await supabase
            .from('employee_profiles')
            .select('first_name, last_name, email')
            .eq('id', employeeProfile.reports_to)
            .single();

          if (!managerError && managerData) {
            setManagerInfo(managerData);
          }
        }

        const { data, error } = await supabase
          .from('time_off_requests')
          .select('*')
          .eq('employee_id', employeeProfile.id)
          .order('created_at', { ascending: false });

        if (error) {
          console.error('Error fetching time off requests:', error);
          toast.error('Failed to load time off requests');
          return;
        }

        if (data) {
          setRequests(data);
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error('Failed to load profile data');
      } finally {
        setLoading(false);
      }
    };

    getRequests();
  }, []);

  const handleNewRequest = async () => {
    // Validate dates
    if (!startDate || !endDate) {
      toast.error('Please select both start and end dates');
      return;
    }

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        toast.error('No authenticated user found');
        return;
      }

      const { data: employeeProfile, error: profileError } = await supabase
        .from('employee_profiles')
        .select('id')
        .eq('user_id', user.id)
        .single();

      if (profileError || !employeeProfile) {
        toast.error('Error creating request: Profile not found');
        return;
      }

      const { data, error } = await supabase
        .from('time_off_requests')
        .insert({
          employee_id: employeeProfile.id,
          request_type: requestType,
          start_date: new Date(startDate).toISOString().split('T')[0],
          end_date: new Date(endDate).toISOString().split('T')[0],
          notes: notes,
          status: 'pending'
        })
        .select()
        .single();

      if (error) {
        toast.error('Error creating time off request');
        console.error('Error:', error);
        return;
      }

      if (data) {
        setRequests([data, ...requests]);
        toast.success('Time off request submitted successfully');
        setIsDialogOpen(false);
        resetForm();
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to submit request');
    }
  };

  const resetForm = () => {
    setRequestType('vacation');
    setStartDate('');
    setEndDate('');
    setNotes('');
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <Clock className="h-6 w-6 text-primary" />
          <h1 className="text-3xl font-bold">Time Off Requests</h1>
        </div>
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogTrigger asChild>
            <Button>
              <Plus className="h-4 w-4 mr-2" />
              New Request
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>New Time Off Request</DialogTitle>
            </DialogHeader>
            <div className="space-y-4 py-4">
              <div className="space-y-2">
                <Label htmlFor="type">Request Type</Label>
                <Select value={requestType} onValueChange={(value: 'vacation' | 'sick' | 'personal' | 'other') => setRequestType(value)}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="vacation">Vacation</SelectItem>
                    <SelectItem value="sick">Sick Leave</SelectItem>
                    <SelectItem value="personal">Personal Leave</SelectItem>
                    <SelectItem value="other">Other</SelectItem>
                  </SelectContent>
                </Select>
              </div>

              <div className="space-y-2">
                <Label htmlFor="startDate">Start Date</Label>
                <Input
                  id="startDate"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="endDate">End Date</Label>
                <Input
                  id="endDate"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="notes">Notes</Label>
                <Textarea
                  id="notes"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  placeholder="Add any additional notes..."
                />
              </div>

              {managerInfo && (
                <div className="text-sm text-muted-foreground">
                  Your request will be sent to: {managerInfo.first_name} {managerInfo.last_name}
                </div>
              )}

              <Button onClick={handleNewRequest} className="w-full">
                Submit Request
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
      
      <div className="grid gap-4">
        {requests.map((request) => (
          <Card key={request.id} className="hover:shadow-md transition-shadow">
            <CardHeader>
              <CardTitle className="flex justify-between items-center">
                <span className="capitalize">{request.request_type}</span>
                <span className={`text-sm font-medium ${
                  request.status === 'approved' ? 'text-green-600' :
                  request.status === 'rejected' ? 'text-red-600' :
                  'text-yellow-600'
                }`}>
                  {request.status}
                </span>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-2">
                <p className="text-muted-foreground">
                  From: {format(new Date(request.start_date), 'MMMM d, yyyy')}
                </p>
                <p className="text-muted-foreground">
                  To: {format(new Date(request.end_date), 'MMMM d, yyyy')}
                </p>
                {request.notes && (
                  <p className="text-muted-foreground">Notes: {request.notes}</p>
                )}
                {request.manager_notes && (
                  <p className="text-muted-foreground mt-2 pt-2 border-t">
                    Manager Response: {request.manager_notes}
                  </p>
                )}
                {request.reviewed_at && (
                  <p className="text-sm text-muted-foreground">
                    Reviewed on: {format(new Date(request.reviewed_at), 'MMMM d, yyyy')}
                  </p>
                )}
              </div>
            </CardContent>
          </Card>
        ))}
        
        {requests.length === 0 && (
          <Card>
            <CardContent className="py-8">
              <div className="text-center text-muted-foreground">
                <Clock className="h-12 w-12 mx-auto mb-4 opacity-50" />
                <p>No time off requests found</p>
                <p className="mt-2">Click "New Request" to submit your first request</p>
              </div>
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
};