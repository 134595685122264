
import { motion } from 'framer-motion';

interface ThankYouHeaderProps {
  firstName: string;
}

export const ThankYouHeader = ({ firstName }: ThankYouHeaderProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, delay: 0.2 }}
      className="text-center w-full"
    >
      <motion.div
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
        transition={{ 
          duration: 0.5, 
          delay: 0.4,
          type: "spring",
          stiffness: 200
        }}
        className="mb-3"
      >
        <h1 className="text-4xl md:text-5xl font-display font-bold text-brand-orange bg-clip-text">
          Thank You {firstName ? firstName : ''}!
        </h1>
      </motion.div>
      
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.6 }}
        className="max-w-2xl mx-auto"
      >
        <p className="text-xl text-white/80 mb-1">
          We're excited to meet with you and discuss your project.
        </p>
        <p className="text-white/70">
          Please select a time that works for you to schedule your discovery call.
        </p>
      </motion.div>
    </motion.div>
  );
};
