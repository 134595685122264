
import { Checkbox } from "@/components/ui/checkbox";
import { Shield, Lock, FileCheck, Eye, ClipboardCheck } from "lucide-react";

interface RequirementsListProps {
  requirements: {
    dataPrivacy: boolean;
    security: boolean;
    fedramp: boolean;
    fisma: boolean;
    section508: boolean;
  };
  onRequirementChange: (key: string, value: boolean) => void;
}

export const RequirementsList = ({ requirements, onRequirementChange }: RequirementsListProps) => {
  const requirementItems = [
    {
      key: 'fedramp',
      label: 'FedRAMP Compliance',
      icon: Shield,
      description: 'Federal Risk and Authorization Management Program for cloud services'
    },
    {
      key: 'fisma',
      label: 'FISMA Compliance',
      icon: Lock,
      description: 'Federal Information Security Modernization Act requirements'
    },
    {
      key: 'section508',
      label: 'Section 508 Compliance',
      icon: FileCheck,
      description: 'Accessibility standards for electronic and information technology'
    },
    {
      key: 'dataPrivacy',
      label: 'Data Privacy Requirements',
      icon: Eye,
      description: 'Protection of sensitive citizen information and privacy regulations'
    },
    {
      key: 'security',
      label: 'Security Controls',
      icon: ClipboardCheck,
      description: 'NIST 800-53 security and privacy controls implementation'
    }
  ];

  return (
    <div className="space-y-6">
      <h3 className="text-xl font-semibold text-emerald-700 dark:text-emerald-400 mb-4">Compliance Requirements</h3>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {requirementItems.map(({ key, label, icon: Icon, description }) => (
          <div key={key} className="flex items-start space-x-3 p-4 bg-emerald-50/50 dark:bg-emerald-900/10 border border-emerald-100 dark:border-emerald-900/30 rounded-lg">
            <Icon className="w-5 h-5 text-emerald-600 dark:text-emerald-400 mt-1 flex-shrink-0" />
            <div className="space-y-2">
              <label className="flex items-center space-x-3">
                <Checkbox
                  checked={requirements[key as keyof typeof requirements]}
                  onCheckedChange={(checked) =>
                    onRequirementChange(key, !!checked)
                  }
                  className="border-emerald-500 data-[state=checked]:bg-emerald-500 data-[state=checked]:text-white"
                />
                <span className="font-medium text-gray-900 dark:text-white">{label}</span>
              </label>
              <p className="text-sm text-gray-500 dark:text-gray-400">{description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
