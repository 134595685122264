import { useState } from "react";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ArrowRight, Upload, Send } from "lucide-react";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";

// Move form data type to a separate types file for better organization
type PitchFormData = {
  company_name: string;
  founder_name: string;
  email: string;
  website: string | null;
  stage: string;
  industry: string;
  location: string | null;
  team_size: string | null;
  problem: string;
  solution: string;
  traction: string | null;
  funding_needs: string | null;
  pitch_deck: File | null;
};

export const PitchForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<PitchFormData>({
    company_name: "",
    founder_name: "",
    email: "",
    website: "",
    stage: "",
    industry: "",
    location: "",
    team_size: "",
    problem: "",
    solution: "",
    traction: "",
    funding_needs: "",
    pitch_deck: null,
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFormData(prev => ({ ...prev, pitch_deck: e.target.files![0] }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // Upload pitch deck if provided
      let pitch_deck_url = "";
      if (formData.pitch_deck) {
        const fileExt = formData.pitch_deck.name.split('.').pop();
        const fileName = `${Math.random()}.${fileExt}`;
        const { error: uploadError } = await supabase.storage
          .from('pitch-decks')
          .upload(fileName, formData.pitch_deck);

        if (uploadError) throw uploadError;
        
        const { data: { publicUrl } } = supabase.storage
          .from('pitch-decks')
          .getPublicUrl(fileName);
          
        pitch_deck_url = publicUrl;
      }

      // Save submission to database
      const { error } = await supabase
        .from('pitch_submissions')
        .insert([{
          ...formData,
          pitch_deck_url,
          status: 'pending'
        }]);

      if (error) throw error;

      toast.success("Your pitch has been submitted successfully! We'll be in touch soon.");
      // Reset form
      setFormData({
        company_name: "",
        founder_name: "",
        email: "",
        website: "",
        stage: "",
        industry: "",
        location: "",
        team_size: "",
        problem: "",
        solution: "",
        traction: "",
        funding_needs: "",
        pitch_deck: null,
      });
      setStep(1);
    } catch (error) {
      console.error('Error submitting pitch:', error);
      toast.error("There was an error submitting your pitch. Please try again.");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="max-w-4xl mx-auto p-6"
    >
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold mb-4">Submit Your Pitch</h2>
        <p className="text-muted-foreground">
          Tell us about your startup and vision. We review every submission carefully.
        </p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-8">
        {step === 1 && (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            className="space-y-6"
          >
            <div className="grid md:grid-cols-2 gap-6">
              <div className="space-y-2">
                <Label htmlFor="company_name">Company Name *</Label>
                <Input
                  id="company_name"
                  value={formData.company_name}
                  onChange={(e) => setFormData(prev => ({ ...prev, company_name: e.target.value }))}
                  required
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="founder_name">Founder Name *</Label>
                <Input
                  id="founder_name"
                  value={formData.founder_name}
                  onChange={(e) => setFormData(prev => ({ ...prev, founder_name: e.target.value }))}
                  required
                />
              </div>
            </div>

            <div className="grid md:grid-cols-2 gap-6">
              <div className="space-y-2">
                <Label htmlFor="email">Email *</Label>
                <Input
                  id="email"
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                  required
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="website">Website</Label>
                <Input
                  id="website"
                  type="url"
                  value={formData.website || ""}
                  onChange={(e) => setFormData(prev => ({ ...prev, website: e.target.value }))}
                  placeholder="https://"
                />
              </div>
            </div>

            <div className="grid md:grid-cols-2 gap-6">
              <div className="space-y-2">
                <Label htmlFor="stage">Stage *</Label>
                <Select
                  value={formData.stage}
                  onValueChange={(value) => setFormData(prev => ({ ...prev, stage: value }))}
                  required
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select stage" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="idea">Idea Stage</SelectItem>
                    <SelectItem value="mvp">MVP</SelectItem>
                    <SelectItem value="early-revenue">Early Revenue</SelectItem>
                    <SelectItem value="growth">Growth</SelectItem>
                    <SelectItem value="scaling">Scaling</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label htmlFor="industry">Industry *</Label>
                <Select
                  value={formData.industry}
                  onValueChange={(value) => setFormData(prev => ({ ...prev, industry: value }))}
                  required
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select industry" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="saas">SaaS</SelectItem>
                    <SelectItem value="fintech">FinTech</SelectItem>
                    <SelectItem value="ai-ml">AI/ML</SelectItem>
                    <SelectItem value="health">HealthTech</SelectItem>
                    <SelectItem value="ecommerce">E-commerce</SelectItem>
                    <SelectItem value="other">Other</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>

            <Button
              type="button"
              onClick={() => setStep(2)}
              className="w-full group"
            >
              Next Step
              <ArrowRight className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" />
            </Button>
          </motion.div>
        )}

        {step === 2 && (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            className="space-y-6"
          >
            <div className="space-y-2">
              <Label htmlFor="problem">Problem You're Solving *</Label>
              <Textarea
                id="problem"
                value={formData.problem}
                onChange={(e) => setFormData(prev => ({ ...prev, problem: e.target.value }))}
                placeholder="What problem are you solving and why is it important?"
                className="min-h-[100px]"
                required
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="solution">Your Solution *</Label>
              <Textarea
                id="solution"
                value={formData.solution}
                onChange={(e) => setFormData(prev => ({ ...prev, solution: e.target.value }))}
                placeholder="How does your solution work and what makes it unique?"
                className="min-h-[100px]"
                required
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="traction">Traction & Metrics</Label>
              <Textarea
                id="traction"
                value={formData.traction || ""}
                onChange={(e) => setFormData(prev => ({ ...prev, traction: e.target.value }))}
                placeholder="Share key metrics, user growth, revenue, or other achievements"
                className="min-h-[100px]"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="pitch_deck">Pitch Deck (PDF, PPT, or KEY) *</Label>
              <div className="flex items-center gap-4">
                <Input
                  id="pitch_deck"
                  type="file"
                  accept=".pdf,.ppt,.pptx,.key"
                  onChange={handleFileChange}
                  className="hidden"
                  required
                />
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => document.getElementById('pitch_deck')?.click()}
                  className="w-full group"
                >
                  <Upload className="mr-2 h-4 w-4" />
                  {formData.pitch_deck ? formData.pitch_deck.name : "Upload Pitch Deck"}
                </Button>
              </div>
            </div>

            <div className="flex gap-4">
              <Button
                type="button"
                variant="outline"
                onClick={() => setStep(1)}
                className="flex-1"
              >
                Back
              </Button>
              <Button type="submit" className="flex-1 group">
                Submit Pitch
                <Send className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" />
              </Button>
            </div>
          </motion.div>
        )}
      </form>
    </motion.div>
  );
};