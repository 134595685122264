
import { memo } from 'react';
import { Handle, Position } from '@xyflow/react';
import { Code } from 'lucide-react';

interface CodeNodeData {
  label: string;
  code?: string;
  language?: string;
  description?: string;
}

export const CodeNode = memo(({ data }: { data: CodeNodeData }) => {
  return (
    <div className="min-w-[200px] min-h-[120px] rounded-lg border-2 border-amber-500 bg-amber-500/10 p-4 backdrop-blur-sm">
      <Handle type="target" position={Position.Left} className="w-4 h-4 !bg-background border-2 border-secondary rounded-full" />
      <div className="space-y-2">
        <div className="flex items-center gap-2">
          <Code className="w-4 h-4" />
          <div className="font-medium">{data.label}</div>
        </div>
        {data.description && (
          <div className="text-sm text-muted-foreground">{data.description}</div>
        )}
        
        {data.code && (
          <div className="mt-2 bg-black/80 text-xs text-white p-2 rounded font-mono overflow-x-auto">
            {data.code.length > 100 ? data.code.substring(0, 100) + '...' : data.code}
          </div>
        )}
        
        {data.language && (
          <div className="text-xs text-amber-500">{data.language}</div>
        )}
      </div>
      <Handle type="source" position={Position.Right} className="w-4 h-4 !bg-background border-2 border-secondary rounded-full" />
    </div>
  );
});

CodeNode.displayName = 'CodeNode';
