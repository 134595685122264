import { motion } from "framer-motion";
import { StartupInfo } from "@/types/ventures";
import { PortfolioBatch } from "./PortfolioBatch";

export const PortfolioShowcase = () => {
  const portfolio: StartupInfo[] = [
    // 2017 Batch
    {
      name: "Rooam",
      status: "Acquired by American Express",
      description: "Touchless payments and POS integration",
      category: "b2b saas fintech hospitality",
      year: 2017
    },
    {
      name: "Tele",
      status: "Active",
      description: "Electronics component manufacturing",
      category: "b2b electronic hardware",
      year: 2017
    },
    // 2018 Batch
    {
      name: "REYETS",
      status: "Backed by Camelback Ventures",
      description: "App to help you know your rights",
      category: "b2c justicetech edtech",
      year: 2018
    },
    {
      name: "TRAXyL",
      status: "Active",
      description: "Last mile solution paint fiber on the road",
      category: "b2b fiber hardware telecom",
      year: 2018
    },
    // 2019 Batch
    {
      name: "PRYZE",
      status: "VC-backed & Profitable",
      description: "Reduces distractions from mobile phones at work with rewards",
      category: "b2b saas future of work productivity",
      year: 2019
    },
    {
      name: "ErgoRX",
      status: "Active",
      description: "World's most ergonomic desk tools",
      category: "b2b2c workplace hardware healthtech",
      year: 2019
    },
    {
      name: "BatteryXchange",
      status: "Active",
      description: "On the go vending platform that provides portable batteries to cell phones",
      category: "b2c energy adtech",
      year: 2019
    },
    {
      name: "CandidWorks",
      status: "Active",
      description: "Solving the student debt problem through dual impact giving",
      category: "b2c student fintech",
      year: 2019
    },
    {
      name: "History X",
      status: "Active",
      description: "Pokemon Go for history",
      category: "b2c social game educatar",
      year: 2019
    },
    {
      name: "Equality MD",
      status: "Active",
      description: "Accessible healthcare for LGBTQ+",
      category: "b2c social community workplace",
      year: 2019
    },
    // 2020 Batch
    {
      name: "WIWO",
      status: "Active",
      description: "Show and tell for adults",
      category: "b2c social community workplace",
      year: 2020
    },
    {
      name: "PetConnect",
      status: "Active",
      description: "Free mobile app that connects pet owners to pet-friendly resources",
      category: "b2b marketplace pets",
      year: 2020
    },
    {
      name: "LACH",
      status: "Active",
      description: "Marketplace for preloved baby clothing and equipment",
      category: "b2c marketplace baby",
      year: 2020
    },
    // 2021 Batch
    {
      name: "Made For You",
      status: "Active",
      description: "Community for women, by women to inspire female leaders globally",
      category: "b2c social community marketplace",
      year: 2021
    },
    {
      name: "IF",
      status: "Active",
      description: "Actionable financial insights for young adults",
      category: "b2c fintech",
      year: 2021
    },
    // 2022 Batch
    {
      name: "Space Interactions",
      status: "Active",
      description: "Space debris management and visualization",
      category: "b2g b2b space govetech",
      year: 2022
    },
    // 2024 Batch
    {
      name: "FiftyOne AI",
      status: "In Development",
      description: "AI powered advocacy platform",
      category: "b2b justicetech ai",
      year: 2024
    },
    {
      name: "BillieBot",
      status: "In Development",
      description: "A virtual companion to help you navigate wellness and safety on campus",
      category: "b2b2c saas edtech healthtech",
      year: 2024
    },
    // 2025 Batch (Future)
    {
      name: "EEZZ Answers",
      status: "Coming Soon",
      description: "In person customer service AI tool",
      category: "b2b2c customer service ai",
      year: 2025
    },
    {
      name: "Grocery Magic",
      status: "Coming Soon",
      description: "Fun shopping and meal prep powered by ai magic",
      category: "b2c food ai",
      year: 2025
    },
    {
      name: "Round Table",
      status: "Coming Soon",
      description: "Future of meetups to track outcomes and build communities",
      category: "b2b community productivity ai",
      year: 2025
    },
    {
      name: "Halo AI",
      status: "Coming Soon",
      description: "HR communications agent to guard against bias and policy violations",
      category: "b2b hrtech ai",
      year: 2025
    }
  ];

  // Group startups by year
  const startupsByYear = portfolio.reduce<Record<number, StartupInfo[]>>((acc, startup) => {
    const year = startup.year;
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(startup);
    return acc;
  }, {});

  // Sort years in descending order
  const sortedYears = Object.keys(startupsByYear)
    .map(Number)
    .sort((a, b) => b - a);

  return (
    <section className="py-24 bg-[#222222] relative overflow-hidden">
      {/* Brutalist grid pattern */}
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#FF4D0005_1px,transparent_1px),linear-gradient(to_bottom,#FF4D0005_1px,transparent_1px)] bg-[size:4rem_4rem]" />

      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-7xl mx-auto"
        >
          <h2 className="text-5xl md:text-7xl font-mono font-light tracking-tighter text-white/90 mb-16">
            Portfolio
          </h2>

          {sortedYears.map((year) => (
            <PortfolioBatch 
              key={year} 
              year={year} 
              companies={startupsByYear[year]} 
            />
          ))}
        </motion.div>
      </div>
    </section>
  );
};
