
import { motion } from "framer-motion";
import { Card } from "@/components/ui/card";
import { Spotlight } from "@/components/ui/spotlight";
import { SplineScene } from "@/components/ui/splite";
import { Button } from "@/components/ui/button"; 
import { useNavigate } from "react-router-dom";
import { ArrowRight } from "lucide-react";

export const FlareSection = () => {
  const navigate = useNavigate();
  
  const handleNavigateToFlare = () => {
    navigate('/flare');
    // Ensure we scroll to the top of the page
    window.scrollTo(0, 0);
  };
  
  return (
    <div className="relative min-h-screen flex flex-col justify-center px-4 md:px-20">
      <Card className="w-full bg-black/[0.96] relative overflow-hidden">
        <Spotlight
          className="-top-40 left-0 md:left-60 md:-top-20"
          color="brand-orange"
        />
        
        <div className="flex flex-col md:flex-row h-full">
          <div className="flex-1 p-8 relative z-10 flex flex-col justify-center">
            <motion.h2 
              className="text-7xl md:text-8xl font-display font-bold text-white"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              GOT FLARE?
            </motion.h2>
            <motion.p 
              className="text-xl md:text-2xl font-mono text-brand-orange/80"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              Fast Launch and Rapid Evolution
            </motion.p>
            <motion.p 
              className="text-xl md:text-2xl text-gray-300 max-w-4xl leading-relaxed mt-6"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              FLARE is a proprietary method developed at Unstuck Labs combining AI engineers, in-house tools, and human developers to build web and mobile platforms at incredible speed and cost savings, while giving you <span className="text-brand-orange">100% code ownership</span>.
            </motion.p>
            
            {/* New CTA Button Section */}
            <motion.div
              className="mt-10"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
            >
              <Button 
                onClick={handleNavigateToFlare}
                className="bg-brand-orange hover:bg-brand-orange/90 text-white px-8 py-7 text-lg rounded-full group"
              >
                Discover FLARE Program
                <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </Button>
            </motion.div>
          </div>

          <div className="flex-1 relative min-h-[400px] md:min-h-[600px]">
            <SplineScene 
              scene="https://prod.spline.design/kZDDjO5HuC9GJUM2/scene.splinecode"
              className="w-full h-full"
            />
          </div>
        </div>
      </Card>
    </div>
  );
};
