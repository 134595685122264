
import { DynamicHeroContent } from "./hero/DynamicHeroContent";
import { HeroActions } from "./hero/HeroActions";
import { ClientSegment } from "@/types/segments";

interface HeroProps {
  clientSegment?: ClientSegment;
}

export const Hero = ({ clientSegment }: HeroProps) => {
  return (
    <div className="relative min-h-[calc(100vh-6rem)] flex items-center">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative py-16 sm:py-20 md:py-24">
        <div className="max-w-5xl mx-auto space-y-16">
          <DynamicHeroContent clientSegment={clientSegment} />
          <HeroActions clientSegment={clientSegment} />
        </div>
      </div>
    </div>
  );
};
