import { motion } from "framer-motion";
import { StartupInfo } from "@/types/ventures";
import { PortfolioCard } from "./PortfolioCard";

interface PortfolioBatchProps {
  year: number;
  companies: StartupInfo[];
}

export const PortfolioBatch = ({ year, companies }: PortfolioBatchProps) => {
  return (
    <div className="mb-16">
      <motion.h3 
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        className="text-4xl font-mono font-light text-white/90 mb-8 tracking-tighter"
      >
        {year === 2025 ? "Coming Soon" : `Batch ${year}`}
      </motion.h3>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {companies.map((company, index) => (
          <PortfolioCard key={company.name} company={company} index={index} />
        ))}
      </div>
    </div>
  );
};