
import { motion } from "framer-motion";

export const FlareAdvantages = () => {
  const advantages = [
    {
      title: "Speed Without Compromise",
      description: "Our proprietary method combines AI-powered development with human expertise to deliver production-ready code in a fraction of the time of traditional development.",
      points: [
        "Functional MVP in 1 week",
        "Iterative development process",
        "Quick feedback incorporation",
        "Continuous deployment"
      ]
    },
    {
      title: "Complete Ownership",
      description: "Unlike no-code solutions or other rapid development services, FLARE gives you 100% ownership of clean, well-architected codebase that can scale with your business.",
      points: [
        "Full source code access",
        "Industry standard architecture",
        "Comprehensive documentation",
        "Seamless handoff to your team"
      ]
    }
  ];

  return (
    <section className="py-24 px-4">
      <div className="max-w-6xl mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-4xl font-bold mb-16 text-center"
        >
          The FLARE Advantage
        </motion.h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {advantages.map((advantage, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
            >
              <h3 className="text-2xl font-bold mb-4">{advantage.title}</h3>
              <p className="text-zinc-400 mb-6">{advantage.description}</p>
              
              <ul className="space-y-3">
                {advantage.points.map((point, i) => (
                  <li key={i} className="flex items-start">
                    <span className="text-brand-orange mr-2">•</span>
                    <span className="text-zinc-300">{point}</span>
                  </li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};
