
import { motion } from "framer-motion";
import { CaseStudyCard } from "../CaseStudyCard";
import { Button } from "../ui/button";
import type { CaseStudy } from "@/types/caseStudy";
import { RefreshCcw } from "lucide-react";

interface CaseStudiesGridProps {
  studies: CaseStudy[];
  onClearFilters: () => void;
}

export const CaseStudiesGrid = ({ studies, onClearFilters }: CaseStudiesGridProps) => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  if (studies.length === 0) {
    return (
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center py-16 px-4 bg-accent/20 rounded-lg border border-white/5"
      >
        <h3 className="text-xl font-semibold mb-3">No case studies match your criteria</h3>
        <p className="text-muted-foreground mb-6">Try adjusting your filters to see more results.</p>
        <Button 
          variant="secondary" 
          className="group"
          onClick={onClearFilters}
        >
          <RefreshCcw className="mr-2 h-4 w-4 group-hover:rotate-90 transition-transform" />
          Reset Filters
        </Button>
      </motion.div>
    );
  }

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="grid md:grid-cols-2 lg:grid-cols-3 gap-8"
    >
      {studies.map((study, index) => (
        <motion.div
          key={index}
          variants={itemVariants}
          className="h-full"
        >
          <CaseStudyCard study={study} />
        </motion.div>
      ))}
    </motion.div>
  );
};
