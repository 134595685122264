import { motion } from "framer-motion";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "@/components/ui/sheet";
import { ScreenType } from "../types";
import { 
  HelpCircle, Book, Ticket, Headset, Bot, CreditCard, 
  Shield, Users, Calendar, Activity, Key, Link, FileText, 
  Package, Loader, CircleDot, AlertOctagon, LogOut,
  Home, Mail, Phone, MapPin, MessageSquare, Info
} from "lucide-react";
import { cn } from "@/lib/utils";

const screenCategories: {
  name: string;
  color: string;
  icon: any;
  screens: Array<{
    type: ScreenType;
    icon: any;
    label: string;
  }>;
}[] = [
  {
    name: 'Website Pages',
    color: '#FF4500',
    icon: Home,
    screens: [
      { type: 'landing_hero', icon: Home, label: 'Hero Section' },
      { type: 'landing_features', icon: Activity, label: 'Features Section' },
      { type: 'landing_cta', icon: MessageSquare, label: 'Call to Action' },
      { type: 'about_us', icon: Info, label: 'About Us' },
      { type: 'about_team', icon: Users, label: 'Team Section' },
      { type: 'contact_form', icon: Mail, label: 'Contact Form' },
      { type: 'contact_info', icon: Phone, label: 'Contact Info' },
      { type: 'contact_map', icon: MapPin, label: 'Location Map' },
    ]
  },
  {
    name: 'E-commerce',
    color: '#8B5CF6',
    icon: CreditCard,
    screens: [
      { type: 'ecom_products', icon: CreditCard, label: 'Product Listings' },
      { type: 'ecom_detail', icon: Package, label: 'Product Detail' },
      { type: 'ecom_cart', icon: CreditCard, label: 'Shopping Cart' },
      { type: 'ecom_checkout', icon: CreditCard, label: 'Checkout' },
      { type: 'ecom_orders', icon: Package, label: 'Orders' },
      { type: 'ecom_payments', icon: CreditCard, label: 'Payments' },
    ]
  },
  {
    name: 'Admin & Moderation',
    color: '#1EAEDB',
    icon: Shield,
    screens: [
      { type: 'admin_dashboard', icon: Shield, label: 'Admin Dashboard' },
      { type: 'admin_users', icon: Users, label: 'User Management' },
      { type: 'admin_roles', icon: Key, label: 'Roles & Permissions' },
      { type: 'admin_moderation', icon: Shield, label: 'Content Moderation' },
      { type: 'admin_logs', icon: Activity, label: 'System Logs' },
    ]
  },
  {
    name: 'Social Features',
    color: '#8B5CF6',
    icon: Users,
    screens: [
      { type: 'social_profile', icon: Users, label: 'Social Profile' },
      { type: 'social_friends', icon: Users, label: 'Friends System' },
      { type: 'social_reactions', icon: Activity, label: 'Reactions' },
      { type: 'social_events', icon: Calendar, label: 'Events' },
      { type: 'social_gamification', icon: Activity, label: 'Gamification' },
    ]
  },
  {
    name: 'Notifications',
    color: '#FF4500',
    icon: Activity,
    screens: [
      { type: 'notif_push', icon: Activity, label: 'Push Notifications' },
      { type: 'notif_email', icon: Activity, label: 'Email Notifications' },
      { type: 'notif_center', icon: Activity, label: 'Notification Center' },
      { type: 'notif_activity', icon: Activity, label: 'Activity Log' },
    ]
  },
  {
    name: 'Integration',
    color: '#1EAEDB',
    icon: Link,
    screens: [
      { type: 'integration_oauth', icon: Key, label: 'OAuth Setup' },
      { type: 'integration_webhooks', icon: Link, label: 'Webhooks' },
      { type: 'integration_services', icon: Link, label: 'Services' },
    ]
  },
  {
    name: 'Legal & Compliance',
    color: '#8B5CF6',
    icon: Shield,
    screens: [
      { type: 'legal_terms', icon: FileText, label: 'Terms & Conditions' },
      { type: 'legal_privacy', icon: Shield, label: 'Privacy Policy' },
      { type: 'legal_gdpr', icon: Shield, label: 'GDPR Compliance' },
      { type: 'legal_cookies', icon: Package, label: 'Cookie Settings' },
    ]
  },
  {
    name: 'Utility Screens',
    color: '#1EAEDB',
    icon: CircleDot,
    screens: [
      { type: 'utility_splash', icon: Loader, label: 'Splash Screen' },
      { type: 'utility_maintenance', icon: Loader, label: 'Maintenance' },
      { type: 'utility_404', icon: AlertOctagon, label: '404 Page' },
      { type: 'utility_expired', icon: LogOut, label: 'Session Expired' },
      { type: 'utility_status', icon: CircleDot, label: 'Status Page' },
    ]
  },
];

interface ScreensDrawerProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onScreenSelect: (screenType: ScreenType) => void;
}

export const ScreensDrawer = ({ open, onOpenChange, onScreenSelect }: ScreensDrawerProps) => {
  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent className="w-[400px] sm:w-[540px] overflow-y-auto bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
        <SheetHeader>
          <SheetTitle className="text-xl font-display">Add Screen</SheetTitle>
        </SheetHeader>
        <div className="grid gap-6 py-6">
          {screenCategories.map((category) => (
            <div 
              key={category.name} 
              className={cn(
                "space-y-3 rounded-lg p-4 transition-all duration-300",
                "hover:bg-accent/5 border-l-4"
              )}
              style={{ 
                borderColor: category.color,
                background: `linear-gradient(to right, ${category.color}05, transparent)`
              }}
            >
              <div className="flex items-center gap-2 mb-3">
                <category.icon 
                  className="h-5 w-5" 
                  style={{ color: category.color }} 
                />
                <h3 
                  className="text-sm font-medium" 
                  style={{ color: category.color }}
                >
                  {category.name}
                </h3>
              </div>
              <div className="grid grid-cols-2 gap-2">
                {category.screens.map(({ type, icon: Icon, label }) => (
                  <motion.button
                    key={type}
                    onClick={() => {
                      onScreenSelect(type);
                      onOpenChange(false);
                    }}
                    className={cn(
                      "flex items-center gap-3 p-4 rounded-lg border border-border",
                      "transition-all duration-200 ease-in-out",
                      "hover:bg-accent hover:scale-[1.02]",
                      "active:scale-[0.98]",
                      "focus:outline-none focus:ring-2 focus:ring-offset-2",
                      "focus:ring-offset-background focus:ring-ring"
                    )}
                    whileHover={{ 
                      backgroundColor: `${category.color}15`,
                      borderColor: category.color 
                    }}
                    style={{ 
                      '--hover-color': category.color,
                    } as React.CSSProperties}
                  >
                    <Icon className="h-5 w-5 text-muted-foreground" />
                    <span className="text-sm">{label}</span>
                  </motion.button>
                ))}
              </div>
            </div>
          ))}
        </div>
      </SheetContent>
    </Sheet>
  );
};
