import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Briefcase, Users } from "lucide-react";

interface ContactJobInfoProps {
  formData: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isEditing?: boolean;
}

export const ContactJobInfo = ({ formData, onChange, isEditing = true }: ContactJobInfoProps) => {
  if (!isEditing) {
    return (
      <div className="grid grid-cols-2 gap-6">
        <div className="space-y-2">
          <div className="flex items-center gap-2 text-muted-foreground">
            <Briefcase className="w-4 h-4" />
            <Label>Job Title</Label>
          </div>
          <p className="text-lg">{formData?.job_title || "Not specified"}</p>
        </div>

        <div className="space-y-2">
          <div className="flex items-center gap-2 text-muted-foreground">
            <Users className="w-4 h-4" />
            <Label>Department</Label>
          </div>
          <p className="text-lg">{formData?.department || "Not specified"}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-2 gap-6">
      <div className="space-y-2">
        <Label htmlFor="job_title" className="flex items-center gap-2">
          <Briefcase className="w-4 h-4" />
          Job Title
        </Label>
        <Input
          id="job_title"
          name="job_title"
          value={formData.job_title || ""}
          onChange={onChange}
          className="bg-background"
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="department" className="flex items-center gap-2">
          <Users className="w-4 h-4" />
          Department
        </Label>
        <Input
          id="department"
          name="department"
          value={formData.department || ""}
          onChange={onChange}
          className="bg-background"
        />
      </div>
    </div>
  );
};