import { useState } from "react";
import { ContactFormProps } from "../types/contactTypes";
import { ContactBasicInfo } from "./sections/ContactBasicInfo";
import { ContactJobInfo } from "./sections/ContactJobInfo";
import { ContactCompanySelect } from "./sections/ContactCompanySelect";
import { ContactSocialInfo } from "./sections/ContactSocialInfo";
import { ContactTags } from "./sections/ContactTags";

export const ContactForm = ({ formData, setFormData, isEditing }: ContactFormProps) => {
  if (!formData) {
    return <div className="p-4 text-center">Loading contact information...</div>;
  }

  const handleBasicInfoChange = (field: string, value: string) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleSocialProfileChange = (platform: string, value: string) => {
    setFormData({
      ...formData,
      social_profiles: {
        ...(formData.social_profiles || {}),
        [platform]: value,
      },
    });
  };

  const handleTagsChange = (tags: string[]) => {
    setFormData({
      ...formData,
      tags,
    });
  };

  const handleCompanyChange = (companyId: string) => {
    setFormData({
      ...formData,
      company_id: companyId,
    });
  };

  return (
    <div className="space-y-8">
      <ContactBasicInfo
        formData={formData}
        onChange={handleBasicInfoChange}
        isEditing={isEditing}
      />
      
      <ContactJobInfo
        formData={formData}
        onChange={handleBasicInfoChange}
        isEditing={isEditing}
      />

      <ContactCompanySelect
        value={formData.company_id || ""}
        onValueChange={handleCompanyChange}
        isEditing={isEditing}
      />

      <ContactSocialInfo
        formData={formData}
        onSocialProfileChange={handleSocialProfileChange}
        isEditing={isEditing}
      />

      <ContactTags
        formData={formData}
        onTagsChange={handleTagsChange}
        isEditing={isEditing}
      />
    </div>
  );
};