import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";

interface DealAdditionalInfoProps {
  formData: {
    expected_close_date: string;
    notes: string;
  };
  onChange: (field: string, value: any) => void;
}

export const DealAdditionalInfo = ({ formData, onChange }: DealAdditionalInfoProps) => {
  return (
    <>
      <div className="space-y-2">
        <Label htmlFor="expected_close_date">Expected Close Date</Label>
        <Input
          id="expected_close_date"
          type="date"
          value={formData.expected_close_date}
          onChange={(e) => onChange("expected_close_date", e.target.value)}
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="notes">Notes</Label>
        <Textarea
          id="notes"
          value={formData.notes}
          onChange={(e) => onChange("notes", e.target.value)}
          className="min-h-[100px]"
          placeholder="Add any relevant notes about this deal..."
        />
      </div>
    </>
  );
};