
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ArrowRight, Lightbulb } from "lucide-react";
import { VenturesHeroHeader } from "./sections/VenturesHeroHeader";

export const VenturesHero = () => {
  const navigate = useNavigate();

  return (
    <section className="min-h-[100dvh] relative bg-[#1A1F2C] overflow-hidden">
      {/* Premium animated background */}
      <motion.div
        className="absolute inset-0 bg-gradient-to-br from-[#1A1F2C] via-[#222222] to-[#1A1F2C]"
        animate={{
          background: [
            "linear-gradient(to right bottom, #1A1F2C 0%, #222222 50%, #1A1F2C 100%)",
            "linear-gradient(to right bottom, #222222 0%, #1A1F2C 50%, #222222 100%)",
          ],
        }}
        transition={{ duration: 10, repeat: Infinity, ease: "linear" }}
      />

      {/* Animated accent lines */}
      <div className="absolute inset-0">
        <motion.div
          className="absolute h-[2px] w-[200px] bg-gradient-to-r from-transparent via-[#FF4D00] to-transparent"
          style={{ top: '20%', left: '-10%' }}
          animate={{
            x: ["0%", "1500%"],
            opacity: [0, 1, 0]
          }}
          transition={{
            duration: 8,
            repeat: Infinity,
            ease: "linear"
          }}
        />
        <motion.div
          className="absolute h-[2px] w-[300px] bg-gradient-to-r from-transparent via-[#FF4D00] to-transparent"
          style={{ top: '60%', right: '-10%' }}
          animate={{
            x: ["0%", "-1000%"],
            opacity: [0, 1, 0]
          }}
          transition={{
            duration: 12,
            repeat: Infinity,
            ease: "linear",
            delay: 2
          }}
        />
      </div>

      {/* Content */}
      <div className="container relative mx-auto px-4 pt-32 pb-24">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="max-w-7xl mx-auto"
        >
          <div className="space-y-8 md:space-y-12">
            <VenturesHeroHeader />
            
            <motion.p 
              className="text-xl md:text-4xl text-white/70 font-mono font-light max-w-3xl ml-auto backdrop-blur-sm bg-white/5 p-6 transform -skew-x-6"
              initial={{ x: -50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              Building the next generation of transformative companies with founders who dare to be different.
            </motion.p>

            <div className="flex flex-wrap gap-6 pt-12">
              <Button 
                size="lg" 
                className="bg-[#FF4D00] hover:bg-[#FF4D00]/90 text-white border border-[#FF4D00] hover:border-[#FF4D00]/90 transition-all duration-300 text-lg px-8 py-6 skew-x-12 transform hover:-skew-x-12 group relative overflow-hidden font-light"
                onClick={() => navigate('/ventures/apply')}
              >
                <motion.span 
                  className="relative z-10 group-hover:animate-pulse flex items-center gap-2"
                  whileHover={{ scale: 1.1 }}
                >
                  Apply for Funding
                  <ArrowRight className="w-5 h-5" />
                </motion.span>
              </Button>
              <Button 
                size="lg" 
                variant="outline"
                className="text-[#FF4D00] hover:text-white border border-[#FF4D00] hover:bg-[#FF4D00]/10 text-lg px-8 py-6 -skew-x-12 transform hover:skew-x-12 backdrop-blur-sm group font-light"
                onClick={() => navigate('/ventures/portfolio')}
              >
                <span className="relative z-10 flex items-center gap-2">
                  Our Portfolio
                  <Lightbulb className="w-5 h-5" />
                </span>
              </Button>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};
