import { Button } from "@/components/ui/button";
import { ArrowLeft, Download } from "lucide-react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast";

interface GuideHeaderProps {
  title: string;
  type: string;
  handleDownload: () => void;
}

export const GuideHeader = ({ title, type, handleDownload }: GuideHeaderProps) => {
  const navigate = useNavigate();
  
  return (
    <div className="mb-12">
      <Button 
        variant="ghost" 
        onClick={() => navigate(-1)}
        className="mb-8 hover:bg-secondary/5 dark:hover:bg-secondary/10 dark:text-gray-300"
      >
        <ArrowLeft className="w-4 h-4 mr-2" />
        Back
      </Button>

      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center space-y-6"
      >
        <img 
          src="/lovable-uploads/f280b14b-8ed0-410b-9ff0-abb3f96b94a2.png" 
          alt="Unstuck Labs Logo" 
          className="h-12 mx-auto mb-8 dark:invert"
        />
        <h1 className="text-4xl font-display font-bold text-primary dark:text-white">
          {title}
        </h1>
        
        <Button 
          onClick={handleDownload}
          className="bg-secondary hover:bg-secondary/90 text-white dark:bg-secondary/90 dark:hover:bg-secondary"
        >
          <Download className="w-4 h-4 mr-2" />
          Save Guide
        </Button>
      </motion.div>
    </div>
  );
};