import { Contact } from "@/components/Contact";
import { Header } from "@/components/Header";
import { Footer } from "@/components/Footer";
import { Helmet } from "react-helmet";

const ContactPage = () => {
  return (
    <main className="min-h-screen bg-white">
      <Helmet>
        <title>Contact Us - Unstuck Labs</title>
        <meta name="description" content="Get in touch with Unstuck Labs for your next digital project." />
      </Helmet>
      <Header />
      <div className="pt-20">
        <Contact />
      </div>
      <Footer />
    </main>
  );
};

export default ContactPage;