
import { Header } from "@/components/Header";
import { WorkspaceLayout } from "@/components/workspace/WorkspaceLayout";

export default function WorkspacePage() {
  return (
    <>
      <Header />
      <WorkspaceLayout />
    </>
  );
}
