
import { useState } from 'react';
import { Panel } from '@xyflow/react';
import { Button } from '@/components/ui/button';
import { Screen } from '../types';
import { toast } from 'sonner';
import { 
  Smartphone, 
  Monitor, 
  Tablet, 
  RefreshCw, 
  Download, 
  Copy, 
  Play, 
  ThumbsUp, 
  ThumbsDown 
} from 'lucide-react';

interface WireframeControlsProps {
  screenId: string;
  nodes: any[];
  onNodesChange: any;
}

export const WireframeControls = ({ screenId, nodes, onNodesChange }: WireframeControlsProps) => {
  const [viewMode, setViewMode] = useState<'mobile' | 'tablet' | 'desktop'>('mobile');
  const [isRefining, setIsRefining] = useState(false);
  
  // Find the selected screen node
  const screenNode = nodes.find(node => node.id === screenId) as Screen;
  
  if (!screenNode) {
    return null;
  }
  
  const wireframe = screenNode.data.wireframe;
  
  const handleViewModeChange = (mode: 'mobile' | 'tablet' | 'desktop') => {
    setViewMode(mode);
    // In a real implementation, this would adjust the wireframe view
    toast.info(`Switched to ${mode} view`);
  };
  
  const handleRefineWireframe = async () => {
    if (!wireframe) {
      toast.error("No wireframe to refine. Generate one first.");
      return;
    }
    
    setIsRefining(true);
    
    try {
      // This would call an AI service to refine the wireframe
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      // Update the node with a "refined" wireframe (mock implementation)
      const updatedNodes = nodes.map(node => {
        if (node.id === screenId) {
          return {
            ...node,
            data: {
              ...node.data,
              wireframe: {
                ...node.data.wireframe,
                lastUpdated: new Date().toISOString(),
                // In a real implementation, this would be the refined elements
              }
            }
          };
        }
        return node;
      });
      
      onNodesChange([{ 
        type: 'change', 
        item: updatedNodes.find(n => n.id === screenId),
        id: screenId 
      }]);
      
      toast.success("Wireframe refined successfully");
    } catch (error) {
      console.error("Error refining wireframe:", error);
      toast.error("Failed to refine wireframe");
    } finally {
      setIsRefining(false);
    }
  };
  
  const handleDownloadWireframe = () => {
    if (!wireframe) {
      toast.error("No wireframe to download. Generate one first.");
      return;
    }
    
    // In a real implementation, this would download the wireframe as an image
    toast.success("Wireframe downloaded");
  };
  
  const handleCopyCode = () => {
    if (!wireframe) {
      toast.error("No wireframe to copy code from. Generate one first.");
      return;
    }
    
    // In a real implementation, this would copy generated code to clipboard
    navigator.clipboard.writeText(`<div>Generated code for ${screenNode.data.label}</div>`);
    toast.success("Code copied to clipboard");
  };
  
  const handlePreviewWireframe = () => {
    if (!wireframe) {
      toast.error("No wireframe to preview. Generate one first.");
      return;
    }
    
    // In a real implementation, this would open a preview in a new window/modal
    toast.success("Preview opened in new window");
  };
  
  const handleFeedback = (type: 'positive' | 'negative') => {
    if (!wireframe) {
      toast.error("No wireframe to provide feedback on. Generate one first.");
      return;
    }
    
    // In a real implementation, this would send feedback to improve the AI
    toast.success(`${type === 'positive' ? 'Positive' : 'Negative'} feedback recorded`);
  };
  
  return (
    <Panel position="bottom-center" className="bg-background/80 backdrop-blur-sm p-4 rounded-t-lg border border-border shadow-md">
      <div className="flex flex-col space-y-4 w-full max-w-lg">
        {wireframe && wireframe.generatedImage ? (
          <div className="flex flex-col items-center">
            <div className="text-xs text-muted-foreground mb-2">
              {screenNode.data.label} Wireframe
            </div>
            <img 
              src={wireframe.generatedImage} 
              alt={`Wireframe for ${screenNode.data.label}`}
              className="border border-border rounded-md mb-2 max-h-60 object-contain"
            />
            <div className="flex space-x-2 mb-2">
              <Button
                size="sm"
                variant={viewMode === 'mobile' ? 'default' : 'outline'}
                onClick={() => handleViewModeChange('mobile')}
              >
                <Smartphone className="h-4 w-4" />
              </Button>
              <Button
                size="sm"
                variant={viewMode === 'tablet' ? 'default' : 'outline'}
                onClick={() => handleViewModeChange('tablet')}
              >
                <Tablet className="h-4 w-4" />
              </Button>
              <Button
                size="sm"
                variant={viewMode === 'desktop' ? 'default' : 'outline'}
                onClick={() => handleViewModeChange('desktop')}
              >
                <Monitor className="h-4 w-4" />
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center h-20 border border-dashed border-border rounded-md">
            <span className="text-sm text-muted-foreground">
              Select a screen and generate a wireframe
            </span>
          </div>
        )}
        
        <div className="flex space-x-2">
          <Button
            size="sm"
            variant="outline"
            onClick={handleRefineWireframe}
            disabled={!wireframe || isRefining}
            className="flex-1"
          >
            {isRefining ? (
              <RefreshCw className="h-4 w-4 mr-1 animate-spin" />
            ) : (
              <RefreshCw className="h-4 w-4 mr-1" />
            )}
            Refine
          </Button>
          
          <Button
            size="sm"
            variant="outline"
            onClick={handleDownloadWireframe}
            disabled={!wireframe}
            className="flex-1"
          >
            <Download className="h-4 w-4 mr-1" />
            Download
          </Button>
          
          <Button
            size="sm"
            variant="outline"
            onClick={handleCopyCode}
            disabled={!wireframe}
            className="flex-1"
          >
            <Copy className="h-4 w-4 mr-1" />
            Get Code
          </Button>
          
          <Button
            size="sm"
            variant="outline"
            onClick={handlePreviewWireframe}
            disabled={!wireframe}
            className="flex-1"
          >
            <Play className="h-4 w-4 mr-1" />
            Preview
          </Button>
        </div>
        
        {wireframe && (
          <div className="flex justify-center space-x-4">
            <Button
              size="sm"
              variant="ghost"
              onClick={() => handleFeedback('positive')}
              className="text-green-500"
            >
              <ThumbsUp className="h-4 w-4 mr-1" />
              Looks Good
            </Button>
            
            <Button
              size="sm"
              variant="ghost"
              onClick={() => handleFeedback('negative')}
              className="text-rose-500"
            >
              <ThumbsDown className="h-4 w-4 mr-1" />
              Needs Work
            </Button>
          </div>
        )}
      </div>
    </Panel>
  );
};
