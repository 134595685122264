
import { supabase } from "@/integrations/supabase/client";
import html2pdf from "html2pdf.js";
import { toast } from "sonner";

export const generatePDF = async (analysisData: any, fileName: string) => {
  try {
    toast.info("Preparing your report for download...");
    
    // Call the Supabase edge function to generate HTML content
    const { data, error } = await supabase.functions.invoke("generate-pdf-quote", {
      body: { analysis: analysisData }
    });
    
    if (error) {
      console.error("Edge function error:", error);
      throw new Error(`Error generating report: ${error.message}`);
    }
    
    if (!data || !data.content) {
      console.error("No content received from edge function");
      throw new Error("Failed to generate report content");
    }
    
    console.log("Received HTML content length:", data.content.length);
    
    // Create a temporary container for the HTML content
    const container = document.createElement("div");
    container.innerHTML = data.content;
    container.id = "pdf-container";
    
    // Make it visible but hidden and add to DOM with specific styling for PDF generation
    container.style.position = "absolute";
    container.style.width = "800px";
    container.style.opacity = "0";
    container.style.pointerEvents = "none";
    container.style.zIndex = "-1000";
    document.body.appendChild(container);
    
    // Log container info for debugging
    console.log("Container added to DOM with dimensions:", 
      container.offsetWidth, "x", container.offsetHeight);
    
    // Wait a moment for the DOM to render the content
    await new Promise(resolve => setTimeout(resolve, 500));
    
    // Configure pdf options
    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5],
      filename: `${fileName}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { 
        scale: 2, 
        useCORS: true, 
        logging: true,
        windowWidth: 800,
        letterRendering: true
      },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" }
    };
    
    try {
      // Generate the PDF
      const pdfWorker = html2pdf().from(container).set(opt);
      
      // Add a worker callback to track progress
      pdfWorker.save().then(() => {
        console.log("PDF generated successfully");
        toast.success("Report downloaded successfully");
        
        // Clean up - remove container after successful PDF generation
        setTimeout(() => {
          if (document.body.contains(container)) {
            document.body.removeChild(container);
          }
        }, 1000);
      });
    } catch (pdfError) {
      console.error("PDF generation error details:", pdfError);
      toast.error("Failed to generate PDF");
      
      // Cleanup on error
      if (document.body.contains(container)) {
        document.body.removeChild(container);
      }
    }
  } catch (err) {
    console.error("Error in PDF generation process:", err);
    toast.error("Failed to generate report");
  }
};
