import { Card } from "@/components/ui/card";
import { useQuery } from "@tanstack/react-query";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { supabase } from "@/integrations/supabase/client";

export const LeadAnalytics = () => {
  const { data: leadStats } = useQuery({
    queryKey: ["lead-stats"],
    queryFn: async () => {
      const { data: leads, error } = await supabase
        .from("leads")
        .select("created_at, status")
        .order("created_at");
      
      if (error) throw error;

      // Process leads data for visualization
      const stats = leads.reduce((acc: any, lead) => {
        const date = new Date(lead.created_at).toLocaleDateString();
        if (!acc[date]) {
          acc[date] = { date, total: 0, qualified: 0, converted: 0 };
        }
        acc[date].total++;
        if (lead.status === "qualified") acc[date].qualified++;
        if (lead.status === "converted") acc[date].converted++;
        return acc;
      }, {});

      return Object.values(stats);
    },
  });

  const { data: conversionRates } = useQuery({
    queryKey: ["conversion-rates"],
    queryFn: async () => {
      const { data: leads, error } = await supabase
        .from("leads")
        .select("status");
      
      if (error) throw error;

      const total = leads.length;
      const qualified = leads.filter(l => l.status === "qualified").length;
      const converted = leads.filter(l => l.status === "converted").length;

      return {
        qualificationRate: (qualified / total) * 100,
        conversionRate: (converted / qualified) * 100,
      };
    },
  });

  return (
    <div className="space-y-6">
      <div className="grid gap-6 md:grid-cols-2">
        <Card className="p-6">
          <h3 className="text-lg font-semibold mb-4">Lead Generation Trend</h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={leadStats}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="total" stroke="#8884d8" name="Total Leads" />
                <Line type="monotone" dataKey="qualified" stroke="#82ca9d" name="Qualified" />
                <Line type="monotone" dataKey="converted" stroke="#ffc658" name="Converted" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Card>

        <Card className="p-6">
          <h3 className="text-lg font-semibold mb-4">Conversion Metrics</h3>
          <div className="space-y-6">
            <div>
              <div className="flex justify-between mb-2">
                <span>Qualification Rate</span>
                <span>{conversionRates?.qualificationRate.toFixed(1)}%</span>
              </div>
              <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-blue-500 rounded-full"
                  style={{ width: `${conversionRates?.qualificationRate || 0}%` }}
                />
              </div>
            </div>
            
            <div>
              <div className="flex justify-between mb-2">
                <span>Conversion Rate</span>
                <span>{conversionRates?.conversionRate.toFixed(1)}%</span>
              </div>
              <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-green-500 rounded-full"
                  style={{ width: `${conversionRates?.conversionRate || 0}%` }}
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};