
import { Link } from "react-router-dom";
import { Home, Building, Building2, Network, Send, Rocket, Settings, Briefcase, LayoutDashboard, Calendar, Wrench } from "lucide-react";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { useAuth } from "@/contexts/AuthContext";
import { toolItems } from "../nav-items";

interface NavigationLinksProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  getSegmentClass: (path: string) => string;
  session: any;
}

export const NavigationLinks = ({ isOpen, setIsOpen, getSegmentClass, session }: NavigationLinksProps) => {
  const { userRoles } = useAuth();

  return (
    <nav className="space-y-2">
      <Link
        to="/"
        onClick={() => setIsOpen(false)}
        className={getSegmentClass('/')}
      >
        <Home className="h-4 w-4" />
        Home
      </Link>

      <Link
        to="/startup"
        onClick={() => setIsOpen(false)}
        className={getSegmentClass('/startup')}
      >
        <Building className="h-4 w-4" />
        Startup
      </Link>

      <Link
        to="/enterprise"
        onClick={() => setIsOpen(false)}
        className={getSegmentClass('/enterprise')}
      >
        <Network className="h-4 w-4" />
        Enterprise
      </Link>

      <Link
        to="/government"
        onClick={() => setIsOpen(false)}
        className={getSegmentClass('/government')}
      >
        <Building2 className="h-4 w-4" />
        Government
      </Link>

      <Link
        to="/flare"
        onClick={() => setIsOpen(false)}
        className={getSegmentClass('/flare')}
      >
        <Send className="h-4 w-4" />
        Got Flare?
      </Link>

      <Link
        to="/about"
        onClick={() => setIsOpen(false)}
        className={getSegmentClass('/about')}
      >
        <Calendar className="h-4 w-4" />
        100 Meetups
      </Link>

      {/* Tools Section */}
      <div className="pt-1 pb-1">
        <h3 className="px-4 text-xs font-semibold text-gray-500 uppercase tracking-wider">
          Tools
        </h3>
      </div>

      {toolItems.map((item, index) => (
        <Link
          key={index}
          to={item.href}
          onClick={() => setIsOpen(false)}
          className={getSegmentClass(item.href)}
        >
          {item.icon && <item.icon className="h-4 w-4" />}
          {item.title}
        </Link>
      ))}

      <Link
        to="/ventures"
        onClick={() => setIsOpen(false)}
        className={getSegmentClass('/ventures')}
      >
        <Rocket className="h-4 w-4" />
        Ventures
      </Link>

      {session && (
        <>
          <Link
            to="/admin"
            onClick={() => setIsOpen(false)}
            className={getSegmentClass('/admin')}
          >
            <LayoutDashboard className="h-4 w-4" />
            Admin Dashboard
          </Link>

          <Link
            to="/employee/dashboard"
            onClick={() => setIsOpen(false)}
            className={getSegmentClass('/employee')}
          >
            <Building2 className="h-4 w-4" />
            Employee Portal
          </Link>

          <Link
            to="/workspace"
            onClick={() => setIsOpen(false)}
            className={getSegmentClass('/workspace')}
          >
            <Briefcase className="h-4 w-4" />
            Workspace
          </Link>

          <Link
            to="/settings"
            onClick={() => setIsOpen(false)}
            className={getSegmentClass('/settings')}
          >
            <Settings className="h-4 w-4" />
            Settings
          </Link>
        </>
      )}
    </nav>
  );
};
