
import { Card } from '@/components/ui/card';
import { Progress } from '@/components/ui/progress';
import { Skeleton } from '@/components/ui/skeleton';
import type { Database } from '@/types/supabase';

type WorkspaceAnalytics = Database['public']['Tables']['workspace_analytics']['Row'];

interface ProgressTrackerProps {
  analytics: WorkspaceAnalytics | null;
  isLoading: boolean;
}

export function ProgressTracker({ analytics, isLoading }: ProgressTrackerProps) {
  const taskCompletionRate = analytics ? 
    Math.round((analytics.completed_tasks || 0) / (analytics.total_tasks || 1) * 100) : 0;

  return (
    <Card className="p-6">
      <h3 className="text-lg font-medium mb-4">Overall Progress</h3>
      <div className="space-y-2">
        <div className="flex justify-between text-sm">
          <span>Task Completion</span>
          <span>{taskCompletionRate}%</span>
        </div>
        {isLoading ? (
          <Skeleton className="h-4 w-full" />
        ) : (
          <Progress value={taskCompletionRate} />
        )}
      </div>
    </Card>
  );
}
