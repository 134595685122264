
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { useAuth } from '@/contexts/AuthContext';

interface CreateItemFormData {
  name: string;
  description?: string;
}

interface CreateItemDialogProps {
  type: 'workspace' | 'space' | 'folder' | 'list';
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onSuccess: () => void;
  workspaceId?: string;
  spaceId?: string;
  folderId?: string;
}

export function CreateItemDialog({ 
  type, 
  isOpen, 
  onOpenChange, 
  onSuccess,
  workspaceId,
  spaceId,
  folderId 
}: CreateItemDialogProps) {
  const { register, handleSubmit, reset, formState: { isSubmitting } } = useForm<CreateItemFormData>();
  const { session } = useAuth();

  const onSubmit = async (data: CreateItemFormData) => {
    try {
      if (!session?.user?.id && type === 'workspace') {
        throw new Error('You must be logged in to create a workspace');
      }

      const table = type === 'workspace' ? 'workspaces' :
                    type === 'space' ? 'spaces' :
                    type === 'folder' ? 'folders' : 'lists';

      // Add the appropriate parent ID based on the type
      const insertData = {
        ...data,
        ...(type === 'space' && { workspace_id: workspaceId }),
        ...(type === 'folder' && { space_id: spaceId }),
        ...(type === 'list' && { folder_id: folderId })
      };

      const { error } = await supabase
        .from(table)
        .insert([insertData]);

      if (error) throw error;

      toast.success(`${type} created successfully`);
      reset();
      onSuccess();
    } catch (error) {
      console.error('Error creating item:', error);
      toast.error(`Failed to create ${type}`);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create New {type.charAt(0).toUpperCase() + type.slice(1)}</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div>
            <Input
              placeholder="Name"
              {...register('name', { required: true })}
            />
          </div>
          <div>
            <Textarea
              placeholder="Description (optional)"
              {...register('description')}
            />
          </div>
          <Button type="submit" disabled={isSubmitting}>
            Create {type.charAt(0).toUpperCase() + type.slice(1)}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
}
