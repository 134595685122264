
import { useParams } from 'react-router-dom';
import { Card } from '@/components/ui/card';
import { supabase } from '@/integrations/supabase/client';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import type { Database } from '@/types/supabase';
import { toast } from 'sonner';
import { useAuth } from '@/contexts/AuthContext';
import { TaskMetrics } from './dashboard/TaskMetrics';
import { ProgressTracker } from './dashboard/ProgressTracker';
import { ActivityFeed } from './dashboard/ActivityFeed';
import { WorkspaceChat } from './dashboard/WorkspaceChat';
import type { Json } from '@/types/supabase';

type WorkspaceMessage = Database['public']['Tables']['workspace_chat_messages']['Row'];
type WorkspaceAnalytics = Database['public']['Tables']['workspace_analytics']['Row'];
type Activity = {
  description: string;
  timestamp: string;
};

export function WorkspaceDashboard() {
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const { session } = useAuth();
  const queryClient = useQueryClient();
  
  const { data: tasks = [], isLoading: isLoadingTasks } = useQuery({
    queryKey: ['workspace-tasks', workspaceId],
    queryFn: async () => {
      if (!workspaceId) throw new Error('No workspace ID provided');
      
      const { data: spaces, error: spacesError } = await supabase
        .from('spaces')
        .select('id')
        .eq('workspace_id', workspaceId);
      
      if (spacesError) throw spacesError;
      
      const spaceIds = spaces.map(space => space.id);
      
      const { data: folders, error: foldersError } = await supabase
        .from('folders')
        .select('id')
        .in('space_id', spaceIds);
      
      if (foldersError) throw foldersError;
      
      const folderIds = folders.map(folder => folder.id);
      
      const { data: lists, error: listsError } = await supabase
        .from('lists')
        .select('id')
        .in('folder_id', folderIds);
      
      if (listsError) throw listsError;
      
      const listIds = lists.map(list => list.id);
      
      const { data: tasks, error: tasksError } = await supabase
        .from('tasks')
        .select('*')
        .in('list_id', listIds);
      
      if (tasksError) throw tasksError;
      
      return tasks;
    },
    enabled: !!workspaceId,
  });

  const { data: analytics, isLoading, error } = useQuery({
    queryKey: ['workspace-analytics', workspaceId, tasks],
    queryFn: async () => {
      if (!workspaceId) throw new Error('No workspace ID provided');
      
      const completedTasks = tasks.filter(task => task.status === 'done').length;
      const inProgressTasks = tasks.filter(task => task.status === 'in_progress').length;
      const blockedTasks = tasks.filter(task => task.status === 'blocked').length;
      const totalTasks = tasks.length;

      const recentActivities = tasks
        .sort((a, b) => new Date(b.updated_at || '').getTime() - new Date(a.updated_at || '').getTime())
        .slice(0, 5)
        .map(task => ({
          description: `Task "${task.title}" ${task.status}`,
          timestamp: task.updated_at || task.created_at
        } as Activity)) as Json[];

      // First, try to get existing analytics
      const { data: existingAnalytics } = await supabase
        .from('workspace_analytics')
        .select()
        .eq('workspace_id', workspaceId)
        .single();

      const analyticsData = {
        workspace_id: workspaceId,
        total_projects: 0,
        completed_tasks: completedTasks,
        in_progress_tasks: inProgressTasks,
        blocked_tasks: blockedTasks,
        total_tasks: totalTasks,
        recent_activities: recentActivities
      };

      let result;
      
      if (existingAnalytics) {
        // Update existing record
        const { data, error } = await supabase
          .from('workspace_analytics')
          .update(analyticsData)
          .eq('workspace_id', workspaceId)
          .select()
          .single();
        
        if (error) throw error;
        result = data;
      } else {
        // Insert new record
        const { data, error } = await supabase
          .from('workspace_analytics')
          .insert(analyticsData)
          .select()
          .single();
        
        if (error) throw error;
        result = data;
      }
      
      return result;
    },
    enabled: !!workspaceId && !!tasks,
  });

  const { data: messages = [], isLoading: isLoadingMessages } = useQuery({
    queryKey: ['workspace-messages', workspaceId],
    queryFn: async () => {
      if (!workspaceId) throw new Error('No workspace ID provided');
      
      const { data, error } = await supabase
        .from('workspace_chat_messages')
        .select('*')
        .eq('workspace_id', workspaceId)
        .order('created_at', { ascending: false })
        .limit(50);
      
      if (error) throw error;
      return data as WorkspaceMessage[];
    },
    enabled: !!workspaceId,
  });

  const sendMessageMutation = useMutation({
    mutationFn: async (content: string) => {
      if (!workspaceId || !session?.user?.id) throw new Error('Not authenticated');
      
      const { error } = await supabase
        .from('workspace_chat_messages')
        .insert({
          workspace_id: workspaceId,
          content,
          user_id: session.user.id,
          type: 'user'
        });
      
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['workspace-messages', workspaceId] });
    },
    onError: (error) => {
      toast.error('Failed to send message');
      console.error('Error sending message:', error);
    },
  });

  if (error) {
    return (
      <div className="p-6">
        <Card className="p-6 bg-red-50 border-red-200">
          <h2 className="text-lg font-semibold text-red-700">Error loading dashboard</h2>
          <p className="text-red-600">{(error as Error).message}</p>
        </Card>
      </div>
    );
  }

  return (
    <div className="p-6 space-y-6">
      <h1 className="text-2xl font-bold mb-6">Workspace Overview</h1>
      
      <TaskMetrics analytics={analytics} isLoading={isLoading} />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-6">
          <ProgressTracker analytics={analytics} isLoading={isLoading} />
          <ActivityFeed analytics={analytics} isLoading={isLoading} />
        </div>

        <WorkspaceChat 
          messages={messages}
          isLoading={isLoadingMessages}
          onSendMessage={(content) => sendMessageMutation.mutate(content)}
          isSending={sendMessageMutation.isPending}
        />
      </div>
    </div>
  );
}
