import { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { Checkbox } from "@/components/ui/checkbox";
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import type { PointTemplate, RawPointTemplate } from '../types/points';

interface AddFeatureDialogProps {
  onFeatureAdd: (feature: {
    name: string;
    description: string;
    priority: 'high' | 'medium' | 'low';
    points: number;
  }) => void;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const AddFeatureDialog = ({ onFeatureAdd, open, onOpenChange }: AddFeatureDialogProps) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [priority, setPriority] = useState<'high' | 'medium' | 'low'>('medium');
  const [points, setPoints] = useState<number>(0);
  const [pointTemplates, setPointTemplates] = useState<PointTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const [selectedMultipliers, setSelectedMultipliers] = useState<string[]>([]);

  useEffect(() => {
    const fetchPointTemplates = async () => {
      const { data, error } = await supabase
        .from('feature_point_templates')
        .select('*');
      
      if (error) {
        toast.error('Failed to load point templates');
        return;
      }

      // Convert the raw data to PointTemplate type
      const convertedData: PointTemplate[] = (data as RawPointTemplate[]).map(template => ({
        ...template,
        multipliers: template.multipliers as Record<string, number>
      }));

      setPointTemplates(convertedData);
    };

    fetchPointTemplates();
  }, []);

  const calculatePoints = (template: PointTemplate, multipliers: string[]) => {
    let total = template.base_points;
    multipliers.forEach(mult => {
      if (template.multipliers && template.multipliers[mult]) {
        total *= template.multipliers[mult];
      }
    });
    return Math.round(total);
  };

  const handleTemplateChange = (templateId: string) => {
    setSelectedTemplate(templateId);
    const template = pointTemplates.find(t => t.id === templateId);
    if (template) {
      setPoints(template.base_points);
      setSelectedMultipliers([]);
    }
  };

  const handleMultiplierChange = (multiplier: string, checked: boolean) => {
    const newMultipliers = checked
      ? [...selectedMultipliers, multiplier]
      : selectedMultipliers.filter(m => m !== multiplier);
    
    setSelectedMultipliers(newMultipliers);
    
    const template = pointTemplates.find(t => t.id === selectedTemplate);
    if (template) {
      setPoints(calculatePoints(template, newMultipliers));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onFeatureAdd({ 
      name, 
      description, 
      priority,
      points
    });
    onOpenChange(false);
    setName('');
    setDescription('');
    setPriority('medium');
    setPoints(0);
    setSelectedTemplate('');
    setSelectedMultipliers([]);
  };

  const selectedTemplateData = pointTemplates.find(t => t.id === selectedTemplate);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <form onSubmit={handleSubmit}>
          <DialogHeader>
            <DialogTitle>Add Custom Feature</DialogTitle>
            <DialogDescription>
              Define a new custom feature for your project. Add details and set its complexity level.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid gap-2">
              <Label htmlFor="name">Feature Name</Label>
              <Input
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter feature name"
                required
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="description">Description</Label>
              <Textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Describe the feature"
                required
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="priority">Priority</Label>
              <Select value={priority} onValueChange={(value: 'high' | 'medium' | 'low') => setPriority(value)}>
                <SelectTrigger>
                  <SelectValue placeholder="Select priority" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="high">High Priority</SelectItem>
                  <SelectItem value="medium">Medium Priority</SelectItem>
                  <SelectItem value="low">Low Priority</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="grid gap-2">
              <Label>Feature Type</Label>
              <Select value={selectedTemplate} onValueChange={handleTemplateChange}>
                <SelectTrigger>
                  <SelectValue placeholder="Select feature type" />
                </SelectTrigger>
                <SelectContent>
                  {pointTemplates.map((template) => (
                    <SelectItem key={template.id} value={template.id}>
                      {template.name} ({template.base_points} pts)
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            {selectedTemplateData && selectedTemplateData.multipliers && (
              <div className="grid gap-2">
                <Label>Complexity Factors</Label>
                <div className="space-y-2">
                  {Object.entries(selectedTemplateData.multipliers).map(([key, value]) => (
                    <div key={key} className="flex items-center space-x-2">
                      <Checkbox
                        id={key}
                        checked={selectedMultipliers.includes(key)}
                        onCheckedChange={(checked) => 
                          handleMultiplierChange(key, checked as boolean)
                        }
                      />
                      <label htmlFor={key} className="text-sm">
                        {key.charAt(0).toUpperCase() + key.slice(1)} (x{value})
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="grid gap-2">
              <Label htmlFor="points">Points</Label>
              <Input
                id="points"
                type="number"
                value={points}
                onChange={(e) => setPoints(Number(e.target.value))}
                placeholder="Enter points"
                required
              />
            </div>
          </div>
          <DialogFooter>
            <Button type="submit">Add Feature</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};