import { Routes, Route, Navigate } from 'react-router-dom';
import { EmployeeLayout } from '@/components/employee/layout/EmployeeLayout';
import { EmployeeDashboard } from '@/components/employee/EmployeeDashboard';
import { EmployeeProfile } from '@/components/employee/EmployeeProfile';
import { EmployeeSchedule } from '@/components/employee/EmployeeSchedule';
import { EmployeeTimeOff } from '@/components/employee/EmployeeTimeOff';
import { EmployeeGoals } from '@/components/employee/EmployeeGoals';
import { EmployeeHandbook } from '@/components/employee/EmployeeHandbook';
import { EmployeeForm } from '@/components/employee/EmployeeForm';

const EmployeePortalPage = () => {
  return (
    <EmployeeLayout>
      <Routes>
        <Route path="/" element={<Navigate to="dashboard" replace />} />
        <Route path="dashboard" element={<EmployeeDashboard />} />
        <Route path="profile" element={<EmployeeProfile />} />
        <Route path="profile/:id" element={<EmployeeProfile />} />
        <Route path="schedule" element={<EmployeeSchedule />} />
        <Route path="time-off" element={<EmployeeTimeOff />} />
        <Route path="goals" element={<EmployeeGoals />} />
        <Route path="handbook" element={<EmployeeHandbook />} />
        <Route path="new" element={<EmployeeForm />} />
        <Route path="edit/:id" element={<EmployeeForm />} />
        <Route path="*" element={<Navigate to="dashboard" replace />} />
      </Routes>
    </EmployeeLayout>
  );
};

export default EmployeePortalPage;