import { BudgetTier } from "../../types";
import { Rocket, Scale, Trophy } from "lucide-react";

export const getDefaultTiers = (complexity: 'low' | 'medium' | 'high'): BudgetTier[] => {
  const baseTiers: BudgetTier[] = [
    {
      range: complexity === 'low' ? "$5K - $15K" : complexity === 'medium' ? "$15K - $30K" : "$30K - $50K",
      description: "Perfect for validating your idea with a basic version that demonstrates core functionality.",
      approach: "no-code",
      platforms: ["Webflow", "WordPress", "Bubble"],
      features: [
        "Core functionality implementation",
        "Basic user interface",
        "Essential features only",
        "Quick market validation"
      ],
      limitations: [
        "Limited customization",
        "Basic feature set",
        "May need rebuilding for scale"
      ],
      icon: Rocket,
      recommended: complexity === 'low'
    },
    {
      range: complexity === 'low' ? "$15K - $30K" : complexity === 'medium' ? "$30K - $60K" : "$50K - $100K",
      description: "A production-ready solution with essential features and scalable architecture.",
      approach: "low-code",
      platforms: ["Next.js", "Node.js", "PostgreSQL"],
      features: [
        "Full feature implementation",
        "Polished user interface",
        "Content management system",
        "API integrations"
      ],
      limitations: [
        "Moderate customization",
        "Standard feature set",
        "Basic scalability"
      ],
      icon: Scale,
      recommended: complexity === 'medium'
    },
    {
      range: complexity === 'low' ? "$30K - $50K" : complexity === 'medium' ? "$60K - $100K" : "$100K+",
      description: "Fully customized solution with advanced features and complete control.",
      approach: "custom",
      platforms: ["Custom Development", "Scalable Architecture", "Advanced Integrations"],
      features: [
        "Unique feature set",
        "Custom integrations",
        "Advanced security",
        "Scalable infrastructure"
      ],
      advantages: [
        "Complete customization",
        "Superior performance",
        "Full ownership"
      ],
      icon: Trophy,
      recommended: complexity === 'high'
    }
  ];

  return baseTiers;
};