import { motion, useScroll, useTransform, useSpring } from "framer-motion";
import { useRef, useState } from "react";
import { Code2, Cpu, Globe, Rocket, Zap } from "lucide-react";

interface Project {
  id: number;
  name: string;
  description: string;
  year: number;
  metrics: { label: string; value: string }[];
  tech: string[];
  icon: typeof Code2;
  color: string;
}

const projects: Project[] = [
  {
    id: 1,
    name: "QuantumLeap AI",
    description: "Revolutionary quantum computing interface",
    year: 2023,
    metrics: [
      { label: "Processing Speed", value: "+840%" },
      { label: "Algorithm Efficiency", value: "99.9%" },
      { label: "User Adoption", value: "1.2M+" },
    ],
    tech: ["Quantum", "Python", "React"],
    icon: Cpu,
    color: "#9b87f5"
  },
  {
    id: 2,
    name: "NeuraTech",
    description: "Neural network optimization platform",
    year: 2022,
    metrics: [
      { label: "Training Speed", value: "+450%" },
      { label: "Model Accuracy", value: "98.7%" },
      { label: "Cost Reduction", value: "-75%" },
    ],
    tech: ["TensorFlow", "CUDA", "AWS"],
    icon: Zap,
    color: "#1EAEDB"
  },
  {
    id: 3,
    name: "GlobalSync",
    description: "Distributed edge computing network",
    year: 2021,
    metrics: [
      { label: "Global Reach", value: "180+" },
      { label: "Latency", value: "-92%" },
      { label: "Uptime", value: "99.999%" },
    ],
    tech: ["Edge", "Rust", "K8s"],
    icon: Globe,
    color: "#D946EF"
  }
];

export const InnovationTimeline = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end end"]
  });

  const springConfig = { stiffness: 100, damping: 30, restDelta: 0.001 };
  const y = useSpring(useTransform(scrollYProgress, [0, 1], [0, 1000]), springConfig);
  const opacity = useSpring(useTransform(scrollYProgress, [0, 0.2, 0.8, 1], [0, 1, 1, 0]), springConfig);

  const [hoveredId, setHoveredId] = useState<number | null>(null);

  return (
    <div 
      ref={containerRef}
      className="min-h-screen bg-[#1A1F2C] relative overflow-hidden py-20"
    >
      {/* Background Effects */}
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#ffffff05_1px,transparent_1px),linear-gradient(to_bottom,#ffffff05_1px,transparent_1px)] bg-[size:4rem_4rem]" />
      <div className="absolute inset-0 bg-gradient-to-b from-transparent via-purple-500/5 to-transparent" />
      
      <motion.div 
        style={{ opacity }}
        className="relative container mx-auto px-4"
      >
        <motion.h2 
          className="text-6xl md:text-8xl font-display font-bold text-white mb-20 text-center"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          Innovation Timeline
        </motion.h2>

        <div className="relative">
          {/* Timeline Line */}
          <div className="absolute left-1/2 top-0 bottom-0 w-px bg-gradient-to-b from-purple-500/20 via-blue-500/20 to-pink-500/20" />

          {projects.map((project, index) => (
            <motion.div
              key={project.id}
              className="mb-32 relative group"
              initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8, delay: index * 0.2 }}
              onHoverStart={() => setHoveredId(project.id)}
              onHoverEnd={() => setHoveredId(null)}
            >
              <div className={`
                absolute left-1/2 top-0 w-4 h-4 -ml-2 rounded-full
                ${hoveredId === project.id ? 'scale-150' : 'scale-100'}
                transition-all duration-300
              `}
                style={{ backgroundColor: project.color }}
              />

              <div className={`
                flex ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}
                items-center gap-8
              `}>
                <div className="w-1/2 text-right">
                  <motion.div
                    className={`
                      p-8 rounded-2xl backdrop-blur-sm
                      border border-white/10 relative group
                      ${index % 2 === 0 ? 'text-right' : 'text-left'}
                    `}
                    style={{
                      backgroundColor: `${project.color}10`,
                      boxShadow: hoveredId === project.id ? `0 0 30px ${project.color}30` : 'none'
                    }}
                    whileHover={{ scale: 1.02 }}
                    transition={{ duration: 0.2 }}
                  >
                    <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                    
                    <div className="relative">
                      <div className="flex items-center gap-3 mb-4 justify-end">
                        <h3 className="text-3xl font-display font-bold text-white">
                          {project.name}
                        </h3>
                        <project.icon className="w-6 h-6 text-white/80" />
                      </div>
                      
                      <p className="text-white/60 mb-6">{project.description}</p>
                      
                      <div className="grid grid-cols-3 gap-4 mb-6">
                        {project.metrics.map((metric, i) => (
                          <div key={i} className="text-center">
                            <div className="text-2xl font-bold" style={{ color: project.color }}>
                              {metric.value}
                            </div>
                            <div className="text-sm text-white/40">
                              {metric.label}
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="flex gap-2 flex-wrap justify-end">
                        {project.tech.map((tech, i) => (
                          <span
                            key={i}
                            className="px-3 py-1 rounded-full text-sm"
                            style={{
                              backgroundColor: `${project.color}20`,
                              color: project.color
                            }}
                          >
                            {tech}
                          </span>
                        ))}
                      </div>
                    </div>
                  </motion.div>
                </div>

                <div className="w-1/2">
                  <motion.span
                    className="text-5xl font-display font-bold"
                    style={{ color: project.color }}
                    animate={{
                      opacity: hoveredId === project.id ? 1 : 0.5,
                      scale: hoveredId === project.id ? 1.1 : 1
                    }}
                  >
                    {project.year}
                  </motion.span>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};