import { motion } from "framer-motion";
import { Terminal, ArrowRight, Command } from "lucide-react";
import { TypeAnimation } from 'react-type-animation';
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/hooks/use-toast";

export const FlareApplyContent = () => {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    projectDescription: "",
  });
  const { toast } = useToast();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // Here you would typically submit the form data
    toast({
      title: "Application Received",
      description: "We'll review your application and get back to you shortly.",
    });
  };

  return (
    <div className="min-h-screen bg-[#1A1F2C] text-[#FF4D00] py-16 font-mono">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="max-w-4xl mx-auto"
        >
          {/* Terminal Header */}
          <div className="bg-[#222632] rounded-t-xl p-4 border border-[#FF4D00]/20">
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 rounded-full bg-red-500" />
              <div className="w-3 h-3 rounded-full bg-yellow-500" />
              <div className="w-3 h-3 rounded-full bg-green-500" />
              <span className="ml-4 text-sm opacity-50">flare_application.sh</span>
            </div>
          </div>

          {/* Terminal Content */}
          <div className="bg-[#222632] p-8 rounded-b-xl border border-t-0 border-[#FF4D00]/20 min-h-[80vh]">
            <TypeAnimation
              sequence={[
                '> Initializing FLARE Application Protocol...\n',
                1000,
                '> Welcome to the FLARE Program Application Process\n',
                1000,
                '> This is a highly selective program designed for ambitious projects\n',
                1000,
                '> Please provide your information to begin the application process...\n',
                1000,
                '> Loading secure form...\n',
                1000,
              ]}
              wrapper="div"
              speed={50}
              style={{ 
                whiteSpace: 'pre-line',
                minHeight: '200px',
                color: '#FF4D00',
                opacity: 0.9,
                lineHeight: '1.8'
              }}
              repeat={0}
            />

            <motion.form
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 6 }}
              onSubmit={handleSubmit}
              className="space-y-6 mt-8"
            >
              <div className="space-y-2">
                <label className="block text-[#FF4D00]/80">
                  $ enter_name:~
                </label>
                <Input
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="bg-[#1A1F2C] border-[#FF4D00]/20 text-[#FF4D00] placeholder-[#FF4D00]/30"
                  placeholder="John Doe"
                />
              </div>

              <div className="space-y-2">
                <label className="block text-[#FF4D00]/80">
                  $ enter_email:~
                </label>
                <Input
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="bg-[#1A1F2C] border-[#FF4D00]/20 text-[#FF4D00] placeholder-[#FF4D00]/30"
                  placeholder="john@example.com"
                />
              </div>

              <div className="space-y-2">
                <label className="block text-[#FF4D00]/80">
                  $ enter_company:~
                </label>
                <Input
                  name="company"
                  value={formData.company}
                  onChange={handleInputChange}
                  className="bg-[#1A1F2C] border-[#FF4D00]/20 text-[#FF4D00] placeholder-[#FF4D00]/30"
                  placeholder="Company Name"
                />
              </div>

              <div className="space-y-2">
                <label className="block text-[#FF4D00]/80">
                  $ describe_project:~
                </label>
                <Textarea
                  name="projectDescription"
                  value={formData.projectDescription}
                  onChange={handleInputChange}
                  className="bg-[#1A1F2C] border-[#FF4D00]/20 text-[#FF4D00] placeholder-[#FF4D00]/30 min-h-[150px]"
                  placeholder="Tell us about your project..."
                />
              </div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
              >
                <Button 
                  type="submit"
                  className="w-full bg-[#FF4D00] hover:bg-[#FF4D00]/90 text-white px-8 py-6 rounded-lg text-lg font-mono mt-8 group"
                >
                  <Command className="mr-2 h-5 w-5 inline-block group-hover:rotate-12 transition-transform" />
                  $ ./submit-application.sh
                  <ArrowRight className="ml-2 h-5 w-5 inline-block group-hover:translate-x-1 transition-transform" />
                </Button>
              </motion.div>
            </motion.form>

            <div className="mt-8 text-sm opacity-70">
              <TypeAnimation
                sequence={[
                  '> Note: All submissions are reviewed within 48-72 hours\n> For urgent inquiries: support@flareprogram.com',
                ]}
                wrapper="div"
                speed={50}
                style={{ 
                  whiteSpace: 'pre-line',
                  color: '#FF4D00',
                  opacity: 0.7,
                  lineHeight: '1.8'
                }}
                repeat={0}
              />
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};