
import { useLocation, useNavigate } from "react-router-dom";
import { Command } from "lucide-react";
import { Button } from "@/components/ui/button";
import { ContactPopover } from "@/components/ContactPopover";

export const ContactButton = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isFlarePage = location.pathname.includes('/flare');
  const isHomePage = location.pathname === '/';
  
  const handleContactClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (isHomePage) {
      const element = document.querySelector('#madlib-section');
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth'
        });
      }
    } else {
      // Use navigate instead of direct window.location to prevent full page refresh
      navigate('/');
      // Need a small timeout to ensure navigation completes before scrolling
      setTimeout(() => {
        const element = document.querySelector('#madlib-section');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  };

  return (
    <ContactPopover align="end">
      <Button 
        size="sm" 
        variant={isFlarePage ? "outline" : "default"}
        className={`h-9 px-4 lg:h-10 lg:px-6 ${
          isFlarePage ? 'border-[#FF4D00] text-[#FF4D00] hover:bg-[#FF4D00]/10 font-mono flex items-center gap-2' : 
          'bg-brand-orange hover:bg-brand-orange/90 text-white font-mono'
        }`}
        onClick={handleContactClick}
      >
        {isFlarePage ? (
          <>
            <Command className="w-4 h-4" />
            ./contact.sh
          </>
        ) : (
          'Contact Us'
        )}
      </Button>
    </ContactPopover>
  );
};
