import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Plus, Layout, FileText } from "lucide-react";

interface AddItemMenuProps {
  onFeatureClick: () => void;
  onScreenClick: () => void;
}

export const AddItemMenu = ({ onFeatureClick, onScreenClick }: AddItemMenuProps) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className="h-8 w-8 bg-accent hover:bg-accent-hover"
          title="Add Item"
        >
          <Plus className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={onFeatureClick}>
          <FileText className="mr-2 h-4 w-4" />
          Add Custom Feature
        </DropdownMenuItem>
        <DropdownMenuItem onClick={onScreenClick}>
          <Layout className="mr-2 h-4 w-4" />
          Add Custom Screen
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};