
import { useState } from 'react';
import { Paperclip, Loader2 } from 'lucide-react';
import { format } from 'date-fns';
import { Button } from '@/components/ui/button';
import { toast } from 'sonner';
import { supabase } from '@/integrations/supabase/client';
import { useQueryClient } from '@tanstack/react-query';

interface TaskAttachmentsProps {
  taskId: string;
  attachments: any[];
  isLoadingAttachments: boolean;
}

export function TaskAttachments({ taskId, attachments, isLoadingAttachments }: TaskAttachmentsProps) {
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const queryClient = useQueryClient();

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !taskId) return;

    setIsUploadingFile(true);
    try {
      const fileExt = file.name.split('.').pop();
      const filePath = `${taskId}/${crypto.randomUUID()}.${fileExt}`;

      const { error: uploadError } = await supabase.storage
        .from('task-attachments')
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      const { error: dbError } = await supabase
        .from('task_attachments')
        .insert([
          {
            task_id: taskId,
            file_name: file.name,
            file_path: filePath,
            file_type: file.type,
            file_size: file.size,
          },
        ]);

      if (dbError) throw dbError;

      toast.success('File uploaded successfully');
      queryClient.invalidateQueries({ queryKey: ['task-attachments', taskId] });
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('Failed to upload file');
    } finally {
      setIsUploadingFile(false);
    }
  };

  const handleDownloadFile = async (filePath: string, fileName: string) => {
    try {
      const { data, error } = await supabase.storage
        .from('task-attachments')
        .download(filePath);

      if (error) throw error;

      const url = URL.createObjectURL(data);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading file:', error);
      toast.error('Failed to download file');
    }
  };

  return (
    <div className="space-y-4">
      <h3 className="font-medium flex items-center gap-2 text-gray-900">
        <Paperclip className="h-4 w-4" />
        Attachments
      </h3>
      <div className="space-y-4">
        <div className="border-2 border-dashed border-[#ff4d00]/20 rounded-lg p-8 text-center relative hover:border-[#ff4d00]/40 transition-colors">
          <input 
            type="file" 
            onChange={handleFileUpload} 
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer" 
            disabled={isUploadingFile} 
          />
          {isUploadingFile ? (
            <div className="flex items-center justify-center gap-2">
              <Loader2 className="h-4 w-4 animate-spin" />
              <p className="text-sm">Uploading...</p>
            </div>
          ) : (
            <p className="text-sm text-[#ff4d00]/70">
              Drop files here or click to upload
            </p>
          )}
        </div>
        {isLoadingAttachments ? (
          <div className="text-center py-4">
            <Loader2 className="h-6 w-6 animate-spin mx-auto" />
          </div>
        ) : attachments.length > 0 ? (
          <div className="space-y-2">
            {attachments.map(attachment => (
              <div key={attachment.id} className="flex items-center justify-between p-3 bg-[#ff4d00]/5 rounded-lg">
                <div className="flex items-center gap-2">
                  <Paperclip className="h-4 w-4 text-[#ff4d00]/70" />
                  <span className="text-sm text-gray-800">{attachment.file_name}</span>
                </div>
                <Button 
                  variant="ghost" 
                  size="sm" 
                  onClick={() => handleDownloadFile(attachment.file_path, attachment.file_name)}
                  className="text-[#ff4d00] hover:text-[#ff4d00]/90 hover:bg-[#ff4d00]/10"
                >
                  Download
                </Button>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center text-[#ff4d00]/70 py-4">
            No attachments yet
          </p>
        )}
      </div>
    </div>
  );
}
