import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { Task } from '@/types/task';
import { Button } from '@/components/ui/button';
import { Calendar, Clock, AlertCircle, ArrowLeft, MessageSquare, Paperclip, Loader2, Plus, Link } from 'lucide-react';
import { format } from 'date-fns';
import { Textarea } from '@/components/ui/textarea';
import { toast } from 'sonner';
import { useAuth } from '@/contexts/AuthContext';
import { CommandPalette } from './CommandPalette';
import { Command } from '@/types/commands';
import { useCommandActions } from './hooks/useCommandActions';
import { TaskComments } from './task-details/TaskComments';
import { TaskAttachments } from './task-details/TaskAttachments';
import { TaskStatusBadges } from './task-details/TaskStatusBadges';
import { TaskDocumentation } from './task-details/TaskDocumentation';

const getPriorityColor = (priority: Task['priority']) => {
  switch (priority) {
    case 'urgent':
      return 'bg-[#ea384c]';
    case 'high':
      return 'bg-[#ea384c]';
    case 'normal':
      return 'bg-[#9b87f5]';
    case 'low':
      return 'bg-[#8E9196]';
    default:
      return 'bg-[#9b87f5]';
  }
};

export function TaskDetails() {
  const { taskId } = useParams<{ taskId: string }>();
  const navigate = useNavigate();
  const [newMessage, setNewMessage] = useState('');
  const [quickNote, setQuickNote] = useState('');
  const [isSubmittingMessage, setIsSubmittingMessage] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [isCommandPaletteOpen, setIsCommandPaletteOpen] = useState(false);
  const queryClient = useQueryClient();
  const { session } = useAuth();

  const { data: task, isLoading } = useQuery({
    queryKey: ['tasks', taskId],
    queryFn: async () => {
      const { data, error } = await supabase.from('tasks').select('*').eq('id', taskId).single();
      if (error) throw error;
      return data as Task;
    },
    enabled: !!taskId
  });

  const { data: messages = [], isLoading: isLoadingMessages } = useQuery({
    queryKey: ['task-messages', taskId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('task_messages')
        .select('*')
        .eq('task_id', taskId)
        .order('created_at', { ascending: false });
      if (error) throw error;
      return data;
    },
    enabled: !!taskId
  });

  const { data: attachments = [], isLoading: isLoadingAttachments } = useQuery({
    queryKey: ['task-attachments', taskId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('task_attachments')
        .select('*')
        .eq('task_id', taskId)
        .order('created_at', { ascending: false });
      if (error) throw error;
      return data;
    },
    enabled: !!taskId
  });

  const { data: approvals = [] } = useQuery({
    queryKey: ['task-approvals', taskId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('task_approvals')
        .select('*')
        .eq('task_id', taskId)
        .eq('status', 'approved')
        .limit(1);
      if (error) throw error;
      return data;
    },
    enabled: !!taskId
  });

  const { data: feedbackRequests = [] } = useQuery({
    queryKey: ['task-feedback', taskId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('task_feedback')
        .select('*')
        .eq('task_id', taskId)
        .eq('status', 'completed')
        .limit(1);
      if (error) throw error;
      return data;
    },
    enabled: !!taskId
  });

  const { 
    isLoading: isCommandLoading,
    requestApproval,
    requestFeedback,
    scheduleMeeting,
    addTechnicalDoc,
    addApiDoc,
    flagBlocker
  } = useCommandActions(task);

  const handleQuickNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setQuickNote(value);
    
    if (value.endsWith('/')) {
      setIsCommandPaletteOpen(true);
    }
  };

  const handleCommandSelect = async (command: Command) => {
    if (!task) return;

    switch (command.id) {
      case 'approve':
        await requestApproval();
        break;
      case 'feedback':
        await requestFeedback();
        break;
      case 'meeting':
        const startTime = new Date();
        const endTime = new Date(startTime.getTime() + 60 * 60 * 1000);
        await scheduleMeeting(startTime, endTime, `Review: ${task.title}`);
        break;
      case 'question':
        toast.message('Coming soon: Ask questions feature');
        break;
      case 'pr':
        toast.message('Coming soon: Link PR feature');
        break;
      case 'deploy':
        toast.message('Coming soon: Deployment status feature');
        break;
      case 'estimate':
        toast.message('Coming soon: Time estimate feature');
        break;
      case 'blocked':
        await flagBlocker('Task blocked - requires attention');
        break;
      case 'timeline':
        toast.message('Coming soon: Timeline view feature');
        break;
      case 'summary':
        toast.message('Coming soon: AI summary feature');
        break;
      case 'specs':
        await addTechnicalDoc(
          'Technical Specification',
          'Initial technical specification document',
          'specification'
        );
        break;
      case 'docs':
        await addTechnicalDoc(
          'Project Documentation',
          'Initial project documentation',
          'documentation'
        );
        break;
      case 'api':
        await addApiDoc(
          '/api/example',
          'GET',
          'Example API endpoint documentation'
        );
        break;
      case 'share':
        toast.message('Coming soon: Share task feature');
        break;
      case 'notify':
        toast.message('Coming soon: Notification feature');
        break;
      default:
        toast.error('Unknown command');
    }
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setNewMessage(value);
    
    if (value.endsWith('/')) {
      setIsCommandPaletteOpen(true);
    }
  };

  const handleMessageSubmit = async () => {
    if (!newMessage.trim() || !taskId || !session?.user?.id) return;
    setIsSubmittingMessage(true);
    try {
      const { error } = await supabase.from('task_messages').insert([{
        task_id: taskId,
        content: newMessage,
        user_id: session.user.id
      }]);
      if (error) throw error;
      setNewMessage('');
      toast.success('Message sent successfully');
      queryClient.invalidateQueries({ queryKey: ['task-messages', taskId] });
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Failed to send message');
    } finally {
      setIsSubmittingMessage(false);
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !taskId) return;
    setIsUploadingFile(true);
    try {
      const fileExt = file.name.split('.').pop();
      const filePath = `${taskId}/${crypto.randomUUID()}.${fileExt}`;
      const { error: uploadError } = await supabase.storage
        .from('task-attachments')
        .upload(filePath, file);
      if (uploadError) throw uploadError;

      const { error: dbError } = await supabase.from('task_attachments').insert([{
        task_id: taskId,
        file_name: file.name,
        file_path: filePath,
        file_type: file.type,
        file_size: file.size
      }]);
      if (dbError) throw dbError;
      toast.success('File uploaded successfully');
      queryClient.invalidateQueries({ queryKey: ['task-attachments', taskId] });
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('Failed to upload file');
    } finally {
      setIsUploadingFile(false);
    }
  };

  const handleDownloadFile = async (filePath: string, fileName: string) => {
    try {
      const { data, error } = await supabase.storage
        .from('task-attachments')
        .download(filePath);
      if (error) throw error;
      const url = URL.createObjectURL(data);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading file:', error);
      toast.error('Failed to download file');
    }
  };

  const handleQuickFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !taskId) return;
    setIsUploadingFile(true);
    try {
      const fileExt = file.name.split('.').pop();
      const filePath = `${taskId}/${crypto.randomUUID()}.${fileExt}`;
      const { error: uploadError } = await supabase.storage
        .from('task-attachments')
        .upload(filePath, file);
      if (uploadError) throw uploadError;

      const { error: dbError } = await supabase.from('task_attachments').insert([{
        task_id: taskId,
        file_name: file.name,
        file_path: filePath,
        file_type: file.type,
        file_size: file.size
      }]);
      if (dbError) throw dbError;
      toast.success('File uploaded successfully');
      queryClient.invalidateQueries({ queryKey: ['task-attachments', taskId] });
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('Failed to upload file');
    } finally {
      setIsUploadingFile(false);
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (!task) return <div>Task not found</div>;

  const priorityColor = getPriorityColor(task.priority);

  return (
    <div className="flex h-full bg-background">
      <CommandPalette
        isOpen={isCommandPaletteOpen}
        onClose={() => setIsCommandPaletteOpen(false)}
        onSelect={handleCommandSelect}
      />

      <div className="flex-1 overflow-y-auto p-6 border-r border-zinc-800">
        <div className="max-w-3xl mx-auto space-y-8">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <Button variant="ghost" size="icon" onClick={() => navigate(-1)}>
                <ArrowLeft className="h-5 w-5" />
              </Button>
              <h1 className="text-2xl font-semibold">{task.title}</h1>
            </div>
          </div>

          <div className="relative">
            <Textarea
              placeholder="Type '/' for commands or write a message..."
              value={quickNote}
              onChange={handleQuickNoteChange}
              className="min-h-[120px] bg-muted resize-none text-foreground placeholder:text-muted-foreground focus:ring-1 focus:ring-[#ff4d00] rounded-lg w-full"
            />
            <div className="absolute right-3 bottom-3 flex items-center gap-2">
              <input
                type="file"
                id="quick-file"
                className="hidden"
                onChange={handleQuickFileUpload}
                disabled={isUploadingFile}
              />
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8 text-muted-foreground hover:text-foreground"
                asChild
              >
                <label htmlFor="quick-file" className="cursor-pointer">
                  <Paperclip className="h-4 w-4" />
                </label>
              </Button>
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8 text-muted-foreground hover:text-foreground"
              >
                <Plus className="h-4 w-4" />
              </Button>
            </div>
          </div>

          <TaskStatusBadges
            hasApproval={approvals.length > 0}
            hasFeedback={feedbackRequests.length > 0}
            isBlocked={task.status === 'blocked'}
            dueDate={task.due_date}
            taskId={task.id}
          />

          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2 px-3 py-1.5 rounded-md bg-[#ff4d00]">
              <span className="text-sm font-medium capitalize">{task.status}</span>
            </div>
            <div className={`flex items-center gap-2 px-3 py-1.5 rounded-md ${priorityColor}`}>
              <AlertCircle className="h-4 w-4" />
              <span className="text-sm font-medium capitalize">{task.priority}</span>
            </div>
          </div>

          <div className="space-y-2">
            <h3 className="text-sm font-medium">Description</h3>
            <p className="text-sm text-muted-foreground">{task.description}</p>
          </div>

          <TaskAttachments
            taskId={task.id}
            attachments={attachments}
            isLoadingAttachments={isLoadingAttachments}
          />

          <TaskDocumentation taskId={task.id} />

          <div className="grid grid-cols-2 gap-6">
            {(task.due_date || task.start_date) && (
              <div className="space-y-3">
                <h3 className="text-sm font-medium">Dates</h3>
                {task.start_date && (
                  <div className="flex items-center gap-2">
                    <Calendar className="h-4 w-4 text-muted-foreground" />
                    <div>
                      <p className="text-xs text-muted-foreground">Start Date</p>
                      <p className="text-sm">{format(new Date(task.start_date), 'PPP')}</p>
                    </div>
                  </div>
                )}
                {task.due_date && (
                  <div className="flex items-center gap-2">
                    <Calendar className="h-4 w-4 text-muted-foreground" />
                    <div>
                      <p className="text-xs text-muted-foreground">Due Date</p>
                      <p className="text-sm">{format(new Date(task.due_date), 'PPP')}</p>
                    </div>
                  </div>
                )}
              </div>
            )}

            {(task.time_estimate || task.time_spent) && (
              <div className="space-y-3">
                <h3 className="text-sm font-medium">Time Tracking</h3>
                {task.time_estimate && (
                  <div className="flex items-center gap-2">
                    <Clock className="h-4 w-4 text-muted-foreground" />
                    <div>
                      <p className="text-xs text-muted-foreground">Estimate</p>
                      <p className="text-sm">{task.time_estimate} hours</p>
                    </div>
                  </div>
                )}
                {task.time_spent && (
                  <div className="flex items-center gap-2">
                    <Clock className="h-4 w-4 text-muted-foreground" />
                    <div>
                      <p className="text-xs text-muted-foreground">Time Spent</p>
                      <p className="text-sm">{task.time_spent} hours</p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="w-96 flex flex-col h-full bg-muted transition-colors duration-200">
        <div className="p-4 flex items-center justify-between border-b border-zinc-800">
          <h2 className="text-lg font-semibold flex items-center gap-2 text-foreground">
            <MessageSquare className="h-5 w-5" />
            Activity
          </h2>
          <div className="flex items-center gap-2">
            <Button variant="ghost" size="icon" className="text-muted-foreground hover:text-foreground">
              <Plus className="h-4 w-4" />
            </Button>
            <Button variant="ghost" size="icon" className="text-muted-foreground hover:text-foreground">
              <ArrowLeft className="h-4 w-4" />
            </Button>
          </div>
        </div>
        
        <div className="flex-1 overflow-y-auto p-4 space-y-4">
          {isLoadingMessages ? (
            <div className="flex justify-center">
              <Loader2 className="h-6 w-6 animate-spin text-muted-foreground" />
            </div>
          ) : messages.length > 0 ? (
            messages.map(message => (
              <div key={message.id} className="bg-accent rounded-lg p-3 space-y-2">
                <p className="text-sm text-foreground">{message.content}</p>
                <div className="flex items-center justify-between">
                  <p className="text-xs text-muted-foreground">
                    {format(new Date(message.created_at), 'PPpp')}
                  </p>
                  <Button variant="ghost" size="icon" className="h-6 w-6 text-muted-foreground hover:text-foreground">
                    <Link className="h-3 w-3" />
                  </Button>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-muted-foreground">No activity yet</p>
          )}
        </div>

        <div className="p-4 border-t border-zinc-800">
          <div className="relative">
            <Textarea
              placeholder="Type '/' for commands or write a message..."
              value={newMessage}
              onChange={handleMessageChange}
              className="min-h-[80px] bg-accent border-none resize-none text-foreground placeholder:text-muted-foreground focus:ring-0 rounded-lg pr-24"
            />
            <Button
              onClick={handleMessageSubmit}
              disabled={isSubmittingMessage || !newMessage.trim()}
              className="absolute bottom-3 right-3 bg-[#ff4d00] hover:bg-[#ff4d00]/90 text-white rounded-md px-4 py-1 text-sm font-medium"
            >
              {isSubmittingMessage ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                'Send'
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
