import { useState } from "react";
import { Card } from "@/components/ui/card";
import { CheckCircle, Calendar, FileText, Rocket } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { TechnicalSpecsModal } from "./TechnicalSpecsModal";
import { useProjectStore } from "../../stores/projectStore";

export const NextSteps = () => {
  const navigate = useNavigate();
  const [showSpecs, setShowSpecs] = useState(false);
  const analysis = useProjectStore((state) => state.analysis);
  
  const steps = [
    {
      icon: <Calendar className="w-5 h-5 text-[#FF4D00]" />,
      title: "Schedule Consultation",
      description: "Book a call with our team to discuss your project in detail",
      action: "Book Call",
      onClick: () => window.open('https://calendly.com/your-link', '_blank')
    },
    {
      icon: <FileText className="w-5 h-5 text-[#FF4D00]" />,
      title: "Review Technical Specifications",
      description: "Get a detailed breakdown of technical requirements",
      action: "View Specs",
      onClick: () => setShowSpecs(true)
    },
    {
      icon: <Rocket className="w-6 h-6 text-white" />,
      title: "Start Free: Your Virtual Startup Lab",
      description: "Join Unstuck Anywhere to access mentorship, funding resources, and founder tools at 20% off",
      action: "Start Free",
      isSpecial: true,
      onClick: () => navigate('/unstuck-anywhere')
    }
  ];

  return (
    <>
      <Card className="p-8">
        <div className="flex items-center gap-3 mb-6">
          <CheckCircle className="w-6 h-6 text-[#FF4D00]" />
          <h3 className="text-xl font-semibold">Next Steps</h3>
        </div>

        <div className="grid md:grid-cols-3 gap-6">
          {steps.map((step, index) => (
            <div 
              key={index} 
              className={`space-y-4 p-6 rounded-lg transition-all duration-300 ${
                step.isSpecial 
                  ? 'bg-gradient-to-br from-[#FF4D00] via-[#FF4D00]/90 to-[#FF4D00]/80 border-2 border-[#FF4D00] shadow-lg hover:shadow-xl transform hover:-translate-y-1' 
                  : 'hover:bg-secondary/5 border border-secondary/20'
              }`}
            >
              <div className={`flex items-center gap-3 ${step.isSpecial ? 'text-white' : ''}`}>
                {step.icon}
                <h4 className={`${step.isSpecial ? 'text-xl font-bold text-white' : 'font-semibold'}`}>
                  {step.title}
                </h4>
              </div>
              <p className={`text-sm ${
                step.isSpecial ? 'text-white/90 font-medium' : 'text-muted-foreground'
              }`}>
                {step.description}
              </p>
              <Button
                variant={step.isSpecial ? "secondary" : "outline"}
                className={`w-full ${
                  step.isSpecial 
                    ? 'bg-white hover:bg-white/90 text-[#FF4D00] font-semibold group'
                    : ''
                }`}
                onClick={step.onClick}
              >
                {step.action}
                {step.isSpecial && (
                  <Rocket className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" />
                )}
              </Button>
            </div>
          ))}
        </div>
      </Card>

      <TechnicalSpecsModal 
        isOpen={showSpecs}
        onClose={() => setShowSpecs(false)}
        projectAnalysis={analysis}
      />
    </>
  );
};