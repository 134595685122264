
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";

export const ThankYouMessage = () => {
  return (
    <Card className="p-8 bg-gray-900 border-gray-800 text-center">
      <h2 className="text-3xl font-bold text-white mb-6">Thank You For Your Interest</h2>
      <p className="text-gray-300 mb-6 max-w-xl mx-auto">
        We've received your project information and will review it carefully. While this specific project might not be an immediate fit for our development services, we have resources that might help you move forward.
      </p>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
        <Card className="p-4 bg-gray-800 border-gray-700 text-left">
          <h3 className="text-lg font-semibold text-brand-orange mb-2">Free Resources</h3>
          <p className="text-gray-300 text-sm mb-4">
            Check out our guides, templates, and blog posts to help with your project planning.
          </p>
          <Link to="/resources">
            <Button variant="outline" className="w-full border-brand-orange/30 text-brand-orange hover:bg-brand-orange/10">
              Explore Resources <ArrowRight className="ml-2 h-4 w-4" />
            </Button>
          </Link>
        </Card>
        
        <Card className="p-4 bg-gray-800 border-gray-700 text-left">
          <h3 className="text-lg font-semibold text-brand-orange mb-2">Project Scoping Workshop</h3>
          <p className="text-gray-300 text-sm mb-4">
            Join our next workshop to refine your project requirements and get expert advice.
          </p>
          <Link to="/workshops">
            <Button variant="outline" className="w-full border-brand-orange/30 text-brand-orange hover:bg-brand-orange/10">
              See Upcoming Workshops <ArrowRight className="ml-2 h-4 w-4" />
            </Button>
          </Link>
        </Card>
      </div>
      
      <p className="text-gray-400 text-sm">
        We'll keep your information on file and reach out if there are future opportunities that might be a better match.
      </p>
    </Card>
  );
};
