
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { Card } from '@/components/ui/card';
import { Loader2 } from 'lucide-react';
import { toast } from 'sonner';
import { ProfileBasicInfo } from './profile/ProfileBasicInfo';
import { ProfileEmergencyContact } from './profile/ProfileEmergencyContact';
import { ProfileMedicalInfo } from './profile/ProfileMedicalInfo';
import { ProfilePreferences } from './profile/ProfilePreferences';
import { ProfileAdminAccess } from './profile/ProfileAdminAccess';
import type { DatabaseEmployee, EmergencyContact, MedicalInfo, Preferences } from '@/types/employee';

export const EmployeeProfile = () => {
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    console.log('[EmployeeProfile] Component mounted', { id });
  }, [id]);

  const { data: profile, isLoading } = useQuery({
    queryKey: ['employee-profile', id],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const { data: rawData, error } = await supabase
        .from('employee_profiles')
        .select('*')
        .eq('id', id || user.id)
        .single();

      if (error) throw error;

      // Default values
      const defaultMedicalInfo: MedicalInfo = {
        allergies: '',
        conditions: '',
        medications: ''
      };

      const defaultPreferences: Preferences = {
        dietary: '',
        accommodations: ''
      };

      const defaultEmergencyContact: EmergencyContact = {
        name: '',
        relationship: '',
        phone: ''
      };

      // Transform raw data into proper types
      const transformedData: DatabaseEmployee = {
        id: rawData.id,
        user_id: rawData.user_id,
        first_name: rawData.first_name,
        last_name: rawData.last_name,
        title: rawData.title,
        department: rawData.department,
        reports_to: rawData.reports_to,
        start_date: rawData.start_date,
        email: rawData.email,
        work_phone: rawData.work_phone,
        personal_phone: rawData.personal_phone,
        avatar_url: rawData.avatar_url,
        created_at: rawData.created_at,
        updated_at: rawData.updated_at,
        pin: rawData.pin,
        location: rawData.location,
        birthday: rawData.birthday,
        personal_email: rawData.personal_email,
        profile_claimed: rawData.profile_claimed,
        is_admin: rawData.is_admin,
        invite_token: rawData.invite_token,
        invite_sent_at: rawData.invite_sent_at,
        status: rawData.status,
        archived_at: rawData.archived_at,
        archived_reason: rawData.archived_reason,
        emergency_contacts: Array.isArray(rawData.emergency_contacts) 
          ? (rawData.emergency_contacts as any[]).map(contact => ({
              name: String(contact?.name || ''),
              relationship: String(contact?.relationship || ''),
              phone: String(contact?.phone || '')
            }))
          : [defaultEmergencyContact],
        medical_info: rawData.medical_info && typeof rawData.medical_info === 'object'
          ? {
              allergies: String((rawData.medical_info as any)?.allergies || ''),
              conditions: String((rawData.medical_info as any)?.conditions || ''),
              medications: String((rawData.medical_info as any)?.medications || '')
            }
          : defaultMedicalInfo,
        preferences: rawData.preferences && typeof rawData.preferences === 'object'
          ? {
              dietary: String((rawData.preferences as any)?.dietary || ''),
              accommodations: String((rawData.preferences as any)?.accommodations || '')
            }
          : defaultPreferences,
        allergies: Array.isArray(rawData.allergies) ? rawData.allergies : [],
        dietary_preferences: Array.isArray(rawData.dietary_preferences) ? rawData.dietary_preferences : [],
        accommodations: Array.isArray(rawData.accommodations) ? rawData.accommodations : []
      };

      console.log('[EmployeeProfile] Profile data fetched successfully');
      return transformedData;
    }
  });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-96">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  if (!profile) {
    console.error('[EmployeeProfile] No profile data available');
    return (
      <Card className="p-6">
        <p className="text-destructive">Failed to load employee profile</p>
      </Card>
    );
  }

  return (
    <div className="container mx-auto p-6 space-y-6">
      <h1 className="text-3xl font-bold mb-8">Employee Profile</h1>
      <div className="grid gap-6 md:grid-cols-2">
        <div className="space-y-6">
          <ProfileBasicInfo 
            profile={profile}
            isEditMode={false}
            onFieldChange={() => {}}
            departments={[]}
            locations={[]}
          />
          <ProfileEmergencyContact 
            profile={profile}
            isEditMode={false}
            onNestedChange={() => {}}
          />
        </div>
        <div className="space-y-6">
          <ProfileMedicalInfo 
            profile={profile}
            allergies={profile.allergies}
            isEditMode={false}
            onNestedChange={() => {}}
          />
          <ProfilePreferences 
            profile={profile}
            dietaryPreferences={profile.dietary_preferences}
            accommodations={profile.accommodations}
            isEditMode={false}
            onNestedChange={() => {}}
          />
          <ProfileAdminAccess 
            profile={profile}
            onAdminToggle={async (isAdmin) => {
              try {
                const { error } = await supabase
                  .from('employee_profiles')
                  .update({ is_admin: isAdmin })
                  .eq('id', profile.id);

                if (error) throw error;
                toast.success(`Admin access ${isAdmin ? 'granted' : 'revoked'}`);
              } catch (error) {
                console.error('[EmployeeProfile] Admin toggle error:', error);
                toast.error('Failed to update admin access');
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
