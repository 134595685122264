import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Code2, Clock, BookOpen } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { ProjectAnalysis, TechStack } from "../../types";
import { TechStackSection } from "../specs/TechStackSection";
import { DevelopmentApproachSection } from "../specs/DevelopmentApproachSection";
import { BestPracticesSection } from "../specs/BestPracticesSection";

interface TechnicalSpecsModalProps {
  isOpen: boolean;
  onClose: () => void;
  projectAnalysis?: ProjectAnalysis;
}

export const TechnicalSpecsModal = ({ isOpen, onClose, projectAnalysis }: TechnicalSpecsModalProps) => {
  const navigate = useNavigate();

  if (!projectAnalysis) return null;

  const getTechRecommendations = (): TechStack => {
    const description = projectAnalysis.description?.toLowerCase() || '';
    const complexity = projectAnalysis.projectUnderstanding?.complexity || 'low';
    
    // Landing page or simple website detection
    if (description.includes('landing page') || 
        description.includes('simple website') || 
        description.includes('basic website')) {
      return {
        frontend: ['Webflow', 'WordPress'],
        backend: ['Built-in CMS'],
        infrastructure: ['Managed Hosting']
      };
    }
    
    // E-commerce detection
    if (description.includes('shop') || 
        description.includes('store') || 
        description.includes('commerce')) {
      return {
        frontend: ['Shopify', 'WooCommerce'],
        backend: ['Shopify Platform', 'WordPress'],
        infrastructure: ['Shopify Hosting', 'Managed WordPress Hosting']
      };
    }
    
    // SaaS/Complex web app detection
    if (complexity === 'high' || 
        description.includes('saas') || 
        description.includes('dashboard') ||
        description.includes('platform')) {
      return {
        frontend: ['React.js', 'Next.js', 'TypeScript'],
        backend: ['Node.js', 'PostgreSQL', 'Redis'],
        infrastructure: ['AWS', 'Vercel', 'Docker']
      };
    }

    // Default to medium complexity stack
    return {
      frontend: ['React.js', 'Next.js'],
      backend: ['Node.js', 'PostgreSQL'],
      infrastructure: ['Vercel', 'Supabase']
    };
  };

  const techStack = getTechRecommendations();
  const approach = projectAnalysis.projectUnderstanding?.recommendedApproach || 'low-code';
  const timeline = projectAnalysis.timeline?.total || "8-12 weeks";

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-4xl max-h-[80vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold">
            Technical Specifications & Development Guide
          </DialogTitle>
          <p className="text-muted-foreground">
            Based on your requirements, we've created a tailored technical plan that balances functionality, cost, and development speed.
          </p>
        </DialogHeader>

        <Tabs defaultValue="stack" className="mt-6">
          <TabsList className="grid grid-cols-3 w-full">
            <TabsTrigger value="stack" className="flex items-center gap-2">
              <Code2 className="w-4 h-4" />
              Technology & Architecture
            </TabsTrigger>
            <TabsTrigger value="approach" className="flex items-center gap-2">
              <Clock className="w-4 h-4" />
              Development Process
            </TabsTrigger>
            <TabsTrigger value="practices" className="flex items-center gap-2">
              <BookOpen className="w-4 h-4" />
              Best Practices
            </TabsTrigger>
          </TabsList>

          <TabsContent value="stack" className="mt-6">
            <TechStackSection 
              stack={techStack}
              recommendations={techStack}
            />
          </TabsContent>

          <TabsContent value="approach" className="mt-6">
            <DevelopmentApproachSection 
              approach={approach}
              methodology="Agile"
              timeline={timeline}
            />
          </TabsContent>

          <TabsContent value="practices" className="mt-6">
            <BestPracticesSection onLearnMore={() => {
              onClose();
              navigate('/unstuck-anywhere');
            }} />
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
};