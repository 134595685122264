
import { Header } from "@/components/Header";
import { Footer } from "@/components/Footer";
import { Hero } from "@/components/Hero";
import { GovernmentJourney } from "@/components/segments/GovernmentJourney";
import { GovernmentComplianceAnalyzer } from "@/components/government/GovernmentComplianceAnalyzer";
import { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { ArrowDownCircle, Shield, FileCheck } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { FinalCTA } from "@/components/segments/FinalCTA";

const GovernmentPage = () => {
  const analyzerRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    localStorage.setItem('preferred-segment', 'government');
  }, []);

  const scrollToAnalyzer = () => {
    analyzerRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <main className="min-h-screen bg-gradient-to-br from-emerald-950/20 via-background dark:to-background">
      <Header />
      <div className="relative z-10">
        <Hero clientSegment="government" />
        
        {/* New CTA Section */}
        <section className="py-16 bg-emerald-900/10 backdrop-blur-sm border-y border-emerald-900/20">
          <div className="container mx-auto px-4">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, amount: 0.3 }}
              className="max-w-5xl mx-auto text-center space-y-6"
            >
              <Badge variant="success" className="mb-4">
                FREE Compliance Tool
              </Badge>
              
              <h2 className="text-3xl md:text-4xl font-bold font-display bg-gradient-to-r from-emerald-400 to-green-300 bg-clip-text text-transparent mb-4">
                Assess Your Agency's Compliance Requirements in Minutes
              </h2>
              
              <p className="text-xl text-gray-600 dark:text-gray-300 mb-8 max-w-3xl mx-auto">
                Our Government Compliance Analyzer will evaluate your regulatory requirements, identify security needs, and suggest a compliance roadmap tailored to your agency.
              </p>
              
              <div className="flex flex-wrap gap-4 justify-center items-center">
                <Button 
                  onClick={scrollToAnalyzer}
                  size="lg" 
                  className="bg-emerald-600 hover:bg-emerald-700 text-white gap-2 py-6 px-8 rounded-lg text-lg group dark:bg-emerald-600/90 dark:hover:bg-emerald-600"
                >
                  <Shield className="w-5 h-5 group-hover:animate-pulse" />
                  Start Your Free Compliance Analysis
                  <ArrowDownCircle className="w-5 h-5 ml-1 animate-bounce" />
                </Button>
                
                <Button
                  variant="outline"
                  size="lg"
                  className="border-emerald-500 text-emerald-500 hover:bg-emerald-950/20 gap-2 py-6 px-8 rounded-lg text-lg dark:border-emerald-400 dark:text-emerald-400"
                  onClick={scrollToAnalyzer}
                >
                  <FileCheck className="w-5 h-5" />
                  See Sample Assessment
                </Button>
              </div>
              
              <div className="pt-8 flex flex-wrap justify-center gap-6 text-sm text-gray-500 dark:text-gray-400">
                <div className="flex items-center">
                  <span className="inline-block w-3 h-3 rounded-full bg-green-500 mr-2"></span>
                  FedRAMP Compliant
                </div>
                <div className="flex items-center">
                  <span className="inline-block w-3 h-3 rounded-full bg-green-500 mr-2"></span>
                  FISMA Assessment
                </div>
                <div className="flex items-center">
                  <span className="inline-block w-3 h-3 rounded-full bg-green-500 mr-2"></span>
                  NIST Framework Aligned
                </div>
              </div>
            </motion.div>
          </div>
        </section>
        
        <GovernmentJourney />
        
        {/* Government Compliance Analyzer Section */}
        <section id="compliance-analyzer" className="py-24" ref={analyzerRef}>
          <div className="container mx-auto px-4">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, amount: 0.3 }}
              className="max-w-6xl mx-auto"
            >
              <GovernmentComplianceAnalyzer />
            </motion.div>
          </div>
        </section>
        
        {/* Government Testimonial Section */}
        <section className="py-24">
          <div className="container mx-auto px-4">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, amount: 0.3 }}
              className="max-w-4xl mx-auto p-8 border-t border-emerald-900/20"
            >
              <div className="text-center mb-8">
                <h2 className="text-3xl font-display font-bold mb-4 dark:text-white">What Government Agencies Say</h2>
              </div>
              <blockquote className="italic text-xl text-gray-600 dark:text-gray-300 text-center mb-6">
                "The compliance analyzer tool helped us identify critical security gaps and create a clear roadmap to achieve FedRAMP certification. It saved us months of research and preparation time."
              </blockquote>
              <div className="text-center">
                <p className="font-semibold dark:text-white">Robert Johnson</p>
                <p className="text-gray-500 dark:text-gray-400">CIO, Federal Agency</p>
              </div>
            </motion.div>
          </div>
        </section>
        
        <FinalCTA segment="government" />
      </div>
      <Footer />
    </main>
  );
};

export default GovernmentPage;
