
import { useState } from "react";
import { motion } from "framer-motion";
import { toast } from "sonner";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Loader2 } from "lucide-react";
import { IntakeFormData, EnrichedData } from "@/types/intake";
import { supabase } from "@/integrations/supabase/client";
import { CalendarBooking } from "./CalendarBooking";
import { ThankYouMessage } from "./ThankYouMessage";
import { DataPreview } from "./DataPreview";

export const IntakeForm = () => {
  const [formData, setFormData] = useState<IntakeFormData>({
    fullName: "",
    email: "",
    companyName: "",
    linkedinProfile: "",
    projectType: "",
    budgetRange: "",
    timeline: "",
    projectDescription: ""
  });
  
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [enrichedData, setEnrichedData] = useState<EnrichedData | null>(null);
  const [isQualified, setIsQualified] = useState(false);
  const [leadId, setLeadId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (name: string, value: string) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateForm = () => {
    // Basic form validation
    if (!formData.fullName.trim()) return "Full name is required";
    if (!formData.email.trim()) return "Email is required";
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) return "Invalid email format";
    if (!formData.companyName.trim()) return "Company name is required";
    if (!formData.projectType) return "Project type is required";
    if (!formData.budgetRange) return "Budget range is required";
    if (!formData.timeline) return "Timeline is required";
    if (!formData.projectDescription.trim()) return "Project description is required";
    
    return null;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Validate the form
    const validationError = validateForm();
    if (validationError) {
      toast.error(validationError);
      return;
    }
    
    setLoading(true);
    setError(null);

    try {
      console.log("Submitting form data:", formData);
      
      const { data, error } = await supabase.functions.invoke('enrich-lead-data', {
        body: formData
      });

      if (error) {
        console.error("Supabase function error:", error);
        throw new Error(error.message || 'Failed to process data');
      }

      console.log("Response from enrich-lead-data:", data);
      
      if (!data.success) {
        console.error("Function reported failure:", data.error);
        throw new Error(data.error || 'Failed to process data');
      }

      setEnrichedData(data.data);
      setIsQualified(data.isQualified);
      setLeadId(data.leadId);
      setStep(2);
      
      toast.success("Information received", {
        description: "We've processed your information successfully."
      });

    } catch (error: any) {
      console.error('Error submitting form:', error);
      setError(error.message || "An unexpected error occurred");
      toast.error("Submission error", {
        description: "There was a problem processing your information. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full max-w-5xl mx-auto p-4 md:p-8">
      <Card className="bg-black border border-brand-orange/20 shadow-lg shadow-brand-orange/5">
        <CardHeader className="text-center pb-8 border-b border-brand-orange/10">
          <CardTitle className="text-3xl md:text-4xl font-display text-white">
            <span className="bg-gradient-to-r from-brand-orange to-red-500 bg-clip-text text-transparent">
              Unstuck Labs
            </span> Project Intake
          </CardTitle>
          {step === 1 && (
            <p className="text-gray-400 mt-2">
              Tell us about your project, and we'll help you bring it to life
            </p>
          )}
          {step === 2 && isQualified && (
            <p className="text-gray-400 mt-2">
              Great news! Your project is a good fit for Unstuck Labs. Schedule a call with our team.
            </p>
          )}
          {step === 2 && !isQualified && (
            <p className="text-gray-400 mt-2">
              Thanks for your interest in Unstuck Labs!
            </p>
          )}
        </CardHeader>
        
        <CardContent className="py-8 px-4 md:px-8">
          {error && (
            <div className="mb-6 p-4 bg-red-900/20 border border-red-500/50 rounded-md text-red-300">
              <p className="font-semibold">Error</p>
              <p className="text-sm">{error}</p>
            </div>
          )}
          
          {step === 1 && (
            <motion.form 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.4 }}
              onSubmit={handleSubmit}
              className="space-y-6"
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-2">
                  <Label htmlFor="fullName" className="text-white">Full Name</Label>
                  <Input
                    id="fullName"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    required
                    className="bg-gray-900 border-gray-700 text-white"
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="email" className="text-white">Email Address</Label>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="bg-gray-900 border-gray-700 text-white"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-2">
                  <Label htmlFor="companyName" className="text-white">Company Name</Label>
                  <Input
                    id="companyName"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    required
                    className="bg-gray-900 border-gray-700 text-white"
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="linkedinProfile" className="text-white">LinkedIn Profile (optional)</Label>
                  <Input
                    id="linkedinProfile"
                    name="linkedinProfile"
                    value={formData.linkedinProfile}
                    onChange={handleChange}
                    placeholder="https://linkedin.com/in/yourprofile"
                    className="bg-gray-900 border-gray-700 text-white"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="space-y-2">
                  <Label htmlFor="projectType" className="text-white">Project Type</Label>
                  <Select
                    value={formData.projectType}
                    onValueChange={(value) => handleSelectChange("projectType", value)}
                    required
                  >
                    <SelectTrigger className="bg-gray-900 border-gray-700 text-white">
                      <SelectValue placeholder="Select project type" />
                    </SelectTrigger>
                    <SelectContent className="bg-gray-900 border-gray-700 text-white">
                      <SelectItem value="web_application">Web Application</SelectItem>
                      <SelectItem value="mobile_app">Mobile App</SelectItem>
                      <SelectItem value="enterprise_software">Enterprise Software</SelectItem>
                      <SelectItem value="ai_ml">AI/Machine Learning</SelectItem>
                      <SelectItem value="mvp">MVP Development</SelectItem>
                      <SelectItem value="other">Other</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className="space-y-2">
                  <Label htmlFor="budgetRange" className="text-white">Budget Range</Label>
                  <Select
                    value={formData.budgetRange}
                    onValueChange={(value) => handleSelectChange("budgetRange", value)}
                    required
                  >
                    <SelectTrigger className="bg-gray-900 border-gray-700 text-white">
                      <SelectValue placeholder="Select budget range" />
                    </SelectTrigger>
                    <SelectContent className="bg-gray-900 border-gray-700 text-white">
                      <SelectItem value="under_25k">Under $25k</SelectItem>
                      <SelectItem value="25k_50k">$25k - $50k</SelectItem>
                      <SelectItem value="50k_100k">$50k - $100k</SelectItem>
                      <SelectItem value="100k_200k">$100k - $200k</SelectItem>
                      <SelectItem value="200k_plus">$200k+</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className="space-y-2">
                  <Label htmlFor="timeline" className="text-white">Timeline</Label>
                  <Select
                    value={formData.timeline}
                    onValueChange={(value) => handleSelectChange("timeline", value)}
                    required
                  >
                    <SelectTrigger className="bg-gray-900 border-gray-700 text-white">
                      <SelectValue placeholder="Select timeline" />
                    </SelectTrigger>
                    <SelectContent className="bg-gray-900 border-gray-700 text-white">
                      <SelectItem value="asap">ASAP</SelectItem>
                      <SelectItem value="1_3_months">1-3 months</SelectItem>
                      <SelectItem value="3_6_months">3-6 months</SelectItem>
                      <SelectItem value="6_12_months">6-12 months</SelectItem>
                      <SelectItem value="not_sure">Not sure yet</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>

              <div className="space-y-2">
                <Label htmlFor="projectDescription" className="text-white">Project Description</Label>
                <Textarea
                  id="projectDescription"
                  name="projectDescription"
                  value={formData.projectDescription}
                  onChange={handleChange}
                  required
                  className="min-h-[120px] bg-gray-900 border-gray-700 text-white"
                  placeholder="Tell us about your project goals, features, and any specific requirements..."
                />
              </div>

              <div className="pt-4">
                <Button
                  type="submit"
                  className="w-full bg-brand-orange hover:bg-brand-orange/90 text-white"
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Processing...
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </motion.form>
          )}

          {step === 2 && enrichedData && (
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.4 }}
              className="space-y-8"
            >
              {isQualified ? (
                <>
                  <DataPreview enrichedData={enrichedData} />
                  <CalendarBooking 
                    fullName={formData.fullName} 
                    email={formData.email} 
                    leadId={leadId} 
                  />
                </>
              ) : (
                <ThankYouMessage />
              )}
            </motion.div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};
