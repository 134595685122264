import { motion } from "framer-motion";
import { Code2, Users, Clock, Server } from "lucide-react";

const metrics = [
  {
    icon: Code2,
    label: "Projects Completed",
    value: "150+",
    color: "bg-blue-500"
  },
  {
    icon: Users,
    label: "Active Users",
    value: "1M+",
    color: "bg-green-500"
  },
  {
    icon: Clock,
    label: "Development Hours",
    value: "50K+",
    color: "bg-purple-500"
  },
  {
    icon: Server,
    label: "Server Uptime",
    value: "99.9%",
    color: "bg-orange-500"
  }
];

export const MetricsDashboard = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      {metrics.map((metric, index) => (
        <motion.div
          key={metric.label}
          className="relative overflow-hidden rounded-xl bg-white/5 backdrop-blur-xl p-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.1 }}
          whileHover={{ scale: 1.05 }}
        >
          <div className={`absolute -top-4 -right-4 w-16 h-16 ${metric.color} opacity-10 rounded-full`} />
          <metric.icon className={`w-8 h-8 mb-4 ${metric.color} text-white`} />
          <h3 className="text-3xl font-bold mb-2">{metric.value}</h3>
          <p className="text-sm text-gray-500">{metric.label}</p>
        </motion.div>
      ))}
    </div>
  );
};