import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { 
  ChevronLeft, 
  ChevronRight, 
  DollarSign,
  Calendar,
  User,
  Building,
  TrendingUp,
  GripHorizontal
} from "lucide-react";
import { toast } from "sonner";

interface DealCardProps {
  deal: any;
  onMove: (dealId: string, currentStage: any, direction: 'left' | 'right') => void;
  isMoving: boolean;
  isFirstStage: boolean;
  isLastStage: boolean;
  isMobile: boolean;
}

export const DealCard = ({
  deal,
  onMove,
  isMoving,
  isFirstStage,
  isLastStage,
  isMobile,
}: DealCardProps) => {
  const navigate = useNavigate();
  const probability = deal.deal_analytics?.[0]?.probability_score || 0;
  const probabilityColor = probability > 0.7 ? 'text-green-500' : 
                          probability > 0.4 ? 'text-yellow-500' : 
                          'text-red-500';

  const handleDealClick = () => {
    console.log("Navigating to deal:", deal.id);
    try {
      navigate(`/admin/deals/${deal.id}`);
    } catch (error) {
      console.error("Navigation error:", error);
      toast.error("Failed to navigate to deal details");
    }
  };

  const handleMoveClick = (e: React.MouseEvent, direction: 'left' | 'right') => {
    e.preventDefault();
    e.stopPropagation();
    onMove(deal.id, deal.stage, direction);
  };

  const handleDragStart = (e: React.DragEvent) => {
    e.dataTransfer.setData('dealId', deal.id);
    e.dataTransfer.setData('currentStage', deal.stage);
  };

  return (
    <Card 
      className="p-4 bg-card hover:bg-accent/5 transition-colors duration-200 cursor-move group relative border border-border/50 shadow-sm"
      onClick={handleDealClick}
      draggable
      onDragStart={handleDragStart}
    >
      <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity">
        <GripHorizontal className="w-4 h-4 text-muted-foreground" />
      </div>

      <div className="flex justify-between items-start gap-2">
        <div className="min-w-0 flex-1">
          <h3 className="font-medium truncate text-foreground mb-3">{deal.name}</h3>
          
          <div className="space-y-2">
            <div className="flex items-center gap-2 text-sm text-muted-foreground">
              <DollarSign className="w-4 h-4 shrink-0" />
              <span className="truncate">${deal.value?.toLocaleString()}</span>
            </div>

            {deal.expected_close_date && (
              <div className="flex items-center gap-2 text-sm text-muted-foreground">
                <Calendar className="w-4 h-4 shrink-0" />
                <span className="truncate">
                  {new Date(deal.expected_close_date).toLocaleDateString()}
                </span>
              </div>
            )}

            {deal.contacts && (
              <div className="flex items-center gap-2 text-sm text-muted-foreground">
                <User className="w-4 h-4 shrink-0" />
                <span className="truncate">
                  {`${deal.contacts.first_name} ${deal.contacts.last_name}`}
                </span>
              </div>
            )}

            {deal.companies && (
              <div className="flex items-center gap-2 text-sm text-muted-foreground">
                <Building className="w-4 h-4 shrink-0" />
                <span className="truncate">{deal.companies.name}</span>
              </div>
            )}

            <div className="flex items-center gap-2 text-sm">
              <TrendingUp className={`w-4 h-4 shrink-0 ${probabilityColor}`} />
              <span className={`truncate ${probabilityColor}`}>
                {Math.round(probability * 100)}% Probability
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-1 shrink-0">
          {!isFirstStage && (
            <Button
              variant="ghost"
              size="icon"
              onClick={(e) => handleMoveClick(e, 'left')}
              disabled={isMoving}
              className="h-8 w-8 hover:bg-accent"
            >
              <ChevronLeft className="h-4 w-4" />
            </Button>
          )}
          
          {!isLastStage && (
            <Button
              variant="ghost"
              size="icon"
              onClick={(e) => handleMoveClick(e, 'right')}
              disabled={isMoving}
              className="h-8 w-8 hover:bg-accent"
            >
              <ChevronRight className="h-4 w-4" />
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
};