import { motion } from "framer-motion";
import { Loader2 } from "lucide-react";

interface AnalysisProgressProps {
  isGenerating: boolean;
  currentStep: string;
}

export const AnalysisProgress = ({ isGenerating, currentStep }: AnalysisProgressProps) => {
  const steps = [
    "Processing project requirements",
    "Analyzing market trends",
    "Generating technical recommendations",
    "Calculating timeline and budget",
  ];

  const currentStepIndex = steps.indexOf(currentStep);

  return isGenerating ? (
    <div className="fixed inset-0 bg-background/90 dark:bg-slate-900/90 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="max-w-md w-full space-y-6 p-6">
        <div className="text-center space-y-4">
          <Loader2 className="w-12 h-12 animate-spin mx-auto text-brand-orange" />
          <h3 className="text-xl font-semibold text-foreground">{currentStep}</h3>
          <div className="w-full bg-secondary/20 dark:bg-secondary/10 rounded-full h-2">
            <motion.div
              className="bg-brand-orange h-2 rounded-full"
              initial={{ width: "0%" }}
              animate={{ width: `${((currentStepIndex + 1) / steps.length) * 100}%` }}
              transition={{ duration: 0.5 }}
            />
          </div>
          <p className="text-sm text-muted-foreground">
            This typically takes 30-45 seconds
          </p>
        </div>
      </div>
    </div>
  ) : null;
};