
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { motion } from "framer-motion";
import { Filter, X } from "lucide-react";
import { useState } from "react";

interface FiltersProps {
  selectedTags: string[];
  selectedIndustry: string;
  industries: string[];
  allTags: string[];
  onTagClick: (tag: string) => void;
  onIndustrySelect: (industry: string) => void;
}

export const Filters = ({
  selectedTags,
  selectedIndustry,
  industries,
  allTags,
  onTagClick,
  onIndustrySelect,
}: FiltersProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  const toggleFilters = () => {
    setIsExpanded(prev => !prev);
  };
  
  const hasActiveFilters = selectedTags.length > 0 || selectedIndustry !== 'all';

  return (
    <motion.div 
      layout
      className="space-y-6 mb-12 bg-accent/20 p-6 rounded-xl backdrop-blur-sm border border-white/5"
    >
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold flex items-center">
          <Filter className="h-5 w-5 mr-2 text-secondary" />
          Filters
          {hasActiveFilters && (
            <Badge variant="secondary" className="ml-2">
              {selectedTags.length + (selectedIndustry !== 'all' ? 1 : 0)}
            </Badge>
          )}
        </h3>
        <Button
          variant="ghost"
          size="sm"
          onClick={toggleFilters}
          className="text-secondary"
        >
          {isExpanded ? "Hide Filters" : "Show Filters"}
        </Button>
      </div>

      {/* Active filters summary */}
      {hasActiveFilters && (
        <div className="flex flex-wrap gap-2 items-center">
          <span className="text-sm text-muted-foreground">Active filters:</span>
          {selectedIndustry !== 'all' && (
            <Badge 
              variant="outline" 
              className="bg-secondary/10 text-secondary flex items-center gap-1 hover:bg-secondary/20"
            >
              Industry: {selectedIndustry}
              <X 
                className="h-3 w-3 ml-1 cursor-pointer" 
                onClick={() => onIndustrySelect('all')}
              />
            </Badge>
          )}
          {selectedTags.map(tag => (
            <Badge 
              key={tag} 
              variant="outline" 
              className="bg-secondary/10 text-secondary flex items-center gap-1 hover:bg-secondary/20"
            >
              {tag}
              <X 
                className="h-3 w-3 ml-1 cursor-pointer" 
                onClick={() => onTagClick(tag)}
              />
            </Badge>
          ))}
        </div>
      )}

      {/* Collapsible filters */}
      <motion.div
        animate={{ height: isExpanded ? "auto" : 0, opacity: isExpanded ? 1 : 0 }}
        initial={{ height: 0, opacity: 0 }}
        className="overflow-hidden"
      >
        <div className="grid md:grid-cols-2 gap-8 pt-2">
          <div>
            <h4 className="text-md font-medium mb-4">Filter by Industry</h4>
            <div className="flex flex-wrap gap-2">
              {industries.map((industry) => (
                <Button
                  key={industry}
                  variant={selectedIndustry === industry ? "secondary" : "outline"}
                  size="sm"
                  onClick={() => onIndustrySelect(industry)}
                  className="capitalize hover:bg-secondary/20 transition-colors"
                >
                  {industry}
                </Button>
              ))}
            </div>
          </div>

          <div>
            <h4 className="text-md font-medium mb-4">Filter by Technology</h4>
            <div className="flex flex-wrap gap-2">
              {allTags.map((tag) => (
                <Badge
                  key={tag}
                  variant={selectedTags.includes(tag) ? "secondary" : "outline"}
                  className="cursor-pointer hover:bg-secondary/20 transition-colors px-3 py-1"
                  onClick={() => onTagClick(tag)}
                >
                  {tag}
                </Badge>
              ))}
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};
