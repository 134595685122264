
import { memo } from 'react';
import { Handle, Position } from '@xyflow/react';
import { ScreenType } from '../types';
import { 
  LogIn, UserPlus, Key, Shield, Users, Presentation,
  LayoutDashboard, Sidebar, Menu, Navigation, Zap,
  UserRound, UserCog, Settings, Lock, CreditCard, Bell, Languages,
  LayoutGrid, FileText, Upload, Edit3, Image, FileSearch,
  FormInput, ScrollText, Search, Calendar, FileUp, Type, ClipboardList,
  SearchCheck, Filter, Database, Tag,
  MessageSquare, MessageCircle, Activity, MessagesSquare, Phone,
  CheckSquare, Kanban, BarChart, GitBranch, Workflow,
  PieChart, Download, TrendingUp,
  ShoppingBag, ShoppingCart, Package, Receipt, DollarSign,
  HelpCircle, Book, Ticket, Headset, Bot,
  Link, Flag, History,
  UserCircle, Heart, Trophy,
  BellRing, Mail, InboxIcon,
  Plug, CircleDot, Loader, AlertOctagon, LogOut,
  Home, Info, MapPin, LayoutTemplate
} from "lucide-react";
import { cn } from "@/lib/utils";

const screenTypeConfig: Record<ScreenType, {
  icon: any;
  color: string;
  label: string;
}> = {
  // Website Pages
  landing_hero: { icon: Home, color: 'border-blue-500 bg-blue-500/10', label: 'Hero Section' },
  landing_features: { icon: Activity, color: 'border-blue-500 bg-blue-500/10', label: 'Features Section' },
  landing_cta: { icon: MessageSquare, color: 'border-blue-500 bg-blue-500/10', label: 'Call to Action' },
  about_us: { icon: Info, color: 'border-blue-500 bg-blue-500/10', label: 'About Us' },
  about_team: { icon: Users, color: 'border-blue-500 bg-blue-500/10', label: 'Team Section' },
  contact_form: { icon: Mail, color: 'border-blue-500 bg-blue-500/10', label: 'Contact Form' },
  contact_info: { icon: Phone, color: 'border-blue-500 bg-blue-500/10', label: 'Contact Info' },
  contact_map: { icon: MapPin, color: 'border-blue-500 bg-blue-500/10', label: 'Location Map' },

  auth_login: { icon: LogIn, color: 'border-blue-500 bg-blue-500/10', label: 'Login' },
  auth_signup: { icon: UserPlus, color: 'border-blue-500 bg-blue-500/10', label: 'Signup' },
  auth_reset: { icon: Key, color: 'border-blue-500 bg-blue-500/10', label: 'Reset Password' },
  auth_2fa: { icon: Shield, color: 'border-blue-500 bg-blue-500/10', label: '2FA Setup' },
  auth_social: { icon: Users, color: 'border-blue-500 bg-blue-500/10', label: 'Social Login' },
  auth_onboarding: { icon: Presentation, color: 'border-blue-500 bg-blue-500/10', label: 'User Onboarding' },

  // Dashboard & Navigation
  dash_main: { icon: LayoutDashboard, color: 'border-purple-500 bg-purple-500/10', label: 'Main Dashboard' },
  dash_sidebar: { icon: Sidebar, color: 'border-purple-500 bg-purple-500/10', label: 'Sidebar Navigation' },
  dash_bottom_nav: { icon: Menu, color: 'border-purple-500 bg-purple-500/10', label: 'Bottom Navigation' },
  dash_breadcrumb: { icon: Navigation, color: 'border-purple-500 bg-purple-500/10', label: 'Breadcrumb Navigation' },
  dash_quick_actions: { icon: Zap, color: 'border-purple-500 bg-purple-500/10', label: 'Quick Actions' },

  // Profile & Account
  profile_view: { icon: UserRound, color: 'border-green-500 bg-green-500/10', label: 'Profile View' },
  profile_edit: { icon: UserCog, color: 'border-green-500 bg-green-500/10', label: 'Edit Profile' },
  profile_settings: { icon: Settings, color: 'border-green-500 bg-green-500/10', label: 'Account Settings' },
  profile_privacy: { icon: Lock, color: 'border-green-500 bg-green-500/10', label: 'Privacy Settings' },
  profile_billing: { icon: CreditCard, color: 'border-green-500 bg-green-500/10', label: 'Billing Management' },
  profile_notifications: { icon: Bell, color: 'border-green-500 bg-green-500/10', label: 'Notifications Settings' },
  profile_language: { icon: Languages, color: 'border-green-500 bg-green-500/10', label: 'Language Settings' },

  // Content Management
  content_list: { icon: LayoutGrid, color: 'border-yellow-500 bg-yellow-500/10', label: 'List/Grid View' },
  content_detail: { icon: FileText, color: 'border-yellow-500 bg-yellow-500/10', label: 'Detail Page' },
  content_upload: { icon: Upload, color: 'border-yellow-500 bg-yellow-500/10', label: 'File Upload' },
  content_editor: { icon: Edit3, color: 'border-yellow-500 bg-yellow-500/10', label: 'Rich Text Editor' },
  content_gallery: { icon: Image, color: 'border-yellow-500 bg-yellow-500/10', label: 'Media Gallery' },
  content_viewer: { icon: FileSearch, color: 'border-yellow-500 bg-yellow-500/10', label: 'Document Viewer' },

  // Forms
  form_basic: { icon: FormInput, color: 'border-pink-500 bg-pink-500/10', label: 'Basic Forms' },
  form_wizard: { icon: ScrollText, color: 'border-pink-500 bg-pink-500/10', label: 'Multi-step Forms' },
  form_search: { icon: Search, color: 'border-pink-500 bg-pink-500/10', label: 'Search Forms' },
  form_date: { icon: Calendar, color: 'border-pink-500 bg-pink-500/10', label: 'Date Input' },
  form_upload: { icon: FileUp, color: 'border-pink-500 bg-pink-500/10', label: 'File Upload' },
  form_rich: { icon: Type, color: 'border-pink-500 bg-pink-500/10', label: 'Rich Text Input' },
  form_survey: { icon: ClipboardList, color: 'border-pink-500 bg-pink-500/10', label: 'Survey Form' },

  // Search
  search_global: { icon: Search, color: 'border-orange-500 bg-orange-500/10', label: 'Global Search' },
  search_advanced: { icon: SearchCheck, color: 'border-orange-500 bg-orange-500/10', label: 'Advanced Search' },
  search_faceted: { icon: Filter, color: 'border-orange-500 bg-orange-500/10', label: 'Faceted Search' },
  search_auto: { icon: Database, color: 'border-orange-500 bg-orange-500/10', label: 'Auto-suggestions' },
  search_tags: { icon: Tag, color: 'border-orange-500 bg-orange-500/10', label: 'Tag System' },

  // Collaboration
  collab_chat: { icon: MessageSquare, color: 'border-indigo-500 bg-indigo-500/10', label: 'Chat Interface' },
  collab_comments: { icon: MessageCircle, color: 'border-indigo-500 bg-indigo-500/10', label: 'Comments Section' },
  collab_activity: { icon: Activity, color: 'border-indigo-500 bg-indigo-500/10', label: 'Activity Feed' },
  collab_forum: { icon: MessagesSquare, color: 'border-indigo-500 bg-indigo-500/10', label: 'Forum Board' },
  collab_calls: { icon: Phone, color: 'border-indigo-500 bg-indigo-500/10', label: 'Voice/Video Calls' },

  // Tasks
  task_list: { icon: CheckSquare, color: 'border-red-500 bg-red-500/10', label: 'Task List' },
  task_kanban: { icon: Kanban, color: 'border-red-500 bg-red-500/10', label: 'Kanban Board' },
  task_dashboard: { icon: BarChart, color: 'border-red-500 bg-red-500/10', label: 'Task Dashboard' },
  task_gantt: { icon: GitBranch, color: 'border-red-500 bg-red-500/10', label: 'Gantt Chart' },
  task_workflow: { icon: Workflow, color: 'border-red-500 bg-red-500/10', label: 'Workflow' },

  // Analytics
  analytics_dashboard: { icon: PieChart, color: 'border-cyan-500 bg-cyan-500/10', label: 'Analytics Dashboard' },
  analytics_reports: { icon: BarChart, color: 'border-cyan-500 bg-cyan-500/10', label: 'Reports' },
  analytics_export: { icon: Download, color: 'border-cyan-500 bg-cyan-500/10', label: 'Export Data' },
  analytics_charts: { icon: TrendingUp, color: 'border-cyan-500 bg-cyan-500/10', label: 'Charts' },

  // E-commerce
  ecom_products: { icon: ShoppingBag, color: 'border-emerald-500 bg-emerald-500/10', label: 'Product Listings' },
  ecom_detail: { icon: Package, color: 'border-emerald-500 bg-emerald-500/10', label: 'Product Detail' },
  ecom_cart: { icon: ShoppingCart, color: 'border-emerald-500 bg-emerald-500/10', label: 'Shopping Cart' },
  ecom_checkout: { icon: CreditCard, color: 'border-emerald-500 bg-emerald-500/10', label: 'Checkout' },
  ecom_orders: { icon: Package, color: 'border-emerald-500 bg-emerald-500/10', label: 'Orders' },
  ecom_payments: { icon: DollarSign, color: 'border-emerald-500 bg-emerald-500/10', label: 'Payments' },

  // Support (Added the missing screen types)
  support_faq: { icon: HelpCircle, color: 'border-rose-500 bg-rose-500/10', label: 'FAQ' },
  support_kb: { icon: Book, color: 'border-rose-500 bg-rose-500/10', label: 'Knowledge Base' },
  support_tickets: { icon: Ticket, color: 'border-rose-500 bg-rose-500/10', label: 'Support Tickets' },
  support_chat: { icon: Headset, color: 'border-rose-500 bg-rose-500/10', label: 'Live Chat' },
  support_bot: { icon: Bot, color: 'border-rose-500 bg-rose-500/10', label: 'Chatbot' },

  // Admin
  admin_dashboard: { icon: Shield, color: 'border-rose-500 bg-rose-500/10', label: 'Admin Dashboard' },
  admin_users: { icon: Users, color: 'border-rose-500 bg-rose-500/10', label: 'User Management' },
  admin_roles: { icon: Key, color: 'border-rose-500 bg-rose-500/10', label: 'Roles & Permissions' },
  admin_moderation: { icon: Shield, color: 'border-rose-500 bg-rose-500/10', label: 'Content Moderation' },
  admin_logs: { icon: Activity, color: 'border-rose-500 bg-rose-500/10', label: 'System Logs' },

  // Social
  social_profile: { icon: UserCircle, color: 'border-fuchsia-500 bg-fuchsia-500/10', label: 'Social Profile' },
  social_friends: { icon: Users, color: 'border-fuchsia-500 bg-fuchsia-500/10', label: 'Friends System' },
  social_reactions: { icon: Heart, color: 'border-fuchsia-500 bg-fuchsia-500/10', label: 'Reactions' },
  social_events: { icon: Calendar, color: 'border-fuchsia-500 bg-fuchsia-500/10', label: 'Events' },
  social_gamification: { icon: Trophy, color: 'border-fuchsia-500 bg-fuchsia-500/10', label: 'Gamification' },

  // Notifications
  notif_push: { icon: BellRing, color: 'border-lime-500 bg-lime-500/10', label: 'Push Notifications' },
  notif_email: { icon: Mail, color: 'border-lime-500 bg-lime-500/10', label: 'Email Notifications' },
  notif_center: { icon: InboxIcon, color: 'border-lime-500 bg-lime-500/10', label: 'Notification Center' },
  notif_activity: { icon: Activity, color: 'border-lime-500 bg-lime-500/10', label: 'Activity Log' },

  // Integration
  integration_oauth: { icon: Key, color: 'border-sky-500 bg-sky-500/10', label: 'OAuth Setup' },
  integration_webhooks: { icon: Link, color: 'border-sky-500 bg-sky-500/10', label: 'Webhooks' },
  integration_services: { icon: Plug, color: 'border-sky-500 bg-sky-500/10', label: 'Services' },

  // Legal
  legal_terms: { icon: FileText, color: 'border-neutral-500 bg-neutral-500/10', label: 'Terms & Conditions' },
  legal_privacy: { icon: Shield, color: 'border-neutral-500 bg-neutral-500/10', label: 'Privacy Policy' },
  legal_gdpr: { icon: Shield, color: 'border-neutral-500 bg-neutral-500/10', label: 'GDPR Compliance' },
  legal_cookies: { icon: Package, color: 'border-neutral-500 bg-neutral-500/10', label: 'Cookie Settings' },

  // Utility
  utility_splash: { icon: Loader, color: 'border-slate-500 bg-slate-500/10', label: 'Splash Screen' },
  utility_maintenance: { icon: Settings, color: 'border-slate-500 bg-slate-500/10', label: 'Maintenance' },
  utility_404: { icon: AlertOctagon, color: 'border-slate-500 bg-slate-500/10', label: '404 Page' },
  utility_expired: { icon: LogOut, color: 'border-slate-500 bg-slate-500/10', label: 'Session Expired' },
  utility_status: { icon: CircleDot, color: 'border-slate-500 bg-slate-500/10', label: 'Status Page' }
};

interface ScreenNodeData {
  label: string;
  description?: string;
  type: ScreenType;
  features: {
    ui: string[];
    backend: string[];
    shared: string[];
  };
  wireframe?: {
    elements?: any[];
    generatedImage?: string;
    lastUpdated?: string;
  };
}

export const ScreenNode = memo(({ data }: { data: ScreenNodeData }) => {
  const config = screenTypeConfig[data.type];
  const Icon = config.icon;
  const hasWireframe = data.wireframe && data.wireframe.generatedImage;
  
  return (
    <div className={`min-w-[200px] min-h-[120px] rounded-lg border-2 ${config.color} p-4 backdrop-blur-sm`}>
      <Handle type="target" position={Position.Left} className="w-4 h-4 !bg-background border-2 border-secondary rounded-full" />
      <div className="space-y-2">
        <div className="flex items-center gap-2">
          <Icon className="w-4 h-4" />
          <div className="font-medium">{data.label}</div>
          {hasWireframe && (
            <div className="ml-auto">
              <LayoutTemplate className="w-3.5 h-3.5 text-blue-500" />
            </div>
          )}
        </div>
        {data.description && (
          <div className="text-sm text-muted-foreground">{data.description}</div>
        )}
        
        {hasWireframe && (
          <div className="mt-2 border border-border rounded overflow-hidden">
            <img 
              src={data.wireframe.generatedImage} 
              alt={`Wireframe for ${data.label}`}
              className="w-full h-auto max-h-24 object-cover"
            />
          </div>
        )}
        
        <div className="text-xs space-y-1">
          {data.features.ui.length > 0 && (
            <div className="text-blue-500">UI Features: {data.features.ui.length}</div>
          )}
          {data.features.backend.length > 0 && (
            <div className="text-purple-500">Backend Features: {data.features.backend.length}</div>
          )}
          {data.features.shared.length > 0 && (
            <div className="text-green-500">Shared Features: {data.features.shared.length}</div>
          )}
        </div>
      </div>
      <Handle type="source" position={Position.Right} className="w-4 h-4 !bg-background border-2 border-secondary rounded-full" />
    </div>
  );
});

ScreenNode.displayName = 'ScreenNode';
