
import { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, Navigate, useLocation } from 'react-router-dom';
import { WorkspaceSidebar } from './WorkspaceSidebar';
import { useWorkspaceData } from './hooks/useWorkspaceData';
import { CreateItemDialog } from './CreateItemDialog';
import { ListView } from './ListView';
import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';
import { TaskDetails } from './TaskDetails';
import { ThemeSwitcher } from '@/components/theme/ThemeSwitcher';
import { WorkspaceDashboard } from './WorkspaceDashboard';

export function WorkspaceLayout() {
  const {
    workspaces,
    spaces,
    folders,
    lists,
    selectedWorkspace,
    selectedSpace,
    selectedFolder,
    setSelectedWorkspace,
    setSelectedSpace,
    setSelectedFolder,
    fetchSpaces,
    fetchFolders,
    fetchLists,
    fetchWorkspaceData,
    deleteWorkspace,
    deleteSpace,
    deleteFolder,
    deleteList,
  } = useWorkspaceData(undefined);

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [createDialogType, setCreateDialogType] = useState<'workspace' | 'space' | 'folder' | 'list'>('space');
  const navigate = useNavigate();
  const location = useLocation();

  // Handle legacy routes
  useEffect(() => {
    if (location.pathname.startsWith('/workspace/list/') && selectedWorkspace) {
      const listId = location.pathname.split('/list/')[1].split('/')[0];
      navigate(`/workspace/${selectedWorkspace}/list/${listId}`, { replace: true });
    }
  }, [location.pathname, selectedWorkspace]);

  const handleCreateItem = (type: 'workspace' | 'space' | 'folder' | 'list', parentId?: string) => {
    setCreateDialogType(type);
    if (parentId) {
      if (type === 'folder') {
        setSelectedSpace(parentId);
      } else if (type === 'list') {
        setSelectedFolder(parentId);
      }
    }
    setIsCreateDialogOpen(true);
  };

  const handleCreateSuccess = async () => {
    setIsCreateDialogOpen(false);
    if (createDialogType === 'workspace') {
      await fetchWorkspaceData();
    } else if (createDialogType === 'space' && selectedWorkspace) {
      await fetchSpaces(selectedWorkspace);
    } else if (createDialogType === 'folder' && selectedSpace) {
      console.log('Fetching folders for space:', selectedSpace);
      await fetchFolders(selectedSpace);
    } else if (createDialogType === 'list' && selectedFolder) {
      await fetchLists(selectedFolder);
    }
  };

  const getMainContent = () => {
    if (!selectedWorkspace) {
      return (
        <div className="flex flex-col items-center justify-center h-full text-center p-8">
          <h2 className="text-2xl font-semibold mb-2">Select a Workspace</h2>
          <p className="text-muted-foreground mb-4">Choose a workspace from the sidebar to view its contents</p>
          <Button onClick={() => handleCreateItem('workspace')} className="gap-2">
            <Plus className="h-4 w-4" />
            New Workspace
          </Button>
        </div>
      );
    }

    return (
      <Routes>
        <Route path="/" element={<Navigate to={`/workspace/${selectedWorkspace}`} replace />} />
        <Route path=":workspaceId" element={<WorkspaceDashboard />} />
        <Route path=":workspaceId/list/:listId/task/:taskId" element={<TaskDetails />} />
        <Route path=":workspaceId/list/:listId" element={<ListView />} />
        {/* Legacy route handling */}
        <Route path="list/:listId" element={
          selectedWorkspace ? 
          <Navigate to={`/workspace/${selectedWorkspace}/list/${location.pathname.split('/list/')[1]}`} replace /> :
          null
        } />
      </Routes>
    );
  };

  return (
    <div className="min-h-screen bg-[#f2f0e3] dark:bg-[#1f1f1f] pt-24 transition-colors duration-200">
      <div className="container mx-auto px-6 h-[calc(100vh-6rem)]">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold">Workspace</h1>
          <ThemeSwitcher />
        </div>
        <div className="flex h-full gap-6 rounded-lg border border-accent overflow-hidden">
          <WorkspaceSidebar
            workspaces={workspaces}
            spaces={spaces}
            folders={folders}
            lists={lists}
            selectedWorkspace={selectedWorkspace}
            selectedSpace={selectedSpace}
            selectedFolder={selectedFolder}
            onWorkspaceClick={async (wsId) => {
              setSelectedWorkspace(wsId);
              await fetchSpaces(wsId);
              navigate(`/workspace/${wsId}`);
            }}
            onSpaceClick={async (spaceId) => {
              setSelectedSpace(spaceId);
              await fetchFolders(spaceId);
            }}
            onFolderClick={async (folderId) => {
              setSelectedFolder(folderId);
              await fetchLists(folderId);
            }}
            onListClick={(listId) => {
              if (selectedWorkspace) {
                navigate(`/workspace/${selectedWorkspace}/list/${listId}`);
              }
            }}
            onNewWorkspace={() => handleCreateItem('workspace')}
            onNewSpace={() => handleCreateItem('space')}
            onNewFolder={(spaceId) => handleCreateItem('folder', spaceId)}
            onNewList={(folderId) => handleCreateItem('list', folderId)}
            deleteWorkspace={deleteWorkspace}
            deleteSpace={deleteSpace}
            deleteFolder={deleteFolder}
            deleteList={deleteList}
            className="border-r border-accent w-[280px] shrink-0 bg-background"
          />
          <main className="flex-1 overflow-auto p-6 bg-background">
            {getMainContent()}
          </main>
        </div>
      </div>

      <CreateItemDialog
        type={createDialogType}
        isOpen={isCreateDialogOpen}
        onOpenChange={setIsCreateDialogOpen}
        onSuccess={handleCreateSuccess}
        workspaceId={selectedWorkspace || undefined}
        spaceId={selectedSpace || undefined}
        folderId={selectedFolder || undefined}
      />
    </div>
  );
}
