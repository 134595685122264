import { Users, Target, Clock } from "lucide-react";

interface GuideMetricsProps {
  type: string;
}

export const GuideMetrics = ({ type }: GuideMetricsProps) => {
  return (
    <div className="flex justify-center gap-8 text-gray-600 dark:text-gray-400">
      <div className="flex items-center">
        <Users className="w-5 h-5 mr-2" />
        <span>For {type === 'checklist' ? 'Project Managers' : 'Developers'}</span>
      </div>
      <div className="flex items-center">
        <Target className="w-5 h-5 mr-2" />
        <span>{type === 'checklist' ? 'Project Planning' : 'Implementation'}</span>
      </div>
      <div className="flex items-center">
        <Clock className="w-5 h-5 mr-2" />
        <span>15-20 min read</span>
      </div>
    </div>
  );
};