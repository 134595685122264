import React from 'react';
import { Command } from 'cmdk';
import {
  MessageSquare,
  Calendar,
  FileCheck,
  HelpCircle,
  FileStack,
  GitPullRequest,
  Rocket,
  Timer,
  AlertCircle,
  Clock,
  Bot,
  FileText,
  Database,
  Share2,
  Bell,
  Loader2
} from 'lucide-react';
import { Command as CommandType } from '@/types/commands';

interface CommandPaletteProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (command: CommandType) => void;
  isLoading?: boolean;
}

const commands: CommandType[] = [
  // Client Collaboration Commands
  {
    id: 'feedback',
    label: 'Request Feedback',
    description: 'Request client feedback on this task',
    icon: MessageSquare,
    category: 'client',
    action: () => {}
  },
  {
    id: 'meeting',
    label: 'Schedule Meeting',
    description: 'Schedule a meeting about this task',
    icon: Calendar,
    category: 'client',
    action: () => {}
  },
  {
    id: 'approve',
    label: 'Request Approval',
    description: 'Request client approval for this task',
    icon: FileCheck,
    category: 'client',
    action: () => {}
  },
  {
    id: 'question',
    label: 'Ask Question',
    description: 'Ask a question about this task',
    icon: HelpCircle,
    category: 'client',
    action: () => {}
  },

  // Development Commands
  {
    id: 'pr',
    label: 'Link PR',
    description: 'Link a GitHub pull request',
    icon: GitPullRequest,
    category: 'development',
    action: () => {}
  },
  {
    id: 'deploy',
    label: 'Deployment Status',
    description: 'Show deployment status',
    icon: Rocket,
    category: 'development',
    action: () => {}
  },
  {
    id: 'estimate',
    label: 'Time Estimate',
    description: 'Add or update time estimate',
    icon: Timer,
    category: 'development',
    action: () => {}
  },
  {
    id: 'blocked',
    label: 'Flag Blocker',
    description: 'Flag a blocking issue',
    icon: AlertCircle,
    category: 'development',
    action: () => {}
  },

  // Project Management Commands
  {
    id: 'timeline',
    label: 'Show Timeline',
    description: 'View project timeline',
    icon: Clock,
    category: 'project',
    action: () => {}
  },
  {
    id: 'summary',
    label: 'Generate Summary',
    description: 'AI-generated task summary',
    icon: Bot,
    category: 'project',
    action: () => {}
  },

  // Documentation Commands
  {
    id: 'specs',
    label: 'Technical Specs',
    description: 'View/edit technical specifications',
    icon: FileText,
    category: 'documentation',
    action: () => {}
  },
  {
    id: 'docs',
    label: 'Documentation',
    description: 'Access project documentation',
    icon: FileStack,
    category: 'documentation',
    action: () => {}
  },
  {
    id: 'api',
    label: 'API Docs',
    description: 'View API documentation',
    icon: Database,
    category: 'documentation',
    action: () => {}
  },

  // Communication Commands
  {
    id: 'share',
    label: 'Share Task',
    description: 'Share this task',
    icon: Share2,
    category: 'communication',
    action: () => {}
  },
  {
    id: 'notify',
    label: 'Send Notification',
    description: 'Send a notification about this task',
    icon: Bell,
    category: 'communication',
    action: () => {}
  }
];

const groupedCommands = commands.reduce((groups, command) => {
  const group = groups.find(g => g.category === command.category);
  if (group) {
    group.commands.push(command);
  } else {
    groups.push({
      category: command.category,
      label: command.category.charAt(0).toUpperCase() + command.category.slice(1),
      commands: [command]
    });
  }
  return groups;
}, [] as { category: string; label: string; commands: CommandType[] }[]);

export function CommandPalette({ isOpen, onClose, onSelect, isLoading }: CommandPaletteProps) {
  return (
    <Command.Dialog
      open={isOpen}
      onOpenChange={onClose}
      className="fixed inset-0 z-50 flex items-start justify-center pt-24"
    >
      <div className="relative w-full max-w-lg rounded-lg border border-zinc-800 bg-background shadow-lg">
        <Command.Input
          placeholder="Type a command or search..."
          className="w-full border-0 border-b border-zinc-800 bg-transparent px-4 py-3 text-foreground outline-none placeholder:text-muted-foreground"
          disabled={isLoading}
        />
        <Command.List className="max-h-[300px] overflow-y-auto p-2">
          {groupedCommands.map((group) => (
            <Command.Group key={group.category} heading={group.label}>
              {group.commands.map((command) => (
                <Command.Item
                  key={command.id}
                  onSelect={() => {
                    if (!isLoading) {
                      onSelect(command);
                      onClose();
                    }
                  }}
                  className="flex items-center gap-2 rounded-md px-2 py-1.5 text-sm hover:bg-accent cursor-pointer"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Loader2 className="h-4 w-4 animate-spin" />
                  ) : (
                    command.icon && <command.icon className="h-4 w-4" />
                  )}
                  <div>
                    <div className="font-medium">{command.label}</div>
                    <div className="text-xs text-muted-foreground">{command.description}</div>
                  </div>
                </Command.Item>
              ))}
            </Command.Group>
          ))}
        </Command.List>
      </div>
    </Command.Dialog>
  );
}
