import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

interface ContactSelectProps {
  companyId?: string;
  value?: string;
  onChange: (value: string) => void;
}

export const ContactSelect = ({ companyId, value, onChange }: ContactSelectProps) => {
  const { data: contacts, isLoading } = useQuery({
    queryKey: ["company-contacts", companyId],
    queryFn: async () => {
      if (!companyId) return [];
      const { data, error } = await supabase
        .from("contacts")
        .select("id, first_name, last_name, job_title")
        .eq("company_id", companyId)
        .order("first_name");
      if (error) throw error;
      return data;
    },
    enabled: !!companyId,
  });

  if (!companyId) {
    return (
      <Select disabled>
        <SelectTrigger>
          <SelectValue placeholder="Select a company first" />
        </SelectTrigger>
      </Select>
    );
  }

  if (isLoading) {
    return (
      <Select disabled>
        <SelectTrigger>
          <SelectValue placeholder="Loading contacts..." />
        </SelectTrigger>
      </Select>
    );
  }

  if (!contacts || contacts.length === 0) {
    return (
      <Select disabled>
        <SelectTrigger>
          <SelectValue placeholder="No contacts available - create one below" />
        </SelectTrigger>
      </Select>
    );
  }

  return (
    <Select value={value} onValueChange={onChange}>
      <SelectTrigger>
        <SelectValue placeholder="Select contact" />
      </SelectTrigger>
      <SelectContent>
        {contacts.map((contact) => (
          <SelectItem key={contact.id} value={contact.id}>
            {contact.first_name} {contact.last_name}
            {contact.job_title && ` - ${contact.job_title}`}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};