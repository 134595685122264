
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Lock, ArrowRight } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card } from "@/components/ui/card";
import { toast } from "sonner";
import { useLocalStorage } from "@/hooks/use-local-storage";

interface PasswordProtectionProps {
  children: React.ReactNode;
  redirectPath?: string;
}

export const PasswordProtection = ({ children, redirectPath = "/flare" }: PasswordProtectionProps) => {
  const [password, setPassword] = useState("");
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [storedAuth, setStoredAuth] = useLocalStorage("flare-canvas-auth", false);
  const navigate = useNavigate();
  const location = useLocation();
  
  // Check if the user is already authorized from localStorage
  useEffect(() => {
    if (storedAuth === true) {
      setIsAuthorized(true);
    }
  }, [storedAuth]);

  const handlePasswordSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Check if password matches
    if (password === "dogoodthings") {
      setIsAuthorized(true);
      setStoredAuth(true);
      toast.success("Access granted to Flare Canvas");
    } else {
      toast.error("Incorrect password");
      setPassword("");
    }
  };

  const handleCancel = () => {
    navigate(redirectPath);
  };

  // If authorized, render the children
  if (isAuthorized) {
    return <>{children}</>;
  }

  return (
    <div className="min-h-screen bg-background flex items-center justify-center p-4">
      <Card className="w-full max-w-md p-6 space-y-6 border-brand-orange/20 bg-black/90">
        <div className="flex flex-col items-center text-center space-y-3">
          <div className="w-12 h-12 bg-brand-orange/10 rounded-full flex items-center justify-center">
            <Lock className="w-6 h-6 text-brand-orange" />
          </div>
          <h2 className="text-2xl font-bold">Protected Area</h2>
          <p className="text-muted-foreground">
            The Flare Canvas is password protected. Please enter the password to continue.
          </p>
        </div>

        <form onSubmit={handlePasswordSubmit} className="space-y-4">
          <div className="space-y-2">
            <Input
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="bg-background/50 border-brand-orange/20"
              autoFocus
            />
          </div>

          <div className="flex gap-2">
            <Button
              type="button"
              variant="outline"
              onClick={handleCancel}
              className="flex-1"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="flex-1 bg-brand-orange hover:bg-brand-orange/90"
            >
              Access Canvas <ArrowRight className="ml-2 h-4 w-4" />
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
};
