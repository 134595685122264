
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";

export const QuickAction = () => {
  return (
    <section className="py-24 md:py-38">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="max-w-[61.8%] mx-auto text-center space-y-10"
        >
          <h2 className="text-3xl md:text-4xl font-display font-bold bg-gradient-to-r from-white via-white to-white/70 bg-clip-text text-transparent">
            Ready to Launch Your Startup?
          </h2>
          
          <p className="text-lg text-gray-300 max-w-2xl mx-auto leading-relaxed">
            Get expert technical guidance and fixed-price development to bring your idea to life in just 4 weeks.
          </p>
          
          <div className="flex flex-col sm:flex-row items-center justify-center gap-6 pt-4">
            <Button 
              className="bg-brand-orange hover:bg-brand-orange/90 text-white rounded-full px-8 py-6 text-lg group w-full sm:w-auto"
              asChild
            >
              <Link to="/start">
                Start Your Project
                <ArrowRight className="ml-3 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </Button>
            
            <Button 
              variant="outline" 
              className="border-white/10 hover:bg-white/5 text-white rounded-full px-8 py-6 text-lg w-full sm:w-auto"
              asChild
            >
              <Link to="/flare">
                Learn About FLARE
              </Link>
            </Button>
          </div>
        </motion.div>
      </div>
    </section>
  );
};
