import { motion } from "framer-motion";
import { ArrowUpRight, ArrowDownRight, ArrowDownLeft, ArrowUpLeft } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";

export const ApplicationProcess = () => {
  const navigate = useNavigate();
  
  const steps = [
    {
      icon: ArrowUpRight,
      title: "Initial Application",
      description: "Submit your pitch deck and basic information through our smart application form",
      timeline: "1-2 days for initial review"
    },
    {
      icon: ArrowDownRight,
      title: "First Meeting",
      description: "Meet with our investment team to discuss your vision, market opportunity, and growth plans",
      timeline: "30-45 minute session"
    },
    {
      icon: ArrowDownLeft,
      title: "Due Diligence",
      description: "Deep dive into your business metrics, market analysis, and growth potential",
      timeline: "2-3 weeks process"
    },
    {
      icon: ArrowUpLeft,
      title: "Partnership Decision",
      description: "Final investment committee review and partnership terms discussion",
      timeline: "1-2 weeks for final decision"
    }
  ];

  return (
    <section className="py-24 bg-[#1A1F2C] relative overflow-hidden">
      {/* Subtle grid pattern */}
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#FF4D0005_1px,transparent_1px),linear-gradient(to_bottom,#FF4D0005_1px,transparent_1px)] bg-[size:4rem_4rem] pointer-events-none" />
      
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="max-w-7xl mx-auto"
        >
          <div className="text-center mb-16">
            <h2 className="text-5xl md:text-7xl font-mono uppercase tracking-tighter text-white/90 mb-6 transform -skew-x-6">
              Application Process
            </h2>
            <p className="text-xl text-white/60 max-w-2xl mx-auto">
              We've designed a founder-friendly process that helps us understand your vision while respecting your time
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
            {steps.map((step, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: index % 2 === 0 ? -20 : 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                viewport={{ once: true }}
                className="relative group"
              >
                <div className="absolute inset-0 bg-[#FF4D00]/5 transform -skew-x-6 group-hover:skew-x-6 transition-transform" />
                <div className="relative p-8 bg-[#222222]/30 backdrop-blur-sm border border-white/10 hover:border-[#FF4D00]/20 transition-all">
                  <step.icon className="w-12 h-12 text-[#FF4D00] mb-6" />
                  <h3 className="text-xl font-mono text-white/90 mb-3">{step.title}</h3>
                  <p className="text-white/60 mb-4">{step.description}</p>
                  <div className="pt-4 border-t border-white/10">
                    <span className="text-white/40 font-mono">{step.timeline}</span>
                  </div>
                </div>
                {index < steps.length - 1 && (
                  <div className="hidden lg:block absolute top-1/2 -right-4 transform -translate-y-1/2">
                    <ArrowUpRight className="w-8 h-8 text-[#FF4D00]/30" />
                  </div>
                )}
              </motion.div>
            ))}
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="max-w-3xl mx-auto text-center bg-[#222222]/50 backdrop-blur-sm border border-white/10 p-12 transform -skew-x-6"
          >
            <h3 className="text-2xl font-mono text-white/90 mb-4">Ready to Change the World?</h3>
            <p className="text-lg text-white/60 mb-8">
              We're excited to learn about your vision and how we can help bring it to life.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <Button 
                onClick={() => navigate('/ventures/apply')}
                size="lg"
                className="bg-[#FF4D00] hover:bg-[#FF4D00]/90 text-white group skew-x-6"
              >
                Submit Your Pitch
                <ArrowUpRight className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" />
              </Button>
              <Button
                variant="outline"
                size="lg"
                onClick={() => navigate('/ventures/criteria')}
                className="text-white border-white/20 hover:bg-white/5 group skew-x-6"
              >
                View Investment Criteria
                <ArrowUpRight className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" />
              </Button>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};