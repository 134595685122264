import { motion } from "framer-motion";
import { Card } from "@/components/ui/card";
import { CheckCircle2, Star, Clock } from "lucide-react";
import { Feature, FeatureSet } from "@/types/projectScoping";

interface FeatureRecommendationsProps {
  features: FeatureSet;
}

export const FeatureRecommendations = ({ features }: FeatureRecommendationsProps) => {
  return (
    <Card className="p-8">
      <h3 className="text-2xl font-bold mb-6">Recommended Features</h3>
      <div className="space-y-8">
        <div>
          <div className="flex items-center gap-2 mb-4">
            <Star className="w-5 h-5 text-[#FF4D00]" />
            <h4 className="text-lg font-semibold">Must-Have Features (MVP)</h4>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {features.mustHave.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="flex items-start gap-3 p-4 bg-gray-50 rounded-lg"
              >
                <CheckCircle2 className="w-5 h-5 text-[#FF4D00] flex-shrink-0 mt-1" />
                <div>
                  <h5 className="font-medium">{feature.name}</h5>
                  <p className="text-sm text-gray-600">{feature.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        <div>
          <div className="flex items-center gap-2 mb-4">
            <Clock className="w-5 h-5 text-blue-500" />
            <h4 className="text-lg font-semibold">Nice-to-Have Features</h4>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {features.niceToHave.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="flex items-start gap-3 p-4 bg-gray-50 rounded-lg"
              >
                <span className="w-2 h-2 rounded-full bg-blue-500 mt-2" />
                <div>
                  <h5 className="font-medium">{feature.name}</h5>
                  <p className="text-sm text-gray-600">{feature.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        {features.future && features.future.length > 0 && (
          <div>
            <div className="flex items-center gap-2 mb-4">
              <Clock className="w-5 h-5 text-purple-500" />
              <h4 className="text-lg font-semibold">Future Enhancements</h4>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {features.future.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="flex items-start gap-3 p-4 bg-gray-50 rounded-lg"
                >
                  <span className="w-2 h-2 rounded-full bg-purple-500 mt-2" />
                  <div>
                    <h5 className="font-medium">{feature.name}</h5>
                    <p className="text-sm text-gray-600">{feature.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};