
import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";
import { motion } from "framer-motion";
import { useNavigate, useLocation } from "react-router-dom";
import { ClientSegment } from "@/types/segments";

interface HeroActionsProps {
  clientSegment?: ClientSegment;
}

export const HeroActions = ({
  clientSegment
}: HeroActionsProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  
  const content = {
    startup: {
      primaryCta: "Start Your Project",
      secondaryCta: "View Case Studies",
      secondaryPath: "/work"
    },
    enterprise: {
      primaryCta: "Schedule Enterprise Consultation",
      secondaryCta: "View Case Studies",
      secondaryPath: "/work"
    },
    government: {
      primaryCta: "Request Information",
      secondaryCta: "View Case Studies",
      secondaryPath: "/work"
    }
  };
  
  const selectedContent = clientSegment ? content[clientSegment] : content.startup;
  
  const handlePrimaryClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (isHomePage) {
      const element = document.querySelector('#madlib-section');
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth'
        });
      }
    } else {
      navigate('/');
      // Need to wait for navigation to complete before scrolling
      setTimeout(() => {
        const element = document.querySelector('#madlib-section');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth'
          });
        }
      }, 300);
    }
  };
  
  return (
    <motion.div 
      initial={{
        opacity: 0,
        y: 20
      }} 
      animate={{
        opacity: 1,
        y: 0
      }} 
      transition={{
        delay: 0.5,
        duration: 0.5
      }} 
      className="flex flex-col sm:flex-row gap-4 mt-8 flex-wrap"
    >
      {/* We've removed the redundant button that was here */}
    </motion.div>
  );
};
