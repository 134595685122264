import { motion } from "framer-motion";
import { Card } from "@/components/ui/card";
import { Target, Users, TrendingUp, Lightbulb, Shield } from "lucide-react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

interface ProjectUnderstandingProps {
  understanding: {
    summary: string;
    targetMarket: string;
    competitors: string[];
    uniqueValue: string;
  };
  marketAnalysis: {
    marketSize: string;
    growthRate: string;
    trends: string[];
    opportunities: string[];
    risks: string[];
  };
  sources?: Array<{
    name: string;
    url?: string;
    year: string;
    type: string;
  }>;
}

export const ProjectUnderstanding = ({ understanding, marketAnalysis, sources }: ProjectUnderstandingProps) => {
  // Transform market data for visualization
  const marketTrendData = marketAnalysis.trends.map((trend, index) => ({
    name: `Trend ${index + 1}`,
    value: Math.random() * 100, // In a real app, this would come from actual data
    trend: trend
  }));

  return (
    <div className="space-y-6">
      <Card className="p-8 bg-gradient-to-br from-background via-background/95 to-accent/5">
        <div className="flex items-center gap-3 mb-6">
          <Target className="w-6 h-6 text-brand-orange" />
          <h3 className="text-2xl font-bold">Project Understanding</h3>
        </div>
        
        <div className="space-y-6">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="prose dark:prose-invert"
          >
            <p className="text-lg">{understanding.summary}</p>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-6">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="space-y-4"
            >
              <div className="flex items-center gap-2">
                <Users className="w-5 h-5 text-brand-orange" />
                <h4 className="font-semibold">Target Market</h4>
              </div>
              <p className="text-muted-foreground">{understanding.targetMarket}</p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="space-y-4"
            >
              <div className="flex items-center gap-2">
                <Lightbulb className="w-5 h-5 text-brand-orange" />
                <h4 className="font-semibold">Unique Value Proposition</h4>
              </div>
              <p className="text-muted-foreground">{understanding.uniqueValue}</p>
            </motion.div>
          </div>
        </div>
      </Card>

      <Card className="p-8 bg-gradient-to-br from-background via-background/95 to-accent/5">
        <div className="flex items-center gap-3 mb-6">
          <TrendingUp className="w-6 h-6 text-brand-orange" />
          <h3 className="text-2xl font-bold">Market Analysis</h3>
        </div>

        <div className="grid md:grid-cols-2 gap-8">
          <div className="space-y-6">
            <div>
              <h4 className="font-semibold mb-2">Market Size</h4>
              <p className="text-2xl font-bold text-brand-orange">{marketAnalysis.marketSize}</p>
              <p className="text-sm text-muted-foreground mt-1">Growth Rate: {marketAnalysis.growthRate}</p>
            </div>

            <div>
              <h4 className="font-semibold mb-3">Market Trends</h4>
              <div className="h-[200px] w-full">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={marketTrendData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip 
                      content={({ payload, label }) => {
                        if (payload && payload.length) {
                          return (
                            <div className="bg-background p-2 border rounded-lg shadow-lg">
                              <p className="font-semibold">{marketAnalysis.trends[parseInt(label.split(' ')[1]) - 1]}</p>
                            </div>
                          );
                        }
                        return null;
                      }}
                    />
                    <Bar dataKey="value" fill="#FF4D00" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>

          <div className="space-y-6">
            <div>
              <h4 className="font-semibold mb-3">Competitors</h4>
              <ul className="space-y-2">
                {understanding.competitors.map((competitor, index) => (
                  <motion.li
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="flex items-center gap-2"
                  >
                    <div className="w-2 h-2 rounded-full bg-brand-orange" />
                    <span>{competitor}</span>
                  </motion.li>
                ))}
              </ul>
            </div>

            <div>
              <h4 className="font-semibold mb-3">Opportunities & Risks</h4>
              <div className="grid gap-4">
                {marketAnalysis.opportunities.map((opportunity, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="p-3 bg-green-50/50 dark:bg-green-950/20 rounded-lg border border-green-100 dark:border-green-900"
                  >
                    <span className="text-green-700 dark:text-green-300">{opportunity}</span>
                  </motion.div>
                ))}
                {marketAnalysis.risks.map((risk, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="p-3 bg-red-50/50 dark:bg-red-950/20 rounded-lg border border-red-100 dark:border-red-900"
                  >
                    <span className="text-red-700 dark:text-red-300">{risk}</span>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Card>

      {sources && sources.length > 0 && (
        <Card className="p-6 bg-background/50">
          <div className="flex items-center gap-2 mb-4">
            <Shield className="w-5 h-5 text-brand-orange" />
            <h4 className="font-semibold">Research Sources</h4>
          </div>
          <div className="grid gap-3">
            {sources.map((source, index) => (
              <div key={index} className="text-sm text-muted-foreground">
                <p>
                  <span className="font-medium">{source.name}</span> ({source.year}) - {source.type}
                  {source.url && (
                    <a 
                      href={source.url} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="ml-2 text-brand-orange hover:underline"
                    >
                      View Source
                    </a>
                  )}
                </p>
              </div>
            ))}
          </div>
        </Card>
      )}
    </div>
  );
};