import { useQuery } from "@tanstack/react-query";
import { Card } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar } from 'recharts';
import { supabase } from "@/integrations/supabase/client";
import { motion } from "framer-motion";
import { Users, TrendingUp, LineChart as ChartIcon, ArrowUp, ArrowDown, Target, Zap, Activity } from "lucide-react";

interface SegmentStat {
  id: string;
  segment: string;
  metric_name: string;
  metric_value: string;
  created_at: string | null;
  updated_at: string | null;
}

interface GroupedStats {
  [key: string]: SegmentStat[];
}

export const AdminAnalytics = () => {
  const { data: visitorStats, isLoading: isLoadingVisitors } = useQuery({
    queryKey: ['visitor-analytics'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('visitor_analytics')
        .select('*')
        .order('date', { ascending: false })
        .limit(30);

      if (error) throw error;
      return data;
    },
  });

  const { data: segmentStats, isLoading: isLoadingSegments } = useQuery({
    queryKey: ['segment-statistics'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('segment_statistics')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data as SegmentStat[];
    },
  });

  const { data: trends, isLoading: isLoadingTrends } = useQuery({
    queryKey: ['industry-trends'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('industry_trends')
        .select('*')
        .eq('is_active', true);

      if (error) throw error;
      return data;
    },
  });

  if (isLoadingVisitors || isLoadingSegments || isLoadingTrends) {
    return <div className="flex items-center justify-center h-96">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
    </div>;
  }

  // Group segment stats by segment
  const groupedStats = segmentStats?.reduce<GroupedStats>((acc, stat) => {
    if (!acc[stat.segment]) {
      acc[stat.segment] = [];
    }
    acc[stat.segment].push(stat);
    return acc;
  }, {});

  const getMetricIcon = (metricName: string) => {
    if (metricName.toLowerCase().includes('conversion')) return <Target className="w-4 h-4" />;
    if (metricName.toLowerCase().includes('engagement')) return <Activity className="w-4 h-4" />;
    return <Zap className="w-4 h-4" />;
  };

  const getMetricDescription = (metricName: string) => {
    const descriptions: Record<string, string> = {
      'Conversion Rate': 'Percentage of visitors who complete desired actions',
      'Engagement Score': 'Measure of user interaction and involvement',
      'Growth Rate': 'Rate of increase in user base or revenue',
      'Retention Rate': 'Percentage of users who return',
      'Satisfaction Score': 'User satisfaction measurement'
    };
    return descriptions[metricName] || 'Key performance indicator';
  };

  return (
    <div className="space-y-8">
      <Card className="p-6">
        <div className="flex items-center gap-2 mb-6">
          <ChartIcon className="w-5 h-5 text-primary" />
          <h2 className="text-2xl font-bold">Analytics Dashboard</h2>
        </div>
        
        <Tabs defaultValue="visitors" className="space-y-4">
          <TabsList className="grid w-full grid-cols-3 lg:w-[400px]">
            <TabsTrigger value="visitors">Visitors</TabsTrigger>
            <TabsTrigger value="segments">Segments</TabsTrigger>
            <TabsTrigger value="trends">Trends</TabsTrigger>
          </TabsList>

          <TabsContent value="visitors" className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <Card className="p-4 bg-gradient-to-br from-blue-50 to-blue-100 dark:from-blue-900/20 dark:to-blue-800/20">
                <div className="flex items-center gap-3">
                  <Users className="w-8 h-8 text-blue-600 dark:text-blue-400" />
                  <div>
                    <h3 className="text-sm font-medium text-muted-foreground">Total Visitors</h3>
                    <p className="text-2xl font-bold">
                      {visitorStats?.reduce((sum, stat) => sum + stat.visitor_count, 0)?.toLocaleString() || 0}
                    </p>
                  </div>
                </div>
              </Card>

              <Card className="p-4 bg-gradient-to-br from-green-50 to-green-100 dark:from-green-900/20 dark:to-green-800/20">
                <div className="flex items-center gap-3">
                  <TrendingUp className="w-8 h-8 text-green-600 dark:text-green-400" />
                  <div>
                    <h3 className="text-sm font-medium text-muted-foreground">Today's Visitors</h3>
                    <p className="text-2xl font-bold">
                      {visitorStats?.[0]?.visitor_count?.toLocaleString() || 0}
                    </p>
                  </div>
                </div>
              </Card>

              <Card className="p-4 bg-gradient-to-br from-purple-50 to-purple-100 dark:from-purple-900/20 dark:to-purple-800/20">
                <div className="flex items-center gap-3">
                  <Users className="w-8 h-8 text-purple-600 dark:text-purple-400" />
                  <div>
                    <h3 className="text-sm font-medium text-muted-foreground">Active Segments</h3>
                    <p className="text-2xl font-bold">
                      {new Set(visitorStats?.map(stat => stat.segment)).size}
                    </p>
                  </div>
                </div>
              </Card>
            </div>

            <Card className="p-6">
              <h3 className="text-lg font-medium mb-4">Visitor Trends</h3>
              <div className="h-[400px]">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={visitorStats?.reverse()}>
                    <CartesianGrid strokeDasharray="3 3" className="stroke-gray-200 dark:stroke-gray-700" />
                    <XAxis 
                      dataKey="date" 
                      className="text-xs"
                      tick={{ fill: 'currentColor' }}
                    />
                    <YAxis 
                      className="text-xs"
                      tick={{ fill: 'currentColor' }}
                    />
                    <Tooltip 
                      contentStyle={{ 
                        backgroundColor: 'var(--background)',
                        border: '1px solid var(--border)',
                        borderRadius: '6px'
                      }}
                    />
                    <Line 
                      type="monotone" 
                      dataKey="visitor_count" 
                      stroke="var(--primary)" 
                      strokeWidth={2}
                      dot={{ fill: 'var(--primary)' }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </Card>
          </TabsContent>

          <TabsContent value="segments" className="space-y-6">
            {Object.entries(groupedStats || {}).map(([segment, stats]) => (
              <Card key={segment} className="p-6">
                <h3 className="text-xl font-semibold mb-4 capitalize">{segment} Segment</h3>
                <p className="text-muted-foreground mb-6">
                  Performance metrics and KPIs specific to the {segment.toLowerCase()} segment
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {stats.map((stat: SegmentStat) => (
                    <motion.div
                      key={stat.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      className="bg-card rounded-lg p-6 border shadow-sm hover:shadow-md transition-shadow"
                    >
                      <div className="flex items-center justify-between mb-2">
                        <div className="flex items-center gap-2">
                          {getMetricIcon(stat.metric_name)}
                          <h4 className="text-sm font-medium">
                            {stat.metric_name}
                          </h4>
                        </div>
                        {parseFloat(stat.metric_value) > 50 ? (
                          <ArrowUp className="w-4 h-4 text-green-500" />
                        ) : (
                          <ArrowDown className="w-4 h-4 text-red-500" />
                        )}
                      </div>
                      <p className="text-3xl font-bold mb-2">
                        {stat.metric_value}
                        {stat.metric_value.includes('%') ? '' : '%'}
                      </p>
                      <p className="text-sm text-muted-foreground mb-4">
                        {getMetricDescription(stat.metric_name)}
                      </p>
                      <div className="h-10">
                        <ResponsiveContainer width="100%" height="100%">
                          <BarChart data={[{ value: parseFloat(stat.metric_value) }]}>
                            <Bar 
                              dataKey="value" 
                              fill={parseFloat(stat.metric_value) > 50 ? 'var(--green-500)' : 'var(--red-500)'} 
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </Card>
            ))}
          </TabsContent>

          <TabsContent value="trends" className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {trends?.map((trend, index) => (
                <motion.div
                  key={trend.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <Card className="p-6 hover:shadow-md transition-shadow">
                    <div className="flex items-center gap-2 mb-2">
                      <TrendingUp className="w-5 h-5 text-primary" />
                      <h3 className="text-lg font-medium">{trend.segment}</h3>
                    </div>
                    <p className="text-muted-foreground">{trend.trend_name}</p>
                    <div className="mt-4 text-sm text-muted-foreground">
                      Track this trend to identify opportunities and optimize your strategy
                    </div>
                  </Card>
                </motion.div>
              ))}
            </div>
          </TabsContent>
        </Tabs>
      </Card>
    </div>
  );
};