
import { Phone, Calendar } from "lucide-react";
import { 
  Popover,
  PopoverContent,
  PopoverTrigger 
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { ReactNode, useState } from "react";

interface ContactPopoverProps {
  children: ReactNode;
  align?: "center" | "start" | "end";
  side?: "top" | "right" | "bottom" | "left";
}

export const ContactPopover = ({ 
  children, 
  align = "center",
  side = "bottom" 
}: ContactPopoverProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <div 
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          {children}
        </div>
      </PopoverTrigger>
      <PopoverContent 
        className="w-56 bg-black/90 border-white/10 backdrop-blur-sm p-0 shadow-xl" 
        align={align}
        side={side}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <div className="flex flex-col">
          <a 
            href="tel:+17035528348"
            className="flex items-center gap-2 p-3 hover:bg-white/10 transition-colors text-white"
          >
            <Phone className="w-4 h-4 text-[#FF4D00]" />
            <span>Call Now</span>
          </a>
          <div className="h-px bg-white/10" />
          <a 
            href="https://calendly.com/unstucklabs/consultation" 
            target="_blank" 
            rel="noopener noreferrer"
            className="flex items-center gap-2 p-3 hover:bg-white/10 transition-colors text-white"
          >
            <Calendar className="w-4 h-4 text-[#FF4D00]" />
            <span>Schedule a Meeting</span>
          </a>
        </div>
      </PopoverContent>
    </Popover>
  );
};
