import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { ChevronDown, ChevronUp, Clock, Gauge, Check } from 'lucide-react';
import { motion } from 'framer-motion';
import { FeatureGroup } from '../data/featureGroups';
import { toast } from 'sonner';
import { cn } from '@/lib/utils';

interface FeatureGroupCardProps {
  group: FeatureGroup;
  onFeatureAdd: (feature: { name: string; description: string; priority: 'high' | 'medium' | 'low' }) => void;
  addedFeatures: Set<string>;
}

export const FeatureGroupCard = ({ group, onFeatureAdd, addedFeatures }: FeatureGroupCardProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const Icon = group.icon;

  const handleAddAll = async () => {
    setIsAdding(true);
    try {
      const unaddedFeatures = group.features.filter(feature => !addedFeatures.has(feature.name));
      
      // Add features with a slight delay to prevent position conflicts
      for (const feature of unaddedFeatures) {
        await new Promise(resolve => setTimeout(resolve, 100));
        onFeatureAdd(feature);
      }
      
      if (unaddedFeatures.length > 0) {
        toast.success(`Added ${unaddedFeatures.length} features from ${group.name}`);
      } else {
        toast.info('All features from this group have already been added');
      }
    } catch (error) {
      toast.error('Failed to add features');
      console.error('Error adding features:', error);
    } finally {
      setIsAdding(false);
    }
  };

  const getComplexityColor = (complexity: 'Low' | 'Medium' | 'High') => {
    switch (complexity) {
      case 'Low':
        return 'text-green-500 bg-green-500/10';
      case 'Medium':
        return 'text-yellow-500 bg-yellow-500/10';
      case 'High':
        return 'text-red-500 bg-red-500/10';
    }
  };

  const allFeaturesAdded = group.features.every(feature => addedFeatures.has(feature.name));

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Card className={cn(
        "p-4 space-y-4 transition-all duration-300",
        isOpen ? "ring-2 ring-primary border-primary/50 shadow-lg" : "hover:border-primary/50",
        allFeaturesAdded && "bg-secondary/10"
      )}>
        <Collapsible open={isOpen} onOpenChange={setIsOpen}>
          <div className="flex items-center gap-3">
            <div className={cn(
              "p-2 rounded-lg",
              isOpen ? "bg-primary text-white" : "bg-primary/10 text-primary"
            )}>
              <Icon className="h-5 w-5" />
            </div>
            <div className="flex-1">
              <div className="flex items-center gap-2">
                <h3 className="font-medium">{group.name}</h3>
                {allFeaturesAdded && (
                  <Badge variant="secondary" className="ml-2">
                    <Check className="h-3 w-3 mr-1" />
                    All Added
                  </Badge>
                )}
              </div>
              <p className="text-sm text-muted-foreground">{group.description}</p>
            </div>
          </div>

          <div className="flex items-center gap-2 text-sm mt-2">
            <Badge variant="secondary" className="flex items-center gap-1">
              <Clock className="h-3 w-3" />
              {group.estimatedTime}
            </Badge>
            <Badge 
              variant="secondary" 
              className={cn(
                "flex items-center gap-1",
                getComplexityColor(group.complexity)
              )}
            >
              <Gauge className="h-3 w-3" />
              {group.complexity}
            </Badge>
          </div>

          <CollapsibleTrigger className="flex items-center gap-2 text-sm text-muted-foreground hover:text-foreground mt-4">
            {isOpen ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
            {isOpen ? 'Hide features' : 'Show features'}
          </CollapsibleTrigger>
          
          <CollapsibleContent className="mt-4 space-y-3">
            {group.features.map((feature, index) => {
              const isAdded = addedFeatures.has(feature.name);
              return (
                <div 
                  key={index} 
                  className={cn(
                    "p-3 rounded-lg",
                    isOpen ? "bg-accent" : "bg-accent/10",
                    isAdded && "bg-secondary/10"
                  )}
                >
                  <div className="flex items-center justify-between">
                    <div>
                      <div className="flex items-center gap-2">
                        <h4 className="font-medium">{feature.name}</h4>
                        {isAdded && (
                          <Badge variant="secondary" className="ml-2">
                            <Check className="h-3 w-3 mr-1" />
                            Added
                          </Badge>
                        )}
                      </div>
                      <p className="text-sm text-muted-foreground">{feature.description}</p>
                    </div>
                    <Badge variant="outline" className="capitalize">
                      {feature.priority}
                    </Badge>
                  </div>
                </div>
              );
            })}
            
            <Button 
              className={cn(
                "w-full mt-4",
                isOpen ? "default" : "secondary",
                allFeaturesAdded && "bg-secondary/50 hover:bg-secondary/60"
              )}
              onClick={handleAddAll}
              disabled={isAdding || allFeaturesAdded}
            >
              {isAdding ? 'Adding Features...' : allFeaturesAdded ? 'All Features Added' : 'Add All Features'}
            </Button>
          </CollapsibleContent>
        </Collapsible>
      </Card>
    </motion.div>
  );
};