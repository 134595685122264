import { TabsList, TabsTrigger } from "@/components/ui/tabs";
import { 
  BarChart3, 
  Building2, 
  Settings, 
  Megaphone,
  Bot
} from "lucide-react";

export const AdminTabs = () => {
  return (
    <TabsList className="grid grid-cols-5 gap-2 bg-transparent h-auto p-0">
      <TabsTrigger 
        value="analytics" 
        className="flex items-center gap-2 
          data-[state=active]:bg-[#7E69AB] data-[state=active]:text-white 
          hover:bg-[#7E69AB]/10 dark:hover:bg-[#7E69AB]/20 
          transition-all duration-300 
          px-4 py-3 rounded-lg border border-transparent 
          hover:border-[#7E69AB]/20 dark:hover:border-[#7E69AB]/30 
          dark:text-gray-300 shadow-sm
          data-[state=active]:shadow-lg data-[state=active]:shadow-[#7E69AB]/20
          font-mono text-sm
          group"
      >
        <BarChart3 className="w-4 h-4 group-hover:scale-110 transition-transform duration-200" />
        <span>Analytics</span>
      </TabsTrigger>

      <TabsTrigger 
        value="sales" 
        className="flex items-center gap-2 
          data-[state=active]:bg-[#0EA5E9] data-[state=active]:text-white 
          hover:bg-[#0EA5E9]/10 dark:hover:bg-[#0EA5E9]/20 
          transition-all duration-300 
          px-4 py-3 rounded-lg border border-transparent 
          hover:border-[#0EA5E9]/20 dark:hover:border-[#0EA5E9]/30 
          dark:text-gray-300 shadow-sm
          data-[state=active]:shadow-lg data-[state=active]:shadow-[#0EA5E9]/20
          font-mono text-sm
          group"
      >
        <Building2 className="w-4 h-4 group-hover:scale-110 transition-transform duration-200" />
        <span>Sales & CRM</span>
      </TabsTrigger>

      <TabsTrigger 
        value="marketing" 
        className="flex items-center gap-2 
          data-[state=active]:bg-[#FF69B4] data-[state=active]:text-white 
          hover:bg-[#FF69B4]/10 dark:hover:bg-[#FF69B4]/20 
          transition-all duration-300 
          px-4 py-3 rounded-lg border border-transparent 
          hover:border-[#FF69B4]/20 dark:hover:border-[#FF69B4]/30 
          dark:text-gray-300 shadow-sm
          data-[state=active]:shadow-lg data-[state=active]:shadow-[#FF69B4]/20
          font-mono text-sm
          group"
      >
        <Megaphone className="w-4 h-4 group-hover:scale-110 transition-transform duration-200" />
        <span>Marketing</span>
      </TabsTrigger>

      <TabsTrigger 
        value="automation" 
        className="flex items-center gap-2 
          data-[state=active]:bg-[#00A67E] data-[state=active]:text-white 
          hover:bg-[#00A67E]/10 dark:hover:bg-[#00A67E]/20 
          transition-all duration-300 
          px-4 py-3 rounded-lg border border-transparent 
          hover:border-[#00A67E]/20 dark:hover:border-[#00A67E]/30 
          dark:text-gray-300 shadow-sm
          data-[state=active]:shadow-lg data-[state=active]:shadow-[#00A67E]/20
          font-mono text-sm
          group"
      >
        <Bot className="w-4 h-4 group-hover:scale-110 transition-transform duration-200" />
        <span>Automation</span>
      </TabsTrigger>

      <TabsTrigger 
        value="settings" 
        className="flex items-center gap-2 
          data-[state=active]:bg-[#8E9196] data-[state=active]:text-white 
          hover:bg-[#8E9196]/10 dark:hover:bg-[#8E9196]/20 
          transition-all duration-300 
          px-4 py-3 rounded-lg border border-transparent 
          hover:border-[#8E9196]/20 dark:hover:border-[#8E9196]/30 
          dark:text-gray-300 shadow-sm
          data-[state=active]:shadow-lg data-[state=active]:shadow-[#8E9196]/20
          font-mono text-sm
          group"
      >
        <Settings className="w-4 h-4 group-hover:scale-110 transition-transform duration-200" />
        <span>Settings</span>
      </TabsTrigger>
    </TabsList>
  );
};