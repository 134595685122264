import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Bot, Mail, MessageSquare, Settings, Loader2 } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";

export const AutomatedAgents = () => {
  const { toast } = useToast();
  const [emailEnabled, setEmailEnabled] = useState(true);
  const [chatEnabled, setChatEnabled] = useState(true);
  const [responseTime, setResponseTime] = useState("5");
  const [isSaving, setIsSaving] = useState(false);
  const [isTestingChat, setIsTestingChat] = useState(false);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      // Test the email automation system
      const { data: emailData, error: emailError } = await supabase.functions.invoke('automated-email', {
        body: {
          email: 'test@example.com',
          subject: 'Test Automation',
          content: 'This is a test of the automated email response system.'
        }
      });

      if (emailError) throw emailError;

      // Test the chat automation system
      const { data: chatData, error: chatError } = await supabase.functions.invoke('chat-bot', {
        body: {
          message: 'Test message',
          sessionId: 'test-session'
        }
      });

      if (chatError) throw chatError;

      // Store automation settings
      localStorage.setItem('automationSettings', JSON.stringify({
        emailEnabled,
        chatEnabled,
        responseTime: parseInt(responseTime)
      }));

      toast({
        title: "Settings saved",
        description: "Your automated agent settings have been updated.",
      });
    } catch (error) {
      console.error('Error saving automation settings:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to save automation settings. Please try again.",
      });
    } finally {
      setIsSaving(false);
    }
  };

  const testChatbot = async () => {
    setIsTestingChat(true);
    try {
      const { data, error } = await supabase.functions.invoke('chat-bot', {
        body: {
          message: 'Hello, this is a test message',
          sessionId: 'test-session'
        }
      });

      if (error) throw error;

      toast({
        title: "Chatbot Test Successful",
        description: "The chatbot is responding correctly.",
      });
    } catch (error) {
      console.error('Error testing chatbot:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to test chatbot. Please try again.",
      });
    } finally {
      setIsTestingChat(false);
    }
  };

  return (
    <Card className="p-6">
      <div className="flex items-center gap-2 mb-6">
        <Bot className="w-6 h-6 text-primary" />
        <h2 className="text-2xl font-bold">Automated Agents</h2>
      </div>

      <div className="space-y-6">
        <div className="flex items-center justify-between p-4 bg-secondary/10 rounded-lg">
          <div className="flex items-center gap-3">
            <Mail className="w-5 h-5 text-primary" />
            <div>
              <h3 className="font-medium">Email Auto-Response</h3>
              <p className="text-sm text-muted-foreground">
                Automatically respond to incoming emails using AI
              </p>
            </div>
          </div>
          <Switch 
            checked={emailEnabled} 
            onCheckedChange={setEmailEnabled}
            disabled={isSaving}
          />
        </div>

        <div className="flex items-center justify-between p-4 bg-secondary/10 rounded-lg">
          <div className="flex items-center gap-3">
            <MessageSquare className="w-5 h-5 text-primary" />
            <div>
              <h3 className="font-medium">Chat Bot</h3>
              <p className="text-sm text-muted-foreground">AI-powered chat assistance</p>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Button 
              variant="outline" 
              size="sm"
              onClick={testChatbot}
              disabled={!chatEnabled || isTestingChat}
            >
              {isTestingChat ? (
                <>
                  <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  Testing...
                </>
              ) : (
                'Test Bot'
              )}
            </Button>
            <Switch 
              checked={chatEnabled} 
              onCheckedChange={setChatEnabled}
              disabled={isSaving}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="response-time">Maximum Response Time (minutes)</Label>
          <Input
            id="response-time"
            type="number"
            value={responseTime}
            onChange={(e) => setResponseTime(e.target.value)}
            min="1"
            max="60"
            disabled={isSaving}
          />
        </div>

        <div className="space-y-4">
          <Button 
            onClick={handleSave} 
            className="w-full"
            disabled={isSaving}
          >
            {isSaving ? (
              <>
                <Settings className="w-4 h-4 mr-2 animate-spin" />
                Saving...
              </>
            ) : (
              'Save Settings'
            )}
          </Button>
          
          <p className="text-sm text-muted-foreground text-center">
            Email auto-responses and chat bot are powered by AI and will be customized based on the incoming message content.
          </p>
        </div>
      </div>
    </Card>
  );
};