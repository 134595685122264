
import { motion } from "framer-motion";
import { Cpu, Database, Network } from "lucide-react";

export const EnterpriseServiceCards = () => {
  return (
    <motion.div 
      className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-12"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.6, duration: 0.5 }}
    >
      <div className="flex items-start space-x-4 p-4 border border-blue-900/30 rounded-lg">
        <Cpu className="w-6 h-6 text-blue-500 flex-shrink-0" />
        <div>
          <h3 className="font-semibold text-white">AI Augmentation</h3>
          <p className="text-sm text-gray-300">Enhance operations with intelligent automation and AI-powered decision support</p>
        </div>
      </div>
      
      <div className="flex items-start space-x-4 p-4 border border-blue-900/30 rounded-lg">
        <Network className="w-6 h-6 text-blue-500 flex-shrink-0" />
        <div>
          <h3 className="font-semibold text-white">Centralized Solutions</h3>
          <p className="text-sm text-gray-300">Consolidate disparate systems into a unified platform for greater control</p>
        </div>
      </div>
      
      <div className="flex items-start space-x-4 p-4 border border-blue-900/30 rounded-lg">
        <Database className="w-6 h-6 text-blue-500 flex-shrink-0" />
        <div>
          <h3 className="font-semibold text-white">System Modernization</h3>
          <p className="text-sm text-gray-300">Transform legacy infrastructure into agile, future-proof digital ecosystems</p>
        </div>
      </div>
    </motion.div>
  );
};
