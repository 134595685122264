import { useState } from 'react';
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { ScreenType } from '../types';

interface AddScreenDialogProps {
  onScreenAdd: (screen: { name: string; description: string; type: ScreenType }) => void;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const AddScreenDialog = ({ onScreenAdd, open, onOpenChange }: AddScreenDialogProps) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState<ScreenType>('landing_hero');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onScreenAdd({ 
      name, 
      description,
      type
    });
    setName('');
    setDescription('');
    onOpenChange(false);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <form onSubmit={handleSubmit}>
          <DialogHeader>
            <DialogTitle>Add Custom Screen</DialogTitle>
            <DialogDescription>
              Create a new custom screen for your project.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid gap-2">
              <Label htmlFor="name">Screen Name</Label>
              <Input
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter screen name"
                required
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="description">Description</Label>
              <Textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Describe the screen's purpose"
                required
              />
            </div>
          </div>
          <DialogFooter>
            <Button type="submit">Add Screen</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};