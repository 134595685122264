import { motion } from "framer-motion";
import { ArrowUpRight } from "lucide-react";
import { StartupInfo } from "@/types/ventures";

interface PortfolioCardProps {
  company: StartupInfo;
  index: number;
}

export const PortfolioCard = ({ company, index }: PortfolioCardProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, x: index % 2 === 0 ? -20 : 20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: index * 0.1 }}
      className="group cursor-pointer"
    >
      <div className="relative border border-white/10 bg-[#222222]/50 backdrop-blur-sm p-8 hover:bg-[#333333]/50 transition-all duration-500">
        <div className="absolute top-4 right-4">
          <ArrowUpRight className="w-6 h-6 text-[#FF4D00] group-hover:rotate-45 transition-transform duration-500" />
        </div>
        
        <div className="space-y-4">
          <span className="text-[#FF4D00]/80 font-mono text-sm tracking-wider">{company.category}</span>
          <h3 className="text-3xl font-mono font-light text-white/90">{company.name}</h3>
          <p className="text-white/60 font-light">{company.description}</p>
          <div className="pt-4 border-t border-white/5">
            <span className="text-white/70 font-mono text-sm">{company.status}</span>
          </div>
        </div>
      </div>
    </motion.div>
  );
};