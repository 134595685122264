
import { motion } from "framer-motion";
import { GridMotion } from "@/components/ui/grid-motion.tsx";

export const EndlessPossibilities = () => {
  const robotVariations = [
    "/lovable-uploads/2e327802-b926-46d5-84df-71f0d63bea5d.png",
    "/lovable-uploads/5135212c-46df-45f9-bad1-53cbd623f728.png",
    "/lovable-uploads/84f060d2-a82d-4297-8498-bfbd356d3df2.png",
    "/lovable-uploads/093e1d1d-aa31-4264-8c2e-69b2df873613.png",
    "/lovable-uploads/d2c034ed-a146-4435-88d1-dc6e68a37261.png",
    "/lovable-uploads/8560e0b3-e3fe-493b-9d3f-1e5c98a3ab04.png",
    "/lovable-uploads/d13a5c41-3f51-48a6-a177-03656dd13c5f.png",
    "/lovable-uploads/2b5cd544-41a0-491a-a2ad-41ec8d3ac06b.png",
    "/lovable-uploads/5a1f49ac-a8d0-4fcd-ac58-41289c6f333f.png",
    "/lovable-uploads/5ea254fe-1e1e-4b8b-b86c-04c13590b5d8.png",
    "/lovable-uploads/6977cea9-010f-4a97-bf1d-1badee50bcca.png",
    "/lovable-uploads/86d91bf8-59e9-44df-b6f6-4391512de8cf.png",
    "/lovable-uploads/bedaf3f5-58d5-42a4-99c2-a2896cf81b35.png",
    "/lovable-uploads/ca85bca8-230c-403e-a3d1-8d02d315d3a9.png",
    "/lovable-uploads/e97fe1e0-8cce-4854-91dd-b0d9d79a1bee.png",
    // Previously added robot images
    "/lovable-uploads/24d541e8-bbe7-4fc0-8b48-b74a8bec8eb6.png",
    "/lovable-uploads/b11a484a-975e-4c38-ac97-5eecc3e79239.png",
    "/lovable-uploads/75e9a878-cc34-4b7c-9560-7d62ea36b5a6.png",
    "/lovable-uploads/93a7a82a-3b3f-48bb-aa5e-7ba5c5c4394d.png",
    "/lovable-uploads/5133e4ec-dd36-4560-8e76-e680699dbb77.png",
    "/lovable-uploads/5857dbd5-988e-454b-9d7b-c0476295514a.png",
    "/lovable-uploads/7cb2205e-2962-4098-bc74-310ab86073d0.png",
    "/lovable-uploads/9e468008-8024-4b8d-910f-27a2cce1a47d.png",
    "/lovable-uploads/cbcba9a2-ee49-4c67-912b-3837cb78997c.png",
    // Adding the new Unstuck images
    "/lovable-uploads/7dde73da-fc7a-40ee-aa92-d6f01ef3cba8.png",
    "/lovable-uploads/a7adc5b7-7452-4209-8cbc-a8c48d09068d.png",
    "/lovable-uploads/1b800e0c-5acc-49f9-9f85-962576072da8.png",
    "/lovable-uploads/f596a33f-af9e-4bcd-9c4b-ed0e66950343.png",
    "/lovable-uploads/6d936da9-900b-4081-a19a-7ae191afb710.png",
    "/lovable-uploads/eb60c9de-ca30-42c1-9745-ca66a32fb22f.png",
    "/lovable-uploads/e69a567c-41c5-4be3-8449-cfa8bc304356.png",
    "/lovable-uploads/8a06239e-99ec-4eeb-8bc5-3b0ee14c765a.png"
  ];

  return (
    <div className="relative min-h-screen bg-gradient-to-br from-black via-brand-charcoal to-black overflow-hidden">
      {/* Enhanced Background Grid with warm styling */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-b from-black/90 via-transparent to-black/90 z-10" />
        <GridMotion 
          items={robotVariations}
          gradientColor="rgba(255, 69, 0, 0.1)"
          className="opacity-60 transition-opacity duration-500 hover:opacity-70"
        />
      </div>

      {/* Content Overlay with improved readability */}
      <div className="relative z-20 flex flex-col items-center justify-center min-h-screen px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center max-w-4xl mx-auto space-y-8 p-8"
        >
          <h2 className="text-4xl md:text-7xl font-display font-bold text-white">
            If You Can Dream It,
            <br />
            We Can Build It
          </h2>
          
          <motion.p 
            className="text-xl md:text-2xl text-white/90 font-mono drop-shadow-[0_1px_1px_rgba(0,0,0,0.8)]"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            From pixel-perfect designs to scalable architectures,
            <br />
            your vision is our mission.
          </motion.p>

          {/* Subtle orange glow effect */}
          <div className="absolute -inset-1 bg-brand-orange/10 blur-3xl opacity-30 group-hover:opacity-40 transition-opacity duration-500" />
        </motion.div>
      </div>

      {/* Bottom gradient fade */}
      <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-black to-transparent" />
    </div>
  );
};
