
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { BookOpen, ExternalLink } from "lucide-react";

interface IndustryTrendsCardProps {
  industry?: string;
}

export const IndustryTrendsCard = ({ industry = "Technology" }: IndustryTrendsCardProps) => {
  // Capitalize the T in Technology if that's the industry
  const displayIndustry = industry === "technology" ? "Technology" : industry;
  
  return (
    <Card className="border-blue-900/30 shadow-lg overflow-hidden">
      <CardHeader className="bg-gradient-to-r from-blue-900/40 to-blue-900/20 border-b border-blue-900/30 pb-6">
        <CardTitle className="text-2xl text-white flex items-center gap-3">
          <BookOpen className="w-6 h-6 text-blue-400" />
          {displayIndustry} Industry Trends
        </CardTitle>
      </CardHeader>
      <CardContent className="pt-8 pb-8 bg-gradient-to-b from-blue-900/5 to-transparent">
        <div className="space-y-8">
          <div className="border-l-4 border-blue-600 pl-6 py-2">
            <h4 className="text-white font-medium text-xl mb-3">Digital Business Acceleration</h4>
            <p className="text-gray-300 text-lg leading-relaxed">
              By 2025, 75% of enterprise-generated data will be processed outside traditional data centers, 
              requiring unified integration strategies to maintain visibility and governance across hybrid environments.
            </p>
            <p className="text-gray-400 text-sm mt-3">Source: Gartner Research, 2023</p>
          </div>
          
          <div className="border-l-4 border-blue-600 pl-6 py-2">
            <h4 className="text-white font-medium text-xl mb-3">System Modernization ROI</h4>
            <p className="text-gray-300 text-lg leading-relaxed">
              Organizations that implement comprehensive system modernization achieve 35-50% improvement in
              business agility, reducing time-to-market for new products and services by an average of 40%.
            </p>
            <p className="text-gray-400 text-sm mt-3">Source: McKinsey Digital, 2023</p>
          </div>
          
          <div className="border-l-4 border-blue-600 pl-6 py-2">
            <h4 className="text-white font-medium text-xl mb-3">AI-Augmented Enterprise</h4>
            <p className="text-gray-300 text-lg leading-relaxed">
              Enterprises adopting AI-driven automation for data processing and workflows realize cost reductions 
              of 20-40% while simultaneously improving accuracy and compliance by 30-60%.
            </p>
            <p className="text-gray-400 text-sm mt-3">Source: Forrester Wave, Enterprise AI Solutions, 2023</p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
