import { Card } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { DollarSign, Calendar } from "lucide-react";
import { format } from "date-fns";
import { CompanyWithRelations } from "@/types/company";

interface CompanyDealsProps {
  deals: CompanyWithRelations['deals'];
}

export const CompanyDeals = ({ deals }: CompanyDealsProps) => {
  return (
    <Card className="divide-y">
      {deals?.map((deal) => (
        <div key={deal.id} className="p-4">
          <div className="flex items-center justify-between mb-2">
            <p className="font-medium">{deal.name}</p>
            <Badge variant={
              deal.stage === "won" ? "default" :
              deal.stage === "lost" ? "destructive" :
              "secondary"
            }>
              {deal.stage}
            </Badge>
          </div>
          <div className="flex items-center justify-between text-sm text-muted-foreground">
            <div className="flex items-center gap-2">
              <DollarSign className="w-4 h-4" />
              <span>{deal.value?.toLocaleString() || "—"}</span>
            </div>
            <div className="flex items-center gap-2">
              <Calendar className="w-4 h-4" />
              <span>{format(new Date(deal.created_at), "MMM d, yyyy")}</span>
            </div>
          </div>
        </div>
      ))}
    </Card>
  );
};