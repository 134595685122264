
import { useState, useCallback, useEffect } from 'react';
import { useNodesState, useEdgesState, Node, Edge } from '@xyflow/react';
import { FlareViewType } from '../types';
import { generateAccessCode } from '../utils/accessCode';

export const useDesignState = (designId?: string) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showPredefined, setShowPredefined] = useState(false);
  const [currentView, setCurrentView] = useState<FlareViewType>('flow');
  const [showWelcome, setShowWelcome] = useState(!designId);
  const [accessCode, setAccessCode] = useState<string>('');
  const [clientName, setClientName] = useState('');
  const [clientCompany, setClientCompany] = useState('');

  // Generate an access code when the hook is initialized and there's no designId (new project)
  useEffect(() => {
    if (!designId && !accessCode) {
      const newAccessCode = generateAccessCode();
      setAccessCode(newAccessCode);
    }
  }, [designId, accessCode]);

  return {
    nodes,
    edges,
    setNodes,
    setEdges,
    onNodesChange,
    onEdgesChange,
    isLoading,
    setIsLoading,
    showPredefined,
    setShowPredefined,
    currentView,
    setCurrentView,
    showWelcome,
    setShowWelcome,
    accessCode,
    setAccessCode,
    clientName,
    setClientName,
    clientCompany,
    setClientCompany,
  };
};
