import { Header } from "@/components/Header";
import { Footer } from "@/components/Footer";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { ArrowDown, Calendar, Users, Rocket, Trophy, Building, DollarSign, HeartHandshake, CheckCircle2, Target, Presentation, Code, Briefcase, Award, Star, Lightbulb, TrendingUp, Mic, Key, GraduationCap, Globe, Brain, Eye } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useRef, useEffect } from "react";
import { useInView } from "framer-motion";
import { GlowingEffect } from "@/components/ui/glowing-effect";
import { TextHoverEffect } from "@/components/ui/text-hover-effect";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import { useNavigate } from "react-router-dom";

const AboutPage = () => {
  const navigate = useNavigate();
  const storyRef = useRef(null);
  const teamRef = useRef(null);
  const isStoryInView = useInView(storyRef, { once: true, amount: 0.2 });
  const isTeamInView = useInView(teamRef, { once: true, amount: 0.2 });
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const team = [
    {
      name: "Wa'il Ashshowwaf",
      role: "CEO & Cofounder",
      bio: "Tech-savvy troublemaker and problem solver with a heart for doing good. Harvard, Georgetown, and Cal State educated with experience at Disney, Pepsi, and HSBC. US State Department professional fellow spreading entrepreneurship globally."
    },
    {
      name: "Ibrahem AlHaidari",
      role: "COO & Cofounder",
      bio: "Serial entrepreneur with four successful startups and multimillion-dollar exits in telecommunications. Skilled in engineering, business development, and strategic growth with 20+ years of industry experience."
    }
  ];

  const timeline = [
    { 
      year: "1987", 
      title: "Founders Meet", 
      description: "Wa'il and Ibrahem first meet in elementary school, starting a decades-long friendship.",
      icon: <Users className="h-6 w-6 text-brand-orange" />,
      metrics: [
        { label: "Years of Friendship", value: "35+", icon: <Calendar className="h-4 w-4" /> },
      ]
    },
    { 
      year: "2017-2018", 
      title: "100 Meetups Milestone", 
      description: "After helping hundreds of founders, we discover our true calling.",
      icon: <Users className="h-6 w-6 text-brand-orange" />,
      metrics: [
        { label: "Weekly Meetups", value: "79", icon: <Calendar className="h-4 w-4" /> },
        { label: "Total Attendees", value: "1,287", icon: <Users className="h-4 w-4" /> },
        { label: "Pitch Events", value: "7", icon: <Presentation className="h-4 w-4" /> },
        { label: "Workshops", value: "14", icon: <Target className="h-4 w-4" /> },
        { label: "Startups Helped", value: "1000+", icon: <CheckCircle2 className="h-4 w-4" /> }
      ]
    },
    { 
      year: "2019-2021", 
      title: "Triple Threat", 
      description: "Launch of our accelerator, software agency, investment fund, and fellowship program.",
      icon: <Rocket className="h-6 w-6 text-brand-orange" />,
      metrics: [
        { label: "Startups Supported", value: "16", icon: <Rocket className="h-4 w-4" /> },
        { label: "Funding Raised", value: "$16.4m", icon: <DollarSign className="h-4 w-4" /> },
        { label: "Software Projects", value: "57", icon: <Code className="h-4 w-4" /> },
        { label: "Unstuck Capital Invested", value: "$1M", icon: <Briefcase className="h-4 w-4" /> },
        { label: "Fellowship Interns", value: "70+", icon: <GraduationCap className="h-4 w-4" /> },
        { label: "Countries Represented", value: "22", icon: <Globe className="h-4 w-4" /> }
      ],
      specialContent: (
        <div className="mt-6 p-4 bg-black/30 rounded-lg border border-zinc-800/70">
          <h4 className="text-lg font-display text-white mb-2 flex items-center">
            <GraduationCap className="h-5 w-5 mr-2 text-brand-orange" /> Fellowship Impact
          </h4>
          <p className="text-zinc-400 text-sm">
            Our Fellowship program has welcomed over 70 full-time interns from 22 countries, 
            creating a global network of talented individuals who have contributed significantly 
            to our projects while gaining valuable real-world experience. This initiative has become 
            one of our most impactful educational offerings, bridging cultures and fostering international collaboration.
          </p>
        </div>
      )
    },
    { 
      year: "2022-2023", 
      title: "Wins & Lessons", 
      description: "While celebrating our wins, we faced challenges and growing pains with scope management and founder relationships.",
      icon: <Trophy className="h-6 w-6 text-brand-orange" />,
      metrics: [
        { label: "Jobs Created", value: "53", icon: <HeartHandshake className="h-4 w-4" /> },
        { 
          label: "First 9-Figure Exit", 
          value: "Success", 
          icon: <TrendingUp className="h-4 w-4" />,
          special: true
        },
        { 
          label: "DC's Coolest Company", 
          value: "Award", 
          icon: <Award className="h-4 w-4" />,
          special: true
        },
        { 
          label: "Fast Company World Changing Ideas", 
          value: "Award", 
          icon: <Star className="h-4 w-4" />,
          special: true
        },
        { 
          label: "Index Design for Good", 
          value: "Award", 
          icon: <Lightbulb className="h-4 w-4" />,
          special: true
        }
      ],
      specialContent: (
        <div className="mt-6 p-4 bg-black/30 rounded-lg border border-zinc-800/70">
          <h4 className="text-lg font-display text-white mb-2 flex items-center">
            <Lightbulb className="h-5 w-5 mr-2 text-brand-orange" /> The Growth Journey
          </h4>
          <p className="text-zinc-400 text-sm">
            While celebrating our wins, we faced challenges and growing pains with scope management and founder relationships. 
            These "productive failures" became the cornerstone of our TED-style talk at California State University—
            "The F Word: Embracing Failure as Progress"—and shaped our refined approach to partnerships.
          </p>
        </div>
      )
    },
    { 
      year: "2024-2025", 
      title: "AI Leadership & FLARE", 
      description: "Becoming leaders in AI and building FLARE: a first-of-its-kind platform that augments teams with superhuman capabilities.",
      icon: <Brain className="h-6 w-6 text-brand-orange" />,
      metrics: [
        { label: "Projects Built", value: "50", icon: <Code className="h-4 w-4" /> },
        { label: "Days to Deliver", value: "90", icon: <Calendar className="h-4 w-4" /> },
        { 
          label: "AI-Powered Tools", 
          value: "12", 
          icon: <Brain className="h-4 w-4" />,
          special: true
        },
        { 
          label: "Development Speed", 
          value: "10x", 
          icon: <TrendingUp className="h-4 w-4" />,
          special: true
        }
      ],
      specialContent: (
        <div className="mt-6 p-4 bg-black/30 rounded-lg border border-zinc-800/70">
          <h4 className="text-lg font-display text-white mb-2 flex items-center">
            <Brain className="h-5 w-5 mr-2 text-brand-orange" /> The FLARE Revolution
          </h4>
          <p className="text-zinc-400 text-sm">
            With FLARE, we've reimagined what's possible in software development, building a platform that 
            combines human creativity with AI acceleration. Our team delivered 50 projects in just 90 days—work 
            that would have taken a traditional agency over a year. This breakthrough approach has transformed 
            how we build, price, and deliver technology, creating an unfair advantage for our clients.
          </p>
        </div>
      )
    },
    { 
      year: "Today", 
      title: "Arlington's Tech Heart", 
      description: "A lean, elite team of genuine people helping founders build the future.",
      icon: <Building className="h-6 w-6 text-brand-orange" />,
      metrics: [
        { 
          label: "Key to the City", 
          value: "Arlington", 
          icon: <Key className="h-4 w-4" />,
          special: true
        },
        { 
          label: "DC Innovators on Fire", 
          value: "Recognition", 
          icon: <Award className="h-4 w-4" />,
          special: true
        },
        { 
          label: "WBJ Best Place to Work", 
          value: "Featured", 
          icon: <Briefcase className="h-4 w-4" />,
          special: true
        }
      ],
      specialContent: (
        <div className="mt-6 p-4 bg-black/30 rounded-lg border border-zinc-800/70">
          <h4 className="text-lg font-display text-white mb-2 flex items-center">
            <GraduationCap className="h-5 w-5 mr-2 text-brand-orange" /> Global Impact
          </h4>
          <p className="text-zinc-400 text-sm">
            Designated as an Entrepreneurship Expert by the US Department of State, we've delivered 
            workshops to entrepreneurs from the Middle East, Africa, and Asia, sharing our expertise 
            and commitment to fostering innovation worldwide.
          </p>
        </div>
      )
    },
    { 
      year: "Tomorrow", 
      title: "The AI-First Future", 
      description: "Our vision for an intelligent technology ecosystem that amplifies human creativity and democratizes innovation.",
      icon: <Eye className="h-6 w-6 text-brand-orange" />,
      metrics: [
        { 
          label: "Developer Empowerment", 
          value: "100x", 
          icon: <Rocket className="h-4 w-4" />,
          special: true
        },
        { 
          label: "Idea to Production", 
          value: "Hours", 
          icon: <TrendingUp className="h-4 w-4" />,
          special: true
        },
        { 
          label: "Startup Cost Reduction", 
          value: "90%", 
          icon: <DollarSign className="h-4 w-4" />,
          special: true
        }
      ],
      specialContent: (
        <div className="mt-6 p-4 bg-black/30 rounded-lg border border-zinc-800/70 bg-gradient-to-br from-black/50 to-purple-900/10">
          <h4 className="text-lg font-display text-white mb-2 flex items-center">
            <Lightbulb className="h-5 w-5 mr-2 text-brand-orange" /> Our Vision
          </h4>
          <p className="text-zinc-400 text-sm">
            We're building a future where technology accelerates human potential rather than replacing it. 
            Our AI-augmented tools will enable founders to transform ideas into functioning products with unprecedented 
            speed and efficiency—democratizing access to technology and opening entrepreneurship to everyone. 
            This isn't just about building better software; it's about unlocking a new era of innovation.
          </p>
        </div>
      )
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const handleLetsTalkClick = () => {
    navigate('/');
    setTimeout(() => {
      const madlibSection = document.querySelector('#madlib-section');
      if (madlibSection) {
        madlibSection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  return (
    <main className="min-h-screen bg-black text-foreground-dark">
      <Helmet>
        <title>Our Story - Unstuck Labs</title>
        <meta name="description" content="From 100 meetups to a thriving tech ecosystem - the Unstuck Labs journey." />
      </Helmet>
      <Header />
      
      <motion.section
        initial={{ opacity: 0, y: 30 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.3 }}
        transition={{ duration: 0.7 }}
        className="w-full min-h-screen flex flex-col items-center justify-center px-4 py-20 sm:py-32 bg-black relative"
      >
        <div className="absolute inset-0 w-full h-full z-0 flex flex-col items-center justify-center">
          <div className="flex flex-col items-center w-full">
            <TextHoverEffect 
              text="100 Meetups" 
              subText="That's how Unstuck Labs began. Not with a business plan, but with a tradition."
              className="w-full max-h-[80vh]"
              textSize="1000%" 
              subTextSize="24px"
              duration={0.3}
            />
          </div>
        </div>
        
        <div className="max-w-6xl mx-auto text-center relative z-10 mt-auto">
          <motion.div 
            initial={{ opacity: 0 }} 
            animate={{ opacity: 1 }} 
            transition={{ delay: 0.6, duration: 0.7 }}
            className="mt-24"
          >
            <Button 
              size="lg"
              className="bg-brand-orange hover:bg-brand-orange/90 text-white group"
              onClick={handleLetsTalkClick}
            >
              Read Our Story <ArrowDown className="ml-2 h-4 w-4 group-hover:translate-y-1 transition-transform" />
            </Button>
          </motion.div>
        </div>
      </motion.section>
      
      <div className="max-w-5xl mx-auto px-4 py-24 bg-black">
        <motion.div 
          id="timeline-section"
          ref={storyRef}
          variants={containerVariants}
          initial="hidden"
          animate={isStoryInView ? "visible" : "hidden"}
          className="my-20 relative"
        >
          <div className="absolute left-[28px] top-0 bottom-0 w-px bg-zinc-800 hidden md:block"></div>
          
          {timeline.map((item, index) => (
            <motion.div 
              key={index}
              variants={itemVariants}
              className="mb-16 md:pl-20 relative"
            >
              <div className="absolute left-0 w-14 h-14 rounded-full bg-black/30 flex items-center justify-center hidden md:flex">
                {item.icon}
              </div>
              
              <div className="relative rounded-xl p-1 md:p-2 group">
                <GlowingEffect 
                  spread={40} 
                  glow={true} 
                  disabled={false} 
                  proximity={64} 
                  inactiveZone={0.01}
                  borderWidth={2}
                  variant="default"
                />
                <div className="bg-zinc-900/50 rounded-lg p-6 md:p-8 relative">
                  <div className="flex items-center gap-4 mb-3">
                    <div className="w-10 h-10 rounded-full bg-black/30 flex items-center justify-center md:hidden">
                      {item.icon}
                    </div>
                    <div>
                      <span className="text-sm font-mono text-brand-orange">{item.year}</span>
                      <h3 className="text-xl md:text-2xl font-display font-bold text-white">{item.title}</h3>
                    </div>
                  </div>
                  <p className="text-zinc-400 md:pl-0 mb-6">{item.description}</p>
                  
                  {item.specialContent && (
                    <div className="mb-6">
                      {item.specialContent}
                    </div>
                  )}
                  
                  {item.metrics && (
                    <div className="flex flex-wrap gap-3 mt-6">
                      {item.metrics.map((metric, idx) => (
                        <Badge 
                          key={idx} 
                          variant="outline"
                          className={cn(
                            "py-2.5 px-4 border bg-black/80 text-white flex items-center gap-2 transition-all hover:scale-105",
                            metric.special 
                              ? "border-gradient-to-r from-purple-500/70 to-orange-500/70 shadow-lg shadow-purple-500/30 hover:shadow-purple-500/40"
                              : idx % 5 === 0 ? "border-purple-500/70" : 
                                idx % 5 === 1 ? "border-blue-500/70" : 
                                idx % 5 === 2 ? "border-green-500/70" :
                                idx % 5 === 3 ? "border-yellow-500/70" : "border-orange-500/70",
                            metric.special 
                              ? "animate-pulse-subtle" 
                              : "shadow-lg shadow-purple-500/20 hover:shadow-purple-500/30"
                          )}
                        >
                          <div className={cn(
                            "rounded-full p-1.5 flex items-center justify-center",
                            metric.special
                              ? "bg-gradient-to-r from-purple-500/30 to-orange-500/30"
                              : idx % 5 === 0 ? "bg-purple-500/20" : 
                                idx % 5 === 1 ? "bg-blue-500/20" : 
                                idx % 5 === 2 ? "bg-green-500/20" :
                                idx % 5 === 3 ? "bg-yellow-500/20" : "bg-orange-500/20"
                          )}>
                            {metric.icon}
                          </div>
                          <span className="font-bold text-lg">{metric.value}</span>
                          <span className="text-xs opacity-90">{metric.label}</span>
                        </Badge>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
        
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.3 }}
          className="my-20 max-w-3xl mx-auto text-center"
        >
          <h2 className="text-3xl md:text-4xl font-display font-bold mb-8 text-white">Breaking the Mold</h2>
          <p className="text-lg text-zinc-400 mb-8 leading-relaxed">
            What started as two friends helping founders has grown into something special.
            We've celebrated exits, learned from flops, and built incredible tech—all without
            losing our soul or genuine passion for helping founders succeed.
          </p>
          <p className="text-lg text-zinc-400 mb-8 leading-relaxed">
            From our home in Arlington, we've created a different kind of tech company. 
            Lean, elite, and yet approachable. Technical, but human first. 
            We're not just building software—we're building success stories.
          </p>
        </motion.div>
        
        <motion.div 
          ref={teamRef}
          initial={{ opacity: 0, y: 20 }}
          animate={isTeamInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="my-20"
        >
          <h2 className="text-3xl md:text-4xl font-display font-bold mb-12 text-center text-white">The Founding Team</h2>
          
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={isTeamInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            className="relative p-1 rounded-xl mb-12"
          >
            <GlowingEffect 
              spread={40} 
              glow={true} 
              disabled={false} 
              proximity={64} 
              inactiveZone={0.01}
              borderWidth={2}
              variant="default"
            />
            <div className="bg-zinc-900/50 p-6 rounded-lg">
              <img 
                src="/lovable-uploads/ce1424fc-cf1c-48d8-9c8b-d8448cd64d21.png" 
                alt="Wa'il Ashshowwaf and Ibrahem AlHaidari, founders of Unstuck Labs" 
                className="w-full h-auto rounded-md shadow-md" 
              />
              <p className="text-sm text-zinc-500 mt-3 text-center italic">Founders at the Unstuck Labs office in Arlington</p>
            </div>
          </motion.div>
          
          <div className="grid md:grid-cols-2 gap-8">
            {team.map((member, index) => (
              <motion.div 
                key={index} 
                initial={{ opacity: 0, y: 20 }}
                animate={isTeamInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                transition={{ duration: 0.5, delay: 0.2 + index * 0.2 }}
                className="relative p-1 rounded-xl"
              >
                <GlowingEffect 
                  spread={40} 
                  glow={true} 
                  disabled={false} 
                  proximity={64} 
                  inactiveZone={0.01}
                  borderWidth={2}
                  variant="default"
                />
                <div className="bg-zinc-900/50 p-6 rounded-lg">
                  <h3 className="text-xl font-bold mb-1 text-white">{member.name}</h3>
                  <p className="text-brand-orange font-mono text-sm mb-3">{member.role}</p>
                  <p className="text-zinc-400 text-sm">{member.bio}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
        
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.5 }}
          className="my-24 rounded-xl border border-gradient-to-r from-purple-500/30 to-brand-orange/30 overflow-hidden"
        >
          <div className="bg-gradient-to-br from-zinc-900/90 to-black p-10 rounded-xl relative">
            <div className="absolute inset-0 overflow-hidden opacity-20">
              <div className="absolute -inset-[10px] bg-gradient-to-r from-purple-500/20 via-brand-orange/20 to-blue-500/20 blur-3xl"></div>
            </div>
            
            <h2 className="text-2xl md:text-3xl font-display font-bold mb-8 text-center text-white">Empowering Arlington's Tech Future</h2>
            
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              <div className="flex flex-col items-center text-center bg-black/40 p-6 rounded-xl border border-zinc-800/50 transform transition-all hover:scale-105 hover:border-brand-orange/30">
                <DollarSign className="h-10 w-10 text-brand-orange mb-3" />
                <div className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-brand-orange to-yellow-500">$16.4M</div>
                <div className="text-sm text-zinc-400 mt-1">Funding Raised</div>
              </div>
              <div className="flex flex-col items-center text-center bg-black/40 p-6 rounded-xl border border-zinc-800/50 transform transition-all hover:scale-105 hover:border-blue-500/30">
                <Rocket className="h-10 w-10 text-blue-500 mb-3" />
                <div className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500">57</div>
                <div className="text-sm text-zinc-400 mt-1">Startups Supported</div>
              </div>
              <div className="flex flex-col items-center text-center bg-black/40 p-6 rounded-xl border border-zinc-800/50 transform transition-all hover:scale-105 hover:border-green-500/30">
                <HeartHandshake className="h-10 w-10 text-green-500 mb-3" />
                <div className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-green-500 to-teal-400">53</div>
                <div className="text-sm text-zinc-400 mt-1">Jobs Created</div>
              </div>
              <div className="flex flex-col items-center text-center bg-black/40 p-6 rounded-xl border border-zinc-800/50 transform transition-all hover:scale-105 hover:border-purple-500/30">
                <GraduationCap className="h-10 w-10 text-purple-500 mb-3" />
                <div className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-500">Global</div>
                <div className="text-sm text-zinc-400 mt-1">Entrepreneurship Expert</div>
              </div>
            </div>
          </div>
        </motion.div>
        
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.5 }}
          className="mt-28 text-center"
        >
          <h2 className="text-2xl md:text-3xl font-display font-bold mb-6 text-white">Join Our Story</h2>
          <p className="text-lg text-zinc-400 mb-8 max-w-2xl mx-auto">
            Every Tuesday started something special. What could we start with you?
          </p>
          <Button 
            size="lg"
            className="bg-brand-orange hover:bg-brand-orange/90 text-white group"
            onClick={handleLetsTalkClick}
          >
            Let's Talk <ArrowDown className="ml-2 h-4 w-4 group-hover:translate-y-1 transition-transform" />
          </Button>
        </motion.div>
      </div>
      
      <Footer />
    </main>
  );
};

export default AboutPage;
