import { motion } from "framer-motion";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Shield, Code, Lock } from "lucide-react";

interface GovernmentHeroProps {
  locationName?: string;
  returningVisitor: boolean;
}

export const GovernmentHero = ({ locationName, returningVisitor }: GovernmentHeroProps) => {
  const { data: insights, isLoading } = useQuery({
    queryKey: ['government-insights'],
    queryFn: async () => {
      const { data: existingData, error } = await supabase
        .from('segment_statistics')
        .select('metric_name, metric_value')
        .eq('segment', 'government');

      if (error) throw error;

      if (!existingData || existingData.length < 4) {
        const response = await supabase.functions.invoke('generate-segment-insights', {
          body: { segment: 'government' }
        });
        
        if (response.error) throw response.error;
        return response.data;
      }

      const transformedData = existingData.reduce((acc, curr) => {
        if (curr.metric_name === 'headline') acc.headline = curr.metric_value;
        else if (curr.metric_name === 'subheading') acc.subheading = curr.metric_value;
        else {
          if (!acc.metrics) acc.metrics = {};
          try {
            acc.metrics[curr.metric_name] = JSON.parse(curr.metric_value);
          } catch (e) {
            console.error('Error parsing metric value:', e);
          }
        }
        return acc;
      }, {} as any);

      return transformedData;
    },
    staleTime: 1000 * 60 * 60,
  });

  const getHeadingMessage = () => {
    if (isLoading) return "Secure Digital Solutions for Government Agencies";
    if (!insights?.headline) return "Secure Digital Solutions for Government Agencies";
    
    if (locationName) {
      return `${insights.headline} in ${locationName}`;
    }
    return insights.headline;
  };

  const getSubheading = () => {
    if (isLoading) return "Build citizen-centric digital services that meet the highest security standards. Backed by cleared personnel and FedRAMP compliance.";
    if (!insights?.subheading) return "Build citizen-centric digital services that meet the highest security standards. Backed by cleared personnel and FedRAMP compliance.";
    
    return insights.subheading;
  };

  return (
    <div className="space-y-8">
      <div className="space-y-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="inline-flex items-center px-4 py-2 bg-emerald-100 dark:bg-emerald-900/30 text-emerald-800 dark:text-emerald-300 rounded-full text-sm font-medium"
        >
          <Shield className="w-4 h-4 mr-2" />
          FedRAMP & FISMA Compliant Development
        </motion.div>

        <motion.h1
          className="text-4xl md:text-6xl font-display font-bold tracking-tight leading-[1.1] bg-gradient-to-br from-emerald-600 via-emerald-500 to-emerald-400 bg-clip-text text-transparent"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          {getHeadingMessage()}
        </motion.h1>

        <motion.p
          className="text-xl md:text-2xl text-gray-600 dark:text-gray-300 max-w-3xl"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.5 }}
        >
          {getSubheading()}
        </motion.p>

        <motion.div 
          className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          <div className="flex items-start space-x-4 p-4 bg-background/50 dark:bg-background/5 backdrop-blur-sm rounded-lg border border-border/50">
            <Code className="w-6 h-6 text-emerald-600 flex-shrink-0" />
            <div>
              <h3 className="font-semibold text-gray-900 dark:text-gray-100">Custom Software Development</h3>
              <p className="text-sm text-gray-600 dark:text-gray-400">Modernize legacy systems and build new solutions that serve citizens better</p>
            </div>
          </div>
          
          <div className="flex items-start space-x-4 p-4 bg-background/50 dark:bg-background/5 backdrop-blur-sm rounded-lg border border-border/50">
            <Lock className="w-6 h-6 text-emerald-600 flex-shrink-0" />
            <div>
              <h3 className="font-semibold text-gray-900 dark:text-gray-100">Security & Compliance</h3>
              <p className="text-sm text-gray-600 dark:text-gray-400">Built to meet FedRAMP, FISMA, and agency-specific security requirements</p>
            </div>
          </div>
          
          <div className="flex items-start space-x-4 p-4 bg-background/50 dark:bg-background/5 backdrop-blur-sm rounded-lg border border-border/50">
            <Shield className="w-6 h-6 text-emerald-600 flex-shrink-0" />
            <div>
              <h3 className="font-semibold text-gray-900 dark:text-gray-100">Digital Transformation</h3>
              <p className="text-sm text-gray-600 dark:text-gray-400">End-to-end development services from planning to deployment and maintenance</p>
            </div>
          </div>
        </motion.div>
      </div>

      {insights?.metrics && (
        <motion.div
          className="grid grid-cols-2 md:grid-cols-4 gap-4 bg-background/50 dark:bg-background/5 backdrop-blur-sm rounded-xl p-6 border border-border/50"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.5 }}
        >
          {Object.entries(insights.metrics).map(([key, metric]: [string, any]) => (
            <div key={key} className="text-center">
              <div className="text-2xl font-bold text-emerald-600 dark:text-emerald-400">{metric.value}</div>
              <div className="text-sm font-medium text-gray-900 dark:text-gray-100">{metric.label}</div>
              <div className="text-xs text-gray-600 dark:text-gray-400 mt-1 line-clamp-1">{metric.description}</div>
            </div>
          ))}
        </motion.div>
      )}
    </div>
  );
};