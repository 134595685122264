
import { Building2, Landmark, Rocket, Flame, Calendar, Wrench } from "lucide-react";
import { NavItem } from "@/types/navigation";

export const navItems: NavItem[] = [
  {
    title: "GOT FLARE?",
    href: "/flare",
    icon: Flame,
  },
  {
    title: "100 Meetups",
    href: "/about",
    icon: Calendar,
  },
];

export const sectorItems: NavItem[] = [
  {
    title: "Startups",
    href: "/startup",
    icon: Rocket,
  },
  {
    title: "Enterprise",
    href: "/enterprise",
    icon: Building2,
  },
  {
    title: "Government",
    href: "/government",
    icon: Landmark,
  },
];

export const toolItems: NavItem[] = [
  {
    title: "Project Scoping Tool",
    href: "/startup#project-scoping",
    icon: Rocket,
  },
  {
    title: "Enterprise System Analyzer",
    href: "/enterprise#enterprise-analyzer",
    icon: Building2,
  },
  {
    title: "Government Compliance Checker",
    href: "/government#compliance-checker",
    icon: Landmark,
  },
];
