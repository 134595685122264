import { Sheet, SheetContent } from "@/components/ui/sheet";
import { useQuery, useMutation } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { CompanyWithRelations } from "@/types/company";
import { toast } from "sonner";
import { CompanyDetailsSidebarHeader } from "./details/CompanyDetailsSidebarHeader";
import { CompanyDetailsSidebarContent } from "./details/CompanyDetailsSidebarContent";
import { CompanyDetailsSidebarLoading } from "./details/CompanyDetailsSidebarLoading";

interface CompanyDetailsSidebarProps {
  companyId: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const CompanyDetailsSidebar = ({ 
  companyId, 
  open, 
  onOpenChange 
}: CompanyDetailsSidebarProps) => {
  const { data: company, isLoading, refetch } = useQuery({
    queryKey: ["company", companyId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("companies")
        .select(`
          *,
          contacts (
            id,
            first_name,
            last_name,
            job_title,
            email,
            phone
          ),
          deals (
            id,
            name,
            value,
            stage,
            created_at,
            admin_profiles (
              name
            )
          )
        `)
        .eq("id", companyId)
        .maybeSingle();

      if (error) throw error;
      return data as CompanyWithRelations;
    },
    enabled: !!companyId && open,
  });

  const enrichMutation = useMutation({
    mutationFn: async () => {
      const response = await supabase.functions.invoke('enrich-company', {
        body: { 
          companyId,
          domain: company?.website_url?.replace(/^https?:\/\//, '').split('/')[0]
        }
      });
      
      if (response.error) throw response.error;
      return response.data;
    },
    onSuccess: () => {
      toast.success("Company data enriched successfully");
      refetch();
    },
    onError: (error) => {
      toast.error("Failed to enrich company data");
      console.error("Enrichment error:", error);
    }
  });

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent className="w-full sm:max-w-xl overflow-y-auto border-l border-border bg-background/80 backdrop-blur-xl">
        <CompanyDetailsSidebarHeader
          onClose={() => onOpenChange(false)}
          onEnrich={() => enrichMutation.mutate()}
          isEnriching={enrichMutation.isPending}
        />

        {isLoading ? (
          <CompanyDetailsSidebarLoading />
        ) : company ? (
          <CompanyDetailsSidebarContent company={company} />
        ) : (
          <div className="flex items-center justify-center h-[60vh] text-muted-foreground">
            Company not found
          </div>
        )}
      </SheetContent>
    </Sheet>
  );
};