import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Clock, Calendar, Award } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';

export const DashboardMetrics = () => {
  const { data: metrics, isLoading } = useQuery({
    queryKey: ['employee-metrics'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const { data: profile } = await supabase
        .from('employee_profiles')
        .select('id')
        .eq('user_id', user.id)
        .single();

      if (!profile) throw new Error('No employee profile found');

      const [
        { data: remoteWork },
        { data: timeOff },
        { data: reviews }
      ] = await Promise.all([
        supabase.from('remote_work_days').select('*').eq('employee_id', profile.id),
        supabase.from('time_off_requests').select('*').eq('employee_id', profile.id),
        supabase.from('employee_reviews').select('rating').eq('employee_id', profile.id)
      ]);

      return {
        remoteWorkDays: remoteWork?.length || 0,
        timeOffBalance: timeOff?.length || 0,
        averageRating: reviews?.reduce((acc, review) => acc + (review.rating || 0), 0) / (reviews?.length || 1) || 0
      };
    },
    meta: {
      onError: (error: Error) => {
        console.error('Error fetching metrics:', error);
        toast.error('Failed to load employee metrics');
      }
    }
  });

  if (isLoading) {
    return <div>Loading metrics...</div>;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Remote Days</CardTitle>
          <Clock className="h-4 w-4 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{metrics?.remoteWorkDays}</div>
          <p className="text-xs text-muted-foreground">Days worked remotely</p>
        </CardContent>
      </Card>

      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Time Off Balance</CardTitle>
          <Calendar className="h-4 w-4 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{metrics?.timeOffBalance}</div>
          <p className="text-xs text-muted-foreground">Days available</p>
        </CardContent>
      </Card>

      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Performance Rating</CardTitle>
          <Award className="h-4 w-4 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{metrics?.averageRating.toFixed(1)}/5.0</div>
          <p className="text-xs text-muted-foreground">Average rating</p>
        </CardContent>
      </Card>
    </div>
  );
};