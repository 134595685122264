
import { useState } from "react";
import { Calendar } from "@/components/ui/calendar";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Textarea } from "@/components/ui/textarea";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Loader2, Check, AlertTriangle } from "lucide-react";
import { format, addDays, startOfToday } from "date-fns";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";

interface CalendarBookingProps {
  fullName: string;
  email: string;
  leadId: string | null;
}

const timeSlots = [
  "09:00", "09:30", "10:00", "10:30", "11:00", "11:30",
  "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30"
];

export const CalendarBooking = ({ fullName, email, leadId }: CalendarBookingProps) => {
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [selectedTime, setSelectedTime] = useState<string>();
  const [notes, setNotes] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [booked, setBooked] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const today = startOfToday();
  const maxDate = addDays(today, 30);

  const handleBookMeeting = async () => {
    if (!selectedDate || !selectedTime) {
      toast.error("Please select a date and time");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      console.log("Booking meeting with:", {
        date: format(selectedDate, 'yyyy-MM-dd'),
        time: selectedTime,
        fullName,
        email,
        notes,
        leadId
      });
      
      // Call the book-meeting edge function
      const { data, error } = await supabase.functions.invoke('book-meeting', {
        body: {
          date: format(selectedDate, 'yyyy-MM-dd'),
          time: selectedTime,
          fullName,
          email,
          notes,
          leadId
        }
      });
      
      if (error) {
        console.error("Error from book-meeting function:", error);
        throw new Error(error.message || 'Failed to book meeting');
      }
      
      console.log("Book meeting response:", data);
      
      if (!data.success) {
        throw new Error(data.error || 'Failed to book meeting');
      }

      setBooked(true);
      toast.success("Meeting scheduled successfully! Check your email for details.");
    } catch (error: any) {
      console.error('Error booking meeting:', error);
      setError(error.message || "There was a problem scheduling your meeting. Please try again.");
      toast.error("Booking error", {
        description: error.message || "There was a problem scheduling your meeting."
      });
    } finally {
      setLoading(false);
    }
  };

  if (booked) {
    return (
      <Card className="p-6 bg-gray-900 border-gray-800 text-center">
        <div className="flex justify-center mb-4">
          <div className="w-16 h-16 rounded-full bg-green-600/20 flex items-center justify-center">
            <Check className="w-8 h-8 text-green-500" />
          </div>
        </div>
        <h3 className="text-2xl font-semibold text-white mb-2">Meeting Scheduled!</h3>
        <p className="text-gray-300 mb-4">
          We've sent a calendar invitation to your email. Looking forward to speaking with you!
        </p>
        <p className="text-gray-400 text-sm">
          If you need to reschedule, please respond to the calendar invitation.
        </p>
      </Card>
    );
  }

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-semibold text-brand-orange">Schedule a Meeting</h2>
      
      {error && (
        <Alert variant="destructive" className="bg-red-900/20 border-red-800 text-white">
          <AlertTriangle className="h-5 w-5 text-red-400" />
          <AlertDescription className="ml-2">{error}</AlertDescription>
        </Alert>
      )}
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card className="p-4 bg-gray-900 border-gray-800">
          <h3 className="text-lg font-medium text-white mb-4">Select a Date</h3>
          <Calendar
            mode="single"
            selected={selectedDate}
            onSelect={setSelectedDate}
            disabled={(date) => date < today || date > maxDate}
            className="rounded-md bg-gray-900 text-white"
          />
        </Card>

        <div className="space-y-6">
          <Card className="p-4 bg-gray-900 border-gray-800">
            <h3 className="text-lg font-medium text-white mb-4">Select a Time</h3>
            {selectedDate ? (
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                {timeSlots.map((time) => (
                  <Button
                    key={time}
                    variant={selectedTime === time ? "default" : "outline"}
                    onClick={() => setSelectedTime(time)}
                    className={`${
                      selectedTime === time 
                        ? 'bg-brand-orange hover:bg-brand-orange/90' 
                        : 'border-gray-700 text-gray-300 hover:text-white hover:bg-gray-800'
                    }`}
                  >
                    {time}
                  </Button>
                ))}
              </div>
            ) : (
              <p className="text-gray-400">Please select a date first</p>
            )}
          </Card>

          <Card className="p-4 bg-gray-900 border-gray-800">
            <h3 className="text-lg font-medium text-white mb-4">Additional Notes</h3>
            <Textarea
              placeholder="Add any specific topics you'd like to discuss..."
              className="bg-gray-800 border-gray-700 text-white resize-none h-24"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Card>
        </div>
      </div>

      <Button
        onClick={handleBookMeeting}
        disabled={!selectedDate || !selectedTime || loading}
        className="w-full bg-brand-orange hover:bg-brand-orange/90 text-white py-6 text-lg"
      >
        {loading ? (
          <>
            <Loader2 className="mr-2 h-5 w-5 animate-spin" />
            Confirming...
          </>
        ) : (
          "Confirm Meeting"
        )}
      </Button>
    </div>
  );
};
