import { Button, ButtonProps } from "@/components/ui/button";
import { UserPlus } from "lucide-react";
import { useState } from "react";
import { CreateContactDialog } from "./CreateContactDialog";

interface CreateContactButtonProps extends ButtonProps {
  companyId?: string;
  variant?: "default" | "outline" | "ghost";
  size?: "default" | "sm" | "lg" | "icon";
  onSuccess?: () => void;
}

export const CreateContactButton = ({ 
  companyId,
  variant = "default",
  size = "default",
  onSuccess,
  ...props
}: CreateContactButtonProps) => {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <Button
        variant={variant}
        size={size}
        onClick={() => setShowDialog(true)}
        {...props}
      >
        <UserPlus className="w-4 h-4 mr-2" />
        Add Contact
      </Button>

      <CreateContactDialog
        open={showDialog}
        onOpenChange={setShowDialog}
        companyId={companyId}
        onSuccess={onSuccess}
      />
    </>
  );
};