
import { CheckCircle2, AlertTriangle, Clock, UserCheck } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';

interface TaskStatusBadgesProps {
  hasApproval?: boolean;
  hasFeedback?: boolean;
  isBlocked?: boolean;
  dueDate?: string | null;
  taskId: string;
}

export function TaskStatusBadges({ hasApproval, hasFeedback, isBlocked, dueDate, taskId }: TaskStatusBadgesProps) {
  const { data: pendingApproval } = useQuery({
    queryKey: ['task-pending-approval', taskId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('task_approvals')
        .select('requested_from, requested_by')
        .eq('task_id', taskId)
        .eq('status', 'pending')
        .maybeSingle();
      
      if (error) throw error;
      return data;
    },
    enabled: !!taskId
  });

  return (
    <div className="flex gap-2 flex-wrap">
      {hasApproval && (
        <div className="flex items-center gap-1 px-2 py-1 bg-green-100 text-green-800 rounded text-xs">
          <CheckCircle2 className="w-3 h-3" />
          Approved
        </div>
      )}
      {pendingApproval && (
        <div className="flex items-center gap-1 px-2 py-1 bg-yellow-100 text-yellow-800 rounded text-xs">
          <UserCheck className="w-3 h-3" />
          Pending Approval
        </div>
      )}
      {hasFeedback && (
        <div className="flex items-center gap-1 px-2 py-1 bg-blue-100 text-blue-800 rounded text-xs">
          <CheckCircle2 className="w-3 h-3" />
          Feedback Received
        </div>
      )}
      {isBlocked && (
        <div className="flex items-center gap-1 px-2 py-1 bg-red-100 text-red-800 rounded text-xs">
          <AlertTriangle className="w-3 h-3" />
          Blocked
        </div>
      )}
      {dueDate && (
        <div className="flex items-center gap-1 px-2 py-1 bg-orange-100 text-orange-800 rounded text-xs">
          <Clock className="w-3 h-3" />
          Due {new Date(dueDate).toLocaleDateString()}
        </div>
      )}
    </div>
  );
}
