import { Header } from "@/components/Header";
import { Footer } from "@/components/Footer";
import { motion } from "framer-motion";

const UnstuckAnywherePage = () => {
  return (
    <main className="min-h-screen bg-gradient-to-br from-[#FF4D00]/10 via-white to-white dark:from-[#FF4D00]/20 dark:via-background dark:to-background">
      <Header />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="container mx-auto px-4 pt-24 pb-16"
      >
        <div className="max-w-4xl mx-auto space-y-12">
          <div className="text-center space-y-4">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold">
              Your Virtual Startup Lab
            </h1>
            <p className="text-xl text-muted-foreground">
              Power through obstacles and launch big ideas—anytime, anywhere
            </p>
          </div>

          <div className="prose prose-lg dark:prose-invert mx-auto">
            <p>
              Unstuck Anywhere is a remote-first startup lab that helps you power through 
              obstacles and launch big ideas—anytime, anywhere. By blending hands-on 
              mentorship, practical tools, and agile support, we turn challenges into 
              momentum for founders on the go.
            </p>
          </div>
        </div>
      </motion.div>
      <Footer />
    </main>
  );
};

export default UnstuckAnywherePage;