import { BlogPost as BlogPostType } from "@/types/blog";
import { BlogPost } from "./BlogPost";

interface BlogGridProps {
  posts: BlogPostType[];
}

export const BlogGrid = ({ posts }: BlogGridProps) => {
  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
      {posts.map((post, index) => (
        <BlogPost key={post.id} post={post} index={index} />
      ))}
    </div>
  );
};