
"use client";

import { cn } from "@/lib/utils";
import { Sparkles } from "lucide-react";

interface DisplayCardProps {
  className?: string;
  icon?: React.ReactNode;
  title?: string;
  description?: string;
  date?: string;
  iconClassName?: string;
  titleClassName?: string;
}

function DisplayCard({
  className,
  icon = <Sparkles className="size-4 text-brand-orange" />,
  title = "Featured",
  description = "Discover amazing content",
  date = "Just now",
  iconClassName = "text-brand-orange",
  titleClassName = "text-brand-orange",
}: DisplayCardProps) {
  return (
    <div
      className={cn(
        "relative flex h-48 w-[26rem] -skew-y-[8deg] select-none flex-col justify-between rounded-xl border-2 backdrop-blur-sm px-8 py-5 transition-all duration-700 hover:border-white/20 [&>*]:flex [&>*]:items-center [&>*]:gap-3",
        className
      )}
    >
      <div className="pt-2">
        <span className="relative inline-block rounded-full bg-accent p-2">
          {icon}
        </span>
        <p className={cn("text-xl font-medium text-white", titleClassName)}>{title}</p>
      </div>
      <p className="text-lg break-words line-clamp-2 text-white/90">{description}</p>
      <p className="text-muted-foreground pb-2 text-white/70">{date}</p>
    </div>
  );
}

interface DisplayCardsProps {
  cards?: DisplayCardProps[];
}

export default function DisplayCards({ cards }: DisplayCardsProps) {
  const defaultCards = [
    {
      className: "[grid-area:stack] hover:-translate-y-20 transition-transform duration-500 hover:z-10 z-0 grayscale-[0%]",
    },
    {
      className: "[grid-area:stack] translate-x-16 translate-y-10 hover:-translate-y-10 transition-transform duration-500 hover:z-10 z-0 grayscale-[0%]",
    },
    {
      className: "[grid-area:stack] translate-x-32 translate-y-20 hover:translate-y-0 transition-transform duration-500 hover:z-10 z-0",
    },
  ];

  const displayCards = cards || defaultCards;

  return (
    <div className="grid [grid-template-areas:'stack'] place-items-center opacity-100 animate-in fade-in-0 duration-700">
      {displayCards.map((cardProps, index) => (
        <DisplayCard key={index} {...cardProps} />
      ))}
    </div>
  );
}
