'use client'

import { Suspense, lazy, useState } from 'react'
import { Loading } from './loading'
const Spline = lazy(() => import('@splinetool/react-spline'))

interface SplineSceneProps {
  scene: string
  className?: string
}

export function SplineScene({ scene, className }: SplineSceneProps) {
  const [error, setError] = useState<string | null>(null)

  const handleError = (err: unknown) => {
    console.error('Spline scene error:', err)
    const errorMessage = err instanceof Error ? err.message : 'Failed to load 3D scene'
    setError(errorMessage)
  }

  if (error) {
    return (
      <div className="w-full h-full flex items-center justify-center bg-background/80 backdrop-blur-sm rounded-lg p-4">
        <p className="text-sm text-muted-foreground text-center">
          {error}
        </p>
      </div>
    )
  }

  return (
    <Suspense 
      fallback={
        <div className="w-full h-full flex items-center justify-center">
          <Loading size="lg" text="Loading 3D scene..." />
        </div>
      }
    >
      <Spline
        scene={scene}
        className={className}
        onError={handleError}
      />
    </Suspense>
  )
}