import { motion } from "framer-motion";
import { Check, AlertCircle } from "lucide-react";
import { BudgetTier } from "../../types";

interface BudgetTierCardProps {
  tier: BudgetTier;
}

export const BudgetTierCard = ({ tier }: BudgetTierCardProps) => {
  const Icon = tier.icon;
  
  return (
    <div className="flex-1">
      <div className="flex items-center gap-2 mb-2">
        {Icon && <Icon className="w-5 h-5 text-[#FF4D00]" />}
        <h4 className="font-semibold text-foreground dark:text-gray-100">{tier.range}</h4>
        {tier.recommended && (
          <span className="bg-[#FF4D00]/10 text-[#FF4D00] text-xs px-2 py-1 rounded-full">
            Recommended
          </span>
        )}
      </div>
      
      <div className="bg-accent dark:bg-gray-800 p-4 rounded-lg mb-4">
        <p className="text-sm text-muted-foreground dark:text-gray-300">{tier.description}</p>
      </div>

      <div className="space-y-4">
        <div>
          <h5 className="text-sm font-medium mb-2 text-foreground dark:text-gray-100">Features:</h5>
          <ul className="grid grid-cols-1 gap-2">
            {tier.features.map((feature, index) => (
              <li key={index} className="flex items-start gap-2 text-sm">
                <Check className="w-4 h-4 text-[#FF4D00] mt-1 flex-shrink-0" />
                <span className="text-muted-foreground dark:text-gray-300">{feature}</span>
              </li>
            ))}
          </ul>
        </div>

        {tier.advantages && (
          <div>
            <h5 className="text-sm font-medium mb-2 text-foreground dark:text-gray-100">Advantages:</h5>
            <ul className="grid grid-cols-1 gap-2">
              {tier.advantages.map((advantage, index) => (
                <li key={index} className="flex items-start gap-2 text-sm">
                  <Check className="w-4 h-4 text-[#FF4D00] mt-1 flex-shrink-0" />
                  <span className="text-muted-foreground dark:text-gray-300">{advantage}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {tier.limitations && (
        <div className="mt-4 flex items-center gap-2 text-sm bg-accent dark:bg-gray-800 p-3 rounded-lg border border-border dark:border-gray-700">
          <AlertCircle className="w-4 h-4 text-[#FF4D00]" />
          <div>
            <p className="font-medium mb-1 text-foreground dark:text-gray-100">Important Considerations:</p>
            <ul className="list-disc list-inside space-y-1 text-muted-foreground dark:text-gray-300">
              {tier.limitations.map((limitation, index) => (
                <li key={index}>{limitation}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};