import { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';
import { Mail, Phone, Calendar } from 'lucide-react';
import { DatabaseEmployee } from '@/types/employee';

interface ProfileBasicInfoProps {
  profile: DatabaseEmployee;
  isEditMode: boolean;
  onFieldChange: (field: keyof DatabaseEmployee, value: any) => void;
  departments: string[];
  locations: string[];
}

export const ProfileBasicInfo = ({
  profile,
  isEditMode,
  onFieldChange,
  departments,
  locations
}: ProfileBasicInfoProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Basic Information</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="flex items-center space-x-4">
          <Avatar className="h-20 w-20">
            <AvatarImage 
              src={profile.avatar_url || undefined} 
              alt={profile.first_name}
            />
            <AvatarFallback>{profile.first_name[0]}{profile.last_name[0]}</AvatarFallback>
          </Avatar>
          <div className="space-y-2 flex-1">
            {isEditMode ? (
              <>
                <div>
                  <Label htmlFor="first_name">First Name</Label>
                  <Input
                    id="first_name"
                    value={profile.first_name}
                    onChange={(e) => onFieldChange('first_name', e.target.value)}
                  />
                </div>
                <div>
                  <Label htmlFor="last_name">Last Name</Label>
                  <Input
                    id="last_name"
                    value={profile.last_name}
                    onChange={(e) => onFieldChange('last_name', e.target.value)}
                  />
                </div>
                <div>
                  <Label htmlFor="department">Department</Label>
                  <Select
                    value={profile.department}
                    onValueChange={(value) => onFieldChange('department', value)}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select department" />
                    </SelectTrigger>
                    <SelectContent>
                      {departments.map((dept) => (
                        <SelectItem key={dept} value={dept}>
                          {dept}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div>
                  <Label htmlFor="location">Location</Label>
                  <Select
                    value={profile.location || ''}
                    onValueChange={(value) => onFieldChange('location', value)}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select location" />
                    </SelectTrigger>
                    <SelectContent>
                      {locations.map((location) => (
                        <SelectItem key={location} value={location}>
                          {location}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </>
            ) : (
              <>
                <h3 className="text-xl font-semibold">{profile.first_name} {profile.last_name}</h3>
                <p className="text-muted-foreground">{profile.title}</p>
              </>
            )}
          </div>
        </div>

        <div className="space-y-2">
          {isEditMode ? (
            <>
              <div>
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  value={profile.email}
                  onChange={(e) => onFieldChange('email', e.target.value)}
                  type="email"
                />
              </div>
              <div>
                <Label htmlFor="work_phone">Work Phone</Label>
                <Input
                  id="work_phone"
                  value={profile.work_phone || ''}
                  onChange={(e) => onFieldChange('work_phone', e.target.value)}
                />
              </div>
              <div>
                <Label htmlFor="personal_phone">Personal Phone</Label>
                <Input
                  id="personal_phone"
                  value={profile.personal_phone || ''}
                  onChange={(e) => onFieldChange('personal_phone', e.target.value)}
                />
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center space-x-2">
                <Mail className="h-4 w-4 text-muted-foreground" />
                <span>{profile.email}</span>
              </div>
              {profile.work_phone && (
                <div className="flex items-center space-x-2">
                  <Phone className="h-4 w-4 text-muted-foreground" />
                  <span>{profile.work_phone}</span>
                </div>
              )}
              {profile.personal_phone && (
                <div className="flex items-center space-x-2">
                  <Phone className="h-4 w-4 text-muted-foreground" />
                  <span>{profile.personal_phone}</span>
                </div>
              )}
              <div className="flex items-center space-x-2">
                <Calendar className="h-4 w-4 text-muted-foreground" />
                <span>Started {new Date(profile.start_date).toLocaleDateString()}</span>
              </div>
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
};