
import { Link, useLocation } from "react-router-dom";
import { Flame, Calendar, Wrench } from "lucide-react";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "@/components/ui/navigation-menu";
import { ContactButton } from "./ContactButton";
import { cn } from "@/lib/utils";
import { ProgramsDropdown } from "../ProgramsDropdown";
import { toolItems } from "./nav-items";

export const Navigation = () => {
  const location = useLocation();
  
  const isFlarePage = location.pathname.includes('/flare');
  const isLandingPage = location.pathname === '/';
  
  const getSegmentClass = (path: string) => {
    const isActive = location.pathname === path;
    const baseClass = "transition-all duration-300 py-2 px-4 rounded-lg flex items-center gap-2";
    
    if (isFlarePage) {
      return `${baseClass} ${
        isActive 
          ? 'text-brand-orange bg-brand-charcoal scale-105' 
          : 'text-brand-orange hover:text-brand-orange hover:bg-brand-charcoal'
      }`;
    }

    return `${baseClass} ${
      isActive 
        ? 'text-brand-orange bg-brand-charcoal scale-105 font-medium' 
        : 'text-brand-orange hover:text-white hover:bg-brand-charcoal'
    }`;
  };

  return (
    <nav className="hidden md:flex items-center gap-4 lg:gap-6">
      <NavigationMenu>
        <NavigationMenuList>
          <NavigationMenuItem>
            <NavigationMenuTrigger className="text-brand-orange hover:text-white hover:bg-brand-charcoal bg-transparent">
              Digital
            </NavigationMenuTrigger>
            <NavigationMenuContent>
              <ul className={cn(
                "w-[300px] p-1 shadow-xl rounded-lg bg-black border border-zinc-800",
                isLandingPage ? "border-zinc-800" : ""
              )}>
                <li>
                  <Link 
                    to="/startup" 
                    className={cn(
                      "block select-none rounded-md p-4 no-underline outline-none transition-colors",
                      isLandingPage 
                        ? "text-brand-orange hover:bg-brand-charcoal hover:text-white" 
                        : location.pathname === '/startup' 
                          ? 'bg-brand-orange text-white' 
                          : 'text-brand-orange hover:bg-brand-charcoal hover:text-white'
                    )}
                  >
                    <div className="text-sm font-semibold">Startup Solutions</div>
                    <p className="line-clamp-2 text-sm opacity-80">
                      Tailored solutions for startups and small businesses
                    </p>
                  </Link>
                </li>
                <li>
                  <Link 
                    to="/enterprise" 
                    className={cn(
                      "block select-none rounded-md p-4 no-underline outline-none transition-colors",
                      isLandingPage 
                        ? "text-brand-orange hover:bg-brand-charcoal hover:text-white" 
                        : location.pathname === '/enterprise' 
                          ? 'bg-brand-orange text-white' 
                          : 'text-brand-orange hover:bg-brand-charcoal hover:text-white'
                    )}
                  >
                    <div className="text-sm font-semibold">Enterprise Solutions</div>
                    <p className="line-clamp-2 text-sm opacity-80">
                      Enterprise-grade digital transformation
                    </p>
                  </Link>
                </li>
                <li>
                  <Link 
                    to="/government" 
                    className={cn(
                      "block select-none rounded-md p-4 no-underline outline-none transition-colors",
                      isLandingPage 
                        ? "text-brand-orange hover:bg-brand-charcoal hover:text-white" 
                        : location.pathname === '/government' 
                          ? 'bg-brand-orange text-white' 
                          : 'text-brand-orange hover:bg-brand-charcoal hover:text-white'
                    )}
                  >
                    <div className="text-sm font-semibold">Government Solutions</div>
                    <p className="line-clamp-2 text-sm opacity-80">
                      Digital solutions for government agencies
                    </p>
                  </Link>
                </li>
              </ul>
            </NavigationMenuContent>
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>

      <ProgramsDropdown />

      {/* Tools Dropdown Menu */}
      <NavigationMenu>
        <NavigationMenuList>
          <NavigationMenuItem>
            <NavigationMenuTrigger className="text-brand-orange hover:text-white hover:bg-brand-charcoal bg-transparent">
              <Wrench className="h-4 w-4 mr-1" />
              Tools
            </NavigationMenuTrigger>
            <NavigationMenuContent>
              <ul className={cn(
                "w-[320px] p-1 shadow-xl rounded-lg bg-black border border-zinc-800",
                isLandingPage ? "border-zinc-800" : ""
              )}>
                {toolItems.map((item, index) => (
                  <li key={index}>
                    <Link 
                      to={item.href} 
                      className={cn(
                        "block select-none rounded-md p-4 no-underline outline-none transition-colors",
                        "text-brand-orange hover:bg-brand-charcoal hover:text-white flex items-center gap-2"
                      )}
                    >
                      {item.icon && <item.icon className="h-4 w-4" />}
                      <div>
                        <div className="text-sm font-semibold">{item.title}</div>
                        <p className="line-clamp-2 text-xs opacity-80">
                          {index === 0 && "Plan your startup project scope and budget"}
                          {index === 1 && "Analyze enterprise systems for optimization"}
                          {index === 2 && "Check government compliance requirements"}
                        </p>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </NavigationMenuContent>
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>

      <Link to="/flare" className={getSegmentClass('/flare')}>
        <Flame className="h-4 w-4" />
        GOT FLARE?
      </Link>
      
      <Link to="/about" className={getSegmentClass('/about')}>
        <Calendar className="h-4 w-4" />
        100 Meetups
      </Link>
      
      <ContactButton />
    </nav>
  );
};
