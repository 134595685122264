
import { ProjectScoping } from "./startup/ProjectScoping";
import { QuickAction } from "./startup/QuickAction";
import { FlareTeaser } from "@/components/programs/flare/FlareTeaser";
import { motion } from "framer-motion";
import { Separator } from "@/components/ui/separator";

export const StartupJourney = () => {
  return (
    <div className="relative">
      <div className="relative z-10">
        {/* Project Scoping Section */}
        <ProjectScoping />
        
        <Separator className="max-w-[61.8%] mx-auto opacity-10 my-8" />
        
        {/* FLARE Program Section */}
        <section className="py-16 md:py-24">
          <div className="container mx-auto px-4">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, ease: "easeOut" }}
              viewport={{ once: true, margin: "-10%" }}
              className="max-w-6xl mx-auto bg-black/30 backdrop-blur-sm rounded-2xl overflow-hidden shadow-xl"
            >
              <FlareTeaser />
            </motion.div>
          </div>
        </section>
        
        <Separator className="max-w-[61.8%] mx-auto opacity-10 my-8" />
        
        {/* Call to Action */}
        <QuickAction />
      </div>
    </div>
  );
};
