import { ScrollArea } from "@/components/ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { CompanyWithRelations } from "@/types/company";
import { CompanyHeader } from "./CompanyHeader";
import { CompanyStats } from "./CompanyStats";
import { CompanyInfo } from "./CompanyInfo";
import { CompanyContacts } from "./CompanyContacts";
import { CompanyDeals } from "./CompanyDeals";
import { CompanyActivities } from "./CompanyActivities";

interface CompanyDetailsSidebarContentProps {
  company: CompanyWithRelations;
}

export const CompanyDetailsSidebarContent = ({
  company,
}: CompanyDetailsSidebarContentProps) => {
  return (
    <ScrollArea className="h-[calc(100vh-8rem)]">
      <div className="space-y-8 pr-4">
        <CompanyHeader company={company} />
        <CompanyStats company={company} />

        <Tabs defaultValue="info" className="w-full">
          <TabsList className="w-full grid grid-cols-4 h-auto p-1 bg-muted/50">
            <TabsTrigger 
              value="info" 
              className="data-[state=active]:bg-background py-2"
            >
              Info
            </TabsTrigger>
            <TabsTrigger 
              value="contacts" 
              className="data-[state=active]:bg-background py-2"
            >
              Contacts
            </TabsTrigger>
            <TabsTrigger 
              value="deals" 
              className="data-[state=active]:bg-background py-2"
            >
              Deals
            </TabsTrigger>
            <TabsTrigger 
              value="activities" 
              className="data-[state=active]:bg-background py-2"
            >
              Activities
            </TabsTrigger>
          </TabsList>

          <div className="mt-6 space-y-6">
            <TabsContent value="info">
              <CompanyInfo company={company} />
            </TabsContent>

            <TabsContent value="contacts">
              <CompanyContacts contacts={company.contacts} />
            </TabsContent>

            <TabsContent value="deals">
              <CompanyDeals deals={company.deals} />
            </TabsContent>

            <TabsContent value="activities">
              <CompanyActivities companyId={company.id} />
            </TabsContent>
          </div>
        </Tabs>
      </div>
    </ScrollArea>
  );
};