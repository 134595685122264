import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { UserPlus, Mail } from "lucide-react";
import { toast } from "sonner";

export const DealTeam = ({ dealId }: { dealId: string }) => {
  const [inviteEmail, setInviteEmail] = useState("");

  const handleInvite = async () => {
    if (!inviteEmail.trim()) return;

    // Here you would typically integrate with your email service
    // to send invitations
    toast.success(`Invitation sent to ${inviteEmail}`);
    setInviteEmail("");
  };

  return (
    <Card className="p-6">
      <h3 className="text-lg font-semibold mb-4">Deal Team</h3>
      
      <div className="space-y-4">
        <div className="flex gap-2">
          <Input
            type="email"
            placeholder="Email address"
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
          />
          <Button onClick={handleInvite} disabled={!inviteEmail.trim()}>
            <UserPlus className="w-4 h-4 mr-2" />
            Invite
          </Button>
        </div>

        <div className="space-y-2">
          <div className="flex items-center justify-between p-3 bg-muted/50 rounded-lg">
            <div className="flex items-center gap-2">
              <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center">
                <Mail className="w-4 h-4 text-primary" />
              </div>
              <div>
                <p className="font-medium">John Doe</p>
                <p className="text-sm text-muted-foreground">john@example.com</p>
              </div>
            </div>
            <Button variant="ghost" size="sm">
              Remove
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
};