
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { ContactSelect } from "../forms/ContactSelect";
import { CompanySelect } from "../forms/CompanySelect";
import { Database } from "@/integrations/supabase/types";
import { Textarea } from "@/components/ui/textarea";
import { Card } from "@/components/ui/card";
import { CalendarIcon, Building2Icon, UserIcon, DollarSignIcon, TagIcon, FileTextIcon } from "lucide-react";

type DealStage = Database["public"]["Enums"]["deal_stage"];

interface DealFormProps {
  formData: any;
  isEditing: boolean;
  onChange: (field: string, value: any) => void;
}

export const DealForm = ({ formData, isEditing, onChange }: DealFormProps) => {
  if (!formData) return null;

  const handleCompanyChange = (value: string) => {
    onChange("company_id", value);
    // Reset contact when company changes
    onChange("contact_id", "");
  };

  const renderField = (label: string, content: React.ReactNode, icon: React.ReactNode) => (
    <div className="space-y-2">
      <Label className="flex items-center gap-2 text-muted-foreground">
        {icon}
        {label}
      </Label>
      {content}
    </div>
  );

  if (!isEditing) {
    return (
      <div className="grid gap-8">
        <Card className="p-6 space-y-6">
          <div className="grid gap-6 sm:grid-cols-2">
            {renderField("Deal Name", 
              <p className="text-lg font-medium">{formData.name}</p>,
              <TagIcon className="w-4 h-4" />
            )}
            {renderField("Company",
              <p className="text-lg font-medium">{formData.companies?.name || "No company assigned"}</p>,
              <Building2Icon className="w-4 h-4" />
            )}
            {renderField("Contact",
              <p className="text-lg font-medium">
                {formData.contacts 
                  ? `${formData.contacts.first_name} ${formData.contacts.last_name}`
                  : "No contact assigned"}
              </p>,
              <UserIcon className="w-4 h-4" />
            )}
            {renderField("Value",
              <p className="text-lg font-medium">${formData.value?.toLocaleString() || 0}</p>,
              <DollarSignIcon className="w-4 h-4" />
            )}
            {renderField("Stage",
              <p className="text-lg font-medium capitalize">{formData.stage}</p>,
              <TagIcon className="w-4 h-4" />
            )}
          </div>
        </Card>

        <Card className="p-6">
          {renderField("Summary",
            <p className="text-muted-foreground whitespace-pre-wrap">
              {formData.notes || "No summary provided"}
            </p>,
            <FileTextIcon className="w-4 h-4" />
          )}
        </Card>
      </div>
    );
  }

  return (
    <div className="grid gap-8">
      <Card className="p-6 space-y-6">
        <div className="grid gap-6 sm:grid-cols-2">
          {renderField("Deal Name", 
            <Input
              value={formData.name}
              onChange={(e) => onChange("name", e.target.value)}
              className="bg-background"
            />,
            <TagIcon className="w-4 h-4" />
          )}

          {renderField("Company",
            <CompanySelect
              value={formData.company_id}
              onChange={handleCompanyChange}
            />,
            <Building2Icon className="w-4 h-4" />
          )}

          {renderField("Contact",
            <ContactSelect
              companyId={formData.company_id}
              value={formData.contact_id || ""}
              onChange={(value) => onChange("contact_id", value)}
            />,
            <UserIcon className="w-4 h-4" />
          )}

          {renderField("Value",
            <Input
              type="number"
              value={formData.value}
              onChange={(e) => onChange("value", e.target.value)}
              className="bg-background"
            />,
            <DollarSignIcon className="w-4 h-4" />
          )}

          {renderField("Stage",
            <Select
              value={formData.stage}
              onValueChange={(value: DealStage) => onChange("stage", value)}
            >
              <SelectTrigger className="bg-background">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="qualification">Qualification</SelectItem>
                <SelectItem value="discovery">Discovery</SelectItem>
                <SelectItem value="proposal">Proposal</SelectItem>
                <SelectItem value="negotiation">Negotiation</SelectItem>
                <SelectItem value="closing">Closing</SelectItem>
                <SelectItem value="won">Won</SelectItem>
                <SelectItem value="lost">Lost</SelectItem>
              </SelectContent>
            </Select>,
            <TagIcon className="w-4 h-4" />
          )}
        </div>
      </Card>

      <Card className="p-6">
        {renderField("Summary",
          <Textarea
            value={formData.notes || ""}
            onChange={(e) => onChange("notes", e.target.value)}
            placeholder="Describe the product or service being sold..."
            className="min-h-[150px] bg-background"
          />,
          <FileTextIcon className="w-4 h-4" />
        )}
      </Card>
    </div>
  );
};
