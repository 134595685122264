import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import { Heart } from 'lucide-react';
import { DatabaseEmployee } from '@/types/employee';

interface ProfileMedicalInfoProps {
  profile: DatabaseEmployee;
  isEditMode: boolean;
  onNestedChange: (category: 'medical_info', field: string, value: string) => void;
  allergies: string[];
}

export const ProfileMedicalInfo = ({
  profile,
  isEditMode,
  onNestedChange,
  allergies
}: ProfileMedicalInfoProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center">
          <Heart className="h-5 w-5 mr-2" />
          Health Information
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {isEditMode ? (
            <>
              <div>
                <Label htmlFor="allergies">Allergies</Label>
                <Select
                  value={profile.medical_info?.allergies || 'None'}
                  onValueChange={(value) => onNestedChange('medical_info', 'allergies', value)}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select allergies" />
                  </SelectTrigger>
                  <SelectContent>
                    {allergies.map((allergy) => (
                      <SelectItem key={allergy} value={allergy}>
                        {allergy}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div>
                <Label htmlFor="conditions">Medical Conditions</Label>
                <Textarea
                  id="conditions"
                  value={profile.medical_info?.conditions || ''}
                  onChange={(e) => onNestedChange('medical_info', 'conditions', e.target.value)}
                  placeholder="List any medical conditions"
                />
              </div>
              <div>
                <Label htmlFor="medications">Medications</Label>
                <Textarea
                  id="medications"
                  value={profile.medical_info?.medications || ''}
                  onChange={(e) => onNestedChange('medical_info', 'medications', e.target.value)}
                  placeholder="List any medications"
                />
              </div>
            </>
          ) : (
            <>
              <div>
                <h4 className="font-semibold mb-2">Allergies</h4>
                <p>{profile.medical_info?.allergies || 'None specified'}</p>
              </div>
              <div>
                <h4 className="font-semibold mb-2">Medical Conditions</h4>
                <p>{profile.medical_info?.conditions || 'None specified'}</p>
              </div>
              <div>
                <h4 className="font-semibold mb-2">Medications</h4>
                <p>{profile.medical_info?.medications || 'None specified'}</p>
              </div>
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
};