
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { MadLibForm } from "@/components/MadLibForm";
import { ClientLogosBackground } from "@/components/ClientLogosBackground";
import { Footer } from "@/components/Footer";
import { EndlessPossibilities } from "@/components/sections/EndlessPossibilities";
import { HeroSection } from "@/components/sections/HeroSection";
import { CostsSection } from "@/components/sections/CostsSection";
import { FlareSection } from "@/components/sections/FlareSection";
import { ScaleSection } from "@/components/sections/ScaleSection";
import { SuccessSection } from "@/components/sections/SuccessSection";
import { MeetupsCard } from "@/components/sections/MeetupsCard";

const subheadings = [
  'Custom Software Development & Digital Innovation',
  'Transforming Ideas into Enterprise-Grade Software Solutions',
  'Expert Software Development for Mission-Critical Projects',
  'Building Future-Proof Software Solutions Since 2017',
  'End-to-End Custom Software Development & Digital Transformation'
];

const Index = () => {
  const [subheading, setSubheading] = useState('');

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * subheadings.length);
    setSubheading(subheadings[randomIndex]);
  }, []);

  const scrollToMadLib = () => {
    const madlibSection = document.querySelector('#madlib-section');
    if (madlibSection) {
      madlibSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const sections = [
    {
      id: "mvp",
      background: "bg-gradient-to-br from-black via-brand-charcoal to-black",
      content: (
        <HeroSection subheading={subheading} scrollToMadLib={scrollToMadLib} />
      )
    },
    {
      id: "clients",
      background: "bg-gradient-to-br from-black via-brand-charcoal to-black",
      content: (
        <ClientLogosBackground />
      )
    },
    {
      id: "costs",
      background: "bg-gradient-to-bl from-brand-charcoal via-black to-brand-charcoal",
      content: (
        <CostsSection />
      )
    },
    {
      id: "flare",
      background: "bg-gradient-to-br from-black via-brand-charcoal to-black",
      content: (
        <FlareSection />
      )
    },
    {
      id: "meetups",
      background: "bg-gradient-to-br from-black via-brand-charcoal to-black",
      content: (
        <MeetupsCard />
      )
    },
    {
      id: "scale",
      background: "bg-gradient-to-tr from-brand-darkCharcoal via-black to-brand-darkCharcoal",
      content: (
        <ScaleSection />
      )
    },
    {
      id: "possibilities",
      background: "bg-gradient-to-br from-black via-brand-charcoal to-black",
      content: (
        <EndlessPossibilities />
      )
    },
    {
      id: "success",
      background: "bg-gradient-to-tl from-black via-brand-charcoal to-black",
      content: (
        <SuccessSection />
      )
    }
  ];

  return (
    <main className="relative">
      <div className="relative z-10">
        {sections.map((section, index) => (
          <motion.section
            key={section.id}
            id={section.id}
            className={`${section.background} overflow-hidden ${
              index === 0 ? 'min-h-screen relative' : 'min-h-screen sticky top-0'
            }`}
            style={{ 
              zIndex: index + 1,
            }}
            initial={{ 
              opacity: index === 0 ? 1 : 0,
              y: index === 0 ? 0 : 50
            }}
            whileInView={{ 
              opacity: 1,
              y: 0
            }}
            viewport={{ 
              once: true,
              amount: 0.05, // Reduced from 0.1 to 0.05 - requires even less of the section to be visible
              margin: "-2%" // Changed from -5% to -2% - will trigger later when scrolling
            }}
            transition={{ 
              duration: 1.5, // Increased from 1.2 to 1.5 seconds
              ease: "easeOut"
            }}
          >
            {section.content}
          </motion.section>
        ))}
      </div>

      <div className="relative z-[60] bg-black">
        <motion.section
          id="madlib-section"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ 
            once: true,
            amount: 0.05, // Changed from 0.1 to 0.05
            margin: "-2%" // Changed from -5% to -2%
          }}
          transition={{ duration: 1.5 }} // Increased from 1.2 to 1.5
          className="bg-gradient-to-br from-black via-brand-charcoal to-black"
        >
          <MadLibForm />
        </motion.section>
      </div>

      <Footer />
    </main>
  );
};

export default Index;
