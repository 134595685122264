import { useParams } from "react-router-dom";
import { Card } from "@/components/ui/card";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { ContactHeader } from "./details/ContactHeader";
import { ContactForm } from "./details/ContactForm";
import { useState } from "react";
import { Loader2 } from "lucide-react";

export const ContactDetailsPage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState<any>(null);

  const { data: contact, isLoading, error } = useQuery({
    queryKey: ['contact', id],
    queryFn: async () => {
      if (!id) throw new Error("Contact ID is required");

      const { data, error } = await supabase
        .from('contacts')
        .select(`
          *,
          companies!contacts_company_id_fkey (
            id,
            name,
            logo_url,
            industry
          )
        `)
        .eq('id', id)
        .maybeSingle();

      if (error) {
        console.error("Error fetching contact:", error);
        toast.error("Failed to fetch contact details");
        throw error;
      }

      if (!data) {
        throw new Error("Contact not found");
      }

      console.log("Fetched contact data:", data);
      setFormData(data);
      return data;
    },
    retry: false
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const { error } = await supabase
        .from('contacts')
        .update({
          ...formData,
          updated_at: new Date().toISOString(),
          interaction_count: (formData.interaction_count || 0) + 1,
        })
        .eq('id', id);

      if (error) throw error;

      toast.success("Contact updated successfully");
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating contact:", error);
      toast.error("Failed to update contact");
    }
  };

  if (error) {
    return (
      <div className="p-8 text-center">
        <p className="text-red-500 mb-4">Error loading contact details</p>
        <Button onClick={() => navigate('/admin/sales')}>
          Return to Contacts
        </Button>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="p-8 flex items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  if (!contact) {
    return (
      <div className="p-8 text-center">
        <p className="text-muted-foreground mb-4">Contact not found</p>
        <Button onClick={() => navigate('/admin/sales')}>
          Return to Contacts
        </Button>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <ContactHeader 
        contact={contact}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        handleSubmit={handleSubmit}
      />

      <Card className="p-6">
        <ContactForm
          formData={formData}
          setFormData={setFormData}
          isEditing={isEditing}
        />
      </Card>
    </div>
  );
};