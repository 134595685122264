
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { TextHoverEffect } from "@/components/ui/text-hover-effect";

export const MeetupsCard = () => {
  return (
    <motion.section
      initial={{ opacity: 0, y: 30 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: 0.3 }}
      transition={{ duration: 0.7 }}
      className="w-full min-h-screen flex flex-col items-center justify-center px-4 py-20 sm:py-32 bg-black relative" 
    >
      <div className="absolute inset-0 w-full h-full z-0">
        <TextHoverEffect 
          text="100 Meetups" 
          subText="That's how Unstuck Labs began. Not with a business plan, but with a tradition."
          className="w-full max-h-[80vh]"
          textSize="1000%" 
          subTextSize="24px"
          duration={0.3}
        />
      </div>
      
      <div className="max-w-6xl mx-auto text-center relative z-10 mt-auto">
        <motion.div 
          initial={{ opacity: 0 }} 
          animate={{ opacity: 1 }} 
          transition={{ delay: 0.6, duration: 0.7 }}
          className="mt-24"
        >
          <Link 
            to="/about" 
            className="text-brand-orange hover:text-brand-orange/80 font-medium text-lg border-b border-brand-orange/30 hover:border-brand-orange/80 pb-1 transition-all duration-300"
          >
            Read our story
          </Link>
        </motion.div>
      </div>
    </motion.section>
  );
};
