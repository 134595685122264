
import { UserRound } from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";

interface UserProfileSectionProps {
  profile: any;
  onLogout: () => void;
}

export const UserProfileSection = ({ profile, onLogout }: UserProfileSectionProps) => {
  return (
    <div className="flex items-center gap-3 px-4 py-3 mb-4">
      <Avatar className="h-10 w-10">
        <AvatarImage src={profile?.avatar_url} />
        <AvatarFallback>
          <UserRound className="h-5 w-5" />
        </AvatarFallback>
      </Avatar>
      <div className="flex flex-col">
        <span className="text-sm font-medium">
          {profile?.username || 'User'}
        </span>
        <Button 
          variant="ghost" 
          size="sm" 
          onClick={onLogout}
          className="text-brand-orange hover:text-white hover:bg-brand-charcoal justify-start p-0 h-auto text-xs"
        >
          Sign out
        </Button>
      </div>
    </div>
  );
};
