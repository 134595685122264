import { Card } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { settingsGuide } from "./documentation/guides/settingsGuide";
import { 
  Users,
  Link,
  Bell,
  Palette,
  Shield
} from "lucide-react";

export const AdminSettings = () => {
  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold">Settings</h2>
      </div>

      <Tabs defaultValue="user" className="space-y-6">
        <TabsList className="bg-card">
          <TabsTrigger value="user" className="space-x-2">
            <Users className="h-4 w-4" />
            <span>User Management</span>
          </TabsTrigger>
          <TabsTrigger value="integration" className="space-x-2">
            <Link className="h-4 w-4" />
            <span>Integrations</span>
          </TabsTrigger>
          <TabsTrigger value="notifications" className="space-x-2">
            <Bell className="h-4 w-4" />
            <span>Notifications</span>
          </TabsTrigger>
          <TabsTrigger value="appearance" className="space-x-2">
            <Palette className="h-4 w-4" />
            <span>Appearance</span>
          </TabsTrigger>
          <TabsTrigger value="security" className="space-x-2">
            <Shield className="h-4 w-4" />
            <span>Security</span>
          </TabsTrigger>
        </TabsList>

        <TabsContent value="user" className="space-y-4">
          <Card className="p-6">
            <h3 className="text-lg font-semibold mb-4">User Management</h3>
            <div className="prose dark:prose-invert">
              <p>{settingsGuide.sections[0].content}</p>
            </div>
          </Card>
        </TabsContent>

        <TabsContent value="integration" className="space-y-4">
          <Card className="p-6">
            <h3 className="text-lg font-semibold mb-4">Integration Settings</h3>
            <div className="prose dark:prose-invert">
              <p>{settingsGuide.sections[1].content}</p>
            </div>
          </Card>
        </TabsContent>

        <TabsContent value="notifications" className="space-y-4">
          <Card className="p-6">
            <h3 className="text-lg font-semibold mb-4">Notification Preferences</h3>
            <p className="text-muted-foreground">Configure your notification settings and preferences.</p>
          </Card>
        </TabsContent>

        <TabsContent value="appearance" className="space-y-4">
          <Card className="p-6">
            <h3 className="text-lg font-semibold mb-4">Appearance Settings</h3>
            <div className="prose dark:prose-invert">
              <p>{settingsGuide.sections[2].content}</p>
            </div>
          </Card>
        </TabsContent>

        <TabsContent value="security" className="space-y-4">
          <Card className="p-6">
            <h3 className="text-lg font-semibold mb-4">Security Settings</h3>
            <p className="text-muted-foreground">Manage security settings and access controls.</p>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
};