
export const validateEmail = (email: string) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

export const validatePassword = (password: string) => {
  return password.length >= 6;
};

export const isWorkEmail = (email: string): boolean => {
  if (!validateEmail(email)) return false;
  
  // List of common personal email domains to reject
  const personalDomains = [
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'outlook.com',
    'aol.com',
    'icloud.com',
    'protonmail.com',
    'mail.com',
    'zoho.com',
    'yandex.com',
    'gmx.com',
    'live.com',
    'me.com',
    'msn.com'
  ];
  
  const domain = email.split('@')[1].toLowerCase();
  return !personalDomains.includes(domain);
};

// Additional validation functions for the enterprise analyzer
export const validateCompanyName = (name: string): boolean => {
  return name.trim().length >= 2;
};

export const validatePhoneNumber = (phone: string): boolean => {
  // Basic phone validation - can be enhanced based on requirements
  const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
  return re.test(phone);
};
