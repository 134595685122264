import { motion } from "framer-motion";

interface IsometricCubeProps {
  className?: string;
  color?: string;
}

export const IsometricCube = ({ className = "", color = "currentColor" }: IsometricCubeProps) => {
  return (
    <svg className={`${className} w-24 h-24`} viewBox="0 0 100 100">
      <motion.path
        initial={{ opacity: 0, pathLength: 0 }}
        animate={{ opacity: 1, pathLength: 1 }}
        transition={{ duration: 2, ease: "easeInOut" }}
        d="M 50 10 L 90 30 L 90 70 L 50 90 L 10 70 L 10 30 Z"
        fill="none"
        stroke={color}
        strokeWidth="2"
      />
      <motion.path
        initial={{ opacity: 0, pathLength: 0 }}
        animate={{ opacity: 1, pathLength: 1 }}
        transition={{ duration: 2, delay: 0.5, ease: "easeInOut" }}
        d="M 50 10 L 50 50 L 90 70"
        fill="none"
        stroke={color}
        strokeWidth="2"
      />
      <motion.path
        initial={{ opacity: 0, pathLength: 0 }}
        animate={{ opacity: 1, pathLength: 1 }}
        transition={{ duration: 2, delay: 1, ease: "easeInOut" }}
        d="M 50 50 L 10 30"
        fill="none"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};