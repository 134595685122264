import { Header } from "@/components/Header";
import { Footer } from "@/components/Footer";
import { VenturesHero } from "@/components/ventures/VenturesHero";
import { InvestmentCriteria } from "@/components/ventures/InvestmentCriteria";
import { InvestmentFocus } from "@/components/ventures/InvestmentFocus";
import { ThesisSection } from "@/components/ventures/ThesisSection";
import { PortfolioShowcase } from "@/components/ventures/portfolio/PortfolioShowcase";
import { ApplicationProcess } from "@/components/ventures/ApplicationProcess";

const VenturesPage = () => {
  return (
    <div className="min-h-screen bg-[#1A1F2C]">
      <Header />
      <main className="relative">
        {/* Animated gradient background */}
        <div 
          className="fixed inset-0 bg-gradient-to-br from-[#1A1F2C] via-[#222222] to-[#1A1F2C] opacity-50 pointer-events-none"
        />
        
        {/* Subtle grid pattern */}
        <div className="fixed inset-0 bg-[linear-gradient(to_right,#FF4D0005_1px,transparent_1px),linear-gradient(to_bottom,#FF4D0005_1px,transparent_1px)] bg-[size:4rem_4rem] pointer-events-none" />
        
        <div className="relative">
          <VenturesHero />
          <ThesisSection />
          <InvestmentCriteria />
          <InvestmentFocus />
          <PortfolioShowcase />
          <ApplicationProcess />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default VenturesPage;