
import { useSearchParams } from 'react-router-dom';
import { HubspotMeetings } from "@/components/ui/hubspot-meetings";
import { motion } from 'framer-motion';

export const DiscoveryMeetingForm = () => {
  const [searchParams] = useSearchParams();

  // Get form data from URL params
  const formData = {
    name: searchParams.get('name') || '',
    company: searchParams.get('company') || '',
    email: searchParams.get('email') || '',
    segment: searchParams.get('segment') || '',
    job_title: searchParams.get('job_title') || '',
    service_type: searchParams.get('service_type') || '',
    goal: searchParams.get('goal') || '',
    contact_info: searchParams.get('contact_info') || '',
  };

  // Map form data to HubSpot parameters
  const hubspotParams = {
    firstName: formData.name.split(' ')[0] || '',
    lastName: formData.name.split(' ').slice(1).join(' ') || '',
    email: formData.contact_info.includes('@') ? formData.contact_info : formData.email,
    company: formData.company,
    jobTitle: formData.job_title,
    message: `Segment: ${formData.segment}, Service: ${formData.service_type}, Goal: ${formData.goal}`
  };

  return (
    <motion.div 
      className="mb-6 text-center w-full"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.7, delay: 0.5 }}
    >
      <motion.div 
        className="bg-black/50 backdrop-blur-sm p-6 rounded-xl border border-white/10"
        initial={{ opacity: 0, scale: 0.98 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, delay: 1.4 }}
      >
        <motion.h2 
          className="text-2xl md:text-3xl font-bold text-white mb-3"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 1.5 }}
        >
          Schedule Your Discovery Meeting
        </motion.h2>
        <motion.p 
          className="text-gray-300 max-w-2xl mx-auto mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 1.6 }}
        >
          Pick a time that works for you to discuss your project needs with our team. 
          We'll dive deeper into your requirements and explore how Unstuck Labs can help you succeed.
        </motion.p>
        
        <motion.div 
          className="w-full max-w-4xl mx-auto"
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.6, delay: 1.7 }}
        >
          <HubspotMeetings 
            url="https://meetings.hubspot.com/wail/dev-team-discovery?embed=true" 
            className="meetings-iframe-transparent"
            queryParams={hubspotParams}
          />
        </motion.div>
      </motion.div>

      {/* Custom styles to override HubSpot defaults */}
      <style dangerouslySetInnerHTML={{
        __html: `
        /* Customize the HubSpot iframe container */
        .meetings-iframe-container {
          height: 750px !important;
          width: 100% !important;
          max-width: 100% !important;
          margin: 0 auto;
          transition: height 0.3s ease;
          background: transparent !important;
        }
        
        /* Style for the iframe itself */
        .meetings-iframe-container iframe {
          border-radius: 0 !important;
          box-shadow: none !important;
          background-color: transparent !important;
          border: none !important;
        }
        
        /* Custom style for mobile view */
        @media (max-width: 768px) {
          .meetings-iframe-container {
            height: 700px !important;
          }
        }
      `}} />
    </motion.div>
  );
};
