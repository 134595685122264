
import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Calendar as CalendarIcon } from "lucide-react";
import { format } from "date-fns";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

interface TaskFormProps {
  dealId: string;
  onSuccess: () => void;
  onCancel: () => void;
}

export const TaskForm = ({ dealId, onSuccess, onCancel }: TaskFormProps) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [dueDate, setDueDate] = useState<Date>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (!title.trim()) return;

    try {
      setIsSubmitting(true);
      
      // First get the current user's auth data
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        toast.error("No authenticated user found");
        return;
      }

      // Then get the admin profile for this user
      const { data: adminProfiles, error: profileError } = await supabase
        .from('admin_profiles')
        .select('id')
        .eq('user_id', user.id)
        .single();

      if (profileError || !adminProfiles) {
        console.error("Error getting admin profile:", profileError);
        toast.error("Failed to get admin profile");
        return;
      }

      // Get the workspace_id from the deal
      const { data: dealData, error: dealError } = await supabase
        .from('deals')
        .select('workspace_id')
        .eq('id', dealId)
        .single();

      if (dealError || !dealData?.workspace_id) {
        toast.error('Could not determine workspace');
        return;
      }

      const { error } = await supabase.from("tasks").insert({
        deal_id: dealId,
        title,
        description,
        due_date: dueDate?.toISOString(),
        assigned_to: adminProfiles.id,
        status: "pending",
        workspace_id: dealData.workspace_id
      });

      if (error) throw error;

      toast.success("Task added successfully");
      setTitle("");
      setDescription("");
      setDueDate(undefined);
      onSuccess();
    } catch (error) {
      console.error("Error adding task:", error);
      toast.error("Failed to add task");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Card className="p-4 space-y-4">
      <Input
        placeholder="Task title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <Textarea
        placeholder="Task description (optional)"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <div className="flex items-center gap-2">
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="outline" className="w-full">
              <CalendarIcon className="mr-2 h-4 w-4" />
              {dueDate ? format(dueDate, "PPP") : "Pick a due date"}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0">
            <Calendar
              mode="single"
              selected={dueDate}
              onSelect={setDueDate}
              initialFocus
            />
          </PopoverContent>
        </Popover>
      </div>
      <div className="flex justify-end gap-2">
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button 
          onClick={handleSubmit} 
          disabled={!title.trim() || isSubmitting}
        >
          {isSubmitting ? "Adding..." : "Add Task"}
        </Button>
      </div>
    </Card>
  );
};
