
import { ProjectScopingTool } from "@/components/ProjectScoping/ProjectScopingTool";
import { motion } from "framer-motion";

export const ProjectScoping = () => {
  return (
    <section className="py-24 md:py-38 bg-black/20 backdrop-blur-sm">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
          viewport={{ once: true, margin: "-10%" }}
          className="max-w-6xl mx-auto rounded-2xl overflow-hidden shadow-xl"
        >
          <ProjectScopingTool />
        </motion.div>
      </div>
    </section>
  );
};
