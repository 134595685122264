import { useEffect, useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { supabase } from '@/integrations/supabase/client';
import { Goal, TrendingUp, Plus, Check, X, Download, BarChart } from 'lucide-react';
import { toast } from 'sonner';
import { format } from 'date-fns';

interface GoalType {
  id: string;
  title: string;
  description: string | null;
  progress: number | null;
  status: string | null;
  target_date: string | null;
  is_company_goal: boolean;
}

interface GoalFormData {
  title: string;
  description: string;
  target_date: string;
  is_company_goal: boolean;
}

export const EmployeeGoals = () => {
  const [goals, setGoals] = useState<GoalType[]>([]);
  const [loading, setLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [formData, setFormData] = useState<GoalFormData>({
    title: '',
    description: '',
    target_date: '',
    is_company_goal: false,
  });

  useEffect(() => {
    fetchGoals();
  }, []);

  const fetchGoals = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        toast.error('No authenticated user found');
        setLoading(false);
        return;
      }

      const { data: employeeProfile, error: profileError } = await supabase
        .from('employee_profiles')
        .select('id')
        .eq('user_id', user.id)
        .maybeSingle();

      if (profileError) {
        toast.error('Error fetching employee profile');
        setLoading(false);
        return;
      }

      if (!employeeProfile) {
        toast.error('Employee profile not found');
        setLoading(false);
        return;
      }

      const { data, error } = await supabase
        .from('goals')
        .select('*')
        .or(`employee_id.eq.${employeeProfile.id},is_company_goal.eq.true`)
        .order('created_at', { ascending: false });

      if (error) {
        toast.error('Error fetching goals');
        console.error('Error fetching goals:', error);
      } else if (data) {
        setGoals(data);
      }
    } catch (error) {
      toast.error('Error fetching goals');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleNewGoal = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        toast.error('No authenticated user found');
        return;
      }

      const { data: employeeProfile } = await supabase
        .from('employee_profiles')
        .select('id')
        .eq('user_id', user.id)
        .maybeSingle();

      if (!employeeProfile) {
        toast.error('Employee profile not found');
        return;
      }

      const { error } = await supabase.from('goals').insert({
        title: formData.title,
        description: formData.description,
        target_date: formData.target_date,
        is_company_goal: formData.is_company_goal,
        employee_id: formData.is_company_goal ? null : employeeProfile.id,
        status: 'in_progress',
        progress: 0,
      });

      if (error) {
        toast.error('Error creating goal');
        console.error('Error creating goal:', error);
      } else {
        toast.success('Goal created successfully');
        fetchGoals();
        setIsDialogOpen(false);
        setFormData({
          title: '',
          description: '',
          target_date: '',
          is_company_goal: false,
        });
      }
    } catch (error) {
      toast.error('Error creating goal');
      console.error('Error:', error);
    }
  };

  const generateReport = async (period: 'weekly' | 'monthly') => {
    try {
      const reportDate = new Date();
      const reportTitle = `Goals Report - ${period === 'weekly' ? 'Week of' : 'Month of'} ${format(reportDate, 'MMM d, yyyy')}`;
      
      let report = `${reportTitle}\n\n`;
      
      // Group goals by status
      const completedGoals = goals.filter(g => g.status === 'completed');
      const inProgressGoals = goals.filter(g => g.status === 'in_progress');
      const notStartedGoals = goals.filter(g => !g.status || g.status === 'not_started');

      report += `Completed Goals (${completedGoals.length}):\n`;
      completedGoals.forEach(g => {
        report += `- ${g.title} (${g.progress}%)\n`;
      });

      report += `\nIn Progress Goals (${inProgressGoals.length}):\n`;
      inProgressGoals.forEach(g => {
        report += `- ${g.title} (${g.progress}%)\n`;
      });

      report += `\nNot Started Goals (${notStartedGoals.length}):\n`;
      notStartedGoals.forEach(g => {
        report += `- ${g.title}\n`;
      });

      // Create blob and download
      const blob = new Blob([report], { type: 'text/plain' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `goals-report-${period}-${format(reportDate, 'yyyy-MM-dd')}.txt`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      toast.success(`${period === 'weekly' ? 'Weekly' : 'Monthly'} report generated`);
    } catch (error) {
      toast.error('Error generating report');
      console.error('Error:', error);
    }
  };

  const getStatusColor = (status: string | null) => {
    switch (status) {
      case 'completed':
        return 'text-green-600';
      case 'in_progress':
        return 'text-blue-600';
      default:
        return 'text-yellow-600';
    }
  };

  const getProgressColor = (progress: number | null) => {
    if (!progress) return 'bg-gray-200';
    if (progress >= 75) return 'bg-green-500';
    if (progress >= 50) return 'bg-blue-500';
    if (progress >= 25) return 'bg-yellow-500';
    return 'bg-red-500';
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <Goal className="h-6 w-6 text-primary" />
          <h1 className="text-3xl font-bold">Goals</h1>
        </div>
        <div className="flex gap-2">
          <Button variant="outline" onClick={() => generateReport('weekly')}>
            <Download className="h-4 w-4 mr-2" />
            Weekly Report
          </Button>
          <Button variant="outline" onClick={() => generateReport('monthly')}>
            <BarChart className="h-4 w-4 mr-2" />
            Monthly Report
          </Button>
          <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogTrigger asChild>
              <Button>
                <Plus className="h-4 w-4 mr-2" />
                New Goal
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Create New Goal</DialogTitle>
              </DialogHeader>
              <div className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="title">Title</Label>
                  <Input
                    id="title"
                    value={formData.title}
                    onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="description">Description</Label>
                  <Textarea
                    id="description"
                    value={formData.description}
                    onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="target_date">Target Date</Label>
                  <Input
                    id="target_date"
                    type="date"
                    value={formData.target_date}
                    onChange={(e) => setFormData(prev => ({ ...prev, target_date: e.target.value }))}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="is_company_goal">Goal Type</Label>
                  <Select
                    value={formData.is_company_goal ? 'company' : 'personal'}
                    onValueChange={(value) => setFormData(prev => ({ ...prev, is_company_goal: value === 'company' }))}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select goal type" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="personal">Personal Goal</SelectItem>
                      <SelectItem value="company">Company Goal</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <Button onClick={handleNewGoal} className="w-full">Create Goal</Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        {goals.map((goal) => (
          <Card key={goal.id} className="hover:shadow-lg transition-shadow">
            <CardHeader>
              <CardTitle className="flex justify-between items-start">
                <span className="flex-1">{goal.title}</span>
                <span className={`text-sm ${getStatusColor(goal.status)}`}>
                  {goal.status === 'completed' ? (
                    <Check className="h-5 w-5" />
                  ) : goal.status === 'in_progress' ? (
                    <TrendingUp className="h-5 w-5" />
                  ) : (
                    <X className="h-5 w-5" />
                  )}
                </span>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {goal.description && (
                  <p className="text-muted-foreground text-sm">
                    {goal.description}
                  </p>
                )}
                
                <div className="space-y-2">
                  <div className="flex justify-between text-sm">
                    <span>Progress</span>
                    <span>{goal.progress || 0}%</span>
                  </div>
                  <div className="w-full h-2 bg-gray-200 rounded-full overflow-hidden">
                    <div 
                      className={`h-full ${getProgressColor(goal.progress)} transition-all duration-300`}
                      style={{ width: `${goal.progress || 0}%` }}
                    />
                  </div>
                </div>

                {goal.target_date && (
                  <div className="text-sm text-muted-foreground">
                    Target: {new Date(goal.target_date).toLocaleDateString()}
                  </div>
                )}

                {goal.is_company_goal && (
                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-primary/10 text-primary">
                    Company Goal
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        ))}
        
        {goals.length === 0 && (
          <Card className="col-span-full">
            <CardContent className="py-8">
              <div className="text-center text-muted-foreground">
                <Goal className="h-12 w-12 mx-auto mb-4 opacity-50" />
                <p>No goals found. Click "New Goal" to create your first goal!</p>
              </div>
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
};