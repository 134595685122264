
import { motion } from "framer-motion";
import { Code, Rocket, Zap, Phone } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { ContactPopover } from "@/components/ContactPopover";

interface StartupHeroProps {
  returningVisitor: boolean;
}

export const StartupHero = ({ returningVisitor }: StartupHeroProps) => {
  const navigate = useNavigate();

  const features = [
    "Free consultation",
    "4-week MVP",
    "Fixed pricing",
    "Technical co-founding",
    "Launch faster",
    "Scale with demand"
  ];

  const cards = [
    {
      icon: Code,
      title: "4-Week MVP",
      description: "From concept to working product"
    },
    {
      icon: Zap,
      title: "Tech Co-Founding",
      description: "Expert guidance throughout"
    },
    {
      icon: Rocket,
      title: "Fixed Pricing",
      description: "No surprise costs"
    }
  ];

  return (
    <div className="relative min-h-[calc(100vh-4rem)] flex items-center justify-center w-full">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-5xl mx-auto px-4 text-left space-y-6 relative z-10"
      >
        {/* Badge */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="inline-flex items-center px-3 py-1 rounded-full bg-[#FF4D00]/10 text-[#FF4D00] text-sm font-mono mb-6"
        >
          🚀 Startup MVP Development
        </motion.div>

        {/* Main Heading */}
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="text-5xl sm:text-6xl md:text-7xl font-display font-bold text-white leading-[1.1] mb-4"
        >
          Launch Your Startup<br />
          <span className="text-[#FF4D00]">in 4 Weeks</span>
        </motion.h1>

        {/* Subheading */}
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="text-xl text-gray-300 max-w-2xl mb-6"
        >
          Get fixed-price development and technical co-founding support to launch faster than your competition.
        </motion.p>

        {/* Feature Pills */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
          className="flex flex-wrap gap-3 mb-8"
        >
          {features.map((feature, index) => (
            <motion.span
              key={index}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.5 + index * 0.1 }}
              className="px-4 py-2 rounded-full bg-white/5 text-gray-300 text-sm border border-white/10 backdrop-blur-sm"
            >
              • {feature}
            </motion.span>
          ))}
        </motion.div>

        {/* Contact Button */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
          className="mb-10"
        >
          <ContactPopover>
            <Button 
              className="inline-flex items-center gap-2 px-5 py-3 bg-[#FF4D00] hover:bg-[#FF4D00]/90 text-white rounded-lg transition-colors border-none"
            >
              <Phone className="h-5 w-5 text-white" />
              <span>Talk to a Technical Founder</span>
            </Button>
          </ContactPopover>
        </motion.div>

        {/* Feature Cards */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
          className="grid grid-cols-1 md:grid-cols-3 gap-6"
        >
          {cards.map((card, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 + index * 0.1 }}
              whileHover={{ scale: 1.02, transition: { duration: 0.2 } }}
              className="p-6 rounded-lg bg-white/5 border border-white/10 backdrop-blur-sm hover:bg-white/10 transition-colors group"
            >
              <card.icon className="w-8 h-8 text-[#FF4D00] mb-4 group-hover:scale-110 transition-transform" />
              <h3 className="text-xl font-bold text-white mb-2">{card.title}</h3>
              <p className="text-gray-400">{card.description}</p>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    </div>
  );
};
