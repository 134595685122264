import { motion } from "framer-motion";
import { CheckCircle2 } from "lucide-react";

const steps = [
  {
    title: "Discovery",
    description: "Understanding your needs and vision"
  },
  {
    title: "Planning",
    description: "Creating detailed project roadmap"
  },
  {
    title: "Development",
    description: "Building your solution"
  },
  {
    title: "Testing",
    description: "Ensuring quality and performance"
  },
  {
    title: "Launch",
    description: "Deploying to production"
  }
];

export const ProcessTimeline = () => {
  return (
    <div className="relative">
      <div className="absolute top-5 left-5 right-5 h-0.5 bg-gray-200" />
      <div className="relative grid grid-cols-5 gap-4">
        {steps.map((step, index) => (
          <motion.div
            key={step.title}
            className="relative pt-8 text-center"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            viewport={{ once: true }}
          >
            <motion.div
              className="absolute top-0 left-1/2 -translate-x-1/2 w-10 h-10 bg-secondary rounded-full flex items-center justify-center"
              whileHover={{ scale: 1.1 }}
            >
              <CheckCircle2 className="w-5 h-5 text-white" />
            </motion.div>
            <h3 className="font-bold mb-1">{step.title}</h3>
            <p className="text-sm text-gray-500">{step.description}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};