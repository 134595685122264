import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Mail, Phone, Plus, UserPlus } from "lucide-react";
import { CompanyWithRelations } from "@/types/company";
import { ScrollArea } from "@/components/ui/scroll-area";

interface CompanyContactsProps {
  contacts: CompanyWithRelations['contacts'];
}

export const CompanyContacts = ({ contacts }: CompanyContactsProps) => {
  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-semibold">Contacts</h3>
        <Button size="sm" className="gap-2">
          <UserPlus className="w-4 h-4" />
          Add Contact
        </Button>
      </div>

      {contacts?.length === 0 ? (
        <Card className="p-8 flex flex-col items-center justify-center text-center space-y-3">
          <div className="w-12 h-12 rounded-full bg-primary/10 flex items-center justify-center">
            <Plus className="w-6 h-6 text-primary" />
          </div>
          <div className="space-y-1">
            <h3 className="font-semibold">No contacts yet</h3>
            <p className="text-sm text-muted-foreground">
              Add contacts to keep track of key people at this company
            </p>
          </div>
        </Card>
      ) : (
        <ScrollArea className="h-[400px]">
          <Card className="divide-y">
            {contacts?.map((contact) => (
              <div 
                key={contact.id} 
                className="p-4 flex items-center justify-between hover:bg-muted/50 transition-colors"
              >
                <div>
                  <p className="font-medium">
                    {contact.first_name} {contact.last_name}
                  </p>
                  {contact.job_title && (
                    <p className="text-sm text-muted-foreground">
                      {contact.job_title}
                    </p>
                  )}
                </div>
                <div className="flex gap-2">
                  {contact.email && (
                    <Button
                      variant="ghost"
                      size="icon"
                      className="h-8 w-8"
                      asChild
                    >
                      <a href={`mailto:${contact.email}`}>
                        <Mail className="w-4 h-4" />
                      </a>
                    </Button>
                  )}
                  {contact.phone && (
                    <Button
                      variant="ghost"
                      size="icon"
                      className="h-8 w-8"
                      asChild
                    >
                      <a href={`tel:${contact.phone}`}>
                        <Phone className="w-4 h-4" />
                      </a>
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </Card>
        </ScrollArea>
      )}
    </div>
  );
};