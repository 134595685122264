import { ReactFlow, Background } from '@xyflow/react';
import { nodeTypes } from '../nodes';
import { ViewProps } from '../types/views';
import '@xyflow/react/dist/style.css';

export const FlowView = ({ 
  nodes, 
  edges, 
  onNodesChange, 
  onEdgesChange, 
  onConnect,
  onNodeDrag,
  onNodeDragStop,
  onNodeDoubleClick,
  onDragOver,
  onDrop,
  fitView,
  minZoom,
  maxZoom,
  defaultViewport
}: ViewProps) => {
  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      onNodeDrag={onNodeDrag}
      onNodeDragStop={onNodeDragStop}
      onNodeDoubleClick={onNodeDoubleClick}
      onDragOver={onDragOver}
      onDrop={onDrop}
      nodeTypes={nodeTypes}
      fitView={fitView}
      minZoom={minZoom}
      maxZoom={maxZoom}
      defaultViewport={defaultViewport}
      className="bg-background"
    >
      <Background color="#333" gap={20} />
    </ReactFlow>
  );
};