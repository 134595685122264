
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { BlogPost as BlogPostType } from "@/types/blog";
import { motion } from "framer-motion";
import { ArrowLeft } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { useState } from "react";

const BlogPost = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [imageError, setImageError] = useState(false);

  // Fallback image in case the one from post doesn't load
  const fallbackImage = "/lovable-uploads/e371f263-27ef-4fc0-a6ec-86c9e23d904f.png";

  const { data: post, isLoading } = useQuery({
    queryKey: ['blog-post', slug],
    queryFn: async () => {
      if (!slug) {
        console.error('No slug provided');
        throw new Error('Invalid blog post URL');
      }

      const { data, error } = await supabase
        .from('blog_posts')
        .select('*')
        .eq('slug', slug)
        .maybeSingle();
      
      if (error) {
        console.error('Error fetching blog post:', error);
        throw error;
      }

      if (!data) {
        console.error('Blog post not found for slug:', slug);
        throw new Error('Blog post not found');
      }

      return data as BlogPostType;
    },
    enabled: Boolean(slug),
    meta: {
      onError: (error: Error) => {
        toast({
          variant: "destructive",
          title: "Error",
          description: error.message || "Failed to load blog post"
        });
        navigate('/blog');
      }
    }
  });

  if (isLoading) {
    return (
      <section className="py-32 bg-background">
        <div className="container mx-auto px-4">
          <div className="text-center">
            <p>Loading blog post...</p>
          </div>
        </div>
      </section>
    );
  }

  if (!post) {
    return (
      <section className="py-32 bg-background">
        <div className="container mx-auto px-4">
          <div className="text-center">
            <h2 className="text-3xl font-bold mb-4">Blog Post Not Found</h2>
            <p className="text-gray-600 mb-6">
              The blog post you're looking for could not be found.
            </p>
            <Button onClick={() => navigate('/blog')} variant="outline">
              <ArrowLeft className="w-4 h-4 mr-2" />
              Back to Blog
            </Button>
          </div>
        </div>
      </section>
    );
  }
  
  const imageUrl = imageError 
    ? fallbackImage 
    : (post.image_url.startsWith('http') ? post.image_url : `${window.location.origin}${post.image_url}`);

  return (
    <section className="py-32 bg-background">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Button 
            onClick={() => navigate('/blog')} 
            variant="outline"
            className="mb-8"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Blog
          </Button>

          <div className="max-w-4xl mx-auto">
            <div className="aspect-video relative overflow-hidden rounded-lg mb-8">
              <img
                src={imageUrl}
                alt={post.title}
                className="object-cover w-full h-full"
                onError={(e) => {
                  console.error('Failed to load image:', post.image_url);
                  setImageError(true);
                }}
              />
            </div>

            <div className="flex flex-wrap gap-2 mb-6">
              {post.tags?.map((tag, index) => (
                <span
                  key={index}
                  className="text-sm font-medium px-3 py-1 rounded-full bg-secondary/10 text-secondary"
                >
                  {tag}
                </span>
              ))}
            </div>

            <h1 className="text-5xl font-display font-bold mb-6 text-primary">
              {post.title}
            </h1>

            <div className="flex items-center justify-between text-sm text-primary/60 mb-8">
              <span>{post.author}</span>
              <span>{post.read_time}</span>
            </div>

            <div className="prose prose-lg max-w-none">
              {post.content}
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default BlogPost;
