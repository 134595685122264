import { motion } from "framer-motion";
import { Terminal, Globe, BarChart3, Code2, Boxes, Brain, Clock } from "lucide-react";
import { TechStack } from "@/components/playground/TechStack";
import { CodeTerminal } from "@/components/playground/CodeTerminal";
import { MetricsDashboard } from "@/components/playground/MetricsDashboard";
import { ProcessTimeline } from "@/components/playground/ProcessTimeline";
import { GlobeVisualization } from "@/components/playground/GlobeVisualization";
import { CodeAnalyzer } from "@/components/playground/CodeAnalyzer";
import { InnovationTimeline } from "@/components/playground/InnovationTimeline";

const DesignPlayground = () => {
  return (
    <div className="min-h-screen bg-background">
      <div className="container mx-auto px-4 py-12">
        <motion.h1 
          className="text-4xl font-display font-bold mb-8 text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          Design Components Playground
        </motion.h1>

        <div className="grid gap-12">
          {/* Innovation Timeline */}
          <section className="space-y-4">
            <div className="flex items-center gap-2 text-2xl font-display">
              <Clock className="w-6 h-6" />
              <h2>Innovation Timeline</h2>
            </div>
            <InnovationTimeline />
          </section>

          <section className="space-y-4">
            <div className="flex items-center gap-2 text-2xl font-display">
              <Brain className="w-6 h-6" />
              <h2>AI Code Analyzer</h2>
            </div>
            <CodeAnalyzer />
          </section>

          {/* Keep existing sections */}
          <section className="space-y-4">
            <div className="flex items-center gap-2 text-2xl font-display">
              <Terminal className="w-6 h-6" />
              <h2>Interactive Code Terminal</h2>
            </div>
            <CodeTerminal />
          </section>

          {/* 3D Tech Stack */}
          <section className="space-y-4">
            <div className="flex items-center gap-2 text-2xl font-display">
              <Boxes className="w-6 h-6" />
              <h2>3D Tech Stack</h2>
            </div>
            <TechStack />
          </section>

          {/* Metrics Dashboard */}
          <section className="space-y-4">
            <div className="flex items-center gap-2 text-2xl font-display">
              <BarChart3 className="w-6 h-6" />
              <h2>Live Metrics Dashboard</h2>
            </div>
            <MetricsDashboard />
          </section>

          {/* Process Timeline */}
          <section className="space-y-4">
            <div className="flex items-center gap-2 text-2xl font-display">
              <Code2 className="w-6 h-6" />
              <h2>Interactive Process Timeline</h2>
            </div>
            <ProcessTimeline />
          </section>

          {/* Global Impact Map */}
          <section className="space-y-4">
            <div className="flex items-center gap-2 text-2xl font-display">
              <Globe className="w-6 h-6" />
              <h2>Global Impact Map</h2>
            </div>
            <GlobeVisualization />
          </section>
        </div>
      </div>
    </div>
  );
};

export default DesignPlayground;
