
import { motion } from "framer-motion";
import { FlareHero } from "./flare/FlareHero";
import { FlareAdvantages } from "./flare/FlareAdvantages";
import { ParticleBackground } from "./flare/ParticleBackground";
import { FlareStats } from "./flare/FlareStats";
import { FlareHowItWorks } from "./flare/FlareHowItWorks";
import { UnifiedContactForm } from "@/components/UnifiedContactForm";

export const FlareContent = () => {
  return (
    <div className="relative min-h-screen bg-black text-white overflow-hidden">
      <ParticleBackground />
      
      <div className="relative z-10 pt-20 md:pt-24">
        <div className="py-12 text-center">
          <motion.span 
            className="inline-flex items-center font-mono text-brand-orange text-sm md:text-base"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            UNSTUCK LABS <span className="ml-2 w-2 h-2 rounded-full bg-brand-orange"></span>
          </motion.span>
        </div>
        <FlareHero />
        <FlareStats />
        <FlareHowItWorks />
        <FlareAdvantages />
        <UnifiedContactForm darkMode={true} />
      </div>
    </div>
  );
};
