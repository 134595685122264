
import { motion } from "framer-motion";
import { Cpu, BarChart, Code } from "lucide-react";

export const FlareStats = () => {
  const stats = [
    {
      id: "01",
      icon: <Cpu className="w-5 h-5 text-brand-orange" />,
      title: "10X FASTER",
      description: "Launch in weeks, not months"
    },
    {
      id: "02",
      icon: <BarChart className="w-5 h-5 text-brand-orange" />,
      title: "60% SAVINGS",
      description: "Enterprise quality at startup costs"
    },
    {
      id: "03",
      icon: <Code className="w-5 h-5 text-brand-orange" />,
      title: "PRODUCTION-READY",
      description: "Not just an MVP, but scalable code"
    }
  ];

  return (
    <section className="py-16 px-4">
      <div className="max-w-6xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {stats.map((stat, index) => (
            <motion.div
              key={stat.id}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              viewport={{ once: true }}
              className="border border-zinc-800 bg-zinc-900/30 backdrop-blur-sm rounded-xl p-6 hover:border-zinc-700 transition-all"
            >
              <div className="flex items-center gap-2 text-xs font-mono text-brand-orange mb-4">
                {stat.id} <span>{stat.icon}</span>
              </div>
              <h3 className="text-2xl font-bold mb-2 text-white">
                {stat.title}
              </h3>
              <p className="text-zinc-400">
                {stat.description}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};
