
import { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Database } from "@/integrations/supabase/types";
import { CompanySelect } from "./forms/CompanySelect";
import { ContactSelect } from "./forms/ContactSelect";
import { DealBasicInfo } from "./forms/DealBasicInfo";
import { DealAdditionalInfo } from "./forms/DealAdditionalInfo";

type DealStage = Database["public"]["Enums"]["deal_stage"];

interface CreateDealDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSuccess?: () => void;
}

interface DealFormData {
  name: string;
  value: string;
  stage: DealStage;
  priority: string;
  company_id: string;
  contact_id?: string;
  expected_close_date: string;
  notes: string;
}

export const CreateDealDialog = ({ open, onOpenChange, onSuccess }: CreateDealDialogProps) => {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState<DealFormData>({
    name: "",
    value: "",
    stage: "qualification",
    priority: "medium",
    company_id: "",
    contact_id: "",
    expected_close_date: "",
    notes: "",
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.company_id) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Please select a company",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      // First get the company's workspace_id
      const { data: companyData, error: companyError } = await supabase
        .from('companies')
        .select('workspace_id')
        .eq('id', formData.company_id)
        .single();

      if (companyError || !companyData?.workspace_id) {
        toast({
          variant: "destructive",
          title: "Error",
          description: "Could not determine workspace",
        });
        return;
      }

      const { error } = await supabase
        .from("deals")
        .insert({
          name: formData.name,
          value: formData.value ? parseFloat(formData.value) : null,
          stage: formData.stage,
          priority: formData.priority,
          company_id: formData.company_id,
          contact_id: formData.contact_id || null,
          expected_close_date: formData.expected_close_date || null,
          notes: formData.notes,
          created_at: new Date().toISOString(),
          workspace_id: companyData.workspace_id
        });

      if (error) throw error;

      toast({
        title: "Success",
        description: "Deal created successfully",
      });

      onSuccess?.();
      setFormData({
        name: "",
        value: "",
        stage: "qualification",
        priority: "medium",
        company_id: "",
        contact_id: "",
        expected_close_date: "",
        notes: "",
      });
      onOpenChange(false);
    } catch (error) {
      console.error("Error creating deal:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to create deal",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFieldChange = (field: string, value: any) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Create New Deal</DialogTitle>
        </DialogHeader>

        <form onSubmit={handleSubmit} className="space-y-6 mt-4">
          <CompanySelect
            value={formData.company_id}
            onChange={(value) => handleFieldChange("company_id", value)}
          />

          <ContactSelect
            companyId={formData.company_id}
            value={formData.contact_id}
            onChange={(value) => handleFieldChange("contact_id", value)}
          />

          <DealBasicInfo
            formData={formData}
            onChange={handleFieldChange}
          />

          <DealAdditionalInfo
            formData={formData}
            onChange={handleFieldChange}
          />

          <div className="flex justify-end gap-2 mt-6">
            <Button
              type="button"
              variant="outline"
              onClick={() => onOpenChange(false)}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Creating..." : "Create Deal"}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
