
import { useState } from 'react';
import { useAuth } from '@/contexts/AuthContext';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { Task } from '@/types/task';
import { useQueryClient } from '@tanstack/react-query';

export function useCommandActions(task: Task) {
  const { session } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  
  const addSystemMessage = async (content: string) => {
    if (!session?.user?.id || !task.id) return;
    
    try {
      const { error } = await supabase.from('task_messages').insert({
        task_id: task.id,
        content,
        user_id: session.user.id,
        type: 'system'
      });
      
      if (error) throw error;
      
      // Invalidate messages query to show new message
      queryClient.invalidateQueries({ queryKey: ['task-messages', task.id] });
    } catch (error) {
      console.error('Error adding system message:', error);
    }
  };

  const requestApproval = async () => {
    if (!session?.user?.id) return;
    setIsLoading(true);
    try {
      // First get the workspace members who could approve
      const { data: workspaceMembers, error: membersError } = await supabase
        .from('workspace_members')
        .select('user_id')
        .eq('workspace_id', task.workspace_id)
        .neq('user_id', session.user.id);

      if (membersError) throw membersError;

      if (!workspaceMembers?.length) {
        toast.error('No workspace members available to request approval from');
        return;
      }

      // Request approval from the first available member
      const { error } = await supabase.from('task_approvals').insert({
        task_id: task.id,
        requested_by: session.user.id,
        requested_from: workspaceMembers[0].user_id,
        status: 'pending'
      });
      
      if (error) throw error;
      
      // Add system message about approval request
      await addSystemMessage('🔍 Approval requested from team member');
      
      toast.success('Approval request sent successfully');
    } catch (error) {
      console.error('Error requesting approval:', error);
      toast.error('Failed to send approval request');
    } finally {
      setIsLoading(false);
    }
  };

  const requestFeedback = async () => {
    if (!session?.user?.id) return;
    setIsLoading(true);
    try {
      const { error } = await supabase.from('task_feedback').insert({
        task_id: task.id,
        requested_by: session.user.id,
        feedback_type: 'general',
        status: 'pending'
      });
      if (error) throw error;
      
      // Add system message about feedback request
      await addSystemMessage('💭 Feedback requested from team');
      
      toast.success('Feedback request sent successfully');
    } catch (error) {
      console.error('Error requesting feedback:', error);
      toast.error('Failed to send feedback request');
    } finally {
      setIsLoading(false);
    }
  };

  const scheduleMeeting = async (startTime: Date, endTime: Date, title: string, description?: string) => {
    if (!session?.user?.id) return;
    setIsLoading(true);
    try {
      const { error } = await supabase.from('task_meetings').insert({
        task_id: task.id,
        scheduled_by: session.user.id,
        meeting_type: 'review',
        start_time: startTime.toISOString(),
        end_time: endTime.toISOString(),
        title,
        description,
        attendees: []
      });
      if (error) throw error;
      
      // Add system message about scheduled meeting
      await addSystemMessage(`📅 Meeting scheduled: ${title}\nDate: ${startTime.toLocaleDateString()}\nTime: ${startTime.toLocaleTimeString()} - ${endTime.toLocaleTimeString()}`);
      
      toast.success('Meeting scheduled successfully');
    } catch (error) {
      console.error('Error scheduling meeting:', error);
      toast.error('Failed to schedule meeting');
    } finally {
      setIsLoading(false);
    }
  };

  const addTechnicalDoc = async (title: string, content: string, docType: string) => {
    if (!session?.user?.id) return;
    setIsLoading(true);
    try {
      const { error } = await supabase.from('task_documentation').insert({
        task_id: task.id,
        created_by: session.user.id,
        title,
        content,
        doc_type: docType
      });
      if (error) throw error;
      
      // Add system message about new documentation
      await addSystemMessage(`📄 New ${docType} documentation added: ${title}`);
      
      toast.success('Documentation added successfully');
    } catch (error) {
      console.error('Error adding documentation:', error);
      toast.error('Failed to add documentation');
    } finally {
      setIsLoading(false);
    }
  };

  const addApiDoc = async (endpoint: string, method: string, description: string) => {
    if (!session?.user?.id) return;
    setIsLoading(true);
    try {
      const { error } = await supabase.from('task_api_docs').insert({
        task_id: task.id,
        created_by: session.user.id,
        endpoint,
        method,
        description
      });
      if (error) throw error;
      
      // Add system message about new API documentation
      await addSystemMessage(`🔗 New API documentation added\nEndpoint: ${endpoint}\nMethod: ${method}`);
      
      toast.success('API documentation added successfully');
    } catch (error) {
      console.error('Error adding API documentation:', error);
      toast.error('Failed to add API documentation');
    } finally {
      setIsLoading(false);
    }
  };

  const flagBlocker = async (description: string) => {
    if (!task.id) return;
    setIsLoading(true);
    try {
      const { error } = await supabase
        .from('tasks')
        .update({ status: 'blocked' })
        .eq('id', task.id);
      if (error) throw error;
      
      // Add system message about blocker
      await addSystemMessage(`⚠️ Task marked as blocked: ${description}`);
      
      toast.success('Task marked as blocked');
    } catch (error) {
      console.error('Error flagging blocker:', error);
      toast.error('Failed to flag blocker');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    requestApproval,
    requestFeedback,
    scheduleMeeting,
    addTechnicalDoc,
    addApiDoc,
    flagBlocker
  };
}
