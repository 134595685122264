import { memo } from 'react';
import { Handle, Position } from '@xyflow/react';
import { FeatureNotes } from '../components/FeatureNotes';
import { Badge } from '@/components/ui/badge';
import { CircleDollarSign, Zap, Clock, ArrowDown, Target } from 'lucide-react';

interface FeatureNodeData {
  id: string;
  label: string;
  description?: string;
  priority?: 'high' | 'medium' | 'low';
  points?: number;
  objectives?: string[];
  notes?: string;
  attachments?: { name: string; url: string }[];
  onNotesChange?: (notes: string) => void;
  onAttachmentAdd?: (attachment: { name: string; url: string }) => void;
  onAttachmentRemove?: (url: string) => void;
}

const priorityConfig = {
  high: {
    color: 'bg-red-900/50 border-red-500/50',
    icon: Zap,
    badge: 'text-red-500 bg-red-500/10'
  },
  medium: {
    color: 'bg-yellow-900/50 border-yellow-500/50',
    icon: Clock,
    badge: 'text-amber-500 bg-amber-500/10'
  },
  low: {
    color: 'bg-green-900/50 border-green-500/50',
    icon: ArrowDown,
    badge: 'text-blue-500 bg-blue-500/10'
  }
};

export const FeatureNode = memo(({ data, id, selected }: { data: FeatureNodeData; id: string; selected?: boolean }) => {
  if (!data) {
    return null;
  }

  const getPriorityColor = () => {
    const priority = data.priority || 'medium';
    return priorityConfig[priority].color;
  };

  const getPointsColor = () => {
    const points = data.points || 0;
    if (points > 200) return 'text-purple-500 bg-purple-500/10';
    if (points > 50) return 'text-blue-500 bg-blue-500/10';
    return 'text-green-500 bg-green-500/10';
  };

  const priority = data.priority || 'medium';
  const PriorityIcon = priorityConfig[priority].icon;

  return (
    <div 
      className={`
        min-w-[200px] px-4 py-2 rounded-lg border-2 shadow-lg backdrop-blur-sm 
        ${getPriorityColor()}
        ${selected ? 'ring-2 ring-primary ring-offset-2 ring-offset-background scale-105' : ''}
        transition-all duration-200 ease-in-out touch-none
      `}
    >
      <Handle type="target" position={Position.Left} className="w-3 h-3" />
      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <div className="font-medium">{data.label}</div>
          <div className="flex items-center gap-2">
            {data.points !== undefined && (
              <Badge className={`${getPointsColor()} flex items-center gap-1`}>
                <CircleDollarSign className="h-3 w-3" />
                {data.points} pts
              </Badge>
            )}
            {priority && (
              <Badge className={`${priorityConfig[priority].badge} flex items-center gap-1`}>
                <PriorityIcon className="h-3 w-3" />
                <span className="capitalize">{priority}</span>
              </Badge>
            )}
          </div>
        </div>
        {data.description && (
          <div className="text-sm text-gray-300">{data.description}</div>
        )}
        {data.objectives && data.objectives.length > 0 && (
          <div className="mt-4 space-y-2">
            <div className="font-medium flex items-center gap-2">
              <Target className="h-4 w-4" />
              Key Objectives:
            </div>
            <ul className="list-disc list-inside space-y-1">
              {data.objectives.map((objective, index) => (
                <li key={index} className="text-sm text-gray-300">{objective}</li>
              ))}
            </ul>
          </div>
        )}
        <FeatureNotes
          featureId={id}
          initialNotes={data.notes}
          attachments={data.attachments}
          onNotesChange={data.onNotesChange}
          onAttachmentAdd={data.onAttachmentAdd}
          onAttachmentRemove={data.onAttachmentRemove}
        />
      </div>
      <Handle type="source" position={Position.Right} className="w-3 h-3" />
    </div>
  );
});

FeatureNode.displayName = 'FeatureNode';