import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Calendar } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { format } from 'date-fns';

export const DashboardSchedule = () => {
  const { data: schedules, isLoading } = useQuery({
    queryKey: ['employee-schedule'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const { data: profile } = await supabase
        .from('employee_profiles')
        .select('id')
        .eq('user_id', user.id)
        .single();

      if (!profile) throw new Error('No employee profile found');

      const { data, error } = await supabase
        .from('schedules')
        .select('*')
        .eq('employee_id', profile.id)
        .gte('date', new Date().toISOString())
        .order('date', { ascending: true })
        .limit(3);

      if (error) throw error;
      return data;
    },
    meta: {
      onError: (error: Error) => {
        console.error('Error fetching schedule:', error);
        toast.error('Failed to load schedule');
      }
    }
  });

  if (isLoading) {
    return <div>Loading schedule...</div>;
  }

  return (
    <Card className="col-span-full">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">Upcoming Schedule</CardTitle>
        <Calendar className="h-4 w-4 text-muted-foreground" />
      </CardHeader>
      <CardContent>
        {schedules && schedules.length > 0 ? (
          <div className="space-y-4">
            {schedules.map((schedule) => (
              <div key={schedule.id} className="flex justify-between items-center">
                <div className="flex flex-col">
                  <span className="font-medium">
                    {format(new Date(schedule.date), 'EEEE, MMMM d')}
                  </span>
                  <span className="text-sm text-muted-foreground">
                    {schedule.start_time} - {schedule.end_time}
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-sm text-muted-foreground">No upcoming schedules</p>
        )}
      </CardContent>
    </Card>
  );
};