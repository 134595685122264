
import { Card } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import type { Database } from '@/types/supabase';

type WorkspaceAnalytics = Database['public']['Tables']['workspace_analytics']['Row'];

interface ActivityFeedProps {
  analytics: WorkspaceAnalytics | null;
  isLoading: boolean;
}

export function ActivityFeed({ analytics, isLoading }: ActivityFeedProps) {
  return (
    <Card className="p-6">
      <h3 className="text-lg font-medium mb-4">Recent Activity</h3>
      {isLoading ? (
        <div className="space-y-4">
          {[...Array(3)].map((_, i) => (
            <div key={i} className="flex items-start gap-4">
              <Skeleton className="w-2 h-2 mt-2 rounded-full" />
              <div className="flex-1">
                <Skeleton className="h-4 w-full mb-1" />
                <Skeleton className="h-3 w-24" />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="space-y-4">
          {(analytics?.recent_activities as any[])?.map((activity, index) => (
            <div key={index} className="flex items-start gap-4">
              <div className="w-2 h-2 mt-2 rounded-full bg-blue-500" />
              <div>
                <p className="text-sm">{activity.description}</p>
                <p className="text-xs text-gray-500">
                  {new Date(activity.timestamp).toLocaleDateString()}
                </p>
              </div>
            </div>
          )) || (
            <p className="text-sm text-gray-500">No recent activities</p>
          )}
        </div>
      )}
    </Card>
  );
}
