import { TabsList, TabsTrigger } from "@/components/ui/tabs";
import { FileText, MessageSquare, CheckSquare, Users, History } from "lucide-react";

export const DealDetailsTabs = () => {
  return (
    <TabsList className="bg-transparent border-b rounded-none p-0 h-auto w-full flex overflow-x-auto">
      <TabsTrigger
        value="overview"
        className="rounded-none border-b-2 border-transparent data-[state=active]:border-primary data-[state=active]:text-foreground text-muted-foreground hover:text-foreground px-6 py-3 transition-colors"
      >
        Overview
      </TabsTrigger>
      <TabsTrigger
        value="activities"
        className="rounded-none border-b-2 border-transparent data-[state=active]:border-primary data-[state=active]:text-foreground text-muted-foreground hover:text-foreground px-6 py-3 flex items-center gap-2 transition-colors"
      >
        <History className="w-4 h-4" />
        Activities
      </TabsTrigger>
      <TabsTrigger
        value="notes"
        className="rounded-none border-b-2 border-transparent data-[state=active]:border-primary data-[state=active]:text-foreground text-muted-foreground hover:text-foreground px-6 py-3 flex items-center gap-2 transition-colors"
      >
        <MessageSquare className="w-4 h-4" />
        Notes
      </TabsTrigger>
      <TabsTrigger
        value="files"
        className="rounded-none border-b-2 border-transparent data-[state=active]:border-primary data-[state=active]:text-foreground text-muted-foreground hover:text-foreground px-6 py-3 flex items-center gap-2 transition-colors"
      >
        <FileText className="w-4 h-4" />
        Files
      </TabsTrigger>
      <TabsTrigger
        value="tasks"
        className="rounded-none border-b-2 border-transparent data-[state=active]:border-primary data-[state=active]:text-foreground text-muted-foreground hover:text-foreground px-6 py-3 flex items-center gap-2 transition-colors"
      >
        <CheckSquare className="w-4 h-4" />
        Tasks
      </TabsTrigger>
      <TabsTrigger
        value="team"
        className="rounded-none border-b-2 border-transparent data-[state=active]:border-primary data-[state=active]:text-foreground text-muted-foreground hover:text-foreground px-6 py-3 flex items-center gap-2 transition-colors"
      >
        <Users className="w-4 h-4" />
        Team
      </TabsTrigger>
    </TabsList>
  );
};