
import { motion } from "framer-motion";
import { useState } from "react";

export const HeroBackground = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div 
      className="absolute inset-0 overflow-hidden w-full h-full"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Animated gradient orbs */}
      <motion.div 
        className={`absolute -top-40 -right-40 w-96 h-96 rounded-full blur-[100px] transition-all duration-700 ${
          isHovered ? "bg-brand-orange/30" : "bg-brand-orange/10"
        }`}
        animate={{
          scale: [1, 1.2, 1],
          rotate: [0, 90, 0],
          y: [0, -20, 0]
        }}
        transition={{
          duration: 20,
          repeat: Infinity,
          ease: "linear"
        }}
      />
      <motion.div 
        className={`absolute -bottom-40 -left-40 w-96 h-96 rounded-full blur-[100px] transition-all duration-700 ${
          isHovered ? "bg-brand-orange/20" : "bg-brand-orange/5"
        }`}
        animate={{
          scale: [1.2, 1, 1.2],
          rotate: [0, -90, 0],
          x: [0, 20, 0]
        }}
        transition={{
          duration: 20,
          repeat: Infinity,
          ease: "linear"
        }}
      />
      
      {/* Subtle floating particles */}
      <div className="absolute inset-0 w-full h-full">
        {Array.from({ length: 20 }).map((_, index) => (
          <motion.div
            key={index}
            className={`absolute w-1 h-1 rounded-full transition-all duration-700 ${
              isHovered ? "bg-white/30" : "bg-white/10"
            }`}
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
            }}
            animate={{
              y: [0, -30, 0],
              opacity: [0, isHovered ? 1 : 0.7, 0],
            }}
            transition={{
              duration: 5 + Math.random() * 5,
              repeat: Infinity,
              delay: Math.random() * 5,
            }}
          />
        ))}
      </div>
      
      {/* Subtle grid pattern */}
      <div className={`absolute inset-0 w-full h-full transition-all duration-700 ${
        isHovered ? "bg-[linear-gradient(to_right,#80808020_1px,transparent_1px),linear-gradient(to_bottom,#80808020_1px,transparent_1px)]" : 
                   "bg-[linear-gradient(to_right,#8080800A_1px,transparent_1px),linear-gradient(to_bottom,#8080800A_1px,transparent_1px)]"
      } bg-[size:32px_32px]`} />
      
      {/* Gradient overlay */}
      <div className={`absolute inset-0 w-full h-full transition-all duration-700 ${
        isHovered ? 
        "bg-gradient-to-b from-black/90 via-black/85 to-black/80" : 
        "bg-gradient-to-b from-black via-black/95 to-black/90"
      }`} />
    </div>
  );
};
