
import { useState } from "react";
import { motion } from "framer-motion";
import { 
  Card, 
  CardContent, 
  CardDescription, 
  CardHeader, 
  CardTitle 
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { 
  ArrowRight, 
  BarChart, 
  Calendar, 
  Code, 
  Database, 
  TrendingUp, 
  Users, 
  Zap, 
  Clock, 
  RefreshCw,
  MessageSquare,
  Loader2,
  CheckSquare,
  ArrowUpRight,
  BarChart4,
  Clock3,
  Server,
  Shield,
  Building,
  Download,
  FileText
} from "lucide-react";
import { IndustryTrendsCard } from "./IndustryTrendsCard";
import { toast } from "sonner";
import { Badge } from "@/components/ui/badge";
import { generatePDF } from "@/utils/pdfUtils";

interface AnalysisResultsProps {
  analysisResult: any;
  email: string;
  resetAnalysis: () => void;
}

export const AnalysisResults = ({ analysisResult, email, resetAnalysis }: AnalysisResultsProps) => {
  const [showBookingForm, setShowBookingForm] = useState(false);
  const [bookingName, setBookingName] = useState("");
  const [bookingEmail, setBookingEmail] = useState(email || "");
  const [bookingPhone, setBookingPhone] = useState("");
  const [isBookingSubmitting, setIsBookingSubmitting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadReport = async () => {
    setIsDownloading(true);
    try {
      await generatePDF(
        { 
          ...analysisResult,
          // Include additional contextual information for PDF generation
          businessChallenges: analysisResult.businessChallenges || "" 
        }, 
        `${analysisResult.industry}_Systems_Modernization_${new Date().toISOString().split('T')[0]}`
      );
    } catch (error) {
      console.error("Download error:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleBookConsultation = async () => {
    if (!bookingName || !bookingEmail) {
      toast.error("Please provide your name and email");
      return;
    }
    
    setIsBookingSubmitting(true);
    
    try {
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      toast.success("Consultation request received! Our enterprise modernization expert will contact you shortly.");
      setShowBookingForm(false);
    } catch (error) {
      console.error("Error booking consultation:", error);
      toast.error("Failed to book consultation. Please try again.");
    } finally {
      setIsBookingSubmitting(false);
    }
  };

  if (!analysisResult) return null;

  const systems = analysisResult.systems || [];
  const recommendations = analysisResult.recommendations || [];
  const aiOpportunities = analysisResult.aiOpportunities || [];
  const industry = analysisResult.industry || "Technology";
  const benchmarks = analysisResult.benchmarks || {};

  const getSystemIcon = (type: string) => {
    switch (type.toLowerCase()) {
      case 'crm':
        return <Users className="h-4 w-4" />;
      case 'hr management':
        return <Building className="h-4 w-4" />;
      case 'database':
        return <Database className="h-4 w-4" />;
      case 'productivity suite':
        return <BarChart className="h-4 w-4" />;
      default:
        return <Server className="h-4 w-4" />;
    }
  };
  
  // Derive some personalized insights based on selected systems
  const systemTypes = systems.map(s => s.type.toLowerCase());
  const hasCRM = systemTypes.some(t => t.includes('crm'));
  const hasERP = systemTypes.some(t => t.includes('erp'));
  const hasDatabase = systemTypes.some(t => t.includes('database'));
  
  // Generate a personalized insight message
  let insightMessage = "";
  if (hasCRM && hasERP) {
    insightMessage = "Your combination of CRM and ERP systems presents significant integration opportunities that could streamline your customer-facing operations.";
  } else if (hasCRM) {
    insightMessage = "Modernizing your CRM implementation could dramatically improve customer engagement and sales efficiency.";
  } else if (hasDatabase) {
    insightMessage = "Your database systems could benefit from modern cloud-native approaches to reduce operational costs and improve scalability.";
  } else if (systems.length > 2) {
    insightMessage = "The number of different systems in your stack suggests integration is likely a significant challenge for your organization.";
  }

  return (
    <div className="w-full space-y-16">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="flex items-center justify-between"
      >
        <div>
          <h2 className="text-3xl font-bold text-white flex items-center gap-3 mb-2">
            <Database className="w-7 h-7 text-blue-400" />
            Enterprise Modernization Blueprint
          </h2>
          <p className="text-gray-300 text-lg">
            Tailored analysis for your {industry} systems with specific modernization recommendations
          </p>
        </div>
        <Button variant="outline" onClick={resetAnalysis} className="gap-2 border-blue-800 text-blue-300 px-4 py-2">
          <RefreshCw className="w-4 h-4" />
          New Analysis
        </Button>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
      >
        <Card className="border-blue-900/30 shadow-lg overflow-hidden">
          <CardHeader className="bg-gradient-to-r from-blue-900/40 to-blue-900/20 border-b border-blue-900/30">
            <CardTitle className="text-2xl text-white flex items-center gap-3">
              <Server className="w-6 h-6 text-blue-400" />
              Systems Analysis
            </CardTitle>
            <CardDescription className="text-gray-300 mt-2">
              {systems.length > 0 
                ? `Analysis of ${systems.length} enterprise systems in the ${industry} industry` 
                : "Enterprise systems modernization analysis"}
            </CardDescription>
          </CardHeader>
          <CardContent className="pt-6 pb-6 bg-gradient-to-b from-blue-900/5 to-transparent">
            <div className="space-y-6">
              <div>
                <h3 className="text-white text-lg mb-3">Selected Systems</h3>
                <div className="flex flex-wrap gap-2">
                  {systems.map((system: any, index: number) => (
                    <Badge key={index} variant="outline" className="bg-blue-950/50 border-blue-800/50 text-blue-200 py-2 px-3 flex items-center gap-2">
                      {getSystemIcon(system.type)}
                      <span>{system.name}</span>
                      <span className="text-xs text-blue-400/80 ml-1">{system.type}</span>
                    </Badge>
                  ))}
                </div>
              </div>
              
              {insightMessage && (
                <div className="bg-amber-900/20 border border-amber-900/30 p-4 rounded-lg mt-4">
                  <h4 className="text-amber-400 font-medium mb-1 flex items-center gap-2">
                    <TrendingUp className="w-4 h-4" />
                    Key Insight
                  </h4>
                  <p className="text-gray-300">{insightMessage}</p>
                </div>
              )}
              
              {Object.keys(benchmarks).length > 0 && (
                <div className="pt-4">
                  <h3 className="text-white text-lg mb-3">Industry Benchmarks for {industry}</h3>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                    {Object.entries(benchmarks).map(([key, value]: [string, any], idx: number) => (
                      <div key={idx} className="bg-blue-950/30 border border-blue-900/40 rounded-lg p-3">
                        <h4 className="text-blue-300 text-sm capitalize mb-1">{key.replace(/([A-Z])/g, ' $1').trim()}</h4>
                        <p className="text-white font-medium">{value}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </motion.div>

      <div className="max-w-4xl space-y-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <Card className="border-blue-900/30 shadow-lg overflow-hidden">
            <CardHeader className="bg-gradient-to-r from-blue-900/40 to-blue-900/20 border-b border-blue-900/30 pb-6">
              <CardTitle className="text-2xl text-white flex items-center gap-3">
                <Zap className="w-6 h-6 text-amber-400" />
                Modernization Recommendations
              </CardTitle>
              <CardDescription className="text-gray-300 mt-3 text-lg">
                High-impact modernization opportunities based on your specific systems
              </CardDescription>
            </CardHeader>
            <CardContent className="pt-8 pb-8 bg-gradient-to-b from-blue-900/5 to-transparent">
              <div className="space-y-12">
                {recommendations.map((recommendation: any, index: number) => (
                  <div key={index} className="border-l-4 border-blue-600 pl-6 py-1 space-y-4">
                    <div className="flex justify-between items-start">
                      <h4 className="text-white font-medium text-xl">{recommendation.title}</h4>
                      <span className={`px-3 py-1 rounded-full text-xs font-medium ${
                        recommendation.complexity === "Low" 
                          ? "bg-green-900/20 text-green-400 border border-green-700/30" 
                          : recommendation.complexity === "Medium"
                            ? "bg-yellow-900/20 text-yellow-400 border border-yellow-700/30"
                            : "bg-red-900/20 text-red-400 border border-red-700/30"
                      }`}>
                        {recommendation.complexity} Complexity
                      </span>
                    </div>
                    
                    <div className="bg-blue-950/30 border border-blue-900/40 rounded-lg p-4 space-y-3">
                      <div className="flex items-start gap-2">
                        <div className="bg-red-900/30 text-red-400 p-1 rounded mt-0.5">
                          <BarChart4 className="w-4 h-4" />
                        </div>
                        <div>
                          <h5 className="text-red-400 font-medium text-sm mb-1">Challenge</h5>
                          <p className="text-gray-300">{recommendation.challenge}</p>
                        </div>
                      </div>
                      
                      <div className="flex items-start gap-2">
                        <div className="bg-green-900/30 text-green-400 p-1 rounded mt-0.5">
                          <CheckSquare className="w-4 h-4" />
                        </div>
                        <div>
                          <h5 className="text-green-400 font-medium text-sm mb-1">Solution</h5>
                          <p className="text-gray-300">{recommendation.solution}</p>
                        </div>
                      </div>
                      
                      <div className="flex items-start gap-2">
                        <div className="bg-blue-900/30 text-blue-400 p-1 rounded mt-0.5">
                          <TrendingUp className="w-4 h-4" />
                        </div>
                        <div>
                          <h5 className="text-blue-400 font-medium text-sm mb-1">Impact</h5>
                          <ul className="list-disc list-inside text-gray-300 space-y-1">
                            {recommendation.impact?.metrics?.map((metric: string, i: number) => (
                              <li key={i}>{metric}</li>
                            ))}
                          </ul>
                          <div className="flex flex-wrap gap-4 mt-2 text-sm">
                            <span className="flex items-center gap-1">
                              <Clock3 className="w-3.5 h-3.5 text-amber-400" />
                              <span className="text-amber-300">
                                Timeframe: {recommendation.impact?.timeframe || "3-6 months"}
                              </span>
                            </span>
                            <span className="flex items-center gap-1">
                              <ArrowUpRight className="w-3.5 h-3.5 text-green-400" />
                              <span className="text-green-300">
                                {recommendation.impact?.roi || "250% ROI over 2 years"}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    {recommendation.caseStudy && (
                      <div className="bg-purple-900/20 border border-purple-900/30 p-4 rounded-lg">
                        <h5 className="text-purple-300 font-medium mb-2 flex items-center gap-2">
                          <Users className="w-4 h-4" />
                          Success Story
                        </h5>
                        <p className="text-gray-300">
                          <span className="font-medium">{recommendation.caseStudy.company}</span> {recommendation.caseStudy.outcome}
                        </p>
                        <p className="text-xs text-gray-400 mt-2">
                          Source: {recommendation.caseStudy.source}
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
        >
          <Card className="border-blue-900/30 shadow-lg overflow-hidden">
            <CardHeader className="bg-gradient-to-r from-purple-900/40 to-blue-900/20 border-b border-blue-900/30 pb-6">
              <CardTitle className="text-2xl text-white flex items-center gap-3">
                <Code className="w-6 h-6 text-purple-400" />
                AI Integration Opportunities
              </CardTitle>
              <CardDescription className="text-gray-300 mt-3 text-lg">
                AI solutions that deliver measurable ROI and competitive advantages
              </CardDescription>
            </CardHeader>
            <CardContent className="pt-8 pb-8 bg-gradient-to-b from-purple-900/5 to-transparent">
              <div className="space-y-10">
                {aiOpportunities.map((opportunity: any, index: number) => (
                  <div key={index} className="border-l-4 border-purple-600 pl-6 py-2">
                    <div className="flex justify-between items-start mb-4">
                      <h4 className="text-white font-medium text-xl">{opportunity.title}</h4>
                      <span className="bg-purple-900/20 text-purple-300 px-3 py-1 rounded-full text-xs font-medium border border-purple-700/30">
                        {opportunity.technology}
                      </span>
                    </div>
                    
                    <p className="text-gray-300 text-lg leading-relaxed mb-6">{opportunity.description}</p>
                    
                    <div className="bg-purple-900/20 border border-purple-900/30 p-4 rounded-md">
                      <h5 className="text-purple-300 font-medium mb-2">
                        {industry} Company Impact Example
                      </h5>
                      <p className="text-gray-300">
                        "After implementing this solution, we saw a {opportunity.productivityGain.split('-')[0]}% reduction in manual work. 
                        Our team now focuses on strategic initiatives instead of repetitive tasks, and we've improved customer 
                        response times by 40%."
                      </p>
                      <p className="text-sm text-gray-400 mt-2">— {industry} Technology Director</p>
                    </div>
                  </div>
                ))}
              </div>
              
              <div className="mt-10 p-6 border border-amber-900/30 bg-amber-900/10 rounded-md">
                <h4 className="text-amber-400 font-medium text-lg flex items-center gap-2 mb-3">
                  <Clock className="w-5 h-5" />
                  Decision-Making Timeline
                </h4>
                <p className="text-gray-300 text-lg leading-relaxed">
                  Organizations in the {industry} sector that implement these AI solutions typically see ROI within 6-9 months. 
                  Early adopters gain significant competitive advantages in operational efficiency and customer experience.
                </p>
              </div>
            </CardContent>
          </Card>
        </motion.div>
        
        <div className="space-y-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
          >
            <IndustryTrendsCard industry={industry} />
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
            className="mt-20"
          >
            <div className="bg-gradient-to-br from-blue-900/40 via-blue-900/20 to-purple-900/30 rounded-xl p-8 border border-blue-900/30 shadow-lg space-y-8">
              <div className="flex flex-col md:flex-row gap-6 items-center justify-between">
                <div>
                  <h3 className="text-2xl text-white font-semibold mb-3">Take the Next Step</h3>
                  <p className="text-gray-300 text-lg">
                    Download your complete analysis report or schedule a free consultation with our enterprise modernization experts.
                  </p>
                </div>
                
                <div className="flex flex-col sm:flex-row gap-4">
                  <Button 
                    onClick={handleDownloadReport}
                    disabled={isDownloading} 
                    className="gap-2 bg-green-700 hover:bg-green-800 text-white px-6 py-6"
                  >
                    {isDownloading ? (
                      <>
                        <Loader2 className="w-5 h-5 animate-spin" />
                        Preparing Report...
                      </>
                    ) : (
                      <>
                        <FileText className="w-5 h-5" />
                        Download Full Report
                      </>
                    )}
                  </Button>
                  
                  <Button 
                    onClick={() => setShowBookingForm(true)}
                    className="gap-2 bg-blue-600 hover:bg-blue-700 text-white px-6 py-6"
                  >
                    <Calendar className="w-5 h-5" />
                    Book Free Consultation
                  </Button>
                </div>
              </div>
              
              <div className="pt-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                <div className="flex items-center gap-2 bg-blue-950/40 p-3 rounded-lg">
                  <CheckSquare className="text-green-400 w-5 h-5" />
                  <span className="text-gray-300">Tailored modernization roadmap</span>
                </div>
                <div className="flex items-center gap-2 bg-blue-950/40 p-3 rounded-lg">
                  <CheckSquare className="text-green-400 w-5 h-5" />
                  <span className="text-gray-300">Budget optimization strategies</span>
                </div>
                <div className="flex items-center gap-2 bg-blue-950/40 p-3 rounded-lg">
                  <CheckSquare className="text-green-400 w-5 h-5" />
                  <span className="text-gray-300">Integration expertise</span>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      {showBookingForm && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="fixed inset-0 bg-black/70 backdrop-blur-sm z-50 flex items-center justify-center p-6"
          onClick={() => setShowBookingForm(false)}
        >
          <div 
            className="bg-gray-900 border border-blue-900/50 rounded-lg max-w-md w-full p-8"
            onClick={e => e.stopPropagation()}
          >
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-2xl font-semibold text-white">Book Expert Consultation</h3>
              <button 
                onClick={() => setShowBookingForm(false)}
                className="text-gray-400 hover:text-white text-xl"
              >
                &times;
              </button>
            </div>
            
            <div className="space-y-6">
              <div>
                <label htmlFor="name" className="text-sm text-gray-300 block mb-2">Full Name</label>
                <input
                  id="name"
                  type="text"
                  value={bookingName}
                  onChange={(e) => setBookingName(e.target.value)}
                  className="w-full p-3 rounded bg-gray-800 border border-gray-700 text-white"
                  placeholder="Your full name"
                />
              </div>
              
              <div>
                <label htmlFor="email" className="text-sm text-gray-300 block mb-2">Email Address</label>
                <input
                  id="email"
                  type="email"
                  value={bookingEmail}
                  onChange={(e) => setBookingEmail(e.target.value)}
                  className="w-full p-3 rounded bg-gray-800 border border-gray-700 text-white"
                  placeholder="your@email.com"
                />
              </div>
              
              <div>
                <label htmlFor="phone" className="text-sm text-gray-300 block mb-2">Phone Number (optional)</label>
                <input
                  id="phone"
                  type="tel"
                  value={bookingPhone}
                  onChange={(e) => setBookingPhone(e.target.value)}
                  className="w-full p-3 rounded bg-gray-800 border border-gray-700 text-white"
                  placeholder="Your phone number"
                />
              </div>
              
              <div className="pt-4">
                <Button 
                  onClick={handleBookConsultation}
                  className="w-full bg-blue-600 hover:bg-blue-700 text-white gap-2 py-6 text-lg"
                  disabled={isBookingSubmitting}
                >
                  {isBookingSubmitting ? (
                    <>
                      <Loader2 className="w-5 h-5 animate-spin" />
                      Booking Consultation...
                    </>
                  ) : (
                    <>
                      <MessageSquare className="w-5 h-5" />
                      Book My Free Consultation
                    </>
                  )}
                </Button>
              </div>
              
              <p className="text-sm text-gray-300 text-center mt-3">
                One of our enterprise modernization experts will contact you within 1 business day to schedule your consultation.
              </p>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
};
