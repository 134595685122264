
import { CaseStudies } from "@/components/CaseStudies";
import { Header } from "@/components/Header";
import { Footer } from "@/components/Footer";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";

const WorkPage = () => {
  return (
    <main className="min-h-screen bg-[#1f1f1f] text-white overflow-hidden">
      <Helmet>
        <title>Our Work - Unstuck Labs</title>
        <meta name="description" content="Explore our portfolio of successful projects and digital transformations." />
      </Helmet>
      <Header />
      
      {/* Hero section */}
      <section className="pt-32 pb-16 relative bg-gradient-to-br from-[#1A1F2C] via-[#221F26] to-[#1A1F2C]">
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1557683316-973673baf926?auto=format&fit=crop&w=2000')] opacity-10 bg-cover bg-center mix-blend-overlay" />
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="container relative z-10"
        >
          <div className="max-w-3xl mx-auto text-center">
            <motion.h1 
              initial={{ opacity: 0, y: -10 }} 
              animate={{ opacity: 1, y: 0 }} 
              transition={{ delay: 0.2, duration: 0.7 }}
              className="text-5xl md:text-7xl font-display font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-secondary to-brand-pink"
            >
              Our Work
            </motion.h1>
            <motion.p 
              initial={{ opacity: 0 }} 
              animate={{ opacity: 1 }} 
              transition={{ delay: 0.4, duration: 0.7 }}
              className="text-xl text-white/70 mb-8"
            >
              Explore our portfolio of innovative solutions that have helped startups, enterprises, 
              and government agencies transform their digital presence and achieve their business goals.
            </motion.p>
          </div>
        </motion.div>
      </section>
      
      <div className="pt-0 bg-[#1f1f1f]">
        <CaseStudies />
      </div>
      <Footer />
    </main>
  );
};

export default WorkPage;
