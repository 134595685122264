
import { useCallback, useEffect, useState } from "react";
import Particles from "@tsparticles/react";
import type { Container } from "@tsparticles/engine";
import { loadSlim } from "@tsparticles/slim";
import { tsParticles } from "@tsparticles/engine";

export const ParticleBackground = () => {
  const [initialized, setInitialized] = useState(false);
  
  useEffect(() => {
    const initParticles = async () => {
      try {
        // Initialize the engine
        await loadSlim(tsParticles);
        setInitialized(true);
      } catch (error) {
        console.error("Error initializing particles:", error);
      }
    };
    
    initParticles();
  }, []);
  
  // This function handles the container after particles are loaded
  const particlesLoaded = useCallback(async (container?: Container) => {
    // You can access the container instance here if needed
  }, []);

  return (
    <>
      {initialized && (
        <Particles
          id="tsparticles"
          particlesLoaded={particlesLoaded}
          options={{
            background: {
              color: {
                value: "#000000",
              },
            },
            fpsLimit: 60,
            particles: {
              color: {
                value: "#FF4D00",
              },
              links: {
                color: "#FF4D00",
                distance: 150,
                enable: true,
                opacity: 0.15,
                width: 1,
              },
              move: {
                direction: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: false,
                speed: 0.5,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                },
                value: 60,
              },
              opacity: {
                value: 0.3,
              },
              size: {
                value: { min: 1, max: 3 },
              },
            },
            detectRetina: true,
          }}
          className="absolute inset-0 z-0"
        />
      )}
    </>
  );
};
