import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { format } from "date-fns";

interface DealListProps {
  deals: any[];
}

export const DealList = ({ deals }: DealListProps) => {
  const navigate = useNavigate();

  const getStageColor = (stage: string) => {
    switch (stage) {
      case 'won':
        return 'bg-green-500/10 text-green-500';
      case 'lost':
        return 'bg-red-500/10 text-red-500';
      default:
        return 'bg-blue-500/10 text-blue-500';
    }
  };

  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Deal Name</TableHead>
            <TableHead>Stage</TableHead>
            <TableHead>Value</TableHead>
            <TableHead>Company</TableHead>
            <TableHead>Expected Close</TableHead>
            <TableHead>Probability</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {deals.map((deal) => (
            <TableRow
              key={deal.id}
              className="cursor-pointer hover:bg-muted/50"
              onClick={() => navigate(`/admin/deals/${deal.id}`)}
            >
              <TableCell className="font-medium">{deal.name}</TableCell>
              <TableCell>
                <Badge className={getStageColor(deal.stage)} variant="secondary">
                  {deal.stage.charAt(0).toUpperCase() + deal.stage.slice(1)}
                </Badge>
              </TableCell>
              <TableCell>${deal.value?.toLocaleString() || 0}</TableCell>
              <TableCell>{deal.leads?.company || 'N/A'}</TableCell>
              <TableCell>
                {deal.expected_close_date
                  ? format(new Date(deal.expected_close_date), 'MMM d, yyyy')
                  : 'Not set'}
              </TableCell>
              <TableCell>
                {deal.deal_analytics?.[0]?.probability_score
                  ? `${Math.round(deal.deal_analytics[0].probability_score * 100)}%`
                  : 'N/A'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};