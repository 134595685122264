
"use client";
import React, { useRef, useState } from "react";
import { motion } from "framer-motion";

export const TextHoverEffect = ({
  text,
  subText,
  duration = 0,
  className,
  textSize = "100%",
  subTextSize = "24px",
}: {
  text: string;
  subText?: string;
  duration?: number;
  automatic?: boolean;
  className?: string;
  textSize?: string;
  subTextSize?: string;
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [cursor, setCursor] = useState({ x: 0, y: 0 });
  const [hovered, setHovered] = useState(false);
  const [maskPosition, setMaskPosition] = useState({ cx: "50%", cy: "50%" });

  // Optimize performance by using passive event listeners and debounced cursor updates
  const handleMouseMove = (e: React.MouseEvent<SVGSVGElement>) => {
    if (!svgRef.current) return;
    
    // Get cursor position relative to SVG
    const svgRect = svgRef.current.getBoundingClientRect();
    const cxPercentage = ((e.clientX - svgRect.left) / svgRect.width) * 100;
    const cyPercentage = ((e.clientY - svgRect.top) / svgRect.height) * 100;
    
    setCursor({ x: e.clientX, y: e.clientY });
    setMaskPosition({
      cx: `${cxPercentage}%`,
      cy: `${cyPercentage}%`,
    });
  };

  return (
    <svg
      ref={svgRef}
      width="100%"
      height="100%"
      viewBox="0 0 1200 600"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onMouseMove={handleMouseMove}
      className={`select-none ${className || ""}`}
      style={{ willChange: "transform" }} // Hint to browser for optimized rendering
    >
      <defs>
        <linearGradient
          id="textGradient"
          gradientUnits="userSpaceOnUse"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="100%"
        >
          <stop offset="0%" stopColor="rgb(234 179 8)" />
          <stop offset="25%" stopColor="rgb(239 68 68)" />
          <stop offset="50%" stopColor="rgb(59 130 246)" />
          <stop offset="75%" stopColor="rgb(6 182 212)" />
          <stop offset="100%" stopColor="rgb(139 92 246)" />
        </linearGradient>

        <motion.radialGradient
          id="revealMask"
          gradientUnits="userSpaceOnUse"
          r="30%"
          animate={maskPosition}
          transition={{ duration, ease: "easeOut" }}
        >
          <stop offset="0%" stopColor="white" />
          <stop offset="100%" stopColor="black" />
        </motion.radialGradient>
        <mask id="textMask">
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            fill="url(#revealMask)"
          />
        </mask>
      </defs>
      
      {/* Main text - base layer only */}
      <text
        x="50%"
        y="45%"
        textAnchor="middle"
        dominantBaseline="middle"
        className="font-display font-bold fill-white text-7xl"
        style={{ fontSize: textSize }}
      >
        {text}
      </text>
      
      {/* Color gradient effect only when hovered */}
      {hovered && (
        <text
          x="50%"
          y="45%"
          textAnchor="middle"
          dominantBaseline="middle"
          fill="url(#textGradient)"
          mask="url(#textMask)"
          className="font-display font-bold text-7xl"
          style={{ fontSize: textSize }}
        >
          {text}
        </text>
      )}
      
      {/* Subtext - only render if provided */}
      {subText && (
        <>
          <text
            x="50%"
            y="60%"
            textAnchor="middle"
            dominantBaseline="middle"
            className="font-display font-medium fill-white"
            style={{ fontSize: subTextSize }}
          >
            {subText}
          </text>
          
          {/* Gradient effect for subtext - only when hovered */}
          {hovered && (
            <text
              x="50%"
              y="60%"
              textAnchor="middle"
              dominantBaseline="middle"
              fill="url(#textGradient)"
              mask="url(#textMask)"
              className="font-display font-medium"
              style={{ fontSize: subTextSize }}
            >
              {subText}
            </text>
          )}
        </>
      )}
    </svg>
  );
};
