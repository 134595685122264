import { Drawer, DrawerContent, DrawerHeader, DrawerTitle } from "@/components/ui/drawer";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import { 
  Shield, Search, Layout, Database, Server, 
  MessageSquare, Settings, Zap, Clock, ArrowDown,
  Check, LayoutGrid, List
} from 'lucide-react';
import { featureGroups } from '../data/featureGroups';
import { FeatureGroupCard } from './FeatureGroupCard';
import { cn } from "@/lib/utils";
import { toast } from "sonner";
import { Node } from "@xyflow/react";

interface PredefinedFeaturesProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onFeatureAdd: (feature: { name: string; description: string; priority: 'high' | 'medium' | 'low' }) => void;
  nodes: Node[];
}

const categories = [
  { id: 'all', label: 'All Features', icon: LayoutGrid },
  { id: 'auth', label: 'Authentication', icon: Shield },
  { id: 'data', label: 'Data & Storage', icon: Database },
  { id: 'ui', label: 'UI/UX', icon: Layout },
  { id: 'integration', label: 'Integration', icon: Server },
  { id: 'analytics', label: 'Analytics', icon: Settings },
  { id: 'core', label: 'Core Features', icon: MessageSquare }
];

const priorityConfig = {
  high: {
    color: 'text-red-500',
    icon: Zap,
    timeline: '1-2 days',
    badge: 'bg-red-500/10'
  },
  medium: {
    color: 'text-amber-500',
    icon: Clock,
    timeline: '2-4 days',
    badge: 'bg-amber-500/10'
  },
  low: {
    color: 'text-blue-500',
    icon: ArrowDown,
    timeline: '4-7 days',
    badge: 'bg-blue-500/10'
  }
};

export function PredefinedFeatures({ open, onOpenChange, onFeatureAdd, nodes }: PredefinedFeaturesProps) {
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [viewMode, setViewMode] = useState<'groups' | 'individual'>('groups');
  const [addedFeatures, setAddedFeatures] = useState<Set<string>>(new Set());

  useEffect(() => {
    if (nodes && Array.isArray(nodes)) {
      const existingFeatures = new Set(
        nodes
          .filter(node => node.type === 'feature' && node.data?.label)
          .map(node => node.data.label as string)
      );
      setAddedFeatures(existingFeatures);
    }
  }, [nodes]);

  const handleFeatureAdd = async (feature: any) => {
    try {
      onFeatureAdd(feature);
      setAddedFeatures(prev => new Set([...prev, feature.name]));
      toast.success(`Added ${feature.name}`);
    } catch (error) {
      console.error('Error adding feature:', error);
      toast.error('Failed to add feature');
    }
  };

  const getAllFeatures = () => {
    return featureGroups.flatMap(group => 
      group.features.map(feature => ({
        ...feature,
        category: group.category
      }))
    );
  };

  const filteredFeatures = getAllFeatures().filter(feature => 
    (selectedCategory === 'all' || feature.category === selectedCategory) &&
    (searchQuery === '' || 
     feature.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
     feature.description.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerContent className="max-h-[95vh]">
        <DrawerHeader>
          <DrawerTitle className="text-2xl font-display mb-6">Feature Hub</DrawerTitle>
          
          <div className="relative mb-6">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <Input
              placeholder="Search features..."
              className="pl-10 h-12 text-lg"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          <div className="flex items-center justify-between mb-4">
            <div className="flex gap-2">
              <Button
                variant={viewMode === 'groups' ? 'default' : 'outline'}
                onClick={() => setViewMode('groups')}
                className={cn(
                  "flex items-center gap-2",
                  viewMode === 'groups' && "border-2 border-secondary"
                )}
              >
                <LayoutGrid className="h-4 w-4" />
                Groups
              </Button>
              <Button
                variant={viewMode === 'individual' ? 'default' : 'outline'}
                onClick={() => setViewMode('individual')}
                className={cn(
                  "flex items-center gap-2",
                  viewMode === 'individual' && "border-2 border-secondary"
                )}
              >
                <List className="h-4 w-4" />
                Individual
              </Button>
            </div>
          </div>

          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 mb-6">
            {categories.map((category) => {
              const IconComponent = category.icon;
              return (
                <Button
                  key={category.id}
                  variant={selectedCategory === category.id ? "default" : "outline"}
                  className={cn(
                    "h-20 flex flex-col items-center justify-center gap-2 p-2",
                    selectedCategory === category.id && "bg-secondary/20 border-secondary",
                    "hover:scale-[1.02] transition-all duration-200"
                  )}
                  onClick={() => setSelectedCategory(category.id)}
                >
                  <IconComponent className="h-6 w-6" />
                  <span className="text-sm text-center">{category.label}</span>
                </Button>
              );
            })}
          </div>
        </DrawerHeader>

        <ScrollArea className="h-[calc(95vh-240px)] px-4">
          {viewMode === 'groups' ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pb-8">
              {featureGroups
                .filter(group => 
                  selectedCategory === 'all' || 
                  group.category === selectedCategory
                )
                .filter(group =>
                  searchQuery === '' ||
                  group.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                  group.description.toLowerCase().includes(searchQuery.toLowerCase())
                )
                .map((group) => (
                  <FeatureGroupCard
                    key={group.id}
                    group={group}
                    onFeatureAdd={handleFeatureAdd}
                    addedFeatures={addedFeatures}
                  />
                ))}
            </div>
          ) : (
            <div className="grid grid-cols-1 gap-4 pb-8">
              {filteredFeatures.map((feature, index) => {
                const isAdded = addedFeatures.has(feature.name);
                const PriorityIcon = priorityConfig[feature.priority].icon;
                return (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: index * 0.05 }}
                  >
                    <div className={cn(
                      "p-4 rounded-lg border",
                      isAdded ? "bg-secondary/10" : "bg-card",
                      "hover:border-primary/50 transition-all duration-200"
                    )}>
                      <div className="flex items-center justify-between">
                        <div className="flex-1">
                          <div className="flex items-center gap-2">
                            <h3 className="font-medium">{feature.name}</h3>
                            {isAdded && (
                              <Badge variant="secondary" className="ml-2">
                                <Check className="h-3 w-3 mr-1" />
                                Added
                              </Badge>
                            )}
                          </div>
                          <p className="text-sm text-muted-foreground mt-1">{feature.description}</p>
                        </div>
                        <div className="flex items-center gap-4">
                          <Badge 
                            variant="outline" 
                            className={cn(
                              "flex items-center gap-1",
                              priorityConfig[feature.priority].color,
                              priorityConfig[feature.priority].badge
                            )}
                          >
                            <PriorityIcon className="h-3 w-3" />
                            {feature.priority}
                          </Badge>
                          {!isAdded && (
                            <Button
                              size="sm"
                              onClick={() => handleFeatureAdd(feature)}
                            >
                              Add Feature
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </motion.div>
                );
              })}
            </div>
          )}
        </ScrollArea>
      </DrawerContent>
    </Drawer>
  );
}
