
import { motion } from "framer-motion";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Database } from "lucide-react";
import { EnterpriseServiceCards } from "../enterprise/EnterpriseServiceCards";
import { EnterpriseMetrics } from "../enterprise/EnterpriseMetrics";

interface EnterpriseHeroProps {
  locationName?: string;
  returningVisitor: boolean;
}

export const EnterpriseHero = ({ locationName, returningVisitor }: EnterpriseHeroProps) => {
  const { data: insights, isLoading } = useQuery({
    queryKey: ['enterprise-insights'],
    queryFn: async () => {
      const { data: existingData, error } = await supabase
        .from('segment_statistics')
        .select('metric_name, metric_value')
        .eq('segment', 'enterprise');

      if (error) throw error;

      if (!existingData || existingData.length < 4) {
        const response = await supabase.functions.invoke('generate-segment-insights', {
          body: { segment: 'enterprise' }
        });
        
        if (response.error) throw response.error;
        return response.data;
      }

      const transformedData = existingData.reduce((acc, curr) => {
        if (curr.metric_name === 'headline') acc.headline = curr.metric_value;
        else if (curr.metric_name === 'subheading') acc.subheading = curr.metric_value;
        else {
          if (!acc.metrics) acc.metrics = {};
          try {
            acc.metrics[curr.metric_name] = JSON.parse(curr.metric_value);
          } catch (e) {
            console.error('Error parsing metric value:', e);
          }
        }
        return acc;
      }, {} as any);

      return transformedData;
    },
    staleTime: 1000 * 60 * 60,
  });

  const getHeadingMessage = () => {
    if (isLoading) return "Modernize Legacy Systems & Centralize Information";
    if (!insights?.headline) return "Modernize Legacy Systems & Centralize Information";
    
    if (locationName) {
      return `${insights.headline} in ${locationName}`;
    }
    return insights.headline;
  };

  const getSubheading = () => {
    if (isLoading) return "Transform disparate systems into a unified, AI-augmented platform. Gain complete control while reducing complexity and increasing efficiency.";
    if (!insights?.subheading) return "Transform disparate systems into a unified, AI-augmented platform. Gain complete control while reducing complexity and increasing efficiency.";
    
    return insights.subheading;
  };

  return (
    <div className="space-y-8">
      <div className="space-y-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="inline-flex items-center px-4 py-2 bg-blue-900/30 text-blue-300 rounded-full text-sm font-medium"
        >
          <Database className="w-4 h-4 mr-2" />
          Enterprise System Modernization
        </motion.div>

        <motion.h1
          className="text-4xl md:text-6xl font-display font-bold tracking-tight leading-[1.1] text-white"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          {getHeadingMessage()}
        </motion.h1>

        <motion.p
          className="text-xl md:text-2xl text-gray-300 max-w-3xl"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.5 }}
        >
          {getSubheading()}
        </motion.p>

        <EnterpriseServiceCards />

        {insights?.metrics && <EnterpriseMetrics metrics={insights.metrics} />}
      </div>
    </div>
  );
};
