import { Header } from "@/components/Header";
import { Footer } from "@/components/Footer";
import { PitchForm } from "@/components/ventures/PitchForm";

const VenturesApplyPage = () => {
  return (
    <div className="min-h-screen bg-background">
      <Header />
      <main className="pt-32 pb-24">
        <PitchForm />
      </main>
      <Footer />
    </div>
  );
};

export default VenturesApplyPage;