
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";

export const ServicesCTA = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  
  const handleContactClick = () => {
    if (isHomePage) {
      document.querySelector('#madlib-section')?.scrollIntoView({ behavior: 'smooth' });
    } else {
      navigate('/#madlib-section');
    }
  };
  
  return (
    <section className="py-24 text-white relative overflow-hidden">
      <div className="container mx-auto px-4 text-center relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="max-w-3xl mx-auto backdrop-blur-md bg-gradient-to-br from-black/80 to-black/60 p-10 rounded-xl border border-white/5 hover:border-white/10 transition-all shadow-xl"
        >
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.1 }}
          >
            <h2 className="text-3xl md:text-4xl font-display font-bold mb-6 bg-gradient-to-r from-brand-orange via-white to-brand-orange bg-clip-text text-transparent">
              Ready to Transform Your Business?
            </h2>
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <p className="text-lg mb-8 text-gray-300">
              Let's discuss how we can help you achieve your digital goals and create 
              lasting impact through innovative solutions.
            </p>
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.3 }}
          >
            <Button 
              className="bg-gradient-to-r from-brand-orange/90 to-brand-orange hover:from-brand-orange hover:to-brand-orange/90 text-white px-8 py-6 text-lg font-mono rounded-full group"
              onClick={handleContactClick}
            >
              Schedule a Consultation <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
            </Button>
          </motion.div>
        </motion.div>
      </div>
      
      {/* Background elements - subtle and not distracting */}
      <motion.div 
        className="absolute -bottom-40 left-0 right-0 h-80 bg-gradient-to-t from-brand-orange/5 to-transparent blur-3xl opacity-30"
        animate={{ 
          opacity: [0.2, 0.3, 0.2],
        }}
        transition={{ 
          duration: 8, 
          repeat: Infinity,
          repeatType: "reverse" 
        }}
      />
    </section>
  );
};
