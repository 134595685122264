import { useState, useCallback, useEffect } from 'react';
import { Panel, useReactFlow, ReactFlow, Connection, Edge, Node, addEdge } from '@xyflow/react';
import { ReactFlowProvider } from '@xyflow/react';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import { Loading } from '@/components/ui/loading';
import { AddFeatureDialog } from './components/AddFeatureDialog';
import { PredefinedFeatures } from './components/PredefinedFeatures';
import { WelcomeScreen } from './components/WelcomeScreen';
import { ScreensDrawer } from './components/ScreensDrawer';
import { Feature, Screen, ScreenType } from './types';
import { FlowView, FocusView, WireframeView, CodeView } from './views';
import { Plus, Library, GitBranch, Target, LayoutGrid, User, Key, Trash2, Smartphone, Save, Zap, ArrowDown, Wand2, LayoutTemplate, Code } from 'lucide-react';
import { useDesignState } from './hooks/useDesignState';
import { v4 as uuidv4 } from 'uuid';
import { AddItemMenu } from './components/AddItemMenu';
import { AddScreenDialog } from './components/AddScreenDialog';
import { AIImportDialog } from './components/AIImportDialog';

interface FlareDesignCanvasProps {
  designId?: string;
}

const initialStages = [
  { 
    id: 'alpha', 
    type: 'stage',
    data: { 
      label: 'Alpha', 
      color: '#1EAEDB',
      features: [] 
    }, 
    position: { x: 200, y: 200 } 
  },
  { 
    id: 'beta', 
    type: 'stage',
    data: { 
      label: 'Beta', 
      color: '#F97316',
      features: [] 
    }, 
    position: { x: 600, y: 200 } 
  },
  { 
    id: 'launch', 
    type: 'stage',
    data: { 
      label: 'Launch', 
      color: '#8B5CF6',
      features: [] 
    }, 
    position: { x: 1000, y: 200 } 
  },
];

const FlowCanvas = ({ designId }: FlareDesignCanvasProps) => {
  const {
    nodes,
    edges,
    setNodes,
    setEdges,
    onNodesChange,
    onEdgesChange,
    isLoading,
    setIsLoading,
    showPredefined,
    setShowPredefined,
    currentView,
    setCurrentView,
    showWelcome,
    setShowWelcome,
    accessCode,
    setAccessCode,
    clientName,
    setClientName,
    clientCompany,
    setClientCompany,
  } = useDesignState(designId);

  const [showAddFeature, setShowAddFeature] = useState(false);
  const [showScreens, setShowScreens] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showAddScreen, setShowAddScreen] = useState(false);
  const [showAIImport, setShowAIImport] = useState(false);
  const reactFlowInstance = useReactFlow();

  const onConnect = useCallback((params: Connection) => {
    setEdges(eds => addEdge(params, eds));
  }, [setEdges]);

  const handleAddFeature = (feature: { name: string; description: string; priority: 'high' | 'medium' | 'low'; points?: number }) => {
    const newNode: Node = {
      id: uuidv4(),
      type: 'feature',
      position: {
        x: Math.random() * 500,
        y: Math.random() * 500,
      },
      data: {
        label: feature.name,
        description: feature.description,
        priority: feature.priority,
        points: feature.points
      }
    };
    setNodes(nodes => [...nodes, newNode]);
    setShowAddFeature(false);
    setShowPredefined(false);
  };

  const handleSave = async () => {
    if (!designId) return;
    
    setIsSaving(true);
    try {
      const featureNodes = nodes.filter(node => node.type === 'feature');
      const stageNodes = nodes.filter(node => node.type === 'stage');
      
      const features = {};
      featureNodes.forEach(node => {
        features[node.id] = {
          id: node.id,
          type: 'feature',
          position: node.position,
          data: node.data
        };
      });

      const stages: Record<string, string[]> = {
        alpha: [],
        beta: [],
        launch: []
      };

      edges.forEach(edge => {
        const stageId = edge.source;
        const featureId = edge.target;
        if (stageNodes.find(n => n.id === stageId) && featureNodes.find(n => n.id === featureId)) {
          stages[stageId].push(featureId);
        }
      });

      const { error } = await supabase
        .from('flare_designs')
        .update({
          features,
          stages,
          client_name: clientName,
          client_company: clientCompany,
          updated_at: new Date().toISOString()
        })
        .eq('id', designId);

      if (error) throw error;
      toast.success('Design saved successfully');
    } catch (error) {
      console.error('Error saving design:', error);
      toast.error('Failed to save design');
    } finally {
      setIsSaving(false);
    }
  };

  const handleWelcomeContinue = (name: string, company: string) => {
    setClientName(name);
    setClientCompany(company);
    setShowWelcome(false);
  };

  const handleCustomFeatureClick = () => {
    setShowAddFeature(true);
  };

  const handlePredefinedFeatureClick = () => {
    setShowPredefined(true);
  };

  const handleDeleteSelected = () => {
    setNodes(nodes => nodes.filter(node => !node.selected));
    setEdges(edges => edges.filter(edge => !edge.selected));
  };

  const handleAutoLayout = () => {
    const spacing = 200;
    const nodesPerRow = 3;
    
    setNodes(nodes => nodes.map((node, index) => {
      if (node.type === 'stage') return node; 
      
      const row = Math.floor(index / nodesPerRow);
      const col = index % nodesPerRow;
      
      return {
        ...node,
        position: {
          x: col * spacing + 200,
          y: row * spacing + 200
        }
      };
    }));
  };

  const handleCustomScreenAdd = (screen: { name: string; description: string; type: ScreenType }) => {
    const newNode: Node = {
      id: uuidv4(),
      type: 'screen',
      position: {
        x: Math.random() * 500,
        y: Math.random() * 500,
      },
      data: {
        label: screen.name,
        description: screen.description,
        type: screen.type,
        features: {
          ui: [],
          backend: [],
          shared: []
        }
      }
    };
    setNodes(nodes => [...nodes, newNode]);
    setShowAddScreen(false);
  };

  const handleScreenSelect = (screenType: ScreenType) => {
    const newNode: Node = {
      id: uuidv4(),
      type: 'screen',
      position: {
        x: Math.random() * 500,
        y: Math.random() * 500,
      },
      data: {
        label: screenType.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
        type: screenType,
        features: {
          ui: [],
          backend: [],
          shared: []
        }
      }
    };
    setNodes(nodes => [...nodes, newNode]);
  };

  const initializeCanvas = useCallback(async () => {
    if (!designId) {
      setNodes(initialStages);
      setIsLoading(false);
      return;
    }

    try {
      const { data: design, error } = await supabase
        .from('flare_designs')
        .select('*')
        .eq('id', designId)
        .single();

      if (error) throw error;

      if (design) {
        const featureNodes = Object.values(design.features || {}).map((feature, index) => ({
          id: feature.id || uuidv4(),
          type: 'feature',
          position: feature.position || { 
            x: 200 + (index % 3) * 250, 
            y: 300 + Math.floor(index / 3) * 150 
          },
          data: {
            label: feature.name,
            description: feature.description,
            priority: feature.priority || 'medium',
            points: feature.points || 50
          }
        }));

        const stageNodes = initialStages.map(stage => ({
          ...stage,
          position: stage.position || { x: 200, y: 200 }
        }));

        setNodes([...stageNodes, ...featureNodes]);

        const newEdges = [];
        Object.entries(design.stages || {}).forEach(([stage, featureIds]) => {
          featureIds.forEach((featureId) => {
            newEdges.push({
              id: `${stage}-${featureId}`,
              source: stage,
              target: featureId,
              type: 'smoothstep'
            });
          });
        });
        setEdges(newEdges);

        setClientName(design.client_name || '');
        setClientCompany(design.client_company || '');
        setAccessCode(design.access_code);
      }
    } catch (error) {
      console.error('Error loading design:', error);
      toast.error('Failed to load design');
    } finally {
      setIsLoading(false);
    }
  }, [designId, setIsLoading, setNodes, setEdges, setClientName, setClientCompany, setAccessCode]);

  useEffect(() => {
    initializeCanvas();
  }, [initializeCanvas]);

  if (isLoading) {
    return (
      <div className="h-screen bg-background flex items-center justify-center">
        <Loading size="lg" text="Loading your design canvas..." />
      </div>
    );
  }

  if (showWelcome) {
    return (
      <WelcomeScreen
        accessCode={accessCode}
        onContinue={handleWelcomeContinue}
      />
    );
  }

  let ViewComponent;
  switch (currentView) {
    case 'focus':
      ViewComponent = FocusView;
      break;
    case 'wireframe':
      ViewComponent = WireframeView;
      break;
    case 'code':
      ViewComponent = CodeView;
      break;
    case 'flow':
    default:
      ViewComponent = FlowView;
      break;
  }

  return (
    <div className="h-[100vh] bg-background touch-none">
      <ReactFlowProvider>
        <Panel position="top-left" className="flex gap-2 p-2">
          <div className="flex items-center gap-2">
            {(clientName || clientCompany) && (
              <div className="flex items-center gap-2 px-3 py-1.5 bg-accent/10 rounded-md">
                <User className="h-4 w-4 text-muted-foreground" />
                <span className="text-sm text-muted-foreground">
                  {clientName}{clientCompany ? ` • ${clientCompany}` : ''}
                </span>
              </div>
            )}
            <div className="flex items-center gap-2 px-3 py-1.5 bg-accent/10 rounded-md">
              <Key className="h-4 w-4 text-muted-foreground" />
              <span className="text-sm text-muted-foreground font-mono">
                {accessCode}
              </span>
            </div>
          </div>
          <div className="h-8 w-[1px] bg-border mx-1" />
          <div className="flex gap-1.5">
            {designId && (
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8 bg-accent hover:bg-accent-hover"
                title="Save Design"
                onClick={handleSave}
                disabled={isSaving}
              >
                <Save className="h-4 w-4" />
              </Button>
            )}
            <Button
              variant="ghost"
              size="icon"
              className="h-8 w-8 bg-accent hover:bg-accent-hover"
              title="AI Import"
              onClick={() => setShowAIImport(true)}
            >
              <Wand2 className="h-4 w-4" />
            </Button>
            <Button
              variant="ghost"
              size="icon"
              className="h-8 w-8 bg-accent hover:bg-accent-hover"
              title="Add Screen"
              onClick={() => setShowScreens(true)}
            >
              <Smartphone className="h-4 w-4" />
            </Button>
            <AddItemMenu 
              onFeatureClick={() => setShowAddFeature(true)}
              onScreenClick={() => setShowAddScreen(true)}
            />
            <Button
              onClick={handlePredefinedFeatureClick}
              variant="ghost"
              size="icon"
              className="h-8 w-8 bg-accent hover:bg-accent-hover"
              title="Predefined Features"
            >
              <Library className="h-4 w-4" />
            </Button>
            <Button
              onClick={handleDeleteSelected}
              variant="ghost"
              size="icon"
              className="h-8 w-8 bg-accent hover:bg-accent-hover"
              title="Delete Selected"
            >
              <Trash2 className="h-4 w-4" />
            </Button>
            <Button
              onClick={handleAutoLayout}
              variant="ghost"
              size="icon"
              className="h-8 w-8 bg-accent hover:bg-accent-hover"
              title="Auto Layout"
            >
              <LayoutGrid className="h-4 w-4" />
            </Button>
          </div>
          <div className="h-8 w-[1px] bg-border mx-1" />
          <div className="flex gap-1.5">
            <Button
              onClick={() => setCurrentView('flow')}
              variant={currentView === 'flow' ? 'secondary' : 'ghost'}
              size="icon"
              className={`h-8 w-8 ${
                currentView === 'flow'
                  ? 'bg-secondary hover:bg-secondary/90'
                  : 'bg-accent hover:bg-accent-hover'
              }`}
              title="Flow View"
            >
              <GitBranch className="h-4 w-4" />
            </Button>
            <Button
              onClick={() => setCurrentView('focus')}
              variant={currentView === 'focus' ? 'secondary' : 'ghost'}
              size="icon"
              className={`h-8 w-8 ${
                currentView === 'focus'
                  ? 'bg-secondary hover:bg-secondary/90'
                  : 'bg-accent hover:bg-accent-hover'
              }`}
              title="Focus View"
            >
              <Target className="h-4 w-4" />
            </Button>
            <Button
              onClick={() => setCurrentView('wireframe')}
              variant={currentView === 'wireframe' ? 'secondary' : 'ghost'}
              size="icon"
              className={`h-8 w-8 ${
                currentView === 'wireframe'
                  ? 'bg-secondary hover:bg-secondary/90'
                  : 'bg-accent hover:bg-accent-hover'
              }`}
              title="Wireframe View"
            >
              <LayoutTemplate className="h-4 w-4" />
            </Button>
            <Button
              onClick={() => setCurrentView('code')}
              variant={currentView === 'code' ? 'secondary' : 'ghost'}
              size="icon"
              className={`h-8 w-8 ${
                currentView === 'code'
                  ? 'bg-secondary hover:bg-secondary/90'
                  : 'bg-accent hover:bg-accent-hover'
              }`}
              title="Code View"
            >
              <Code className="h-4 w-4" />
            </Button>
          </div>
        </Panel>

        <ViewComponent
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          fitView
          minZoom={0.5}
          maxZoom={2}
          defaultViewport={{ x: 0, y: 0, zoom: 0.8 }}
        />
        
        <AIImportDialog
          open={showAIImport}
          onOpenChange={setShowAIImport}
          designId={designId}
          onAnalysisComplete={initializeCanvas}
          clientName={clientName}
          clientCompany={clientCompany}
          accessCode={accessCode}
          onAddScreen={handleCustomScreenAdd}
        />
        
        <AddFeatureDialog 
          open={showAddFeature} 
          onOpenChange={setShowAddFeature} 
          onFeatureAdd={handleAddFeature} 
        />
        <AddScreenDialog
          open={showAddScreen}
          onOpenChange={setShowAddScreen}
          onScreenAdd={handleCustomScreenAdd}
        />
        <PredefinedFeatures 
          open={showPredefined} 
          onOpenChange={setShowPredefined}
          onFeatureAdd={handleAddFeature}
          nodes={nodes}
        />
        <ScreensDrawer
          open={showScreens}
          onOpenChange={setShowScreens}
          onScreenSelect={handleScreenSelect}
        />
      </ReactFlowProvider>
    </div>
  );
};

export const FlareDesignCanvas = (props: FlareDesignCanvasProps) => (
  <ReactFlowProvider>
    <FlowCanvas {...props} />
  </ReactFlowProvider>
);
