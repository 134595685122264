import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Tabs, TabsContent } from "@/components/ui/tabs";
import { DealForm } from "./details/DealForm";
import { DealHeader } from "./details/DealHeader";
import { DealDetailsTabs } from "./details/DealDetailsTabs";
import { DealActions } from "./details/DealActions";
import { DealIntelligence } from "./DealIntelligence";
import { DealActivities } from "./details/DealActivities";
import { DealNotes } from "./details/DealNotes";
import { DealFiles } from "./details/DealFiles";
import { DealTasks } from "./details/DealTasks";
import { DealTeam } from "./details/DealTeam";
import { toast } from "sonner";
import { useDealData } from "./hooks/useDealData";
import { ArrowLeft, Building2, User } from "lucide-react";

export const DealView = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  // Validate UUID format
  const isValidUUID = id?.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i);

  const {
    deal,
    isLoading,
    error,
    isEditing,
    setIsEditing,
    isSubmitting,
    formData,
    handleSave,
    handleFieldChange,
  } = useDealData(isValidUUID ? id : undefined);

  if (!isValidUUID) {
    return (
      <Card className="p-6">
        <p className="text-center text-muted-foreground">Invalid deal ID</p>
      </Card>
    );
  }

  if (isLoading) {
    return (
      <Card className="p-6">
        <div className="flex items-center justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
        </div>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="p-6">
        <p className="text-center text-destructive">Error loading deal details</p>
      </Card>
    );
  }

  if (!deal) {
    return (
      <Card className="p-6">
        <p className="text-center text-muted-foreground">Deal not found</p>
      </Card>
    );
  }

  return (
    <div className="space-y-8 max-w-[1800px] mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <Button 
            variant="ghost" 
            onClick={() => navigate('/admin')}
            className="hover:bg-accent flex items-center gap-2 text-muted-foreground hover:text-foreground"
          >
            <ArrowLeft className="h-4 w-4" />
            Back to Pipeline
          </Button>
          <DealHeader deal={deal} />
        </div>
        <DealActions
          isEditing={isEditing}
          onEdit={() => setIsEditing(true)}
          onSave={handleSave}
          onCancel={() => setIsEditing(false)}
          isSubmitting={isSubmitting}
          dealId={deal.id}
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2 space-y-8">
          <Card className="p-8 bg-card dark:bg-card border-border">
            <Tabs defaultValue="overview" className="w-full">
              <DealDetailsTabs />
              
              <div className="mt-8">
                <TabsContent value="overview">
                  <DealForm
                    formData={formData}
                    isEditing={isEditing}
                    onChange={handleFieldChange}
                  />
                </TabsContent>

                <TabsContent value="activities">
                  <DealActivities dealId={deal.id} />
                </TabsContent>

                <TabsContent value="notes">
                  <DealNotes dealId={deal.id} />
                </TabsContent>

                <TabsContent value="files">
                  <DealFiles dealId={deal.id} />
                </TabsContent>

                <TabsContent value="tasks">
                  <DealTasks dealId={deal.id} />
                </TabsContent>

                <TabsContent value="team">
                  <DealTeam dealId={deal.id} />
                </TabsContent>
              </div>
            </Tabs>
          </Card>
        </div>

        <div className="space-y-8">
          <DealIntelligence dealId={deal.id} />
          
          <Card className="p-6 bg-card dark:bg-card border-border">
            <h3 className="text-lg font-semibold mb-6">Quick Links</h3>
            <div className="space-y-3">
              {deal.company_id && (
                <Button 
                  variant="ghost" 
                  className="w-full justify-start text-muted-foreground hover:text-foreground group"
                  onClick={() => navigate(`/admin/companies/${deal.company_id}`)}
                >
                  <Building2 className="w-4 h-4 mr-3 group-hover:text-primary" />
                  View Company Profile
                </Button>
              )}
              {deal.contact_id && (
                <Button 
                  variant="ghost" 
                  className="w-full justify-start text-muted-foreground hover:text-foreground group"
                  onClick={() => navigate(`/admin/contacts/${deal.contact_id}`)}
                >
                  <User className="w-4 h-4 mr-3 group-hover:text-primary" />
                  View Contact Profile
                </Button>
              )}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};