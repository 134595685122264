
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Shield } from 'lucide-react';
import { DatabaseEmployee } from '@/types/employee';

interface ProfileEmergencyContactProps {
  profile: DatabaseEmployee;
  isEditMode: boolean;
  onNestedChange: (category: 'emergency_contacts', field: string, value: string) => void;
}

export const ProfileEmergencyContact = ({
  profile,
  isEditMode,
  onNestedChange
}: ProfileEmergencyContactProps) => {
  const emergencyContact = profile.emergency_contacts[0] || { name: '', relationship: '', phone: '' };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center">
          <Shield className="h-5 w-5 mr-2" />
          Emergency Contact
        </CardTitle>
      </CardHeader>
      <CardContent>
        {isEditMode ? (
          <div className="space-y-4">
            <div>
              <Label htmlFor="emergency_name">Name</Label>
              <Input
                id="emergency_name"
                value={emergencyContact.name}
                onChange={(e) => onNestedChange('emergency_contacts', 'name', e.target.value)}
              />
            </div>
            <div>
              <Label htmlFor="emergency_relationship">Relationship</Label>
              <Input
                id="emergency_relationship"
                value={emergencyContact.relationship}
                onChange={(e) => onNestedChange('emergency_contacts', 'relationship', e.target.value)}
              />
            </div>
            <div>
              <Label htmlFor="emergency_phone">Phone</Label>
              <Input
                id="emergency_phone"
                value={emergencyContact.phone}
                onChange={(e) => onNestedChange('emergency_contacts', 'phone', e.target.value)}
              />
            </div>
          </div>
        ) : (
          emergencyContact ? (
            <div className="space-y-2">
              <p><strong>Name:</strong> {emergencyContact.name}</p>
              <p><strong>Relationship:</strong> {emergencyContact.relationship}</p>
              <p><strong>Phone:</strong> {emergencyContact.phone}</p>
            </div>
          ) : (
            <p>No emergency contacts provided</p>
          )
        )}
      </CardContent>
    </Card>
  );
};
