import { TabsList, TabsTrigger } from "@/components/ui/tabs";

interface SubTabsProps {
  tabs: {
    value: string;
    label: string;
  }[];
  activeColor?: string;
}

export const SubTabs = ({ tabs, activeColor = "primary" }: SubTabsProps) => {
  return (
    <TabsList className="w-full justify-start border-b rounded-none p-0 h-auto overflow-x-auto flex-nowrap bg-transparent">
      {tabs.map((tab) => (
        <TabsTrigger
          key={tab.value}
          value={tab.value}
          className={`
            rounded-none border-b-2 border-transparent 
            data-[state=active]:border-admin-${activeColor} 
            whitespace-nowrap px-4 py-2 text-sm
            hover:bg-black/5 dark:hover:bg-white/5
            transition-colors duration-200
            font-mono
          `}
        >
          {tab.label}
        </TabsTrigger>
      ))}
    </TabsList>
  );
};