import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Card } from "@/components/ui/card";
import { format } from "date-fns";
import { Button } from "@/components/ui/button";
import { Plus, MoreVertical } from "lucide-react";

interface DealActivityProps {
  dealId: string;
}

export const DealActivities = ({ dealId }: DealActivityProps) => {
  const { data: activities, isLoading } = useQuery({
    queryKey: ["deal-activities", dealId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("deal_activities")
        .select(`
          *,
          admin_profiles (
            name
          )
        `)
        .eq("deal_id", dealId)
        .order("created_at", { ascending: false });

      if (error) throw error;
      return data;
    },
  });

  if (isLoading) {
    return <div className="p-4 text-muted-foreground">Loading activities...</div>;
  }

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-lg font-semibold">Next activity</h2>
        <Button variant="outline" className="gap-2">
          <Plus className="h-4 w-4" />
          Create next activity
        </Button>
      </div>

      <div className="space-y-4">
        {activities?.map((activity) => (
          <Card key={activity.id} className="bg-card/50 border-border/50">
            <div className="p-4 flex items-start justify-between">
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <span className="text-sm bg-primary/10 text-primary px-2 py-0.5 rounded">
                    {format(new Date(activity.created_at), "MMM d, yyyy 'at' h:mm a")}
                  </span>
                </div>
                <p className="text-sm text-foreground">{activity.description}</p>
                <p className="text-xs text-muted-foreground">
                  by {activity.admin_profiles?.name || "System"}
                </p>
              </div>
              <Button variant="ghost" size="icon" className="text-muted-foreground">
                <MoreVertical className="h-4 w-4" />
              </Button>
            </div>
          </Card>
        ))}
        {activities?.length === 0 && (
          <p className="text-center text-muted-foreground py-8">No activities recorded yet.</p>
        )}
      </div>
    </div>
  );
};