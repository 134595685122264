import { Card } from "@/components/ui/card";
import { Code2, HelpCircle } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider
} from "@/components/ui/tooltip";
import { TechStack } from "../../types";

interface TechStackProps {
  stack: TechStack;
  recommendations: TechStack;
}

export const TechStackSection = ({ stack, recommendations }: TechStackProps) => {
  const getTechExplanation = (tech: string) => {
    const explanations: { [key: string]: { what: string; why: string; benefit: string } } = {
      WordPress: {
        what: "A user-friendly content management system perfect for websites and blogs",
        why: "Ideal for content-focused websites that need easy updates",
        benefit: "You can manage your website content without technical knowledge"
      },
      Webflow: {
        what: "A visual website builder with professional design capabilities",
        why: "Great for creating custom-looking websites without coding",
        benefit: "Professional design control without development complexity"
      },
      Shopify: {
        what: "An all-in-one e-commerce platform",
        why: "Perfect for online stores with built-in payment processing",
        benefit: "Start selling online quickly with minimal technical setup"
      },
      "Custom Development": {
        what: "Tailored solution built specifically for your needs",
        why: "Provides complete control over functionality and features",
        benefit: "Your application works exactly as you need it to"
      }
    };

    return explanations[tech] || {
      what: "A technology selected for your specific needs",
      why: "Chosen based on your project requirements",
      benefit: "Provides the right balance of functionality and ease of use"
    };
  };

  return (
    <Card className="p-6">
      <div className="flex items-center gap-3 mb-4">
        <Code2 className="w-6 h-6 text-[#FF4D00]" />
        <h3 className="text-xl font-semibold">Technology Recommendations</h3>
      </div>
      
      <p className="text-muted-foreground mb-6">
        Based on your project requirements, we've selected technologies that will help you achieve your goals efficiently and cost-effectively.
      </p>

      <div className="space-y-6">
        {Object.entries(recommendations).map(([category, techs]) => (
          <div key={category} className="space-y-3">
            <h4 className="font-medium capitalize flex items-center gap-2">
              {category} Technologies
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <HelpCircle className="w-4 h-4 text-muted-foreground" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p className="max-w-xs">
                      {category === 'frontend' && "These create what users see and interact with"}
                      {category === 'backend' && "These handle the behind-the-scenes operations"}
                      {category === 'infrastructure' && "This is where your application runs"}
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </h4>
            <div className="grid gap-4">
              {techs.map((tech) => {
                const explanation = getTechExplanation(tech);
                return (
                  <div key={tech} className="p-6 bg-secondary/5 rounded-lg space-y-3">
                    <div className="font-medium text-lg">{tech}</div>
                    {explanation && (
                      <div className="space-y-2">
                        <p className="text-sm text-muted-foreground">
                          <strong>What it is:</strong> {explanation.what}
                        </p>
                        <p className="text-sm text-muted-foreground">
                          <strong>Why we chose it:</strong> {explanation.why}
                        </p>
                        <p className="text-sm text-[#FF4D00]">
                          <strong>Benefit to you:</strong> {explanation.benefit}
                        </p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};