import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';

export const useHandbook = () => {
  const fetchHandbook = async () => {
    const { data: session } = await supabase.auth.getSession();
    
    if (!session?.session) {
      throw new Error('Authentication required');
    }

    const { data, error } = await supabase
      .from('handbook_sections')
      .select('*')
      .order('created_at', { ascending: true });

    if (error) {
      console.error('Error fetching handbook:', error);
      toast.error('Failed to load handbook content');
      throw error;
    }

    return data;
  };

  return useQuery({
    queryKey: ['handbook'],
    queryFn: fetchHandbook,
    retry: 1,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
};