
import { useEffect } from 'react';
import Script from '@/components/ui/script';

interface HubspotMeetingsProps {
  url: string;
  className?: string;
  queryParams?: Record<string, string>;
}

export function HubspotMeetings({ url, className = "", queryParams = {} }: HubspotMeetingsProps) {
  useEffect(() => {
    // Create a global window.HubSpotMeetings object if it doesn't exist
    if (!(window as any).HubSpotMeetings) {
      (window as any).HubSpotMeetings = {};
    }
    
    // Reinitialize meetings when the component mounts
    const onLoad = () => {
      if ((window as any).HubSpot && (window as any).HubSpot.MeetingsEmbed) {
        (window as any).HubSpot.MeetingsEmbed.create();
      }
    };
    
    // Call onLoad immediately in case the script has already loaded
    onLoad();
    
    // Also listen for the script to load
    window.addEventListener('message', (event) => {
      if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady') {
        console.log('HubSpot form is ready');
      }
    });
    
    return () => {
      // Clean up event listeners
      window.removeEventListener('message', onLoad);
    };
  }, [url]);

  // Construct URL with query parameters
  const constructUrl = () => {
    const baseUrl = url;
    const urlObj = new URL(baseUrl);
    
    // Add query parameters
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value) {
        urlObj.searchParams.append(key, value);
      }
    });
    
    return urlObj.toString();
  };

  return (
    <div className={`hubspot-meetings-wrapper ${className}`}>
      <div 
        className="meetings-iframe-container" 
        data-src={constructUrl()}
      ></div>
      <Script src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js" />
    </div>
  );
}
