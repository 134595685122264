import { motion } from "framer-motion";

interface EnterpriseMetricsProps {
  metrics: Record<string, any>;
}

export const EnterpriseMetrics = ({ metrics }: EnterpriseMetricsProps) => {
  if (!metrics) return null;

  return (
    <motion.div
      className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-12 bg-blue-50/50 dark:bg-blue-950/20 rounded-xl p-6"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.7, duration: 0.5 }}
    >
      {Object.entries(metrics).map(([key, metric]: [string, any]) => (
        <div key={key} className="text-center">
          <div className="text-3xl font-bold text-blue-600 dark:text-blue-400">{metric.value}</div>
          <div className="text-sm font-medium text-gray-900 dark:text-gray-100">{metric.label}</div>
          <div className="text-xs text-gray-600 dark:text-gray-400 mt-1 line-clamp-1">{metric.description}</div>
        </div>
      ))}
    </motion.div>
  );
};