import { useQuery } from "@tanstack/react-query";
import { Card } from "@/components/ui/card";
import { MessageSquare, Phone, Calendar, FileText } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";

export const LeadActivities = () => {
  const { data: activities, isLoading } = useQuery({
    queryKey: ["lead-activities"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("lead_activities")
        .select(`
          *,
          leads (
            name,
            email
          ),
          admin_profiles!lead_activities_performed_by_fkey (
            name
          )
        `)
        .order("created_at", { ascending: false });
      
      if (error) throw error;
      return data;
    },
  });

  const getActivityIcon = (type: string) => {
    switch (type) {
      case "email":
        return <MessageSquare className="w-4 h-4" />;
      case "call":
        return <Phone className="w-4 h-4" />;
      case "meeting":
        return <Calendar className="w-4 h-4" />;
      default:
        return <FileText className="w-4 h-4" />;
    }
  };

  return (
    <div className="space-y-4">
      <Card className="p-6">
        <h3 className="text-lg font-semibold mb-4">Recent Activities</h3>
        
        {isLoading ? (
          <p>Loading activities...</p>
        ) : activities?.length === 0 ? (
          <p>No activities recorded yet</p>
        ) : (
          <div className="space-y-4">
            {activities?.map((activity) => (
              <div key={activity.id} className="flex items-start gap-4 border-b border-gray-100 pb-4">
                <div className="p-2 bg-gray-100 rounded-full">
                  {getActivityIcon(activity.type)}
                </div>
                <div className="flex-1">
                  <p className="font-medium">
                    {activity.leads?.name} ({activity.leads?.email})
                  </p>
                  <p className="text-sm text-muted-foreground">{activity.description}</p>
                  <div className="flex items-center gap-2 mt-1 text-xs text-muted-foreground">
                    <span>by {activity.admin_profiles?.name}</span>
                    <span>•</span>
                    <span>{new Date(activity.created_at).toLocaleString()}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </Card>
    </div>
  );
};