
import { motion } from "framer-motion";
import { Check, ArrowRight } from "lucide-react";
import { Button } from "@/components/ui/button";
import { LucideIcon } from "lucide-react";
import { GlowingEffect } from "@/components/ui/glowing-effect";

interface ServiceProps {
  id: string;
  title: string;
  description: string;
  features: string[];
  icon: LucideIcon;
  ctaText: string;
  imageSrc: string; // Keep this prop for backward compatibility
}

interface ServiceCardProps {
  service: ServiceProps;
  index: number;
  onClick: () => void;
}

// Function to get a background color based on the service ID
const getBackgroundColor = (id: string) => {
  const colors: Record<string, string> = {
    software: "from-zinc-900 to-zinc-800",
    startup: "from-zinc-900 to-zinc-800",
    government: "from-zinc-900 to-zinc-800", 
    innovation: "from-zinc-900 to-zinc-800",
    cloud: "from-zinc-900 to-zinc-800",
    security: "from-zinc-900 to-zinc-800"
  };
  
  return colors[id] || "from-zinc-900 to-zinc-800";
};

// Get gradient colors for glow effect based on service ID
const getGlowVariant = (id: string): "default" | "white" => {
  // All services use default gradient, but you could customize per service type
  return "default";
};

export const ServiceCard = ({ service, index, onClick }: ServiceCardProps) => {
  const { icon: Icon, title, description, features, ctaText, id } = service;
  const bgGradient = getBackgroundColor(id);
  const glowVariant = getGlowVariant(id);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className="group relative rounded-xl overflow-hidden border border-white/5 hover:border-brand-orange/20 shadow-lg hover:shadow-brand-orange/5 transition-all duration-500"
    >
      {/* Glow effect */}
      <GlowingEffect 
        spread={40} 
        glow={true} 
        disabled={false} 
        proximity={64} 
        inactiveZone={0.01}
        borderWidth={2}
        variant={glowVariant}
      />
      
      {/* Solid background gradient */}
      <div className={`absolute inset-0 bg-gradient-to-b ${bgGradient} z-0`}></div>
      
      {/* Subtle pattern overlay for texture */}
      <div className="absolute inset-0 opacity-5 bg-[radial-gradient(#ffffff33_1px,transparent_1px)] bg-[size:20px_20px] z-0"></div>
      
      {/* Content */}
      <div className="relative z-10 p-6 md:p-8">
        <div className="mb-5 flex items-center gap-3">
          <div className="bg-gradient-to-br from-brand-orange/20 to-brand-orange/10 backdrop-blur-md p-3 rounded-lg">
            <Icon className="h-5 w-5 text-brand-orange" />
          </div>
          <h3 className="text-xl md:text-2xl font-display font-bold text-white">{title}</h3>
        </div>
        
        <p className="mb-5 text-gray-300 text-sm md:text-base">{description}</p>
        
        <div className="mb-6 space-y-1.5">
          {features.map((feature, idx) => (
            <div key={idx} className="flex items-start gap-2 text-xs md:text-sm">
              <div className="bg-brand-orange/10 p-1 rounded-full mt-0.5 flex-shrink-0">
                <Check className="h-3 w-3 text-brand-orange" />
              </div>
              <span className="text-gray-200">{feature}</span>
            </div>
          ))}
        </div>
        
        <Button 
          className="w-full md:w-auto bg-black/50 hover:bg-brand-orange/90 text-white border border-brand-orange/30 text-xs md:text-sm font-mono group backdrop-blur-sm"
          onClick={onClick}
        >
          {ctaText} <ArrowRight className="ml-1 h-3 w-3 group-hover:translate-x-1 transition-transform" />
        </Button>
      </div>
    </motion.div>
  );
};
