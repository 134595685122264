import { Card } from "@/components/ui/card";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";

interface DealAnalyticsProps {
  deals: any[];
  className?: string;
}

export const DealAnalytics = ({ deals, className }: DealAnalyticsProps) => {
  // Prepare data for pipeline value by stage
  const pipelineData = deals.reduce((acc: any[], deal) => {
    const existingStage = acc.find((item) => item.stage === deal.stage);
    if (existingStage) {
      existingStage.value += deal.value || 0;
    } else {
      acc.push({
        stage: deal.stage,
        value: deal.value || 0,
      });
    }
    return acc;
  }, []);

  // Prepare data for win probability
  const probabilityData = deals
    .filter((deal) => deal.deal_analytics?.probability_score)
    .map((deal) => ({
      name: deal.name,
      probability: deal.deal_analytics.probability_score,
    }));

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

  return (
    <div className={className}>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <Card className="p-4">
          <h3 className="text-lg font-semibold mb-4">Pipeline Value by Stage</h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={pipelineData}
                  dataKey="value"
                  nameKey="stage"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  label
                >
                  {pipelineData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </Card>

        <Card className="p-4">
          <h3 className="text-lg font-semibold mb-4">Deal Win Probability</h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={probabilityData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="probability"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Card>
      </div>
    </div>
  );
};