
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Sparkles } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { ResultsView } from "./views/ResultsView";
import { useProjectSubmission } from "./hooks/useProjectSubmission";
import { toast } from "sonner";
import { AnalysisProgress } from "./components/feedback/AnalysisProgress";
import { useProjectStore } from "./stores/projectStore";

export const ProjectScopingTool = () => {
  const [currentView, setCurrentView] = useState<"input" | "results">("input");
  const [pitch, setPitch] = useState("");
  const { submitProject, isLoading, currentStep } = useProjectSubmission();
  const setAnalysis = useProjectStore((state) => state.setAnalysis);

  const handleSubmitPitch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!pitch.trim() || isLoading) return;

    try {
      const result = await submitProject(pitch);
      if (result) {
        setAnalysis(result);
        setCurrentView("results");
      }
    } catch (error) {
      console.error("Error submitting project:", error);
      toast.error("Failed to analyze project. Please try again.");
    }
  };

  if (currentView === "input") {
    return (
      <>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-3xl mx-auto px-4 py-12"
        >
          <form onSubmit={handleSubmitPitch} className="space-y-8">
            <div className="space-y-4">
              <h2 className="text-3xl font-bold text-center text-gray-900 dark:text-white">
                Share your elevator pitch and let our AI generate a{" "}
                <span className="bg-gradient-to-r from-[#FF4D00] to-[#FF6B00] bg-clip-text text-transparent">
                  comprehensive project plan
                </span>
              </h2>
              <p className="text-center text-gray-600 dark:text-gray-300">
                Including features, timeline, budget, and technical recommendations
              </p>
            </div>

            <Textarea
              value={pitch}
              onChange={(e) => setPitch(e.target.value)}
              placeholder="Describe your startup idea or project requirements..."
              className="min-h-[200px] text-lg p-6 font-mono resize-none focus:ring-2 focus:ring-[#FF4D00] dark:bg-gray-900/50 dark:text-white dark:border-gray-700 bg-white/90 border-gray-200 text-gray-900 shadow-sm"
              disabled={isLoading}
            />

            <Button
              type="submit"
              disabled={!pitch.trim() || isLoading}
              className="w-full bg-[#FF4D00] hover:bg-[#FF4D00]/90 text-white relative overflow-hidden group"
            >
              <span className="flex items-center gap-2 relative z-10">
                {isLoading ? "Analyzing Your Pitch..." : (
                  <>
                    Generate Analysis
                    <Sparkles className="w-4 h-4" />
                  </>
                )}
              </span>
              {!isLoading && (
                <motion.div
                  className="absolute inset-0 bg-gradient-to-r from-[#FF4D00]/20 to-transparent"
                  animate={{
                    x: ["0%", "100%"],
                  }}
                  transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    ease: "linear",
                  }}
                />
              )}
            </Button>

            {!isLoading && (
              <p className="text-sm text-center text-muted-foreground">
                Analysis typically takes 30-45 seconds to complete
              </p>
            )}
          </form>
        </motion.div>

        <AnalysisProgress 
          isGenerating={isLoading}
          currentStep={currentStep}
        />
      </>
    );
  }

  return (
    <div className="container mx-auto px-4">
      <AnimatePresence mode="wait">
        <motion.div
          key="results"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
        >
          <ResultsView />
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default ProjectScopingTool;
