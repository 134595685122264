import { motion } from "framer-motion";
import { IsometricCube } from "./3d/IsometricCube";
import { IsometricRocket } from "./3d/IsometricRocket";

export const ThesisSection = () => {
  return (
    <section className="py-24 bg-[#1A1F2C] relative overflow-hidden">
      {/* Subtle grid pattern */}
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#FF4D0010_1px,transparent_1px),linear-gradient(to_bottom,#FF4D0010_1px,transparent_1px)] bg-[size:4rem_4rem]" />
      
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-5xl mx-auto space-y-16"
        >
          <div className="space-y-6">
            <motion.h2 
              className="text-5xl md:text-[7rem] font-mono uppercase tracking-tighter text-white transform -skew-x-6"
              animate={{
                textShadow: [
                  "0 0 10px rgba(255,77,0,0.5)",
                  "0 0 20px rgba(255,77,0,0.3)",
                  "0 0 10px rgba(255,77,0,0.5)"
                ]
              }}
              transition={{ duration: 2, repeat: Infinity }}
            >
              Investment Thesis
            </motion.h2>
            <p className="text-2xl text-white/90 font-mono max-w-3xl">
              We back exceptional founders building category-defining companies.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-12">
            {[
              {
                icon: IsometricRocket,
                title: "Innovation First",
                description: "We seek founders who challenge conventions"
              },
              {
                icon: IsometricCube,
                title: "Global Ambition",
                description: "Building companies that scale globally"
              },
              {
                icon: IsometricCube,
                title: "Impact Driven",
                description: "Supporting real-world solutions"
              }
            ].map((item, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
                whileHover={{ scale: 1.05, rotateZ: index % 2 === 0 ? -1 : 1 }}
                className="relative group cursor-pointer"
              >
                <div className="absolute inset-0 bg-[#FF4D00]/5 transform -skew-x-6 group-hover:skew-x-6 transition-transform" />
                <div className="relative p-8 backdrop-blur-sm bg-[#1A1F2C]/50">
                  <motion.div
                    animate={{
                      scale: [1, 1.1, 1],
                      rotate: [0, 5, 0]
                    }}
                    transition={{ duration: 3, repeat: Infinity }}
                  >
                    <item.icon className="text-[#FF4D00] mb-6" />
                  </motion.div>
                  <h3 className="text-2xl font-mono text-white mb-4">{item.title}</h3>
                  <p className="text-white/80 font-mono">{item.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  );
};